/*eslint-disable*/
import React, {useRef} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../../components/Navbars/AdminNavbarLinks.jsx";

import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import {ListItemIcon} from "@material-ui/core";
import PrivateComponent from "../PrivateComponent/PrivateComponent";
import {APP_ACTIVITY} from "../../variables/coreConstants";
import {getData, getUser} from "../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
// import PerfectScrollbar from "perfect-scrollbar";
import SidebarWrapper from "./SidebarWrapper";



const Sidebar = ({ ...props }) => {
    const mainPanel = useRef(null);
    const { classes, logo, routes, miniActive } = props;

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        let currentRoute = null;
        let url = (window.location.href).toString();
        let index = url.search(routeName);

        if (index !== -1) {
            currentRoute = url.slice(index);
        }

        return currentRoute === routeName;
    }

    const hasAccountSettingsPermission = () => {
        let result = false;

        try {
            let userTabs = getUser().userRoutes;

            let index = userTabs.findIndex(x => x.routeUrl === "/company-settings");
            if (index !== -1) {
                result = true;
            }

        } catch (e) {}

        return result;
    };

    const hasSettingsTabs = () => {
        return routes.findIndex(x => x.appType === "Setup") !== -1;
    };
    const hasCommerceTabs = () => {
        return routes.findIndex(x => x.appType === "Commerce") !== -1;

    };
    const hasPaymentTabs = () => {
        return routes.findIndex(x => x.appType === "Payment") !== -1;

    };
    const hasDebitTabs = () => {
        return routes.findIndex(x => x.appType === "DirectDebit") !== -1;

    };
    const hasActivityTabs = () => {
        return routes.findIndex(x => x.appType === "Activity") !== -1;

    };


    // const { classes, color, logo, image, logoText, routes } = props;
    const [openSetup, setOpenSetup] = React.useState(false);
    const [openActivity, setOpenActivity] = React.useState(false);
    const [openCommerce, setOpenCommerce] = React.useState(false);
    const [openPayment, setOpenPayment] = React.useState(false);
    const [openDebit, setOpenDebit] = React.useState(false);
    const [minActive, setMinActive] = React.useState(miniActive);

    const handleClickSetup = () => {
        setOpenSetup(!openSetup);
    };
    const handleClickActivity = () => {
        setOpenActivity(!openActivity);
    };
    const handleClickCommerce = () => {
        setOpenCommerce(!openCommerce);
    };

    const handleClickPayment = () => {
        setOpenPayment(!openPayment);
    };
    const handleClickDebit = () => {
        setOpenDebit(!openDebit);
    };

    const needMinSideBar = () => {
        let currentRoute = null;
        try {
            let url = (window.location.href).toString();
            let index = url.search("/admin/orders-kanban");

            if (index !== -1) {
                currentRoute = url.slice(index);
            }
        } catch (e) {
            console.log("Something went wrong");
        }

        return currentRoute === "/admin/orders-kanban";
    };

    const handleCloseActive = () => {
        if (needMinSideBar()) {
            setMinActive(false);
        }
    };
    const handleOpenActive = () => {
        if (needMinSideBar()) {
            setMinActive(true);
            setOpenCommerce(true);
        }
    };

    const whiteFontClasses = classNames({
        [" " + classes.textSectionColor]: true
    });
    const warningFontClasses = classNames({
        [" " + classes.textWaringColor]: true
    });

    const itemText = classNames({
            [classes.itemText]: true,
            [classes.itemTextMini]: minActive,
            [classes.itemTextMiniRTL]: props.rtlActive && minActive,
            [classes.itemTextRTL]: props.rtlActive,
        });

    const notificationNoSupported = classNames({
            [classes.notificationNoSupported]: true,
        });

    const logoNormal = classNames({
            [classes.logoNormal]: true,
            [classes.logoNormalSidebarMini]: minActive,
            [classes.logoNormalSidebarMiniRTL]:
            props.rtlActive && minActive,
            [classes.logoNormalRTL]: props.rtlActive
        });

    const logoMini = classNames({
            [classes.logoMini]: true,
            [classes.logoMiniRTL]: props.rtlActive
        });

    const logoClasses = classNames({ [classes.logo]: true });

    const innerNavLinkClasses = classNames({[classes.collapseItemLink]: true});

    const collapseItemMini = classNames({
        [classes.collapseItemMini]: true,
        [classes.collapseItemMiniRTL]: props.rtlActive
    });

    const navLinkClasses = classNames({ [classes.itemLink]: true });

    const collapseItemText = classNames({
            [classes.collapseItemText]: true,
            [classes.collapseItemTextMini]: minActive,
            [classes.collapseItemTextMiniRTL]: props.rtlActive && minActive,
            [classes.collapseItemTextRTL]: props.rtlActive
        });

    const itemIcon = classNames({
            [classes.itemIcon]: true,
            [classes.itemIconRTL]: props.rtlActive
        });


    let account = getUser();

    var links = (
        <List className={classes.list + " " + classes.collapseList}>
            {
                !account.verified
                    ? (
                        <div>
                            {
                                hasAccountSettingsPermission() ? (
                                    <NavLink
                                        to={"/admin/company-settings"}
                                        className={classes.item}
                                        activeClassName="active"
                                    >
                                        <ListItem button className={classes.itemLinkNoVerified + " " + classes.selectedColorNoVerified + " " + classes.collapseItem}>
                                            <ListItemText
                                                primary={props.t('VERIFY_ACCOUNT_TEXT')}
                                                className={classNames(itemText, whiteFontClasses)}
                                                disableTypography={true}
                                            />
                                        </ListItem>
                                    </NavLink>
                                ) : (
                                    <NavLink
                                        to={"#"}
                                        className={classes.item}
                                        activeClassName="active"
                                    >
                                        <ListItem button className={classes.itemLinkNoVerified + " " + classes.selectedColorNoVerified + " " + classes.collapseItem}>
                                            <ListItemText
                                                primary={props.t('VERIFY_ACCOUNT_TEXT')}
                                                className={classNames(itemText, whiteFontClasses)}
                                                disableTypography={true}
                                            />
                                        </ListItem>
                                    </NavLink>
                                )
                            }
                        </div>

                    ) : null
            }
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses;
                if (prop.path === "/settings") {
                    activePro = classes.activePro + " ";
                    listItemClasses = classNames({
                        [" " + classes["selectedColor"]]: true
                    });
                } else {
                    listItemClasses = classNames({
                        [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                    });
                }
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });

                if (prop.appType === "Default") {
                    return (
                        <NavLink
                            to={prop.layout + prop.path}
                            className={activePro + classes.item}
                            activeClassName="active"
                            key={key}
                        >
                            <ListItem button className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={props.t(prop.name)}
                                    className={classNames(itemText, whiteFontClasses)}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                }
            })}

            {
                hasPaymentTabs() ? (
                    <div>

                        <ListItem button onClick={() => handleClickPayment()} className={classes.itemSectionLink}>
                            <ListItemIcon className={classes.right}>
                                {openPayment
                                    ? <ExpandLess
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                    : <ExpandMore
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={props.t('PAYMENTS_TEXT')}
                                className={classNames(itemText, whiteFontClasses)}
                                disableTypography={true}
                            />
                        </ListItem>
                        <Collapse in={openPayment} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {routes.map((prop, key) => {
                                    var activePro = " ";
                                    var listItemClasses;
                                    if (prop.path === "/settings") {
                                        activePro = classes.activePro + " ";
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: true
                                        });
                                    } else {
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                                        });
                                    }
                                    const whiteFontClasses = classNames({
                                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                                    });

                                    if (prop.appType === "Payment") {
                                        return (
                                            <NavLink
                                                to={prop.layout + prop.path}
                                                className={activePro + classes.item2}
                                                activeClassName="active"
                                                key={key}
                                            >
                                                <ListItem button className={classes.itemLink2 + listItemClasses}>
                                                    {typeof prop.icon === "string" ? (
                                                        <Icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        >
                                                            {prop.icon}
                                                        </Icon>
                                                    ) : (
                                                        <prop.icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        />
                                                    )}
                                                    <ListItemText
                                                        primary={props.t(prop.name)}
                                                        className={classNames(itemText, whiteFontClasses)}
                                                        disableTypography={true}
                                                    />
                                                </ListItem>
                                            </NavLink>
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                    </div>
                ) : null
            }

            {
                hasDebitTabs() ? (
                    <div>

                        <ListItem button onClick={() => handleClickDebit()} className={classes.itemSectionLink}>
                            <ListItemIcon className={classes.right}>
                                {openDebit
                                    ? <ExpandLess
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                    : <ExpandMore
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={props.t('DIRECT_DEBIT_TEXT')}
                                className={classNames(itemText, whiteFontClasses)}
                                disableTypography={true}
                            />
                        </ListItem>
                        <Collapse in={openDebit} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {routes.map((prop, key) => {
                                    var activePro = " ";
                                    var listItemClasses;
                                    if (prop.path === "/settings") {
                                        activePro = classes.activePro + " ";
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: true
                                        });
                                    } else {
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                                        });
                                    }
                                    const whiteFontClasses = classNames({
                                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                                    });

                                    if (prop.appType === "DirectDebit") {
                                        return (
                                            <NavLink
                                                to={prop.layout + prop.path}
                                                className={activePro + classes.item2}
                                                activeClassName="active"
                                                key={key}
                                            >
                                                <ListItem button className={classes.itemLink2 + listItemClasses}>
                                                    {typeof prop.icon === "string" ? (
                                                        <Icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        >
                                                            {prop.icon}
                                                        </Icon>
                                                    ) : (
                                                        <prop.icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        />
                                                    )}
                                                    <ListItemText
                                                        primary={props.t(prop.name)}
                                                        className={classNames(itemText, whiteFontClasses)}
                                                        disableTypography={true}
                                                    />
                                                </ListItem>
                                            </NavLink>
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                    </div>
                ) : null
            }

            {
                hasCommerceTabs() ? (
                    <div>
                        <ListItem button onClick={() => handleClickCommerce()} className={classes.itemSectionLink}>
                            <ListItemIcon className={classes.right}>
                                {openCommerce
                                    ? <ExpandLess
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                    : <ExpandMore
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={props.t('COMMERCE_TEXT')}
                                className={classNames(itemText, whiteFontClasses)}
                                disableTypography={true}
                            />
                        </ListItem>
                        <Collapse in={openCommerce} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {routes.map((prop, key) => {
                                    var activePro = " ";
                                    var listItemClasses;
                                    if (prop.path === "/settings") {
                                        activePro = classes.activePro + " ";
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: true
                                        });
                                    } else {
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                                        });
                                    }
                                    const whiteFontClasses = classNames({
                                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                                    });

                                    if (prop.appType === "Commerce") {
                                        return (
                                            <NavLink
                                                to={prop.layout + prop.path}
                                                className={activePro + classes.item2}
                                                activeClassName="active"
                                                key={key}
                                            >
                                                <ListItem button className={classes.itemLink2 + listItemClasses}>
                                                    {typeof prop.icon === "string" ? (
                                                        <Icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        >
                                                            {prop.icon}
                                                        </Icon>
                                                    ) : (
                                                        <prop.icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        />
                                                    )}
                                                    <ListItemText
                                                        primary={props.t(prop.name)}
                                                        className={classNames(itemText, whiteFontClasses)}
                                                        disableTypography={true}
                                                    />
                                                </ListItem>
                                            </NavLink>
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                    </div>
                ) : null
            }

            {
                hasSettingsTabs() ? (
                    <div>
                        <ListItem button onClick={() => handleClickSetup()} className={classes.itemSectionLink}>
                            <ListItemIcon className={classes.right}>
                                {openSetup
                                    ? <ExpandLess
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                    : <ExpandMore
                                        className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive
                                        })}
                                    />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={props.t('SETUP_TEXT')}
                                className={classNames(itemText, whiteFontClasses)}
                                disableTypography={true}
                            />
                        </ListItem>
                        <Collapse in={openSetup} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {routes.map((prop, key) => {
                                    var activePro = " ";
                                    var listItemClasses;
                                    if (prop.path === "/settings") {
                                        activePro = classes.activePro + " ";
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: true
                                        });
                                    } else {
                                        listItemClasses = classNames({
                                            [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                                        });
                                    }
                                    const whiteFontClasses = classNames({
                                        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                                    });

                                    if (prop.appType === "Setup") {
                                        return (
                                            <NavLink
                                                to={prop.layout + prop.path}
                                                className={activePro + classes.item}
                                                activeClassName="active"
                                                key={key}
                                            >
                                                <ListItem button className={classes.itemLink + listItemClasses}>
                                                    {typeof prop.icon === "string" ? (
                                                        <Icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        >
                                                            {prop.icon}
                                                        </Icon>
                                                    ) : (
                                                        <prop.icon
                                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                [classes.itemIconRTL]: props.rtlActive
                                                            })}
                                                        />
                                                    )}
                                                    <ListItemText
                                                        primary={props.t(prop.name)}
                                                        className={classNames(itemText, whiteFontClasses)}
                                                        disableTypography={true}
                                                    />
                                                </ListItem>
                                            </NavLink>
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                    </div>
                ) : null
            }

            {
                hasActivityTabs() ? (
                    <div>
                        <PrivateComponent appType={APP_ACTIVITY} userType={null}>
                            <ListItem button onClick={() => handleClickActivity()} className={classes.itemSectionLink}>
                                <ListItemIcon className={classes.right}>
                                    {openActivity
                                        ? <ExpandLess
                                            className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                        : <ExpandMore
                                            className={classNames(classes.itemCategoryIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Activity"}
                                    className={classNames(itemText, whiteFontClasses)}
                                    disableTypography={true}
                                />
                            </ListItem>
                            <Collapse in={openActivity} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {routes.map((prop, key) => {
                                        var activePro = " ";
                                        var listItemClasses;
                                        if (prop.path === "/settings") {
                                            activePro = classes.activePro + " ";
                                            listItemClasses = classNames({
                                                [" " + classes["selectedColor"]]: true
                                            });
                                        } else {
                                            listItemClasses = classNames({
                                                [" " + classes["selectedColor"]]: activeRoute(prop.layout + prop.path)
                                            });
                                        }
                                        const whiteFontClasses = classNames({
                                            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                                        });

                                        if (prop.appType === "Activity") {
                                            return (
                                                <NavLink
                                                    to={prop.layout + prop.path}
                                                    className={activePro + classes.item}
                                                    activeClassName="active"
                                                    key={key}
                                                >
                                                    <ListItem button className={classes.itemLink + listItemClasses}>
                                                        {typeof prop.icon === "string" ? (
                                                            <Icon
                                                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                    [classes.itemIconRTL]: props.rtlActive
                                                                })}
                                                            >
                                                                {prop.icon}
                                                            </Icon>
                                                        ) : (
                                                            <prop.icon
                                                                className={classNames(classes.itemIcon, whiteFontClasses, {
                                                                    [classes.itemIconRTL]: props.rtlActive
                                                                })}
                                                            />
                                                        )}
                                                        <ListItemText
                                                            primary={props.t(prop.name)}
                                                            className={classNames(itemText, whiteFontClasses)}
                                                            disableTypography={true}
                                                        />
                                                    </ListItem>
                                                </NavLink>
                                            );
                                        }
                                    })}
                                </List>
                            </Collapse>
                        </PrivateComponent>
                    </div>
                ) : null
            }



            {/*{*/}
            {/*    getData("notificationSupport")*/}
            {/*        ? (*/}
            {/*            <NavLink*/}
            {/*                to={"#"}*/}
            {/*                className={classes.activePro}*/}
            {/*                activeClassName="active"*/}
            {/*            >*/}

            {/*                <ListItem button className={classes.itemLink}>*/}
            {/*                    <Icon*/}
            {/*                        className={classNames(classes.itemSmallIcon, whiteFontClasses)}*/}
            {/*                    >*/}
            {/*                        {"warning"}*/}
            {/*                    </Icon>*/}
            {/*                    <ListItemText*/}
            {/*                        primary={props.t('NOTIFICATION_NO_SUPPORTED')}*/}
            {/*                        className={classNames(itemText, whiteFontClasses, notificationNoSupported)}*/}
            {/*                        disableTypography={true}*/}
            {/*                    />*/}
            {/*                </ListItem>*/}
            {/*            </NavLink>*/}
            {/*        ) : null*/}
            {/*}*/}
        </List>
    );

    var brand = (
        <div className={classes.logo}>
            <img src={logo} width={"auto"} height={"20px"} alt={"Ginih"}/>
        </div>
    );

    const drawerPaper = classNames({
            [classes.drawerPaper]: true,
            [classes.drawerPaperMini]: minActive,
            [classes.drawerPaperRTL]: props.rtlActive
        });
    const sidebarWrapper = classNames({
            [classes.sidebarWrapper]: true,
        [classes.drawerPaperMini]: minActive,
            [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
        });

    return (
        <div ref={mainPanel}>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={"left"}
                    open={props.open}
                    classes={{
                        paper: drawerPaper
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <SidebarWrapper
                        className={sidebarWrapper}
                        headerLinks={<AdminNavbarLinks/>}
                        links={links}
                    />
                    <div
                        className={classes.background}
                    />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    // onMouseOver={() => handleCloseActive()}
                    // onMouseOut={() => handleOpenActive()}
                    anchor={"left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: drawerPaper
                    }}
                >
                    {brand}
                    <SidebarWrapper
                        className={sidebarWrapper}
                        links={links}
                    />
                    <div
                        className={classes.background}
                    />
                </Drawer>
            </Hidden>
        </div>

    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    logo: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    miniActive: PropTypes.bool
};

export default withStyles(sidebarStyle)(withTranslation()(Sidebar));