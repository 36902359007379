/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)
* Modified By Pinett
* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

// #############################
// Parameters of configurations
// #############################

const ROWS_PER_PAGE                 = 25;
const ROWS_PER_PAGE_DETAILS         = 5;
const ROWS_PER_PAGE_OPTIONS         = [10, 25, 50];
const ROWS_PER_PAGE_OPTIONS_DETAILS = [5, 10, 25];


// ##############################
// Default information
// #############################

const DEFAULT_MEMBERSHIP_ID = 1;


// ##############################
// Google Services
// #############################

const GOOGLE_ANALYTICS_TRACKING = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const GOOGLE_API = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
const FIREBASE_NOTIFICATIONS = process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY;


// ##############################
// Endpoints for management dashboard
// #############################

/** BASE URL **/
const BASE_URL_ACCOUNT        = process.env.REACT_APP_ACCOUNT_API_URL;
const BASE_URL_CORE           = process.env.REACT_APP_CORE_API_URL;
const BASE_URL_COMMERCE       = process.env.REACT_APP_COMMERCE_API_URL;
const BASE_URL_APPLICATIONS   = process.env.REACT_APP_APPLICATION_API_URL;
const BASE_URL_BALANCE        = process.env.REACT_APP_BALANCE_API_URL;
const BASE_URL_DIRECT_DEBIT   = process.env.REACT_APP_DIRECT_DEBIT_API_URL;
const BASE_URL_PAYMENT_METHOD_SERVICE      = process.env.REACT_APP_PAYMENT_METHOD_SERVICE_URL;
const BASE_URL_WEB_SOCKET_SYNC             = process.env.REACT_APP_BASE_URL_WEB_SOCKET_SYNC;
const BASE_URL_WEB_SOCKET_DIRECT_DEBIT     = process.env.REACT_APP_BASE_URL_WEB_SOCKET_DIRECT_DEBIT;
const WIJMO_DISTRIBUTION_KEY               = process.env.REACT_APP_WIJMO_DIST_KEY_I + process.env.REACT_APP_WIJMO_DIST_KEY_II;



/** ACCOUNT ENDPOINTS **/
const VALIDATE_CREDENTIALS        = "/auth/business/login";
const ACCOUNT                     = "/auth/business/account";
const COMPANY_LOGO                = "/company/logo";
const COMPANY                     = "/company-information";
const ACCOUNT_LINK                = "/company/account-link";
const USER_INFORMATION            = "/user-information";
const COMPANY_INFORMATION         = "/update/company-information";
const CONFIRM_REGISTRATION        = "/auth/confirmRegistration/";
const RESEND_CONFIRMATION_EMAIL   = "/auth/business/resend-email";
const COMPANY_DOCUMENTS           = "/company-documents";
const DOCUMENT_REQUIRED           = "/company/document";
const SUBMIT_VERIFICATION_REQUEST = "/request-document-verification";
const FORGOT_PASSWORD             = "/auth/business/forgot-password";
const REQUEST_VERIFICATION        = "/auth/user/request-verification";
const RESET_PASSWORD              = "/auth/user/reset-password";
const CHANGE_EMAIL                = "/business/change-email";
const EMAIL_VERIFICATION          = "/auth/business/confirm-email";

/** CORE ENDPOINTS **/
const STORES_BY_COMPANY       = "/stores/company";
const ALL_STORES_ROLE         = "/all-stores/role";
const STORE                   = "/store";
const STORES_TYPES            = "/store-types";
const STORE_IMAGES            = "/store/images";
const STORE_CONFIGURATION     = "/store-configurations";
const STORE_DETAILS           = "/store-details";
const UPDATE_EXPLORER_MODE    = "/update-explorer-mode/store";
const STORES_AVAILABLE        = "/stores-available";

const CATALOG                     = "/catalog";
const CATALOGS_BY_COMPANY         = "/catalogs/company";
const CATALOGS_BY_CATEGORY         = "/catalogs/category";
const CATALOGS_INFORMATION_BY_APP = "/catalog-information/application";
const CATALOGS_BY_STORE           = "/catalogs/store/";
const CATALOGS_BY_APP             = "/catalogs/application";

const SCHEDULES                   = "/schedules";
const SCHEDULES_DAYS              = "/schedule-days";
const SCHEDULES_STORE             = "/schedules/store";
const SCHEDULES_CATALOG           = "/schedules/catalog";

const ASSET_BY_COMPANY        = "/asset/company";
const ASSET_AVAILABLE         = "/unassigned-asset/company";
const ASSET_BY_ZONE           = "/asset/zone/";
const ASSET                   = "/asset";
const ASSET_INFORMATION       = "/asset-information/";
const QR_ASSETS_BY_STORE      = "/qr-code-assets/store";

const ZONES                     = "/zones";
const ZONE                      = "/zone";
const ZONES_BY_COMPANY          = "/zones/company";
const ZONES_BY_STORE            = "/zones/store";
const ZONES_BY_APPLICATION      = "/application-zone";
const ZONE_ASSETS               = "/zone-assets";
const ZONE_ASSETS_APPS          = "/zone/assets-applications";
const ASSET_TECHNOLOGY          = "/asset-technology";
const ZONES_AVAILABLE_BY_STORE  = "/zones-available/store";
const ZONES_GROUPS_BY_COMPANY   = "/zone-groups";
const ZONES_GROUPS_ASSIGNMENTS  = "/zone-groups/";
const ZONES_GROUPS_BY_STORE     = "/zone-groups/store/";
const ZONE_GROUP                = "/zone-group";


const ORDERS                  = "/orders";
const ORDERS_BY_STORE         = "/orders/store";
const ORDERS_BY_COMPANY       = "/orders/company";
const ORDER_DETAIL            = "/order-detail";
const UPDATE_ORDER_STATUS     = "/update-order-status/";
const ORDER_LIST_BY_ZONE      = "/order-list/zone";
const SYNC_ORDER              = "/order-sync";

const PRODUCT                         = "/product";
const PRODUCTS_BY_COMPANY             = "/products/company";
const PRODUCTS_BY_CATEGORY            = "/products/category";
const PRODUCTS_AVAILABLE_BY_CATEGORY  = "/unassigned-products/category/";
const PRODUCT_TYPE                    = "/product-types";

const MODIFIER_GROUP                  = "/modifier-group";
const MODIFIERS_GROUPS_BY_COMPANY     = "/modifiers-groups/company";
const MODIFIER_PRODUCT_ASSIGNMENTS    = PRODUCT + "/all-modifier-groups";
const MODIFIER_GROUP_INFORMATION      = "/modifier-group-information/";
const MODIFIER_ITEM_ASSIGNMENTS       = "/all-modifier-items/";

const CATEGORIES                  = "/categories";
const CATEGORIES_BY_COMPANY       = "/categories/company";
const CATEGORIES_BY_CATALOG       = "/all-categories/catalog";
const CATEGORIES_ASSIGNED_CATALOG = "/categories/catalog";
const CATEGORIES_BY_PRODUCT       = "/all-categories/product";
const CATEGORY_CATALOG_ORDER      = "/category-catalog-order";
const CATEGORY                    = "/category";
const PRICEBOOKS                  = "/pricebooks";
const PRICEBOOK                   = "/pricebook";

const CITIES_BY_COUNTRY       = "/city/country/";
const COUNTRIES               = "/auth/information-countries";

const ALL_COUNTRIES           = "/all-countries";
const REGIONS_BY_COUNTRY      = "/country-regions";

const MEMBER                      = "/member";
const MEMBERS_BY_COMPANY          = "/members/company";
const MEMBERS_OWNERS_BY_COMPANY   = "/members-owner/company";
const MEMBER_SEND_INVITE          = "/send-invite/member/";
const MEMBER_DETAILS              = "/member-detail/";
const MEMBER_ORDERS               = "/member-orders";
const MEMBER_PAYMENT_REQUEST      = "/member-payment-requests";
const MEMBER_BULK                 = "/member-bulk";
const SYNC_MEMBERS                = "/job/sync-members"; // bulk sync members payments
const SYNC_MEMBER                 = "/job/sync-member";
const SYNC_MEMBER_DATA            = "/job/sync-member-data"; // sync member information

const PAYMENT_REQUEST_GROUPS_BY_MEMBER  = "/payment-request-groups/member/";
const MEMBER_GROUPS_BY_COMPANY          = "/member-groups";
const MEMBER_GROUP_DETAILS              = "/member-group/members";
const MEMBER_GROUP                      = "/member-group";
const MEMBER_GROUP_ASSIGNMENT           = "/member-group/assignment";
const MEMBER_GROUP_ASSIGNMENTS          = "/member-group/available-members";
const MEMBER_GROUP_TYPES                = "/member-group/types";
const DELETE_MEMBER_GROUP_ASSIGNMENT    = "/member-group-assignment/delete";

const MEMBER_PAYMENT_METHODS         = "/members-credit-cards";
const MEMBER_CREDIT_CARD             = "/member/credit-card";
const DEFAULT_MEMBER_CREDIT_CARD     = "/member/credit-card/default";
const MEMBER_CREDIT_CARD_STATUS      = "/member/credit-card/status";
const MEMBER_CREDIT_CARDS_CSV        = "/member-payment-method/bulk";

const LOGIN_DIRECT_DEBIT              = "/login-direct-debit";
const DIRECT_DEBITS                   = "/debit-definitions";
const DIRECT_DEBIT                    = "/debit-definition";
const LAST_EXECUTION_DIRECT_DEBIT     = "/debit-definition/recent-sync/";
const DIRECT_DEBIT_SYNC_PAYMENTS      = "/debit-definition/sync";
const DIRECT_DEBIT_PAYMENTS           = "/debit-definition/sync-result";
const EXECUTE_DEBIT                   = "/debit-definition/execute";
const DEBIT_EXECUTION_RESULT          = "/debit-definition/execution-result";
const LAST_DIRECT_DEBIT_JOBS          = "/debit-definition/last-executions/";


const ROLES                   = "/roles";
const ROLES_BY_COMPANY        = "/roles/company";
const ROLE                    = "/role";

const APPLICATION             = "/application";
const ALL_APPLICATION_BY_ROLE = "/all-role-applications";
const APPLICATION_BY_COMPANY  = "/application/company";
const APPLICATION_BY_ZONE     = "/zone-application";
const APPLICATION_BY_ROLE     = "/role-application";
const PUBLIC_MENU_APPS        = "/public-menu-applications";

const ACTIVITY                          = "/activity";
const ACTIVITIES_BY_COMPANY             = "/activity/company";
const SESSIONS_BY_ACTIVITY              = "/sessions/activity/";
const SESSIONS_SUBSCRIBERS_BY_ACTIVITY   = "/session-subscriber/activity";
const ATTENDANCES_BY_SESSION            = "/atendees/activity-session";

const APPS_AVAILABLE_BY_ROLE  = "/unassigned-role-application";
const APPS_AVAILABLE_BY_ZONE  = "/unassigned-zone-application";
const ACCESS_LEVELS           = "/application-access-level";
const TYPES_APPLICATIONS      = "/application-type";
const TAGS_APP_TYPE           = "/tags-app-type";
const TAGS_AVAILABLE          = "/tags-app-assignments";
const MENU_APP_SETTINGS       = "/menu-app-setting";
const TIP_PERCENTAGES         = "/tip-percentages";
const PAYMENT_AMOUNT_OPTIONS  = "/payment-amount-options";


const PAYMENTS_BY_COMPANY             = "/payments/company";
const PAYMENT_REQUEST_BY_COMPANY      = "/company-payment-request";
const PAYMENT_REQUEST                 = "/payment-request";
const UPDATE_PAYMENT_REQUEST          = "/update-payment-request";
const PAYMENT_REQUEST_DETAILS         = "/payment-request-detail/";
const STORES_AVAILABLE_PAYMENT_REQUEST        = "/available-stores/payment-request/";
const SYNC_PAYMENT_REQUEST            = "/payment/payment-request-sync";
const PAYMENT_DETAILS                 = "/payment-details";
const CANCEL_PAYMENT_REQUEST          = "/cancel/payment-request";
const UPDATE_PAYMENT_REQUEST_STATUS   = "/payment-request/status";
const PAYMENT_REQUEST_CSV             = "/csv-payment-requests";
const SYNC_PAYMENT_REQUEST_MANUALLY   = "/payment/payment-request-sync-manually";


const DISCOUNTS_BY_COMPANY            = "/discounts/company";
const DISCOUNT                        = "/discount";
const DISCOUNT_CONFIGURATION          = "/visibility-configuration";
const DISCOUNTS_TYPE_VALUE            = "/discounts/value-types";
const DISCOUNTS_DETAILS               = "/discount-details";
const DISCOUNT_PRODUCT_ASSIGNMENT     = "/discount-product-assignment";
const DISCOUNT_STORE_ASSIGNMENT       = "/discount-store-assignment";
const DISCOUNT_MEMBERSHIP_ASSIGNMENT  = "/discount-membership-assignment";
const UNASSIGNED_PRODUCTS_DISCOUNT    = "/unassigned-products/discount/";
const UNASSIGNED_STORES_DISCOUNT      = "/unassigned-stores/discount/";


const PAYMENT_METHOD_PROVIDERS_BY_COMPANY     = "/payment-providers/company";
const PAYMENT_METHOD_PROVIDERS_BY_COUNTRY     = "/payment-providers";
const PAYMENT_METHOD_PROVIDER                 = "/payment-provider";
const COMPANY_PROVIDERS_STATUS                = "/company-provider";
const SET_DEFAULT_PAYMENT_PROVIDER            = "/change-default-provider";
const COMPANY_DEV_API_KEYS                    = "/company-api-settings";
const COMPANY_API_KEY                         = "/company-api-setting";
const REGISTER_NOTIFICATION_SERVICE           = "/register-notification-service";
const USERS_BY_COMPANY                        = "/users/company";
const DELEGATED_USER                          = "/delegated-user";
const PERMISSION_SET                          = "/permissions-set";
const PERMISSION_SET_AVAILABLE_BY_USER        = "/permissions-set/user/";
const EVENT_NOTIFICATIONS                     = "/events-notifications";
const EVENT_NOTIFICATIONS_AVAILABLE           = "/events-notifications/user/";


const INTEGRATIONS_BY_COMPANY           = "/integrations/company";
const INTEGRATION                       = "/integration";
const UPDATE_INTEGRATION                = "/update-integrations";
const INTEGRATION_OBJECTS               = "/integration-object";
const INTEGRATION_ACTIONS_BY_OBJECT     = "/integration-actions/object/";
const INTEGRATION_SETTINGS              = "/integration-configuration/integration/";
const HTTP_METHODS                      = "/http-methods";


const MEMBER_AUTH_SOURCES               = "/member-auth-sources";
const COMPANY_MEMBER_CONFIGURATIONS     = "/company-member-configurations";
const MEMBER_INTEGRATIONS               = "/member-integrations/company";
const MEMBER_AUTH_CONFIGURATION         = "/member-auth-configuration";
const UPDATE_MEMBER_AUTH_CONFIGURATION  = "/update-member-auth-configuration";
const DEFAULT_MEMBER_AUTH_SOURCE        = "/default-member-auth-source";


const PAYMENT_REQUEST_TEMPLATE          = "/payment-template";
const UPDATE_PAYMENT_TEMPLATE           = "/update-payment-template";
const UPDATE_PAYMENT_TEMPLATE_INFO      = "/update-template-information";
const TERMS_TEMPLATE_CONFIGURATION      = "/terms-template-configurations";
const PAYMENT_REQUEST_TEMPLATES         = "/payment-templates/company";
const SEARCH_MEMBERS_COMPANY            = "/search-member/company";
const PAYMENT_TEMPLATE_DETAILS          = "/payment-template-detail/";
const ASSIGN_MEMBER_TEMPLATE            = "/member-payment-template-assign";
const PAYMENT_TEMPLATE_ITEM             = "/payment-template-item";
const UPDATE_PAYMENT_TEMPLATE_ITEM      = "/update-template-item";
const GROUP_PAYMENT_REQUESTS            = "/group-payment-requests";
const ADD_MEMBER_PAYMENT_REQUEST        = "/member/{memberId}/payment-request";


const WALLET_ACCOUNTS                   = "/wallet-accounts";
const WALLET_ACCOUNT_DETAILS            = "/wallet-account-details";
const CASH_ACCOUNTS_BY_COMPANY          = "/bank-accounts/company";
const CASH_ACCOUNT                      = "/bank-account";
const VALIDATE_CASH_ACCOUNT             = "/bank-account/validation";
const UPDATE_CASH_ACCOUNT               = "/update-bank-account";
const CASH_ACCOUNT_DETAILS              = "/bank-account-details/";
const CASH_ACCOUNTS_AVAILABLE           = "/bank-accounts-available/";
const BANK_PROVIDERS_AVAILABLE          = "/bank-providers-available";
const WALLET_AGREEMENT                  = "/wallet-agreement";
const CANCEL_WALLET_AGREEMENT           = "/wallet-agreement/cancel";
const CURRENCIES_AVAILABLE              = "/currencies-available";
const BANK_ACCOUNT_TYPES                = "/bank-account-types";
const TRANSFER_SUMMARY_CSV              = "/transfers-summary";


const TRANSFERS_BY_COMPANY          = "/transfers/company";
const WALLET_MOVEMENT               = "/wallet-movement";
const UPDATE_TRANSFER               = "/update-transfer";
const TRANSFER_DETAILS              = "/transfer-details/";
const TRANSFER_TYPES                = "/transfer-types";


const WALLET_ACCOUNT                              = "/wallet-account/";
const BLOCK_DEPOSIT_DETAILS                       = "/block-deposit-details/";
const REVENUE_RECONCILIATION_DETAILS              = "/revenue-reconciliation-details/";
const BLOCK_DEPOSIT_REVENUE_RECONCILIATION_LIST   = "/block-deposit/revenue-reconciliations";
const ALL_REVENUE_RECONCILIATION_LIST             = "/all-revenue-reconciliations";
const UPDATE_RECONCILIATION                       = "/revenue-reconciliation";
const EXPORT_BLOCK_DEPOSIT                        = "/csv-block-deposit/";
const EXPORT_CONCILIATION                         = "/csv-revenue-reconciliation";



/** APPLICATIONS ENDPOINTS **/
const TIME_CHECKS_BY_COMPANY  = "/timecheck/company";
const TIME_CHECKS_BY_DATE     = "/timecheck/days";
const TIME_CHECK_MANUAL       = "/timecheck-manual";


/** STATUS ENDPOINTS **/
const ASSET_ZONE              = "/asset-zone";
const APPLICATION_ZONE        = "/application-zone";
const APPLICATION_ROLE        = "/application-role";
const PRODUCT_CATEGORY         = "/product-category";
const ITEM_MODIFIER_GROUP     = "/item-modifier-group";

const ROLE_STORE              = "/role-store";
const APP_CATALOG             = "/application-catalog";

const MEMBER_STATUS             = "/member/status";
const DELETE_DASHBOARD_USER     = "/dashboard-user";
const ACTIVITY_STATUS           = "/activity/status";

const STATUS         = "/status";

const PUBLISH_STORE  = "/list-store-explorer";


/** ASSIGMENT ENDPOINTS **/
const ZONE_APPLICATION_ASSIGN = "/zone-application-assign";
const ZONE_ASSET_ASSIGN       = "/zone-asset-assign";
const ROLE_APPLICATION_ASSIGN = "/role-application-assign";
const CATEGORY_PRODUCT_ASSIGN = "/category-product-assign";
const MODIFIER_ITEM_ASSIGN    = "/modifier-group-item-assign";
const APP_CATALOG_ASSIGN      = "/application-catalog-assign";
const DISCOUNT_STORE_ASSIGN       = "/discount-store-assignment";
const DISCOUNT_PRODUCT_ASSIGN     = "/discount-product-assignment";
const DISCOUNT_MEMBERSHIP_ASSIGN  = "/discount-membership-assignment";


/** ANALYTICS ENDPOINTS **/
const COMMERCE_ANALYTICS      = "/commerce-analytics/company";
const HOME_FEED_ANALYTICS     = "/home-feed-analytics";
const DEFAULTING_MEMBERS      = "/defaulting-members/company";

/** DIRECT DEBITS ENDPOINTS **/
const DIRECT_DEBITS_MEMBER_GROUPS      = "/direct-debit/member-groups";
const DIRECT_DEBITS_MEMBER_GROUP      = "/direct-debit/member-group";



module.exports = {
  bugs,
  website,
  server,
  ROWS_PER_PAGE_OPTIONS,
  ROWS_PER_PAGE_OPTIONS_DETAILS,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_DETAILS,
  DEFAULT_MEMBERSHIP_ID,
  GOOGLE_ANALYTICS_TRACKING,
  GOOGLE_API,
  BASE_URL_ACCOUNT,
  BASE_URL_CORE,
  BASE_URL_COMMERCE,
  BASE_URL_APPLICATIONS,
  VALIDATE_CREDENTIALS,
  ACCOUNT,
  COMPANY,
  USER_INFORMATION,
  CONFIRM_REGISTRATION,
  COMPANY_INFORMATION,
  COMPANY_DOCUMENTS,
  DOCUMENT_REQUIRED,
  STORES_BY_COMPANY,
  ALL_STORES_ROLE,
  STORE,
  STORES_TYPES,
  STORE_CONFIGURATION,
  STORE_DETAILS,
  CATALOG,
  CATALOGS_BY_COMPANY,
  CATALOGS_INFORMATION_BY_APP,
  CATALOGS_BY_STORE,
  CATALOGS_BY_APP,
  CATEGORY_PRODUCT_ASSIGN,
  SCHEDULES,
  SCHEDULES_DAYS,
  ASSET_BY_COMPANY,
  ASSET_AVAILABLE,
  ASSET_BY_ZONE,
  ASSET,
  ASSET_INFORMATION,
  ASSET_TECHNOLOGY,
  QR_ASSETS_BY_STORE,
  ZONES,
  ZONE,
  ZONES_BY_COMPANY,
  ZONES_BY_STORE,
  ZONES_BY_APPLICATION,
  ZONE_ASSETS,
  ASSET_ZONE,
  APPLICATION_ZONE,
  ORDERS,
  ORDERS_BY_COMPANY,
  ORDERS_BY_STORE,
  ORDER_DETAIL,
  PRODUCT,
  PRODUCTS_BY_COMPANY,
  PRODUCTS_BY_CATEGORY,
  PRODUCTS_AVAILABLE_BY_CATEGORY,
  CATEGORY,
  CATEGORIES,
  CATEGORIES_BY_COMPANY,
  CATEGORIES_BY_CATALOG,
  CATEGORIES_ASSIGNED_CATALOG,
  CATEGORIES_BY_PRODUCT,
  CATEGORY_CATALOG_ORDER,
  CATALOGS_BY_CATEGORY,
  PRICEBOOK,
  PRICEBOOKS,
  TIME_CHECKS_BY_COMPANY,
  TIME_CHECKS_BY_DATE,
  TIME_CHECK_MANUAL,
  MEMBERS_BY_COMPANY,
  MEMBERS_OWNERS_BY_COMPANY,
  MEMBER,
  MEMBER_SEND_INVITE,
  ROLES,
  ROLES_BY_COMPANY,
  ROLE,
  APPLICATION,
  APPLICATION_BY_COMPANY,
  ALL_APPLICATION_BY_ROLE,
  APPLICATION_BY_ZONE,
  APPS_AVAILABLE_BY_ZONE,
  PAYMENTS_BY_COMPANY,
  COUNTRIES,
  CITIES_BY_COUNTRY,
  ACCESS_LEVELS,
  TYPES_APPLICATIONS,
  TAGS_APP_TYPE,
  TAGS_AVAILABLE,
  MENU_APP_SETTINGS,
  ZONE_APPLICATION_ASSIGN,
  ZONE_ASSET_ASSIGN,
  ROLE_APPLICATION_ASSIGN,
  APPLICATION_BY_ROLE,
  APPS_AVAILABLE_BY_ROLE,
  APPLICATION_ROLE,
  ACTIVITY,
  ACTIVITIES_BY_COMPANY,
  SESSIONS_BY_ACTIVITY,
  SESSIONS_SUBSCRIBERS_BY_ACTIVITY,
  ATTENDANCES_BY_SESSION,
  MEMBER_STATUS,
  ACTIVITY_STATUS,
  ZONE_ASSETS_APPS,
  STORE_IMAGES,
  PRODUCT_CATEGORY,
  MODIFIER_GROUP,
  MODIFIERS_GROUPS_BY_COMPANY,
  MODIFIER_GROUP_INFORMATION,
  MODIFIER_PRODUCT_ASSIGNMENTS,
  MODIFIER_ITEM_ASSIGN,
  ITEM_MODIFIER_GROUP,
  MODIFIER_ITEM_ASSIGNMENTS,
  ROLE_STORE,
  APP_CATALOG,
  SCHEDULES_STORE,
  SCHEDULES_CATALOG,
  APP_CATALOG_ASSIGN,
  STATUS,
  COMMERCE_ANALYTICS,
  PAYMENT_METHOD_PROVIDER,
  PAYMENT_METHOD_PROVIDERS_BY_COUNTRY,
  PAYMENT_METHOD_PROVIDERS_BY_COMPANY,
  PUBLISH_STORE,
  SUBMIT_VERIFICATION_REQUEST,
  PUBLIC_MENU_APPS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REQUEST_VERIFICATION,
  EMAIL_VERIFICATION,
  CHANGE_EMAIL,
  UPDATE_ORDER_STATUS,
  UPDATE_EXPLORER_MODE,
  COMPANY_PROVIDERS_STATUS,
  SET_DEFAULT_PAYMENT_PROVIDER,
  TIP_PERCENTAGES,
  COMPANY_DEV_API_KEYS,
  COMPANY_API_KEY,
  DISCOUNTS_BY_COMPANY,
  DISCOUNT,
  DISCOUNTS_TYPE_VALUE,
  DISCOUNTS_DETAILS,
  DISCOUNT_MEMBERSHIP_ASSIGNMENT,
  DISCOUNT_PRODUCT_ASSIGNMENT,
  DISCOUNT_STORE_ASSIGNMENT,
  UNASSIGNED_PRODUCTS_DISCOUNT,
  UNASSIGNED_STORES_DISCOUNT,
  DISCOUNT_CONFIGURATION,
  DISCOUNT_PRODUCT_ASSIGN,
  DISCOUNT_STORE_ASSIGN,
  DISCOUNT_MEMBERSHIP_ASSIGN,
  STORES_AVAILABLE,
  ZONES_AVAILABLE_BY_STORE,
  ORDER_LIST_BY_ZONE,
  REGISTER_NOTIFICATION_SERVICE,
  FIREBASE_NOTIFICATIONS,
  ZONES_GROUPS_ASSIGNMENTS,
  ZONES_GROUPS_BY_COMPANY,
  ZONES_GROUPS_BY_STORE,
  ZONE_GROUP,
  INTEGRATION,
  UPDATE_INTEGRATION,
  INTEGRATIONS_BY_COMPANY,
  INTEGRATION_ACTIONS_BY_OBJECT,
  INTEGRATION_OBJECTS,
  INTEGRATION_SETTINGS,
  HTTP_METHODS,
  PAYMENT_REQUEST_BY_COMPANY,
  PAYMENT_REQUEST,
  UPDATE_PAYMENT_REQUEST,
  MEMBER_AUTH_CONFIGURATION,
  MEMBER_AUTH_SOURCES,
  MEMBER_INTEGRATIONS,
  COMPANY_MEMBER_CONFIGURATIONS,
  STORES_AVAILABLE_PAYMENT_REQUEST,
  DEFAULT_MEMBER_AUTH_SOURCE,
  UPDATE_MEMBER_AUTH_CONFIGURATION,
  PAYMENT_REQUEST_DETAILS,
  SYNC_PAYMENT_REQUEST,
  DEFAULTING_MEMBERS,
  HOME_FEED_ANALYTICS,
  MEMBER_PAYMENT_REQUEST,
  MEMBER_ORDERS,
  MEMBER_DETAILS,
  PAYMENT_REQUEST_TEMPLATES,
  TERMS_TEMPLATE_CONFIGURATION,
  PAYMENT_REQUEST_TEMPLATE,
  SEARCH_MEMBERS_COMPANY,
  PAYMENT_TEMPLATE_DETAILS,
  ASSIGN_MEMBER_TEMPLATE,
  PAYMENT_TEMPLATE_ITEM,
  UPDATE_PAYMENT_TEMPLATE_ITEM,
  MEMBER_GROUP,
  MEMBER_GROUPS_BY_COMPANY,
  UPDATE_PAYMENT_TEMPLATE_INFO,
  UPDATE_PAYMENT_TEMPLATE,
  DELETE_DASHBOARD_USER,
  USERS_BY_COMPANY,
  DELEGATED_USER,
  PERMISSION_SET,
  PERMISSION_SET_AVAILABLE_BY_USER,
  PAYMENT_DETAILS,
  PRODUCT_TYPE,
  SYNC_ORDER,
  COMPANY_LOGO,
  ACCOUNT_LINK,
  PAYMENT_REQUEST_GROUPS_BY_MEMBER,
  CANCEL_PAYMENT_REQUEST,
  UPDATE_PAYMENT_REQUEST_STATUS,
  RESEND_CONFIRMATION_EMAIL,
  GROUP_PAYMENT_REQUESTS,
  MEMBER_BULK,
  PAYMENT_REQUEST_CSV,
  CASH_ACCOUNT,
  CASH_ACCOUNTS_BY_COMPANY,
  UPDATE_CASH_ACCOUNT,
  WALLET_MOVEMENT,
  TRANSFER_TYPES,
  TRANSFERS_BY_COMPANY,
  UPDATE_TRANSFER,
  CASH_ACCOUNT_DETAILS,
  TRANSFER_DETAILS,
  CASH_ACCOUNTS_AVAILABLE,
  BANK_PROVIDERS_AVAILABLE,
  BASE_URL_BALANCE,
  WALLET_ACCOUNT_DETAILS,
  VALIDATE_CASH_ACCOUNT,
  CANCEL_WALLET_AGREEMENT,
  WALLET_AGREEMENT,
  CURRENCIES_AVAILABLE,
  BANK_ACCOUNT_TYPES,
  PAYMENT_AMOUNT_OPTIONS,
  TRANSFER_SUMMARY_CSV,
  EVENT_NOTIFICATIONS_AVAILABLE,
  EVENT_NOTIFICATIONS,
  REVENUE_RECONCILIATION_DETAILS,
  BLOCK_DEPOSIT_REVENUE_RECONCILIATION_LIST,
  ALL_REVENUE_RECONCILIATION_LIST,
  BLOCK_DEPOSIT_DETAILS,
  UPDATE_RECONCILIATION,
  EXPORT_BLOCK_DEPOSIT,
  EXPORT_CONCILIATION,
  SYNC_PAYMENT_REQUEST_MANUALLY,
  ALL_COUNTRIES,
  REGIONS_BY_COUNTRY,
  MEMBER_PAYMENT_METHODS,
  MEMBER_CREDIT_CARD,
  DEFAULT_MEMBER_CREDIT_CARD,
  MEMBER_CREDIT_CARDS_CSV,
  MEMBER_CREDIT_CARD_STATUS,
  DIRECT_DEBITS_MEMBER_GROUPS,
  DIRECT_DEBITS_MEMBER_GROUP,
  MEMBER_GROUP_DETAILS,
  MEMBER_GROUP_TYPES,
  MEMBER_GROUP_ASSIGNMENTS,
  MEMBER_GROUP_ASSIGNMENT,
  DELETE_MEMBER_GROUP_ASSIGNMENT,
  BASE_URL_DIRECT_DEBIT,
  DIRECT_DEBIT_SYNC_PAYMENTS,
  DIRECT_DEBIT_PAYMENTS,
  DIRECT_DEBIT,
  DIRECT_DEBITS,
  EXECUTE_DEBIT,
  DEBIT_EXECUTION_RESULT,
  LAST_EXECUTION_DIRECT_DEBIT,
  BASE_URL_PAYMENT_METHOD_SERVICE,
  SYNC_MEMBERS,
  BASE_URL_WEB_SOCKET_SYNC,
  LOGIN_DIRECT_DEBIT,
  SYNC_MEMBER,
  SYNC_MEMBER_DATA,
  BASE_URL_WEB_SOCKET_DIRECT_DEBIT,
  LAST_DIRECT_DEBIT_JOBS,
  WIJMO_DISTRIBUTION_KEY,
  ADD_MEMBER_PAYMENT_REQUEST,
  WALLET_ACCOUNTS,
  WALLET_ACCOUNT
};
