import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {useTranslation} from "react-i18next";
import HeaderForm from "../../../../components/CustomComponents/HeaderForm";
import Select from "../../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import CurrencyInput from "../../../../components/CustomInput/CurrencyInput";
import {withRouter} from "react-router-dom";



function TransferFormDetails({ ...props }) {
    const { classes, component, cashAvailable, currency } = props;
    const {t} = useTranslation();


    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.headerFormContainer}>
                                <HeaderForm title={t('FORM_NEW_TRANSFER_TITLE')} description={t('FORM_NEW_TRANSFER_DESCRIPTION')} textAlign={"center"}/>
                                <p className={classes.legendTitleForm}>{t('AVAILABLE_TO_TRANSFER_TXT') + ": " + cashAvailable}</p>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.formBodyContainer}>
                                <div className={classes.selectContainer}>
                                    <Select
                                        value={component.state.account}
                                        onChange={component.changeAccountSelect}
                                        options={component.state.accountOptions}
                                        closeMenuOnSelect={true}
                                        placeholder={t('DESTINATION_ACCOUNT_TXT')}
                                        maxMenuHeight={400}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 5 })
                                        }}
                                    />
                                </div>
                                <CurrencyInput
                                    value={component.state.amountMask}
                                    onChange={component.handleCurrencyValue}
                                    labelText={t('AMOUNT_TRANSFER_TXT')}
                                    id="amountMask"
                                    name="amount"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.inputFormContainer
                                    }}
                                    currencySymbol={component.state.currency}
                                />
                                <CustomInput
                                    value={component.state.note}
                                    onChange={component.handleChangeValues}
                                    labelText={t('OPTIONAL_NOTE_TXT')}
                                    id="note"
                                    name="note"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.inputFormContainer
                                    }}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}


TransferFormDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    cashAvailable: PropTypes.string,
    component: PropTypes.object,
};

export default withRouter(withStyles(dashboardStyle)(TransferFormDetails));
