/* Created by Marlon Reyes on July 25, 2019 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import { withRouter } from "react-router-dom";
import {
    getData,
    getStatusValue,
    isSuccessfulRequest,
    nullController,
    removeData,
    saveData,
} from "../../../utils/helpersFunctions";
import {
    ACTIVATE,
    ACTIVATE_STATUS,
    DEACTIVATE,
    DEACTIVATE_STATUS,
    ENTITY_DISCOUNT,
    NOTIFICATION_DURATION,
    RULE_COMPANIES,
    RULE_PRODUCT,
    RULE_STORE,
} from "../../../variables/coreConstants";
import Loading from "../../../components/Loading/Loading";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import { validateSession } from "../../../services/api";
import { statusText } from "../../../utils/statusHelpers";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Snackbar from "../../../components/Snackbar/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    getDiscountDetails,
    updateDiscountConfiguration,
} from "../../../services/discountService";
import { statusController } from "../../../services/statusService";
import discountDetail from "../../../models/DiscountModel";
import DiscountProductDetails from "./DiscountProductDetails";
import DiscountStoreDetails from "./DiscountStoreDetails";
import DiscountCompanyDetails from "./DiscountCompanyDetails";

class QuickActionsDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discount: getData("discount"),
            companyData: [],
            storeData: [],
            productData: [],
            openStore: false,
            openCompany: false,
            openProduct: false,
            isLoading: false,
            isRequesting: false,
            disableSwitch: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.onCompleteRequest = this.onCompleteRequest.bind(this);
    }

    componentDidMount() {
        if (getData("discount")) {
            this.setState({ isLoading: true });
            this.request();
        } else {
            this.props.history.push("discounts");
        }
    }

    componentWillUnmount() {
        removeData("discount");
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true });
                    this.request();
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    onCompleteRequest(type) {
        if (type) {
            this.setState({ isLoading: true });
            this.request();
        }
    }

    goToDiscounts = () => {
        this.props.history.push("discounts");
    };

    changeStatus = (statusValue) => {
        let status = null;
        let action = null;

        if (statusText[statusValue] === "STATUS_INACTIVE") {
            status = ACTIVATE_STATUS;
            action = ACTIVATE;
        } else if (statusText[statusValue] === "STATUS_ACTIVE") {
            status = DEACTIVATE_STATUS;
            action = DEACTIVATE;
        }

        let dataParameters = {
            entity: ENTITY_DISCOUNT,
            uuid: this.state.discount.uuid,
            status: status,
        };

        this.setState({ isRequesting: true, disableSwitch: true });
        statusController(action, null, null, dataParameters, this, true);
    };

    changeRule = (value, rule) => {
        let optionValue = null;

        if (rule === RULE_STORE) {
            optionValue = "ALL_STORES";
        } else if (rule === RULE_PRODUCT) {
            optionValue = "ALL_PRODUCTS";
        } else if (rule === RULE_COMPANIES) {
            optionValue = "SPECIFIC_COMPANIES";
        }

        let dataParameters = {
            discountUuid: nullController(this.state.discount, "uuid"),
            discountVisibilityOption: optionValue,
            discountVisibilityValue: !value,
        };

        this.setState({ isRequesting: true, disableSwitch: true });
        updateDiscountConfiguration(dataParameters)
            .then((response) => {
                this.setState({ isRequesting: false });
                if (isSuccessfulRequest(response)) {
                    this.showNotification(
                        response.data.message,
                        "primary",
                        NotificationSuccessIcon,
                        true
                    );
                } else {
                    this.showNotification(
                        response.data.message,
                        "danger",
                        NotificationErrorIcon,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({ isRequesting: false, disableSwitch: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };

    request() {
        let dataParameters = {
            discountId: nullController(this.state.discount, "uuid"),
        };

        getDiscountDetails(dataParameters)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    saveData("discount", response.data.discount);
                    discountDetail.setDiscountDetails(response.data.discount);

                    this.setState({
                        discount: response.data.discount,
                        productData: response.data.products,
                        storeData: response.data.stores,
                        companyData: response.data.companies,
                        isLoading: false,
                        isRequesting: false,
                        disableSwitch: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        isRequesting: false,
                        disableSwitch: false,
                    });
                    this.showNotification(
                        response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    isRequesting: false,
                    disableSwitch: false,
                });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            discount,
            isLoading,
            storeData,
            productData,
            companyData,
            isRequesting,
            disableSwitch,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <Backdrop className={classes.backdrop} open={isRequesting}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {nullController(discount, "name")}
                            </h3>
                            <Muted>
                                {nullController(discount, "description")}
                            </Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                {t("EDIT_TEXT")}
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToDiscounts}
                            >
                                {t("BTN_GO_DISCOUNTS")}
                            </CustomButton>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <p className={classes.settingsText}>
                                                {t("TH_APPLY_STORE")}
                                            </p>
                                            <FormControlLabel
                                                className={
                                                    classes.publishSwitch
                                                }
                                                control={
                                                    <CustomSwitch
                                                        className={
                                                            classes.switchBase
                                                        }
                                                        size="small"
                                                        disabled={disableSwitch}
                                                        onChange={() =>
                                                            this.changeRule(
                                                                discount.applyAllStores,
                                                                RULE_STORE
                                                            )
                                                        }
                                                        checked={getStatusValue(
                                                            discount,
                                                            "applyAllStores"
                                                        )}
                                                    />
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <p className={classes.settingsText}>
                                                {t("TH_APPLY_PRODUCT")}
                                            </p>
                                            <FormControlLabel
                                                className={
                                                    classes.publishSwitch
                                                }
                                                control={
                                                    <CustomSwitch
                                                        className={
                                                            classes.switchBase
                                                        }
                                                        size="small"
                                                        disabled={disableSwitch}
                                                        onChange={() =>
                                                            this.changeRule(
                                                                discount.applyAllProducts,
                                                                RULE_PRODUCT
                                                            )
                                                        }
                                                        checked={getStatusValue(
                                                            discount,
                                                            "applyAllProducts"
                                                        )}
                                                    />
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <p className={classes.settingsText}>
                                                {t("TH_APPLY_COMPANIES")}
                                            </p>
                                            <FormControlLabel
                                                className={
                                                    classes.publishSwitch
                                                }
                                                control={
                                                    <CustomSwitch
                                                        className={
                                                            classes.switchBase
                                                        }
                                                        size="small"
                                                        disabled={disableSwitch}
                                                        onChange={() =>
                                                            this.changeRule(
                                                                discount.applyOnlyToCompany,
                                                                RULE_COMPANIES
                                                            )
                                                        }
                                                        checked={getStatusValue(
                                                            discount,
                                                            "applyOnlyToCompany"
                                                        )}
                                                    />
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={3} md={3}>
                                            <p className={classes.settingsText}>
                                                {t("ACTIVE_TEXT")}
                                            </p>
                                            <FormControlLabel
                                                className={
                                                    classes.publishSwitch
                                                }
                                                control={
                                                    <CustomSwitch
                                                        className={
                                                            classes.switchBase
                                                        }
                                                        size="small"
                                                        disabled={disableSwitch}
                                                        onChange={() =>
                                                            this.changeStatus(
                                                                discount.status
                                                            )
                                                        }
                                                        checked={getStatusValue(
                                                            discount,
                                                            "status"
                                                        )}
                                                    />
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        )}
                    </GridItem>
                </GridContainer>
                {isLoading ? null : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <DiscountStoreDetails
                                data={storeData}
                                component={this}
                            />
                        </GridItem>
                    </GridContainer>
                )}
                {isLoading ? null : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <DiscountProductDetails
                                data={productData}
                                component={this}
                            />
                        </GridItem>
                    </GridContainer>
                )}
                {isLoading ? null : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <DiscountCompanyDetails
                                data={companyData}
                                component={this}
                            />
                        </GridItem>
                    </GridContainer>
                )}
            </div>
        );
    }
}

QuickActionsDiscount.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsDiscount))
);
