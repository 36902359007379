import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Button from "../../../../components/CustomButtons/Button";
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import directDebitDetails from "../../../../models/DirectDebitModel";
import {disableComponent, isEmpty, isSuccessfulCreate} from "../../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../../variables/coreConstants";
import {executeDirectDebit, refreshPrivateServiceToken} from "../../../../services/directDebitService";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";


class ExecuteDebitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            executionJobId: null,
            retryDebitToken: 0,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    showNotification = (msj, color, icon, data) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (data != null) {
                    this.props.onCloseForm("openExecutionModal", false, data);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };


    execute = async () => {
        const  { t } = this.props;

        if (!isEmpty(directDebitDetails.id) && !isEmpty(directDebitDetails.jobId)) {
            disableComponent(this, "disableField")
            let excluded = this.props.excludedItems.map((item) => {
                return item['id'];
            });

            let dataParameters = {
                "debitDefinitionId": directDebitDetails.id,
                "jobId": directDebitDetails.jobId,
                "excludedPayments": excluded
            };

            this.setState({ retryDebitToken: this.state.retryDebitToken + 1 }, () => {
                executeDirectDebit(dataParameters).then(response => {
                    if (isSuccessfulCreate(response)) {
                        const {data} = response;

                        if (data.code != null && data.code.code === 500) {
                            this.setState({
                                executionJobId: data.id,
                                retryDebitToken: 0
                            });

                            this.showNotification(t('SUCCESS_DEBIT_EXECUTION'), "primary",
                                NotificationSuccessIcon,
                                {
                                    debitName: directDebitDetails.name,
                                    executionJobId: directDebitDetails.jobId,
                                    executionDate: data.executionDate
                                });

                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.handlerHttpError(response);
                        }
                    } else {
                        this.setState({ retryDebitToken: 0 });
                        this.handlerHttpError(response);
                    }
                }).catch(e => {
                    if (!isEmpty(e.response)) {
                        if (this.state.retryDebitToken <= 2) {
                            refreshPrivateServiceToken(e.response, this.execute, this.handlerHttpError);
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.handlerHttpError(e.response);
                        }
                    } else {
                        this.setState({ isLoading: false, isRequesting: false, disableField: false, retryDebitToken: 0 });
                        this.showNotification(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon, null);
                    }
                });
            });
        } else {
            this.showNotification(t('INVALID_REQUEST'), 'danger', NotificationErrorIcon, null);
        }
    };


    handlerHttpError = (response) => {
        const { t } = this.props;

        this.setState({ isLoading: false, isRequesting: false, disableField: false });

        if (!isEmpty(response.data)) {
            const { data } = response;

            if (!isEmpty(data.error)) {
                this.showNotification(data.error.message, "danger", NotificationFailedIcon, null);
                // If we need to validate the code error to do something
            } else {
                if (!isEmpty(data.code)) {
                    this.showNotification(data.code.message, "danger", NotificationFailedIcon, null);
                } else {
                    this.showNotification(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon, null);
                }
            }
        } else {
            this.showNotification(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon, null);
        }
    };


    render() {
        const { classes, onCloseForm, t } = this.props;

        return (
            <div className={classes.containerForm}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.bottomPaper}>
                        <p className={classes.cardThTxtGray}>
                            {t("NUMBER_OF_PAYMENT_TO_DEBIT")}
                        </p>
                        {directDebitDetails.totalPayments + " " + (directDebitDetails.totalPayments.length !== 1 ? t('PAYMENTS_TEXT') : t('PAYMENT_TXT'))}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.bottomPaper}>
                        <p className={classes.cardThTxtGray}>
                            {t("TOTAL_AMOUNT")}
                        </p>
                        {directDebitDetails.totalAmount}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} className={classes.bottomPaper}>
                        <p className={classes.cardThTxtGray}>
                            {t("DISCLAIMER")}
                        </p>
                        <p className={classes.cardGrayValueText}>
                            {t('DISCLAIMER_DESCRIPTION')}
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.centerWithTopMargin}>
                            <Button
                                color="white"
                                disabled={this.state.disableField}
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => onCloseForm("openExecutionModal", false, null)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <Button
                                color="primary"
                                className={classes.buttonSpacing}
                                onClick={() => this.execute()}
                                disabled={this.state.disableField}
                            >
                                {t('BTN_CONFIRM')}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

ExecuteDebitForm.propTypes = {
    classes: PropTypes.object,
    onCloseForm: PropTypes.func,
    excludedItems: PropTypes.array
}

export default withRouter(withStyles(formStyle)(withTranslation()(ExecuteDebitForm)));
