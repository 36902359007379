const statusColors = {
    0: 'warning',
    1: 'success',
    2: 'success',
    3: 'warning',
    4: 'success',
    5: 'danger',
    6: 'success',
    7: 'danger',
    8: 'warning',
    9: 'danger',
    10: 'success',
    11: 'danger',
    13: 'danger',
    14: 'primary',
    15: 'success',
    16: 'warning',
    17: 'warning',
    18: 'danger',
    19: 'warning',
    20: 'success',
    21: 'success',
    22: 'danger',
    23: 'warning',
    24: 'danger',
    25: 'error',
    27: 'warning',
    31: 'danger',
    32: 'success',
    33: 'danger',
    34: 'warning',
    35: 'danger',
    36: 'success',
    37: 'warning',
    38: 'warning',
    39: 'cyanBlue',
    40: 'warning',
    41: 'warning',
    42: 'danger',
    43: 'success',
    44: 'cyanBlue',
    45: 'danger',
    46: 'cyanBlue',
    47: 'success',
    48: 'warning',
    50: 'grayishPink',
    53: 'cyanBlue',
    54: 'grayishPink',
    ready_to_pay: 'green',
    sync_completed: 'green',
    completed: 'green',
    excluded: 'black',
    sync_failed: 'black',
    declined: 'black',
    provider_failed: 'black',
    confirmation_failed: 'black',
    pending_authentication: 'black',
    payment_tried: 'black',
    error: 'black',
    service_error: 'black',
    in_progress: 'black',
    pending: 'black'
};
const statusTextColors = {
    0: 'statusWarning',
    1: 'statusSuccess',
    2: 'statusSuccess',
    3: 'statusWarning',
    4: 'statusSuccess',
    5: 'statusDanger',
    6: 'statusSuccess',
    7: 'statusDanger',
    8: 'statusWarning',
    9: 'statusDanger',
    10: 'statusSuccess',
    11: 'statusSuccess',
    13: 'statusDanger',
    16: 'statusWarning',
};

const statusText = {
    0: 'STATUS_INACTIVE',
    1: 'STATUS_ACTIVE',
    2: 'STATUS_DELIVERED',
    3: 'STATUS_PENDING',
    4: 'STATUS_PAID',
    5: 'STATUS_NOT_PAID',
    6: 'STATUS_ASSIGNED',
    7: 'STATUS_CANCELLED',
    8: 'STATUS_UNASSIGNED',
    9: 'STATUS_REVOKED',
    10: 'STATUS_SUCCESS',
    11: 'STATUS_DECLINED',
    13: 'STATUS_FAILED',
    14: 'STATUS_IN_PROGRESS',
    15: 'STATUS_COMPLETED',
    16: 'STATUS_NOT_COMPLETED',
    17: 'STATUS_UNSPECIFIED',
    18: 'STATUS_NOT_SENT',
    19: 'STATUS_AWAITING_APPROVAL',
    20: 'STATUS_APPROVED',
    21: 'STATUS_COMPLETED',
    22: 'STATUS_NOT_COMPLETED',
    23: 'STATUS_AWAITING_APPROVAL',
    24: 'STATUS_REJECTED',
    25: 'STATUS_SYNC_FAILED',
    27: 'STATUS_TRANSFERRED',
    31: 'STATUS_DEFAULTING',
    32: 'STATUS_UP_TO_DATE',
    33: 'STATUS_EXPIRED',
    34: 'STATUS_RETRY_FAILED',
    35: 'STATUS_EXTERNAL_FAILED',
    36: 'STATUS_EXTERNAL_PAID',
    37: 'STATUS_TRANSFERRED',
    38: 'STATUS_NOT_ADDED',
    39: 'STATUS_ADDED',
    40: 'STATUS_PROCESSING',
    41: 'STATUS_NOT_SENT',
    42: 'STATUS_BLOCKED',
    43: 'STATUS_PAID_PARTIALLY',
    44: 'STATUS_PENDING_AUTH',
    45: 'STATUS_3DS_ERROR',
    46: 'STATUS_PAID_PENDING_SYNC',
    47: 'STATUS_SETTLED',
    48: 'STATUS_NOT_SETTLED',
    50: 'STATUS_REFUNDED',
    53: 'STATUS_PENDING_VOID',
    54: 'STATUS_VOIDED',
};

const formTitles = {
    0: 'FORM_NEW_TEXT',
    1: 'FORM_EDIT_TEXT',
};

const debitStatus = {
    ready_to_pay: 'READY_TO_PAY',
    completed: 'DEBIT_COMPLETED',
    excluded: 'EXCLUDED',
    pending: 'STATUS_PENDING',
    in_progress: 'STATUS_IN_PROGRESS',
    error: 'DEBIT_ERROR',
    payment_tried: 'DEBIT_PAYMENT_TRIED',
    sync_completed: 'SYNC_COMPLETED',
    sync_failed: 'SYNC_FAILED',
    active: 'STATUS_ACTIVE',
    expired: 'STATUS_EXPIRED',
    inactive: 'STATUS_INACTIVE',
    declined: 'STATUS_DECLINED',
    provider_failed: 'STATUS_EXTERNAL_FAILED',
    pending_authentication: 'STATUS_PENDING_AUTH',
    confirmation_failed: 'STATUS_3DS_CONFIRMATION_FAILED',
    service_error: 'STATUS_SERVICE_ERROR',
    undefined: '-',
};


module.exports = {
    statusColors,
    statusTextColors,
    statusText,
    formTitles,
    debitStatus
};