import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import ExtIdIcon from "@material-ui/icons/Fingerprint";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";

// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent, enableComponent, isEmpty, isSuccessfulCreate,
    isSuccessfulRequest, selectValidation,
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {addMemberGroup, editMemberGroup, getMemberGroupTypes} from "../../../services/memberService";
import memberDetails from "../../../models/MemberModel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";


class MemberGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: memberDetails.memberGroup.name,
            externalId: memberDetails.memberGroup.externalId,
            type: memberDetails.memberGroup.type,
            typeOptions: [],
            color: 'primary',
            notificationMessage: '',
            icon: NotificationSuccessIcon,
            notification: false,
            disableField: false,
        };
        this.saveGroup = this.saveGroup.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    componentDidMount() {
        this.initRequest();
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData() {
        this.setState({
            name: '',
            externalId: '',
        });
    }

    changeTypeSelect = (type) => {
        this.setState({ type });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh !== null)
                    this.props.closeForm("openGroup", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    saveGroup() {
        const { t } = this.props;
        const { name, externalId, type } = this.state;

        if (name === "" || selectValidation(type)) {
            this.showNotification(t('MEMBER_GROUP_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let dataParameters = {
                "name": name,
                "externalId": isEmpty(externalId) ? null : externalId,
                "groupType": {
                    "uuid": type.value
                }
            };

            disableComponent(this, "disableField");
            addMemberGroup(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if(error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }


    initRequest() {
        disableComponent(this, "disableField");
        getMemberGroupTypes()
            .then((res) => {
                let values = [];
                values = res.data.map( item => {
                    return { value: item.uuid , label: item.name };
                });

                this.setState({ typeOptions: values }, () => {
                    enableComponent(this, "disableField");
                });
            })
            .catch((err) => {
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                }
            });
    }


    updateGroup() {
        const { t } = this.props;
        const { name, externalId, type } = this.state;

        if (name === "" || selectValidation(type)) {
            this.showNotification(t('MEMBER_GROUP_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let dataParameters = {
                "uuid": memberDetails.memberGroup.uuid,
                "name": name,
                "externalId": isEmpty(externalId) ? null : externalId,
                "groupType": {
                    "uuid": type.value
                }
            };


            disableComponent(this, "disableFiled");
            editMemberGroup(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableFiled");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableFiled");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history)
                });
        }
    }


    render() {
        const { classes, t } = this.props;

        const SaveButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveGroup()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        const UpdateButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateGroup()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExtIdIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText={t('EXTERNAL_ID_TEXT')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExtIdIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="type" className={classes.selectTitle}>
                                {t('TYPE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.type}
                                onChange={this.changeTypeSelect}
                                isDisabled={this.state.disableField}
                                isLoading={this.state.disableField}
                                options={this.state.typeOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openGroup", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateButton/> : <SaveButton/>}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

MemberGroupForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(MemberGroupForm)));
