/** Create by Marlon Reyes 24/09/2019 **/

import {
    blackColor,
    dangerColor,
    defaultFont,
    grayColor, hexToRgb,
    primaryColor, steelGray, successColor, warningColor,
    whiteColor
} from "../material-dashboard-react";


const formStyle = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    colorIcon:{
        color: grayColor[0]
    },
    formControl: {
        minWidth: "100%",
    },
    datePickerControl: {
        minWidth: "100%",
        marginLeft: "15px",
    },
    selectControl: {
        marginTop: 0,
    },
    selectControlMax: {
        marginTop: 20,
        marginBottom: 40,
    },
    selectControlPayment: {
        marginTop: 20,
        marginBottom: 25,
    },
    dialogBody:{
        padding: "8px 5px",
    },
    formContainer: {
        marginBottom: 1,
        backgroundColor: "transparent"
    },
    formDirectDebit: {
        marginBottom: 1,
        backgroundColor: "transparent",
        minHeight: "480px"
    },
    scheduleContainer:{
        margin: "4px",
    },
    scheduleContainerFilter:{
        margin: "4px",
        minHeight: "55vh"
    },
    itemsGroupContainer:{
        marginTop: "20px",
    },
    formActions:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
    },
    buttonSpacing:{
        marginLeft: 5,
        marginRight:5,
    },
    footerForm:{
        position: "absolute",
        bottom: "20px",
        right: "20px"
    },
    cancelButton:{
        border: "1px solid #A2A2A2"
    },
    rightPaper:{
        float: "right",
        marginTop: 30,
    },
    center:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    bottomPaper: {
        marginBottom: 15
    },
    centerWithTopMargin:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        marginTop: 15,
    },
    centerColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    centerButtonsColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        marginTop: 20
    },
    hidden: {
        display: "none"
    },
    centerPaper:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer:{
        marginTop: 60,
    },
    subtitleContent:{
        marginBottom: 25,
    },
    selectTitle:{
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "11px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    timerContainer:{
        marginTop: 6,
    },
    defaultTimeContainer:{
        marginTop: 30,
    },
    addScheduleButton: {
        marginTop: 1,
    },
    iconButtonGreen: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: successColor[2]
    },
    iconButtonRed: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: dangerColor[0]
    },
    iconButtonBlue: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: primaryColor[0]
    },
    separator:{
        marginLeft: "20px",
        marginRight: "20px",
    },
    switchBase: {
        color: primaryColor[0],
        "&$checked": {
            color: primaryColor[0],
        },
        "&$checked + $track": {
            backgroundColor: primaryColor[0],
        },
    },
    leftPaper:{
        float: "left",
        marginTop: 10,
    },
    rightContainer:{
        float: "right",
        marginTop: 10,
    },
    checked: {},
    track: {},


    btnImage: {
        position: "relative",
        border: "1px dashed gray",
        "&:hover, &$focusVisible": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.4,
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: grayColor[2],
    },
    imageSrcContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 40%",
    },
    imageBackdrop: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: grayColor[9],
        opacity: 0.25,
        transition: "opacity",
    },
    imageTitle: {
        position: "relative",
        padding: "2px 4px 7px",
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: grayColor[2],
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
    },
    descriptionTitle: {
        color: grayColor[2],
        fontWeight: "400",
        lineHeight: "1.42857",

    },
    imageSelector: {
        height: 300,
        width: 500,
    },
    imageEditorContainer: {
        marginBottom: 1,
        alignContent: "center",
    },
    selectTypeOptionContainer: {
        // display: "flex",
        // backgroundColor: "red",
        marginBottom: 1,
        // justifyContent: "center",
        // alignContent: "center",
        // alignItems: "center",
    },
    inputFile: {
        marginBottom: 15,
    },
    title2: {
        marginLeft: 10,
        fontSize: 17,
        fontWeight: 500,
        color: grayColor[7]
    },
    formBodyTitles: {
        marginTop: 15,
        marginBottom: 5,
        fontSize: 17,
        fontWeight: 500,
        color: grayColor[7]
    },
    titleNoMargin: {
        fontSize: 17,
        fontWeight: 500,
        color: grayColor[7]
    },
    surchargeContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
        width: 100,
    },
    modifierItemNameContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
    },
    itemNameContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
    },
    itemSelect: {
      marginTop: 10
    },
    buttonFit: {
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
    },
    objectColorGrey: {
        color: grayColor[2],
        width: "17px",
        height: "17px",
        marginRight: 10,
    },
    objectColorWhite: {
        color: whiteColor,
        width: "17px",
        height: "17px",
        marginRight: 10,
    },
    draggableObject:{

    },
    buttonLink: {
        ...defaultFont,
        cursor: "pointer",
        position: "relative",
        display: "block",
        padding: "10px 15px",
        marginLeft: 5,
        marginRight:5,
        textDecoration: "none",
        transition: "all .3s",
        border: "0 !important",
        borderRadius: "3px",
        textAlign: "center",
        backgroundColor: primaryColor[0],
        color: whiteColor,
        minHeight: "auto",
        width: "80px",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "12px",
        lineHeight: "1.42857143rem",
        "&:hover": {
            backgroundColor: primaryColor[0],
            opacity: 0.7,
            color: whiteColor
        },
        "&:visited":{
            color: whiteColor
        },
        "&:link":{
            color: whiteColor
        },
        "&:active":{
            color: whiteColor
        }
    },
    formTitle: {
        fontSize: "1.25rem",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "500",
        lineHeight: 1.6,
        letterSpacing: "0.0075em"
    },
    selectDescription: {
        marginTop: 10
    },
    selectForm: {
        marginTop: 20,
        marginBottom: 10
    },
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    status: {
        alignItems: "center",
        marginRight: 1,
        width: "auto",
    },
    copyButtonIcon: {
        width: "17px",
        height: "17px",
        color: primaryColor[0]
    },
    detailText: {
        ...defaultFont,
        fontWeight: "400",
        fontSize: "14px",
    },
    smallSwitchLabel: {
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    centerFormContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "75%",
        marginLeft: "12.5%",
        marginRight: "12.5%"
    },
    selectControlCenter: {
        width: "75%",
        marginLeft: "12%",
        marginBottom: 25,
        display: "flex",
        flexDirection: "column",
    },
    iconAvatarBlue: {
        width: "52px",
        height: "52px",
        padding: "0",
        color: primaryColor[0]
    },
    iconAvatar: {
        width: "52px",
        height: "52px",
        backgroundColor: "transparent",
        marginRight: 10
    },
    grayText: {
        color: grayColor[0],
        fontSize: "18px"
    },
    graySubText: {
        color: grayColor[0],
        fontSize: "14px",
        marginTop: "-5px !important",
        paddingTop: "0px !important"
    },
    blackTitleText: {
        fontWeight: "500",
        fontSize: "18px",
    },
    blackSmallTitleText: {
        color: blackColor,
        fontWeight: "500",
        fontSize: "15px",
    },
    graySmallTitleText: {
        color: grayColor[1],
        fontWeight: "normal",
        fontSize: "14px",
    },
    zeroMargin: {
        marginTop: 0,
    },
    blueTitle: {
        fontSize: "14px",
        color: primaryColor[0]
    },
    formIcon: {
        color: whiteColor,
        width: "37px",
        height: "37px",
        marginBottom: 5
    },
    editIcon: {
        width: "23px",
        height: "23px",
        padding: "0",
        color: primaryColor[0]
    },
    editIconDisabled: {
        width: "23px",
        height: "23px",
        padding: "0",
        color: grayColor[0]
    },
    hardLine: {
        backgroundColor: grayColor[0],
        height: 1.3,
        width: "98%",
        opacity: 0.9,
        marginRight: "1%",
        marginLeft: "1%",
        marginTop: 15,
        marginBottom: 10,
    },
    spaceContainer: {
        width: "98%",
        marginRight: "1%",
        marginLeft: "1%",
        marginBottom: 20,
    },
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    orderUserContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "50%"
    },
    orderNameContainer: {
        display: "flex",
        flexDirection: "column",
        width: "35%"
    },
    orderStatusContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "50%"
    },
    containerRight: {
        float: "right",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    orderTypeContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%"
    },
    orderTypeIcon: {
        width: "37px",
        height: "37px",
        padding: "0",
        marginRight: 10,
        color: primaryColor[0]
    },
    warningIcon: {
        width: "27px",
        height: "27px",
        color: warningColor[4],
        marginRight: 5
    },
    warningContainer: {
        marginLeft: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    },
    warningText: {
        fontSize: "14px",
        color: warningColor[0],
        fontWeight: "400"
    },
    zoneNameContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        marginTop: 5
    },
    descriptionGrayText: {
        fontSize: "14px",
        color: grayColor[0],
        fontWeight: "400"
    },
    detailTextColor: {
        fontSize: "14px",
        color: grayColor[1],
        fontWeight: "500"
    },
    discountText: {
        fontSize: "13px",
        paddingTop: 0,
        marginTop: 0,
        color: grayColor[0],
        fontWeight: "400",
        fontStyle: "italic"
    },
    modifierSmallGrayText: {
        fontSize: "11px",
        color: grayColor[0],
        fontWeight: "400",
        marginLeft: 10,
        padding: 0,
    },
    descriptionDarkBig: {
        fontSize: "16px",
        fontWeight: "500",
        color: grayColor[21]
    },
    totalTextContainer: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        backgroundColor: "yellow"
    },
    totalView: {
        display: "flex",
        float: "right",
        width: "50%",
        flexDirection: "row",
        justifyContent: "flex-end",

    },
    cardFooterView: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "red",
        marginTop: 20,
        width: "100%",
        left: 0,
        right: 0,
        bottom: 0,
    },
    detailItemsContainer: {
        display: "flex",
        flexWrap:"wrap",
        flexDirection:"row",
    },
    orderDetailTitleContainer: {
        display: "flex",
        flexDirection: "row",
        left: 0,
        right: 0,
        top: 0
    },
    discountContainer: {
        display: "flex",
        flexDirection: "column",
        left: 0,
        right: 0,
        top: 0,
        marginBottom: 10
    },
    orderDetailCard: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: 15
    },
    priceView: {
        float: "right",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    item: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    quantityView: {
        width: "3%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    productView: {
        width: "65%",
        marginRight: "7%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: "3%",
        marginBottom: 10
    },
    formOrderDetailContainer: {
        marginBottom: 1,
        width: "90%",
        marginLeft: "5%",
        backgroundColor: "transparent"
    },
    checkBoxColor: {
        color: primaryColor[0]
    },
    detailTextView: {
        width: "75%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10
    },
    paymentLabelText: {
        fontSize: "14px",
        fontWeight: "500",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    paymentValueText: {
        color: grayColor[1],
        fontSize: "14px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    paymentValueTextLink: {
        color: primaryColor[0],
        fontSize: "14px !important",
        marginTop: 0,
        marginBottom: 0,
        textDecorationLine: "underline",
        padding: 0
    },
    disabledTextArea: {
        width: "100%",

    },
    negativeMargin: {
        marginTop: "-30px"
    },
    suffixWarning: {
        fontSize: "10px",
        color: warningColor[0],
        fontWeight: "400"
    },
    orderDetailFooterText: {
        fontSize: "13px",
        color: grayColor[7],
        fontWeight: "500",
        marginTop: 10,
        marginBottom: 10
    },

    buttonUploadFile: {
        flexDirection: "row",
        textTransform: "none !important",
        marginRight: "15px",
        minHeight: "auto",
        minWidth: "auto",
        width: "auto",
        maxWidth: "200px",
        color: whiteColor,
        borderRadius: "3px",
        border: "1px solid #A2A2A2",
        position: "relative",
        padding: "12px 30px",
        margin: ".3125rem 1px",
        fontSize: "12px",
        fontWeight: "400",
        letterSpacing: "0",
        willChange: "box-shadow, transform",
        transition:
            "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        textColor: whiteColor,
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "pointer",
        "& .fab,& .fas,& .far,& .fal, &.material-icons": {
            position: "relative",
            display: "inline-block",
            top: "0",
            marginTop: "-1em",
            marginBottom: "-1em",
            fontSize: "1.1rem",
            marginRight: "4px",
            verticalAlign: "middle"
        },
        "& svg": {
            position: "relative",
            display: "inline-block",
            top: "0",
            width: "18px",
            height: "18px",
            marginRight: "4px",
            verticalAlign: "middle"
        },
        "&$justIcon": {
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                marginTop: "0px",
                position: "absolute",
                width: "100%",
                transform: "none",
                left: "0px",
                top: "0px",
                height: "100%",
                lineHeight: "41px",
                fontSize: "20px"
            }
        },
        backgroundColor: whiteColor,
        boxShadow:
            "0 2px 2px 0 rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.14), 0 3px 1px -2px rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.2), 0 1px 5px 0 rgba(" +
            hexToRgb(grayColor[0]) +
            ", 0.12)",
        "&,&:focus,&:hover": {
            backgroundColor: whiteColor,
            color: grayColor[0]
        },
    },
    checkContainer: {
        backgroundColor: "transparent",
        marginTop: 10
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    itemIdContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
        width: 60,
    },
    groupLegend: {
        marginBottom: "0px",
    },
    radioContainer: {
        marginTop: "10px",
    },
    groupBox: {
        paddingTop: "0px",
    },
    radioButton: {
        marginBottom: "0px",
        color: grayColor[0],
        "&$checked": {
            color: primaryColor[0],
        },
        "&$checked + $track": {
            backgroundColor: primaryColor[0],
        },
    },
    formInput: {
        paddingBottom: "17px !important"
    },
    formDropDown: {
        marginTop: "15px",
        marginBottom: "15px"
    },
    multiLineInput: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "75%",
        marginLeft: "12.5%",
        marginRight: "12.5%"
    },
    datePickerForm: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "75%",
        marginLeft: "12.5%",
        marginRight: "12.5%",
        marginBottom: 20,
    },
    countrySelect: {
        minWidth: "100%",
        marginTop: "1.5px",
    },
    fieldLabel: {
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    selectContainer: {
        minWidth: "100%",
        marginBottom: "20px",
        marginTop: "15px"
    },
    textFieldPrimary: {
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#000000',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: "5px !important"
        }
    },
    textFieldError: {
        '& label.Mui-focused': {
            color: dangerColor[0],
        },
        '&:hover fieldset': {
            color: dangerColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: dangerColor[0],
        },
        '&.Mui-focused fieldset': {
            borderColor: dangerColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: dangerColor[0],
            },
            '&.Mui-focused fieldset': {
                borderColor: dangerColor[0],
            },
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: "5px !important"
        }
    },
    dateTimePicker: {
        zIndex: 99999
    },
    cardThTxtGray: {
        color: steelGray[1],
        fontSize: "15px",
        lineHeight: "15px",
        fontWeight: "500",
        padding: 0,
        marginTop: 0,
        marginBottom: 7,
    },
    cardGrayValueText: {
        color: blackColor,
        fontSize: "14px",
        lineHeight: "15px",
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
    },
    balanceCardTotalText: {
        color: grayColor[2],
        fontSize: "14px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    balanceCardSubTitle: {
        color: grayColor[1],
        fontSize: "13px",
        lineHeight: "18px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    balanceCardTitle: {
        color: grayColor[19],
        fontSize: "14px",
        fontWeight: "bold",
        padding: 0,
        marginTop: 0,
        marginBottom: 5,
    },
    btnCardRowTxtBlue: {
        color: primaryColor[4],
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "14px",
        textAlign: "left",
        padding: "0px !important",
        marginTop: "-12px !important",
        marginBottom: "0px !important",
        minWidth: "auto !important"
    },
    iconSimpleBtn: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: primaryColor[0]
    },
    checkBox: {
        width: "25px",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    checkBoxChecked: {
        color: primaryColor[0] + "!important"
    },
    checkboxLabel: {
        marginTop: 20,
        '& span.MuiTypography-body1': {
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: "0.875rem !important",
            lineHeight: "1.1rem !important",
        }
    }
};

export default formStyle;