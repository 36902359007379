/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
// core components
import Navbar from "../components/Navbars/Navbar.jsx";
import Sidebar from "../components/Sidebar/CustomSidebar.jsx";

import routes from "../routes.js";
import sideBarRoutes from "../utils/sideBarRoutes.js";

import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import {
    IMG_SIDEBAR,
    IMG_SIDEBAR_LOGO,
    NOTIFICATION_SOUND,
} from "../variables/resourcesConstants";
import { messaging } from "../utils/configurations";
import NewNotificationIcon from "@material-ui/icons/NotificationsActive";
import { NOTIFICATION_DURATION_LARGE } from "../variables/coreConstants";
import {
    getData,
    getUser,
    hasRouteAccess,
    isSuccessfulRequest,
    nullController,
    removeData,
    removeSession,
    saveData,
} from "../utils/helpersFunctions";
import Snackbar from "../components/Snackbar/Snackbar";
import { withTranslation } from "react-i18next";
import { registerNotificationClient } from "../services/accountService";
import { FIREBASE_NOTIFICATIONS } from "../variables/apiConstants";

const OBJECT_NAMES = require("../variables/objectConstants");

let ps;
const audio = new Audio(NOTIFICATION_SOUND);

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        exact={true}
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    >
                        <prop.component routeProps={prop} />
                    </Route>
                );
            }
            return null;
        })}
        <Redirect from="/admin" to="/admin/home" />
    </Switch>
);

class Dashboard extends React.Component {
    state = {
        image: IMG_SIDEBAR,
        hasImage: true,
        miniActive: false,
        notificationData: "",
        fixedClasses: "dropdown show",
        mobileOpen: false,
        sidebarOptions: [],
        notificationMessage: "",
        notification: false,
        action: null,
        color: "primary",
        icon: NewNotificationIcon,
    };

    mainPanel = React.createRef();

    handleImageClick = (IMG_SIDEBAR) => {
        this.setState({ image: IMG_SIDEBAR });
    };

    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({ fixedClasses: "dropdown show" });
        } else {
            this.setState({ fixedClasses: "dropdown" });
        }
    };

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    getRoute() {
        return window.location.pathname !== "/admin/orders-kanban";
    }

    getValidRoute() {
        return (
            window.location.pathname === "/admin/orders-kanban" ||
            window.location.pathname === "/admin/home"
        );
    }

    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };

    handlePlayNotification = () => {
        try {
            audio.loop = true;
            audio.play();
        } catch (e) {}
    };

    handleStopNotification = () => {
        try {
            audio.pause();
        } catch (e) {}
    };

    getSidebarTabAccess = () => {
        let routes = [];

        try {
            let userTabs = getUser().userRoutes;
            let allTabs = getUser().allRoutes;

            allTabs.forEach((item) => {
                let index = userTabs.findIndex((x) => x.uuid === item.uuid);

                if (index !== -1) {
                    let indexRoute = sideBarRoutes.findIndex(
                        (route) => route.path === item.routeUrl
                    );

                    if (indexRoute !== -1) {
                        routes.push(sideBarRoutes[indexRoute]);
                    }
                }
            });
        } catch (e) {}

        if (routes.length === 0) {
            removeSession();
            this.props.history.replace("/sign-in");
        }

        return routes;
    };

    isRouteTab = (routeName) => {
        return (
            routes.findIndex(
                (x) => x.layout + x.path === routeName && x.isTab
            ) !== -1
        );
    };

    getRouteObject = (path) => {
        let route = null;

        try {
            let index = routes.findIndex((x) => x.layout + x.path === path);
            route = index !== -1 ? routes[index] : null;
        } catch (e) {}

        return route;
    };

    componentDidMount() {
        this.setState({ sidebarOptions: this.getSidebarTabAccess() });

        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.mainPanel.current);
        }

        if (messaging !== null) {
            this.requestPermission();

            if (this.getRoute()) {
                this.globalNotification = messaging.onMessage((payload) => {
                    if (
                        payload.data &&
                        payload.data.zoneId &&
                        payload.data.storeId
                    ) {
                        this.handlePlayNotification();
                        this.setState({ notificationData: payload.data });
                        this.showNotification(
                            nullController(payload.data, "title"),
                            "primary",
                            NewNotificationIcon,
                            NOTIFICATION_DURATION_LARGE,
                            true
                        );
                    }
                });
            }
        }

        window.addEventListener("resize", this.resizeFunction);
    }

    componentDidUpdate(e) {
        if (
            hasRouteAccess(e.history.location.pathname) ||
            !this.isRouteTab(e.history.location.pathname)
        ) {
            if (e.history.location.pathname !== e.location.pathname) {
                if (e.location.pathname === "/admin/orders-kanban") {
                    if (messaging !== null) {
                        this.globalNotification = messaging.onMessage(
                            (payload) => {
                                if (
                                    payload.data &&
                                    payload.data.zoneId &&
                                    payload.data.storeId
                                ) {
                                    this.handlePlayNotification();
                                    this.setState({
                                        notificationData: payload.data,
                                    });
                                    this.showNotification(
                                        nullController(payload.data, "title"),
                                        "primary",
                                        NewNotificationIcon,
                                        NOTIFICATION_DURATION_LARGE,
                                        true
                                    );
                                }
                            }
                        );
                    }
                }

                this.mainPanel.current.scrollTop = 0;
                if (this.state.mobileOpen) {
                    this.setState({ mobileOpen: false });
                }
            }

            let newRoute = e.history.location.pathname;
            // let route = this.getRouteObject(newRoute);

            if (
                newRoute !== "/admin/cash" &&
                !newRoute.includes("/transfer-form/new") &&
                newRoute !== "/admin/cash-account-form/new" &&
                newRoute !== "/admin/cash-account-form/update"
            ) {
                removeData(OBJECT_NAMES.CURRENT_CASH_TAB);
            }

            // if (route !== null) {
            //     if (route.appType !== "DirectDebit" && getData("directDebitAuth")) {
            //         removeData("directDebitAuth");
            //     }
            // }
        } else {
            this.props.history.push("/admin/not-found");
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        window.removeEventListener("resize", this.resizeFunction);
    }

    // Request current token for messaging
    requestToken = () => {
        messaging
            .getToken({ vapidKey: FIREBASE_NOTIFICATIONS })
            .then((currentToken) => {
                if (currentToken) {
                    let tk = getData("notificationTk");

                    if (tk === null || tk !== currentToken) {
                        let dataParameters = {
                            token: currentToken,
                            os: "web",
                        };

                        registerNotificationClient(dataParameters)
                            .then((response) => {
                                if (isSuccessfulRequest(response)) {
                                    saveData("notificationTk", currentToken);
                                }
                            })
                            .catch((error) => {
                                console.log("Something went wrong", error);
                            });
                    }
                } else {
                    this.requestPermission();
                }
            })
            .catch((err) => {
                console.log("Notification service error", err);
            });
    };

    // Request notification permissions
    requestPermission = () => {
        if (this.getValidRoute()) {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === "granted") {
                        this.requestToken();
                    } else {
                        console.log("Permission not provided");
                    }
                })
                .catch((err) => {
                    console.log("Error getting permissions");
                });
        }
    };

    getNeedMiniSideBar = () => {
        let value;
        if (this.props.history.location.pathname === "/admin/orders-kanban") {
            value = true;
        } else {
            value = false;
        }
        return value;
    };

    showNotification = (msj, color, icon, time, action) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
            action: action !== null,
        });

        window.setTimeout(
            function() {
                this.handleStopNotification();
                this.setState({ notification: false, notificationData: "" });
            }.bind(this),
            time
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    redirectToOrders = (data) => {
        saveData("storeSelected", {
            value: data.storeId,
            label: data.storeName,
        });
        saveData("zoneSelected", { value: data.zoneId, label: data.zoneName });
        this.props.history.push("orders-kanban");
    };

    render() {
        const { classes, t, ...rest } = this.props;

        return (
            <div className={classes.wrapper}>
                {this.getRoute() ? (
                    <Sidebar
                        routes={this.state.sidebarOptions}
                        logo={IMG_SIDEBAR_LOGO}
                        handleDrawerToggle={this.handleDrawerToggle}
                        open={this.state.mobileOpen}
                        miniActive={false}
                        {...rest}
                    />
                ) : null}
                <div
                    className={
                        this.getRoute()
                            ? classes.mainPanel
                            : classes.kanbanMainPanel
                    }
                    ref={this.mainPanel}
                >
                    <Navbar
                        routes={routes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>
                                {switchRoutes}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.kanbanContent}>
                            <div className={classes.kanbanContainer}>
                                {switchRoutes}
                            </div>
                        </div>
                    )}
                    {/*FIXED PLUGIN*/}
                </div>
                <Snackbar
                    place="tr"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    onAction={
                        this.state.action
                            ? () =>
                                  this.redirectToOrders(
                                      this.state.notificationData
                                  )
                            : undefined
                    }
                    actionText={t("GO_TO_ORDERS_TEXT")}
                    close
                />
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(Dashboard))
);
