import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {
    getUser,
    nullController,
    removeSession,
} from "../../utils/helpersFunctions";
import CardAvatar from "../../components/Card/CardAvatar";
import UserIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Muted from "../../components/Typography/Muted";
import ProfileForm from "./components/ProfileForm";
import {withRouter} from "react-router-dom";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInformation: getUser(),
            openForm: false,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    handleOpenForm = () => {
        this.setState({
            openForm: true,
        });
    };
    handleCloseForm = () => {
        this.setState({
            openForm: false,
        });
    };

    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) {
            removeSession();
            this.props.history.push("/sign-in");
        }
    }

    render() {
        const { classes, t, routeProps } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <Card profile>
                            <CardAvatar profile>
                                <UserIcon className={classes.profile} />
                            </CardAvatar>
                            <CardBody profile>
                                <div className={classes.sectionContainer}>
                                    <h6 className={classes.cardCategory}>
                                        {nullController(
                                            this.state.userInformation,
                                            "email"
                                        )}
                                    </h6>
                                    <Tooltip
                                        id="tooltip-edit"
                                        title={t("CHANGE_TEXT")}
                                        placement="right-start"
                                    >
                                        <IconButton
                                            onClick={this.handleOpenForm}
                                            className={
                                                classes.tableActionButton
                                            }
                                        >
                                            <EditIcon
                                                className={
                                                    classes.iconButtonBlue
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <h4 className={classes.cardTitle}>
                                    {nullController(
                                        this.state.userInformation,
                                        "first_name"
                                    ) +
                                        " " +
                                        nullController(
                                            this.state.userInformation,
                                            "last_name"
                                        )}
                                </h4>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t("FORM_PROFILE_TITLE")}
                            <Muted>{t("FORM_PROFILE_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <ProfileForm closeForm={this.closeForm} />
                        </DialogContent>
                    </Dialog>
                </GridContainer>
            </div>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(Profile)));
