import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "./CustomInput";


// Only rerender component if value or label change
function propsAreEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value && prevProps.labelText === nextProps.labelText && prevProps.currencySymbol === nextProps.currencySymbol;
}


function CurrencyInput({ ...props }) {
    const {
        value,
        onChange,
        labelText,
        disabled,
        formControlProps,
        id,
        name,
        type,
        autoFocus,
        currencySymbol,
    } = props;

    return (
        <CustomInput
            value={value}
            onChange={onChange}
            labelText={labelText}
            id={id}
            autoFocus={autoFocus}
            name={name}
            type={type}
            disabled={disabled}
            currencySymbol={currencySymbol}
            formControlProps={formControlProps}
            startAdornment={
                <InputAdornment position="start">
                    {currencySymbol}
                </InputAdornment>
            }
            inputProps={{
                style: { textAlign: "right" }
            }}
        />
    );
}

CurrencyInput.propTypes = {
    labelText: PropTypes.node,
    id: PropTypes.string,
    formControlProps: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    currencySymbol: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool
};

export default React.memo(CurrencyInput, propsAreEqual);
