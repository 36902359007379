import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
//import AssetIcon from "@material-ui/icons/Memory";
//import RangeIcon from "@material-ui/icons/TrackChanges";
//import IdentifierIcon from "@material-ui/icons/Fingerprint";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {ERROR_REQUEST_MESSAGE, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    getData, isSuccessfulCreate,
    selectValidation,
} from "../../../utils/helpersFunctions";
import {assign} from "../../../services/statusService";
import {BASE_URL_CORE, ZONE_APPLICATION_ASSIGN} from "../../../variables/apiConstants";
import {getAppsAvailableByZone} from "../../../services/applicationsService";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";


const animatedComponents = makeAnimated();

class AppZoneAssignmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            app: '',
            appsOptions: [],
            loadingApps: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.assignApp = this.assignApp.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    changeAppSelect = app => {
        this.setState({ app } );
    };

    cleanData() {
        this.setState({
            app: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openApp", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {
        let zone = getData("zone");
        let appsValues;

        getAppsAvailableByZone(zone.id)
            .then((res) => {
                appsValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({
                    appsOptions: appsValues,
                    loadingApps: false
                });
            })
            .catch((err) => {
                this.setState({ loadingApps: false} );

                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                }
            });
    }


    assignApp() {
        const { t } = this.props;
        if (selectValidation(this.state.app)) {
            this.showNotification(t('APP_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let zone = getData("zone");
            let appsId= this.state.app.map( item => {
                return { id: item.value };
            });

            let dataParameters = {
                "zone_id": zone.id,
                "applications": JSON.stringify(appsId)
            };

            disableComponent(this, "disableField");
            assign(BASE_URL_CORE, ZONE_APPLICATION_ASSIGN, dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    render(){
        const { classes, t } = this.props;

        const AssignAppButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.assignApp()}
                disabled={this.state.disableField}
            >
                {t('BTN_ASSIGN')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControlMax}>
                            <InputLabel htmlFor="asset" className={classes.selectTitle}>
                                {t('APPLICATIONS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.app}
                                onChange={this.changeAppSelect}
                                components={animatedComponents}
                                options={this.state.appsOptions}
                                placeholder={t('SELECT_TEXT')}
                                isMulti={true}
                                isClearable={true}
                                maxMenuHeight={100}
                                closeMenuOnSelect={false}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openApp", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <AssignAppButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

AppZoneAssignmentForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(AppZoneAssignmentForm)));