/** Create by Marlon Reyes 26/08/2019 **/

import ic_Products from "@material-ui/icons/ShoppingCart";
import ic_BarChart from "@material-ui/icons/BarChart";
import ic_Payments from "@material-ui/icons/Description";
import ic_Members from "@material-ui/icons/People"
import ic_Receipt from "@material-ui/icons/Receipt";
import ic_App from "@material-ui/icons/Apps";
// import ic_Automations from "@material-ui/icons/Apps";
import ic_Location from "@material-ui/icons/Place"
import ic_List from "@material-ui/icons/ListAlt"
import ic_Home from "@material-ui/icons/HomeOutlined";
import ic_Assets from "@material-ui/icons/Memory";
import ic_Time from "@material-ui/icons/AlarmOn";
import ic_Activity from "@material-ui/icons/AccessAlarm";
// import ic_Role from "@material-ui/icons/Face";
import ic_Category from "@material-ui/icons/ChromeReaderMode";
import ic_Discount from "@material-ui/icons/TrendingDown";
import ic_PaymentRequest from "@material-ui/icons/SwapHorizontalCircle";
import ic_Template from "@material-ui/icons/CollectionsBookmark";
import ic_Cash from "@material-ui/icons/AccountBalanceWallet";
import ic_Bank from "@material-ui/icons/AccountBalance";
import ic_Debits from "@material-ui/icons/Payment";
import ic_Groups from "@material-ui/icons/GroupAdd";
import ic_PaymentMethods from "@material-ui/icons/Payment";

// components/views for Admin layout
import DashboardPage from "../views/Dashboard/Dashboard.jsx";
import HomePage from "../views/Home/Home";
import Stores from "../views/Stores/Stores";
import Menus from "../views/Menus/Menus";
import Products from "../views/Products/Products";
import Orders from "../views/Orders/Orders";
import Payments from "../views/Payments/Payments";
import Assets from "../views/Assets/Assets";
import TimeChecks from "../views/TimeChecks/TimeChecks";
// import Members from "../views/Members/Members";
// import Roles from "../views/Roles/Roles";
import Applications from "../views/Applications/Applications";
import Activities from "../views/Activities/Activities";
import Categories from "../views/Categories/Categories";
import Discounts from "../views/Discounts/Discounts";
import ic_ViewList from "@material-ui/icons/ViewList";
import OrdersKanban from "../views/OrdersKanban/OrdersKanban";
import ic_Integrations from "@material-ui/icons/Cloud";
import PaymentRequest from "../views/Payments/PaymentRequest";
import IntegrationContainer from "../views/Integrations/IntegrationContainer";
import PaymentTemplates from "../views/Payment Template/PaymentTemplates";
import MemberContainer from "../views/Members/MemberContainer";
import Cash from "../views/Cash/Cash";
import RevenueReconciliationsScreen from "../views/RevenueReconciliation/RevenueReconciliationsScreen";
import MemberGroups from "../views/MemberGroups/MemberGroups";
import MemberPaymentMethods from "../views/Members/components/MemberPaymentMethods";
import DirectDebitsController from "../views/DirectDebit/DirectDebitsController";




// Changed by Marlon (Added appType for filter routes and don't show commerce applications)

const sideBarRoutes = [
    {
        path: "/home",
        name: "HOME_TEXT",
        icon: ic_Home,
        component: HomePage,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/dashboard",
        name: "ANALYTICS_TEXT",
        icon: ic_BarChart,
        component: DashboardPage,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/stores",
        name: "STORES_TEXT",
        icon: ic_Location,
        component: Stores,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/members",
        name: "MEMBERS_TEXT",
        icon: ic_Members,
        component: MemberContainer,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/cash",
        name: "CASH_TEXT",
        icon: ic_Bank,
        component: Cash,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/reconciliations",
        name: "REVENUE_RECONCILIATION_TXT",
        icon: ic_Cash,
        component: RevenueReconciliationsScreen,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/payment-requests",
        name: "PAYMENT_REQUEST_TEXT",
        icon: ic_PaymentRequest,
        component: PaymentRequest,
        appType: "Payment",
        layout: "/admin"
    },
    {
        path: "/payment-templates",
        name: "PAYMENT_TEMPLATE_TEXT",
        icon: ic_Template,
        component: PaymentTemplates,
        appType: "Payment",
        layout: "/admin"
    },
    {
        path: "/orders",
        name: "ORDERS_TEXT",
        icon: ic_Receipt,
        component: Orders,
        appType: "Payment",
        layout: "/admin"
    },
    {
        path: "/payments",
        name: "TRANSACTIONS_TEXT",
        icon: ic_Payments,
        component: Payments,
        appType: "Payment",
        layout: "/admin"
    },
    {
        path: "/debits",
        name: "DEBITS_TEXT",
        icon: ic_Debits,
        component: DirectDebitsController,
        appType: "DirectDebit",
        layout: "/admin"
    },
    {
        path: "/payment-methods",
        name: "PAYMENT_METHODS_TEXT",
        icon: ic_PaymentMethods,
        component: MemberPaymentMethods,
        appType: "DirectDebit",
        layout: "/admin"
    },
    {
        path: "/member-groups",
        name: "MEMBER_GROUPS_TEXT",
        icon: ic_Groups,
        component: MemberGroups,
        appType: "DirectDebit",
        layout: "/admin"
    },
    {
        path: "/menus",
        name: "MENUS_TEXT",
        icon: ic_List,
        component: Menus,
        appType: "Commerce",
        layout: "/admin"
    },
    {
        path: "/categories",
        name: "CATEGORIES_TEXT",
        icon: ic_Category,
        component: Categories,
        appType: "Commerce",
        layout: "/admin"
    },
    {
        path: "/products",
        name: "PRODUCTS_TEXT",
        icon: ic_Products,
        component: Products,
        appType: "Commerce",
        layout: "/admin"
    },
    {
        path: "/orders-kanban",
        name: "ORDERS_KANBAN_TEXT",
        icon: ic_ViewList,
        component: OrdersKanban,
        appType: "Commerce",
        layout: "/admin"
    },
    {
        path: "/discounts",
        name: "DISCOUNTS_TEXT",
        icon: ic_Discount,
        component: Discounts,
        appType: "Commerce",
        layout: "/admin"
    },
    {
        path: "/applications",
        name: "APPLICATIONS_TEXT",
        icon: ic_App,
        component: Applications,
        appType: "Setup",
        layout: "/admin"
    },
    {
        path: "/integrations",
        name: "CONNECT_TEXT",
        icon: ic_Integrations,
        component: IntegrationContainer,
        appType: "Setup",
        layout: "/admin"
    },
    {
        path: "/assets",
        name: "ASSETS_TEXT",
        icon: ic_Assets,
        component: Assets,
        appType: "Setup",
        layout: "/admin"
    },
    // {
    //     path: "/automations",
    //     name: "AUTOMATIONS_TEXT",
    //     icon: ic_Automations,
    //     component: Automations,
    //     appType: "Setup",
    //     layout: "/admin"
    // },
    {
        path: "/attendance",
        name: "ATTENDANCE_TEXT",
        icon: ic_Time,
        component: TimeChecks,
        appType: "Activity",
        layout: "/admin"
    },
    {
        path: "/activities",
        name: "ACTIVITIES_TEXT",
        icon: ic_Activity,
        component: Activities,
        appType: "Activity",
        layout: "/admin"
    },
];

export default sideBarRoutes;