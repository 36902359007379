import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {
    getCompanyCurrency,
    getDefaultWalletAccount,
    getRoundNumber, getUser, mergeArrays,
    nullController, saveData, setCurrencyValue,
} from "../../utils/helpersFunctions";
import {
    NOTIFICATION_DURATION
} from "../../variables/coreConstants";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {validateSession} from "../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {ROWS_PER_PAGE} from "../../variables/apiConstants";
import {withRouter} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Muted from "../../components/Typography/Muted";
import {withNotification} from "../../components/HOC/Notification/NotificationHOC";
import {
    getAllRevenueReconciliation,
} from "../../services/blockDepositService";
import ExportRevenueReconciliationForm from "./ExportRevenueReconciliationForm";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";
import Button from "../../components/CustomButtons/Button";
import Arrow from "@material-ui/icons/ExpandMore";
import Poppers from "@material-ui/core/Popper";
import classNames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {getWalletAccounts} from "../../services/cashAccountService";
import OBJECT_NAMES from "../../variables/objectConstants";


class RevenueReconciliationsScreen extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            isLoading: false,
            isLoadingMore: false,
            walletAccountList: [],
            walletAccount: null,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            currencySymbol: null,
            unSettledAmount: setCurrencyValue(0.00),
            page: 0,
            lastPage: false,
            totalRecords: 0,
            totalPages: 0,
            count: 0,
            openExport: false,
            openWalletSelector: false,
        };
    }

    componentDidMount() {
        const { t, routeProps } = this.props;
        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        this.setState({isLoading: true}, () => {
            this.handleRequestWalletAccountList();
        });
    }

    handleRequestWalletAccountList = () => {
        getWalletAccounts()
            .then((response) => {
                this.setState(
                    {
                        walletAccountList: response.data !== null && response.data !== undefined ? response.data : [],
                        walletAccount: getDefaultWalletAccount(response.data, getCompanyCurrency())
                    },
                    () => {
                        this.makeInitialRequest();
                    }
                );
            })
            .catch((err) => {
                this.makeInitialRequest();

                if (err.response) {
                    validateSession(err.response, this.props.history);
                }
            });
    };

    makeInitialRequest = () => {
        let dataParameters = {
            "pageNo": this.state.page,
            "pageSize": this.state.rowsPerPage,
            "filterId": this.state.walletAccount
        };

        this.setState({ isLoading: true }, () => {
            this.request(dataParameters);
        });
    };

    handleOpenExport = () => {
        this.setState({ openExport: true });
    };
    handleCloseExport = () => {
        this.setState({ openExport: false });
    };


    handleCloseForm = (requireUpdate, states) => {
        this.setState({ openDate: false });
        if(requireUpdate && states !== null) {
            try {
                this.setState({ isLoading: true, page: 0 });

                let dataParameters = {
                    "pageNo": 0,
                    "pageSize": this.state.rowsPerPage,
                    "filterId": this.state.walletAccount
                };

                this.request(dataParameters);
            } catch (e) {
                this.setState({ isLoading: false, isLoadingMore: false });
            }
        }
    };


    closeForm = (state, value, type) => {
        this.setState({[state]: value});
        if(type) {
            this.setState({ isLoading: true, page: 0 }, () => {
                let dataParameters = {
                    "pageNo": 0,
                    "pageSize": this.state.rowsPerPage,
                    "filterId": this.state.walletAccount
                };
                this.request(dataParameters);
            });
        }
    };


    
    request(parameters) {
        const { showNotificationMessage, t, history } = this.props;
        getAllRevenueReconciliation(parameters)
            .then((response) => {
                let newData = response.data !== null ? response.data.content.map(r => {
                    r.originalAmountLabel = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "originalAmount"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.cancelledAmountLabel = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "cancelledAmount"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.netAmountLabel = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "netAmount"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    return r;
                }) : [];

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid",
                    null
                );

                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    data: paginatedData,
                    filteredData: paginatedData,
                    totalRecords: response.data !== null ? response.data.totalElements : 0,
                    lastPage: response.data !== null ? response.data.last : false,
                    totalPages: response.data !== null ? response.data.totalPages : 0,
                    currencySymbol: response.data !== null && response.data.headerData !== null ? response.data.headerData.currencySymbol : null,
                    unSettledAmount: response.data !== null && response.data.headerData !== null ? setCurrencyValue(getRoundNumber(response.data.headerData.totalAmount)) : setCurrencyValue(0.00),
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false, isLoadingMore: false });
                if (error.response) {
                    showNotificationMessage(error.response.data.message, "danger", NotificationFailedIcon, NOTIFICATION_DURATION, null);
                    validateSession(error.response, history);
                } else {
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION, null);
                }
            });
    }


    handleRevenueReconciliationDetails = (revenue) => {
        saveData("revenueReconciliationDetails", revenue);
        this.props.history.push("/admin/reconciliation/" + revenue.uuid);
    };

    handleGoDeposit = (id, waId) => {
        saveData("blockDeposit", { uuid: id });
        this.props.history.push("/admin/wallet-account/" + waId + "/deposit/" + id);
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, lastPage } = this.state;

        if (!isLoadingMore && !lastPage) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "pageNo": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "filterId": this.state.walletAccount
                };
                this.request(dataParameters);
            });
        }
    };


    handleToggleSelector = () => {
        this.setState(state => ({openWalletSelector: !state.openWalletSelector}));
    };

    handleCloseSelector = event => {
        if (this.anchorSelector.contains(event.target)) {
            return;
        }
        this.setState({openWalletSelector: false});
    };
    handleSelectWallet = (wa) => {
        this.setState({openWalletSelector: false, walletAccount: wa.uuid, isLoading: true, page: 0 }, () => {
            let dataParameters = {
                "pageNo": this.state.page,
                "pageSize": this.state.rowsPerPage,
                "filterId": this.state.walletAccount
            };
            this.request(dataParameters);
        });
    };

    render(){
        const { classes, t } = this.props;
        const { totalPages, lastPage, page, rowsPerPage, totalRecords, isLoading, isLoadingMore, filteredData, unSettledAmount,
            openWalletSelector } = this.state;



        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading || isLoadingMore}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3 className={classes.screenTitle}>{t('REVENUE_RECONCILIATION_TXT')}</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={4} className={classes.gridNoTop}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={10} md={10}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <p className={classes.balanceCardTitle}>{t('UNSETTLED_BALANCE_TXT')}</p>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                                                <p className={classes.balanceCardTitle2}>{nullController(this.state, "currencySymbol") + " " + unSettledAmount}</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={2}>
                                    <div className={classes.manager}>
                                        <Button
                                            buttonRef={node => {
                                                this.anchorSelector = node;
                                            }}
                                            color={"white"}
                                            justIcon={true}
                                            simple={false}
                                            aria-owns={null}
                                            aria-haspopup="true"
                                            onClick={this.handleToggleSelector}
                                        >
                                            <Arrow className={classes.icons} fontSize={"large"}/>
                                        </Button>

                                        <Poppers
                                            open={openWalletSelector}
                                            anchorEl={this.anchorSelector}
                                            transition
                                            disablePortal
                                            className={
                                                classNames({[classes.popperClose]: !openWalletSelector}) +
                                                " " +
                                                classes.popperNav
                                            }
                                        >
                                            {({TransitionProps, placement}) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    id="profile-menu-list-grow"
                                                    style={{
                                                        transformOrigin:
                                                            placement === "bottom" ? "center top" : "center bottom"
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener
                                                            onClickAway={this.handleCloseSelector}>
                                                            <MenuList role="menu">
                                                                {
                                                                    this.state.walletAccountList.map((wa, k) => (
                                                                        <MenuItem
                                                                            key={k}
                                                                            onClick={() => this.handleSelectWallet(wa)}
                                                                            className={classes.dropdownItem}
                                                                        >
                                                                            {wa.currencyId.name + ' (' + wa.currencyId.symbol + ')'}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Poppers>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.gridTop}>
                    <TableGridWijmo
                        tableName={'reconciliations'}
                        title={t('REVENUE_RECONCILIATION_TXT')}
                        exportFileName={t('REVENUE_RECONCILIATION_TXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_SEND_SUMMARY'), function: this.handleOpenExport, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.handleRevenueReconciliationDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_MEMBER_REFERENCE'), name: "memberReference", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_INVOICE_REFERENCE'), name: "invoiceId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('ORIGINAL_AMOUNT'), name: "originalAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_PAYMENT_DATE'), name: "paymentDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_POSTED_DATE'), name: "erpSyncedDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TRANSACTION_ID'), name: "transactionId", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('CANCELLED_AMOUNT'), name: "cancelledAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('DEPOSITED_AMOUNT_TXT'), name: "netAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_DEPOSIT'), name: "depositLabel", onSelect: this.handleGoDeposit, isReadOnly: true, width: "*", cssClass: "", isCustomCell: true },
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openExport}
                    onClose={() => this.handleCloseExport()}
                    aria-labelledby="form-dialog-export"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('EXPORT_RECONCILIATION_TITLE')}
                        <Muted>
                            {t('EXPORT_RECONCILIATION_DESCRIPTION', { email: nullController(getUser(), "email")})}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <ExportRevenueReconciliationForm closeForm={this.closeForm} />
                    </DialogContent>
                </Dialog>
            </GridContainer>
        );
    }
}

RevenueReconciliationsScreen.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withNotification(withTranslation()(RevenueReconciliationsScreen))));
