import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import {withTranslation} from "react-i18next";
import GridItem from "../../../components/Grid/GridItem";
import {
    getData, isEmpty,
    isSuccessfulRequest,
    objectSizeValidator,
    saveData
} from "../../../utils/helpersFunctions";
import GridContainer from "../../../components/Grid/GridContainer";
import {
    DELETE,
    NOTIFICATION_DURATION, PAYMENT_METHOD_ENROLLMENT
} from "../../../variables/coreConstants";
import Button from "../../../components/CustomButtons/Button";
import {statusText} from "../../../utils/statusHelpers";
import {deleteMemberFromGroup, getMembersOnMemberGroupByCompany} from "../../../services/memberService";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationInfoIcon from "@material-ui/icons/Info";
import {validateSession} from "../../../services/api";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FilterNone";
import {getCompanyInformation} from "../../../services/accountService";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class MemberGroupDetails extends React.Component {
    csvLink = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            memberGroup: getData('memberGroup'),
            isLoading: false,
            isUpdating: false,
            csvData: [],
            data: [],
            filteredData: [],
            rowsPerPage: 50,
            page: 0,
            lastPage: false,
            openRequestMethod: false,
            showClipboard: false,
            paymentMethodLink: null,
            totalElements: 0,
            totalPages: 0,
            csvDownloadRef: null
        }
    }

    componentDidMount() {
        this.request();
        this.getLink();
    }

    openForm = (action, member) => {
        const { showNotificationMessage } = this.props;
        if (action === DELETE) {
            this.setState({ isUpdating: true }, () => {
                deleteMemberFromGroup({ "memberGroupAssignmentId": member.id })
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            this.setState({ isUpdating: false });
                            showNotificationMessage(response.data.message, "primary",
                                NotificationErrorIcon, NOTIFICATION_DURATION, () => { this.request() });

                        } else {
                            this.setState({ isUpdating: false });
                            showNotificationMessage(response.data.message, "danger",
                                NotificationErrorIcon, NOTIFICATION_DURATION, null);
                        }
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        if (err.response) {
                            showNotificationMessage(err.response.data.message, "danger",
                                NotificationFailedIcon, null);

                            validateSession(err.response, this.props.history);
                        } else {
                            showNotificationMessage(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                                NotificationFailedIcon, NOTIFICATION_DURATION, null);
                        }
                    });
            });
        }
    };

    handleEditMembers = () => {
        this.props.history.push(this.state.memberGroup.uuid + "/member-assignment");
    };


    handleDownloadMembers = () => {
        const { showNotificationMessage, t } = this.props;
        const { data } = this.state;
        try {
            let dataCsv = [];

            data.forEach(m => dataCsv.push({
                "memberName": m.name,
                "memberId": m.memberId,
                "memberExternalId": m.externalId,
                "memberEmail": m.email
            }));

            if (objectSizeValidator(dataCsv, 0)) {
                this.setState({
                    csvData: dataCsv,
                }, () => {
                    window.setTimeout(
                        function() {
                            if (this.csvLink !== null && this.csvLink !== undefined) {
                                this.csvLink.link.click();
                                this.setState({ isLoading: false });
                            }
                        }.bind(this),
                        1000
                    );
                });
            } else {
                showNotificationMessage(t('NO_RECORDS_TXT'), "warning", NotificationInfoIcon, NOTIFICATION_DURATION, null);
            }
        } catch (e) {
            showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger", NotificationErrorIcon, NOTIFICATION_DURATION, null);
        }
    };


    request = () => {
        const { page, rowsPerPage, memberGroup } = this.state;
        this.setState({ isLoading: true }, () => {

            let parameters = {
                "page": page,
                "pageSize": rowsPerPage,
                "uuid": memberGroup.uuid
            };

            getMembersOnMemberGroupByCompany(parameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.setState({
                            isLoading: false,
                            data: response.data !== null ? response.data.content : [],
                            filteredData: response.data !== null ? response.data.content : [],
                            totalElements: response.data !== null ? response.data.totalElements : 0,
                            lastPage: response.data !== null ? response.data.last : false,
                            totalPages: response.data !== null ? response.data.totalPages : 0,
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.props.showNotificationMessage(response.data.message, "danger", NotificationErrorIcon, NOTIFICATION_DURATION, null);
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    if (err.response) {
                        this.props.showNotificationMessage(err.response.data.message, "danger",
                            NotificationFailedIcon, null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.props.showNotificationMessage(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION, null);

                    }
                });
        });
    };


    getLink = () => {
        getCompanyInformation()
            .then((response) => {
                this.setState({
                    paymentMethodLink: response.data.link + PAYMENT_METHOD_ENROLLMENT.replace("mgID", this.state.memberGroup.uuid)
                });
            })
            .catch(e => { console.log("No link available") });
    };

    search = (e) => {
        let newData = this.state.data;
        try {
            newData = newData.filter((item) => {
                return (item.email !== null ? (item.email.search(e.target.value) !== -1) : false)
                    || (item.externalId !== null ? (item.externalId.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.name !== null ? (item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.role !== null ? (item.role.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || this.props.t(statusText[item.status]).toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });
            this.setState({ filteredData: newData });

        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    handleGoToDetails = (values) => {
        saveData("member", {
            uuid: values.memberId,
            name: values.name,
            externalId: values.externalId,
            status: values.status
        });

        window.open("/admin/member-details/" + values.memberId, "_blank");
    };


    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(val);
            this.setState({
                showClipboard: true
            }, () => {
                window.setTimeout(() => {
                    this.setState({ showClipboard: false })
                }, 1000);
            });
        } catch (e) {
            console.log("Something went wrong");
        }
    };




    render() {
        const { classes, t } = this.props;
        const { memberGroup, page, rowsPerPage, isLoading, filteredData, data, openRequestMethod, paymentMethodLink, showClipboard } = this.state;


        return (
            <div>
                <GridContainer>
                    <Backdrop className={classes.backdrop} open={isLoading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <TableGridWijmo
                            tableName={'member_group_details'}
                            title={t('MEMBER_GROUP_DETAILS_TEXT')}
                            exportFileName={t('MEMBER_GROUP_ASSIGMENT_TXT', { groupName: memberGroup.name })}
                            // handleLoadMore={this.handleLoadMore}
                            // isLoading={isLoading}
                            data={filteredData}
                            actions={[
                                { label: t('BTN_REQUEST_PAYMENT_METHOD'), function: () => this.setState({ openRequestMethod: true }), isLoading: false, disabled: false, type: "simple" },
                                { label: t('BTN_ASSIGN_MEMBERS'), function: this.handleEditMembers, isLoading: false, disabled: false, type: "simple" },
                                { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ]}
                            controls={[]}
                            headers={[
                                { label: t('TH_NAME'), name: "name", onSelect: this.handleGoToDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                                { label: t('TH_EXTERNAL_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_EMAIL'), name: "email", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_ADDED_BY'), name: "createdBy", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_ENROLLED_DATE'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_MEMBER_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true },
                                { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                            ]}
                        />
                    </GridItem>
                </GridContainer>

                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={openRequestMethod}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('REQUEST_PAYMENT_METHOD')}
                        {/*<Muted>*/}
                        {/*    {t('FORM_REQUEST_PAYMENT_METHOD_DESCRIPTION')}*/}
                        {/*</Muted>*/}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.formDivContainer}>
                                        <p className={classes.formContentDescription}>{t('FORM_REQUEST_PAYMENT_METHOD_ENROLLMENT_DESCRIPTION')}</p>
                                        <p className={classes.formContentTitle}>{t('PAYMENT_METHOD_LINK')}</p>
                                        {
                                            isEmpty(paymentMethodLink) ? null : (
                                                <p className={classes.formContentLegend}>
                                                    {paymentMethodLink}
                                                    <Tooltip open={showClipboard} id={paymentMethodLink} title={t('COPIED_TEXT')} placement="top-start">
                                                        <IconButton
                                                            style={{marginRight: 20, marginBottom: 3}}
                                                            size={"small"}
                                                            onClick={() => this.handleCopyToClipboard(paymentMethodLink)}>
                                                            <CopyIcon className={classes.copyButtonIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </p>
                                            )
                                        }
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.center}>
                                        <Button
                                            color="white"
                                            disabled={this.state.disableField}
                                            className={classes.buttonSpacing + " " + classes.cancelButton}
                                            onClick={() => this.setState({ openRequestMethod: false })}
                                        >
                                            {t('BTN_CLOSE')}
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>

                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


MemberGroupDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withNotification(withTranslation()(MemberGroupDetails))));
