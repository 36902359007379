import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {withRouter} from "react-router-dom";
import {
    BASE_URL_COMMERCE, MEMBER_CREDIT_CARD_STATUS, ROWS_PER_PAGE
} from "../../../variables/apiConstants";
import {
    getRoundNumber,
    isEmpty,
    isSuccessfulRequest, mergeArrays, nullController, setCurrencyValue
} from "../../../utils/helpersFunctions";
import {DialogTitle} from "@material-ui/core";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import Button from "../../../components/CustomButtons/Button";
import PaymentMethodForm from "../../PaymentMethod/PaymentMethodForm";
import Muted from "../../../components/Typography/Muted";
import {
    DIRECT_DEBIT_TYPE_ID,
    MEMBER_CARDS_CSV_EXAMPLE_URL, MEMBER_CARDS_IMPORT_TYPE,
    NOTIFICATION_DURATION_LARGE,
    SET_DEFAULT
} from "../../../variables/coreConstants";
import {
    getMemberGroupsByCompany,
    getMemberPaymentMethods,
    setDefaultMemberPaymentMethod
} from "../../../services/memberService";
import {validateSession} from "../../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {getStatus, statusController} from "../../../services/statusService";
import Link from "@material-ui/core/Link";
import MemberCsvUploadForm from "./MemberCsvUploadForm";
import UploadMemberCardsSummary from "./UploadMemberCardsSummary";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FilterNone";
import Select from "../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class MemberPaymentMethods extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isMemberPresent: !isEmpty(props.memberId),
            showClipboard: false,
            openRequestMethod: false,
            openForm: false,
            openCsvForm: false,
            isLoadingGroup: false,
            isLoadingMore: false,
            showCsvDetails: false,
            csvDetails: null,
            memberGroup: null,
            isLoading: false,
            isUpdating: false,
            data: [],
            filteredData: [],
            groupOptions: [],
            rowsPerPage: ROWS_PER_PAGE,
            lastPage: false,
            page: 0,
            totalRecords: 0,
            totalPages: 0,
            count: 0,
            paymentMethodLink: props.paymentMethodLink
        };
    }

    componentDidMount() {
        let dataParameters = {
            "uuid": this.state.isMemberPresent ? this.props.memberId : null,
            "page": this.state.page,
            "pageSize": this.state.rowsPerPage
        };
        this.request(dataParameters);
        this.requestGroups();
    }


    request = (parameters) => {
        this.setState({
            isLoading: true
        }, () => {
            getMemberPaymentMethods(parameters, this.onCompletePaymentMethods, this.onError);
        });
    };

    requestGroups = () => {
        this.setState({ isLoadingGroup: true }, () => {

            let parameters = {
                "page": 0,
                "pageSize": 500,
                "filterId": DIRECT_DEBIT_TYPE_ID,
                "status": -1 // Without status filter
            };

            getMemberGroupsByCompany(parameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.setState({
                            isLoadingGroup: false,
                            groupOptions: response.data !== null ? response.data.content.map(i => {return { label: i.name, value: i.uuid }}) : [],
                        });
                    } else {
                        this.setState({ isLoadingGroup: false });
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                    }
                })
                .catch((err) => {
                    this.setState({ isLoadingGroup: false });
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });
        });
    };

    onCompletePaymentMethods = (response) => {
        const { showNotificationMessage } = this.props;

        if (response.status === 200) {
            let newData = response.data !== null ? response.data.content : [];
            let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                this.state.filteredData,
                newData,
                "uuid",
            );

            this.setState({
                isLoading: false,
                isLoadingMore: false,
                data: paginatedData,
                filteredData: paginatedData,
                totalElements: response.data !== null ? response.data.totalElements : 0,
                lastPage: response.data !== null ? response.data.last : false,
                totalPages: response.data !== null ? response.data.totalPages : 0,
            });
        } else {
            this.setState({ isLoading: false, isLoadingMore: false });

            showNotificationMessage(
                response.data.message,
                "danger",
                NotificationErrorIcon,
                NOTIFICATION_DURATION_LARGE,
                false
            );
        }
    };


    onComplete = (response) => {
        const { showNotificationMessage } = this.props;

        let isSuccess = isSuccessfulRequest(response);

        showNotificationMessage(
            response.data.message,
            isSuccess ? "primary": "danger",
            isSuccess ? NotificationSuccessIcon: NotificationErrorIcon,
            NOTIFICATION_DURATION_LARGE,
            isSuccess ? this.handleRefresh : null
        );
    };

    handleRefresh = () => {
        this.setState({ page: 0 }, () => {
            let dataParameters = {
                "uuid": this.state.isMemberPresent ? this.props.memberId : null,
                "page": this.state.page,
                "pageSize": this.state.rowsPerPage
            };
            this.request(dataParameters);
        })
    };

    onError = (error) => {
        const { showNotificationMessage, history, t } = this.props;

        this.setState({ isLoading: false, isLoadingMore: false });

        if (error.response) {
            showNotificationMessage(error.response.data.message, "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
            validateSession(error.response, history);
        } else {
            showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
        }
    };


    showNotification = (msj, color, icon, refresh) => {
        const { showNotificationMessage } = this.props;

        showNotificationMessage(msj, color, icon, NOTIFICATION_DURATION_LARGE, refresh ? this.handleRefresh : null);
    };

    handleAction = (action, formData) => {
        // const { memberId, history, t, showNotificationMessage } = this.props;

        if (action === 0) {
            this.setState({
                openForm: true,
                actionType: 1
            })
        } else if (action === SET_DEFAULT) {
            let dataParameters = { memberCreditCardId: formData.uuid };

            setDefaultMemberPaymentMethod(dataParameters, this.onComplete, this.onError);
        } else {
            let status = getStatus(action);

            if (status !== null) {
                let dataParameters = {
                    "uuid": formData.uuid,
                    "status": status
                };

                statusController(action, BASE_URL_COMMERCE, MEMBER_CREDIT_CARD_STATUS, dataParameters, this, true);
            }
        }
    };


    changeMemberGroup = (memberGroup) => {
        this.setState({ memberGroup });
    };


    handleOpenForm = () => {
        this.setState({ openForm: true });
    };

    handleOpenCsvForm = () => {
        this.setState({
            showCsvDetails: false,
            csvDetails: null,
            openCsvForm: true
        });
    };

    handleCleanUploadCsv = () => {
        this.setState({
            showCsvDetails: false,
            csvDetails: null
        });
    };

    handleCloseCsvForm = (update, csvDetails) => {
        if (csvDetails !== null && csvDetails !== undefined) {
            this.setState({
                showCsvDetails: true,
                csvDetails: csvDetails,
            });
        } else {
            this.handleCloseForm("openCsvForm", false, update);
        }
    };

    handleCloseForm = (state, value, refresh) => {
        this.setState({[state]: value});
        if(refresh) {
            this.handleRefresh();
        }
    };


    sendPaymentMethodRequest = () => {

    };


    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(val);
            this.setState({
                showClipboard: true
            }, () => {
                window.setTimeout(() => {
                    this.setState({ showClipboard: false })
                }, 1000);
            });
        } catch (e) {
            console.log("Something went wrong");
        }
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, noMoreItems } = this.state;

        if (!isLoadingMore && !noMoreItems) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "uuid": this.state.isMemberPresent ? this.props.memberId : null,
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage
                };
                this.request(dataParameters);
            });
        }
    };



    render() {
        const { classes, t, memberId } = this.props;
        const { openForm, openCsvForm, isLoading, filteredData, data, totalPages, lastPage, page, totalElements, isLoadingMore,
            rowsPerPage, isMemberPresent, showCsvDetails, csvDetails, openRequestMethod,paymentMethodLink, showClipboard, memberGroup } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'payment_methods'}
                        title={t('PAYMENT_METHODS_TEXT')}
                        exportFileName={t('PAYMENT_METHODS_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={isMemberPresent ? [
                            { label: t('BTN_REQUEST_PAYMENT_METHOD'), function: () => this.setState({ openRequestMethod: true, memberGroup: null }), isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_ADD_PAYMENT_METHOD'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ] : [{ label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_NUMBER'), name: "number", isReadOnly: true, width: 110, isCustomCell: false },
                            // { label: t('EXPIRATION_TXT'), name: "expirationDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DEFAULT'), name: "isDefault", isReadOnly: true, width: 100, cssClass: "cell-number", isCustomCell: true },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true },
                            { label: t('TH_ADDED_BY'), name: "addedBy", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.handleAction, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={openForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_PAYMENT_METHOD_TITLE')}
                            <Muted>
                                {t('FORM_PAYMENT_METHOD_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <PaymentMethodForm onCloseForm={this.handleCloseForm} memberId={memberId} />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={openCsvForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {showCsvDetails ? t('IMPORT_MEMBER_CARDS_SUMMARY') : t('FORM_PAYMENT_METHOD_CSV_TITLE')}
                            <Muted>
                                {showCsvDetails ? csvDetails.message : t('FORM_PAYMENT_METHOD_CSV_DESCRIPTION')}
                                {showCsvDetails ? null : (
                                    <Link href={MEMBER_CARDS_CSV_EXAMPLE_URL} target={"_blank"} className={classes.linkText}>
                                        {t('DOWNLOAD_CSV')}
                                    </Link>
                                )}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            {
                                showCsvDetails
                                    ? <UploadMemberCardsSummary summary={csvDetails} closeForm={this.handleCloseCsvForm} onGoBack={this.handleCleanUploadCsv} />
                                    : <MemberCsvUploadForm closeForm={this.handleCloseCsvForm} importType={MEMBER_CARDS_IMPORT_TYPE} />
                            }
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={openRequestMethod}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('BTN_REQUEST_PAYMENT_METHOD')}
                            {/*<Muted>*/}
                            {/*    {t('FORM_REQUEST_PAYMENT_METHOD_DESCRIPTION')}*/}
                            {/*</Muted>*/}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.formDivContainer} style={{minHeight: "200px"}}>
                                            <p className={classes.formContentDescription}>{t('FORM_REQUEST_PAYMENT_METHOD_DESCRIPTION')}</p>
                                            <div className={classes.selectContainerTop} style={{marginBottom: "20px"}}>
                                                <Select
                                                    value={memberGroup}
                                                    onChange={this.changeMemberGroup}
                                                    options={this.state.groupOptions}
                                                    isLoading={this.state.isLoadingGroup}
                                                    isDisabled={this.state.isLoadingGroup}
                                                    // closeMenuOnSelect={true}
                                                    placeholder={t('MEMBER_GROUP_ENROLLMENT')}
                                                    maxMenuHeight={190}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: primaryColor[0],
                                                        },
                                                    })}
                                                    styles={{
                                                        menu: provided => ({ ...provided, zIndex: 5 })
                                                    }}
                                                />
                                            </div>

                                            {!isEmpty(memberGroup) ? <p className={classes.formContentTitle}>{t('PAYMENT_METHOD_LINK')}</p> : null}

                                            {
                                                isEmpty(paymentMethodLink) || isEmpty(memberGroup) ? null : (
                                                    <p className={classes.formContentLegend}>
                                                        {paymentMethodLink.replace("mgID", memberGroup.value)}
                                                        <Tooltip open={showClipboard} id={paymentMethodLink} title={t('COPIED_TEXT')} placement="top-start">
                                                            <IconButton
                                                                style={{marginRight: 20, marginBottom: 3}}
                                                                size={"small"}
                                                                onClick={() => this.handleCopyToClipboard(paymentMethodLink.replace("mgID", memberGroup.value))}>
                                                                <CopyIcon className={classes.copyButtonIcon} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.center}>
                                            <Button
                                                color="white"
                                                disabled={this.state.disableField}
                                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                                onClick={() => this.setState({ openRequestMethod: false })}
                                            >
                                                {t('BTN_CLOSE')}
                                            </Button>
                                            {/*<Button*/}
                                            {/*    color="primary"*/}
                                            {/*    className={classes.buttonSpacing}*/}
                                            {/*    onClick={() => this.sendPaymentMethodRequest()}*/}
                                            {/*    disabled={this.state.disableField || isEmpty(paymentMethodLink) || isEmpty(memberGroup)}*/}
                                            {/*>*/}
                                            {/*    {t('BTN_SEND')}*/}
                                            {/*</Button>*/}
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }

}

MemberPaymentMethods.propTypes = {
    classes: PropTypes.object,
    memberId: PropTypes.string,
    paymentMethodLink: PropTypes.string
};

export default withRouter(withStyles(tableViewStyle)(withNotification(withTranslation()(MemberPaymentMethods))));
