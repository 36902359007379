import {getPublicService, getService, postPublicService, postService, putService} from "./api";
import storeDetails from "../models/StoreModel";
import {
    BASE_URL_CORE,
    STORES_BY_COMPANY,
    STORE,
    CITIES_BY_COUNTRY,
    COUNTRIES,
    BASE_URL_ACCOUNT,
    STORES_TYPES,
    STORE_IMAGES,
    ALL_STORES_ROLE,
    SCHEDULES_STORE,
    PUBLISH_STORE,
    STORE_DETAILS,
    UPDATE_EXPLORER_MODE, STORES_AVAILABLE, STORES_AVAILABLE_PAYMENT_REQUEST
} from "../variables/apiConstants";

export function getStores() {
    let url = BASE_URL_CORE + STORES_BY_COMPANY;
    return getService(url);
}

export function getAllStoresAssignments(roleId) {
    let url = BASE_URL_CORE + ALL_STORES_ROLE;
    let parameters = { "roleId": roleId };

    return postService(url, parameters);
}

export function getStoreTypes() {
    let url = BASE_URL_CORE + STORES_TYPES;
    return getService(url);
}

export function getCitiesByCountry(_countryId) {
    let url = BASE_URL_CORE + CITIES_BY_COUNTRY + _countryId;
    return getPublicService(url);
}

export function getCountries() {
    let url = BASE_URL_ACCOUNT + COUNTRIES;
    return postPublicService(url, null);
}

export function addStore(parameters) {
    let url = BASE_URL_CORE + STORE;
    return postService(url, parameters);
}

export function editStore(parameters) {
    let url = BASE_URL_CORE + STORE + "/" + storeDetails.id;
    return putService(url, parameters);
}

export function addStoreImages(parameters) {
    let url = BASE_URL_CORE + STORE_IMAGES;
    return putService(url, parameters);
}


export function getSchedulesByStore(storeId) {
    let url = BASE_URL_CORE + SCHEDULES_STORE;
    let parameters = {
        "storeId": storeId
    };

    return postService(url, parameters);
}


export function publishStore(parameters) {
    let url = BASE_URL_CORE + PUBLISH_STORE;

    return postService(url, parameters);
}

export function getStoreDetails(storeId) {
    let url = BASE_URL_CORE + STORE_DETAILS;
    let parameters = { "storeId": storeId };

    return postService(url, parameters);
}


export function updateExplorerMode(parameters) {
    let url = BASE_URL_CORE + UPDATE_EXPLORER_MODE;
    return putService(url, parameters);
}


export function getStoresAvailable() {
    let url = BASE_URL_CORE + STORES_AVAILABLE;

    return getService(url);
}

export function getStoresAvailableForPaymentRequest(paymentRquestId) {
    let url = BASE_URL_CORE + STORES_AVAILABLE_PAYMENT_REQUEST + paymentRquestId;

    return getService(url);
}
