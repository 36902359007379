import {
    successColor,
    whiteColor,
    grayColor,
    blackColor,
    primaryColor,
    dangerColor,
    defaultFont,
    lightColors,
    warningColor,
    hexToRgb
} from "../../jss/material-dashboard-react.jsx";

const searchableStyle = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    rightPaper:{
        float: "right",
        marginBottom: 0,
    },

    rowColor:{
        backgroundColor: lightColors[1] + "!important"
    },
    thColor:{
        color: grayColor[2]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        overflow: "auto",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        color: "inherit",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        "&, &$tableCell": {
            fontSize: "1em"
        },
    },
    tableCellActions: {
        color: "inherit",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        "&, &$tableCell": {
            fontSize: "1em"
        },
        lineHeight: "1.42857143",
        padding: "8px 4px",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
        width: "7%",
        textAlign: "center"
    },
    tableCell: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "8px 4px !important",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
    },
    tableCellCenter: {
        justifyContent: "center"
    },
    tableCell1: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "4px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableRow: {
        padding: "1px 1px",
    },
    tableResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "calc(100vh - 235px)",
    },
    tableResumeResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "290px"
    },
    status: {
        alignItems: "center",
        marginRight: 1,
        width: "auto",
    },
    statusSmallText: {
        alignItems: "center",
        width: "auto",
        fontSize: "0.7525rem",
        lineHeight: "1.42857143"
    },
    statusSuccess: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: successColor[2]
    },
    statusDanger: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: dangerColor[2]
    },
    hoverRow:{
        cursor: "pointer",
    },
    card:{
        marginTop: "1px",
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0"
    },
    tableActionButtonIcon: {
        color: primaryColor[0],
        width: "17px",
        height: "17px"
    },
    blueColor: {
        color: primaryColor[0],
    },
    selectFilter:{
        marginTop: "7px",
    },
    root: {
        width: "100%",
    },
    tableWrapper: {
        maxHeight: "calc(100vh - 235px)",
        overflow: "auto",
    },
    exportContainer:{
        float: "right",
        marginRight: "5px",
    },
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    cancelButton:{
        border: "1px solid #A2A2A2"
    },
    buttonFit: {
        paddingLeft: 10,
        paddingRight: 10
    },
    cardBodyBottomFit: {
        paddingBottom: 0
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    closeIconButton: {
        width: "27px",
        height: "27px",
        padding: "0",
        backgroundColor: dangerColor[0],
    },
    formIcons: {
        color: whiteColor,
        width: "27px",
        height: "27px"
    },
    iconButtonRed: {
        width: "27px",
        height: "27px",
        color: dangerColor[0]
    },
    iconButtonBlue: {
        width: "27px",
        height: "27px",
        color: primaryColor[0]
    },

    thBlueIcon: {
        color: primaryColor[0],
        width: "27px",
        height: "27px",
        alignSelf: "center"
    },
    thGreenIcon: {
        color: successColor[2],
        width: "23px",
        height: "23px",
        alignSelf: "center",
    },
    statusFixed: {
        alignItems: "center",
        margin: 0,
        width: "auto",
        padding: 0
    },
    minCardLightText: {
        fontSize: "10px",
        color: grayColor[0],
        fontWeight: "400"
    },
    minPaymentLightText: {
        fontSize: "9px",
        color: grayColor[0],
        fontWeight: "400",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
        textOverflow: "ellipsis"
    },
    minCardDarkText: {
        fontSize: "11px",
        color: grayColor[2],
        fontWeight: "500",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover,&:focus": {
            textDecoration: "none",
            color: grayColor[2],
        },
    },
    identifierContainer: {
        backgroundColor: "transparent",
        fontSize: "11px",
        color: grayColor[2],
        fontWeight: "500",
        height: "60%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover,&:focus": {
            backgroundColor: lightColors[1],
            borderRadius: "5px"
        },
    },

    gridItemWithoutLeftPadding: {
        padding: "0 15px 15px 0 !important"
    },
    gridItemNoPadding: {
        padding: "8px 0 0 30px !important"
    },
    kanbanContainer: {
        backgroundColor: "transparent",
        width: "100%",
        padding: "15px 0 15px 30px !important"
    },
    zoneCard: {
        backgroundColor: whiteColor,
        marginTop: "5px",
        marginBottom: "5px",
        width: "97%",
        marginLeft: "1.5%",
        marginRight: "1.5%",
        border: "0",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: "#E9E9E9",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    zoneCardSelected: {
        backgroundColor: primaryColor[0],
        marginTop: "5px",
        marginBottom: "5px",
        width: "97%",
        marginLeft: "1.5%",
        marginRight: "1.5%",
        border: "0",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(whiteColor) + ", 0.87)",
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: primaryColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    selectTitle:{
        ...defaultFont,
        color: grayColor[2] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    iconAvatarBlue: {
        width: "27px",
        height: "27px",
        padding: "0",
        color: primaryColor[0]
    },
    iconAvatar: {
        width: "27px",
        height: "27px",
        backgroundColor: "white",
        marginRight: "3px"
    },
    modifierSmallGrayText: {
        fontSize: "8px",
        color: grayColor[0],
        fontWeight: "400",
        marginLeft: "5px",
        padding: 0,
    },
    priceView: {
        float: "right",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    item: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "2px"
    },
    quantityView: {
        width: "10%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    productView: {
        width: "90%",
        marginRight: "7%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: "2%",
        marginBottom: "1px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    descriptionGrayText: {
        fontSize: "10px",
        color: grayColor[2],
        fontWeight: "400"
    },
    orderTypeIcon: {
        width: "17px",
        height: "17px",
        padding: "0",
        marginRight: "2px",
        color: primaryColor[0]
    },
    orderDineInIcon: {
        width: "19px",
        height: "19px",
        padding: "0",
        marginRight: "2px",
        color: primaryColor[0]
    },
    warningSmallIcon: {
        width: "14px",
        height: "14px",
        color: warningColor[4]
    },
    containerRight: {
        float: "right",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    containerNoteRight: {
        float: "right",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },

    // Kanban styles
    orderKanbanContainer: {
        backgroundColor: "transparent",
        width: "auto",
        height: "100%",
        paddingBottom: 2
    },
    zoneTitle: {
        marginTop: 0,
        marginBottom: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    roundNotificationNew: {
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        backgroundColor: "orange",
        width: "17px",
        height: "17px",
        borderRadius: "50%",
        border: "1.5px solid white",
    },
    roundNotificationProgress: {
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        backgroundColor: "#68C7BD",
        width: "17px",
        height: "17px",
        borderRadius: "50%",
        border: "1.5px solid white",
    },
    zoneWrapperContainer: {
        backgroundColor: lightColors[1],
        width: "95%",
        marginLeft: "2.5%",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        marginRight: "2.5%",
        marginTop: "10px",
        height: "auto",
        minHeight: "calc(100vh - 125px)"
    },
    zoneGroupContainer: {
        backgroundColor: whiteColor,
        width: "100%",
        overflow: "scroll",
        overflowX: "auto",
        overflowY: "auto",
        height: "auto",
        minHeight: "calc(100vh - 160px)",
        borderRight: "1.5px solid #E3E3E3"
    },
    zoneListContainer: {
        backgroundColor: grayColor[25],
        width: "100%",
        overflow: "scroll",
        overflowX: "auto",
        overflowY: "auto",
        height: "auto",
        minHeight: "calc(100vh - 160px)"
    },
    hidden: {
        backgroundColor: "transparent",
        width: "95%",
        marginLeft: "2.5%",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        marginRight: "2.5%",
        marginTop: "10px",
        height: "auto",
        minHeight: "calc(100vh - 125px)"
    },
    zoneSection: {
        display: "flex",
        width: "100%",
        overflow: "hidden",
        height: "auto",
        flexDirection: "row",
        backgroundColor: "transparent",
    },



    container: {
        display: "flex",
        flexDirection: "row",
        width: "auto",
        minHeight: "calc(100vh - 125px)",
        maxHeight: "calc(100vh - 125px)",
    },

    mainContainer: {
        width: "300px",
        height: "auto",
        minHeight: "calc(100vh - 125px)",
        display: "inline-block",
        flexDirection: "column",
        borderTop: "2px solid #E3E3E3",
        borderLeft: "2px solid #E3E3E3",
        borderRight: "2px solid #E3E3E3"
    },
    containerHeader: {
        height: "50px",
        backgroundColor: "white",
        maxWidth: "300px",
        width: "300px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    containerBody: {
        backgroundColor: "white",
        marginLeft: "-2px",
        paddingBottom: "3px",
        width: "300px",
        maxHeight: "calc(100vh - 180px)",
        minHeight: "calc(100vh - 180px)",
        borderBottom: "2px solid #E3E3E3",
        borderLeft: "2px solid #E3E3E3",
        borderRight: "2px solid #E3E3E3",
        overflowY: "auto",
        overflowX: "hidden",
    },
    containerListDecoration: {
        backgroundColor: lightColors[2],
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
        maxWidth: "290px",
        minWidth: "290px",
        width: "290px",
        height: "auto",
        minHeight: "calc(100vh - 185px)",
        paddingTop: "3px",
        marginRight: "5px",
        marginLeft: "5px",
        paddingBottom: "2px"
    },
    searchIconColor: {
        color: primaryColor[0]
    },
    search: {
        width: "95%",
        marginLeft: "2.5%",
        paddingLeft: "8px",
        paddingRight: "3px",
        "&:before": {
            backgroundColor: "transparent !important"
        },
        "&:hover:not($disabled):before,&:before": {
            borderColor: "transparent !important",
            borderWidth: "transparent !important"
        },
        "&:after": {
            borderColor: "transparent !important",
            border: "1px solid " + grayColor[3]
        },
        borderRadius: "20px",
        border: "1px solid " + grayColor[26],
        "&:hover": {
            border: "1px solid " + grayColor[3]
        },
    },
    searchContainer: {
        width: "100%",
        backgroundColor: "transparent",
    },
    adornmentContainer: {
        margin: 0,
        padding: 0
    },
    assignmentActions: {
        marginRight: 10,
        marginLeft: 10,
        width: "120px",
        minHeight: "calc(100vh - 125px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    actionIcons: {
        width: "37px",
        height: "37px",
        color: primaryColor[0]
    },

    itemCardSelected: {
        backgroundColor: primaryColor[0],
        borderRadius: "6px",
        marginTop: "5px",
        border: "1px solid #E3E3E3",
        marginBottom: "5px",
        width: "98%",
        marginLeft: "1%",
        marginRight: "1%",
        color: whiteColor,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: primaryColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    loadingText: {
        backgroundColor: "transparent",
        marginTop: "5px",
        marginBottom: "5px",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "center",
    },

    itemCard: {
        backgroundColor: whiteColor,
        marginTop: "5px",
        marginBottom: "5px",
        width: "98%",
        marginLeft: "1%",
        marginRight: "1%",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: whiteColor,
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },

    itemContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
    },
    itemTextContainer: {
        width: "85%",
        marginRight: "1%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textOverflow: "ellipsis",
        minHeight: "1em",
        maxHeight: "2.2em"
    },
    itemIconContainer: {
        width: "13%",
        marginRight: "1%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textOverflow: "ellipsis",
        minHeight: "1em",
        maxHeight: "2.2em"
    },
    componentContainer: {
        display: "flex",
        width: "auto",
        overflow: "hidden",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        minHeight: "calc(100vh - 125px)",
        height: "calc(100vh - 125px)",
        flexDirection: "row",
        backgroundColor: "transparent",
        marginTop: 10,
        paddingBottom: 2
    },

    itemText: {
        alignItems: "flex-start",
        textOverflow: "ellipsis",
        minHeight: "1em",
        maxHeight: "1.1em",
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemTextGray: {
        alignItems: "flex-start",
        fontSize: "13px",
        color: grayColor[1],
        textOverflow: "ellipsis",
        minHeight: "1em",
        maxHeight: "1.1em",
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    iconYellow: {
        width: "20px",
        height: "20px",
        marginTop: 5,
        color: warningColor[0]
    },
    iconError: {
        width: "20px",
        height: "20px",
        marginTop: 5,
        color: dangerColor[3]
    },




    zonesHeader: {
        marginTop: 11,
        height: "35px",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #E3E3E3",
        borderBottom: "1px solid #E3E3E3"
    },
    dropTitle: {
        color: grayColor[2],
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.9725rem",
        fontFamily: 'sans-serif',
        fontWeight: "300",
        // ...defaultFont,
        "&, &$tableCell": {
            fontSize: "1em"
        },
    },
    dropBody: {
        backgroundColor: "white",
        marginLeft: "-2px",
        paddingBottom: "3px",
        width: "260px",
        height: "auto",
        minHeight: "calc(100vh - 185px)",
        borderBottom: "2px solid #E3E3E3",
        borderLeft: "2px solid #E3E3E3",
        borderRight: "2px solid #E3E3E3"
    },
    dropCardContainer: {
        backgroundColor: "transparent",
        height: "35px",
        width: "95%",
        borderRadius: "6px 6px 0 0",
        display: "flex",
        flexDirection: "row",
        marginLeft: "2%",
        marginRight: "2%"
    },
    dropCardHeader: {
        backgroundColor: "transparent",
        height: "100%",
        width: "35%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardStatusContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardOrderInfoContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "32%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardMethodContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "45%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardIdentifierContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "55%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    divider: {
        width: "95%",
        height: ".7px",
        backgroundColor: grayColor[24],
        alignSelf: "center"
    },
    dropCardBody: {
        backgroundColor: "transparent",
        height: "68px",
        maxHeight: "65px",
        width: "94%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.5px",
        marginTop: "1.5px",
        marginLeft: "3%",
        marginRight: "3%"
    },
    dropCardFooter: {
        backgroundColor: "transparent",
        height: "25px",
        width: "95%",
        display: "flex",
        flexDirection: "row",
        marginLeft: "2%",
        marginRight: "2%",
        paddingRight: ".5%",
        paddingLeft: ".5%"
    },
    dropCardTime: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardTimeLarge: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardTotal: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    dropCardTotalLarge: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    dropCardNote: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        minWidth: "20%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardDeliveryType: {
        backgroundColor: "transparent",
        position: "relative",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardDeliveryTpLarge: {
        backgroundColor: "transparent",
        position: "relative",
        height: "100%",
        width: "34%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    // Styles for order detail form dialog
    orderDetailActions: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    actionCloseContainer: {
        display: "flex",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "30%"
    },
    orderDetailIdentifierDialog: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: "40%"
    },
    actionPrintContainer: {
        display: "flex",
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: "30%"
    },
    printContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'flex-end',
        marginTop: 5
    },
    iconButton: {
        width: "24px",
        height: "24px",
        padding: "0px"
    },
    close: {
        width: "11px",
        height: "11px"
    },
    inputNoStyle: {
        textShadow: "none",
        display: "inline-block",
        appearance: 'none',
        backgroundColor: "transparent",
        padding: 0,
        width: "192px",
        borderWidth: "0px",
        borderColor: "transparent",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    copyButton: {
        "&.MuiButton-root": {
            padding: "0px 0px",
            minWidth: "50px",
            backgroundColor:"red"
        }
    },
    select:{
        marginTop: 8
    },
    noPadding: {
        paddingTop: "0 !important",
        marginTop: "10px !important",
        marginBottom: "0 !important",
        paddingBottom: "0 !important"
    }
};

export default searchableStyle;
