import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import TableHead from "@material-ui/core/TableHead";
// core components
import tableStyle from "../../assets/jss/material-dashboard-react/components/tableStyle.jsx";
import {lightColors} from "../../assets/jss/material-dashboard-react";

function CustomTableHead({ ...props }) {
    const { classes, children, withColor } = props;

    return (
        <TableHead className={classes.objectColor} style={{backgroundColor: withColor ? lightColors[2] : "transparent"}} >
            {children}
        </TableHead>
    );
}


CustomTableHead.defaultProps = {
    tableHeaderColor: "default",
};

CustomTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    withColor: PropTypes.bool,
    children: PropTypes.node,
};

export default React.memo(withStyles(tableStyle)(CustomTableHead));
