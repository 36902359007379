import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import StoreIcon from "@material-ui/icons/Store";
import PhoneIcon from "@material-ui/icons/Phone";
import AddressIcon from "@material-ui/icons/Place";
import CityIcon from "@material-ui/icons/LocationCity";
import ExternalIdIcon from "@material-ui/icons/ShortText";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import storeDetails from "../../../models/StoreModel";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {addStore, editStore, getCountries, getSchedulesByStore, getStoreTypes} from "../../../services/storeService";
import Select from "../../../components/CustomSelect/CustomSelect";
import {
    convertStringToDate,
    disableComponent,
    enableComponent, formatTime, isSuccessfulCreate,
    isSuccessfulRequest, removeData, saveData,
    selectValidation,
    validateFields
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import Muted from "../../../components/Typography/Muted";
import ScheduleAvailable from "../../../components/Schedules/ScheduleAvailable";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Schedule from "../../../components/Schedules/ScheduleForm";
import scheduleDetails from "../../../models/ScheduleModel";
import SimpleButton from "@material-ui/core/Button";
import GooglePlaces from "../../../components/AddressInput/GooglePlaces";

class StoreForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            storeName: storeDetails.name,
            externalId: storeDetails.externalId,
            address: storeDetails.address,
            phoneNumber: storeDetails.phoneNumber,
            postalCode: storeDetails.postalCode,
            typesOptions: [],
            storeType: storeDetails.storeType,
            countryOptions: [],
            country: storeDetails.country,
            city: storeDetails.city,
            disableField: false,
            openSchedule: false,
            schedules: scheduleDetails.schedules,
            addressDetails: storeDetails.addressDetails,
            oldSchedules: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            latitude: storeDetails.place.latitude,
            longitude: storeDetails.place.longitude,
            placeId: storeDetails.place.extPlaceId,
            placeReference: storeDetails.place.extReference,
            placeDescription: storeDetails.placeDescription
        };
        this.saveStore = this.saveStore.bind(this);
        this.updateStore = this.updateStore.bind(this);
        this.request = this.request.bind(this);
        this.scheduleForm = this.scheduleForm.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    componentDidMount() {
        this.request();
    }


    changeCountrySelect = country => {
        this.setState({ country });
    };
    changeTypeSelect = storeType => {
        this.setState({ storeType });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData() {
        this.setState({
            storeName: '',
            externalId: '',
            address: '',
            phoneNumber: '',
            postalCode: '',
            country: '',
            storeType: '',
            city: '',
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            latitude: 0.0,
            longitude: 0.0,
            placeId: '',
            placeReference: '',
            addressDetails: '',
            placeDescription: ''
        });
    }



    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                        this.props.component.setState({storeCompleted: true});
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    openScheduleForm = () => {
        this.setState({
            openSchedule: true,
            actionType: 0
        });
        scheduleDetails.cleanSchedule();
    };

    closeScheduleForm = () => {
        this.setState({ openSchedule: false } );
    };

    scheduleForm(value) {
        this.setState({ openSchedule: value });
    };

    /*
    Utility function
    */
    setPlaceAddress = (postalCode, city, latitude, longitude, formattedAddress) => {
        this.setState({
            postalCode: postalCode,
            city: city,
            latitude: latitude,
            longitude: longitude,
            address: formattedAddress,
        })
    };

    /*
    Set place References
    */
    setPlaceReferenceData = (placeId, reference, description) => {
        this.setState({
            placeId: placeId,
            placeReference: reference,
            placeDescription: description
        })
    };


    request() {
        const { t } = this.props;
        let typesValues;

        getStoreTypes()
            .then((res) => {
                typesValues = res.data.map( item => {
                    return { value: item.id , label: item.type };
                });
                this.setState({ typesOptions: typesValues });
            })
            .catch((err) => {
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                }
            });

        let countryValues;
        getCountries()
            .then((res) => {
                countryValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({ countryOptions: countryValues });
            })
            .catch((error) => {
                this.showNotification(t('GET_COUNTRIES_FAILED_MSG'), "danger", NotificationFailedIcon, null);

                if (error.response)
                    validateSession(error.response, this.props.history);
            });


        let schedules;
        if (this.props.action) {
            getSchedulesByStore(storeDetails.id)
                .then((response) => {
                    schedules = response.data.map(val => {
                        return {
                            assignId: val.id,
                            scheduleId: val.scheduleId.id,
                            scheduleDaysId: val.scheduleId.scheduleDaysId.id,
                            day: val.scheduleId.scheduleDaysId.days,
                            timeFrom: convertStringToDate(val.scheduleId.timeFrom),
                            timeTo: convertStringToDate(val.scheduleId.timeTo)
                        }
                    });

                    scheduleDetails.schedules = schedules;
                    this.setState({
                        schedules: schedules,
                        oldSchedules: response.data
                    });

                })
                .catch((error) => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    saveStore() {
        const { t } = this.props;

        let fields = [
            this.state.storeName,
            this.state.address,
            this.state.placeDescription,
            this.state.phoneNumber,
            this.state.postalCode,
            this.state.city
        ];

        if (!validateFields(fields) || selectValidation(this.state.country) || selectValidation(this.state.storeType)) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let schedules = this.state.schedules.map( item => {
                return {
                    scheduleDaysId: item.scheduleDaysId,
                    timeFrom: formatTime(item.timeFrom),
                    timeTo: formatTime(item.timeTo)
                };
            });


            let dataParameters = {
                "name": this.state.storeName,
                "externalId": this.state.externalId,
                "countryId": this.state.country.value,
                "storeTypeId": this.state.storeType.value,
                "city": this.state.city,
                "address": this.state.placeDescription,
                "postalCode": this.state.postalCode,
                "phoneNumber": this.state.phoneNumber,
                "schedules": JSON.stringify(schedules),
                "formattedAddress": this.state.address,
                "externalReference": this.state.placeReference,
                "externalPlaceIdentifier": this.state.placeId,
                "latitude": this.state.latitude,
                "longitude": this.state.longitude,
                "details": this.state.addressDetails
            };


            disableComponent(this, "disableField");
            addStore(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        removeData("store");
                        // history.push('quick-actions-store');

                        if (response.data.entity) {
                            saveData("store", response.data.entity);
                            storeDetails.id = response.data.entity.id;
                        }

                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    updateStore() {
        const { t } = this.props;

        let fields = [
            this.state.storeName,
            this.state.address,
            this.state.placeDescription,
            this.state.phoneNumber,
            this.state.postalCode
        ];

        if (validateFields(fields)) {

            let schedules = this.state.schedules.map( item => {
                return {
                    assignId: item.assignId,
                    scheduleId: item.scheduleId,
                    dayId: item.scheduleDaysId,
                    timeFrom: formatTime(item.timeFrom),
                    timeTo: formatTime(item.timeTo)
                };
            });


            let oldSchedules = this.state.oldSchedules.map( item => {
                return {
                    assignId: item.id,
                    scheduleId: item.scheduleId.id,
                    dayId: item.scheduleId.scheduleDaysId.id,
                    timeFrom: item.scheduleId.timeFrom,
                    timeTo: item.scheduleId.timeTo
                };
            });

            let dataParameters = {
                "name": this.state.storeName,
                "externalId": this.state.externalId,
                "address": this.state.placeDescription,
                "postalCode": this.state.postalCode,
                "phoneNumber": this.state.phoneNumber,
                "storeTypeId": this.state.storeType.value,
                "schedules": JSON.stringify(schedules),
                "oldSchedules": JSON.stringify(oldSchedules),
                "formattedAddress": this.state.address,
                "externalReference": this.state.placeReference,
                "externalPlaceIdentifier": this.state.placeId,
                "latitude": this.state.latitude,
                "longitude": this.state.longitude,
                "details": this.state.addressDetails
            };


            disableComponent(this, "disableField");
            editStore(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });

        } else {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
        }
    }




    render(){
        const { classes, t } = this.props;

        const SaveStoreButton = withRouter(({ history }) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveStore(history)}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_NEXT')}
            </Button>
        ));

        const UpdateStoreButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateStore()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_NEXT')}
            </Button>
        );

        return (
            <div style={{flex:1}}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.storeName}
                            onChange={this.handleChangeValues}
                            labelText={t('STORE_NAME_TEXT')}
                            id="storeName"
                            name="storeName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <StoreIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="storeType" className={classes.selectTitle}>
                                {t('TYPE_TEXT')}
                            </InputLabel>
                            <Select
                                key={"mRS_StoreType"}
                                value={this.state.storeType}
                                onChange={this.changeTypeSelect}
                                options={this.state.typesOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={10} sm={10} md={6}>
                        <GooglePlaces
                            value={this.state.placeDescription}
                            labelText={t('LOCATION_TEXT')}
                            formControlProps={{
                                fullWidth: true
                            }}
                            setPlaceAddress={this.setPlaceAddress}
                            setPlaceReferenceData={this.setPlaceReferenceData}

                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.addressDetails}
                            onChange={this.handleChangeValues}
                            labelText={t('ADDRESS_TEXT')}
                            id="addressDetails"
                            name="addressDetails"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <AddressIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.postalCode}
                            onChange={this.handleChangeValues}
                            labelText={t('POSTAL_CODE_TEXT')}
                            id="postalCode"
                            name="postalCode"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <AddressIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.city}
                            onChange={this.handleChangeValues}
                            labelText={t('CITY_TEXT')}
                            id="city"
                            name="city"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CityIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="country" className={classes.selectTitle}>
                                {t('SELECT_COUNTRY_TEXT')}
                            </InputLabel>
                            <Select
                                key={"mRS_StoreCountry"}
                                value={this.state.country}
                                onChange={this.changeCountrySelect}
                                options={this.state.countryOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.phoneNumber}
                            onChange={this.handleChangeValues}
                            type={"number"}
                            labelText={t('PHONE_NUMBER_TEXT')}
                            id="phoneNumber"
                            name="phoneNumber"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <PhoneIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText={t('EXTERNAL_ID_TEXT')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIdIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.typo}>
                            <h5 style={{color: 'black'}}>
                                {t('SCHEDULE_SECTION_TITLE')}
                                <Muted>
                                    {t('SCHEDULE_STORE_SECTION_DESCRIPTION')}
                                </Muted>
                            </h5>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <ScheduleAvailable schedules={this.state.schedules} component={this}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.addScheduleButton}>
                            <SimpleButton
                                size="small"
                                color="primary"
                                onClick={this.openScheduleForm}
                            >
                                {t('BTN_ADD_AVAILABILITY')}
                            </SimpleButton>
                        </div>
                        <Dialog
                            maxWidth={"sm"}
                            fullWidth={true}
                            open={this.state.openSchedule}
                            onClose={this.closeScheduleForm}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                {this.state.actionType
                                    ? (t('FORM_EDIT_TEXT')) : (t('FORM_NEW_TEXT'))} {t('SCHEDULE_SECTION_TITLE')}
                            </DialogTitle>
                            <DialogContent>
                                <Schedule closeForm={this.scheduleForm} action={this.state.actionType}/>
                            </DialogContent>
                        </Dialog>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openStore", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateStoreButton/> : <SaveStoreButton />}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

StoreForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object,
};

export default withRouter(withStyles(formStyle)(withTranslation()(StoreForm)));