import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import TextIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";

// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent, enableComponent, isEmpty, isSuccessfulCreate,
    isSuccessfulRequest, nowMomentDate, removeData, saveData, selectValidation,
} from "../../../utils/helpersFunctions";
import {DIRECT_DEBIT_TYPE_ID, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {
    getMemberGroupsByCompany
} from "../../../services/memberService";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import Datetime from "react-datetime";
import {addDirectDebit, refreshPrivateServiceToken} from "../../../services/directDebitService";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import HeaderForm from "../../../components/CustomComponents/HeaderForm";
import {formTitles} from "../../../utils/statusHelpers";
import {Backdrop, CircularProgress} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


class DirectDebitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debitId: '',
            name: '',
            memberGroup: '',
            emailNotifications: true,
            memberGroupOptions: [],
            date: nowMomentDate(),
            retryDebitToken: 0,
            color: 'primary',
            notificationMessage: '',
            icon: NotificationSuccessIcon,
            notification: false,
            isLoading: false,
            disableField: false,
        };
    }


    componentDidMount() {
        const { t, routeProps } =  this.props;

        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        this.initRequest();
    }



    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData = () => {
        this.setState({
            name: '',
            memberGroup: '',
        });
    };

    changeGroupSelect = (memberGroup) => {
        this.setState({ memberGroup });
    };

    handleChangeDate = (date) => {
        this.setState({ date: date } );
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh) {
                    this.handleGoDetails({
                        id: this.state.debitId,
                        name: this.state.name,
                        paymentExpiringBefore: this.state.date.valueOf().toString()
                    });
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    saveDebit = async () => {
        const { t } = this.props;
        const { name, memberGroup, date, emailNotifications } = this.state;

        if (!selectValidation(memberGroup) && name !== '') {
            disableComponent(this, "disableField");

            let dataParameters = {
                "name": name,
                "groupId": memberGroup.value,
                "paymentExpiringBeforeDate": date.valueOf().toString(),
                "emailNotifications": emailNotifications
            };

            this.setState({ retryDebitToken: this.state.retryDebitToken + 1 }, ()=> {
                addDirectDebit(dataParameters).then(response => {
                    if (isSuccessfulCreate(response)) {
                        const {data} = response;
                        if (data.code != null && data.code.code === 500) {
                            this.setState({ debitId: data.id, retryDebitToken: 0 });
                            this.showNotification(t('SUCCESS_DEBIT_DEFINITION_CREATION'), "primary", NotificationSuccessIcon, true);
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.showNotification(data.error.message, "danger",
                                NotificationFailedIcon, null);
                        }
                    } else {
                        this.setState({ retryDebitToken: 0 });
                        this.showNotification(response.data.error.message, "danger",
                            NotificationFailedIcon, null);
                    }

                    enableComponent(this, "disableField");
                }).catch(e => {
                    enableComponent(this, "disableField");
                    this.setState({ isLoading: false });
                    if (!isEmpty(e.response)) {
                        if (this.state.retryDebitToken <= 2) {
                            refreshPrivateServiceToken(e.response, this.saveDebit, (errorResponse) => {
                                this.showNotification(errorResponse.data.error.message, "danger",
                                    NotificationFailedIcon, null);
                            });
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.showNotification(e.response.data.error.message, "danger",
                                NotificationFailedIcon, null);
                        }
                    } else {
                        this.setState({ retryDebitToken: 0 });
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });
            });
        } else {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationFailedIcon, null);
        }
    }




    initRequest = () => {
        this.setState({ isLoading: true });

        let parameters = {
            "page": 0,
            "pageSize": 100,
            "filterId": DIRECT_DEBIT_TYPE_ID,
            "status": -1 // Without status filter
        };


        getMemberGroupsByCompany(parameters)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    let values = [];

                    if (response.data !== null && response.data !== undefined) {
                        values = response.data.content.map(item => {
                            return { label: item.name, value: item.uuid }
                        })
                    }

                    this.setState({
                        isLoading: false,
                        memberGroupOptions: values
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);

                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });
    }


    handleGoDetails = (row) => {
        removeData("syncJobId");
        removeData("isExecuted");
        removeData("syncSummary");
        removeData("debitExecution");
        saveData("debit", row);
        saveData("syncJobAvailable", true);
        this.props.history.replace("/admin/debit/" + row.id + "/prepare-sync");
    };

    updateGroup = () => {}

    handleChangEmailNotifications = (prevEmailNotifications) => {
        this.setState({
            emailNotifications: !prevEmailNotifications
        });
    };



    render() {
        const { classes, t } = this.props;
        const { disableField, isLoading, emailNotifications } = this.state;
        return (
            <div>
                <Backdrop className={classes.backdrop} open={disableField || isLoading}>
                    <CircularProgress color={"inherit"} />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={false} sm={2} md={2} lg={3}/>
                    <GridItem xs={12} sm={8} md={8} lg={6}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <GridContainer>
                                            <GridItem xs={false} sm={2} md={3} lg={3}/>
                                            <GridItem xs={12} sm={8} md={6} lg={6}>
                                                <div className={classes.headerFormContainer}>
                                                    <HeaderForm title={t(formTitles[0]) + t('DIRECT_DEBIT_TEXT')} description={""} textAlign={"center"}/>
                                                </div>
                                            </GridItem>
                                            <GridItem xs={false} sm={2} md={3} lg={3}/>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            value={this.state.name}
                                            onChange={this.handleChangeValues}
                                            labelText={t('NAME_TEXT')}
                                            id="name"
                                            name="name"
                                            autoFocus={true}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <TextIcon className={classes.colorIcon}/>
                                                </InputAdornment>
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.formControl}>
                                            <InputLabel htmlFor="type" className={classes.selectTitle}>
                                                {t('TH_MEMBER_GROUP')}
                                            </InputLabel>
                                            <Select
                                                value={this.state.memberGroup}
                                                onChange={this.changeGroupSelect}
                                                isDisabled={this.state.disableField}
                                                isLoading={this.state.disableField}
                                                options={this.state.memberGroupOptions}
                                                placeholder={t('SELECT_TEXT')}
                                                closeMenuOnSelect={true}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div className={classes.defaultTimeContainer}>
                                            <InputLabel className={classes.selectTitle}>
                                                {t('PAYMENT_EXPIRING_BEFORE')}
                                            </InputLabel>
                                            <Datetime
                                                value={this.state.date}
                                                onChange={this.handleChangeDate}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat={"HH:mm:ss"}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            className={classes.checkboxLabel}
                                            control={
                                                <Checkbox
                                                    value={emailNotifications}
                                                    checked={emailNotifications}
                                                    onClick={() => this.handleChangEmailNotifications(emailNotifications)}
                                                    classes={{
                                                        checked: classes.checkBoxChecked,
                                                        root: classes.checkBox
                                                    }}
                                                />}
                                            label={t('EMAIL_NOTIFICATIONS_DEBIT')}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.center} style={{ marginTop: 20 }}>
                                            <Button
                                                color="white"
                                                disabled={this.state.disableField}
                                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                                onClick={() => this.props.history.goBack() }
                                            >
                                                {t('BTN_CANCEL')}
                                            </Button>
                                            <Button
                                                color="primary"
                                                className={classes.buttonSpacing}
                                                onClick={() => this.saveDebit()}
                                                disabled={this.state.disableField}
                                            >
                                                {t('BTN_SAVE')}
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={false} sm={2} md={2} lg={3} />
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

DirectDebitForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(DirectDebitForm)));
