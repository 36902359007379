/*
* Ginih Team
* Created by Marlon On October 11, 2020
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import formStyle from "../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    isSuccessfulRequest
} from "../../utils/helpersFunctions";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import {submitForgotPassword} from "../../services/accountService";



class ForgotPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.submitRequest = this.submitRequest.bind(this);
    }

    cleanData = () => {
        this.setState({
            email: '',
        });
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.close();

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };


    submitRequest() {
        const { t } = this.props;

        if (this.state.email === "") {
            this.showNotification(t('EMAIL_IS_REQUIRED'), "danger", NotificationErrorIcon, null);

        } else {
            let dataParameters = {
                "email": this.state.email,
            };

            disableComponent(this, "disableField");
            submitForgotPassword(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                    enableComponent(this, "disableField");
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


    render() {
        const { classes, t } = this.props;
        const { disableField, email } = this.state;

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={10} md={10}>
                        <CustomInput
                            value={email}
                            onChange={this.handleChangeValues}
                            labelText={t("EMAIL_TEXT")}
                            id="email"
                            name="email"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.close()}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <Button
                                color="primary"
                                className={classes.buttonSpacing}
                                onClick={() => this.submitRequest()}
                                disabled={disableField}
                            >
                                {t('BTN_SUBMIT')}
                            </Button>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ForgotPasswordForm.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(formStyle)(withTranslation()(ForgotPasswordForm)));