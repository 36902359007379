import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/core/icons icons
import DescriptionIcon from "@material-ui/icons/ShortText";
import CodeIcon from "@material-ui/icons/SortByAlpha";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Button from "../../../components/CustomButtons/Button";
import {
    disableComponent,
    enableComponent,
    formatDate,
    getCompanyCurrency,
    getValueFromCurrencyMask,
    isEmpty,
    isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation,
    setCurrencyValue,
    validateEmail,
} from "../../../utils/helpersFunctions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Datetime from "react-datetime";
import {getStoresAvailable, getStoresAvailableForPaymentRequest} from "../../../services/storeService";
import {addMemberPaymentRequest, addPaymentRequest, editPaymentRequest} from "../../../services/paymentService";
import paymentRequestDetails from "../../../models/PaymentRequestModel";
import CurrencyInput from "../../../components/CustomInput/CurrencyInput";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";

const animatedComponents = makeAnimated();


class PaymentRequestForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            name: paymentRequestDetails.name,
            description: paymentRequestDetails.description,
            email: paymentRequestDetails.email,
            amountMask: setCurrencyValue(paymentRequestDetails.amount),
            amount: paymentRequestDetails.amount,
            surchargeMask: setCurrencyValue(paymentRequestDetails.surcharge),
            surcharge: paymentRequestDetails.surcharge,
            discountMask: setCurrencyValue(paymentRequestDetails.discount),
            discount: paymentRequestDetails.discount,
            expirationDate: paymentRequestDetails.expirationDate,
            externalId: paymentRequestDetails.externalId,
            store: paymentRequestDetails.store,
            taxMask: setCurrencyValue(paymentRequestDetails.tax),
            tax: paymentRequestDetails.tax,
            currency: paymentRequestDetails.currencyCode,
            loadingStore: false,
            deactivateAfterExpiration: paymentRequestDetails.deactivateOption,
            storeOptions: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveRequest = this.saveRequest.bind(this);
        this.updateRequest = this.updateRequest.bind(this);
    }


    componentDidMount() {
        this.setState({
            loadingStore: true
        });
        this.request();
    }


    cleanData() {
        this.setState({
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh) {
                    this.props.closeForm("open", false, refresh)
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };

    handleDateChange = (date) => {
        this.setState({ expirationDate: date });
    };



    handleChangeDeactivateCheckbox = () => {
        this.setState(prevState => ({
            deactivateAfterExpiration: !prevState.deactivateAfterExpiration
        }));
    };


    changeStoreSelect = (store) => {
        this.setState({ store });
    };

    // Validate field required and save new product
    saveRequest() {
        const { t, memberId } = this.props;
        const { name, description, email, store, amount, expirationDate, discount, tax,
            surcharge, deactivateAfterExpiration } = this.state;

        if (name === "" || expirationDate === "" || selectValidation(store)) {
            this.showNotification(t('PAY_REQUEST_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if ((isEmpty(memberId) && email !== "" && validateEmail(email)) || (!isEmpty(memberId) && ((email !== "" && validateEmail(email)) || (email === "")))) {
                if (description.length <= 255) {
                    if (amount <= 0 || discount < 0 || tax < 0 || surcharge < 0) {
                        this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                    } else {
                        let dataParameters = [{
                            "storeUuid": store.value,
                            "name": name,
                            "externalId": paymentRequestDetails.externalId,
                            "description": description,
                            "email": email,
                            "tax": tax,
                            "total": amount,
                            "surcharge": surcharge,
                            "discount": discount,
                            "expirationDate": expirationDate.valueOf(),
                            "expiration": formatDate(new Date(expirationDate.valueOf())),
                            "deactivateAfterExpiration": deactivateAfterExpiration
                        }];

                        disableComponent(this, "disableField");

                        if (!isEmpty(memberId)) {
                            addMemberPaymentRequest(dataParameters, memberId)
                                .then((response) => this.onCompletePaymentRequest(response))
                                .catch((e) => this.onErrorPaymentRequest(e));
                        } else {
                            addPaymentRequest(dataParameters)
                                .then((response) => this.onCompletePaymentRequest(response))
                                .catch((e) => this.onErrorPaymentRequest(e));
                        }
                    }
                } else {
                    this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                        "danger", NotificationErrorIcon, null);
                }
            } else {
                this.showNotification(t('INVALID_EMAIL_MESSAGE'),
                    "danger", NotificationErrorIcon, null);
            }

        }
    }

    onCompletePaymentRequest = (response) => {
        if (isSuccessfulCreate(response)) {
            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
            this.cleanData();
        } else {
            enableComponent(this, "disableField");
            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
        }
    };

    onErrorPaymentRequest = (error) => {
        enableComponent(this, "disableField");
        if (error.response) {
            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
            validateSession(error.response, this.props.history);
        } else {
            this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }
    };


    // Validate field required and edit a product
    updateRequest() {
        const { t } = this.props;
        const { name, description, email, store, amount, expirationDate, discount, tax,
            surcharge, deactivateAfterExpiration } = this.state;

        if (name === "" || expirationDate === "" || selectValidation(store)) {
            this.showNotification(t('PAY_REQUEST_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (description.length <= 255) {
                if (amount <= 0 || discount < 0 || tax < 0 || surcharge < 0) {
                    this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    let dataParameters = [{
                        "paymentRequestId": paymentRequestDetails.uuid,
                        "storeUuid": store.value,
                        "name": name,
                        "externalId": paymentRequestDetails.externalId,
                        "description": description,
                        "email": email,
                        "tax": tax,
                        "total": amount,
                        "surcharge": surcharge,
                        "discount": discount,
                        "expirationDate": expirationDate.valueOf(),
                        "expiration": formatDate(new Date(expirationDate.valueOf())),
                        "deactivateAfterExpiration": deactivateAfterExpiration
                    }];

                    disableComponent(this, "disableField");
                    editPaymentRequest(dataParameters)
                        .then((response) => {
                            if (isSuccessfulRequest(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();
                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch((e) => {
                            enableComponent(this, "disableField");
                            if (e.response) {
                                this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                                validateSession(e.response, this.props.history);
                            } else {
                                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                            }
                        });
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                    "danger", NotificationErrorIcon, null);
            }
        }
    }


    request() {
        disableComponent(this, "disableField");
        if (this.props.action) {
            getStoresAvailableForPaymentRequest(paymentRequestDetails.uuid)
                .then((response) => {
                    enableComponent(this, "disableField");
                    if (response.data != null) {
                        let values = response.data.map(val => {
                            return { value: val.id, label: val.name };
                        });

                        this.setState({
                            storeOptions: values,
                            loadingStore: false,
                            store: paymentRequestDetails.store,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ loadingStore: false });
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        } else {
            getStoresAvailable()
                .then((response) => {
                    enableComponent(this, "disableField");
                    if (response.data !== null) {
                        let values = response.data.map(val => {
                            return { value: val.id, label: val.name };
                        });

                        this.setState({
                            storeOptions: values,
                            loadingStore: false
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ loadingStore: false });
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


    render() {
        const { classes, t } = this.props;
        const { currency } = this.state;

        const SaveButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveRequest()}
                disabled={this.state.disableField}
            >
                {t('BTN_ADD_REQUEST')}
            </Button>
        );

        const UpdateButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateRequest()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('EXPIRATION_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.expirationDate}
                                onChange={this.handleDateChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.amountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('TH_AMOUNT')}
                            id="amountMask"
                            name="amount"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={currency}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.taxMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('TAX_TEXT')}
                            id="taxMask"
                            name="tax"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={"%"}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.email}
                            onChange={this.handleChangeValues}
                            labelText={t('EMAIL_TEXT')}
                            id="email"
                            name="email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CodeIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.surchargeMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('SURCHARGE_TEXT')}
                            id="surchargeMask"
                            name="surcharge"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={currency}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.discountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('DISCOUNT_TEXT')}
                            id="discountMask"
                            name="discount"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={currency}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="store" className={classes.selectTitle}>
                                {t('STORE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.store}
                                onChange={this.changeStoreSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingStore}
                                options={this.state.storeOptions}
                                isDisabled={this.state.loadingStore}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={160}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.checkContainer}>
                            <CustomCheckbox
                                onChange={this.handleChangeDeactivateCheckbox}
                                value={this.state.deactivateAfterExpiration}
                                label={t('DEACTIVATE_AFTER_EXP')}/>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("open", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateButton/> : <SaveButton/>}
                        </div>
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

PaymentRequestForm.propTypes = {
    classes: PropTypes.object,
    memberId: PropTypes.string,
};

export default withRouter(withStyles(formStyle)(withTranslation()(PaymentRequestForm)));
