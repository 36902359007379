import React from "react";
import {nullController, isEmpty, setCurrencyValue, getRoundNumber, nullValidator} from "../helpersFunctions";
import TableCellDouble from "../../components/Table/TableCellDouble";
import {debitStatus, statusColors, statusText} from "../statusHelpers";
import Status from "../../components/Status/Status";
import TableActions from "../../components/TableActions/TableActions";
import {
    APPLICATION_TABLE_ACTIONS,
    ASSET_TABLE_ACTIONS,
    CASH_ACCOUNT_TABLE_ACTIONS,
    CATEGORY_TABLE_ACTIONS,
    COMPANY_API_KEYS_TABLE_ACTIONS,
    CONNECT_TABLE_ACTIONS,
    DISCOUNT_TABLE_ACTIONS,
    MEMBER_GROUP_DETAILS_TABLE_ACTIONS,
    MEMBER_GROUP_TABLE_ACTIONS,
    MEMBER_PAYMENT_METHOD_TABLE_ACTIONS,
    MEMBER_TABLE_ACTIONS, MEMBERSHIP_AUTH_DEFAULT_TABLE_ACTIONS,
    MENU_TABLE_ACTIONS,
    ORDERS_TABLE_ACTIONS,
    PAYMENT_REQUEST_SYNC_TABLE_ACTIONS, PAYMENT_REQUEST_SYNC_TABLE_ACTIONS_II,
    PAYMENT_REQUEST_TABLE_ACTIONS, PAYMENT_REQUEST_TABLE_ACTIONS_II,
    PRODUCT_DETAILS_TABLE_ACTIONS,
    PRODUCT_TABLE_ACTIONS,
    ROLE_TABLE_ACTIONS,
    STATUS_PAID_NOT_SYNC,
    STATUS_TRANSFERRED,
    STORE_TABLE_ACTIONS,
    TEMPLATE_ITEM_ACTIONS,
    TEMPLATE_TABLE_ACTIONS
} from "../../variables/coreConstants";
import TableCell from "@material-ui/core/TableCell";
import SimpleButton from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FilterNone";
import CheckIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";


/**
 *
 * @param context Wijmo grid context from template property props as function
 * @param table Table or view name (lowercase separated by _)
 * @param column Json property name binding to header column
 * @param classes View styles classes
 * @param callback Function callback if cell is clickable
 * @return {JSX.Element}
 */
export const generateCellTemplate = (context, table, column, classes, callback, t) => {

    if ((column === 'createdDate' || column === 'paymentExpiringBefore' || column === 'lastModifiedDate') && (table !== 'payment_requests' && table !== 'payments')) {
        return !isEmpty(context.item) && !isEmpty(context.item[column]) ? new Date(parseInt(context.item[column])).toLocaleString() : "---";
    } else if (column === 'action') {
        return (
            <TableCell className={classes.tableCellSingleGrid}>
                <TableActions
                    rowData={context.item}
                    openForm={callback}
                    options={getTableActions(table, context.item)}
                />
            </TableCell>
        )
    } else if (column === 'recordName') {
            return (
                <p
                    className={classes.flexGridCellLink}
                    onClick={() => { callback(context.item) }}>
                    {nullController(context.item, column)}
                </p>
            )
    } else if (column === 'name' && table !== 'payment_requests') {
            return (
                <p
                    className={classes.flexGridCellLink}
                    onClick={() => { callback(context.item) }}>
                    {nullController(context.item, column)}
                </p>
            )
    } else if ((column === 'status' || column === 'orderStatus') && (table !== 'debit_sync_result' && table !== 'payment_requests' && table !== 'member_payment_requests')) {
            return (
                <TableCell className={classes.tableCellSingleGrid}>
                    <Status
                        className={classes.status}
                        color={statusColors[context.item[column]]}
                        size="lg"
                        label={t(statusText[context.item[column]])}/>
                </TableCell>
            )
    } else if (column === 'productPhoto') {
        return nullController(context.item, 'productPhoto') == null ? 'No Image available' : <img src={nullController(context.item, column)} width={"55px"} height={"55px"} style={{resizeMode: "cover"}} alt={"Product " + context.item.id}/>;
    }  else if (column === 'photo') {
        return nullController(context.item, column) === ""
            ? (
            <div>
                <span className={classes.imageSrcContainer} style={{backgroundImage: `url(${null})`,}}/>
                <span className={classes.imageBackdrop}/>
                <span className={classes.imageButton}>
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="inherit"
                        className={
                            classes.imageTitle
                        }
                    >
                    {t(
                        "NO_IMAGE_AVAILABLE_TEXT"
                    )}
                </Typography>
            </span>
            </div>
        ) : (
            <img src={context.item[column] + "?" + new Date()} width={"55px"} height={"55px"} style={{resizeMode: "cover"}} alt={"Product " + context.item.id}/>
        )

    } else {
        switch (table) {
                case 'direct_debits':
                if (column === 'name') {
                    return (
                        <p
                            className={classes.flexGridCellLink}
                            onClick={() => { callback(context.item) }}>
                            {nullController(context.item, column)}
                        </p>
                    );
                }
                break;
            case 'direct_debit_history':
                if (column === 'recordNum') {
                    return (
                        <p
                            className={classes.flexGridCellLink}
                            onClick={() => { callback(context.item) }}>
                            {nullController(context.item, column)}
                        </p>
                    )
                }
                break;
            case 'debit_execution_result':
                if (column === 'memberName') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, 'memberExternalId')}/>
                    )
                }
                if (column === 'paymentMethod') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item) || (!isEmpty(context.item) && isEmpty(context.item[column]) && isEmpty(context.item.paymentMethodStatus))}
                            mainText={nullController(context.item, column)}
                            secondaryText={!isEmpty(context.item.paymentMethodStatus) ? t(debitStatus[context.item.paymentMethodStatus]) : ""}/>
                    )
                }
                if (column === 'paymentExternalId') {
                    return (
                        <TableCellDouble
                            className={context.item.disabled ? classes.tableCellSingleGrid + " " + classes.hoverRow : classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            singleItem={context.item.disabled}
                            title={"-"}
                            onClick={() => callback(context.item)}
                            mainText={nullController(context.item, 'paymentName')}
                            secondaryText={nullController(context.item, column)}/>
                    )
                }
                if (column === 'amountFormatted') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellSingleGrid + " " + classes.hoverRow}
                            singleItem
                            title={nullController(context.item, column)}/>
                    )
                }
                if (column === 'paymentStatus') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellSingleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item.paymentStatusMessage)}
                            textColor={statusColors[context.item[column]]}
                            title={t(debitStatus[context.item[column]])}
                            mainText={t(debitStatus[context.item[column]])}
                            secondaryText={t(context.item.paymentStatusMessage)}/>
                    )
                }
                if (column === 'syncStatus') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellSingleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item.syncStatusMessage)}
                            textColor={statusColors[context.item[column]]}
                            title={t(debitStatus[context.item[column]])}
                            mainText={t(debitStatus[context.item[column]])}
                            secondaryText={t(context.item.syncStatusMessage)}/>
                    )
                }
            case 'debit_sync_result':
                if (column === 'memberName') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            onClick={() => { callback(context.item.memberId, "m") }}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, 'memberExternalId')}/>
                    )
                }
                if (column === 'paymentMethod') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item) || (!isEmpty(context.item) && isEmpty(context.item[column]) && isEmpty(context.item.paymentMethodStatus))}
                            mainText={nullController(context.item, column)}
                            secondaryText={!isEmpty(context.item.paymentMethodStatus) ? t(debitStatus[context.item.paymentMethodStatus]) : ""}/>
                    )
                }
                if (column === 'paymentExternalId') {
                    return (
                        <TableCellDouble
                            className={context.item.disabled ? classes.tableCellSingleGrid + " " + classes.hoverRow : classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            singleItem={context.item.disabled && isEmpty(context.item.paymentId)}
                            title={"-"}
                            mainText={nullController(context.item, 'paymentName')}
                            secondaryText={nullController(context.item, column)}/>
                    )
                }
                if (column === 'amountFormatted') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellSingleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item.dueDate)}
                            title={nullController(context.item, column)}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, 'dueDate')}/>
                    )
                }
                if (column === 'status') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellSingleGrid + " " + classes.hoverRow}
                            singleItem={isEmpty(context.item.statusMessage)}
                            textColor={statusColors[context.item[column]]}
                            title={t(debitStatus[context.item[column]])}
                            mainText={t(debitStatus[context.item[column]])}
                            secondaryText={t(context.item.statusMessage)}/>
                    )
                }
                break;
            case 'reconciliations':
                if (column === 'originalAmount' || column === 'cancelledAmount') {
                    return (nullController(context.item, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(context.item, column)))).replace(nullController(context.item, 'currencySymbol') + "-", "- " + nullController(context.item, 'currencySymbol'));
                }
                if (column === 'depositLabel') {
                    return nullValidator(context.item, "depositId") && context.item.depositId !== ""
                        ? (
                            <SimpleButton className={classes.btnTableRowTxtBlue} size="small" color="primary" onClick={() => callback(context.item.depositId, context.item.walletAccountId)}>
                                {context.item[column]}
                            </SimpleButton>
                        ) : null
                }
                break;
            case 'deposit':
                if (column === 'originalAmount') {
                    return (nullController(context.item, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(context.item, column)))).replace(nullController(context.item, 'currencySymbol') + "-", "- " + nullController(context.item, 'currencySymbol'));
                }
                break;
            case 'transfers':
                if (column === 'date') {
                    return (
                        <p
                            className={classes.flexGridCellLink}
                            onClick={() => { callback(context.item) }}>
                            {nullController(context.item, column)}
                        </p>
                    );
                }
                break;
            case 'payment_requests':
                if (column === 'name') {
                    return (
                        <TableCellDouble
                            onClick={() => callback(context.item)}
                            className={classes.tableCellDoubleGrid + " " + classes.hoverRow}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, 'currencyCode') + " " + setCurrencyValue(getRoundNumber(nullController(context.item, 'amount')))} />
                    );
                }
                if (column === 'user' || column === 'memberExternalId') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}/>
                    );
                }
                if (column === 'amountPaidLabel') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}/>
                    );
                }
                if (column === 'status') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={t(statusText[context.item[column]])}
                            secondaryText={nullController(context.item, "paidDate")}/>
                    );
                }
                if (column === 'createdBy') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, "createdDate")}/>
                    );
                }
                if (column === 'link') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}>
                            {context.item[column] !== null ?
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <Tooltip id={context.item.uuid} title={t('COPY_TEXT') + " " + context.item[column]} placement="top-start">
                                        <IconButton
                                            size={"small"}
                                            onClick={() => callback(context.item)}>
                                            <CopyIcon className={classes.copyButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div> : ""
                            }
                        </TableCellDouble>
                    );
                }
                break;
            case 'member_payment_requests':
                if (column === 'user' || column === 'memberExternalId') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}/>
                    );
                }
                if (column === 'amountLabel') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}/>
                    );
                }
                if (column === 'amountPaidLabel') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}/>
                    );
                }
                if (column === 'status') {
                    return t(statusText[context.item[column]]);
                }
                if (column === 'createdBy') {
                    return (
                        <TableCellDouble
                            className={classes.tableCellDoubleGrid}
                            mainText={nullController(context.item, column)}
                            secondaryText={nullController(context.item, "createdDate")}/>
                    );
                }
                break;
            case 'payments':
                if (column === 'createdBy') {
                    return (
                        <p
                            className={classes.flexGridCellLink}
                            onClick={() => { callback(context.item) }}>
                            {nullController(context.item, column)}
                        </p>
                    )
                }
                break;
            case 'orders':
                if (column === 'identifier') {
                    return (
                        <p
                            className={classes.flexGridCellLink}
                            onClick={() => { callback(context.item) }}>
                            {nullController(context.item, column)}
                        </p>
                    )
                }
                break;
            case 'payment_methods':
                if (column === 'isDefault') {
                    return (
                        <TableCell className={classes.tableCellWijmo}>
                            {nullController(context.item, column)
                                ? <CheckIcon className={classes.thGreenIcon}/>
                                : null}
                        </TableCell>
                    )
                }
                break;
            case 'member_authentications':
                if (column === 'isDefault') {
                    return nullController(context.item, column) ? <CheckIcon className={classes.thGreenIcon}/> : null;
                }
                break;
        }
    }
};


const getTableActions = (table, item) => {
    let result = [];

    switch (table) {
        case 'members':
            result = MEMBER_TABLE_ACTIONS;
            break;
        case 'roles':
            result = ROLE_TABLE_ACTIONS;
            break;
        case 'stores':
            result = STORE_TABLE_ACTIONS;
            break;
        case 'cash_accounts':
            result = item.status === STATUS_TRANSFERRED ? CASH_ACCOUNT_TABLE_ACTIONS : undefined;
            break;
        case 'payment_requests':
            result = item.status === STATUS_PAID_NOT_SYNC ? (!isEmpty(item.link) ? PAYMENT_REQUEST_SYNC_TABLE_ACTIONS : PAYMENT_REQUEST_SYNC_TABLE_ACTIONS_II) : (!isEmpty(item.link) ? PAYMENT_REQUEST_TABLE_ACTIONS : PAYMENT_REQUEST_TABLE_ACTIONS_II);
            break;
        case 'member_payment_requests':
            result = item.status === STATUS_PAID_NOT_SYNC ? (!isEmpty(item.link) ? PAYMENT_REQUEST_SYNC_TABLE_ACTIONS : PAYMENT_REQUEST_SYNC_TABLE_ACTIONS_II) : (!isEmpty(item.link) ? PAYMENT_REQUEST_TABLE_ACTIONS : PAYMENT_REQUEST_TABLE_ACTIONS_II);
            break;
        case 'orders':
            result = item.orderStatus === STATUS_PAID_NOT_SYNC ? ORDERS_TABLE_ACTIONS : undefined;
            break;
        case 'payment_templates':
            result = TEMPLATE_TABLE_ACTIONS;
            break;
        case 'payment_template_items':
            result = TEMPLATE_ITEM_ACTIONS;
            break;
        case 'member_groups':
            result = MEMBER_GROUP_TABLE_ACTIONS;
            break;
        case 'member_group_details':
            result = MEMBER_GROUP_DETAILS_TABLE_ACTIONS;
            break;
        case 'payment_methods':
            result = MEMBER_PAYMENT_METHOD_TABLE_ACTIONS;
            break;
        case 'catalogs':
            result = MENU_TABLE_ACTIONS;
            break;
        case 'catalog_category_assignments':
            result = PRODUCT_DETAILS_TABLE_ACTIONS;
            break;
        case 'categories':
            result = CATEGORY_TABLE_ACTIONS;
            break;
        case 'category_product_assignments':
            result = PRODUCT_DETAILS_TABLE_ACTIONS;
            break;
        case 'discounts':
            result = DISCOUNT_TABLE_ACTIONS;
            break;
        case 'assets':
            result = ASSET_TABLE_ACTIONS;
            break;
        case 'products':
            result = PRODUCT_TABLE_ACTIONS;
            break;
        case 'integrations':
            result = CONNECT_TABLE_ACTIONS;
            break;
        case 'developer_settings':
            result = COMPANY_API_KEYS_TABLE_ACTIONS;
            break;
        case 'member_authentications':
            result = MEMBERSHIP_AUTH_DEFAULT_TABLE_ACTIONS;
            break;
        case 'applications':
            result = APPLICATION_TABLE_ACTIONS;
            break;
        default:
            break;

    }

    return result;
};

export const manageSelectItems = (flex, columnId, columnValue, propertyData, s, e) => {
    let selectedItems = [];
    let excluded = [];

    // console.log(s)
    // console.log(e)

    for (const row of flex.rows.filter(r => r.isSelected)) {
        selectedItems.push({ "id": row._data[columnId], [propertyData]: row._data[columnValue] });
    }

    for (const row of flex.rows.filter(r => r.isSelected === false)) {
        excluded.push({ "id": row._data[columnId] });

    }
    this.setState({
        selectedItems: selectedItems,
        excludedItems: excluded
    });
}
