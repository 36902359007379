import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import Datetime from "react-datetime";
import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";

import Button from "../CustomButtons/Button";
// @material-ui/react-select
import Select from "../../components/CustomSelect/CustomSelect";
// import makeAnimated from "react-select/animated";
import formStyle from "../../assets/jss/custom-components/formStyle";
import {getSchedulesDays} from "../../services/menuService";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import {NOTIFICATION_DURATION} from "../../variables/coreConstants";
import Snackbar from "../Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import scheduleDetails from "../../models/ScheduleModel";
import {validateSession} from "../../services/api";
import {formatDay} from "../../utils/helpersFunctions";
import InputLabel from "@material-ui/core/InputLabel";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();


class ScheduleForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: scheduleDetails.schedule.currentIndex,
            assignId: scheduleDetails.schedule.assignId,
            scheduleId: scheduleDetails.schedule.scheduleId,
            day: scheduleDetails.schedule.day,
            startTime: scheduleDetails.schedule.startTime,
            endTime: scheduleDetails.schedule.endTime,
            scheduleOptions: [],
            defaultDay: scheduleDetails.schedule.defaultDay,
            isLoading: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
        this.request = this.request.bind(this);
    }


    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    changeScheduleSelect = day => {
        this.setState({ day });
    };

    handleStartTimeChange = (date) => {
        this.setState({ startTime: date });
    };

    handleEndTimeChange = (date) => {
        this.setState({ endTime: date } );
    };

    addSchedule = () => {
        const { t } = this.props;

         if (this.state.day === "" || this.state.day === null) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon);

        } else {

            this.state.day.forEach(day =>
                scheduleDetails.schedules.push({
                    assignId: null,
                    scheduleId: null,
                    scheduleDaysId: day.value,
                    day: day.label,
                    timeFrom: this.state.startTime,
                    timeTo: this.state.endTime
                })
            );


            this.props.closeForm(false);
        }
    };

    editSchedule = () => {
        const { t, action } = this.props;

        if (this.state.day === "" || this.state.day === null) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon);

        } else {
            if (action && this.state.day.length > 1) {
                this.showNotification(t('ERROR_EDIT_SCHEDULE_MSG'), "danger", NotificationErrorIcon);

            } else {
                let index = this.state.currentIndex;


                let newSchedule = {
                    assignId: scheduleDetails.schedule.assignId,
                    scheduleId: scheduleDetails.schedule.scheduleId,
                    scheduleDaysId: this.state.day[0].value,
                    day: this.state.day[0].label,
                    timeFrom: this.state.startTime,
                    timeTo: this.state.endTime
                };


                scheduleDetails.schedules.splice(index, 1, newSchedule);
                this.setState({ schedules: scheduleDetails.schedules });
                this.props.closeForm(false);
            }
        }
    };


    request() {
        const { t } = this.props;

        let daysOptions;
        getSchedulesDays()
            .then((res) => {
                daysOptions = res.data.map( item => {
                    return { value: item.id , label: t(formatDay(item.id)) };
                });

                this.setState({
                    scheduleOptions: daysOptions,
                    isLoading: false
                });

            })
            .catch(function(error) {
                this.setState({ isLoading: false });

                if (error.response)
                    validateSession(error.response, this.props.history);
            });
    }


    render() {
        const { classes, t } = this.props;

        const AddSchedule = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.addSchedule()}
            >
                {t('BTN_CONFIRM')}
            </Button>
        );

        const EditSchedule = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.editSchedule()}
            >
                {t('BTN_CONFIRM')}
            </Button>
        );


        return (
            <div className={classes.scheduleContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={5}>
                                <div style={{marginTop: 11}}>
                                    <Select
                                        value={this.state.day}
                                        onChange={this.changeScheduleSelect}
                                        components={animatedComponents}
                                        isMulti
                                        options={this.state.scheduleOptions}
                                        isLoadig={this.state.isLoading}
                                        placeholder={t('SELECT_DAY_TEXT')}
                                        closeMenuOnSelect={false}
                                        maxMenuHeight={130}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <div className={classes.timerContainer}>
                                    <InputLabel className={classes.selectTitle}>
                                        {t('START_TIME_TEXT')}
                                    </InputLabel>
                                    <Datetime
                                        value={this.state.startTime}
                                        onChange={this.handleStartTimeChange}
                                        dateFormat={false}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={3} md={3}>
                                <div className={classes.timerContainer}>
                                    <InputLabel className={classes.selectTitle}>
                                        {t('END_TIME_TEXT')}
                                    </InputLabel>
                                    <Datetime
                                        value={this.state.endTime}
                                        onChange={this.handleEndTimeChange}
                                        dateFormat={false}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.buttonContainer}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.closeForm(false)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                {this.props.action ? <EditSchedule/> : <AddSchedule />}
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ScheduleForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ScheduleForm)));
