import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Card from "./Card";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";
import {useTranslation} from "react-i18next";


function AnalyticCard({ ...props }) {
    const { classes, loading, title, value, currencySymbol, icon } = props;
    const {t} = useTranslation();

    return (
        <div>
            <Card className={classes.cardFixed}>
                <CardHeader color="danger" stats icon>
                    <p className={classes.cardCategory}>{title}</p>
                    <h3 className={classes.cardTitle}>
                        {currencySymbol + value}
                    </h3>
                </CardHeader>
                <CardFooter className={classes.cardFooterFixed}>
                    <div className={classes.stats}>
                        {icon}
                        {loading ? t('LOADING_TEXT') : ""}
                    </div>
                </CardFooter>
            </Card>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.loading === nextProps.loading && prevProps.value === nextProps.value
        && prevProps.currencySymbol === nextProps.currencySymbol;
}


AnalyticCard.defaultProps = {
    loading: false,
    title: "Title",
    value: "0",
    currencySymbol: "",
    icon: ""
};

AnalyticCard.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currencySymbol: PropTypes.string.isRequired,
    icon: PropTypes.object
};

export default React.memo(withStyles(dashboardStyle)(AnalyticCard), propsAreEqual);
