import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { NavLink, withRouter } from "react-router-dom";
import Snackbar from "../../components/Snackbar/Snackbar.jsx";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    getTimeZone,
    isSuccessfulCreate,
    selectValidation,
    validateEmail,
    validateFields,
    validatePassword,
} from "../../utils/helpersFunctions";
import signInStyle from "../../assets/jss/custom-components/signInStyle";
import { createAccount } from "../../services/accountService";
import { NOTIFICATION_DURATION } from "../../variables/coreConstants";
import CompanyRegistration from "./CompanyRegistration";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import AccountForm from "./AccountForm";
import { DEFAULT_MEMBERSHIP_ID } from "../../variables/apiConstants";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import {
    IMG_GINIH_LOGO,
    IMG_WELCOME,
} from "../../variables/resourcesConstants";

export default function SignUp() {
    const classes = signInStyle();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [countryCode, setCountry] = React.useState("");
    const { t } = useTranslation();

    const [values, setValues] = React.useState({
        membershipId: DEFAULT_MEMBERSHIP_ID,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        showPassword: false,
        passwordError: true,
        companyName: "",
        city: "",
        postalCode: "",
        phoneNumber: "",
        address: "",
        taxId: "",
        message: "",
        color: "success",
        icon: NotificationSuccessIcon,
    });

    function showNotification(msj, color, icon, isComplete) {
        setValues({ ...values, message: msj, color: color, icon: icon });
        setOpen(true);
        window.setTimeout(function() {
            setOpen(false);
            if (isComplete === true) setActiveStep(activeStep + 1);
        }, NOTIFICATION_DURATION);
    }
    function hideNotification(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    }

    const handleField = (state, value) => {
        setValues({ ...values, [state]: value });
    };

    const handleCountryField = (value) => {
        setCountry(value);
    };

    const handleFieldPassword = (value) => {
        setValues({
            ...values,
            password: value,
            passwordError: !validatePassword(value),
        });
    };

    function validateAccountForm() {
        let fields = [
            values.firstName,
            values.lastName,
            values.email,
            values.password,
            values.confirmPassword,
        ];

        if (!validateFields(fields)) {
            showNotification(
                t("FIELDS_EMPTY"),
                "danger",
                NotificationErrorIcon
            );
            return false;
        } else if (!validateEmail(values.email)) {
            showNotification(
                t("INVALID_EMAIL_MESSAGE"),
                "danger",
                NotificationErrorIcon
            );
            return false;
        } else if (values.password !== values.confirmPassword) {
            showNotification(
                t("PASSWORD_ERROR_MESSAGE"),
                "danger",
                NotificationErrorIcon
            );
            return false;
        } else if (!validatePassword(values.password)) {
            setValues({ ...values, passwordError: true });
            showNotification(
                t("INVALID_PASSWORD_MESSAGE"),
                "danger",
                NotificationErrorIcon
            );

            return false;
        } else {
            return true;
        }
    }

    function validateCompanyForm() {
        let fields = [
            values.companyName,
            values.phoneNumber,
            values.city,
            values.address,
            values.taxId,
        ];

        if (!validateFields(fields) || selectValidation(countryCode)) {
            showNotification(
                t("FIELDS_EMPTY"),
                "danger",
                NotificationErrorIcon
            );
            return false;
        } else {
            return true;
        }
    }

    function completeSignUp() {
        if (validateCompanyForm()) {
            setLoading((prevLoading) => !prevLoading);
            let dataParameters = {
                membershipId: values.membershipId,
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                password: values.password,
                fullname: values.companyName,
                phoneNumber: values.phoneNumber,
                countryId: countryCode.value,
                address: values.address,
                postalCode: parseInt(values.postalCode),
                city: values.city,
                taxId: values.taxId,
                tzname: getTimeZone(),
            };

            createAccount(dataParameters)
                .then(function(response) {
                    if (isSuccessfulCreate(response)) {
                        setLoading((prevLoading) => !prevLoading);
                        showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        setLoading((prevLoading) => !prevLoading);
                        showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon
                        );
                    }
                })
                .catch((error) => {
                    setLoading((prevLoading) => !prevLoading);
                    showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon
                    );
                });
        }
    }

    /** STEP COMPONENT IMPLEMENTATION **/
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (validateAccountForm()) setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1);
    };

    const steps = [t("USER_TEXT"), t("COMPANY_REGISTRATION")];

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <AccountForm
                        handleFields={handleField}
                        handleFieldPassword={handleFieldPassword}
                        values={values}
                    />
                );
            case 1:
                return (
                    <CompanyRegistration
                        handleFields={handleField}
                        handleCountryField={handleCountryField}
                        values={values}
                    />
                );
            default:
                throw new Error("Unknown step");
        }
    };

    const goToSignIn = (history) => {
        history.push("/sign-in");
    };

    const NextButton = () => (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleNext()}
        >
            {t("BTN_NEXT")}
        </Button>
    );

    const SignUpButton = () => (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => completeSignUp()}
        >
            {loading ? (
                <div className={classes.loadingPaper}>
                    {t("REGISTER_TEXT")}
                    <CircularProgress
                        size={18}
                        className={classes.loadingButton}
                    />
                </div>
            ) : (
                t("REGISTER_TEXT")
            )}
        </Button>
    );

    const SignInButton = withRouter(({ history }) => (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => goToSignIn(history)}
        >
            {t("BTN_LOGIN")}
        </Button>
    ));

    //Set page title
    document.title = document.title
        ? document.title.split("-")[0].trim() + " - " + t("SIGNUP_TEXT")
        : t("SIGNUP_TEXT");

    return (
        <div className={classes.container}>
            <GridContainer style={{ height: "100%" }}>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.welcomeWrapper}>
                        <div className={classes.welcomeMessage}>
                            <img
                                className={classes.image}
                                src={IMG_WELCOME}
                                alt={"Login"}
                            />
                            <Typography component="h3" variant="h3">
                                {t("WELCOME_TITLE")}
                            </Typography>
                            <Typography component="h3" variant="subtitle1">
                                {t("WELCOME_MSG")}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <img
                                className={classes.logoContainer}
                                src={IMG_GINIH_LOGO}
                                alt={"Logo"}
                            />
                            <form className={classes.form} noValidate>
                                <Grid container>
                                    <Stepper
                                        activeStep={activeStep}
                                        className={classes.stepper}
                                    >
                                        {steps.map((label) => (
                                            <Step
                                                key={label}
                                                onClick={
                                                    label === t("USER_TEXT") &&
                                                    activeStep === 1
                                                        ? handleBack
                                                        : null
                                                }
                                            >
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Typography variant="h5">
                                            {t("ACCOUNT_CREATED_TITLE")}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t("ACCOUNT_CREATED_MESSAGE")}
                                        </Typography>
                                    </React.Fragment>
                                ) : (
                                    <div>
                                        {getStepContent(activeStep)}
                                        {activeStep === steps.length - 1 ? (
                                            <SignUpButton />
                                        ) : (
                                            <NextButton />
                                        )}
                                    </div>
                                )}
                                {activeStep === steps.length ? (
                                    <SignInButton />
                                ) : (
                                    <Grid container justify="flex-end">
                                        <Grid item>
                                            {t("HAVE_ACCOUNT")}
                                            <NavLink
                                                to="/sign-in"
                                                style={{
                                                    color: primaryColor[0],
                                                    textDecoration: "none",
                                                }}
                                                activeStyle={{
                                                    color: primaryColor[0],
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {t("BTN_LOGIN")}
                                            </NavLink>
                                        </Grid>
                                    </Grid>
                                )}
                            </form>
                            <Snackbar
                                place="tr"
                                color={values.color}
                                icon={values.icon}
                                message={values.message}
                                open={open}
                                closeNotification={hideNotification}
                                close
                            />
                        </div>
                    </Container>
                </GridItem>
            </GridContainer>
        </div>
    );
}
