import {getRoundNumber} from "../utils/helpersFunctions";

var productDetails = {
    id: null,
    name: "",
    externalId: "",
    code: "",
    description: "",
    type: "",
    minimumAge: 0,
    category: {
        value: 0,
        label: "Select...",
    },
    standardPrice: 0.00,
    tax: 10.00,
    photo: null,
    priceId: null,
    modifiers: [],
    oldModifiers: [],


    setProductDetail : function (data) {
        try {
            this.id = data.id;
            this.name = data.name;
            this.externalId = data.externalId;
            this.description = data.description;
            this.code = data.code;
            this.standardPrice = getRoundNumber(data.standardPrice.price);
            this.priceId = data.standardPrice.id;
            this.photo = data.photo;
            this.tax = getRoundNumber(data.standardPrice.tax);
            this.minimumAge = data.consumerMinimumLegalAge;
            this.type = { value: data.type.id, label: data.type.name };
        } catch (e) {}

    },

    cleanProductDetails : function () {
        this.id = null;
        this.name = "";
        this.externalId = "";
        this.code = "";
        this.description = "";
        this.type = "";
        this.category.value = 0;
        this.category.label = "Select...";
        this.minimumAge = 0;
        this.standardPrice = 0.00;
        this.tax = 0.00;
        this.priceId = null;
        this.photo = null;
        this.modifiers = [];
        this.oldModifiers= [];
    },

};

export default productDetails;