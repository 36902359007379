import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {addPaymentMethodProvider, getPaymentMethodProvidersByCountry} from "../../../services/accountService";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import BankIcon from "@material-ui/icons/AccountBalance";
import UserIcon from "@material-ui/icons/AccountCircle";
import KeyIcon from "@material-ui/icons/VpnKey";
import Muted from "../../../components/Typography/Muted";
import {
    disableComponent,
    enableComponent,
    getStringTextFormatByName,
    isSuccessfulCreate,
    selectValidation
} from "../../../utils/helpersFunctions";
import paymentMethodProviderDetails from "../../../models/PaymentMethodProviderModel";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";


const animatedComponents = makeAnimated();

class PaymentMethodProviderForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentProvider: '',
            iBan: '',
            apiKey: '',
            authUsername: '',
            authPassword: '',
            username: '',
            password: '',
            providerOptions: [],
            loadingProviders: true,
            showPassword: false,
            showAuthPassword: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.assignProvider = this.assignProvider.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleClickShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleClickShowAuthPassword = () => {
        this.setState(prevState => ({
            showAuthPassword: !prevState.showAuthPassword
        }));
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    changePaymentProviderSelect = paymentProvider => {
        this.setState({ paymentProvider } );
    };

    cleanData() {
        this.setState({
            paymentProvider: '',
            password: '',
            authPassword: '',
            username: '',
            authUsername: '',
            apiKey: '',
            iBan: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    getMethodTypes = () => {
        let types = 'No available';

        if (this.state.paymentProvider !== null && this.state.paymentProvider !== '') {
            let index = paymentMethodProviderDetails.allProviders
                .findIndex(val => val.id === this.state.paymentProvider.value);

            if (index !== -1) {
                types = getStringTextFormatByName(paymentMethodProviderDetails.allProviders[index].paymentTypes);
            }
        }



        return types;
    };


    request() {
        let paymentProvideValues;

        getPaymentMethodProvidersByCountry()
            .then((res) => {
                paymentMethodProviderDetails.allProviders = res.data;

                paymentProvideValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({ providerOptions: paymentProvideValues });
                this.setState({ loadingProviders: false });
            })

            .catch((err) => {
                this.setState({ loadingProviders: false });

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    assignProvider() {
        const { t } = this.props;
        if (selectValidation(this.state.paymentProvider)) {
            this.showNotification(t('PROVIDER_REQUIRED'), "danger", NotificationErrorIcon, null);

        } else {
            let dataParameters = {
                "providerId": this.state.paymentProvider.value,
                "iban": this.state.iBan,
                "apiKey": this.state.apiKey,
                "username": this.state.username,
                "password": this.state.password,
                "authUsername": this.state.authUsername,
                "authPassword": this.state.authPassword
            };


            disableComponent(this, "disableField");
            addPaymentMethodProvider(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }



    render(){
        const { classes, t } = this.props;

        const AssignProviderButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.assignProvider()}
                disabled={this.state.disableField}
            >
                {t('BTN_ASSIGN')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={11} sm={10} md={10}>
                        <div className={classes.selectControlPayment}>
                            <InputLabel htmlFor="providers" className={classes.selectTitle}>
                                {t('PAYMENTS_PROVIDERS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.paymentProvider}
                                onChange={this.changePaymentProviderSelect}
                                components={animatedComponents}
                                options={this.state.providerOptions}
                                placeholder={"Select..."}
                                isClearable={true}
                                maxMenuHeight={130}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                            <div className={classes.selectDescription}>
                                {
                                    (this.state.paymentProvider !== null && this.state.paymentProvider !== '')
                                        ? <Muted className={classes.selectDescription}>
                                            {t('PAYMENT_DESCRIPTION') + ": " + this.getMethodTypes() + "."}
                                        </Muted>
                                        : null
                                }
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={1} sm={2} md={2} />
                    <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.formBodyTitles}>{t('AUTHENTICATION_TEXT')}</h5>
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.iBan}
                            onChange={this.handleChangeValues}
                            labelText={t('IBAN_TEXT')}
                            id="iBan"
                            name="iBan"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <BankIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.apiKey}
                            onChange={this.handleChangeValues}
                            labelText={t('API_KEY_TEXT')}
                            id="apiKey"
                            name="apiKey"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <KeyIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.username}
                            onChange={this.handleChangeValues}
                            labelText={t('AUTHENTICATION_USERNAME_TEXT')}
                            id="username"
                            name="username"
                            autoFocus={false}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <UserIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.password}
                            onChange={this.handleChangeValues}
                            labelText={t('AUTHENTICATION_PASSWORD_TEXT')}
                            id="password"
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle-password-visibility"
                                        onClick={() => this.handleClickShowPassword()}>
                                        {this.state.showPassword
                                            ? <VisibilityOff className={classes.colorIcon}/>
                                            : <Visibility className={classes.colorIcon}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.formBodyTitles}>{t('AUTHORIZATION_TEXT')}</h5>
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.authUsername}
                            onChange={this.handleChangeValues}
                            labelText={t('AUTH_USERNAME_TEXT')}
                            id="authUsername"
                            name="authUsername"
                            autoFocus={false}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <UserIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={11} sm={6} md={6}>
                        <CustomInput
                            value={this.state.authPassword}
                            onChange={this.handleChangeValues}
                            labelText={t('AUTH_PASSWORD_TEXT')}
                            id="authPassword"
                            name="authPassword"
                            type={this.state.showAuthPassword ? 'text' : 'password'}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle-auth-password-visibility"
                                        onClick={() => this.handleClickShowAuthPassword()}>
                                        {this.state.showAuthPassword
                                            ? <VisibilityOff className={classes.colorIcon}/>
                                            : <Visibility className={classes.colorIcon}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <AssignProviderButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

PaymentMethodProviderForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(PaymentMethodProviderForm)));
