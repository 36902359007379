import React from 'react';
import Radio from '@material-ui/core/Radio';
import {grayColor, primaryColor} from "../../assets/jss/material-dashboard-react";
import withStyles from "@material-ui/core/styles/withStyles";


const ColorRadioButton = withStyles({
    root: {
        color: grayColor[0],
        marginBottom: 0,
        marginTop: 0,
        '&$checked': {
            color: primaryColor[0],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function SimpleRadioButton(props) {
    return (
        <ColorRadioButton {...props} />
    );
};

