import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    getData, isSuccessfulCreate,
} from "../../../utils/helpersFunctions";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {addDiscountMembershipAssigment} from "../../../services/discountService";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExternalIcon from "@material-ui/icons/LocalOffer";


class CompanyDiscountAssignmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: '',
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.assignCompany = this.assignCompany.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    cleanData() {
        this.setState({
            company: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null) {
                    this.props.closeForm("openCompany", false, refresh);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return }
        this.setState({ notification: false });
    };


    assignCompany() {
        const { t } = this.props;

        if (this.state.company === '') {
            this.showNotification(t('COMPANY_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let discount = getData("discount");

            let dataParameters = {
                "discountId": discount.uuid,
                "externalCompanyId": this.state.company
            };

            disableComponent(this, "disableField");
            addDiscountMembershipAssigment(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


    render(){
        const { classes, t } = this.props;
        const { disableField } = this.state;

        const AssignStoreButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.assignCompany()}
                disabled={disableField}
            >
                {t('BTN_ASSIGN')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.company}
                            onChange={this.handleChangeValues}
                            labelText={t("EXTERNAL_ID_TEXT")}
                            id="company"
                            name="company"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openCompany", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <AssignStoreButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

CompanyDiscountAssignmentForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(CompanyDiscountAssignmentForm)));