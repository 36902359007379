import { getService, postService, putService } from "./api";
import assetDetails from "../models/AssetModel";
import {
    BASE_URL_CORE,
    ASSET,
    ZONE_ASSETS,
    ASSET_BY_COMPANY,
    ASSET_BY_ZONE,
    ASSET_INFORMATION,
    ASSET_AVAILABLE,
    ASSET_STATUS, ASSET_TECHNOLOGY, QR_ASSETS_BY_STORE
} from "../variables/apiConstants";


export function getAssetsByCompany() {
    let url = BASE_URL_CORE + ASSET_BY_COMPANY;
    return getService(url);
}

export function getAssetsByZone(_zoneId) {
    let url = BASE_URL_CORE + ASSET_BY_ZONE + _zoneId;
    return getService(url);
}

export function getAssetsAvailable() {
    let url = BASE_URL_CORE + ASSET_AVAILABLE;
    return getService(url);
}

export function getAssetsActions() {
    let url = BASE_URL_CORE + ZONE_ASSETS;
    return getService(url);
}

export function getAssetsTechnologies() {
    let url = BASE_URL_CORE + ASSET_TECHNOLOGY;
    return getService(url);
}

export function addAsset(parameters) {
    let url = BASE_URL_CORE + ASSET;
    return postService(url, parameters);
}

export function editAsset(parameters) {
    let url = BASE_URL_CORE + ASSET_INFORMATION + assetDetails.id;
    return putService(url, parameters);
}

export function changeAssetStatus(_id, _value) {
    let url = BASE_URL_CORE + ASSET_STATUS + _id;
    let parameters = {
        "status": _value
    };

    return putService(url, parameters);
}

export function getQrCodeInformationByStore(storeId) {
    let url = BASE_URL_CORE + QR_ASSETS_BY_STORE;
    let parameters = {
        "storeId": storeId
    };

    return postService(url, parameters);
}