import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
// @material-ui/core icons
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import UserIcon from "@material-ui/icons/AccountCircle";
import TimeIcon from "@material-ui/icons/Alarm";
import DineInIcon from "@material-ui/icons/Deck";
import TakeAwayIcon from "@material-ui/icons/DirectionsRun";
import DeliveryIcon from "@material-ui/icons/LocalShipping";
// Constants and services
import {DELIVERY, DINE_IN, NOTIFICATION_DURATION, STATUS_PROGRESS, TAKE_AWAY} from "../../../variables/coreConstants";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Avatar from "@material-ui/core/Avatar";
import Muted from "../../../components/Typography/Muted";
import IconButton from "@material-ui/core/IconButton";
import {Typography} from "@material-ui/core";
import {statusText} from "../../../utils/statusHelpers";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import CallIcon from "@material-ui/icons/PhoneInTalk";
import Status from "../../../components/Status/Status";
import {
    getOrderEta,
    getRoundNumber,
    nullController,
    parseOtherInformation,
    setCurrencyValue
} from "../../../utils/helpersFunctions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import OrderStatusForm from "./OrderStatusForm";
import orderDetails from "../../../models/OrderModel";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "../../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";


class OrderDetailsForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            status: null,
            phone: '',
            notificationMessage: '',
            openForm: false,
            openPhone: false,
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.closeForm = this.closeForm.bind(this);
    }


    componentDidMount() {}


    cleanData() {
        // this.setState({
        // });
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openOrder", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleOpenForm = () => {
        this.setState({openForm: true});
    };
    handleCloseForm = () => {
        this.setState({openForm: false});
    };
    closeForm(state, value, status) {
        this.setState({[state]: value});
        if (status !== null) {
            orderDetails.status = status;
            this.setState({ status: status });
        }
    }


    handleOpenPhoneForm = (data) => {
        this.setState({openPhone: true, phone: (data !== null && data.customerPhone && data.customerPhone !== "")
                ? data.customerPhone : ""});
    };

    handleClosePhoneForm = () => {
        this.setState({openPhone: false, phone: "" });
    };


    render() {
        const { classes, t, data, isImageError } = this.props;

        return (
            <div className={classes.formOrderDetailContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rowContainer}>
                            <div className={classes.orderUserContainer}>
                                {data.photo != null && !isImageError
                                    ? <Avatar alt="Profile" src={data.photo} className={classes.iconAvatar}/>
                                    : <Avatar className={classes.iconAvatar}>
                                        <UserIcon className={classes.iconAvatarBlue} />
                                    </Avatar>
                                }
                                <div className={classes.orderNameContainer}>
                                    <Typography
                                        // component={"subtitle1"}
                                        variant={"h6"}
                                        className={classes.blackTitleText}>
                                        {data.name}
                                    </Typography>
                                    <Typography
                                        // component={"subtitle1"}
                                         className={classes.graySubText}>
                                        {nullController(data, "memberRole")}
                                    </Typography>
                                </div>
                                <IconButton
                                    onClick={() => this.handleOpenPhoneForm(data)}
                                    style={{marginLeft: 5, marginTop: 3}}
                                    className={classes.formIcon}
                                >
                                    <CallIcon className={classes.editIcon}/>
                                </IconButton>
                            </div>
                            <div className={classes.orderStatusContainer}>
                                <div className={classes.containerRight}>
                                    <Typography
                                        // component={"subtitle2"}
                                        variant={"h6"}
                                        className={classes.blackTitleText}>
                                        {this.state.status === null
                                            ? (t('TH_ORDER') + " " + t(statusText[data.status]))
                                            : (t('TH_ORDER') + " " + t(statusText[this.state.status]))
                                        }
                                    </Typography>
                                    <Tooltip id="tooltip-edit" title={t('CHANGE_TEXT')} placement="right-start">
                                        <IconButton
                                            onClick={this.handleOpenForm}
                                            disabled={orderDetails.disableOrderStatus()}
                                            className={classes.formIcon}
                                        >
                                            <EditIcon className={
                                                orderDetails.disableOrderStatus()
                                                    ? classes.editIconDisabled
                                                    : classes.editIcon}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.columnContainer}>
                            <Typography
                                // component={"subtitle2"}
                                variant={"h6"}
                                className={classes.blackTitleText + " " + classes.zeroMargin}>
                                {t('PAYMENT_TEXT')}
                            </Typography>
                            <Typography
                                // component={"subtitle2"}
                                variant={"h6"}
                                className={classes.descriptionGrayText}>
                                {nullController(data.paymentInformation.methodInformation, "name")}
                                {nullController(data.paymentInformation.methodInformation, "lastDigits") !== null
                                && nullController(data.paymentInformation.methodInformation, "lastDigits") !== ""
                                    ? t('ENDING_IN_TEXT') + data.paymentInformation.methodInformation.lastDigits : null}
                            </Typography>
                            <Typography
                                // component={"subtitle2"}
                                variant={"h6"}
                                className={classes.descriptionGrayText}>
                                {nullController(data.paymentInformation, "transactionId") !== ""
                                    ? "#" + data.paymentInformation.transactionId : null}
                            </Typography>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.containerRight}>
                            <Status
                                className={classes.status}
                                customColor={nullController(data.paymentInformation, "statusColor")}
                                size="lg"
                                label={nullController(data.paymentInformation, "statusText")}
                            />
                        </div>
                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.columnContainer}>
                            <Typography
                                // component={"subtitle2"}
                                variant={"h6"}
                                className={classes.blackTitleText + " " + classes.zeroMargin}>
                                {t('NOTE_TEXT')}
                            </Typography>
                            <Typography variant={"h6"} className={classes.descriptionGrayText}>
                                {data.comment}
                            </Typography>
                        </div>
                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={12} sm={10} md={10}>
                        <div className={classes.columnContainer}>
                            <div className={classes.orderTypeContainer}>
                                {
                                    data.deliveryTypeId === TAKE_AWAY
                                        ? <TakeAwayIcon className={classes.orderTypeIcon}/>
                                        : <div>
                                            {
                                                data.deliveryTypeId === DINE_IN
                                                    ? <DineInIcon className={classes.orderTypeIcon}/>
                                                    : <div>
                                                        {
                                                            data.deliveryTypeId === DELIVERY
                                                            ? <DeliveryIcon className={classes.orderTypeIcon}/> : null
                                                        }
                                                    </div>
                                            }
                                        </div>
                                }
                                <Typography variant={"h6"} className={classes.descriptionGrayText}>
                                    {data.deliveryType}
                                </Typography>
                            </div>
                            <div className={classes.zoneNameContainer}>
                                <Typography variant={"h6"} className={classes.descriptionGrayText}>
                                    {data.deliveryAddress}
                                </Typography>
                                {
                                    (data.locationIsVeryFar && data.deliveryTypeId === DINE_IN)
                                        ? (
                                            <div className={classes.warningContainer}>
                                                <WarningIcon className={classes.warningIcon}/>
                                                <Typography className={classes.warningText}>
                                                    {t('DINE_IN_LOCATION_ERROR_TXT')}
                                                </Typography>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        {
                            data.status === STATUS_PROGRESS
                                ? (
                                    <div className={classes.containerRight}>
                                        <TimeIcon className={classes.orderTypeIcon}/>
                                        <Typography variant={"h6"} className={classes.descriptionGrayText}>
                                            {getOrderEta(data.status, data.etaDate) !== null ? getOrderEta(data.status, data.etaDate) : ""}
                                        </Typography>
                                    </div>
                                ) : null
                        }

                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.orderDetailCard}>
                            <div className={classes.orderDetailTitleContainer}>
                                <Typography
                                    className={classes.blackTitleText + " " + classes.zeroMargin}>
                                    {t('ORDER_DETAILS_TEXT')}
                                </Typography>
                            </div>
                            <div className={classes.detailItemsContainer}>
                                {
                                    data.items.map((item, key) => {
                                        return (
                                            <div className={classes.item} key={key}>
                                                <div className={classes.quantityView}>
                                                    <Typography
                                                        // component={"subtitle2"}
                                                        variant={"h6"}
                                                        className={classes.descriptionGrayText}>
                                                        {item.quantity + "x"}
                                                    </Typography>
                                                </div>
                                                <div className={classes.productView}>
                                                    <div>
                                                        <Typography
                                                            // component={"subtitle2"}
                                                            variant={"h6"}
                                                            className={classes.descriptionGrayText}>
                                                            {item.name}
                                                        </Typography>
                                                        <div className={{flexDirection: "column"}}>
                                                            {
                                                                item.modifierItems.map((value, k) => {
                                                                    return (
                                                                        <div className={classes.columnContainer} key={k}>
                                                                            <Typography
                                                                                // component={"subtitle2"}
                                                                                variant={"h6"}
                                                                                className={classes.modifierSmallGrayText}>
                                                                                {
                                                                                    value.quantity + "x " +
                                                                                    value.name + ((value.surcharge > 0) ?
                                                                                            (" (+" + setCurrencyValue(getRoundNumber(value.surcharge)) + ")") :
                                                                                            ""
                                                                                    )
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.priceView}>
                                                    <Typography
                                                        // component={"subtitle2"}
                                                        variant={"h6"}
                                                        className={classes.descriptionGrayText}>
                                                        {data.currencySymbol}
                                                        {setCurrencyValue(getRoundNumber(item.total))}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </GridItem>
                    {
                        data.discountInformation !== null ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.discountContainer}>
                                    <Typography
                                        // component={"subtitle2"}
                                        variant={"h6"} className={classes.descriptionGrayText}>
                                        {nullController(data.discountInformation, "name")}
                                    </Typography>
                                    <Typography
                                        // component={"subtitle2"}
                                        variant={"h6"} className={classes.discountText}>
                                        {t('TOTAL_DISCOUNT_TXT') + ": " + setCurrencyValue(getRoundNumber(nullController(data.discountInformation, "total")))}
                                    </Typography>
                                </div>
                            </GridItem>
                        ) : null
                    }
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.columnContainer}>
                            <Typography
                                className={classes.descriptionGrayText + " " + classes.descriptionDarkBig}>
                                {t('SUBTOTAL_TEXT') + ": "}
                            </Typography>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.containerRight}>
                            <Typography
                                className={classes.descriptionGrayText + " " + classes.descriptionDarkBig}>
                                {data.currencySymbol}
                                {setCurrencyValue(getRoundNumber(data.total))}
                            </Typography>
                        </div>
                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.columnContainer}>
                            <Typography
                                className={classes.descriptionGrayText}>
                                {t('TIP_TEXT') + ": "}
                            </Typography>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.containerRight}>
                            <Typography
                                className={classes.descriptionGrayText}>
                                {data.currencySymbol}
                                {setCurrencyValue(getRoundNumber(data.tip))}
                            </Typography>
                        </div>
                    </GridItem>

                    {
                        data.deliveryTypeId === DELIVERY ? (
                            <GridItem  xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} md={6}>
                                        <div className={classes.columnContainer}>
                                            <Typography
                                                className={classes.descriptionGrayText}>
                                                {t('DELIVERY_FEE_TEXT') + ": "}
                                            </Typography>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6} sm={6} md={6}>
                                        <div className={classes.containerRight}>
                                            <Typography
                                                className={classes.descriptionGrayText}>
                                                {data.currencySymbol}
                                                {setCurrencyValue(getRoundNumber(parseFloat(data.deliveryFee)))}
                                            </Typography>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        ) : null
                    }

                    <div className={classes.spaceContainer}/>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.columnContainer}>
                            <Typography
                                className={classes.blackTitleText + " " + classes.zeroMargin}>
                                {t('TOTAL_TEXT') + ": "}
                            </Typography>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.containerRight}>
                            <Typography
                                className={classes.blackTitleText + " " + classes.zeroMargin}>
                                {data.currencySymbol}
                                {setCurrencyValue(getRoundNumber(parseFloat(data.total) + parseFloat(data.tip) + parseFloat(data.deliveryFee)))}
                            </Typography>
                        </div>
                    </GridItem>
                    <div className={classes.hardLine}/>
                    <GridItem xs={6} sm={6} md={6}>
                        <Typography className={classes.orderDetailFooterText}>
                            {t('GINIH_ORDER_ID') + ": " + data.uuid}
                        </Typography>
                    </GridItem>


                    {
                        nullController(data, "integrationData") !== "" ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.columnContainer} style={{marginTop: 10}}>
                                    <Typography
                                        className={classes.blackSmallTitleText + " " + classes.zeroMargin}>
                                        {t('OTHER_INFORMATION_TEXT')}
                                    </Typography>
                                    <TextField
                                        disabled={true}
                                        readOnly={true}
                                        draggable={false}
                                        multiline
                                        className={classes.disabledTextArea}
                                        rowsMax={7}
                                        variant={"outlined"}
                                        defaultValue={parseOtherInformation(nullController(data, "integrationData"))}
                                    />
                                </div>
                            </GridItem>
                        ) : null
                    }


                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openForm}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('FORM_ORDER_STATUS_TITLE')}
                        <Muted>
                            {t('FORM_ORDER_STATUS_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <OrderStatusForm closeForm={this.closeForm}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openPhone}
                    onClose={this.handleClosePhoneForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('FORM_PHONE_TITLE')}
                        <Muted>
                            {this.state.phone !== '' ? t('FORM_PHONE_DESCRIPTION') : t('FORM_NOT_PHONE_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <GridContainer>
                                <GridItem>
                                    <Typography
                                        // component={"subtitle2"}
                                        variant={"h6"}
                                        className={classes.blueTitle + " " + classes.zeroMargin}>
                                        {this.state.phone}
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.rightPaper}>
                                        <Button
                                            color="white"
                                            className={classes.buttonSpacing + " " + classes.cancelButton}
                                            onClick={() => this.handleClosePhoneForm()}
                                        >
                                            {t('BTN_CANCEL')}
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

OrderDetailsForm.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    isImageError: PropTypes.bool
};

export default withRouter(withStyles(formStyle)(withTranslation()(OrderDetailsForm)));
