import React from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import {dangerColor, grayColor, lightColors, primaryColor, whiteColor} from "../../assets/jss/material-dashboard-react";
import PropTypes from "prop-types";

function CreditCardDetails({...props}) {

    // const [hover, setHover] = React.useState(false);
    const [focus, setFocus] = React.useState(false);

    const {
        cvc,
        expiration,
        cardNumber,
        handleChangeCVC,
        handleChangeExpiryDate,
        handleChangeCardNumber,
        setError
    } = props;
    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
    } = usePaymentInputs();

    // setError(meta.error !== undefined);


    const styles = {
        container: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: whiteColor,
            minWidth: "100%",
            maxWidth: "100%",
            width: "100%",
            height: "40px",
            paddingLeft: "5px",
            borderRadius: "4px",
            border: "1px solid" + grayColor[4],
            "&:focus": {
                border: "1px solid" + primaryColor[0],
                boxShadow: "unset",
            },
        },
        containerHover: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: whiteColor,
            minWidth: "100%",
            maxWidth: "100%",
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            paddingLeft: "5px",
            border: "1px solid" + grayColor[8],
            boxShadow: "unset",
        },
        containerFocus: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: whiteColor,
            minWidth: "100%",
            maxWidth: "100%",
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            paddingLeft: "5px",
            border: "1px solid" + lightColors[5],
            boxShadow: "unset",
        },
        containerError: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: whiteColor,
            minWidth: "100%",
            maxWidth: "100%",
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            paddingLeft: "5px",
            border: "1px solid" + dangerColor[0],
            "&:hover,&:focus": {
                boxShadow: "unset",
                borderWidth: "2px",
                borderColor: primaryColor[0],
            },
        },
        inputNoStyle: {
            outline: "none !important",
            borderColor: "transparent",
            boxShadow: "unset"
        },
        cardNumberInput: {
            width: "57%", // no zip 55%
            outline: "none !important",
            borderColor: "transparent",
            boxShadow: "unset"
        },
        expirationInput: {
            width: "23%", // no zip 15%
            outline: "none !important",
            borderColor: "transparent",
            boxShadow: "unset",
        },
        cvcInput: {
            width: "20%", // no zip 10%
            outline: "none !important",
            borderColor: "transparent",
            boxShadow: "unset",
        },
        zipInput: {
            width: "15%",
            outline: "none !important",
            borderColor: "transparent",
            boxShadow: "unset"
        },
        errorMessage: {
            color: dangerColor[0],
            fontSize: "12px"
        },
        errorLabel: {
            "&.ui.basic.label": {
                border: "1px solid #e0b4b4 !important",
                color: "#9f3a38 !important"
            },
            whiteSpace: "normal",
            background: "#fff !important",

        },
        labelError: {
            fontSize: "0.75rem",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: "normal",
            lineHeight: 1.66,
            marginLeft: "5px",
            color: "red"
        }
    };

    return (
        <div>
            <div style={focus ? styles.containerFocus : styles.container} onFocus={()=>{setFocus(true)}} onBlur={()=>{setFocus(false)}}>
                <svg {...getCardImageProps({ images })} />
                <input {...getCardNumberProps({ onChange: handleChangeCardNumber })} value={cardNumber} style={styles.cardNumberInput}/>
                <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiration} style={styles.expirationInput}/>
                <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} style={styles.cvcInput}/>
            </div>

            {meta.isTouched && meta.error && <label style={styles.labelError}>{meta.error}</label>}
        </div>

    );
}


// function propsAreEqual(prevProps, nextProps) {
//     return prevProps.cardNumber === nextProps.cardNumber && prevProps.expiration === nextProps.expiration
//         && prevProps.cvc === nextProps.cvc;
// }

CreditCardDetails.propTypes = {
    handleChangeCardNumber: PropTypes.func,
    handleChangeExpiryDate: PropTypes.func,
    handleChangeCVC: PropTypes.func,
    setError: PropTypes.func,
    cardNumber: PropTypes.string,
    expiration: PropTypes.string,
    cvc: PropTypes.string,
};


export default CreditCardDetails;