import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import ExtIdIcon from "@material-ui/icons/Fingerprint";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";

// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {addRole, editRole} from "../../../services/roleService";
import roleDetails from "../../../models/RoleModel";
import Select from "../../../components/CustomSelect/CustomSelect";
import InputLabel from "@material-ui/core/InputLabel";
import makeAnimated from "react-select/animated";
import {
    disableComponent, enableComponent, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation, validateNewValue
} from "../../../utils/helpersFunctions";
import {getApplicationsByCompany, getApplicationsForRole} from "../../../services/applicationsService";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {getAllStoresAssignments, getStores} from "../../../services/storeService";

const animatedComponents = makeAnimated();


class RoleForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roleName: roleDetails.name,
            application: roleDetails.application,
            applicationOptions: [],
            defaultAppSelect: [],
            storeOptions: [],
            stores: [],
            oldStores: [],
            loadingApps: true,
            loadingStores: true,
            color: 'primary',
            notificationMessage: '',
            icon: NotificationSuccessIcon,
            notification: false,
            disableField: false,
            disableApps: false,
            disableStores: false,
        };
        this.saveRole = this.saveRole.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.request = this.request.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData(){
        this.setState({
            roleName: '',
            notificationMessage: '',
            color: 'primary',
            notification: false,
        });
    }


    changeAppsSelect = (application) => {
        this.setState({ application });
    };

    changeStoreSelect = (stores) => {
        this.setState({ stores });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh !== null)
                    this.props.closeForm("openRole", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request () {
        let appOptions, apps, storeOptions;

        if (this.props.action) {

            disableComponent(this, "disableApps");

            getApplicationsForRole(roleDetails.id)
                .then((response) => {
                    appOptions = response.data.unnasignedApplications.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    apps = response.data.assignedApplications.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    this.setState({
                        applicationOptions: appOptions,
                        application: apps,
                        loadingApps: false
                    });

                })

                .catch((error) => {
                    this.setState({ loadingApps: false} );
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    enableComponent(this, "disableApps");

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });

            getAllStoresAssignments(roleDetails.id)
                .then((response) => {
                    storeOptions = response.data.unassigned
                        .map( item => { return { value: item.id , label: item.name } });

                    let assigned = response.data.assigned
                        .map( item => { return {
                            value: item.entity.id,
                            label: item.entity.name
                        }});

                    let oldData = response.data.assigned
                        .map( item => { return {
                            assignId: item.assignId,
                            entityId: item.entity.id
                        }});

                    this.setState({
                        storeOptions: storeOptions,
                        stores: assigned,
                        oldStores: oldData,
                        loadingStores: false
                    });
                })
                .catch((error) => {
                    this.setState({ loadingStores: false });
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });

        } else {
            getApplicationsByCompany()
                .then((response) => {
                    appOptions = response.data.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    this.setState({
                        applicationOptions: appOptions,
                        loadingApps: false
                    });
                })

                .catch((error) => {
                    this.setState({ loadingApps: false });
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    enableComponent(this, "disableApps");

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });


            getStores()
                .then((response) => {
                    storeOptions = response.data.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    this.setState({
                        storeOptions: storeOptions,
                        loadingStores: false
                    });
                })
                .catch((error) => {
                    this.setState({ loadingStores: false} );
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    enableComponent(this, "disableStores");

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }

    }


    saveRole() {
        const { t } = this.props;

        if (this.state.roleName === "" || selectValidation(this.state.application)) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let appsId = this.state.application.map( item => {
                return { id: item.value };
            });

            let storesId;
            if (selectValidation(this.state.stores)) {
                storesId = [];

            } else {
                storesId = this.state.stores.map( item => {
                    return { id: item.value }
                });
            }


            let dataParameters = {
                "name": this.state.roleName,
                "applications": JSON.stringify(appsId),
                "stores": JSON.stringify(storesId)
            };

            disableComponent(this, "disableField");
            addRole(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if(error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }


    updateRole() {
        const { t } = this.props;

        if (this.state.roleName === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let stores;
            if (selectValidation(this.state.stores)) {
                stores = [];
            } else {
                stores = this.state.stores.map( item => {
                    if (validateNewValue(this.state.oldStores, "entityId", item.value)) {
                        let index = this.state.oldStores.findIndex(val => val.entityId === item.value);
                        return {
                            assignId: this.state.oldStores[index].assignId,
                            entityId: this.state.oldStores[index].entityId
                        };

                    } else {
                        return { assignId: null, entityId: item.value };
                    }
                });
            }

            let dataParameters = {
                "name": this.state.roleName,
                "stores": JSON.stringify(stores),
                "oldStores": JSON.stringify(this.state.oldStores)
            };


            disableComponent(this, "disableFiled");
            editRole(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableFiled");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableFiled");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history)
                });
        }
    }


    render() {
        const { classes, t } = this.props;

        const SaveRoleButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveRole()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        const UpdateRoleButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateRole()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.roleName}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="roleName"
                            name="roleName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExtIdIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="zoneStore" className={classes.selectTitle}>
                                {t('APP_ACCESS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.application}
                                onChange={this.changeAppsSelect}
                                components={animatedComponents}
                                options={this.state.applicationOptions}
                                defaultValue={this.state.defaultAppSelect}
                                isLoading={this.state.loadingApps}
                                isDisabled={this.state.disableApps}
                                name="application"
                                classNamePrefix="application"
                                placeholder={t('SELECT_APP_TEXT')}
                                isMulti
                                closeMenuOnSelect={false}
                                maxMenuHeight={80}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="store" className={classes.selectTitle}>
                                {t('STORE_ACCESS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.stores}
                                onChange={this.changeStoreSelect}
                                components={animatedComponents}
                                options={this.state.storeOptions}
                                isLoading={this.state.loadingStores}
                                isDisabled={this.state.disableStores}
                                name="stores"
                                classNamePrefix="stores"
                                placeholder={t('SELECT_STORE_TEXT')}
                                isMulti
                                closeMenuOnSelect={false}
                                maxMenuHeight={80}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openRole", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateRoleButton/> : <SaveRoleButton/>}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

RoleForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(RoleForm)));