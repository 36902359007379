import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import {getData, isSuccessfulRequest, nullController} from "../../../utils/helpersFunctions";
import {getPublicApplicationsByStore} from "../../../services/applicationsService";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {validateSession} from "../../../services/api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import {publishStore} from "../../../services/storeService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Muted from "../../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import StoreRequirementsForm from "./StoreRequirementsForm";



class PublicApplicationDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isLoadingApp: false,
            openAlert: false,
            appsData: this.props.data,
            errorData: [],
            errorMsg: '',
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
            errorData: [],
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.props.component.setState({ isLoading: true });
                    this.props.component.request();
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    openAlert = () => {
        this.setState({ openAlert: true });
    };
    closeAlert = () => {
        this.setState({ openAlert: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type)
            this.request(type);
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    changePublished = (value) => {
        if (!this.state.isLoadingApp) {
            const { t } = this.props;

            try {
                let store = getData("store");
                let activate = !value.activated;
                let isCurrentlyActive = false;
                let isError = false;

                let currentApp = this.state.appsData.findIndex(val => val.zoneAppId === value.zoneAppId);
                let activeApp = this.state.appsData.findIndex(val => val.activated === true);

                if (currentApp !== -1) {
                    if (activeApp !== -1) {
                        isCurrentlyActive = currentApp === activeApp;

                        if (isCurrentlyActive) {
                            activate = false;

                        } else {
                            // Show error
                            this.showNotification(t('ERROR_APP_SELECTED'), "danger", NotificationFailedIcon, null);
                            isError = true;
                        }
                    } else {
                        activate = true;
                    }
                } else {
                    // Show error
                    isError = true;
                    this.showNotification(t('SOMETHING_WENT_WRONG'), "primary", NotificationFailedIcon, null);
                }

                if (!isError) {

                    let dataParameters = {
                        "storeId": store.id,
                        "appId": value.appId,
                        "activate": activate
                    };

                    this.openAlert();
                    this.setState({ isLoadingApp: true } );
                    publishStore(dataParameters)
                        .then((response) => {
                            this.closeAlert();
                            if (isSuccessfulRequest(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                            } else {
                                this.showNotification(response.data.message, "danger", NotificationFailedIcon, null);
                            }

                        })
                        .catch((error) => {
                            this.setState({ isLoadingApp: false} );
                            let data = error.response.data;

                            if (error.response) {
                                if (error.response.status === 400) {
                                    this.setState({
                                        errorData: data.error ? data.error.requirements : [],
                                        errorMsg: data.message
                                    });
                                    this.openAlert();

                                } else {
                                    this.closeAlert();
                                    validateSession(error.response, this.props.history);
                                }
                            }
                        })
                }
            } catch (e) {
                this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
            }
        }
    };

    request(type){
        if (!type)
            this.setState({ isLoading: true} );

        let store = getData("store");

        getPublicApplicationsByStore(store.id)
            .then((res) => {
                this.setState({
                    isLoading: false,
                    isLoadingApp: false,
                    appsData: res.data,
                    errorData: []
                });
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                    isLoadingApp: false,
                    errorData: []
                });

                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                }
            });
    }



    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage, errorData, errorMsg, isLoadingApp } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                                <h4 style={{marginBottom: 5}}> {t('PUBLIC_APPS_TEXT')} </h4>
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACCESS_LEVEL')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_PUBLISH_STORE')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.appsData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'name')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'accessLevel')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            <FormControlLabel
                                                                className={classes.publishSwitch}
                                                                control={
                                                                    <CustomSwitch
                                                                        className={classes.switchBase}
                                                                        size="small"
                                                                        checked={val.activated}
                                                                        onChange={() => this.changePublished(val)}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.appsData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        aria-labelledby="form-requirements-dialog-title"
                        open={this.state.openAlert}
                        onClose={this.closeAlert}
                    >
                        <DialogTitle id="form-requirements-dialog-title">
                            {t('FORM_STORE_REQUIREMENTS_TEXT')}
                            <Muted>
                                {isLoadingApp ? t('SUBMIT_EXPLORER_REQUEST_MSG') : errorMsg}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <StoreRequirementsForm data={errorData} isLoading={isLoadingApp} closeForm={this.closeForm}/>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

PublicApplicationDetails.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(PublicApplicationDetails)));