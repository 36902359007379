import React, {useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
import {
    getCompanyCurrency
} from "../../../utils/helpersFunctions";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {
    DELETE,
    STATUS_ASSIGNED
} from "../../../variables/coreConstants";
import paymentTemplateDetails from "../../../models/PaymentTemplateModel";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


function PaymentTemplateDetails({ ...props }) {
    const { classes, data, template, component } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const {t} = useTranslation();
    const currency = getCompanyCurrency() + " ";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(page);
    };

    const handleEnableAction = () => {
        let result = false;

        try {
            result = template !== null && template.status !== STATUS_ASSIGNED;

        } catch (e) {}

        return result;
    };


    const handleTableAction = (action, formData) => {
        paymentTemplateDetails.cleanTemplateItemDetails();
        if ((action === 1 || action === DELETE) && handleEnableAction()) {
            paymentTemplateDetails.setPaymentTemplateItemDetails(formData);
            if (action === DELETE) {
                component.handleDeleteTemplateItem();
            } else {
                component.handleItemTableAction();
            }

        } else {
            component.handleError("CANNOT_UPDATE_TEMPLATE_ITEM");
        }
    };

    const handleOpenForm = () => {
        paymentTemplateDetails.cleanTemplateItemDetails();
        if (handleEnableAction()) {
            component.handleOpenItemForm();
        } else {
            component.handleError("CANNOT_ADD_TEMPLATE_ITEM");
        }
    };

        return (
        <div>
            <GridContainer>
                {/*<Backdrop className={classes.backdrop} open={isLoading}>*/}
                {/*    <CircularProgress color="inherit" />*/}
                {/*</Backdrop>*/}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'payment_template_items'}
                        title={t('PAYMENT_TEMPLATE_ITEMS_TEXT')}
                        exportFileName={t('PAYMENT_TEMPLATE_ITEMS_TEXT')}
                        headerFixed
                        // handleLoadMore={this.handleLoadMore}
                        // isLoading={isLoading}
                        data={data}
                        actions={[
                            { label: t('BTN_ADD'), function: handleOpenForm, isLoading: false, disabled: !handleEnableAction(), type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('UNIT_PRICE'), name: "unitPriceLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_SURCHARGE'), name: "surchargeLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('DISCOUNT_TEXT'), name: "discountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('EXPIRATION_DATE_TEXT'), name: "expirationDateLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_TOTAL'), name: "totalLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: handleTableAction, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data && prevProps.template === nextProps.template && prevProps.component === nextProps.component;
}

PaymentTemplateDetails.defaultProps = {
    data: [],
    template: []
};

PaymentTemplateDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    template: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    component: PropTypes.object.isRequired
};

export default withRouter(React.memo(withStyles(tableViewStyle)(PaymentTemplateDetails), propsAreEqual));
