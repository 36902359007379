import i18next from "../utils/i18n";

var memberDetails = {
    id: null,
    uuid: "",
    email: "",
    externalId: "",
    phone: "",
    clientCode: "",
    name: "",
    role: {
        value: 0,
        label: i18next.t('SELECT_TEXT'),
    },
    memberGroup: {
        uuid: "",
        name: "",
        externalId: "",
        type: {
            value: 0,
            label: i18next.t('SELECT_TEXT'),
        },
    },

    setMemberDetail : function (values) {
        try {
            this.id = values.id;
            this.uuid = values.uuid;
            this.email = values.email;
            this.externalId = values.externalId;
            this.clientCode = values.clientCode;
            this.name = values.name;
            this.phone = values.phone !== null ? values.phone : "";
            this.role.label = values.role.name;
            this.role.value = values.role.id;
        } catch (e) {}

    },
    setMemberGroupDetail : function (values) {
        try {
            this.memberGroup.name = values.name;
            this.memberGroup.uuid = values.uuid;
            this.memberGroup.externalId = values.externalId;

                if (values.groupType !== null && values.groupType !== undefined) {
                this.memberGroup.type.label = values.groupType.name;
                this.memberGroup.type.value = values.groupType.uuid;
            }
        } catch (e) {}

    },

    cleanMemberDetails : function () {
        this.id = null;
        this.uuid = "";
        this.email = "";
        this.clientCode = "";
        this.externalId = "";
        this.phone = "";
        this.name = "";
        this.role.label = i18next.t('SELECT_TEXT');
        this.role.value = 0;
    },
    cleanMemberGroupDetails : function () {
        this.memberGroup.name = "";
        this.memberGroup.uuid = "";
        this.memberGroup.externalId = "";
        this.memberGroup.type.label = i18next.t('SELECT_TEXT');
        this.memberGroup.type.value = 0;
    }


};

export default memberDetails;