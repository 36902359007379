var modifierDetails = {
    id: 0,
    name: "",
    externalId: "",
    required: false,
    maxItems: 1,
    modifierItems: [],

    cleanModifier : function () {
        this.id = 0;
        this.name = "";
        this.externalId = "";
        this.required = false;
        this.maxItems = 1;
        this.modifierItems = [];
    },
};

export default modifierDetails;