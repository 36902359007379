import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import {
    getCompanyCurrency,
    getData,
    getRoundNumber,
    getUser,
    getValueFromCurrencyMask,
    isSuccessfulCreate,
    nullController,
    selectValidation,
    setCurrencyValue,
} from "../../../../utils/helpersFunctions";

import { withTranslation } from "react-i18next";
import {
    ACTION_NEW,
    NOTIFICATION_DURATION_LARGE,
    WITHDRAWAL_MOVEMENT_ID,
} from "../../../../variables/coreConstants";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import SimpleButton from "@material-ui/core/Button";
import TransferFormDetails from "./TransferFormDetails";
import TransferFormReview from "./TransferFormReview";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateSession } from "../../../../services/api";
import { withRouter } from "react-router-dom";
import {
    addWalletMovement,
    getCashAccountsAvailable,
} from "../../../../services/transferService";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import { getWalletAccountDetail } from "../../../../services/cashAccountService";

const OBJECT_NAMES = require("../../../../variables/objectConstants");

class TransferFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            walletAccountId: props.match.params.waId,
            stepTotal: 3,
            requestError: false,
            isLoading: false,
            account: "",
            currency: "",
            accountOptions: [],
            amountMask: setCurrencyValue(0.0),
            amount: 0.0,
            balanceAvailable: 0.0,
            transactionCost: 0.0,
            note: "",
            walletAccount: getData(OBJECT_NAMES.WALLET_ACCOUNT_ID),
            disableField: false,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
        };
    }

    componentDidMount() {
        if (this.hasPaymentPermissionPermission()) {
            this.setState({ isLoading: true }, () => {
                getWalletAccountDetail(this.props.match.params.waId)
                    .then((response) => {
                        this.setState(
                            {
                                currency: response.data != null ? response.data.currencySymbol : "",
                                balanceAvailable: response.data != null ? getRoundNumber(response.data.balanceAvailable) : 0.0,
                            },
                            () => {
                                this.requestAccounts();
                            }
                        );
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        if (err.response) {
                            this.showNotification(
                                err.response.data.message,
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                            validateSession(err.response, this.props.history);
                        } else {
                            this.showNotification(
                                this.props.t("SOMETHING_WENT_WRONG"),
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                        }
                    });
            });
        } else {
            this.props.history.push("/admin/not-found");
        }
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION_LARGE
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value,
        });
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask =
                        value.length <= 11
                            ? setCurrencyValue(value)
                            : setCurrencyValue(0);

                    this.setState({
                        [name]:
                            value.length <= 11
                                ? getValueFromCurrencyMask(mask)
                                : 0.0,
                        [id]: mask,
                    });
                }
            } else {
                this.setState({
                    [name]: 0.0,
                    [id]: setCurrencyValue(0),
                });
            }
        }
    };

    changeAccountSelect = (account) => {
        this.setState({ account });
    };

    createTransfer = () => {
        const { t, history } = this.props;

        if (this.state.amount <= 0 || selectValidation(this.state.account)) {
            this.showNotification(
                t("TRANSFER_FIELDS_REQUIRED_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            if (this.state.balanceAvailable >= this.state.amount) {
                let dataParameters = {
                    walletAccountId: this.state.walletAccountId,
                    bankAccountId: this.state.account.value,
                    movementType: WITHDRAWAL_MOVEMENT_ID,
                    amount: this.state.amount,
                    note: this.state.note,
                };

                this.setState({ isLoading: true, disableField: true }, () => {
                    addWalletMovement(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.setState({
                                    step: this.state.stepTotal,
                                    isLoading: false,
                                    disableField: false,
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    disableField: false,
                                    requestError: true,
                                });
                                this.showNotification(
                                    response.data.message,
                                    "danger",
                                    NotificationErrorIcon,
                                    null
                                );
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                isLoading: false,
                                disableField: false,
                                requestError: true,
                            });
                            if (error.response) {
                                this.showNotification(
                                    error.response.data.message,
                                    "danger",
                                    NotificationFailedIcon,
                                    null
                                );
                                validateSession(error.response, history);
                            } else {
                                this.showNotification(
                                    t("SOMETHING_WENT_WRONG"),
                                    "danger",
                                    NotificationFailedIcon,
                                    null
                                );
                            }
                        });
                });
            } else {
                this.showNotification(
                    this.props.t("INSUFFICIENT_BALANCE_MSG"),
                    "danger",
                    NotificationErrorIcon,
                    null
                );
            }
        }
    };

    handleValidationForm = () => {
        if (this.state.amount <= 0 || selectValidation(this.state.account)) {
            this.showNotification(
                this.props.t("TRANSFER_FIELDS_REQUIRED_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            if (this.state.balanceAvailable >= this.state.amount) {
                this.setState({ step: this.state.step + 1 });
            } else {
                this.showNotification(
                    this.props.t("INSUFFICIENT_BALANCE_MSG"),
                    "danger",
                    NotificationErrorIcon,
                    null
                );
            }
        }
    };

    handleNext = () => {
        const { step, stepTotal } = this.state;

        switch (step) {
            case 1:
                this.handleValidationForm();
                break;
            case 2:
                this.createTransfer();
                break;
            case stepTotal:
                this.handleCancel();
                break;
            default:
                return true;
        }
    };

    hasPaymentPermissionPermission = () => {
        const { match } = this.props;
        let result = false;

        try {
            let userTabs = getUser().userRoutes;

            let index = userTabs.findIndex((x) => x.routeUrl === "/cash");
            if (index !== -1 && match.params.action === ACTION_NEW) {
                result = true;
            }
        } catch (e) {}

        return result;
    };

    getStepContent = (step, currency) => {
        const {
            amountMask,
            account,
            transactionCost,
            balanceAvailable,
        } = this.state;
        const { t } = this.props;

        switch (step) {
            case 1:
                return (
                    <TransferFormDetails
                        component={this}
                        cashAvailable={currency + setCurrencyValue(balanceAvailable)}
                    />
                );
            case 2:
                return (
                    <TransferFormReview
                        stepSuccess={false}
                        data={{
                            destinationAccount: nullController(
                                account,
                                "label"
                            ),
                            beneficiaryName: nullController(account, "name"),
                            accountNumber: nullController(account, "number"),
                            amount: currency + amountMask,
                            transactionPrice:
                                transactionCost > 0
                                    ? currency +
                                      setCurrencyValue(transactionCost)
                                    : t("FREE_TXT"),
                        }}
                    />
                );
            case 3:
                return (
                    <TransferFormReview
                        stepSuccess={true}
                        data={{
                            destinationAccount: nullController(
                                account,
                                "label"
                            ),
                            beneficiaryName: nullController(account, "name"),
                            accountNumber: nullController(account, "number"),
                            amount: currency + amountMask,
                            transactionPrice:
                                transactionCost > 0
                                    ? currency +
                                      setCurrencyValue(transactionCost)
                                    : t("FREE_TXT"),
                        }}
                    />
                );
            default:
                return null;
        }
    };

    handleGoBack = () => {
        this.setState({ step: this.state.step - 1 });
    };

    handleCancel = () => {
        this.props.history.push("/admin/cash");
    };

    requestAccounts() {
        const { t, history } = this.props;
        const { walletAccountId } = this.state;
        getCashAccountsAvailable(walletAccountId)
            .then((response) => {
                let values = response.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                        name: item.beneficiaryName,
                        number: item.number,
                    };
                });

                this.setState({
                    accountOptions: values,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, history);
                } else {
                    this.showNotification(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            step,
            stepTotal,
            isLoading,
            notification,
            notificationMessage,
            icon,
            currency,
            color,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={2} md={2} lg={2}>
                        {step > 1 && step < stepTotal ? (
                            <SimpleButton
                                onClick={this.handleGoBack}
                                className={classes.breadButton}
                            >
                                {"<- " + t("BTN_BACK")}
                            </SimpleButton>
                        ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        {this.getStepContent(step, currency)}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.center}>
                                            {step === stepTotal ? null : (
                                                <Button
                                                    disabled={
                                                        this.state.isLoading
                                                    }
                                                    className={
                                                        classes.cancelButton +
                                                        " " +
                                                        classes.horizontalSpacing
                                                    }
                                                    onClick={this.handleCancel}
                                                    color="white"
                                                >
                                                    {t("BTN_CANCEL")}
                                                </Button>
                                            )}

                                            {!this.state.requestError ? (
                                                <Button
                                                    disabled={
                                                        this.state.isLoading
                                                    }
                                                    onClick={this.handleNext}
                                                    color="primary"
                                                >
                                                    {step === 2
                                                        ? t("BTN_TRANSFER")
                                                        : step === stepTotal
                                                        ? t("BTN_FINISH")
                                                        : t("BTN_NEXT")}
                                                </Button>
                                            ) : null}
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={false} sm={2} md={2} lg={2} />
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={color}
                    icon={icon}
                    message={notificationMessage}
                    open={notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

TransferFormContainer.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(TransferFormContainer))
);
