import React from "react";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Status from "../../../components/Status/Status";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import Typography from "@material-ui/core/Typography";
import Loading from "../../../components/Loading/Loading";


class StoreRequirementsForm extends React.Component{

    render() {
        const { classes, t, data, isLoading } = this.props;

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {
                            isLoading ? <Loading/> : (
                                <GridContainer>
                                    {
                                        data.map((val, key) => {
                                            return (
                                                <GridItem xs={12} sm={9} md={9} key={key}>
                                                    <div style={{width: "100%", marginBottom: 20}}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={8} md={8}>
                                                                <Typography
                                                                    color={"primary"}
                                                                    className={classes.detailText}>
                                                                    {val.name}
                                                                </Typography>
                                                            </GridItem>
                                                            <GridItem  xs={12} sm={3} md={3}>
                                                                <Status
                                                                    className={classes.status}
                                                                    color={statusColors[val.status]}
                                                                    size="lg"
                                                                    label={t(statusText[val.status])}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>
                                                    </div>
                                                </GridItem>
                                            )
                                        })
                                    }
                                </GridContainer>
                            )
                        }
                    </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.closeForm("openAlert", false, null)}
                                >
                                    {t('BTN_CLOSE')}
                                </Button>
                            </div>
                        </GridItem>
                </GridContainer>
            </div>
        );
    }
}

StoreRequirementsForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object,
    isLoading: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default withRouter(withStyles(formStyle)(withTranslation()(StoreRequirementsForm)));
