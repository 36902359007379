import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import EmailIcon from "@material-ui/icons/Email";
import NotesIcon from "@material-ui/icons/ShortText";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent,
    enableComponent, isSuccessfulCreate, isSuccessfulRequest,
    selectValidation, validateEmail
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import dashboardUserDetails from "../../../models/DashboardUserModel";
import {
    createDelegatedUser, getEventNotifications, getEventNotificationsAvailableByUser,
    getPermissionsSet,
    getPermissionsSetAvailableByUser, updateDelegatedUser
} from "../../../services/accountService";

const animatedComponents = makeAnimated();


class DashboardUserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: dashboardUserDetails.firstName,
            lastName: dashboardUserDetails.lastName,
            email: dashboardUserDetails.email,
            permissions: dashboardUserDetails.permissions,
            notifications: dashboardUserDetails.notifications,
            permissionOptions: [],
            notificationOptions: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveUser = this.saveUser.bind(this);
        this.request = this.request.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    changePermissionSelect = permissions => {
        this.setState({ permissions });
    };

    changeNotificationsSelected = notifications => {
        this.setState({ notifications });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };


    cleanData() {
        this.setState({
            uuid: '',
            lastName: '',
            firstName: '',
            email: '',
            notifications: '',
            permissions: [],
            notificationMessage: '',
            notification: false,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.props.closeForm("openUser", false, refresh);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {
        let permissionValues;
        let notificationValues;

        if (this.props.action) {
            getPermissionsSetAvailableByUser(dashboardUserDetails.uuid)
                .then((res) => {
                    permissionValues = res.data.map( item => {
                        return { value: item.uuid , label: item.name };
                    });
                    this.setState({ permissionOptions: permissionValues });
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });

            getEventNotificationsAvailableByUser(dashboardUserDetails.uuid)
                .then((res) => {
                    notificationValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    this.setState({ notificationOptions: notificationValues });
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });
        } else {
            getPermissionsSet()
                .then((res) => {
                    permissionValues = res.data.map( item => {
                        return { value: item.uuid , label: item.name };
                    });
                    this.setState({ permissionOptions: permissionValues });
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });

            getEventNotifications()
                .then((res) => {
                    notificationValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    this.setState({ notificationOptions: notificationValues });
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });
        }
    }


    saveUser() {
        if (this.state.firstName === "" || this.state.lastName === "" || selectValidation(this.state.permissions)) {
            this.showNotification(this.props.t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (validateEmail(this.state.email)) {

                let permissionsId = [];

                permissionsId = this.state.permissions.map(item => {
                    return { uuid: item.value }
                });

                let notifications = [];

                if (this.state.notifications !== null && this.state.notifications !== undefined && this.state.notifications !== '') {
                    notifications = this.state.notifications.map(item => {
                        return { uuid: item.value }
                    });
                }


                let dataParameters = {
                    "uuid": dashboardUserDetails.uuid,
                    "email": this.state.email,
                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "permissions": JSON.stringify(permissionsId),
                    "notifications": JSON.stringify(notifications)
                };

                disableComponent(this, "disableField");
                if (this.props.action) {
                    updateDelegatedUser(dataParameters)
                        .then((response) => {
                            if (isSuccessfulRequest(response)) {
                                this.cleanData();
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            if (error.response) {
                                this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                                validateSession(error.response, this.props.history);
                            }
                        });
                } else {
                    createDelegatedUser(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.cleanData();
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            if (error.response) {
                                this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                                validateSession(error.response, this.props.history);
                            }
                        });
                }
            } else {
                this.showNotification(this.props.t('INVALID_EMAIL_MESSAGE'), "danger", NotificationErrorIcon, null);
            }
        }
    }


    render() {
        const { classes, t, action } = this.props;

        const SaveButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveUser()}
                disabled={this.state.disableField}
            >
                {action ? t('BTN_SAVE_CHANGES') : t('BTN_SAVE')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.firstName}
                            onChange={this.handleChangeValues}
                            labelText={t('FIRST_NAME_TEXT')}
                            id="firstName"
                            name="firstName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formInput
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <NotesIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.lastName}
                            onChange={this.handleChangeValues}
                            labelText={t('LAST_NAME_TEXT')}
                            id="lastName"
                            name="lastName"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formInput
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <NotesIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.email}
                            onChange={this.handleChangeValues}
                            labelText={t('EMAIL_TEXT')}
                            id="email"
                            name="email"
                            formControlProps={{
                                fullWidth: true,
                                className: classes.formInput
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.formDropDown}>
                            <InputLabel htmlFor="permissionId" className={classes.selectTitle}>
                                {t('PERMISSIONS_SET_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.permissions}
                                onChange={this.changePermissionSelect}
                                components={animatedComponents}
                                options={this.state.permissionOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={80}
                                isMulti
                                isClearable={false}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.formDropDown}>
                            <InputLabel htmlFor="permissionId" className={classes.selectTitle}>
                                {t('NOTIFICATIONS_TXT')}
                            </InputLabel>
                            <Select
                                value={this.state.notifications}
                                onChange={this.changeNotificationsSelected}
                                components={animatedComponents}
                                options={this.state.notificationOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={80}
                                isMulti
                                isClearable={false}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper} style={{marginTop:"50px"}}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openUser", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <SaveButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

DashboardUserForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(DashboardUserForm)));