import { getService, postService, putService } from "./api";
import activityDetails from "../models/ActivityModel";
import {
    ACTIVITIES_BY_COMPANY,
    ACTIVITY, ATTENDANCES_BY_SESSION,
    BASE_URL_CORE,
    SESSIONS_BY_ACTIVITY, SESSIONS_SUBSCRIBERS_BY_ACTIVITY,
} from "../variables/apiConstants";

export function getActivitiesByCompany() {
    let url = BASE_URL_CORE + ACTIVITIES_BY_COMPANY;

    return getService(url);
}

export function getSessionsByActivity(activityId) {
    let url = BASE_URL_CORE + SESSIONS_BY_ACTIVITY + activityId;
    return getService(url);
}

export function getSessionsSubscribersByActivity(activityId) {
    let url = BASE_URL_CORE + SESSIONS_SUBSCRIBERS_BY_ACTIVITY;
    let parameters = {
        "activity_id": activityId
    };
    return postService(url, parameters);
}

export function geyAttendancesBySession(sessionId) {
    let url = BASE_URL_CORE + ATTENDANCES_BY_SESSION;
    let parameters = {
        "activity_session_id": sessionId
    };

    return postService(url, parameters);
}

export function addActivity(parameters) {
    let url = BASE_URL_CORE + ACTIVITY;
    return postService(url, parameters);
}

export function editActivity(parameters) {
    let url = BASE_URL_CORE + ACTIVITY + "/" + activityDetails.id;
    return putService(url, parameters);
}