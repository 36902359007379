import React from "react";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";

// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";

import Button from "../../../components/CustomButtons/Button";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import NameIcon from "@material-ui/icons/Notes";
import SimpleButton from "@material-ui/core/Button";
import {
    disableComponent,
} from "../../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
import integrationDetails from "../../../models/IntegrationModel";
import Headers from "./Headers";



class IntegrationActionForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            uuid: integrationDetails.actionDetail.uuid,
            name: integrationDetails.actionDetail.name,
            endpoint: integrationDetails.actionDetail.endpoint,
            headers: integrationDetails.actionDetail.headers,
            headerName: '',
            headerValue: '',
            currentId: 1,
            disabledItem: false,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    cleanData() {
        this.setState({
            headerName: '',
            headerValue: '',
            headers: [],
            uuid: '',
            name: '',
            endpoint: '',
            currentId: 1
        });
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };



    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh)
                    this.props.closeForm(false);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };




    addItem = () => {
        let id = this.state.currentId;
        if (this.props.action && this.state.headers.length && this.state.headers.length > 0) {
            id = parseInt(this.state.headers[this.state.headers.length - 1].id) + 1;
            this.setState({ currentId: id });
        }
        let array = {id: id, name: "", value: "", isEditing: true, isUpdated: false};

        integrationDetails.actionDetail.headers.push(array);

        disableComponent(this, "disableField");
        this.setState({
            currentId: this.state.currentId + 1,
            headerName: "",
            headerValue: "",
            headers: integrationDetails.actionDetail.headers,
        });
    };



    updateActionDetails = () => {
        const { component, closeForm } = this.props;

        if (this.state.endpoint === "") {
            this.showNotification(this.props.t('ENDPOINT_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            try {
                let headers = this.state.headers.map(val => {
                    return {value: val.value, name: val.name};
                });

                let index = integrationDetails.actions.findIndex(val => val.uuid === this.state.uuid);

                if (index !== -1) {
                    integrationDetails.actions[index].uuid = this.state.uuid;
                    integrationDetails.actions[index].name = integrationDetails.actionDetail.name;
                    integrationDetails.actions[index].settingUuid = integrationDetails.actionDetail.settingUuid;
                    integrationDetails.actions[index].endpoint = this.state.endpoint;
                    integrationDetails.actions[index].headers = JSON.stringify(headers);

                    component.setState({
                        actions: integrationDetails.actions,
                    });

                    integrationDetails.cleanActionDetails();
                    closeForm(false);
                } else {
                    this.showNotification(this.props.t('ACTION_ALREADY_ADDED_MSG'), "danger", NotificationErrorIcon, null);
                }
            } catch (e) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationErrorIcon, null);
            }
        }
    };



    addActionDetails = () => {
        const { component, closeForm } = this.props;

        if (this.state.endpoint === "") {
            this.showNotification(this.props.t('ENDPOINT_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            try {
                let headers = this.state.headers.map(val => {
                    return {value: val.value, name: val.name};
                });

                let index = integrationDetails.actions.findIndex(val => val.uuid === this.state.uuid);

                if (index === -1) {

                    let action = { uuid: this.state.uuid, name: integrationDetails.actionDetail.name,
                        settingUuid: integrationDetails.actionDetail.settingUuid, endpoint: this.state.endpoint,
                        headers: JSON.stringify(headers)
                    };

                    integrationDetails.actions.push(action);
                    component.setState({
                        actions: integrationDetails.actions,
                    });

                    integrationDetails.cleanActionDetails();
                    closeForm(false);
                } else {
                    this.showNotification(this.props.t('ACTION_ALREADY_ADDED_MSG'), "danger", NotificationErrorIcon, null);
                }
            } catch (e) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationErrorIcon, null);
            }
        }
    };



    render() {
        const { classes, t } = this.props;

        const AddAction = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem}
                onClick={() => this.addActionDetails()}
            >
                {t('BTN_CONFIRM')}
            </Button>
        );

        const EditAction = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem}
                onClick={() => this.updateActionDetails()}
            >
                {t('BTN_CONFIRM_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.scheduleContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{width: "100%"}}>
                            <CustomInput
                                value={this.state.endpoint}
                                onChange={this.handleChangeValues}
                                labelText={t('ENDPOINT_TXT')}
                                id="endpoint"
                                name="endpoint"
                                autoFocus={true}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <NameIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h5 className={classes.titleNoMargin}>{t('HEADERS_TXT')}</h5>
                        <div className={classes.itemsGroupContainer}>
                            <Headers items={this.state.headers} component={this}/>
                        </div>
                        <div className={classes.addScheduleButton}>
                            <SimpleButton
                                size="small"
                                color="primary"
                                disabled={this.state.disableField || this.state.disabledItem}
                                onClick={this.addItem}
                            >
                                {t('BTN_ADD_HEADER')}
                            </SimpleButton>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{marginTop: 15}}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.closeForm(false)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                {this.props.action ? <EditAction/> : <AddAction />}
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

IntegrationActionForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(IntegrationActionForm)));