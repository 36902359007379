import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Muted from "../../../components/Typography/Muted";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core icons
import MenuIcon from "@material-ui/icons/Assignment";
// import LabelIcon from "@material-ui/icons/Label";
import RangeIcon from "@material-ui/icons/TrackChanges";
import ExternalIcon from "@material-ui/icons/LocalOffer"
import DescriptionIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {getAssetsAvailable} from "../../../services/assetService";
import {
    addZone,
    editZone,
    getAssetsApplicationsByZone,
    getAvailableGroupsByZone, getZoneGroupsByStore
} from "../../../services/zoneService";
import {getStores} from "../../../services/storeService";
import zoneDetails from "../../../models/ZoneModel";
import {
    disableComponent,
    enableComponent,
    getData,
    isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION, ZONE_TYPE_PAYMENT} from "../../../variables/coreConstants";
import {getApplicationsByCompany} from "../../../services/applicationsService";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {Hidden} from "@material-ui/core";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withTranslation} from "react-i18next";


const animatedComponents = makeAnimated();


class ZoneForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            zoneName: zoneDetails.name,
            zoneLabel: zoneDetails.label,
            externalId: zoneDetails.externalId,
            parentZone: '',
            zoneDescription: zoneDetails.description,
            zoneStore: zoneDetails.store,
            asset: [],
            apps: [],
            groups: [],
            range: zoneDetails.zoneRange,
            delivery: zoneDetails.delivery,
            deliveryBlocked: zoneDetails.deliveryBlocked,
            assetsOptions: [],
            appsOptions: [],
            zonesOptions: [],
            storesOptions: [],
            groupOptions: [],
            loadingStores: true,
            loadingParentZ: true,
            loadingAssets: true,
            loadingApps: true,
            loadingGroups: true,
            disabledStore: false,
            disabledApp: false,
            disabledAsset: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveZone = this.saveZone.bind(this);
        this.updateZone = this.updateZone.bind(this);
    }

    componentDidMount() {
        let store = getData("store");
        if (store) {
            this.setState({
                zoneStore: { value: store.id, label: store.name},
            });
            disableComponent(this, "disabledStore");
        }

        this.request();
    }


    cleanData() {
        this.setState({
            zoneName: '',
            zoneLabel: '',
            externalId: '',
            zoneDescription: '',
            range: 0,
            parentZone: '',
            zoneStore: '',
            asset: '',
            apps: '',
            groups: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openZone", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    handleDisableForm = () => {
        return zoneDetails.type === ZONE_TYPE_PAYMENT && this.props.action;
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    changeDelivery = () => {
        this.setState(prevState => ({
            delivery: !prevState.delivery
        }));
    };
    changeBlockDelivery = () => {
        this.setState(prevState => ({
            deliveryBlocked: !prevState.deliveryBlocked
        }));
    };
    changeAssetSelect = asset => {
        this.setState({ asset });
    };
    changeAppsSelect = apps => {
        this.setState({ apps });
    };
    changeGroupSelect = groups => {
        const { t } = this.props;

        if (groups !== null) {
            if (groups.length <= 1 ) {
                this.setState({ groups });

            } else {
                this.showNotification(t('CATEGORY_SELECT_FORBIDDEN_MSG'), "danger",
                    NotificationErrorIcon, null);
            }

        } else {
            this.setState({ groups });
        }
    };
    changeStoreSelect = zoneStore => {
        this.setState({ zoneStore });
    };

    // Method that request selects data
    request() {
        let storesValues;
        getStores()
            .then((res) => {
                storesValues = res.data.map(item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({
                    storesOptions: storesValues,
                    loadingStores: false
                });
            })
            .catch((err) => {
                this.setState({ loadingStores: false} );
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });


        /*let zonesValues;
        let store = getData("store");
        getZonesByStore(store.id)
            .then((res) => {
                zonesValues = res.data.map( item => {
                    return { value: item.id , label: item.label };
                });
                this.setState({ zonesOptions: zonesValues});
                this.setState({ loadingParentZ: false} );
            })
            .catch((err) => {
                this.setState({ loadingParentZ: false} );
                if (err.response) {
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                    console.log(err);
                }
            });*/


        let assetsValues;
        let appsValues;
        let groupsValues;

        if (this.props.action) {
            getAssetsApplicationsByZone()
                .then((res) => {
                    let assets = [];
                    // Set apps available
                    appsValues = res.data.applicationsAssignments.unnasignedApplications.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    // Set apps on zone
                    let apps = res.data.applicationsAssignments.assignedApplications.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    if (zoneDetails.type !== ZONE_TYPE_PAYMENT) {
                        // Set asset available
                        assetsValues = res.data.assetsAssignments.unassignedAssets.map( item => {
                            return { value: item.id , label: item.name };
                        });

                        // Set asset on zone
                        assets = res.data.assetsAssignments.assignedAssets.map( item => {
                            return { value: item.id , label: item.name };
                        });

                    }

                    this.setState({
                        appsOptions: appsValues,
                        apps: apps,
                        loadingApps: false,
                        assetsOptions: assetsValues,
                        asset: assets,
                        loadingAssets: false
                    });

                })
                .catch((err) => {
                    this.setState({
                        loadingAssets: false,
                        loadingApps: false
                    });
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });


            getAvailableGroupsByZone(zoneDetails.uuid)
                .then((res) => {
                    // Set groups available
                    groupsValues = res.data.allGroups.map( item => {
                        return { value: item.uuid , label: item.name };
                    });


                    // Set groups on zone
                    let groups = res.data.assignedGroups.map( item => {
                        return { value: item.uuid , label: item.name };
                    });



                    this.setState({
                        groupOptions: groupsValues,
                        groups: groups,
                        loadingGroups: false
                    });

                })
                .catch((err) => {
                    this.setState({ loadingGroups: false });

                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });

        } else {
            getAssetsAvailable()
                .then((res) => {
                    assetsValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    this.setState({ assetsOptions: assetsValues, loadingAssets: false });
                })

                .catch((err) => {
                    this.setState({ loadingAssets: false} );
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });


            getApplicationsByCompany()
                .then((res) => {
                    appsValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });
                    this.setState({
                        appsOptions: appsValues,
                        loadingApps: false
                    });

                    if (!this.props.action)
                        this.setState({ apps: [appsValues[0]] })
                })

                .catch((err) => {
                    this.setState({ loadingApps: false} );
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });

            let store = getData("store");
            getZoneGroupsByStore(store.uuid)
                .then((response) => {
                    groupsValues = response.data.map( item => {
                        return { value: item.uuid , label: item.name };
                    });
                    this.setState({
                        groupOptions: groupsValues,
                        loadingGroups: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingGroups: false} );
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });
        }

    }


    saveZone() {
        const { t } = this.props;

        if (this.state.zoneName === "" || selectValidation(this.state.zoneStore)) {
            this.showNotification(t('ZONE_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let storeId = this.state.zoneStore.value;
            let appId;
            if (selectValidation(this.state.apps)) {
                appId = [];
            } else {
                appId = this.state.apps.map( item => {
                    return { id: item.value };
                });
            }

            let parentZoneId = 0;
            // if (selectValidation(this.state.parentZone)) {
            //     parentZoneId = 0;
            // } else {
            //     parentZoneId = this.state.parentZone.value;
            // }

            let zoneGroup;
            if (selectValidation(this.state.groups)) {
                zoneGroup = "";
            } else {
                zoneGroup = this.state.groups[0]
            }

            let assetId;
            if (selectValidation(this.state.asset)) {
                assetId = [];
            } else {
                assetId = this.state.asset.map( item => {
                    return { id: item.value };
                });
            }


            let dataParameters = {
                "name": this.state.zoneName,
                "label": this.state.zoneLabel,
                "description": this.state.zoneDescription,
                "external_id": this.state.externalId,
                "store_id": storeId,
                "parent_zone_id": parentZoneId,
                "delivery": this.state.delivery,
                "zone_range": parseFloat(this.state.range),
                "assets": JSON.stringify(assetId),
                "applications": JSON.stringify(appId),
                "zone_group": zoneGroup.value,
                "block_delivery": false
            };



            disableComponent(this, "disableField");
            addZone(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        this.cleanData();

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }

                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }



    updateZone() {
        const { t } = this.props;

        if (this.state.zoneName === "") {
            this.showNotification(t('ZONE_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let zoneGroup;
            if (selectValidation(this.state.groups)) {
                zoneGroup = "";
            } else {
                zoneGroup = this.state.groups[0]
            }

            let appId;
            if (selectValidation(this.state.apps)) {
                appId = [];
            } else {
                appId = this.state.apps.map( item => {
                    return { id: item.value };
                });
            }

            let assetId;
            if (selectValidation(this.state.asset)) {
                assetId = [];
            } else {
                assetId = this.state.asset.map( item => {
                    return { id: item.value };
                });
            }

            let dataParameters = {
                "name": this.state.zoneName,
                "label": this.state.zoneLabel,
                "description": this.state.zoneDescription,
                "external_id": this.state.externalId,
                "delivery": this.state.delivery,
                "zone_range": parseFloat(this.state.range),
                "zone_group": zoneGroup.value,
                "assets": JSON.stringify(assetId),
                "applications": JSON.stringify(appId),
                "block_delivery": this.state.deliveryBlocked
            };


            disableComponent(this, "disableField");
            editZone(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    }
                    else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }


    render() {
        const { classes, t } = this.props;

        const SaveZoneButton = (( ) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveZone()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_ZONE')}
            </Button>
        ));

        const UpdateZoneButton = (( ) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateZone()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        ));


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.zoneName}
                            onChange={this.handleChangeValues}
                            disabled={this.handleDisableForm()}
                            labelText={t('ZONE_NAME_TEXT')}
                            id="zoneName"
                            name="zoneName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <MenuIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    {/*<GridItem xs={12} sm={6} md={3}>
                        <CustomInput
                            value={this.state.zoneLabel}
                            onChange={this.handleChangeValues}
                            labelText="Label"
                            id="zoneLabel"
                            name="zoneLabel"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <LabelIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>*/}
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="zoneStore" className={classes.selectTitle}>
                                {t('STORE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.zoneStore}
                                onChange={this.changeStoreSelect}
                                components={animatedComponents}
                                isDisabled={this.state.disabledStore || this.handleDisableForm()}
                                isLoading={this.state.loadingStores}
                                options={this.state.storesOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={200}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.zoneDescription}
                            onChange={this.handleChangeValues}
                            disabled={this.handleDisableForm()}
                            labelText={t('ZONE_DESCRIPTION_TEXT')}
                            id="zoneDescription"
                            name="zoneDescription"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>

                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="groups" className={classes.selectTitle}>
                                {t('ZONE_GROUPS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.groups}
                                onChange={this.changeGroupSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingGroups || this.handleDisableForm()}
                                isDisabled={this.state.loadingGroups}
                                options={this.state.groupOptions}
                                name="groups"
                                classNamePrefix="groups"
                                placeholder={t('SELECT_TEXT')}
                                isMulti
                                closeMenuOnSelect={false}
                                maxMenuHeight={80}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            disabled={this.handleDisableForm()}
                            labelText={t('EXTERNAL_ID_TEXT')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    {/*<GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="parentZone" className={classes.selectTitle}>
                                Parent Zone
                            </InputLabel>
                            <Select
                                value={this.state.parentZone}
                                onChange={this.changeZoneSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingParentZ}
                                options={this.state.zonesOptions}
                                placeholder={"Select..."}
                                isClearable={true}
                                closeMenuOnSelect={true}
                                maxMenuHeight={200}
                                theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: primaryColor[0],
                                },
                            })}
                            />
                        </div>
                    </GridItem>*/}
                    <GridItem xs={6} sm={3} md={3}>
                        {
                            zoneDetails.type !== ZONE_TYPE_PAYMENT ? (
                                <FormControlLabel
                                    className={classes.rightContainer}
                                    disabled={this.handleDisableForm()}
                                    control={
                                        <CustomSwitch
                                            className={classes.switchBase}
                                            size="small"
                                            checked={this.state.delivery}
                                            onChange={this.changeDelivery}
                                        />
                                    }
                                    label={t('DELIVERY_TEXT')}
                                />
                            ) : (
                                <FormControlLabel
                                    className={classes.rightContainer}
                                    control={
                                        <CustomSwitch
                                            className={classes.switchBase}
                                            size="small"
                                            checked={this.state.deliveryBlocked}
                                            onChange={this.changeBlockDelivery}
                                        />
                                    }
                                    label={t('BLOCK_DELIVERY_TEXT')}
                                />
                            )
                        }

                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="apps" className={classes.selectTitle}>
                                {t('APPLICATIONS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.apps}
                                onChange={this.changeAppsSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingApps}
                                isDisabled={this.state.disabledApp || this.handleDisableForm()}
                                options={this.state.appsOptions}
                                name="apps"
                                classNamePrefix="apps"
                                placeholder={t('SELECT_APP_TEXT')}
                                isMulti
                                closeMenuOnSelect={false}
                                maxMenuHeight={80}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.subtitleContent}>
                            <h5 style={{color: 'black'}}>
                                {t('IDENTIFIER_SECTION_TITLE')}
                                <Muted>
                                    {t('IDENTIFIER_SECTION_DESCRIPTION')}
                                </Muted>
                            </h5>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.subtitleContent}>
                            <div className={classes.subtitleContent}>
                                <h5 style={{color: 'black'}}>
                                    {t('QR_CODE_SECTION_TITLE')}
                                    <Muted>
                                        {t('QR_CODE_SECTION_DESCRIPTION')}
                                    </Muted>
                                </h5>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="assetZone" className={classes.selectTitle}>
                                {t('IDENTIFIERS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.asset}
                                onChange={this.changeAssetSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingAssets}
                                isDisabled={this.state.disabledAsset || this.handleDisableForm()}
                                options={this.state.assetsOptions}
                                name="asset"
                                classNamePrefix="asset"
                                placeholder={t('SELECT_TEXT')}
                                isMulti
                                closeMenuOnSelect={false}
                                maxMenuHeight={80}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <Hidden xsDown={true} xsUp={true}>
                            <CustomInput
                                value={this.state.range}
                                onChange={this.handleChangeValues}
                                labelText={t('BEACON_RANGE_TEXT')}
                                id="range"
                                name="range"
                                type={"number"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <RangeIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </Hidden>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openZone", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateZoneButton/> : <SaveZoneButton/>}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ZoneForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ZoneForm)));
