import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/core/icons icons
import DiscountIcon from "@material-ui/icons/ConfirmationNumber";
import DescriptionIcon from "@material-ui/icons/ShortText";
import CodeIcon from "@material-ui/icons/SortByAlpha";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Button from "../../../components/CustomButtons/Button";
import {
    disableComponent,
    enableComponent, formatDatetimeToDate, getCompanyCurrency, getValueFromCurrencyMask,
    isSuccessfulCreate, isSuccessfulRequest, removeData, saveData, selectValidation, setCurrencyValue,
} from "../../../utils/helpersFunctions";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {
    NOTIFICATION_DURATION
} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import NotificationErrorIcon from "@material-ui/icons/Error";
import discountDetail from "../../../models/DiscountModel";
import {addDiscount, editDiscount, getDiscountTypeValue} from "../../../services/discountService";
import Datetime from "react-datetime";
import CurrencyInput from "../../../components/CustomInput/CurrencyInput";

const animatedComponents = makeAnimated();


class DiscountForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loadingType: false,
            code: discountDetail.code,
            maxAmount: discountDetail.maxAmount,
            maxAmountMask: setCurrencyValue(discountDetail.maxAmount),
            quantity: discountDetail.quantity,
            quantityPerUser: discountDetail.quantityPerUser,
            typeValue: discountDetail.typeValue,
            value: discountDetail.value,
            valueMask: setCurrencyValue(discountDetail.value),
            typeOptions: [],
            discountName: discountDetail.name,
            description: discountDetail.description,
            validFrom: discountDetail.validFrom,
            validTo: discountDetail.validTo,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveDiscount = this.saveDiscount.bind(this);
        this.updateDiscount = this.updateDiscount.bind(this);
    }


    componentDidMount() {
        this.request();
    }


    cleanData() {
        this.setState({
            discountName: '',
            description: '',
            code: '',
            typeValue: '',
            valueMask: setCurrencyValue(0.00),
            value: 0,
            maxAmount: 0,
            maxAmountMask: setCurrencyValue(0.00),
            quantity: 0,
            quantityPerUser: 0,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh) {
                    this.props.closeForm("openDiscount", false, refresh)
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };

    handleValidFromChange = (date) => {
        this.setState({ validFrom: date });
    };

    handleValidToChange = (date) => {
        if (this.state.validFrom < date) {
            this.setState({ validTo: date } );
        }
    };

    changeTypeSelect = typeValue => {
        this.setState({ typeValue });
    };

    // Validate field required and save new product
    saveDiscount() {
        const { t } = this.props;
        const { discountName, description, value, typeValue, quantity, validFrom, validTo, maxAmount, code,
            quantityPerUser } = this.state;

        if (discountName === "" || code === "" || selectValidation(typeValue)) {
            this.showNotification(t('DISCOUNT_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (description.length <= 255) {
                if (value < 0 || quantity < 0 || maxAmount < 0 || quantityPerUser < 0) {
                    this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    if (value > 100) {
                        this.showNotification(t('INVALID_VALUE_PERCENTAGE_MSG'), "danger", NotificationErrorIcon, null);
                    } else {

                        let dataParameters = {
                            "name": discountName,
                            "code": code,
                            "description": description,
                            "valueType": typeValue.value,
                            "value": value,
                            "quantity": quantity,
                            "validFrom": formatDatetimeToDate(validFrom.toDate()),
                            "validTo": formatDatetimeToDate(validTo.toDate()),
                            "maxAmount": maxAmount,
                            "maxQuantityUser": quantityPerUser
                        };

                        disableComponent(this, "disableField");
                        addDiscount(dataParameters)
                            .then((response) => {
                                if (isSuccessfulCreate(response)) {
                                    this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                    this.cleanData();
                                    removeData("discount");

                                    if (response.data.entity) {
                                        saveData("discount", response.data.entity);
                                        this.props.history.push('quick-actions-discount');
                                    }
                                } else {
                                    enableComponent(this, "disableField");
                                    this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                                }
                            })
                            .catch((e) => {
                                enableComponent(this, "disableField");
                                if (e.response) {
                                    this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                                    validateSession(e.response, this.props.history);
                                } else {
                                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                                }
                            });
                    }
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                    "danger", NotificationErrorIcon, null);
            }
        }
    }


    // Validate field required and edit a product
    updateDiscount() {
        const { t } = this.props;
        const { discountName, description, value, typeValue, quantity, quantityPerUser, validFrom, validTo, maxAmount,
            code } = this.state;

        if (discountName === "" || code === "" || selectValidation(typeValue)) {
            this.showNotification(t('DISCOUNT_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (description.length <= 255) {
                if (value < 0 || quantity < 0 || maxAmount < 0 || quantityPerUser < 0) {
                    this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    if (value > 100) {
                        this.showNotification(t('INVALID_VALUE_PERCENTAGE_MSG'), "danger", NotificationErrorIcon, null);
                    } else {

                        let dataParameters = {
                            "name": discountName,
                            "description": description,
                            "code": code,
                            "valueType": typeValue.value,
                            "value": value,
                            "quantity": parseInt(quantity),
                            "validFrom": formatDatetimeToDate(validFrom.toDate()),
                            "validTo": formatDatetimeToDate(validTo.toDate()),
                            "maxAmount": maxAmount,
                            "maxQuantityUser": quantityPerUser

                        };

                        disableComponent(this, "disableField");
                        editDiscount(dataParameters, discountDetail.uuid)
                            .then((response) => {
                                if (isSuccessfulRequest(response)) {
                                    this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                    this.cleanData();
                                } else {
                                    enableComponent(this, "disableField");
                                    this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                                }
                            })
                            .catch((e) => {
                                enableComponent(this, "disableField");
                                if (e.response) {
                                    this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                                    validateSession(e.response, this.props.history);
                                } else {
                                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                                }
                            });
                    }
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                    "danger", NotificationErrorIcon, null);
            }
        }
    }


    request() {
        this.setState({ loadingType: true });
        disableComponent(this, "disableField");
        getDiscountTypeValue()
            .then((response) => {
                enableComponent(this, "disableField");
                if (response.data.length) {
                    let values = response.data.map(val => {
                        return { value: val.value, label: val.name };
                    });

                    this.setState({
                        typeOptions: values,
                        typeValue: values[0],
                        loadingType: false
                    });
                }
            })
            .catch((error) => {
                enableComponent(this, "disableField");
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }


    render() {
        const { classes, t } = this.props;

        const SaveDiscountButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveDiscount()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_DISCOUNT')}
            </Button>
        );

        const UpdateDiscountButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateDiscount()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.discountName}
                            onChange={this.handleChangeValues}
                            labelText={t('DISCOUNT_NAME_TEXT')}
                            id="discountName"
                            name="discountName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DiscountIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.code}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_CODE')}
                            id="code"
                            name="code"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CodeIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CurrencyInput
                            value={this.state.maxAmountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('MAX_AMOUNT_TEXT')}
                            id="maxAmountMask"
                            name="maxAmount"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.quantity}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_QUANTITY_USAGE')}
                            id="quantity"
                            name="quantity"
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.quantityPerUser}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_QUANTITY_PER_USER')}
                            id="quantityPerUser"
                            name="quantityPerUser"
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="typeValue" className={classes.selectTitle}>
                                {t('TYPE_VALUE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.typeValue}
                                onChange={this.changeTypeSelect}
                                components={animatedComponents}
                                isLoading={this.state.loadingType}
                                options={this.state.typeOptions}
                                isDisabled={this.state.loadingType}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={160}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CurrencyInput
                            value={this.state.valueMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('VALUE_TEXT')}
                            id="valueMask"
                            name="value"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={"%"}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('VALID_FROM_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.validFrom}
                                onChange={this.handleValidFromChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('VALID_TO_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.validTo}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                onChange={this.handleValidToChange}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openDiscount", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateDiscountButton/> : <SaveDiscountButton/>}
                        </div>
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

DiscountForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(DiscountForm)));
