import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {convertDate, getData, nullController} from "../../../utils/helpersFunctions";
import {
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import Status from "../../../components/Status/Status";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {geyAttendancesBySession} from "../../../services/activityService";
import {eventTagTypes} from "../../../variables/tagConstants";
import {validateSession} from "../../../services/api";


class SessionAttendancesDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            attendancesData: [],
            filteredAttendances: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
    }

    componentDidMount() {
        this.request();
    }



    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    request() {
        this.setState({ isLoading: true} );
        let session = getData("session");

        geyAttendancesBySession(session.id)
            .then((res) => {
                this.setState({ attendancesData: res.data });
                this.setState({ filteredAttendances: this.convertArrayToTimeFormat(res.data) });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false} );
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, false);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    convertArrayToTimeFormat = (timeChecks) => {
        return timeChecks.map(item => {
            return {
                id: item.id,
                externalId: item.externalId,
                name: item.firstName + " " + item.lastName,
                store: item.store,
                zone: item.zone,
                datetime: convertDate(item.datetime),
                eventType: eventTagTypes[item.eventType],
                roleName: item.roleName,
                addedBy: item.addedBy
            };
        });
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.typo}>
                                <h4> Attendances </h4>
                            </div>
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading
                        ? <Loading/>
                        : <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Date Time
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Member External Id
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Role
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Status
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredAttendances.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'name')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'datetime')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'externalId')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'roleName')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.filteredAttendances.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

SessionAttendancesDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(SessionAttendancesDetails)));