var assetDetails = {
    id: null,
    identifier: "",
    name: "",
    status: null,
    range: 10,
    technologyId: {value: 0, label: "Select..."},


    cleanAssetDetails : function () {
        this.id = null;
        this.identifier = "";
        this.name = "";
        this.range = 10;
        this.status = null;
        this.technologyId.value = 0;
        this.technologyId.label = "Select...";

    },
};

export default assetDetails;