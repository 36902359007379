import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION, STATUS_CANCELLED} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    isSuccessfulRequest,
    selectValidation,
} from "../../../utils/helpersFunctions";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import orderDetails from "../../../models/OrderModel";
import {updateOrderStatus} from "../../../services/orderService";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Notes";


const animatedComponents = makeAnimated();

class OrderStatusForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: '',
            comment: '',
            statusOptions: orderDetails.getStatusOptions(this.props),
            isLoading: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
    }


    changeStatusSelect = status => {
        this.setState({ status } );
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    showNotification = (msj, color, icon, status) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (status != null)
                    this.props.closeForm("openForm", false, status);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    updateStatus() {
        const { t } = this.props;
        if (selectValidation(this.state.status)) {
            this.showNotification(t('STATUS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (this.state.status.value === STATUS_CANCELLED && this.state.comment === "") {
                this.showNotification(t('COMMENT_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
            } else {
                let status = this.state.status.value;
                let dataParameters = {
                    "order_id": orderDetails.uuid,
                    "eta": 0,
                    "status_comment": status === STATUS_CANCELLED ? this.state.comment : "",
                };

                disableComponent(this, "disableField");
                updateOrderStatus(orderDetails.getOrderStatusAction(status), dataParameters)
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, status);

                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        }
                    });
            }
        }
    }


    render(){
        const { classes, t } = this.props;

        const UpdateButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateStatus()}
                disabled={this.state.disableField}
            >
                {t('BTN_UPDATE')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControlMax}>
                            <InputLabel htmlFor="asset" className={classes.selectTitle}>
                                {t('TH_STATUS')}
                            </InputLabel>
                            <Select
                                value={this.state.status}
                                onChange={this.changeStatusSelect}
                                components={animatedComponents}
                                options={this.state.statusOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={120}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    {
                        this.state.status.value === STATUS_CANCELLED ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    value={this.state.comment}
                                    onChange={this.handleChangeValues}
                                    labelText={t('COMMENT_TEXT')}
                                    id="comment"
                                    name="comment"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <DescriptionIcon className={classes.colorIcon}/>
                                        </InputAdornment>
                                    }
                                />
                            </GridItem>
                        ) : null
                    }
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openForm", false, null)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <UpdateButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

OrderStatusForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(OrderStatusForm)));