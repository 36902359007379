import React from "react";
import { withTranslation } from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { withRouter } from "react-router-dom";
import {
    ACTIVITY_TABLE_ACTIONS,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS,
} from "../../variables/coreConstants";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../components/CustomButtons/Button";
import Loading from "../../components/Loading/Loading";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Status from "../../components/Status/Status";
import {
    formTitles,
    statusColors,
    statusText,
} from "../../utils/statusHelpers";
import TableActions from "../../components/TableActions/TableActions";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
    getStringTextFormatByName,
    isSuccessfulRequest,
    nullController,
    saveData,
} from "../../utils/helpersFunctions";
import activityDetails from "../../models/ActivityModel";
import ActivityForm from "./components/ActivityForm";
import { getActivitiesByCompany } from "../../services/activityService";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import { statusController } from "../../services/statusService";
import { ACTIVITY_STATUS, BASE_URL_CORE } from "../../variables/apiConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";

class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openActivity: false,
            isLoading: false,
            actionType: 0,
            activitiesData: [],
            filteredActivities: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
        //console.log(getStringTextFormatByName());
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openActivity", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleOpenForm = () => {
        activityDetails.cleanActivityDetails();
        this.setState({ openActivity: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openActivity: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        activityDetails.id = formData.id;
        activityDetails.name = formData.name;
        activityDetails.description = formData.description;
        activityDetails.externalId = formData.external_id;
        activityDetails.duration = formData.duration;
        if (formData.memberOwners !== "" || formData.memberOwners !== []) {
            activityDetails.owner = formData.memberOwners.map((item) => {
                return { value: item.id, label: item.name };
            });
        }

        activityDetails.status = formData.status;

        if (action === 1 || action === 0) {
            this.setState({ openActivity: true });
            this.setState({ actionType: action });
        } else {
            let dataParameters = {
                activity_id: formData.id,
            };

            statusController(
                action,
                BASE_URL_CORE,
                ACTIVITY_STATUS,
                dataParameters,
                this,
                false
            )
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            false
                        );
                    }
                })
                .catch((error) => {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        false
                    );
                });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    goToDetails = (val) => {
        saveData("activity", val);
        activityDetails.name = val.name;
        activityDetails.id = val.id;
        this.props.history.push("quick-actions-activity");
    };

    searchActivity = (e) => {
        let newData = this.state.activitiesData;
        try {
            newData = newData.filter(function(item) {
                return (
                    item.name
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1
                );
            });
            this.setState({ filteredActivities: newData });
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    request = () => {
        this.setState({ isLoading: true });

        getActivitiesByCompany()
            .then((res) => {
                this.setState({ activitiesData: res.data });
                this.setState({ filteredActivities: res.data });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Input
                                onChange={this.searchActivity}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon
                                            className={classes.objectColor}
                                        />
                                    </InputAdornment>
                                }
                                placeholder="Search activity..."
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="primary"
                                    onClick={this.handleOpenForm}
                                >
                                    Add New Activity
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <Card>
                            <CardBody>
                                <div className={classes.root}>
                                    <div className={classes.tableWrapper}>
                                        <Table className={classes.table}>
                                            <TableHead
                                                className={classes.objectColor}
                                            >
                                                <TableRow>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        Name
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        Description
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        External Id
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        Owner
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        className={
                                                            classes.tableCell +
                                                            " " +
                                                            classes.tableHeadCell
                                                        }
                                                    >
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.filteredActivities
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage +
                                                            rowsPerPage
                                                    )
                                                    .map((val, key) => {
                                                        return (
                                                            <TableRow
                                                                key={key}
                                                                hover={true}
                                                            >
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell +
                                                                        " " +
                                                                        classes.hoverRow
                                                                    }
                                                                    onClick={() =>
                                                                        this.goToDetails(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    {nullController(
                                                                        val,
                                                                        "name"
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {nullController(
                                                                        val,
                                                                        "description"
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {nullController(
                                                                        val,
                                                                        "external_id"
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {getStringTextFormatByName(
                                                                        val.memberOwners
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    <Status
                                                                        className={
                                                                            classes.status
                                                                        }
                                                                        color={
                                                                            statusColors[
                                                                                val
                                                                                    .status
                                                                            ]
                                                                        }
                                                                        size="lg"
                                                                        label={t(
                                                                            statusText[
                                                                                val
                                                                                    .status
                                                                            ]
                                                                        )}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {
                                                                        <TableActions
                                                                            rowData={
                                                                                val
                                                                            }
                                                                            openForm={
                                                                                this
                                                                                    .openForm
                                                                            }
                                                                            options={
                                                                                ACTIVITY_TABLE_ACTIONS
                                                                            }
                                                                        />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredActivities.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page",
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page",
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={
                                        this.handleChangeRowsPerPage
                                    }
                                />
                            </CardBody>
                        </Card>
                    )}
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openActivity}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} Activity
                            <Muted>
                                Activities are a set of sessions that have
                                attendees. Attendees can subscribe to the
                                activity and rate each session
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <ActivityForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

Activities.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(Activities))
);
