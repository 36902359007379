import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import {withRouter} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {convertDate, formatValue, nullController, saveData} from "../../../utils/helpersFunctions";
import {
    NOTIFICATION_DURATION, SESSIONS_DETAILS_TABLE_ACTIONS
} from "../../../variables/coreConstants";
import Status from "../../../components/Status/Status";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";



class ActivitySessionsDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openSession: false,
            isLoading: false,
            actionType: 0,
            sessionsData: this.props.data,
            filteredSessions: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    goToDetails = (val) => {
        saveData("session", val);
        this.props.history.push("quick-actions-session");
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.typo}>
                                <h4> Sessions </h4>
                            </div>
                        </GridItem>
                    </GridContainer>
                    <Card>
                        <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Id
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Started Date Time
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Duration (min)
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Owner
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Attendance Rate
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredSessions.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}
                                                            onClick={() => this.goToDetails(val)}>
                                                            {nullController(val, 'id')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}
                                                            onClick={() => this.goToDetails(val)}   >
                                                            {convertDate(val.start_time)}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'duration')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'owner')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {formatValue(val.attendance_rate, "%")}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    options={SESSIONS_DETAILS_TABLE_ACTIONS}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.filteredSessions.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                        <Snackbar
                            place="tc"
                            color={this.state.color}
                            icon={this.state.icon}
                            message={this.state.notificationMessage}
                            open={this.state.notification}
                            closeNotification={this.hideNotification}
                            close
                        />
                </GridItem>
            </GridContainer>
        );
    }
}

ActivitySessionsDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(ActivitySessionsDetails)));