import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import EmailIcon from "@material-ui/icons/Email";
import NotesIcon from "@material-ui/icons/ShortText";
import PhoneIcon from "@material-ui/icons/ContactPhone";
import ExternalIdIcon from "@material-ui/icons/Fingerprint";
import CodeIcon from "@material-ui/icons/ConfirmationNumber";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {addMember, editMember} from "../../../services/memberService";
import { getRolesByCompany} from "../../../services/roleService";
import {
    disableComponent,
    enableComponent, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation, validateEmail
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import memberDetails from "../../../models/MemberModel";
import {withTranslation} from "react-i18next";

const animatedComponents = makeAnimated();


class MemberForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            memberEmail: memberDetails.email,
            name: memberDetails.name,
            phone: memberDetails.phone,
            externalId: memberDetails.externalId,
            clientCode: memberDetails.clientCode,
            role: memberDetails.role,
            appsOptions: [],
            rolesOptions: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveMember = this.saveMember.bind(this);
        this.updateMember = this.updateMember.bind(this);
        this.request = this.request.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    changeRoleSelect = role => {
        this.setState({ role });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };


    cleanData() {
        this.setState({
            memberEmail: '',
            name: '',
            clientCode: '',
            phone: '',
            externalId: '',
            role: '',
            notificationMessage: '',
            notification: false,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openMember", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {
        let rolesValues;

        getRolesByCompany()
            .then((res) => {
                rolesValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({ rolesOptions: rolesValues });
            })
            .catch((err) => {
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    saveMember() {
        if (this.state.name === "" || selectValidation(this.state.role)) {
            this.showNotification(this.props.t('ALL_MEMBER_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (this.state.memberEmail === "" || validateEmail(this.state.memberEmail)) {
                let dataParameters = {
                    "email": this.state.memberEmail,
                    "name": this.state.name,
                    "phoneNumber": this.state.phone,
                    "clientCode": this.state.clientCode,
                    "role_id": this.state.role.value,
                    "external_id": this.state.externalId
                };

                disableComponent(this, "disableField");
                addMember(dataParameters)
                    .then((response) => {
                        if (isSuccessfulCreate(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        }
                    });
            } else {
                this.showNotification(this.props.t('INVALID_EMAIL_MESSAGE'), "danger", NotificationErrorIcon, null);
            }
        }
    }

    updateMember() {
        if (this.state.name === "" || selectValidation(this.state.role)) {
            this.showNotification(this.props.t('ALL_MEMBER_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (this.state.memberEmail === "" || validateEmail(this.state.memberEmail)) {

                let dataParameters = {
                    "email": this.state.memberEmail,
                    "name": this.state.name,
                    "phoneNumber": this.state.phone,
                    "clientCode": this.state.clientCode,
                    "role_id": this.state.role.value,
                    "external_id": this.state.externalId
                };

                disableComponent(this, "disableField");
                editMember(dataParameters)
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        }
                    });
            } else {
                this.showNotification(this.props.t('INVALID_EMAIL_MESSAGE'), "danger", NotificationErrorIcon, null);
            }
        }
    }


    render() {
        const { classes, t } = this.props;

        const SaveMemberButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveMember()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_SEND')}
            </Button>
        );

        const UpdateMemberButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField}
                onClick={() => this.updateMember()}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('MEMBER_NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <NotesIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.memberEmail}
                            onChange={this.handleChangeValues}
                            labelText={t('MEMBER_EMAIL_TXT')}
                            id="memberEmail"
                            name="memberEmail"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.phone}
                            onChange={this.handleChangeValues}
                            labelText={t('MEMBER_PHONE_TEXT')}
                            id="phone"
                            name="phone"
                            type={"number"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <PhoneIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_EXTERNAL_ID')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIdIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.clientCode}
                            onChange={this.handleChangeValues}
                            labelText={t('CLIENT_CODE_TXT')}
                            id="clientCode"
                            name="clientCode"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CodeIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="actionId" className={classes.selectTitle}>
                                {t('ROLE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.role}
                                onChange={this.changeRoleSelect}
                                components={animatedComponents}
                                options={this.state.rolesOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={80}
                                isClearable={false}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper} style={{marginTop:"50px"}}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openMember", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateMemberButton/> : <SaveMemberButton/>}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

MemberForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(MemberForm)));