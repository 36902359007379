import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {useTranslation} from "react-i18next";
import HeaderForm from "../../../../components/CustomComponents/HeaderForm";
import {nullController} from "../../../../utils/helpersFunctions";
import CardInvoice from "../../../../components/CustomComponents/CardInvoice";



function TransferFormDetails({ ...props }) {
    const { classes, stepSuccess, data } = props;
    const {t} = useTranslation();


    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.headerFormContainerSmall}>
                                <HeaderForm
                                    title={stepSuccess ? t('TRANSFER_SUCCESS_TXT') : t('FORM_CONFIRM_TRANSFER_DETAILS_TITLE')}
                                    description={stepSuccess ? t('FORM_SUCCESS_TRANSFER_DESCRIPTION') : t('FORM_CONFIRM_TRANSFER_DETAILS_DESCRIPTION')}/>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.formBodyContainer}>
                                <CardInvoice
                                    data={[
                                        { label: t('DESTINATION_ACCOUNT_TXT'), value: nullController(data, "destinationAccount"), footer: nullController(data, "accountNumber")},
                                        { label: t('BENEFICIARY_LEGAL_NAME_TEXT'), value: nullController(data, "beneficiaryName")},
                                        { label: t('TH_AMOUNT'), value: nullController(data, "amount")},
                                        { label: t('TRANSACTION_PRICE_TXT'), value: nullController(data, "transactionPrice")}]}
                                    showFooter={false}/>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                {
                    !stepSuccess ? (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={false} sm={2} md={3} lg={3}/>
                                <GridItem xs={12} sm={8} md={6} lg={6}>
                                    <div className={classes.footerLegendFormContainer}>
                                        <HeaderForm
                                            title={null}
                                            description={t('FORM_CONFIRM_TRANSFER_DETAILS_LEGEND')}/>
                                    </div>
                                </GridItem>
                                <GridItem xs={false} sm={2} md={3} lg={3}/>
                            </GridContainer>
                        </GridItem>
                    ) : null
                }

            </GridContainer>
        </div>
    );
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.stepSuccess === nextProps.stepSuccess;
}

TransferFormDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    stepSuccess: PropTypes.bool,
    data: PropTypes.object
};

export default React.memo(withStyles(dashboardStyle)(TransferFormDetails), propsAreEqual);
