import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import {
    getCompanyCurrency, getData, getRoundNumber, isSuccessfulRequest, mergeArrays,
    nullController, saveData, setCurrencyValue,
} from "../../../utils/helpersFunctions";
import {formTitles} from "../../../utils/statusHelpers";
import {
    ALL_STATUS_INT,
    DELETE,
    FAILED_SYNC_STATUS_INT,
    NOT_COMPLETED_STATUS_INT,
    NOTIFICATION_DURATION,
    PAID_STATUS_INT,
    PENDING_STATUS_INT,
    STATUS_PENDING,
    SYNC_NOW,
    UNSPECIFIED_STATUS_INT,
    STATUS_PAID_PENDING_SYNC_INT, COPY_LINK, SINGLE_PAYMENT_REQUEST, TEMPLATE_PAYMENT_REQUEST
} from "../../../variables/coreConstants";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {validateSession} from "../../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import MultiIcon from "@material-ui/icons/Filter9Plus";
import SingleIcon from "@material-ui/icons/Filter1";
import InfoIcon from "@material-ui/icons/Info";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getMemberPaymentsRequest, getPaymentsRequestGroupsByMember} from "../../../services/memberService";
import {BASE_URL_CORE, PAYMENT_REQUEST, ROWS_PER_PAGE} from "../../../variables/apiConstants";
import {withRouter} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Muted from "../../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentRequestForm from "../../Payments/components/PaymentRequestForm";
import paymentRequestDetails from "../../../models/PaymentRequestModel";
import {syncPaymentRequest} from "../../../services/paymentService";
import {statusController} from "../../../services/statusService";
import PaymentTemplateGeneratorForm from "../../Payment Template/components/PaymentTemplateGeneratorForm";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";
import OptionSelectorModal from "./OptionSelectorModal";



class MemberPaymentsRequest extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            currency: getCompanyCurrency(),
            open: false,
            openPaymentGroup: false,
            openDetail: false,
            isLoading: false,
            isLoadingGroups: false,
            loadingDetail: false,
            noMoreItems: false,
            isImageError: false,
            data: [],
            currentPayment: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            count: 0,
            actionType: 0,
            status: {value: ALL_STATUS_INT, label: this.props.t('ALL_STATUS_TEXT')},
            statusOptions: [
                {value: ALL_STATUS_INT, label: this.props.t('ALL_STATUS_TEXT')},
                {value: PAID_STATUS_INT, label: this.props.t('STATUS_PAID')},
                {value: FAILED_SYNC_STATUS_INT, label: this.props.t('STATUS_SYNC_FAILED')},
                {value: PENDING_STATUS_INT, label: this.props.t('STATUS_PENDING')},
                {value: UNSPECIFIED_STATUS_INT, label: this.props.t('STATUS_UNSPECIFIED')},
                {value: NOT_COMPLETED_STATUS_INT, label: this.props.t('STATUS_NOT_COMPLETED')},
                {value: STATUS_PAID_PENDING_SYNC_INT, label: this.props.t('STATUS_PAID_PENDING_SYNC')}
            ],
            paymentGroup: '',
            paymentGroupOptions: [],
            notificationMessage: '',
            copySuccess: false,
            notification: false,
            noItems: false,
            generateFormType: null,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount(): void {
        this.setState({ isLoading: true, isLoadingGroups: true });
        let dataParameters = {
            "page": this.state.page,
            "pageSize": this.state.rowsPerPage,
            "status": this.state.status.value,
            "uuid": this.props.uuid,
            "filterUuid": this.state.paymentGroup.value
        };
        this.requestPaymentGroups();
        this.request(dataParameters);
    }

    handleSelectGenerationType = (state) => {
        this.setState({ generateFormType: state === 'single' ? SINGLE_PAYMENT_REQUEST : TEMPLATE_PAYMENT_REQUEST });
    };

    handleCloseForm = () => {
        this.setState({ open: false, generateFormType: null });
    };

    openForm = (action, formData) => {
        paymentRequestDetails.setPaymentRequestDetail(formData);

        if ((action === 1 || action === DELETE) && formData.status !== STATUS_PENDING) {
            this.showNotification(this.props.t('CANNOT_UPDATE_PAYMENT_REQUEST_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            if (action === 1 || 0) {
                this.setState({ open: true, actionType: action, generateFormType: null });
            } else if (action === COPY_LINK) {
                this.handleCopyToClipboard(formData);
            } else if (action === SYNC_NOW) {
                syncPaymentRequest(formData.uuid)
                    .then(response => {
                        if (isSuccessfulRequest(response)) {
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        } else {
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        } else {
                            this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                        }
                    });
            } else {
                statusController(action, BASE_URL_CORE, PAYMENT_REQUEST, {"paymentRequestId": formData.uuid},
                    this, false);

            }
        }
    };



    handleOpenPaymentGroupForm = () => {
        paymentRequestDetails.cleanPaymentRequestDetail();
        this.setState({ openPaymentGroup: true, generateFormType: null });
    };

    handleClosePaymentGroupForm = () => {
        this.setState({ openPaymentGroup: false, generateFormType: null });
    };


    closeForm(state, value, type) {
        this.setState({
            [state]: value,
            generationType: null,
            openPaymentGroup: value,
        });
        if(type) {
            this.setState({ isLoading: true, page: 0 });

            let dataParameters = {
                "page": 0,
                "pageSize": this.state.rowsPerPage,
                "status": this.state.status.value,
                "uuid": this.props.uuid,
                "filterUuid": this.state.paymentGroup.value
            };

            this.request(dataParameters);
            this.requestPaymentGroups();
        }
    }




    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true, page: 0 });
                    let dataParameters = {
                        "page": 0,
                        "pageSize": this.state.rowsPerPage,
                        "status": this.state.status.value,
                        "uuid": this.props.uuid,
                        "filterUuid": this.state.paymentGroup.value
                    };
                    this.request(dataParameters);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };
    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    changeStatusSelect = status => {
        this.setState({ status });
        this.setState({ isLoading: true, page: 0 });
        let dataParameters = {
            "page": 0,
            "pageSize": this.state.rowsPerPage,
            "status": status.value,
            "uuid": this.props.uuid,
            "filterUuid": this.state.paymentGroup.value
        };
        this.request(dataParameters);
    };

    changePaymentGroupSelect = paymentGroup => {
        this.setState({ paymentGroup });
        this.setState({ isLoading: true, page: 0 });
        let dataParameters = {
            "page": 0,
            "pageSize": this.state.rowsPerPage,
            "status": this.state.status.value,
            "uuid": this.props.uuid,
            "filterUuid": paymentGroup.value
        };
        this.request(dataParameters);
    };



    requestPaymentGroups() {
        getPaymentsRequestGroupsByMember(this.props.uuid)
            .then((response) => {
                let values = response.data.map(item => {
                    return { value: item.id, label: item.name }
                });

                this.setState({
                    isLoadingGroups: false,
                    paymentGroupOptions: values
                });

            })
            .catch((error) => {
                this.setState({ isLoadingGroups: false} );
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }


    request(parameters) {
        getMemberPaymentsRequest(parameters)
            .then((response) => {
                let newData = response.data !== null ? response.data.map(r => {
                    r.amountPaidLabel = parseFloat(r.amountPaid) > 0 ? (nullController(r, 'currencyCode') + " " + setCurrencyValue(getRoundNumber(nullController(r, 'amountPaid')))) : null
                    r.amountLabel = nullController(r, 'currencyCode') + " " + setCurrencyValue(getRoundNumber(nullController(r, 'amount')));
                    return r;
                }) : [];

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid"
                );

                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    noMoreItems: newData.length === 0,
                    data: paginatedData,
                    filteredData: paginatedData
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false} );
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }



    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(nullController(val, "link"));
            this.showNotification(this.props.t('LINK_COPIED_MSG'), "primary", InfoIcon, null);
        } catch (e) {
            console.log("Something went wrong");
        }
    };

    handleGoToDetails = (values) => {
        saveData("paymentRequest", values);
        this.props.history.replace("/admin/payment-request-details/" + values.uuid);
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, noMoreItems } = this.state;

        if (!isLoadingMore && !noMoreItems) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "status": this.state.status.value,
                    "uuid": this.props.uuid,
                    "filterUuid": this.state.paymentGroup.value
                };

                this.request(dataParameters);
            });
        }
    };

    render(){
        const { classes, t, uuid } = this.props;
        const { color, icon, actionType, notificationMessage, notification, open, openPaymentGroup, isLoading, isLoadingMore,
            filteredData, statusOptions, status, isLoadingGroups, paymentGroupOptions, paymentGroup, loadingDetail, generateFormType
        } = this.state;


        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={loadingDetail || isLoading || isLoadingMore} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'member_payment_requests'}
                        title={t('MEMBER_PAYMENT_REQUEST_TEXT')}
                        exportFileName={t('MEMBER_PAYMENT_REQUEST_OF_TEXT', { memberName: getData('member').name })}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_GENERATE'), function: this.handleOpenPaymentGroupForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[
                            { value: paymentGroup, data: paymentGroupOptions, label: t('SEARCH_PAYMENT_REQUEST_GROUP'), function: this.changePaymentGroupSelect, isLoading: isLoadingGroups, disabled: isLoadingGroups, type: "select"},
                            { value: status, data: statusOptions, function: this.changeStatusSelect, isLoading: isLoading, disabled: isLoading, type: "select"}
                        ]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.handleGoToDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_AMOUNT'), name: "amountLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_AMOUNT_PAID'), name: "amountPaidLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: "*", cssClass: "cell-left", isCustomCell: true },
                            { label: t('DUE_DATE_TXT'), name: "expirationDate", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_CREATED_BY'), name: "createdBy", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('CREATED_DATE'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={open}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t('PAYMENT_REQUEST_TXT')}
                        <Muted>
                            {t('FORM_PAYMENT_REQUEST_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <PaymentRequestForm closeForm={this.closeForm} action={actionType}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={false}
                    open={openPaymentGroup}
                    onClose={this.handleClosePaymentGroupForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {generateFormType === SINGLE_PAYMENT_REQUEST ? (t(formTitles[0]) + t('PAYMENT_REQUEST_TXT')) : t('FORM_GENERATE_PAYMENT_GROUP_TXT')}
                        <Muted>
                            {generateFormType === SINGLE_PAYMENT_REQUEST ? '' : t('FORM_PAYMENT_GROUP_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        {
                            generateFormType !== null ? (
                                <div>
                                    {
                                        generateFormType === SINGLE_PAYMENT_REQUEST
                                            ? <PaymentRequestForm closeForm={this.closeForm} action={actionType} memberId={uuid}/>
                                            : <PaymentTemplateGeneratorForm closeForm={this.closeForm} isTemplate={false} memberId={uuid}/>
                                    }
                                </div>
                            ) : (
                                <OptionSelectorModal
                                    title={t('PAYMENT_REQUEST_GENERATION_TYPE')}
                                    onSelectOption={this.handleSelectGenerationType}
                                    onClose={this.closeForm}
                                    btnCloseLabel={t('BTN_CANCEL')}
                                    options={[
                                        {icon: SingleIcon, label: t('SINGLE'), state: 'single', selected: generateFormType === SINGLE_PAYMENT_REQUEST},
                                        {icon: MultiIcon, label: t('MULTI'), state: 'multi', selected: generateFormType === TEMPLATE_PAYMENT_REQUEST},
                                    ]}
                                />
                            )
                        }

                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={color}
                    icon={icon}
                    message={notificationMessage}
                    open={notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

MemberPaymentsRequest.propTypes = {
    classes: PropTypes.object,
    uuid: PropTypes.string.isRequired
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(MemberPaymentsRequest)));
