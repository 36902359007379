import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import signInStyle from "../../assets/jss/custom-components/signInStyle";
import {getCountries} from "../../services/storeService";
import Select from "../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../assets/jss/material-dashboard-react";



export default function SignUp(props) {
    const classes = signInStyle();
    const {t} = useTranslation();

    const [values, setValues] = React.useState({
        companyName: '',
        countryOptions: [],
        countryCode: '',
        phoneNumber: '',
        city:'',
        postalCode:'',
        address: '',
        taxId: '',
    });

    useEffect(() => {
        request();
    }, []);

    const handleChange = () => e => {

        const name = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [name]: value });
        props.handleFields(name, value);
    };

    const changeCountrySelect = (countryCode) => {
        setValues({ ...values, countryCode });
        props.handleCountryField(countryCode);
    };

    const request = () => {
        let countryValues;
        getCountries()
            .then((res) => {
                countryValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                setValues({ ...values, countryOptions: countryValues });
            })
            .catch((err) => {
                console.log('Something went wrong');
            });
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        className={classes.textFieldPrimary}
                        name="companyName"
                        id="companyName"
                        value={props.values.companyName}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        label={t('ORGANIZATION_NAME_TEXT')}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        className={classes.textFieldPrimary}
                        name="address"
                        id="address"
                        value={props.values.address}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        label={t('ADDRESS_TEXT')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        className={classes.textFieldPrimary}
                        name="city"
                        id="city"
                        value={props.values.city}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        label={t('CITY_TEXT')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textFieldPrimary}
                        name="postalCode"
                        id="postalCode"
                        value={props.values.postalCode}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        label={t('POSTAL_CODE_TEXT')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={classes.formControl}>
                        <Select
                            value={props.values.countryCode}
                            onChange={changeCountrySelect}
                            options={values.countryOptions}
                            placeholder={t('SELECT_COUNTRY_TEXT')}
                            closeMenuOnSelect={true}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: primaryColor[0],
                                },
                            })}
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 2 })
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        className={classes.textFieldPrimary}
                        name="phoneNumber"
                        id="phoneNumber"
                        value={props.values.phoneNumber}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        label={t('PHONE_NUMBER_TEXT')}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        className={classes.textFieldPrimary}
                        name="taxId"
                        id="taxId"
                        value={props.values.taxId}
                        onChange={handleChange()}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        required={true}
                        label={t('TAX_ID_TEXT')}
                    />
                </Grid>
            </Grid>
        </div>
    );
}