import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { formTitles } from "../../utils/statusHelpers";
import { withRouter } from "react-router-dom";
import { getCategoriesByCompany } from "../../services/categoryService";
import categoryDetails from "../../models/CategoryModel";
import CategoryForm from "./components/CategoryForm";
import {
    ROWS_PER_PAGE,
} from "../../variables/coreConstants";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import {
    removeData,
    saveData,
} from "../../utils/helpersFunctions";
import { withTranslation } from "react-i18next";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";


class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCategory: false,
            isLoading: true,
            actionType: 0,
            categoriesData: [],
            filteredCategories: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        categoryDetails.cleanCategoryDetails();
        this.setState({ openCategory: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openCategory: false });
    };

    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        categoryDetails.id = formData.id;
        categoryDetails.name = formData.name;
        removeData("category");
        this.setState({ openCategory: true });
        this.setState({ actionType: action });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });
        getCategoriesByCompany()
            .then((res) => {
                this.setState({ categoriesData: res.data });
                this.setState({ filteredCategories: res.data });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    goToActions = (val) => {
        saveData("category", val);
        this.props.history.push("quick-actions-category");
    };


    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage, filteredCategories, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'categories'}
                        title={t('CATEGORIES_TEXT')}
                        exportFileName={t('CATEGORIES_TEXT')}
                        data={filteredCategories}
                        actions={[
                            { label: t('BTN_ADD_NEW_CATEGORY'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openCategory}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {actionType
                                ? t(formTitles[actionType]) + t("CATEGORY_TEXT")
                                : t("FORM_CATEGORY_TITLE")}
                            <Muted>{t("FORM_CATEGORY_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <CategoryForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }
}

Categories.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(Categories))
);
