var categoryDetails = {
    id: null,
    name: '',
    description: '',
    isDefault: 0,
    list: [],

    cleanCategoryDetails : function () {
        this.id = null;
        this.name = '';
        this.description = '';
        this.isDefault = 0;
        this.list = [];
    }
};

export default categoryDetails;