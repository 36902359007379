import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Table from "@material-ui/core/Table";
import {withRouter} from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getRoundNumber, nullController, saveData, setCurrencyValue} from "../../../utils/helpersFunctions";
import CustomTableHead from "../../../components/Table/CustomTableHead";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import Status from "../../../components/Status/Status";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import TablePagination from "@material-ui/core/TablePagination";
import {ROWS_PER_PAGE_OPTIONS} from "../../../variables/coreConstants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentDetailsForm from "./PaymentDetailsForm";
import {getPaymentDetails} from "../../../services/paymentService";
import {validateSession} from "../../../services/api";


function PaymentRequestAttemptList({ ...props }) {
    const { classes, data, currency } = props;
    const [page, setPage] = useState(0);
    const [paymentDetail, setPaymentDetails] = useState("");
    const [selected, setSelected] = useState(null);
    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [openPaymentDetail, setOpenDetail] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {t} = useTranslation();


    useEffect(() => {
        if (selected !== null && selected !== undefined && selected !== "") {
            setLoadingDetails(true);
            getPaymentDetails(selected.reference)
                .then((response) => {
                    if (response.data) {
                        setPaymentDetails(response.data);
                        setLoadingDetails(false);
                    } else {
                        setLoadingDetails(false);
                        setOpenDetail(false);
                        setPaymentDetails("");
                        setSelected(null);
                    }

                })
                .catch((error) => {
                    setLoadingDetails(false);
                    setOpenDetail(false);
                    setPaymentDetails("");
                    setSelected(null);

                    if (error.response) {
                        validateSession(error.response, props.history);
                    }
                });
        }
    }, [selected]);


    useEffect(() => {
        if (paymentDetail !== null && paymentDetail !== undefined && paymentDetail !== "") {
            setOpenDetail(true);
        }
    }, [paymentDetail]);

    useEffect(() => {
        if (!openPaymentDetail) {
            setPaymentDetails("");
            setSelected(null);
        }
    }, [openPaymentDetail]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        setPage(page);
    };


    const openDetails = (formData) => {
        setSelected(formData);
    };

    const closeForm = (state, value, type) => {
        setOpenDetail(false);
    };

    const openLink = (data) => {
        saveData("paymentRequest", { uuid: data.paymentRequestId });
        setOpenDetail(false);
        props.history.push("/admin/payment-request-details/" + data.paymentRequestId);
        props.onUpdate(data);
    };


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <div>
                        <h5 className={classes.noPadding}> {t('PAYMENTS_ATTEMPT_LIST_TXT')} </h5>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <div className={classes.root}>
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <CustomTableHead withColor>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_REFERENCE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_PAID_BY')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_AMOUNT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_TRANSACTION_ID')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('CREATED_DATE_TXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('PAID_DATE_TXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STATUS')}
                                                </TableCell>
                                            </TableRow>
                                        </CustomTableHead>
                                        <TableBody>
                                            {data.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell
                                                            onClick={() => openDetails(val)}
                                                            className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'reference')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'createdBy')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {currency + setCurrencyValue(getRoundNumber(nullController(val, 'amount')))}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'transactionId')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'createdAt')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'paidDate')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <Backdrop className={classes.backdrop} open={isLoadingDetails} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={openPaymentDetail}
                    aria-labelledby="form-dialog-title"
                    style={{marginBottom: 0, paddingTop: "0px !important"}}
                >
                    <DialogTitle id="form-dialog-title">
                        {t('PAYMENT_DETAILS_TEXT')}
                    </DialogTitle>
                    <DialogContent>
                        <PaymentDetailsForm data={paymentDetail} closeForm={closeForm} openLink={openLink}/>
                    </DialogContent>
                </Dialog>
            </GridContainer>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data;
}

PaymentRequestAttemptList.defaultProps = {
    data: []
};

PaymentRequestAttemptList.propTypes = {
    classes: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    currency: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default withRouter(React.memo(withStyles(tableViewStyle)(PaymentRequestAttemptList), propsAreEqual));
