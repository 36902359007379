import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import {withTranslation} from "react-i18next";
import GridItem from "../../../components/Grid/GridItem";
import {
    disableComponent,
    enableComponent,
    getData,
    isSuccessfulRequest, nullController
} from "../../../utils/helpersFunctions";
import GridContainer from "../../../components/Grid/GridContainer";
import {
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE_PAYMENTS
} from "../../../variables/coreConstants";
import {assignMembersToGroup, getMemberGroupMemberAssignments} from "../../../services/memberService";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {validateSession} from "../../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class MemberGroupAssignment extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            memberGroup: getData('memberGroup'),
            isLoading: false,
            disableField: false,
            checkAll: false,
            data: [],
            selectedItems: [],
            selectedIds: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE_PAYMENTS,
            page: 0,
            lastPage: false,
            totalElements: 0,
            totalPages: 0
        }
    }



    componentDidMount() {
        this.request();
    }


    request = () => {
        const { page, rowsPerPage, memberGroup } = this.state;
        this.setState({ isLoading: true }, () => {

            let parameters = {
                "page": page,
                "pageSize": rowsPerPage,
                "uuid": memberGroup.uuid
            };

            getMemberGroupMemberAssignments(parameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        let data = [];

                        if (response.data !== null) {
                            data = response.data.content.map(i => {
                                i.isSelected = this.state.selectedItems.findIndex(x => x.uuid === i.uuid) !== -1;
                                return i;
                            });
                        }

                        this.setState({
                            isLoading: false,
                            data: data,
                            filteredData: data,
                            totalElements: response.data !== null ? response.data.totalElements : 0,
                            lastPage: response.data !== null ? response.data.last : false,
                            totalPages: response.data !== null ? response.data.totalPages : 0,
                        });
                    } else {
                        this.setState({ isLoading: false });
                        this.props.showNotificationMessage(response.data.message, "danger",
                            NotificationErrorIcon, NOTIFICATION_DURATION, null);
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    if (err.response) {
                        this.props.showNotificationMessage(err.response.data.message, "danger",
                            NotificationFailedIcon, NOTIFICATION_DURATION, null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.props.showNotificationMessage(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, NOTIFICATION_DURATION, null)
                    }
                });
        });
    };


    //
    // handleCheck = (item) => {
    //     const array = this.state.selectedItems.slice();
    //     const initData = this.state.data.slice();
    //
    //     let indexOnTable = initData.findIndex(i => i.uuid === item.uuid);
    //
    //     if (indexOnTable !== -1) {
    //         let index = array.findIndex(i => i.uuid === item.uuid);
    //
    //         if (index !== -1) {
    //             if (item.isSelected) {
    //                 array.splice(index, 1);
    //             } else {
    //                 item.isSelected = false;
    //                 array.push({ "uuid": item.uuid });
    //             }
    //
    //             this.setState({
    //                 selectedItems: array
    //             }, ()=> {
    //                 initData[indexOnTable].isSelected = !item.isSelected;
    //                 this.setState({
    //                     data: initData
    //                 })
    //             });
    //         } else {
    //             item.isSelected = false;
    //             array.push({ "uuid": item.uuid });
    //
    //             this.setState({
    //                 selectedItems: array
    //             }, ()=> {
    //                 initData[indexOnTable].isSelected = !item.isSelected;
    //                 this.setState({
    //                     data: initData
    //                 })
    //             });
    //         }
    //     }
    // };
    //
    // handleCheckAll = () => {
    //     this.setState({
    //         checkAll: !this.state.checkAll
    //     })
    // };

    handleAssign = () => {
        const { t, showNotificationMessage, history } = this.props;
        const { memberGroup, selectedIds } = this.state;

        if (selectedIds === null || selectedIds === undefined || selectedIds.length === 0) {
            showNotificationMessage(t('SELECT_MEMBERS_REQUITED_MSG'), "danger", NotificationErrorIcon, NOTIFICATION_DURATION, null);

        } else {

            let dataParameters = {
                "id": memberGroup.uuid,
                "isAllSelected": false,
                "newAssignments": JSON.stringify(selectedIds),
                "removedAssignments": "[]"
            };


            disableComponent(this, "disableField");
            assignMembersToGroup(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.setState({ disableField: false, selectedItems: [] });
                        showNotificationMessage(response.data.message, "primary", NotificationSuccessIcon,
                            NOTIFICATION_DURATION, () => {
                                this.props.history.push("/admin/member-group/" + this.state.memberGroup.uuid);

                        });
                    } else {
                        enableComponent(this, "disableField");
                        showNotificationMessage(response.data.message, "danger", NotificationErrorIcon,
                            NOTIFICATION_DURATION, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");

                    if (error.response) {
                        showNotificationMessage(error.response.data.message, "danger",
                            NotificationErrorIcon, NOTIFICATION_DURATION, null);

                        validateSession(error.response, history);
                    } else {
                        showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, NOTIFICATION_DURATION, null);
                    }
                });
        }
    };


    handleSelectedItems = (flex, s, e) => {
        let gridRows = flex.rows.filter(r => r.isSelected)

        let selectedUuid = gridRows.map(row => {
            return { "uuid": row._data.uuid };
        });


        this.setState({
            selectedItems: gridRows,
            selectedIds: selectedUuid
        });
    };



    render() {
        const { classes, t } = this.props;
        const { isLoading, filteredData, disableField, selectedItems, memberGroup } = this.state;


        return (
            <div>
                <GridContainer>
                    <Backdrop className={classes.backdrop} open={isLoading} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <TableGridWijmo
                            tableName={'member_group_assignments'}
                            title={t('MEMBERS_AVAILABLE')}
                            exportFileName={t('MEMBERS_AVAILABLE_FOR_GROUP', { groupName: nullController(memberGroup, "name") })}
                            multiSelect
                            handleSelect={this.handleSelectedItems}
                            data={filteredData}
                            actions={[
                                { label: t('BTN_ADD'), function: this.handleAssign, isLoading: false, disabled: selectedItems.length === 0 || disableField, type: "simple" },
                                { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ]}
                            controls={[]}
                            headers={[
                                { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_EXTERNAL_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_EMAIL'), name: "email", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_ROLE'), name: "roleId.name", isReadOnly: true, width: "*", isCustomCell: false },
                                { label: t('TH_MEMBER_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


MemberGroupAssignment.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withNotification(withTranslation()(MemberGroupAssignment))));
