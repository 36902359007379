import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { formTitles } from "../../utils/statusHelpers";
import {
    ROWS_PER_PAGE,
    UNASSIGN,
} from "../../variables/coreConstants";
import {
    convertToYesNo,
    isSuccessfulRequest,
    saveData,
} from "../../utils/helpersFunctions";
import applicationDetails from "../../models/ApplicationModel";
import { statusController } from "../../services/statusService";
import { APPLICATION_ZONE, BASE_URL_CORE } from "../../variables/apiConstants";
import ApplicationForm from "./components/ApplicationForm";
import { getApplicationsByCompany } from "../../services/applicationsService";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import { withTranslation } from "react-i18next";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Applications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            isLoading: true,
            actionType: 0,
            appsData: [],
            filteredApps: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        applicationDetails.cleanApplicationDetails();
        this.setState({ openForm: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        applicationDetails.cleanApplicationDetails();
        applicationDetails.id = formData.id;
        applicationDetails.name = formData.name;
        applicationDetails.description = formData.description;
        applicationDetails.type.value = formData.typeId.id;
        applicationDetails.type.label = formData.typeId.name;
        applicationDetails.accessLevel.value = formData.accessLevelId.id;
        applicationDetails.accessLevel.label = formData.accessLevelId.name;
        applicationDetails.paymentAmountOption.value =
            formData.payment_amount_option_id.id;
        applicationDetails.paymentAmountOption.label = this.props.t(
            formData.payment_amount_option_id.translateCode.toUpperCase()
        );
        applicationDetails.isCompact = formData.isCompact;
        applicationDetails.isAutoOpen = formData.autoOpen;
        applicationDetails.published = formData.isPublished;

        if (action === 1 || 0) {
            this.setState({ openForm: true });
            this.setState({ actionType: action });
        } else if (action === UNASSIGN) {
            let dataParameters = {
                application_id: formData.id,
            };
            statusController(
                action,
                BASE_URL_CORE,
                APPLICATION_ZONE,
                dataParameters,
                this,
                false
            )
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        // this.showNotification("Asset De.", "primary", NotificationSuccessIcon, true);
                        console.log("Application unassigned");
                    } else {
                        // this.showNotification("Bad request, asset was not assigned.", "danger", NotificationErrorIcon, false);
                        console.log("Bad request");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, false);
                });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    goToDetails = (val) => {
        saveData("app", val);
        applicationDetails.name = val.name;
        applicationDetails.id = val.id;
        this.props.history.push("quick-actions-application");
    };

    request = () => {
        this.setState({ isLoading: true });

        getApplicationsByCompany()
            .then((res) => {
                let newData = res.data.map(item => {
                    item.published = convertToYesNo(item.isPublished);
                    return item;
                });
                this.setState({ appsData: newData, filteredApps: newData, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    // Method to search data
    searchApplication = (e) => {
        let newData = this.state.appsData;
        try {
            newData = newData.filter(function(item) {
                return (
                    item.name
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1
                );
            });
            this.setState({ filteredApps: newData });
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage, filteredApps, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'applications'}
                        title={t('APPLICATIONS_TEXT')}
                        exportFileName={t('APPLICATIONS_TEXT')}
                        data={filteredApps}
                        actions={[
                            { label: t('BTN_ADD_NEW_APP'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToDetails, isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_DESCRIPTION'), name: "description", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TYPE'), name: "typeId.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACCESS_LEVEL'), name: "accessLevelId.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_PUBLISHED'), name: "published", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openForm}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {actionType
                                ? t(formTitles[actionType]) +
                                  t("APPLICATION_TEXT")
                                : t("FORM_APPLICATION_TITLE")}
                            <Muted>{t("FORM_APP_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <ApplicationForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }
}

Applications.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Applications)));
