import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ProductForm from "./components/ProductForm";
// Styles
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
// Implementations
import {
    formTitles
} from "../../utils/statusHelpers";
import productDetails from "../../models/ProductModel";
import { getProductsByCompany } from "../../services/productService";
import { withRouter } from "react-router-dom";
import {
    ENTITY_PRODUCT,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE
} from "../../variables/coreConstants";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import {
    getCompanyCurrency,
    getRoundNumber,
    nullController,
    removeData,
    setCurrencyValue,
} from "../../utils/helpersFunctions";
import modifierDetails from "../../models/ModifierModel";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import { getStatus, statusController } from "../../services/statusService";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openProduct: false,
            isLoading: true,
            actionType: 0,
            currency: getCompanyCurrency(),
            productsData: [],
            filteredProducts: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        productDetails.cleanProductDetails();
        modifierDetails.cleanModifier();
        removeData("category");

        removeData("menu");
        this.setState({ openProduct: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openProduct: false });
    };

    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        productDetails.cleanProductDetails();
        productDetails.setProductDetail(formData);

        if (action === 0 || action === 1) {
            removeData("category");
            this.setState({ openProduct: true, actionType: action });
        } else {
            let status = getStatus(action);

            if (status !== null) {
                let dataParameters = {
                    entity: ENTITY_PRODUCT,
                    id: productDetails.id,
                    status: status,
                };

                statusController(
                    action,
                    null,
                    null,
                    dataParameters,
                    this,
                    true
                );
            }
        }
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.closeForm("openProduct", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    request = () => {
        const { t } = this.props;

        this.setState({ isLoading: true });
        getProductsByCompany()
            .then((res) => {
                let newData = res.data.map(item => {
                    item.categoryNameLabel = nullController(item, "categoryName") !== "" ? item.categoryName : t("NONE_TEXT");
                    item.price = this.state.currency + " " + setCurrencyValue(getRoundNumber(nullController(item.standardPrice, "price")));
                    item.tax = nullController(item.standardPrice, "tax") + "%";
                    return item;
                });

                this.setState({
                    productsData: newData,
                    filteredProducts: newData,
                    isLoading: false
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };


    goToActions = (val) => {
        productDetails.name = val.name;
        productDetails.id = val.id;
    };


    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, isLoading, filteredProducts } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'products'}
                        title={t('PRODUCTS_TEXT')}
                        exportFileName={t('PRODUCTS_TEXT')}
                        data={filteredProducts}
                        actions={[
                            { label: t('BTN_ADD_PRODUCT'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_CODE'), name: "code", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_IMAGE'), name: "photo", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DESCRIPTION'), name: "description", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('PRICE_TEXT'), name: "price", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TAX_TEXT'), name: "tax", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 120, isCustomCell: true },
                            { label: t('CATEGORY_TEXT'), name: "categoryNameLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_EXTERNAL_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openProduct}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} {t("PRODUCT_TEXT")}
                            <Muted>{t("FORM_NEW_PRODUCT_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <ProductForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}
Products.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(Products))
);
