import React from "react";
import LoadDebitPayments from "./sync/LoadDebitPayments";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import {
    getDirectDebitPayments,
    getLastDirectDebitExecution, getLastDirectDebitJobs, refreshPrivateServiceToken,
    syncDirectDebitPayments
} from "../../../services/directDebitService";
import {
    cloneObject,
    getCompanyCurrency,
    getData,
    getRoundNumber,
    getTableColumnObject,
    isEmpty,
    isSuccessfulCreate, mergeArrays, nullController, removeData,
    saveData,
    setCurrencyValue
} from "../../../utils/helpersFunctions";
import {
    DEBIT_TSK_EXCLUDED,
    NOTIFICATION_DURATION_LARGE,
    DEBIT_TSK_READY_TO_PAY,
    DEBIT_TSK_SYNC_COMPLETED, DEBIT_WSS_JOB_SYNC_ACTION
} from "../../../variables/coreConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import directDebitDetails from "../../../models/DirectDebitModel";
// import API_CODES from "../../../variables/apiCodes";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {BASE_URL_WEB_SOCKET_DIRECT_DEBIT} from "../../../variables/apiConstants";
import {getSignature} from "../../../services/api";
import {STATUS_COMPLETED_JOB, STATUS_IN_PROGRESS_TSK} from "../../../variables/apiCodes";
import {withWssListener} from "../../../components/HOC/WebSocket/WebSocketHOC";
import { Selector } from '@grapecity/wijmo.grid.selector';
import {WIJMO_DISTRIBUTION_KEY} from "../../../variables/apiConstants";
import { setLicenseKey } from '@grapecity/wijmo';
setLicenseKey(WIJMO_DISTRIBUTION_KEY);


class DebitDetailsController extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uuid: null,
            currencySymbol: getCompanyCurrency(),
            debitDetails: getData("debit"),
            syncSummary: {
                "total": 0,
                "pending": 0,
                "completed": 0,
                "availableToExecute": 0,
                "notAvailableToExecute": 0,
                "withMultiplePayments": 0
            },
            currentJobId: getData("syncJobId"),
            jobAvailableExecute: !isEmpty(getData("syncJobId")),
            debitInfoLoaded: false,
            isRequesting: false,
            isLoading: false,
            debitOptionEnabled: false,
            syncResultLoaded: false,
            step: 0,
            debitPayments: [],
            debitPaymentsFiltered: [],
            metadata: null,
            checkAll: false,
            excludedItems: [],
            selectedItems: [],
            openExecutionModal: false,
            page: 0,
            rowsPerPage: 50,
            lastPage: false,
            totalElements: 0,
            totalPages: 0,
            retryDebitToken: 0,
            showLiveProcess: false,
            enableProgress: false,
            forceConnection: false,
            jobInProgress: false,
            wssTask: [],
            wss: null,
            wssConnecting: false,
            wssProcessCompleted: false,
            countReconnection: 0,
            isLoadingMore: false
        };
        this.selector = null;
    }

    #finishWssListener;


    componentDidMount() {
        const { t, routeProps } =  this.props;

        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);


        if (isEmpty(this.state.currentJobId)) {
            this.syncDirectDebitPayments();
        } else {
            this.handleRefreshDirectDebitSummaryPayments(true);
        }
        // document.addEventListener('visibilitychange', () => {
        //     if (document.visibilityState === 'visible') {
        //         this.onCheckWssConnection();
        //     }
        // });
    }



    requestDetails = async () => {
        const { match, history, t, showNotificationMessage } = this.props;

        if (!isEmpty(match.params.id)) {
            this.setState({ isRequesting: true, retryDebitToken: this.state.retryDebitToken + 1 }, () => {
                getLastDirectDebitExecution(match.params.id).then(response => {
                    if (!isEmpty(response) && !isEmpty(response.data)) {
                        if (!isEmpty(response.data.id) && response.data.availableToExecute) {
                            this.setState({
                                currentJobId: response.data.id,
                                jobAvailableExecute: true,
                                retryDebitToken: 0
                            }, () => {
                                saveData("syncJobId", response.data.id);
                                this.handleRefreshDirectDebitSummaryPayments(true);
                            });
                        } else {
                            this.setState({
                                isLoading: false,
                                retryDebitToken: 0,
                                isRequesting: false,
                                currentJobId: null,
                                jobAvailableExecute: false
                            });
                        }
                    } else {
                        this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0 });
                    }
                }).catch(e => {
                    if (!isEmpty(e.response)) {
                        if (this.state.retryDebitToken <= 2) {
                            refreshPrivateServiceToken(e.response, this.requestDetails, this.handlerHttpError);
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.handlerHttpError(e.response);
                        }
                    } else {
                        this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0 });
                        showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                            NOTIFICATION_DURATION_LARGE, null);
                    }

                    // history.goBack();
                });
            });
        }
    }


    syncDirectDebitPayments = async () => {
        const { showNotificationMessage, t, history } = this.props;
        const { currentJobId, jobAvailableExecute, debitDetails, debitPayments, debitInfoLoaded, jobInProgress } = this.state;

        if (currentJobId === null || debitInfoLoaded || (!jobInProgress && currentJobId !== null)) {
            // if (!jobAvailableExecute && isEmpty(currentJob)) {
            let dataParameters = { "debitDefinitionId": debitDetails.id };

            this.setState({ isRequesting: true, retryDebitToken: this.state.retryDebitToken + 1 }, () => {

                syncDirectDebitPayments(dataParameters)
                    .then(response => {
                        if (isSuccessfulCreate(response)) {
                            const {data} = response;

                            if (data.code != null && data.code.code === 500) {
                                this.setState({
                                    isRequesting: false,
                                    syncSummary: {
                                        "total": 0,
                                        "pending": 0,
                                        "completed": 0,
                                        "availableToExecute": 0,
                                        "notAvailableToExecute": 0,
                                        "withMultiplePayments": 0
                                    },
                                    debitInfoLoaded: false,
                                    isLoading: false,
                                    currentJobId: response.data.id,
                                    jobAvailableExecute: true,
                                    debitOptionEnabled: false,
                                    wssTask: [],
                                    showLiveProcess: true,
                                    enableProgress: true,
                                    selectedItems: [],
                                    debitPayments: [],
                                    debitPaymentsFiltered: [],
                                    retryDebitToken: 0
                                }, () => {
                                    saveData("syncJobId", response.data.id);
                                    saveData("syncJobAvailable", true);
                                    this.onInitializeWss();
                                });

                                // Init web socket
                                showNotificationMessage(
                                    t('DEBIT_SYNC_JOB_CREATED'),
                                    'primary',
                                    NotificationErrorIcon,
                                    NOTIFICATION_DURATION_LARGE,
                                    null
                                );
                            } else {
                                this.setState({ retryDebitToken: 0 });
                                this.handlerHttpError(response);
                            }
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.handlerHttpError(response);
                        }
                    }).catch(e => {
                    if (!isEmpty(e.response)) {
                        if (this.state.retryDebitToken <= 2) {
                            refreshPrivateServiceToken(e.response, this.syncDirectDebitPayments, this.handlerHttpError);
                        } else {
                            this.setState({ retryDebitToken: 0 });
                            this.handlerHttpError(e.response);
                        }
                    } else {
                        this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0 });
                        showNotificationMessage(
                            t('SOMETHING_WENT_WRONG'),
                            'danger',
                            NotificationErrorIcon,
                            NOTIFICATION_DURATION_LARGE,
                            null
                        );
                    }
                });
                // } else {
                //     showNotificationMessage(
                //         t('SOMETHING_WENT_WRONG'),
                //         'danger',
                //         NotificationErrorIcon,
                //         NOTIFICATION_DURATION_LARGE,
                //         null
                //     );
                // }
            });
        } else {
            // show error cannot load payments
        }
    };

    handleRefreshDirectDebitSummaryPayments = (initWss) => {
        if (this.state.jobAvailableExecute && !this.state.isLoading) {
            this.setState({
                forceConnection: initWss === true,
                selectedItems: this.state.syncResultLoaded ? [] : this.state.selectedItems,
                debitPayments: this.state.syncResultLoaded ? [] : this.state.debitPayments,
                debitPaymentsFiltered: this.state.syncResultLoaded ? [] : this.state.debitPaymentsFiltered,
            }, () => {
                this.handleRefreshResult();
            });
        }
    }

    handleRefreshResult = () => {
        const { showNotificationMessage, t, history } = this.props;
        const { jobAvailableExecute, debitDetails, currentJobId, isLoadingMore } = this.state;

        if (currentJobId !== null && jobAvailableExecute) {
            let missingLastItem = false;
            let lastItem = null;

            if (!isEmpty(this.state.metadata)) {
                if (this.state.metadata.hasMore) {
                    lastItem = this.state.metadata.lastItem;
                    missingLastItem = isEmpty(this.state.metadata.lastItem);
                }
            }

            if (!missingLastItem) {
                let dataParameters = {
                    "debitDefinitionId": debitDetails.id,
                    "jobId": currentJobId,
                    "lastItem": lastItem
                };

                this.setState({ isLoading: !isLoadingMore, retryDebitToken: this.state.retryDebitToken + 1 }, () => {
                    getDirectDebitPayments(dataParameters)
                        .then(response => {
                            let recordNum = this.state.debitPayments.length + 1;
                            let selected = [];
                            let data = [];
                            let metadataPagination = null;
                            let cloneSummary = cloneObject(this.state.syncSummary);

                            if (response.data !== null && !isEmpty(response.data.members)) {
                                const { members, summary, metadata } = response.data;
                                metadataPagination = metadata;

                                if (!isEmpty(summary)) {
                                    cloneSummary.withMultiplePayments = summary.withMultiplePayments;
                                    cloneSummary.notAvailableToExecute = summary.notAvailableToExecute;
                                    cloneSummary.availableToExecute = summary.availableToExecute;
                                    cloneSummary.completed = summary.completed;
                                    cloneSummary.total = summary.total;
                                    cloneSummary.pending = summary.pending;
                                }


                                this.setState({
                                    syncSummary: cloneSummary,
                                    debitInfoLoaded: !isEmpty(cloneSummary) && (cloneSummary.total > 0 && cloneSummary.completed >= cloneSummary.total),
                                }, () => {
                                    for(let m = 0; m < members.length; m++) {
                                        let current = members[m];

                                        if (current.payments.length > 0) {
                                            for(let p = 0; p < current.payments.length; p++) {
                                                try {
                                                    let currentP = current.payments[p];

                                                    data.push({
                                                        recordNum: recordNum,
                                                        paymentId: currentP.id,
                                                        memberId: current.member.id,
                                                        isSelected: current.status === DEBIT_TSK_SYNC_COMPLETED,
                                                        disabled: current.status === DEBIT_TSK_EXCLUDED,
                                                        status: current.status,
                                                        statusMessage: getTableColumnObject(current, "statusSync"),
                                                        memberName: current.member.name,
                                                        memberExternalId: current.member.externalId,
                                                        paymentExternalId: currentP.externalId,
                                                        paymentMethod: !isEmpty(current.paymentMethod) ? "XXXX-" + current.paymentMethod.lastDigits : null,
                                                        paymentMethodStatus: !isEmpty(current.paymentMethod) ? current.paymentMethod.status : null,
                                                        paymentName: currentP.name,
                                                        amountFormatted: !isNaN(parseInt(currentP.amount.minorAmount)) && parseInt(currentP.amount.minorAmount) >= 0 ? currentP.amount.currency.symbol + " " + setCurrencyValue(getRoundNumber(parseInt(currentP.amount.minorAmount) / 100)) : "-",
                                                        amountValue: !isNaN(currentP.amount.minorAmount) && parseInt(currentP.amount.minorAmount) >= 0 ? parseInt(currentP.amount.minorAmount) : 0,
                                                        dueDate: !isEmpty(currentP.dueDate) ? (t('DUE_DATE') + new Date(parseInt(nullController(currentP, 'dueDate'))).toLocaleString()) : "",
                                                        currencySymbol: currentP.amount.currency.symbol
                                                    });

                                                    recordNum++;

                                                    if (current.status === DEBIT_TSK_SYNC_COMPLETED) {
                                                        selected.push({
                                                            "id": currentP.id,
                                                            "amount": !isNaN(currentP.amount.minorAmount) && parseInt(currentP.amount.minorAmount) >= 0 ? parseInt(currentP.amount.minorAmount) : 0
                                                        });
                                                    }


                                                    // Set currency
                                                    if (this.state.currencySymbol !== null
                                                        && this.state.currencySymbol !== undefined
                                                        && this.state.currencySymbol !== "") {

                                                        this.setState({
                                                            currencySymbol: currentP.amount.currency.symbol
                                                        });
                                                    }
                                                } catch (e) {
                                                    console.log("Error getting object from json item");
                                                }
                                            }
                                        } else {
                                            try {
                                                data.push({
                                                    recordNum: recordNum,
                                                    memberId: current.member.id,
                                                    paymentId: null,
                                                    isSelected: false,
                                                    status: current.status,
                                                    statusMessage: 'NO_PAYMENTS_FOUND',
                                                    paymentMethod: !isEmpty(current.paymentMethod) ? "XXXX-" + current.paymentMethod.lastDigits : null,
                                                    paymentMethodStatus: !isEmpty(current.paymentMethod) ? current.paymentMethod.status : null,
                                                    disabled: current.status === DEBIT_TSK_EXCLUDED,
                                                    memberName: current.member.name,
                                                    memberExternalId: current.member.externalId,
                                                    paymentExternalId: null,
                                                    paymentName: null,
                                                    amountFormatted: "-",
                                                    amountValue: 0,
                                                    dueDate: null,
                                                    currencySymbol: null
                                                });
                                                recordNum++;
                                            } catch (e) {
                                                console.log("Error getting m object from json item");
                                            }
                                        }
                                    }
                                });
                            }

                            let paginatedData = mergeArrays(
                                this.state.debitPayments,
                                data,
                                "memberId",
                                (a,b) => a["recordNum"] - b["recordNum"]
                            );

                            // let initDataVirtualized = this.state.debitPaymentsFiltered;
                            // if (isEmpty(this.state.metadata)) {
                            //     initDataVirtualized = paginatedData.length > 20 ? paginatedData.slice(0, 20) : paginatedData;
                            // }

                            let excluded = [];
                            paginatedData.forEach(item => {
                                if (!item.isSelected && !isEmpty(item.paymentId)) {
                                    excluded.push({ "id": item.paymentId });
                                }
                            });


                            this.setState({
                                isLoading: false,
                                isLoadingMore: false,
                                isRequesting: false,
                                showLiveProcess: this.state.enableProgress,
                                retryDebitToken: 0,
                                step: 1,
                                selectedItems: mergeArrays(this.state.selectedItems, selected, "id", null),
                                excludedItems: excluded,
                                debitPayments: paginatedData,
                                debitPaymentsFiltered: paginatedData,
                                metadata: metadataPagination,
                                syncResultLoaded: !isEmpty(metadataPagination) && !metadataPagination.hasMore,
                                debitOptionEnabled: this.state.debitInfoLoaded && selected.length > 0
                            }, () => {
                                // if (this.state.syncSummary.completed < this.state.syncSummary.total && (this.state.wss === null)) {
                                //     // this.onPrepareWssConnection(this.onConnectDirectDebitWss);
                                // }
                                // console.log("forceConnection => ", this.state.forceConnection);

                                if (cloneSummary.completed < cloneSummary.total
                                    && cloneSummary.total > 0 && this.state.currentJobId !== null && this.state.forceConnection) {

                                    this.onInitializeWss();
                                }

                                if (cloneSummary.completed >= cloneSummary.total && cloneSummary.total > 0) {
                                    this.handleLoadMore();
                                }
                            });
                        }).catch(e => {
                            if (!isEmpty(e.response)) {
                                if (this.state.retryDebitToken <= 2) {
                                    // is loading must be false
                                    refreshPrivateServiceToken(e.response, () => {
                                        this.setState({ isLoading: false }, this.handleRefreshDirectDebitSummaryPayments);
                                    }, this.handlerHttpError);
                                } else {
                                    this.setState({ retryDebitToken: 0, metadata: null });
                                    this.handlerHttpError(e.response);
                                }
                            } else {
                                this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0, isLoadingMore: false, metadata: null });

                                showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                                    NOTIFICATION_DURATION_LARGE, null);
                            }
                    });
                });
            } else {
                this.setState({ isLoading: false, isRequesting: false, metadata: null, isLoadingMore: false });
            }
        } else {
            this.setState({ isLoading: false, isRequesting: false, metadata: null, isLoadingMore: false });
        }
    };


    onChangePage = (event, newPage) => {
        this.setState({ page: newPage})
    };

    onChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value
        });
    };


    handleSelectRow = (item) => {
        let array = this.state.selectedItems.slice();
        let excluded = this.state.excludedItems.slice();
        let initData = this.state.debitPayments.slice();
        let selected = false;

        let indexOnTable = initData.findIndex(i => i.paymentId === item.paymentId);

        if (indexOnTable !== -1) {
            let index = array.findIndex(i => i.id === item.paymentId);
            let indexExcluded = excluded.findIndex(i => i.id === item.paymentId);

            if (index !== -1) {
                if (item.isSelected) {
                    array.splice(index, 1);

                    if (indexExcluded === -1) {
                        excluded.push({ "id": item.paymentId });
                    }
                } else {
                    selected = true;
                    array.push({ "id": item.paymentId, "amount": item.amountValue });

                    if (indexExcluded !== -1) {
                        excluded.splice(indexExcluded, 1);
                    }
                }
            } else {
                selected = true;
                array.push({ "id": item.paymentId, "amount": item.amountValue });

                if (indexExcluded !== -1) {
                    excluded.splice(indexExcluded, 1);
                }
            }

            this.setState({
                selectedItems: array,
                excludedItems: excluded,
                debitOptionEnabled: this.state.debitInfoLoaded && array.length > 0
            }, ()=> {
                initData[indexOnTable].isSelected = selected;
                this.setState({
                    debitPayments: initData
                });
            });
        }
    };


    search = (e) => {
        let newData = this.state.debitPayments;

        try {
            newData = newData.filter(function(item) {
                return (item.paymentName !== null ? item.paymentName.search(e.target.value) !== -1 : false)
                || (item.paymentExternalId !== null ? item.paymentExternalId.search(e.target.value) !== -1 : false)
                || (item.paymentExternalId !== null ? item.paymentExternalId.search(e.target.value) !== -1 : false)
                || (item.memberName !== null ? item.memberName.toLowerCase().search(e.target.value.toLowerCase()) !== -1 : false)
                || (item.memberExternalId !== null ? item.memberExternalId.search(e.target.value) !== -1 : false)
                    // || this.props.t(statusText[item.status]).toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });

            this.setState({ debitPaymentsFiltered: newData });

        } catch (e) {
            console.log(e)
            console.log("Something went wrong.");
        }
    };


    handleExecuteDebit = () => {
        const { showNotificationMessage, t, history } = this.props;
        try {
            const { syncSummary, debitDetails, selectedItems, excludedItems, currencySymbol, currentJobId } = this.state;

            if (getData("syncJobAvailable")) {
                if (syncSummary.availableToExecute > 0 && selectedItems.length > 0) {
                    directDebitDetails.cleanDebitDetails();
                    let amountMinor = 0;

                    for (let i = 0 ; i < selectedItems.length ; i++) {
                        amountMinor = parseInt(amountMinor) + parseInt(selectedItems[i].amount);
                    }


                    directDebitDetails.setDebitDetails({
                        id: debitDetails.id,
                        name: debitDetails.name,
                        jobId: currentJobId,
                        totalPayments: selectedItems.length,
                        amount: currencySymbol + " " + setCurrencyValue(getRoundNumber(amountMinor / 100))
                    });

                    console.log(selectedItems);
                    console.log(excludedItems);
                    this.setState({ openExecutionModal: true });
                } else {
                    // cannot execute
                }
            } else {
                showNotificationMessage(t('COULD_NOT_EXECUTE_SYNC_JOB'), 'danger', NotificationErrorIcon,
                    NOTIFICATION_DURATION_LARGE, null);
            }

        } catch (e) {
            console.log(e);
        }
    };


    /**
     *
     * @param state Parent component state name
     * @param value New state value
     * @param data Other data, provided when success flow is completed
     */
    handleCloseForm = (state, value, data) => {
        this.setState({[state]: value });
        if(data !== null && data !== undefined) {
            // this.props.history.
            //this.handleSelectRow(data);
            this.setState({
                executionJobId: data.executionJobId
            });

            removeData("isExecuted");
            saveData("syncSummary", this.state.syncSummary)
            saveData("debitExecution", data)
            this.props.history.push("/admin/debit/" + this.state.debitDetails.id + "/execution/" + data.executionJobId);
        }
    };


    /**
     * @description Error response validator
     * @param response Http response object
     */
    handlerHttpError = (response) => {
        const { showNotificationMessage, t, history } = this.props;

        this.setState({ isLoading: false, isRequesting: false, isLoadingMore: false });

        if (!isEmpty(response.data)) {
            const { data } = response;

            if (!isEmpty(data.error)) {
                showNotificationMessage(data.error.message, "danger", NotificationFailedIcon,
                    NOTIFICATION_DURATION_LARGE, null);

                // If we need to validate the code error to do something
                // if (data.error.code === API_CODES.GENERAL_FAIL_CODE.code) {
                // }
            } else {
                if (!isEmpty(data.code)) {
                    showNotificationMessage(data.code.message, "danger", NotificationFailedIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                } else {
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            }
        } else {
            showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                NOTIFICATION_DURATION_LARGE, null);
        }
    };


    handleSelectColumn = (id, type) => {
        if (!isEmpty(id) && !isEmpty(type)) {
            if (type === 'm') {
                saveData("member", { uuid: id });
                window.open("/admin/member-details/" + id, "_blank");
            }
        }
    };


    handleCloseLiveProcess = (update) => {
        this.setState({
            wssTask: [],
            showLiveProcess: false,
            enableProgress: false
        });
        // }, () => {
        //     if (update) {
        //         this.handleRefreshDirectDebitSummaryPayments();
        //     }
        // });
    };


    /**
     * @description Function triggered when wss api send a notification
     * @param message
     */
    onReceiveNotification = (message) => {
        const { currentJobId, syncSummary } = this.state;
        const { jobId, jobProgress, summary, status, actionType, step } = message;
        let taskData = null;
        // const { code,  description, task } = step;
        // const { pending, completed, total } = summary;

        // console.log(JSON.stringify(message));

        if (jobId === currentJobId && actionType === 'debit_sync_payments') {
            if (!isEmpty(summary)) {
                // console.log("*********************");
                // console.log(jobId);
                // console.log(status);
                // console.log(JSON.stringify(summary));

                const { pending, completed, total, availableToExecute, notAvailableToExecute, withMultiplePayments } = summary;

                let updatedSummary = cloneObject(this.state.syncSummary);

                // if (updatedSummary.total === 0) {
                //     updatedSummary.total = total;
                // }
                //
                // if (total > updatedSummary.total) {
                //     updatedSummary.total = total;
                // }
                //
                //
                // // do not set complete value if receive a message with minor task number than current summary
                // if (completed > updatedSummary.completed) {
                //     if (completed < updatedSummary.total) {
                //         updatedSummary.pending = pending;
                //         updatedSummary.completed = completed;
                //     } else if (completed === updatedSummary.total) {
                //         updatedSummary.pending = 0;
                //         updatedSummary.completed = completed;
                //     }
                // }

                updatedSummary.total = total;
                updatedSummary.completed = completed;
                updatedSummary.pending = pending;
                updatedSummary.availableToExecute = availableToExecute;
                updatedSummary.notAvailableToExecute = notAvailableToExecute;
                updatedSummary.withMultiplePayments = withMultiplePayments;

                if (!isEmpty(step)) {
                    const { code,  description, task } = step;
                    taskData = task;
                }


                this.setState({
                    syncSummary: updatedSummary,
                    wssTask: !isEmpty(taskData) ? [taskData].concat(this.state.wssTask) : this.state.wssTask
                }, () => {
                    if (status === STATUS_IN_PROGRESS_TSK) {
                        // console.log("Job in progress");

                    } else if (status === STATUS_COMPLETED_JOB) {
                        // console.log("Job completed");
                        this.props.onWssCompleted();
                        if (!isEmpty(this.state.syncSummary) && (this.state.syncSummary.total > 0
                            && this.state.syncSummary.completed >= this.state.syncSummary.total)) {

                            this.setState({
                                // jobInProgress: false,
                                debitInfoLoaded: true
                            }, () => {
                                this.handleRefreshDirectDebitSummaryPayments(false);
                            });
                        }
                    } else {
                        // console.log("Invalid job status (" + status + ")");
                    }
                });
            } else {
                // iff summary is null ?
                // validate status
                // console.log("Wss summary null");
            }
        } else {
            // console.log("Incoming message from another job (" + jobId + ")");
        }


        this.props.onUpdateWssListenerCondition(() => {
            // console.log("Last message");
            const { syncSummary } = this.state;
            if (syncSummary.completed < syncSummary.total) {
                // console.log("Close connection last");
                this.onMissingMessages();
            }
        });
    };


    /**
     * @description Function used when wss doesn't send a message between interval time configured
     */
    onMissingMessages = () => {
        // this.setState({
        //     debitInfoLoaded: true
        // });

        if (this.state.syncSummary.completed < this.state.syncSummary.total && this.state.syncSummary.total > 0 && this.state.currentJobId !== null) {
            this.setState({
                jobInProgress: false,
                debitInfoLoaded: false,
                retryDebitToken: 0,
                isRequesting: false,
            }, () => {
                this.props.onWssCompleted();
                this.handleRefreshDirectDebitSummaryPayments(false);
            });
        } else {
            this.setState({
                debitInfoLoaded: true,
                jobInProgress: false
            });
        }
    }


    onInitializeWss = () => {

        this.setState({
            jobInProgress: true
        }, async () => {
            const data = {
                actionType: 'debit_sync_payments',
                jobId: this.state.currentJobId
            };

            const signature = await getSignature(BASE_URL_WEB_SOCKET_DIRECT_DEBIT, data, getData("directDebitAuth"));

            this.props.onSetWssConfig(
                BASE_URL_WEB_SOCKET_DIRECT_DEBIT,
                JSON.stringify({
                    action: DEBIT_WSS_JOB_SYNC_ACTION,
                    data: data,
                    headers: {
                        "signature": signature,
                        "x-secret-key": getData("userTk"),
                        "authorization": getData("directDebitAuth")
                    }
                }),
                1,
                10,
                this.onReceiveNotification,
                this.onMissingMessages
            );
        });
    }


    handleLoadMore = () => {
        const { isLoadingMore, metadata } = this.state;

        if (!isLoadingMore && !isEmpty(metadata) && metadata.hasMore) {
            this.setState({
                isLoadingMore: true
            }, () => {
                this.handleRefreshDirectDebitSummaryPayments(false);
            });
        }
    }



    onRequestLastDebitJobs = () => {
        return getLastDirectDebitJobs(this.props.match.params.id);
    }


    onRecentJobSelected = (item) => {
        if (item['Executed']) {
            removeData("isExecuted");
            saveData("syncSummary", null);
            saveData("debitExecution", {
                debitName: this.state.debitDetails.name,
                executionJobId: item['Id'],
                executionDate: item['LastModifiedDate']
            });

            saveData("isExecuted", item['Executed']);
            this.props.history.push("/admin/debit/" + this.state.debitDetails.id + "/execution/" + item['Id']);
        }
    }


    // Improve this method
    _initializeGrid = (flex) => {
        this.selector = new Selector(flex, {
            itemChecked: (s, e) => {
                let selectedItems = [];
                let excluded = [];

                // console.log(s)
                // console.log(e)

                for (const row of flex.rows.filter(r => r.isSelected)) {
                    selectedItems.push({ "id": row._data.paymentId, "amount": row._data.amountValue });
                }

                for (const row of flex.rows.filter(r => r.isSelected === false)) {
                    excluded.push({ "id": row._data.paymentId });

                }
                this.setState({
                    selectedItems: selectedItems,
                    excludedItems: excluded
                });
            }
        });

        // flex.scrollPositionChanged.addHandler((s) => {
        //     if (s.viewRange.bottomRow >= s.rows.length - 1) {
        //         let view = s.collectionView;
        //         let index = view.currentPosition; // keep position in case the view is sorted
        //         this._addGridData(this.state.debitPaymentsFiltered.length);
        //         view.refresh();
        //         view.currentPosition = index;
        //     }
        // });
    }

    // _addGridData = (currentLength) => {
    //     let newData = [];
    //     let more = currentLength + 20;
    //     for (let i = currentLength; i < more; i++) {
    //         if (i < this.state.debitPayments.length) {
    //             newData.push(this.state.debitPayments[i]);
    //         }
    //     }
    //
    //     if (newData.length > 0) {
    //         let paginatedData = mergeArrays(
    //             this.state.debitPaymentsFiltered,
    //             newData,
    //             "memberId",
    //             null
    //         );
    //
    //         this.setState({
    //             debitPaymentsFiltered: paginatedData
    //         });
    //     }
    // };

    handleSelectRow = (flex, s, e) => {
        let selectedItems = [];
        let excluded = [];

        // console.log(s)
        // console.log(e)

        for (const row of flex.rows.filter(r => r.isSelected)) {
            selectedItems.push({ "id": row._data.paymentId, "amount": row._data.amountValue });
        }

        for (const row of flex.rows.filter(r => r.isSelected === false)) {
            excluded.push({ "id": row._data.paymentId });

        }
        this.setState({
            selectedItems: selectedItems,
            excludedItems: excluded
        });
    };


    render() {
        const { step, debitDetails , isLoading, openExecutionModal, debitPaymentsFiltered, selectedItems, debitOptionEnabled,
            page, rowsPerPage, isRequesting, debitInfoLoaded, currentJobId, excludedItems, jobAvailableExecute, syncSummary,
            showLiveProcess, jobInProgress, wssTask, isLoadingMore, debitPayments } = this.state;
        // console.log("*******************");
        // console.log("currentJobId ", currentJobId);
        // console.log("debitInfoLoaded ", debitInfoLoaded);
        // console.log("!jobInProgress ", !jobInProgress);

        if (step === 0 || step === 1) {
            return <LoadDebitPayments
                isLoading={isLoading}
                isLoadingMore={isLoadingMore}
                debitInfoLoaded={debitInfoLoaded}
                summary={syncSummary}
                isRequesting={isRequesting}
                data={debitPaymentsFiltered}
                // data={debitPayments}
                dataItemCount={debitPayments.length}
                directDebit={debitDetails}
                refreshAvailable={jobAvailableExecute && !isLoading}
                // syncAvailable={!debitInfoLoaded}
                syncAvailable={currentJobId === null || debitInfoLoaded || (!jobInProgress && currentJobId !== null)}
                executionEnabled={syncSummary.availableToExecute > 0 && selectedItems.length > 0}
                // executionEnabled={selectedItems.length > 0}
                showExecutionModal={openExecutionModal}
                excludedItems={excludedItems}
                onSearch={this.search}
                onExecuteDebit={this.handleExecuteDebit}
                onCloseExecutionModal={this.handleCloseForm}
                handleCheckRow={this.handleSelectRow}
                onLoadPayments={this.syncDirectDebitPayments}
                onRefresh={this.handleRefreshDirectDebitSummaryPayments}
                handleChangePage={this.onChangePage}
                handleChangeRowsPerPage={this.onChangeRowsPerPage}
                handleSelectColumn={this.handleSelectColumn}
                page={page}
                rowsPerPage={rowsPerPage}
                showLiveProcess={showLiveProcess}
                handleCloseLiveProcess={this.handleCloseLiveProcess}
                tasks={wssTask}
                handleLoadMore={this.handleLoadMore}
                handleRequestLastDebitJobs={this.onRequestLastDebitJobs}
                handleRecentJob={this.onRecentJobSelected}
                handleInitGrid={this._initializeGrid}
            />
        } else {
            return null;
        }
    }
}


DebitDetailsController.propTypes = {};

export default withRouter(withWssListener(withNotification(withTranslation()(DebitDetailsController))));