/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  successColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb, steelGray, dangerColor, warningColor
} from "../../../../assets/jss/material-dashboard-react.jsx";
import {lightColors, primaryColor} from "../../../../assets/jss/material-dashboard-react";
import {defaultFont} from "../../material-dashboard-react";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: grayColor[2],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: blackColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  rightPaper:{
    float: "right",
    marginBottom: 0,
  },
  leftPaper:{
    float: "left",
    marginBottom: 0,
  },
  headTitle:{
    fontWeight: "bold",
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
  },
  center:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton:{
    border: "1px solid #A2A2A2"
  },
  settingsTitle:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: grayColor[2],
    fontSize: "0.7915rem",
    lineHeight: "0.9715rem"
  },
  settingsTitles:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: grayColor[1],
    fontSize: "0.7915rem",
  },
  settingsTitleNoMargin:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: grayColor[1],
    fontSize: "0.7915rem",
    marginTop: "0px !important",
    marginBottom: "0px !important"
  },
  settingsText:{
    fontFamily: "sans-serif",
    fontWeight: "bold",
    color: grayColor[2],
    fontSize: "0.8715rem",
    lineHeight: "1rem",
  },
  profile: {
    color: primaryColor[0],
    fontSize: "100px",
    padding: "10px 10px"
  },
  buttonFit: {
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 5,

  },
  filterContent: {
    marginRight: 15,
    width: 200,
    marginBottom: 20
  },
  filterContentDiv: {
    marginRight: 15,
    width: 200,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
  },
  dateLabelContainer: {
    marginRight: 15,
    width: 'auto',
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  rowDiv: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row"
  },
  separatorColumn: {
    marginLeft: 10,
    marginRight: 15
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  wrapContent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start"
  },
  descriptionLink: {
    marginTop: 25,
    fontFamily: "sans-serif",
    fontWeight: "normal",
    color: primaryColor[2],
    fontSize: "0.8515rem",
    lineHeight: "0rem"
  },
  grayDescription: {
    marginTop: 25,
    fontFamily: "sans-serif",
    fontWeight: "normal",
    color: grayColor[1],
    fontSize: "0.8515rem",
    lineHeight: "0rem"
  },
  switchContainerRight:{
    float: "right",
    marginTop: 10,
  },
  rightContainerSwitch:{
    float: "right !important",
    marginRight: 0,
  },

  switchBase: {
    color: primaryColor[0],
    "&$checked": {
      color: primaryColor[0],
    },
    "&$checked + $track": {
      backgroundColor: primaryColor[0],
    },
  },
  publishSwitch:{
    marginLeft: 0
  },
  iconButtonBlue: {
    width: "20px",
    height: "20px",
    padding: "0",
    color: primaryColor[0]
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  tableActionButton: {
    marginLeft: "2px",
    marginTop: "5px",
    width: "27px",
    height: "27px",
  },
  noMargin: {
    marginTop: 0,
    paddingTop: 0,
  },
  backdrop: {
    zIndex: 9999,
    color: '#fff',
  },
  tabBar: {
    marginTop: 10,
    boxShadow: "none",
    transition: "all 150ms ease 0s",
    zIndex: "1029",
    backgroundColor: "#F5F5F5",
    borderBottom: '1px solid #e8e8e8',
    color: grayColor[7],
    display: "block",
    '& span.MuiTabs-indicator': {
      backgroundColor: primaryColor[0]
    },
    '&:hover': {
      color: grayColor[7],
      opacity: 1,
    },
    '&$selected': {
      color: grayColor[7],
    },
    '&:focus': {
      color: grayColor[7],
    },
  },
  cardRowHeader: {
    padding: "0 15px 0 0 !important"
  },
  memberCardPhone: {
    color: grayColor[2],
    marginLeft: 5,
    fontSize: "14px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  memberCardBodyText: {
    color: grayColor[2],
    marginLeft: 45,
    fontSize: "14px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  paymentCardBodyText: {
    color: grayColor[2],
    marginLeft: 10,
    fontSize: "13px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  paymentDetailCardValueText: {
    color: grayColor[0],
    marginRight: 10,
    textAlign: "right",
    fontSize: "13px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  memberCardName: {
    color: grayColor[2],
    marginLeft: 5,
    fontSize: "14px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  statusCardTitle: {
    color: grayColor[2],
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: 10,
    padding: 0,
    marginTop: 5,
    marginBottom: 0,
  },
  memberProfileRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  memberPhoneRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10
  },
  memberStatusRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  iconAvatarBlue: {
    width: "27px",
    height: "27px",
    padding: "0",
    marginLeft: 5,
    color: primaryColor[0]
  },
  iconAvatar: {
    width: "35px",
    height: "35px",
    backgroundColor: "transparent",
    marginLeft: 5,
  },
  formIcon: {
    color: whiteColor,
    width: "37px",
    height: "37px",
    marginBottom: 5
  },
  editIcon: {
    width: "23px",
    height: "23px",
    padding: "0",
    color: primaryColor[0]
  },
  statusCard: {
    alignItems: "center",
    marginRight: 10,
    marginTop: 5,
    width: "auto",
  },
  copyButtonIcon: {
    width: "23px",
    height: "23px",
    color: primaryColor[0]
  },
  hardLine: {
    backgroundColor: grayColor[0],
    height: 1.2,
    width: "96%",
    opacity: 0.9,
    marginRight: "2%",
    marginLeft: "2%",
    marginTop: 5,
    marginBottom: 5,
  },
  formContent: {
    marginBottom: 1,
    backgroundColor: "transparent"
  },
  textInputIcon: {
    color: whiteColor,
    width: "37px",
    height: "37px",
    marginBottom: 5
  },
  accountPhotoCard: {
    maxWidth: "50px !important",
    maxHeight: "50px !important",
    margin: "-15px auto 0px !important",
    border: ".5px solid rgba(" + hexToRgb(blackColor) + ", .34)",
    borderRadius: "50%",
    overflow: "hidden",
  },
  accountWithoutPhoto: {
    width: "50px !important",
    height: "50px !important",
    resizeMode: "contain"
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonMargin: {
    marginLeft: 5,
    marginRight:5,
  },
  editLinkIcon: {
    color: whiteColor,
    width: "37px",
    height: "37px",
    marginTop: "-15px"
  },
  selectControlBottom: {
    marginTop: 0,
    marginBottom: 20,
  },
  cardFixed: {
    marginBottom: "15px",
    marginTop: "10px",
  },
  cardFooterFixed: {
    padding: "0",
    margin: "0 15px 10px",
    borderRadius: "0",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    backgroundColor: "transparent",
    border: "0"
  },
  labelGrayText: {
    fontSize: "14px",
    color: grayColor[2],
    fontWeight: "500",
    marginRight: 5
  },
  valueGrayText: {
    fontSize: "14px",
    color: grayColor[0],
    fontWeight: "400"
  },
  balanceCardTitle2: {
    color: grayColor[2],
    fontSize: "20px",
    lineHeight: "18px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  balanceCardSubTitle: {
    color: grayColor[1],
    fontSize: "13px",
    lineHeight: "18px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  balanceCardTitle: {
    color: grayColor[19],
    fontSize: "14px",
    fontWeight: "bold",
    padding: 0,
    marginTop: 0,
    marginBottom: 5,
  },
  screenTitle: {
    marginBottom: "-15px",
    marginTop: 0,
    color: grayColor[2]
  },
  gridNoTop: {
    marginTop: "0px !important",
    paddingTop: "0px !important"
  },
  balanceCardTotalText: {
    color: grayColor[2],
    fontSize: "14px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  horizontalSpacing: {
    marginRight: 10
  },
  containerForm: {
    marginBottom: 20
  },
  headFormTitle:{
    color: grayColor[2],
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "20px",
    marginTop: 0,
    marginBottom: 5,
    paddingBottom: 0,
  },
  headerFormDescription: {
    ...defaultFont,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: grayColor[1],
    width: "100%"
  },
  legendTitleForm: {
    color: grayColor[7],
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontSize: "12px",
  },
  selectContainer: {
    width: "100%"
  },
  formBodyContainer: {
    width: "100%",
    marginBottom: 20
  },
  headerFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20
  },
  headerFormContainerSmall: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 0
  },
  inputFormContainer: {
    marginBottom: "0px !important",
    marginTop: 15,
    paddingBottom: "0px !important"
  },
  breadButton: {
    color: primaryColor[3],
    fontSize: "14px"
  },
  cardInvoice: {
    backgroundColor: lightColors[1],
    borderRadius: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  invoiceRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10
  },
  invoiceRowTitle: {
    color: grayColor[2],
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: 15,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    lineHeight: "18px",
  },
  invoiceRowValue: {
    color: grayColor[1],
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "500",
    marginLeft: 15,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  footerLegendFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 0
  },
  centerRows: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  laterButton: {
    color: primaryColor[0],
  },
  selectContainerTop: {
    width: "100%",
    marginTop: 30
  },
  invoiceFooterText: {
    color: grayColor[8],
    fontSize: "12px",
    fontWeight: "500",
    marginLeft: 15,
    padding: 0,
    marginTop: 5,
    marginBottom: 0,
    lineHeight: "13px",
  },
  extractCard: {
    width: "90%",
    marginTop: "15px",
    marginLeft: "5%",
    border: "1px dotted black",
    borderRadius: "4px",
    paddingBottom: "20px",
    marginBottom: "10px"
  },
  extractCardRowHead: {
    width: "90%",
    marginLeft: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: grayColor[0],
    fontSize: "12px",
    borderBottom: "1px dotted black",
  },
  extractCardRow: {
    width: "90%",
    marginLeft: "5%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px dotted black",
    color: blackColor,
    fontWeight: "bold",
    fontSize: "11px",
  },
    zeroMargin: {
    marginTop: 0,
  },
  blackSmallTitleText: {
    color: blackColor,
    fontWeight: "500",
    fontSize: "15px",
  },
  disabledTextArea: {
    width: "100%",

  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  cardTitleGray: {
    color: steelGray[0],
    fontSize: "16px",
    fontWeight: "500",
    padding: 0,
    marginTop: 0,
    marginBottom: 5,
  },
  cardThTxtGray: {
    color: steelGray[1],
    fontSize: "13px",
    lineHeight: "13px",
    fontWeight: "500",
    padding: 0,
    marginTop: 0,
    marginBottom: 7,
  },
  recentlyItemTitle: {
    color: grayColor[19],
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "5%",
    padding: 0,
    marginTop: 0,
    marginBottom: 5,
  },
  recentlyItem: {
    color: steelGray[1],
    fontSize: "12px",
    lineHeight: "11px",
    fontWeight: "500",
    padding: 0,
    marginTop: 0,
    marginBottom: 3,
  },
  recentlyItemContainer: {
    backgroundColor: whiteColor,
    marginTop: "0px",
    marginBottom: "3px",
    // border: "1px solid gray",
    padding: "3px 2px 1px 3px !important",
    // paddingLeft: "0px !important",
    width: "100%",
    // marginLeft: "2.5%",
    // marginRight: "2.5%",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    position: "relative",
    display: "flex",
    borderRadius: "4px",
    flexDirection: "row !important",
    // alignItems: "center",
    // alignContent: "center",
    // justifyContent: "center",
    // minWidth: "0",
    // minHeight: "2.5rem",
    height: "auto",
    maxHeight: "auto",
    // lineHeight: ".975rem",
    // fontSize: ".875rem",
    textAlign: "left",
    "&:hover,&:focus": {
      cursor: "pointer",
      backgroundColor: "#E9E9E9",
    },
    "& span": {
      marginLeft: "0px !important",
      padding: "0px !important",
      alignItems: "center"
    }
  },
  iconRecentlyItem: {
    color: steelGray[1],
    padding: 0,
    marginTop: 0,
    marginBottom: 3,
  },
  cardRowTxtBlack: {
    color: grayColor[8],
    fontSize: "14px",
    lineHeight: "14px",
    textAlign: "left",
    fontWeight: "400",
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  cardRowTxtBlue: {
    color: primaryColor[4],
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "14px",
    textAlign: "left",
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  btnCardRowTxtBlue: {
    color: primaryColor[4],
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "14px",
    textAlign: "left",
    padding: "0px !important",
    marginTop: "-12px !important",
    marginBottom: "0px !important",
    minWidth: "auto !important"
  },
  cardRowSeparator: {
    height: "30px",
    width: "100%",
    margin: 0,
    padding: 0,
    backgroundColor: "transparent"
  },
  orderDetailIdentifierDialog: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: "40%"
  },
  actionCloseContainer: {
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: "30%"
  },
  formIcons: {
    color: whiteColor,
    width: "27px",
    height: "27px"
  },
  iconButtonRed: {
    width: "27px",
    height: "27px",
    color: dangerColor[0]
  },
  orderDetailActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  btnColorPrimary: {
    color: primaryColor[4]
  },
  tableRefreshIcon: {
    color: primaryColor[0],
    width: "27px",
    height: "27px"
  },
  tableRefreshIconDisable: {
    color: grayColor[0],
    width: "27px",
    height: "27px"
  },
  stepPathContainer: {
    marginBottom: 20,
  },
    cardThTxtPrimary: {
    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: "500",
  },
  taskIconGreen: {
    width: "24px",
    height: "24px",
    marginLeft: 5,
    padding: 0,
    color: successColor[2]
  },
  taskIconRed: {
    width: "24px",
    height: "24px",
    color: dangerColor[0],
    marginLeft: 5,
    padding: 0
  },
  checkBox: {
    width: "25px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  checkBoxChecked: {
    color: primaryColor[0] + "!important"
  },
  checkboxLabelGroup: {
    marginTop: 0,
    '& span.MuiTypography-body1': {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.875rem !important",
      lineHeight: "1.1rem !important",
    }
  },
  emptyFormText: {
    fontSize: "14px",
    color: grayColor[1],
    marginTop: 0,
    paddingTop: 0,
    marginBottom: "15px !important"
  },
  backdropCenter: {
    zIndex: 9999,
    color: '#fff',
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
    popperNav: {
        zIndex: 999,
    },
};

export default dashboardStyle;
