import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { statusText, formTitles} from "../../utils/statusHelpers";
import {getMembersByCompany, sendMemberInvite, syncMembers} from "../../services/memberService";
import MemberForm from "./components/MemberForm";
import {
    ENTITY_MEMBER, MEMBER_CSV_EXAMPLE_URL,
    MEMBER_TABLE_ACTIONS, MEMBERS_IMPORT_TYPE, NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS, SEND_INVITE
} from "../../variables/coreConstants";
import {
    getData,
    isEmpty,
    isSuccessfulCreate,
    isSuccessfulRequest,
    nullController,
    saveData
} from "../../utils/helpersFunctions";
import TableBody from "@material-ui/core/TableBody";
import TableActions from "../../components/TableActions/TableActions";
import Muted from "../../components/Typography/Muted";
import {validateSession} from "../../services/api";
import memberDetails from "../../models/MemberModel";
import {getStatus, statusController} from "../../services/statusService";
import {BASE_URL_WEB_SOCKET_SYNC} from "../../variables/apiConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import MemberCsvUploadForm from "./components/MemberCsvUploadForm";
import Link from "@material-ui/core/Link";
import {JOB_CREATED_CODE, STATUS_COMPLETED_JOB, STATUS_IN_PROGRESS_TSK} from "../../variables/apiCodes";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Members extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openMember: false,
            openUploadCsv: false,
            isLoading: true,
            actionType: 0,
            membersData: [],
            filteredMembers: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            currentListJob: null,
            syncInProgress: false,
            paymentList: [],
            pendingTask: [],
            pendingTaskMsg: "",
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openMember", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleOpenForm = () => {
        memberDetails.cleanMemberDetails();
        this.setState({openMember: true, actionType: 0});
    };

    handleOpenUploadForm = () => {
        this.setState({ openUploadCsv: true, importType: null });
    };

    handleCloseUploadForm = (update) => {
        this.setState({ openUploadCsv: false });
        if (update) {
            this.request();
        }
    };

    handleCloseForm = () => {
        this.setState({openMember: false});
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type)
            this.request();
    }
    openForm = (action, formData) => {
        memberDetails.setMemberDetail(formData);

        if (action === 1 || action === 0) {
            this.setState({ openMember: true, actionType: action });

        } else if (action === SEND_INVITE) {
            sendMemberInvite(memberDetails.id)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                    }
                })
                .catch(error => {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        } else {
            let dataParameters = {
                "entity": ENTITY_MEMBER,
                "id": memberDetails.id,
                "status": getStatus(action)
            };

            statusController(action, null, null, dataParameters, this, true)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };

    handleImportTypeChange = (value) => {
        this.setState({
            importType: value
        });
    };


    request = () => {
        this.setState({ isLoading: true} );
        getMembersByCompany()
            .then((res) => {
                this.setState({ membersData: res.data, filteredMembers: res.data, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    // Method to search data
    searchAsset = (e) => {
        let newData = this.state.membersData;
        try {
            newData = newData.filter((item) => {
                return (item.email !== null ? (item.email.search(e.target.value) !== -1) : false)
                    || (item.externalId !== null ? (item.externalId.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.name !== null ? (item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || this.props.t(statusText[item.status]).toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });
            this.setState({filteredMembers: newData});

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    handleGoToDetails = (values) => {
        saveData("member", values);
        this.props.history.push("member-details/" + values.uuid);
    };




    /* Sync Payment Members Implementation */

    handleSync = () => {
        if (!this.state.syncInProgress) {
            this.setState({
                syncInProgress: true
            }, () => {
                syncMembers().then(r => {
                    this.onJobDone(r);
                }).catch(e => {
                    this.onJobError(e);
                });
            });
        }
    };


    /**
     *
     * @param jobId
     * @param callBack
     */
    initSocketConnection = (jobId, callBack) => {
        try {
            // Add URL to the server which will contain the server side setup
            const ws = new WebSocket(BASE_URL_WEB_SOCKET_SYNC);

            // When a connection is made to the server, send the user ID, we can track which
            // socket belongs to which user
            ws.onopen = () => {
                // console.log("Connecting....");
                ws.send(JSON.stringify({action:"registerJob", data: { jobId: jobId, userAuth: getData("userTk") }}))
            };

            // when receives a message from the server
            ws.onmessage = (e) => {
                // console.log("*******************");
                // console.log("Listening....");
                let result = JSON.parse(e.data);
                callBack(result);
            };
        } catch (e) {
            console.log(e)
        }
    };


    /**
     *
     * @param response
     */
    onJobDone = (response) => {
        const { t } = this.props;

        // console.log("RESPONSE REQUEST JOB:");
        // console.log(response);
        if (isSuccessfulCreate(response)) {
            if (!isEmpty(response.data)) {
                if (!isEmpty(response.data.result)) {
                    if (response.data.result.code === JOB_CREATED_CODE.code) {
                        // console.log("Go to init");

                        this.setState({
                            currentListJob: response.data.jobId,
                        }, () => {
                            this.setState({
                                syncInProgress: false,
                                pendingTaskMsg: response.data.result.message
                            });
                            // console.log(contextRouting.currentListJob);
                            // console.log("*****************************************");
                            // this.initSocketConnection(response.data.jobId, this.onJobListener)
                        });

                        this.showNotification(response.data.result.message, "primary", NotificationSuccessIcon, null);

                    } else {
                        this.showNotification(response.data.result.message, "danger", NotificationFailedIcon, null);
                    }
                } else {
                    this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            } else {
                this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
            }
        } else {
            this.showNotification(response.data.message, "danger", NotificationFailedIcon, null);
        }
    };


    /**
     *
     * @param error
     * @param type
     */
    onJobError = (error, type) => {
        const { t } = this.props;

        this.setState({
            loadCompleted: true
        }, () => {
            this.setState({ syncInProgress: false });
        });

        if (!isEmpty(error) && !isEmpty(error.response)) {
            // console.log("ERROR:");
            // console.log(error.response);
            if (!isEmpty(error.response.data) && error.response.data !== "") {
                this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
            } else {
                this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
            }

            validateSession(error.response, this.props.history);
        } else {
            this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }
    };


    /**
     *
     * @param data
     */
    onJobListener = (data) => {
        const { t } = this.props;
        // console.log("JOB DATA FROM LISTENER:");
        // console.log(data);

        if (data !== null && data !== undefined) {
            if (!isEmpty(data.job)) {
                const { job } = data;

                // console.log(job.id + " << == >> " + contextRouting.currentListJob);

                if (job.id === this.state.currentListJob) {
                    if (job.status === STATUS_IN_PROGRESS_TSK) {
                        if (!isEmpty(data.notification)) {
                            // console.log("Adding task...");

                            const { notification } = data;
                            let list = this.state.pendingTask.slice();

                            let index = list.findIndex((val => val.task === notification.task));
                            if (index !== -1) {
                                list[index] = notification;
                                // list.splice(index, 1, notification)
                            } else {
                                list.push(notification);
                            }


                            this.setState({
                                pendingTask: list
                            });
                        }
                    } else if (job.status === STATUS_COMPLETED_JOB) {
                        // console.log("JOB COMPLETED...");

                        this.setState({
                            loadCompleted: false,
                            currentListJob: null
                        }, () => {
                            this.setState({
                                syncInProgress: false,
                                pendingTaskMsg: "",
                                pendingTask: []
                            }, () => {
                                // show notification to show completed sync
                                this.showNotification(t('SYNC_COMPLETED'), "primary", NotificationSuccessIcon, null);

                            });
                        });
                    } else {
                        // show no payments, clean data and show error getting data
                        // console.log("NO STATUS");
                        this.setState({
                            loadCompleted: true,
                            currentListJob: null
                        }, () => {
                            this.setState({
                                syncInProgress: false,
                                pendingTaskMsg: "",
                                pendingTask: []
                            });
                        });
                    }
                }
            }
        }
    };




    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage, currentListJob, syncInProgress, isLoading, filteredMembers } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={syncInProgress || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12}>
                    <TableGridWijmo
                        tableName={'members'}
                        title={t('MEMBERS_TEXT')}
                        exportFileName={t('MEMBERS_TEXT')}
                        // handleLoadMore={() => {}}
                        isLoading={isLoading}
                        data={filteredMembers}
                        actions={[
                            { label: t('BTN_SYNC'), function: this.handleSync, isLoading: syncInProgress, disabled: currentListJob !== null || syncInProgress, type: "simple" },
                            { label: t('BTN_UPLOAD_CSV'), function: this.handleOpenUploadForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_ADD_MEMBER'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            // { label: "No", name: "recordNum", cssClass: "cell-number", isReadOnly: true, width: 50 },
                            { label: t('MEMBER_NAME_TEXT'), name: "name", onSelect: this.handleGoToDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_MEMBER_EXT_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('MEMBER_EMAIL_TXT'), name: "email", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('PHONE_NUMBER_TEXT'), name: "phone", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ROLE'), name: "role.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                            { label: t('CREATED_DATE'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openMember}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t('MEMBER_TEXT')}
                        <Muted>
                            {t('FORM_MEMBER_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <MemberForm closeForm={this.closeForm} action={actionType}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openUploadCsv}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('UPLOAD_MEMBER_CSV_TEXT')}
                        <Muted>
                            {t('FORM_UPLOAD_MEMBER_CSV_DESCRIPTION') + " "}
                            <Link href={MEMBER_CSV_EXAMPLE_URL} target={"_blank"} className={classes.linkText}>
                                {t('DOWNLOAD_CSV')}
                            </Link>
                        </Muted>

                    </DialogTitle>
                    <DialogContent>
                        <MemberCsvUploadForm importType={MEMBERS_IMPORT_TYPE} closeForm={this.handleCloseUploadForm}/>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Members.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Members)));