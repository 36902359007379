import React from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import {withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentDetailsForm from "../../../Payments/components/PaymentDetailsForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";
import {getData, isEmpty} from "../../../../utils/helpersFunctions";


class DebitedPaymentsDetails extends React.Component {
    render() {
        const { classes, t, data, onSearch, isLoading, page, rowsPerPage, handleChangeRowsPerPage,
            handleChangePage, isRequesting, openPaymentDetails, paymentDetails, onClosePayment, onOpenLink,
            handleOpenPayment, isLoadingMore, handleLoadMore, dataItemCount, handleExport, handleRefresh
        } = this.props;

        const debitExecution = getData('debitExecution');


        let fileParameters = {
            debit: !isEmpty(debitExecution) ? debitExecution.debitName : "",
            executionDate: !isEmpty(debitExecution) && !isEmpty(debitExecution.executionDate) ? new Date(parseInt(debitExecution.executionDate)).toDateString() : ""
        };

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TableGridWijmo
                            tableName={'debit_execution_result'}
                            title={t('DEBIT_EXECUTION_DETAILS_TEXT')}
                            exportFileName={t('DEBIT_EXECUTION_RESULT_FILE_NAME', fileParameters)}
                            handleLoadMore={handleLoadMore}
                            data={data}
                            actions={[
                                { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ]}
                            controls={[]}
                            headers={[
                                { label: "No", name: "recordNum", cssClass: "cell-number", isReadOnly: true, width: 50 },
                                { label: t('TH_MEMBER'), name: "memberName", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                                { label: t('TH_PAYMENT_METHOD'), name: "paymentMethod", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                                { label: t('PAYMENT_TEXT'), name: "paymentExternalId", onSelect: handleOpenPayment, isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true, clickable: true },
                                { label: t('TH_AMOUNT'), name: "amountFormatted", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                                { label: t('TH_PAYMENT_STATUS'), name: "paymentStatus", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                                { label: t('TH_SYNC_STATUS'), name: "syncStatus", isReadOnly: true, width: "*", cssClass: "cell-vertical-middle", isCustomCell: true },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
                <Backdrop className={classes.backdrop} open={isRequesting} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={openPaymentDetails}
                    aria-labelledby="form-dialog-title"
                    style={{marginBottom: 0, paddingTop: "0px !important"}}
                >
                    <DialogTitle id="form-dialog-title">
                        {/*<div className={classes.orderDetailActions}>*/}
                        {/*    <div className={classes.actionCloseContainer}>*/}
                        {/*        <Tooltip id="tooltip-close" title={t('BTN_CLOSE')} placement="right-start">*/}
                        {/*            <IconButton size={"small"} onClick={this.handleCloseDetailForm} className={classes.formIcons}>*/}
                        {/*                <CloseIcon className={classes.iconButtonRed} />*/}
                        {/*            </IconButton>*/}
                        {/*        </Tooltip>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {t('PAYMENT_DETAILS_TEXT')}
                    </DialogTitle>
                    <DialogContent>
                        <PaymentDetailsForm data={paymentDetails} closeForm={onClosePayment} openLink={onOpenLink}/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

DebitedPaymentsDetails.propTypes = {
    classes: PropTypes.object,
    onSearch: PropTypes.func,
    onRefresh: PropTypes.func,
    directDebit: PropTypes.object,
    data: PropTypes.array,
    dataItemCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    refreshAvailable: PropTypes.bool,
    isRequesting: PropTypes.bool,
    paymentDetails: PropTypes.object,
    openPaymentDetails: PropTypes.bool,
    onClosePayment: PropTypes.func,
    onOpenLink: PropTypes.func,
    handleOpenPayment: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleLoadMore: PropTypes.func,
    handleRefresh: PropTypes.func,
    handleExport: PropTypes.func
}

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DebitedPaymentsDetails)));