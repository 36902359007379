import React from "react";
import Avatar from "@material-ui/core/Avatar/Avatar";
import PropTypes from "prop-types";
import UserIcon from "@material-ui/icons/AccountCircle";
import {verifyAccessImage} from "../../utils/helpersFunctions";


class CustomAvatar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isImageError: false
        };
    }

    componentDidMount(): void {
        verifyAccessImage(this.props.src, this)
    }

    render() {
        const { className, classNameIcon, alt, src, ...rest } = this.props;
        const { isImageError } = this.state;

        return (
            <div>
                {src != null && !isImageError
                    ? <Avatar alt={alt} src={src} className={className} {...rest}/>
                    : <Avatar alt={alt} className={className} {...rest}>
                        <UserIcon className={classNameIcon} />
                    </Avatar>
                }
            </div>
        );
    }
}

CustomAvatar.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    classNameIcon: PropTypes.string,
    src: PropTypes.string.isRequired,
};

function propsAreEqual(prevProps, nextProps) {
    return prevProps.src === nextProps.src;
}

export default React.memo(CustomAvatar, propsAreEqual);