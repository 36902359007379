import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import Card from "../../../../components/Card/Card.jsx";
import CardBody from "../../../../components/Card/CardBody.jsx";
import Loading from "../../../../components/Loading/Loading";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
    getCompanyCurrency,
    getData,
    getRoundNumber,
    getUser,
    isEmpty, mergeArrays,
    nullController,
    nullValidator,
    removeData,
    saveData,
    setCurrencyValue,
} from "../../../../utils/helpersFunctions";
import { NOTIFICATION_DURATION } from "../../../../variables/coreConstants";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import { validateSession } from "../../../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {
    ROWS_PER_PAGE,
} from "../../../../variables/apiConstants";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Muted from "../../../../components/Typography/Muted";
import { withNotification } from "../../../../components/HOC/Notification/NotificationHOC";
import {
    getBlockDepositDetails,
    getRevenueReconciliationListByBlockDeposit,
} from "../../../../services/blockDepositService";
import ExportBlockDeposit from "./ExportBlockDeposit";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const OBJECT_NAMES = require("../../../../variables/objectConstants");


class BlockDepositScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: props.match.params.id,
            walletAccountId: props.match.params.waId,
            blockDeposit: null,
            currency: getCompanyCurrency(),
            loadingDetail: false,
            isLoading: true,
            isLoadingMore: false,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            lastPage: false,
            totalRecords: 0,
            totalPages: 0,
            count: 0,
            openExport: false,
        };
    }

    componentDidMount() {
        const { showNotificationMessage, t, history, match } = this.props;

        if (
            match.params.id !== "" &&
            match.params.id !== null &&
            match.params.id !== undefined &&
            match.params.waId !== "" &&
            match.params.waId !== null &&
            match.params.waId !== undefined
        ) {
            getBlockDepositDetails(match.params.waId, match.params.id)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        blockDeposit:
                            response.data !== null ? response.data : null,
                    });

                    if (response.data !== null) {
                        saveData("blockDeposit", response.data);
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                    if (error.response) {
                        showNotificationMessage(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            NOTIFICATION_DURATION,
                            null
                        );
                        validateSession(error.response, history);
                    } else {
                        showNotificationMessage(
                            t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            NOTIFICATION_DURATION,
                            null
                        );
                    }
                });

            this.makeInitialRequest();
        } else {
            removeData("blockDeposit");
            this.props.history.goBack();
        }
    }

    makeInitialRequest = () => {
        let dataParameters = {
            pageNo: this.state.page,
            pageSize: this.state.rowsPerPage,
            filterId: this.state.uuid,
            walletAccountId: this.state.walletAccountId
        };

        this.setState({ loadingDetail: true }, () => {
            this.request(dataParameters);
        });
    };

    handleOpenExport = () => {
        this.setState({ openExport: true });
    };
    handleCloseExport = () => {
        this.setState({ openExport: false });
    };

    handleCloseForm = (requireUpdate, states) => {
        this.setState({ openDate: false });
        if (requireUpdate && states !== null) {
            try {
                this.setState({ loadingDetail: true, page: 0 });

                let dataParameters = {
                    pageNo: 0,
                    pageSize: this.state.rowsPerPage,
                    filterId: this.state.uuid,
                    walletAccountId: this.state.walletAccountId
                };

                this.request(dataParameters);
            } catch (e) {
                this.setState({ loadingDetail: false, isLoadingMore: false });
            }
        }
    };

    closeForm = (state, value, type) => {
        this.setState({ [state]: value });
        if (type) {
            this.setState({ loadingDetail: true, page: 0 }, () => {
                let dataParameters = {
                    pageNo: 0,
                    pageSize: this.state.rowsPerPage,
                    filterId: this.state.uuid,
                    walletAccountId: this.state.walletAccountId
                };
                this.request(dataParameters);
            });
        }
    };


    request(parameters) {
        const { showNotificationMessage, t, history } = this.props;
        getRevenueReconciliationListByBlockDeposit(parameters)
            .then((response) => {
                let newData = response.data !== null ? response.data.content.map(r => {
                    r.originalAmountLabel = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "originalAmount"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    r.netAmountLabel = nullController(r, 'currencySymbol') + setCurrencyValue(getRoundNumber(nullController(r, "netAmount"))).replace(nullController(r, 'currencySymbol') + "-", "- " + nullController(r, 'currencySymbol'));
                    return r;
                }) : [];

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid",
                    null
                );

                this.setState({
                    loadingDetail: false,
                    isLoadingMore: false,
                    data: paginatedData,
                    filteredData: paginatedData,
                    totalElements:
                        response.data !== null
                            ? response.data.totalElements
                            : 0,
                    lastPage:
                        response.data !== null ? response.data.last : false,
                    totalPages:
                        response.data !== null ? response.data.totalPages : 0,
                });
            })
            .catch((error) => {
                this.setState({ loadingDetail: false, isLoadingMore: false });
                if (error.response) {
                    showNotificationMessage(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                    validateSession(error.response, history);
                } else {
                    showNotificationMessage(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    }



    handleRevenueReconciliationDetails = (revenue) => {
        saveData("revenueReconciliationDetails", revenue);
        this.props.history.push("/admin/reconciliation/" + revenue.uuid);
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, lastPage } = this.state;

        if (!isLoadingMore && !lastPage) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "pageNo": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "filterId": this.state.uuid,
                    "walletAccountId": this.state.walletAccountId
                };
                this.request(dataParameters);
            });
        }
    };


    render() {
        const { classes, t, routeProps } = this.props;
        const {
            totalElements,
            loadingDetail,
            blockDeposit,
            uuid,
            isLoadingMore,
            isLoading,
            filteredData,
            walletAccountId
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3 className={classes.screenTitle}>
                        {nullController(blockDeposit, "type") +
                            " " +
                            nullController(blockDeposit, "date")}
                    </h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={4} className={classes.gridNoTop}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <p className={classes.balanceCardTitle}>
                                        {t("SUMMARY_TXT")}
                                    </p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={5} sm={5} md={5}>
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {t("TH_GROSS")}
                                                </p>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {t("TH_FEE")}
                                                </p>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {t("TH_TAX")}
                                                </p>
                                                <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                    {t("TH_NET")}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={7} sm={7} md={7}>
                                            <div style={{display: "flex", flexDirection: "column", float: "right", textAlign: "right"}}>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) +
                                                        setCurrencyValue(
                                                            getRoundNumber(
                                                                nullController(
                                                                    blockDeposit,
                                                                    "gross"
                                                                )
                                                            )
                                                        )
                                                    ).replace(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) + "-",
                                                        "- " +
                                                            nullController(
                                                                blockDeposit,
                                                                "currencySymbol"
                                                            )
                                                    )}
                                                </p>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) +
                                                        setCurrencyValue(
                                                            getRoundNumber(
                                                                nullController(
                                                                    blockDeposit,
                                                                    "fee"
                                                                )
                                                            )
                                                        )
                                                    ).replace(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) + "-",
                                                        "- " +
                                                            nullController(
                                                                blockDeposit,
                                                                "currencySymbol"
                                                            )
                                                    )}
                                                </p>
                                                <p className={classes.balanceCardSubTitle}>
                                                    {(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) +
                                                        setCurrencyValue(
                                                            getRoundNumber(
                                                                nullController(
                                                                    blockDeposit,
                                                                    "tax"
                                                                )
                                                            )
                                                        )
                                                    ).replace(
                                                        nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) + "-",
                                                        "- " +
                                                            nullController(
                                                                blockDeposit,
                                                                "currencySymbol"
                                                            )
                                                    )}
                                                </p>
                                                {nullValidator(
                                                    blockDeposit,
                                                    "net"
                                                ) ? (
                                                    <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                        {nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) +
                                                            " " +
                                                            setCurrencyValue(
                                                                getRoundNumber(
                                                                    nullController(
                                                                        blockDeposit,
                                                                        "net"
                                                                    )
                                                                )
                                                            ).replace(
                                                                nullController(
                                                                    blockDeposit,
                                                                    "currencySymbol"
                                                                ) + "-",
                                                                "- " +
                                                                    nullController(
                                                                        blockDeposit,
                                                                        "currencySymbol"
                                                                    )
                                                            )}
                                                    </p>
                                                ) : (
                                                    <p className={classes.balanceCardTotalText} style={{fontWeight: "bold",}}>
                                                        {nullController(
                                                            blockDeposit,
                                                            "currencySymbol"
                                                        ) +
                                                            " " +
                                                            setCurrencyValue(
                                                                getRoundNumber(
                                                                    nullController(
                                                                        blockDeposit,
                                                                        "credit"
                                                                    )
                                                                )
                                                            ).replace(
                                                                nullController(
                                                                    blockDeposit,
                                                                    "currencySymbol"
                                                                ) + "-",
                                                                "- " +
                                                                    nullController(
                                                                        blockDeposit,
                                                                        "currencySymbol"
                                                                    )
                                                            )}
                                                    </p>
                                                )}
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>

                {!isEmpty(blockDeposit) &&
                !isEmpty(blockDeposit.description) ? (
                    <GridItem xs={12} sm={6} md={5} lg={4} className={classes.gridNoTop}>
                        <Card className={classes.cardFixed}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p className={classes.balanceCardTitle}>
                                            {t("DESCRIPTION_TEXT")}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <p
                                            className={
                                                classes.balanceCardSubTitle
                                            }
                                        >
                                            {nullController(
                                                blockDeposit,
                                                "description"
                                            )}
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : null}

                <GridItem xs={12} sm={12} md={12}>
                    <TableGridWijmo
                        tableName={'deposit'}
                        title={t('BLOCK_DEPOSIT_TXT')}
                        exportFileName={nullController(blockDeposit, "type") + " " + nullController(blockDeposit, "date")}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_SEND_SUMMARY'), function: this.handleOpenExport, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.handleRevenueReconciliationDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_MEMBER_REFERENCE'), name: "memberReference", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_INVOICE_REFERENCE'), name: "invoiceId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('ORIGINAL_AMOUNT'), name: "originalAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_PAYMENT_DATE'), name: "paymentDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_POSTED_DATE'), name: "erpSyncedDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TRANSACTION_ID'), name: "transactionId", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('DEPOSITED_AMOUNT_TXT'), name: "netAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openExport}
                    onClose={() => this.handleCloseExport()}
                    aria-labelledby="form-dialog-export"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("FORM_BLOCK_DEPOSIT_EXPORT_TITLE")}
                        <Muted>
                            {t("FORM_BLOCK_DEPOSIT_EXPORT_DESCRIPTION", {
                                email: nullController(getUser(), "email"),
                            })}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <ExportBlockDeposit
                            walletAccountId={walletAccountId}
                            uuid={uuid}
                            closeForm={this.closeForm}
                        />
                    </DialogContent>
                </Dialog>
            </GridContainer>
        );
    }
}

BlockDepositScreen.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withNotification(withTranslation()(BlockDepositScreen))));
