import React from "react";
import {withTranslation} from "react-i18next";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../components/CustomButtons/Button";
import {
    NOTIFICATION_DURATION
} from "../../variables/coreConstants";
import formStyle from "../../assets/jss/custom-components/formStyle";
import * as moment from "moment";
import {
    formatDate,
    initialMomentOfDay,
    nowMomentDate,
} from "../../utils/helpersFunctions";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {validateSession} from "../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {exportConciliationSummary} from "../../services/blockDepositService";
import {enableComponent, isSuccessfulRequest} from "../../utils/helpersFunctions";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";


class ExportRevenueReconciliationForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            startTime: initialMomentOfDay(),
            endTime: nowMomentDate(),
            isLoading: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openExport", false, false);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleStartTimeChange = (date) => {
        date = date > this.state.endTime ? initialMomentOfDay() : date;
        this.setState({ startTime: date });
    };

    handleEndTimeChange = (date) => {
        const currentDate = moment();
        date = date > currentDate ? currentDate : (date < this.state.startTime ? this.state.startTime : date);
        this.setState({ endTime: date } );
    };



    onGenerateCsv = () => {
        this.setState({ isLoading: true }, () => {
            let dataParameters = {
                "initDate": formatDate(this.state.startTime.valueOf()),
                "endDate":formatDate(this.state.endTime.valueOf())
            };

            exportConciliationSummary(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.setState({ isLoading: false });
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        });
    };


    render() {
        const { classes, t, closeForm } = this.props;
        const { isLoading, hideNotification, color, icon, notification, notificationMessage, startTime, endTime } = this.state;

        return (
            <div className={classes.scheduleContainer}>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('START_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={startTime}
                                onChange={this.handleStartTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('END_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={endTime}
                                onChange={this.handleEndTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.buttonContainer}>
                            <div className={classes.rightPaper}>
                                <Button
                                    disabled={isLoading}
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => closeForm("openExport", false, false)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                <Button
                                    color="primary"
                                    className={classes.buttonSpacing}
                                    onClick={() => this.onGenerateCsv()}
                                    disabled={this.state.disableField}
                                >
                                    {t('BTN_SEND')}
                                </Button>
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={color}
                        icon={icon}
                        message={notificationMessage}
                        open={notification}
                        closeNotification={hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ExportRevenueReconciliationForm.propTypes = {
    classes: PropTypes.object,
    closeForm: PropTypes.func
};

export default withRouter(withStyles(formStyle)(withTranslation()(ExportRevenueReconciliationForm)));
