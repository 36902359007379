import { getService, postService, putService } from "./api";
import {
    BASE_URL_CORE,
    CATALOGS_BY_COMPANY,
    CATALOG,
    CATALOGS_BY_STORE,
    SCHEDULES_DAYS,
    CATALOGS_INFORMATION_BY_APP, CATALOGS_BY_APP, CATALOGS_BY_CATEGORY, SCHEDULES_CATALOG
} from "../variables/apiConstants";
import menuDetails from "../models/MenuModel";
import applicationDetails from "../models/ApplicationModel";
import categoryDetails from "../models/CategoryModel";


export function getMenusByCompany() {
    let url = BASE_URL_CORE + CATALOGS_BY_COMPANY;
    return getService(url);
}

export function getMenusByCategory() {
    let url = BASE_URL_CORE + CATALOGS_BY_CATEGORY;
    let dataParameters = { "categoryId": categoryDetails.id };

    return postService(url, dataParameters);
}

export function getMenusByStore(_storeId) {
    let url = BASE_URL_CORE + CATALOGS_BY_STORE + _storeId;
    return getService(url);
}

export function getSchedulesDays() {
    let url = BASE_URL_CORE + SCHEDULES_DAYS;
    return getService(url);
}

export function addMenu(parameters) {
    let url = BASE_URL_CORE + CATALOG;
    return postService(url, parameters);
}

export function editMenu(parameters) {
    let url = BASE_URL_CORE + CATALOG + "/" + menuDetails.id;
    return putService(url, parameters);
}

export function getMenusAssignmentsByApp() {
    let url = BASE_URL_CORE + CATALOGS_INFORMATION_BY_APP;
    let parameters = {
        "app_id": applicationDetails.id
    };

    return postService(url, parameters);
}

export function getMenusByApplication(appId) {
    let url = BASE_URL_CORE + CATALOGS_BY_APP;
    let parameters = {
      "appId": appId
    };

    return postService(url, parameters);
}


export function getSchedulesByMenu(catalogId) {
    let url = BASE_URL_CORE + SCHEDULES_CATALOG;
    let parameters = {
        "catalogId": catalogId
    };

    return postService(url, parameters);
}