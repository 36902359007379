import {TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/DeleteOutline";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import NotificationErrorIcon from "@material-ui/icons/Error";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import memberAuthConfigDetails from "../../../models/MemberAuthConfig";
import {validateEmailDomain} from "../../../utils/helpersFunctions";


class Headers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.component.state.domainName,
            value: this.props.component.state.domainUuid,
            selectedItem: null
        };
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };



    editItem = (item) => {
        const { component } = this.props;

        //Find index of specific object using findIndex method.
        let index = memberAuthConfigDetails.domains.findIndex((val => val.id === item.id));

        if (index !== -1) {
            memberAuthConfigDetails.domains[index].isEditing = true;

            component.setState({
                domains:  memberAuthConfigDetails.domains,
                disabledItem: true
            });

            this.setState({
                selectedItem: item,
                name: item.name,
                value: item.value,
            })
        }
    };


    saveItemChanges = (item) => {
        const { component, t } = this.props;

        if (this.state.name !== "" && validateEmailDomain(this.state.name)) {
            //Find index of specific object using findIndex method.
            let index = memberAuthConfigDetails.domains.findIndex((val => val.id === item.id));

            memberAuthConfigDetails.domains[index].name = this.state.name;
            memberAuthConfigDetails.domains[index].value = this.state.value;
            memberAuthConfigDetails.domains[index].isEditing = false;

            this.setState({
                selectedItem: null,
                name: "",
                value: "",
            });
            component.setState({
                domains:  memberAuthConfigDetails.domains,
                disabledItem: false,
                disableField: false,
            });
        } else {
            component.showNotification(t('INVALID_DOMAIN_MSG'), "danger", NotificationErrorIcon);
        }
    };


    deleteItem = (item) => {
        const { component } = this.props;

        let index = memberAuthConfigDetails.domains.findIndex(val => val.id === item.id);
        memberAuthConfigDetails.domains.splice(index, 1);
        component.setState({
            domains: memberAuthConfigDetails.domains,
            disabledItem: false,
            disableField: false
        });

        this.setState({ selectedItem: null, name: ''});
    };



    render() {
        const { classes, items, t } = this.props;
        const { selectedItem, name } = this.state;
        return (
            <div>
                {
                    items.map((item, key) => (
                        <div key={key} className={classes.scheduleContainer}>
                            {
                                item.isEditing
                                    ? (
                                        <GridContainer>
                                            <GridItem xs={12} sm={5} md={5}>
                                                <TextField
                                                    value={name}
                                                    id="name"
                                                    name="name"
                                                    placeholder={t('NAME_TEXT')}
                                                    autoFocus={true}
                                                    fullWidth={true}
                                                    onChange={this.handleChangeValues}
                                                    className={classes.modifierItemNameContainer}
                                                    margin="dense"
                                                    variant="outlined"/>
                                            </GridItem>
                                            <GridItem xs={10} sm={5} md={5}>
                                                <Tooltip id="tooltip-save" title={t('BTN_SAVE_CHANGES')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.saveItemChanges(item)}
                                                        style={{marginLeft: 25, marginTop:13}}>
                                                        <SaveIcon className={classes.iconButtonGreen} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="tooltip-delete" title={t('DELETE_ITEM_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.deleteItem(item)}
                                                        style={{marginTop:13}}>
                                                        <ClearIcon className={classes.iconButtonRed} />
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>
                                    ) : (
                                        <GridContainer>
                                            <GridItem xs={12} sm={5} md={5}>
                                                <Chip label={item.name} style={{maxWidth: "350px"}}/>
                                            </GridItem>
                                            <GridItem xs={10} sm={5} md={5}>
                                                <Tooltip id="tooltip-edit" title={t('EDIT_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.editItem(item)}
                                                        disabled={selectedItem != null && selectedItem !== item}
                                                        style={{marginLeft: 25}}>
                                                        <EditIcon className={classes.iconButtonBlue} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="tooltip-delete" title={t('DELETE_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={selectedItem != null && selectedItem !== item}
                                                        onClick={() => this.deleteItem(item)}>
                                                        <ClearIcon className={classes.iconButtonRed} />
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>
                                    )
                            }
                        </div>
                    ))
                }
            </div>
        );
    }

}

Headers.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(Headers)));
