import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import {withRouter} from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {nullController, saveData} from "../../../utils/helpersFunctions";
import {
    APP_ZONES_DETAILS_TABLE_ACTIONS,
} from "../../../variables/coreConstants";
import Status from "../../../components/Status/Status";



class AppZonesDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            zonesData: this.props.data,
            filteredZones: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
        };
    }



    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    goToDetails = (val) => {
        saveData("zone", val);
        this.props.history.push("quick-actions-zone");
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody className={classes.cardBodyBottomFit}>
                            <div className={classes.tableResumeResponsive}>
                                <Table className={classes.table}>
                                    <TableHead className={classes.objectColor}>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}>
                                                Name
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}>
                                                Description
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}>
                                                Range (mts)
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}>
                                                Status
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell + " " + classes.tableHeadCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.filteredZones.slice(
                                            page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                            return (
                                                <TableRow key={key} hover={true}>
                                                    <TableCell
                                                        className={classes.tableCell + " " + classes.hoverRow}
                                                        onClick={() => this.goToDetails(val)}>
                                                        {nullController(val, 'name')}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {nullController(val, 'description')}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {nullController(val, 'range')}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Status
                                                            className={classes.status}
                                                            color={statusColors[val.status]}
                                                            size="lg"
                                                            label={t(statusText[val.status])}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {
                                                            <TableActions
                                                                rowData={val}
                                                                options={APP_ZONES_DETAILS_TABLE_ACTIONS}
                                                            />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                component="div"
                                count={this.state.filteredZones.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                backIconButtonProps={{
                                    'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'next page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

AppZonesDetails.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(AppZonesDetails)));