import {getService, postService, putService} from "./api";
import {
    BANK_PROVIDERS_AVAILABLE,
    BASE_URL_BALANCE,
    CASH_ACCOUNTS_AVAILABLE,
    CURRENCIES_AVAILABLE,
    WALLET_MOVEMENT,
    TRANSFER_DETAILS,
    TRANSFER_TYPES,
    TRANSFERS_BY_COMPANY,
    UPDATE_TRANSFER,
    TRANSFER_SUMMARY_CSV,
} from "../variables/apiConstants";


export function getTransfersByCompany(parameters) {
    let url = BASE_URL_BALANCE + TRANSFERS_BY_COMPANY;

    return postService(url, parameters);
}

export function getTransferTypes() {
    let url = BASE_URL_BALANCE + TRANSFER_TYPES;

    return getService(url);
}

export function getTransferDetail(uuid) {
    let url = BASE_URL_BALANCE + TRANSFER_DETAILS + uuid;

    return getService(url);
}

export function addWalletMovement(parameters) {
    let url = BASE_URL_BALANCE + WALLET_MOVEMENT;

    return postService(url, parameters);
}

export function editTransfer(parameters) {
    let url = BASE_URL_BALANCE + UPDATE_TRANSFER;

    return putService(url, parameters);
}


export function getCashAccountsAvailable(walletAccountId) {
    let url = BASE_URL_BALANCE + CASH_ACCOUNTS_AVAILABLE + walletAccountId;

    return getService(url);
}


export function getBankProvidersAvailable() {
    let url = BASE_URL_BALANCE + BANK_PROVIDERS_AVAILABLE;

    return getService(url);
}

export function getCurrenciesAvailable() {
    let url = BASE_URL_BALANCE + CURRENCIES_AVAILABLE;
    return getService(url);
}


export function generateTransferSummaryCsv(parameters) {
    let url = BASE_URL_BALANCE + TRANSFER_SUMMARY_CSV;

    return postService(url, parameters);
}

