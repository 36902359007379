import React, {  useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import withStyles from "@material-ui/core/styles/withStyles";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import {GOOGLE_API} from "../../variables/apiConstants";
import {getNonceProvider} from "../../utils/helpersFunctions";



function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    script.nonce = getNonceProvider();
    position.appendChild(script);
}

const autocompleteService = { current: null };
let sessionToken;


function GooglePlaces(props) {
    const [value, setValue] = React.useState(props.value);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const [generateToken, setGenerateToken] = React.useState(true);


    const {
        classes,
        formControlProps,
    } = props;



    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }
        loaded.current = true;

    }

// Create a new session token.

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions({
                    input: request.input,
                    sessionToken: sessionToken
                }, callback);
            }, 200),
        [],
    );



    const setSelectedOptions = (newOptions) =>{
        setOptions(newOptions);
    };


    useEffect(() => {
        /*
      Callback to get place details
      */
        if (value != null && typeof value === "object" ){
            props.setPlaceReferenceData(value.place_id, value.reference, value.description);

            let request = {
                placeId: value.place_id,
                fields: ['address_component', "adr_address", 'formatted_address', 'geometry', 'icon', 'plus_code', 'vicinity'],
                sessionToken: sessionToken
            };

            const map = new window.google.maps.Map(document.getElementById("google-map"));

            const service = new window.google.maps.places.PlacesService(map);
            service.getDetails(request, callbackGetDetails);
            setGenerateToken(true)
        }
    }, [value]);



    const setSelectedValue = (newInputValue) =>{
        setInputValue(newInputValue);

        if (generateToken){
            sessionToken = new window.google.maps.places.AutocompleteSessionToken();
            setGenerateToken(false)
        }
    };

    function callbackGetDetails(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setGenerateToken(true);

            /*
            add returned values
            */
           let postCode = extractFromAddress(place.address_components, "postal_code");
           let city = extractFromAddress(place.address_components, "administrative_area_level_2");
           let latitude = place.geometry.location.lat();
           let longitude = place.geometry.location.lng();
           let formattedAddress = place.formatted_address;

           props.setPlaceAddress(postCode, city, latitude, longitude, formattedAddress);
        }
    }


    function extractFromAddress(components, type){
        for (let i=0; i<components.length; i++)
            for (let j=0; j<components[i].types.length; j++)
                if (components[i].types[j]===type) return components[i].long_name;
        return "";
    }




    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }

        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);



    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + " " + classes.formControl}
        >
        <Autocomplete
            id="google-map"
            className={classes.autoCompleteStyle}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            includeInputInList
            filterSelectedOptions
            value={value}
            size={'small'}
            onChange={(event, newValue) => {
                setSelectedOptions(newValue ? [newValue, ...options] : options);
                setSelectedValue(newValue);
                setValue(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={props.labelText} variant="outlined" fullWidth/>
            )}
            renderOption={(option) => {
                if (typeof option === "object") {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                        option.type,
                        option.location
                    );

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }
            }}
        />
        </FormControl>
    );
}

GooglePlaces.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    error: PropTypes.bool,
    success: PropTypes.bool
};


export default withStyles(customInputStyle)(GooglePlaces);
