import React, {useState} from "react";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import Slide from "@material-ui/core/Slide";


export const withNotification = (WrappedComponent) => {
    return props => {
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("");
        const [duration, setDuration] = useState(NOTIFICATION_DURATION);
        const [severity, setSeverity] = useState("success");
        const [icon, setIcon] = useState(NotificationSuccessIcon);



        const showNotification = (message, severity, icon, duration, callbackFunction) => {
            setMessage(message);
            setSeverity(severity);
            setIcon(icon);
            setDuration(duration === null || duration === undefined ? NOTIFICATION_DURATION : duration);
            setOpen(true);

            window.setTimeout(
                () => {
                    setOpen(false);
                    if (callbackFunction !== null && callbackFunction) {
                        callbackFunction();
                    }
                },
                duration
            );
        };

        const hideNotification = (event, reason) => {
            if (reason === 'clickaway') { return; }
            setOpen(false);
        };


        return (
            <>
                <WrappedComponent {...props} showNotificationMessage={showNotification} />
                <Snackbar
                    place="tc"
                    color={severity}
                    icon={icon}
                    message={message}
                    open={open}
                    closeNotification={hideNotification}
                    TransitionComponent={Slide}
                    close
                />

            </>
        );
    };
};
