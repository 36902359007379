import * as moment from "moment";

let _date = moment();

var discountDetail = {
    uuid: null,
    code: "",
    name: "",
    description: "",
    maxAmount: 0.00,
    status: null,
    applyAllStores: false,
    applyAllProducts: false,
    applyOnlyToCompany: false,
    quantity: 0,
    quantityPerUser: 0,
    value: 0.00,
    typeValue: { value: 0, label: "Select..." },
    validFrom: _date,
    validTo: _date,


    setDiscountDetails : function (values) {
        try {
            this.uuid = values.uuid;
            this.code = values.code;
            this.name = values.name;
            this.description = values.description;
            this.status = values.status;
            this.applyAllStores = values.applyAllStores;
            this.applyAllProducts = values.applyAllProducts;
            this.applyOnlyToCompany = values.applyOnlyToCompany;
            this.maxAmount = values.maxAmount.toFixed(2);
            this.quantity = values.quantity;
            this.quantityPerUser = values.maxQuantityUser;
            this.value = values.value.toFixed(2);
            this.validFrom = moment(new Date(values.validFrom));
            this.validTo = moment(new Date(values.validTo));
        } catch (e) { console.log("Something went wrong") }
    },

    cleanDiscountDetails : function () {
        this.uuid = null;
        this.typeValue.value = 0;
        this.typeValue.label = "Select...";
        this.code = "";
        this.name = "";
        this.description = "";
        this.status = null;
        this.applyAllStores = false;
        this.applyAllProducts = false;
        this.applyOnlyToCompany = false;
        this.maxAmount = 0.00;
        this.quantity = 0;
        this.quantityPerUser = 0;
        this.value = 0.00;
        this.validFrom = _date;
        this.validTo= _date;
    },
};

export default discountDetail;