import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {
    disableComponent,
    enableComponent,
    getUser,
    isSuccessfulRequest,
    nullController,
    updateUser,
} from "../../utils/helpersFunctions";
import {
    getCompanyInformation,
    uploadCompanyLogo,
} from "../../services/accountService";
import Loading from "../../components/Loading/Loading";
import { validateSession } from "../../services/api";
import companyDetails from "../../models/AccountModel";
import { withTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import AccountSettings from "./AccountSettings";
import DashboardUsers from "../Users/DashboardUsers";
import DashboardPermissions from "../Users/DashboardPermissions";
import Avatar from "@material-ui/core/Avatar";
import IMAGE_HOLDER from "../../assets/images/image_placeholder.jpg";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Muted from "../../components/Typography/Muted";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ImageSelector from "../Stores/components/ImageSelector";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {
    BASE64_HEADER,
    DATA_TYPE_SUPPORTED,
    NOTIFICATION_DURATION,
} from "../../variables/coreConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";

class AccountContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            companyInformation: [],
            isLoading: false,
            logo: "",
            crop: {
                unit: "px",
                width: 250,
                height: 250,
                aspect: 1,
            },
            imageType: "logo",
            openImage: false,
            disableField: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
    }

    componentDidMount() {
        if (this.hasAccountSettingsPermission()) {
            this.setState({ isLoading: true });
            this.request();
        } else {
            this.props.history.push("/admin/not-found");
        }
    }

    handleCloseForm = () => {
        this.setState({ openImage: false });
    };
    handleOpenForm = () => {
        this.setState({ openImage: true });
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null) this.request();
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleCompleteForm = () => {
        this.setState({ openImage: false });
        const { t } = this.props;

        if (this.state.logo === "" && this.state.logo === null) {
            this.showNotification(
                t("REQUIRED_LOGO_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            let logoBase64Data = "";
            if (this.state.logo !== null) {
                if (this.state.logo.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
                    logoBase64Data = this.state.logo.slice(
                        BASE64_HEADER.length
                    );
                } else {
                    logoBase64Data = this.state.logo;
                }
            }

            if (logoBase64Data !== "") {
                let dataParameters = {
                    logo: logoBase64Data,
                };

                disableComponent(this, "disableField");
                uploadCompanyLogo(dataParameters)
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            enableComponent(this, "disableField");
                            this.showNotification(
                                response.data.message,
                                "primary",
                                NotificationSuccessIcon,
                                true
                            );
                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(
                                response.data.message,
                                "danger",
                                NotificationErrorIcon,
                                null
                            );
                        }
                    })
                    .catch((error) => {
                        enableComponent(this, "disableField");
                        this.showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        if (error.response)
                            validateSession(error.response, this.props.history);
                    });
            } else {
                this.showNotification(
                    t("SOMETHING_WENT_WRONG"),
                    "danger",
                    NotificationErrorIcon,
                    null
                );
            }
        }
    };

    hasAccountSettingsPermission = () => {
        let result = false;

        try {
            let userTabs = getUser().userRoutes;

            let index = userTabs.findIndex(
                (x) => x.routeUrl === "/company-settings"
            );
            if (index !== -1) {
                result = true;
            }
        } catch (e) {}

        return result;
    };

    request() {
        getCompanyInformation()
            .then((response) => {
                this.setState({
                    companyInformation: response.data,
                    logo: response.data.logo,
                    isLoading: false,
                });

                companyDetails.setCompanyDetail(response.data);
                updateUser(response.data);

                try {
                    let key = 0;
                    companyDetails.timeZone = response.data.timeZones.map(
                        (item) => {
                            key++;
                            return { value: key, label: item };
                        }
                    );
                    companyDetails.currentTime = response.data.currentTimeZone;
                } catch (e) {
                    console.log("Something went wrong");
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    validateSession(err.response, this.props.history);
                }
            });
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            value,
            isLoading,
            crop,
            imageType,
            companyInformation,
            openImage,
            disableField,
            logo,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={10} md={10}>
                                <h3 className={classes.headTitle}>
                                    {nullController(
                                        companyInformation,
                                        "fullName"
                                    )}
                                </h3>
                            </GridItem>
                            <GridItem xs={12} sm={10} md={2}>
                                <div className={classes.logoContainer}>
                                    <Tooltip
                                        id="tooltip-info"
                                        title={t("UPLOAD_IMAGE_TXT")}
                                        placement="right-start"
                                    >
                                        <IconButton
                                            className={classes.accountPhotoCard}
                                            onClick={this.handleOpenForm}
                                            disabled={disableField}
                                        >
                                            <Avatar
                                                alt="Profile"
                                                src={
                                                    logo !== "" && logo !== null
                                                        ? logo
                                                        : IMAGE_HOLDER
                                                }
                                                className={
                                                    classes.accountWithoutPhoto
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Muted>{t("COMPANY_LOGO_TXT")}</Muted>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <AppBar
                                    position={"static"}
                                    className={classes.tabBar}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={this.handleChange}
                                        aria-label="tab"
                                    >
                                        <Tab
                                            label={t("GENERAL_TEXT")}
                                            {...this.a11yProps(0)}
                                        />
                                        <Tab
                                            label={t("USERS_TEXT")}
                                            {...this.a11yProps(1)}
                                        />
                                        <Tab
                                            label={t("PERMISSIONS_SET_TEXT")}
                                            {...this.a11yProps(2)}
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <AccountSettings
                                        loading={isLoading}
                                        data={companyInformation}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <DashboardUsers
                                        loading={isLoading}
                                        data={companyInformation}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <DashboardPermissions
                                        loading={isLoading}
                                        data={companyInformation}
                                    />
                                </TabPanel>
                            </GridItem>
                            <Dialog
                                maxWidth={"md"}
                                fullWidth={true}
                                open={openImage}
                                onClose={this.handleCloseForm}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">
                                    {t("FORM_IMAGE_TEXT")}
                                </DialogTitle>
                                <DialogContent>
                                    <ImageSelector
                                        crop={crop}
                                        component={this}
                                        type={imageType}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Snackbar
                                place="tc"
                                color={this.state.color}
                                icon={this.state.icon}
                                message={this.state.notificationMessage}
                                open={this.state.notification}
                                closeNotification={this.hideNotification}
                                close
                            />
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

AccountContainer.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(AccountContainer)));
