import React from "react";
import Button from "../../components/CustomButtons/Button";


function FileUploader({...props}) {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        props.handleFile(event);
    };
    return (
        <>
            <Button color={props.color} onClick={handleClick} className={props.className}>{props.label}</Button>
            <input type="file"
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display:'none'}}
            />
        </>
    );
}

export default (FileUploader);