import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {nullController} from "../../../utils/helpersFunctions";
import {
    DISCOUNT_STORE_TABLE_ACTIONS,
    ENTITY_STORE_DISCOUNT,
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import {getStatus, statusController} from "../../../services/statusService";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Muted from "../../../components/Typography/Muted";
import StoreDiscountAssignmentForm from "./StoreDiscountAssignmentForm";


class DiscountStoreDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openStore: false,
            isLoading: false,
            storesData: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openStore", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    handleOpenForm = () => {
        this.setState({ openStore: true });
    };
    handleCloseForm = () => {
        this.setState({ openStore: false });
    };


    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type) {
            this.props.component.onCompleteRequest(type);
        }
    }
    openForm = (action, formData) => {
        let status = getStatus(action);

        let dataParameters = {
            "entity": ENTITY_STORE_DISCOUNT,
            "uuid": formData.id,
            "status": status
        };
        statusController(action, null, null, dataParameters, this.props.component, true);
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value, page: 0 });
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage, isLoading} = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <div className={classes.typo}>
                        <h4 style={{marginBottom: 0}}> {t('STORES_TEXT')} </h4>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <div className={classes.rightPaper}>
                        <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_ASSIGN_STORE')}</Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    style={{width: "20%"}}
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.storesData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'name')}
                                                        </TableCell>
                                                        <TableCell style={{width: "20%"}} className={classes.tableCell}>
                                                            <TableActions
                                                                rowData={val}
                                                                openForm={this.openForm}
                                                                options={DISCOUNT_STORE_TABLE_ACTIONS}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.storesData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"xs"}
                        fullWidth={true}
                        open={this.state.openStore}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_ASSIGN_STORES_TITLE')}
                            <Muted>
                                {t('FORM_ASSIGN_STORE_DISCOUNT_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <StoreDiscountAssignmentForm closeForm={this.closeForm} />
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

DiscountStoreDetails.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DiscountStoreDetails)));