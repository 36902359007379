import React from 'react';
import {useTranslation} from "react-i18next";
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import signInStyle from "../../assets/jss/custom-components/signInStyle";
import Tooltip from "@material-ui/core/Tooltip";


export default function AccountForm(props) {
    const classes = signInStyle();
    const {t} = useTranslation();

    const [values, setValues] = React.useState({
        firstName:'',
        lastName:'',
        email: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
    });


    const handleChange = () => e => {

        const name = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [name]: value });
        props.handleFields(name, value);
    };

    const handleFieldPassword = () => e => {

        const name = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [name]: value });
        props.handleFieldPassword(value);
    };

    const showPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textFieldPrimary}
                        autoComplete="fname"
                        name="firstName"
                        id="firstName"
                        value={props.values.firstName}
                        onChange={handleChange()}
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        label={t('FIRST_NAME_TEXT')}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textFieldPrimary}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        id="lastName"
                        value={props.values.lastName}
                        onChange={handleChange()}
                        label={t('LAST_NAME_TEXT')}
                        name="lastName"
                        autoComplete="lname"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textFieldPrimary}
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        value={props.values.email}
                        onChange={handleChange()}
                        id="email"
                        label={t('EMAIL_ADDRESS_TEXT')}
                        name="email"
                        autoComplete="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Tooltip
                        disableHoverListener
                        disableTouchListener
                        title={t('PASSWORD_WEAK_MESSAGE')}
                        placement={"bottom"}
                    >
                    <TextField
                        className={props.values.passwordError ? classes.textFieldError: classes.textFieldSuccess}
                        variant="outlined"
                        required
                        fullWidth
                        margin="dense"
                        value={props.values.password}
                        name="password"
                        label={t('PASSWORD_TEXT')}
                        type={values.showPassword ? 'text' : 'password'}
                        id="password"
                        onChange={handleFieldPassword()}
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle password visibility"
                                        onClick={showPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={classes.textFieldPrimary}
                        required
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={props.values.confirmPassword}
                        name="confirmPassword"
                        label={t('CONFIRM_PASSWORD_TEXT')}
                        type={values.showPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        onChange={handleChange()}
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle password visibility"
                                        onClick={showPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
                <Grid item xs={12}>
                    <div className={classes.privacyPolicyLabel}>
                        {t('BY_CLICKING_TEXT')}
                        <Link href="https://ginih.com/terms-of-service/" target={"_blank"} rel="noopener noreferrer" variant="body2" className={classes.privacyPolicyLink}>
                            {t('TERMS_USE_TEXT')}
                        </Link>
                        { " " + t('AND_TEXT') + " "}
                        <Link href="https://ginih.com/privacy-policy/" target={"_blank"} variant="body2" className={classes.privacyPolicyLink}>
                            {t('PRIVACY_POLICY_TEXT')}
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}