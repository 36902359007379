import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {
    confirmAccount,
    requestConfirmEmail,
    resendConfirmationEmail,
} from "../../services/accountService";
import {
    IMG_ERROR,
    IMG_NOT_FOUND,
    IMG_SUCCESSFUL_CONFIRMATION,
} from "../../variables/resourcesConstants";
import { isSuccessfulRequest } from "../../utils/helpersFunctions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { ERROR_PASSWORD_EXPIRED } from "../../variables/coreConstants";
import {withRouter} from "react-router-dom";

const useStyles = {
    paper: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
    },
    messageComponent: {
        width: "40%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
    },
    image: {
        width: "250",
        height: "250px",
    },
    title: {
        marginTop: "15px",
    },
    backdrop: {
        zIndex: 9999,
        color: "#fff",
    },
    messageText: {
        marginTop: 0,
    },
};

class ConfirmationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            message: "",
            imgMessage: IMG_SUCCESSFUL_CONFIRMATION,
            showResend: true,
            isRequesting: true,
        };
    }

    componentDidMount() {
        const { match } = this.props;

        if (match.params.code) {
            if (match.params.referenceId) {
                this.submitConfirmEmail(match.params);
            } else {
                this.submitConfirmAccount(match.params);
            }
        }
    }

    submitConfirmAccount = (params) => {
        const { t } = this.props;
        let tk = params.code;

        confirmAccount(tk)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_SUCCESS"),
                        message: response.data.message,
                        isRequesting: false,
                        showResend: false,
                    });
                } else {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_FAIL"),
                        message: response.data.message,
                        imgMessage: IMG_ERROR,
                        showResend: true,
                        isRequesting: false,
                    });
                }

                if (
                    response.data.entity !== null &&
                    response.data.entity.errorData !== null
                ) {
                    if (
                        response.data.entity.errorType ===
                        ERROR_PASSWORD_EXPIRED
                    ) {
                        this.props.history.push(
                            "/reset-password/" +
                                response.data.entity.errorData.referenceId +
                                "/" +
                                response.data.entity.errorData.code
                        );
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    title: t("CONFIRM_ACCOUNT_TITLE_ERROR"),
                    message: error.response.data.message,
                    imgMessage: IMG_NOT_FOUND,
                    isRequesting: false,
                    showResend: true,
                });

                if (
                    error.response.data.entity !== null &&
                    error.response.data.entity.errorData !== null
                ) {
                    if (
                        error.response.data.entity.errorType ===
                        ERROR_PASSWORD_EXPIRED
                    ) {
                        this.props.history.push(
                            "/reset-password/" +
                                error.response.data.entity.errorData
                                    .referenceId +
                                "/" +
                                error.response.data.entity.errorData.code
                        );
                    }
                }
            });
    };

    submitResendEmail = () => {
        const { t, match } = this.props;

        this.setState({ isRequesting: true });
        resendConfirmationEmail({ secretCode: match.params.code })
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_SUCCESS"),
                        message: response.data.message,
                        isRequesting: false,
                        imgMessage: IMG_SUCCESSFUL_CONFIRMATION,
                        showResend: false,
                    });
                } else {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_FAIL"),
                        message: response.data.message,
                        imgMessage: IMG_ERROR,
                        isRequesting: false,
                        showResend: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    title: t("CONFIRM_ACCOUNT_TITLE_ERROR"),
                    message: error.response.data.message,
                    imgMessage: IMG_NOT_FOUND,
                    isRequesting: false,
                    showResend: false,
                });
            });
    };

    submitConfirmEmail = (params) => {
        const { t } = this.props;

        let code = params.code;
        let reference = params.referenceId;

        let dataParameters = {
            secret_code: code,
            reference_id: reference,
        };

        requestConfirmEmail(dataParameters)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_SUCCESS"),
                        message: response.data.message,
                        isRequesting: false,
                        showResend: false,
                    });
                } else {
                    this.setState({
                        title: t("CONFIRM_ACCOUNT_TITLE_FAIL"),
                        message: response.data.message,
                        imgMessage: IMG_ERROR,
                        isRequesting: false,
                        showResend: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    title: t("CONFIRM_ACCOUNT_TITLE_ERROR"),
                    message: error.response.data.message,
                    imgMessage: IMG_NOT_FOUND,
                    isRequesting: false,
                    showResend: false,
                });
            });
    };

    static goToSignIn(history) {
        history.push("/sign-in");
    }

    render() {
        const { classes, t } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() +
              " - " +
              t("CONFIRMATION_ACCOUNT_TEXT")
            : t("CONFIRMATION_ACCOUNT_TEXT");

        return (
            <GridContainer>
                <Backdrop
                    className={classes.backdrop}
                    open={this.state.isRequesting}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.isRequesting ? null : (
                        <div className={classes.paper}>
                            <div className={classes.messageComponent}>
                                <img
                                    className={classes.image}
                                    src={this.state.imgMessage}
                                    alt={"Message Icon"}
                                />
                                <Typography
                                    component="h4"
                                    variant="h4"
                                    className={classes.title}
                                >
                                    {this.state.title}
                                </Typography>
                                <h4 className={classes.messageText}>
                                    {this.state.message}
                                </h4>
                                {this.state.showResend ? (
                                    <Button
                                        onClick={this.submitResendEmail}
                                        size="small"
                                        color="primary"
                                    >
                                        {t("BTN_RESEND")}
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    )}
                </GridItem>
            </GridContainer>
        );
    }
}

export default withRouter(withStyles(useStyles)(withTranslation()(ConfirmationPage)));
