/* Created by Marlon Reyes on July 25, 2019 */

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import { withRouter } from "react-router-dom";
import {
    getData, isEmpty,
    isSuccessfulRequest,
    nullController,
    nullValidator,
} from "../../../utils/helpersFunctions";
import { MENU, NOTIFICATION_DURATION } from "../../../variables/coreConstants";
import Loading from "../../../components/Loading/Loading";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AppZonesDetails from "./AppZonesDetails";
import { getZonesByApplication } from "../../../services/zoneService";
import { validateSession } from "../../../services/api";
import { getMenusByApplication } from "../../../services/menuService";
import AppMenusDetails from "./AppMenusDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withTranslation } from "react-i18next";
import { statusController } from "../../../services/statusService";
import { APP_CATALOG, BASE_URL_CORE } from "../../../variables/apiConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import MenuAppAssignmentForm from "./MenuAppAssignmentForm";

class QuickActionsApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            app: getData("app"),
            zonesData: [],
            menusData: [],
            menusOptions: [],
            isLoadingZ: false,
            isLoadingM: false,
            openMenu: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request(null);
    }

    componentWillUnmount() {
        // removeData("app");
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh !== null)
                    this.closeForm("openMenu", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    openMenuForm = () => {
        this.setState({
            openMenu: true,
        });
    };
    handleCloseForm = () => {
        this.setState({ openMenu: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request(state);
    }

    openForm = (action, formData) => {
        if (action === 1 || action === 0) {
            this.setState({
                openMenu: true,
                actionType: action,
            });
        } else {
            let dataParameters = {
                appCatalogId: formData.assignId,
            };

            statusController(
                action,
                BASE_URL_CORE,
                APP_CATALOG,
                dataParameters,
                this,
                false
            )
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            false
                        );
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            false
                        );
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    };

    request(state) {
        let app = getData("app");

        if (state === null && app !== null) {
            this.setState({
                isLoadingZ: true,
            });

            getZonesByApplication(app.id)
                .then((response) => {
                    if (typeof response.data == "object") {
                        this.setState({ zonesData: response.data });
                    }
                    this.setState({ isLoadingZ: false });
                })
                .catch((err) => {
                    this.showNotification(
                        err.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    this.setState({ isLoadingZ: false });

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });
        }

        // If application type is menu show menus assigned
        if (!isEmpty(app) && !isEmpty(app.typeId) && app.typeId.id === MENU.value) {
            this.setState({
                isLoadingM: true,
            });
            getMenusByApplication(app.id)
                .then((response) => {
                    if (typeof response.data == "object") {
                        let values = response.data.unassigned.map((value) => {
                            return { value: value.id, label: value.name };
                        });

                        this.setState({
                            menusData: response.data.assigned,
                            menusOptions: values,
                        });
                    }
                    this.setState({ isLoadingM: false });
                })
                .catch((err) => {
                    this.setState({ isLoadingM: false });

                    if (err.response) {
                        this.showNotification(
                            err.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        validateSession(err.response, this.props.history);
                    }
                });
        }
    }

    goToApps = () => {
        this.props.history.push("applications");
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { isLoadingZ, app, menusOptions } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.app, "name")}
                            </h3>
                            <Muted>
                                {nullController(this.state.app, "description")}
                            </Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                Edit
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToApps}
                            >
                                Go to applications
                            </CustomButton>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.typo}>
                            <h4> Zones </h4>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {isLoadingZ ? (
                            <Loading />
                        ) : (
                            <AppZonesDetails data={this.state.zonesData} />
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {nullValidator(app, "typeId") &&
                        app.typeId.id === MENU.value ? (
                            <AppMenusDetails
                                component={this}
                                data={this.state.menusData}
                            />
                        ) : null}
                    </GridItem>
                    <Dialog
                        maxWidth={"xs"}
                        fullWidth={true}
                        open={this.state.openMenu}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t("FORM_ASSIGN_MENU_TEXT")}
                        </DialogTitle>
                        <DialogContent>
                            <MenuAppAssignmentForm
                                menusOptions={menusOptions}
                                closeForm={this.closeForm}
                            />
                        </DialogContent>
                    </Dialog>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsApplication.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsApplication))
);
