import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {grayColor, primaryColor} from "../../assets/jss/material-dashboard-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ColorCheckbox = withStyles({
    root: {
        color: grayColor[0],
        '&$checked': {
            color: primaryColor[0],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function CustomCheckbox(props) {
    return (
        <FormControlLabel
            control={<ColorCheckbox checked={props.value} onChange={props.onChange} name={props.name} />}
            label={props.label}
        />
    );
}