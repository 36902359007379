import React from "react";
import CreatableSelect from 'react-select/creatable';
import {getNonceProvider} from "../../utils/helpersFunctions";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";



const cache = createCache({
    key: 'react-select-creatable',
    nonce: getNonceProvider()
});


function CustomCreatableSelect({ ...props }) {
    const { ...rest} = props;
    return (
        <CacheProvider value={cache} >
            <CreatableSelect{...rest}/>
        </CacheProvider>
    );
}

export default CustomCreatableSelect;

