import {BASE_URL_CORE, COMMERCE_ANALYTICS, HOME_FEED_ANALYTICS} from "../variables/apiConstants";
import {getService, postService} from "./api";


export function getCommerceAnalytics(parameters) {
    let url = BASE_URL_CORE + COMMERCE_ANALYTICS;

    return postService(url, parameters);
}

export function getHomeFeedAnalytics(parameters) {
    let url = BASE_URL_CORE + HOME_FEED_ANALYTICS;

    return postService(url, parameters);
}