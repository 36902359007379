import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
//import Table from "../../components/Table/Table.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Status from "../../components/Status/Status";
import {
    statusColors,
    statusText,
    formTitles,
} from "../../utils/statusHelpers";
import TableActions from "../../components/TableActions/TableActions";
import { withRouter } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import Loading from "../../components/Loading/Loading";
import PriceBookForm from "./components/PriceBookForm";
import priceBookDetails from "../../models/PriceBookModel";
import { getPriceBoooks } from "../../services/priceBookService";
import TablePagination from "@material-ui/core/TablePagination";
import {
    APP_COMMERCE,
    PRICEBOOK_TABLE_ACTIONS,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS,
} from "../../variables/coreConstants";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import PrivateComponent from "../../components/PrivateComponent/PrivateComponent";
import { nullController } from "../../utils/helpersFunctions";

class PriceBooks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openPriceBook: false,
            isLoading: true,
            actionType: 0,
            priceBooksData: [],
            filteredPriceBooks: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        priceBookDetails.cleanPriceBookDetails();
        this.setState({ openPriceBook: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openPriceBook: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        priceBookDetails.id = formData.id;
        priceBookDetails.name = formData.name;
        priceBookDetails.description = formData.description;
        this.setState({ openPriceBook: true });
        this.setState({ actionType: action });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });
        getPriceBoooks()
            .then((res) => {
                this.setState({ priceBooksData: res.data });
                this.setState({ filteredPriceBooks: res.data });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    // Method to search data
    searchPriceBook = (e) => {
        let newData = this.state.priceBooksData;
        try {
            newData = newData.filter(function(item) {
                return (
                    item.name
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1
                );
            });
            this.setState({ filteredPriceBooks: newData });
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Input
                                onChange={this.searchPriceBook}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon
                                            className={classes.objectColor}
                                        />
                                    </InputAdornment>
                                }
                                placeholder="Search price books..."
                            />
                        </GridItem>
                        <PrivateComponent appType={APP_COMMERCE}>
                            <GridItem xs={6} sm={6} md={6}>
                                <div className={classes.rightPaper}>
                                    <Button
                                        color="primary"
                                        disabled={true}
                                        onClick={this.handleOpenForm}
                                    >
                                        Add New Price Books
                                    </Button>
                                </div>
                            </GridItem>
                        </PrivateComponent>
                    </GridContainer>
                    {this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <Card>
                            <CardBody>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead
                                            className={classes.objectColor}
                                        >
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Description
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Default
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredPriceBooks
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage +
                                                        rowsPerPage
                                                )
                                                .map((val, key) => {
                                                    return (
                                                        <TableRow
                                                            key={key}
                                                            hover={true}
                                                        >
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell +
                                                                    " " +
                                                                    classes.hoverRow
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "name"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell +
                                                                    " " +
                                                                    classes.hoverRow
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "description"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                <Status
                                                                    className={
                                                                        classes.status
                                                                    }
                                                                    color={
                                                                        statusColors[
                                                                            val
                                                                                .status
                                                                        ]
                                                                    }
                                                                    size="lg"
                                                                    label={t(
                                                                        statusText[
                                                                            val
                                                                                .status
                                                                        ]
                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell +
                                                                    " " +
                                                                    classes.hoverRow
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "isStandard"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {
                                                                    <TableActions
                                                                        rowData={
                                                                            val
                                                                        }
                                                                        openForm={
                                                                            this
                                                                                .openForm
                                                                        }
                                                                        options={
                                                                            PRICEBOOK_TABLE_ACTIONS
                                                                        }
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredPriceBooks.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page",
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page",
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={
                                        this.handleChangeRowsPerPage
                                    }
                                />
                            </CardBody>
                        </Card>
                    )}
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openPriceBook}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} PriceBook
                            <Muted>Set of prices for each product.</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <PriceBookForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }
}

PriceBooks.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(PriceBooks))
);
