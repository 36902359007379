import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/core/icons icons
import DescriptionIcon from "@material-ui/icons/ShortText";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Button from "../../../components/CustomButtons/Button";
import {
    disableComponent,
    enableComponent,
    formatDate,
    getCompanyCurrency, getValueFromCurrencyMask,
    initialMomentDate,
    isSuccessfulCreate,
    isSuccessfulRequest, setCurrencyValue,
} from "../../../utils/helpersFunctions";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {
    NOTIFICATION_DURATION
} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Datetime from "react-datetime";
import CurrencyInput from "../../../components/CustomInput/CurrencyInput";
import paymentTemplateDetails from "../../../models/PaymentTemplateModel";
import {addPaymentTemplateItem, editPaymentTemplateItem} from "../../../services/paymentTemplateService";


class PaymentTemplateItemForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            name: paymentTemplateDetails.templateItemDetails.name,
            description: paymentTemplateDetails.templateItemDetails.description,
            amountMask: setCurrencyValue(paymentTemplateDetails.templateItemDetails.amount),
            amount: paymentTemplateDetails.templateItemDetails.amount,
            surcharge: paymentTemplateDetails.templateItemDetails.surcharge,
            surchargeMask: setCurrencyValue(paymentTemplateDetails.templateItemDetails.surcharge),
            discountMask: setCurrencyValue(paymentTemplateDetails.templateItemDetails.discount),
            discount: paymentTemplateDetails.templateItemDetails.discount,
            expirationDate: paymentTemplateDetails.templateItemDetails.expirationDate,
            tax: paymentTemplateDetails.templateItemDetails.tax,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveTemplateItem = this.saveTemplateItem.bind(this);
        this.updateTemplateItem = this.updateTemplateItem.bind(this);
    }


    componentDidMount() {}


    cleanData() {
        this.setState({
            name: "",
            description: "",
            amountMask: setCurrencyValue(0.00),
            amount: 0,
            surcharge: 0,
            discount: 0,
            discountMask: setCurrencyValue(0.00),
            surchargeMask: setCurrencyValue(0.00),
            expirationDate: initialMomentDate(),
            tax: 0,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh) {
                    this.props.closeForm("openItemForm", false, refresh)
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };


    handleDateChange = (date) => {
        this.setState({ expirationDate: date });
    };


    saveTemplateItem() {
        const { t, templateId } = this.props;
        const { name, description, amount, expirationDate, discount, tax, surcharge } = this.state;

        if (name === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (description.length <= 255) {
                if (amount <= 0 || discount < 0 || tax < 0 || surcharge < 0) {
                    this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    let dataParameters = {
                        "templateId": templateId,
                        "templateItemId": "",
                        "name": name,
                        "description": description,
                        "tax": tax,
                        "unitPrice": amount,
                        "discount": discount,
                        "surcharge": surcharge,
                        "expirationDate": formatDate(new Date(expirationDate.valueOf())),
                    };

                    disableComponent(this, "disableField");
                    addPaymentTemplateItem(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();
                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch((e) => {
                            enableComponent(this, "disableField");
                            if (e.response) {
                                this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                                validateSession(e.response, this.props.history);
                            } else {
                                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                            }
                        });
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                    "danger", NotificationErrorIcon, null);
            }
        }
    }


    updateTemplateItem() {
        const { t, templateId } = this.props;
        const { name, description, amount, expirationDate, discount, tax, surcharge } = this.state;

        if (name === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (description.length <= 255) {
                if (amount <= 0 || discount < 0 || tax < 0 || surcharge < 0) {
                    this.showNotification(t('INVALID_NUMBERS_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    let dataParameters = {
                        "templateId": templateId,
                        "templateItemId": paymentTemplateDetails.templateItemDetails.uuid,
                        "name": name,
                        "description": description,
                        "tax": tax,
                        "unitPrice": amount,
                        "discount": discount,
                        "surcharge": surcharge,
                        "expirationDate": formatDate(new Date(expirationDate.valueOf())),
                    };


                    disableComponent(this, "disableField");
                    editPaymentTemplateItem(dataParameters)
                        .then((response) => {
                            if (isSuccessfulRequest(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();
                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch((e) => {
                            enableComponent(this, "disableField");
                            if (e.response) {
                                this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                                validateSession(e.response, this.props.history);
                            } else {
                                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                            }
                        });
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'),
                    "danger", NotificationErrorIcon, null);
            }
        }
    }



    render() {
        const { classes, t } = this.props;

        const SaveButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveTemplateItem()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        const UpdateButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateTemplateItem()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('EXPIRATION_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.expirationDate}
                                onChange={this.handleDateChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.amountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('TH_AMOUNT')}
                            id="amountMask"
                            name="amount"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.discountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('DISCOUNT_TEXT')}
                            id="discountMask"
                            name="discount"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.surchargeMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('SURCHARGE_TEXT')}
                            id="surchargeMask"
                            name="surcharge"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openItemForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateButton/> : <SaveButton/>}
                        </div>
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

PaymentTemplateItemForm.propTypes = {
    classes: PropTypes.object,
    templateId: PropTypes.string.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(PaymentTemplateItemForm)));