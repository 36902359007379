import React from "react";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";

// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";

import Button from "../../../components/CustomButtons/Button";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    DEFAULT_TAX,
    ERROR_REQUEST_MESSAGE,
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import ProductIcon from "@material-ui/icons/ShoppingCart";
import Muted from "../../../components/Typography/Muted";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import {TextField} from "@material-ui/core";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import SimpleButton from "@material-ui/core/Button";
import {addProduct} from "../../../services/productService";
import {validateSession} from "../../../services/api";
import productDetails from "../../../models/ProductModel";
import {
    disableComponent,
    enableComponent, getCompanyCurrency, getRoundNumber, getValueFromCurrencyMask,
    isSuccessfulCreate,
    selectValidation, setCurrencyValue
} from "../../../utils/helpersFunctions";
import CreatableSelect from "../../../components/CustomSelect/CustomCreatableSelect";
import modifierDetails from "../../../models/ModifierModel";
import {
    addModifierGroup, editModifierGroup,
    getAllModifierItemsByGroup
} from "../../../services/modifierGroupService";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import ModifierItems from "../../../components/ModifierGroup/ModifierItems";
import {withTranslation} from "react-i18next";
// import PriceIcon from "@material-ui/icons/AttachMoney";



class ModifierForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            id: modifierDetails.id,
            modifierName: modifierDetails.name,
            modifierExternalId: modifierDetails.externalId,
            maxModifier: modifierDetails.maxItems,
            isRequired: modifierDetails.required,
            modifiersOptions: [],
            item: '',
            oldItems: '',
            surchargeMask: setCurrencyValue(0.00),
            surcharge: 0,
            itemsOptions: [],
            itemsGroup: modifierDetails.modifierItems,
            oldItemsGroup: [],
            itemSurchargeMask: setCurrencyValue(0.00),
            itemSurcharge: 0,
            itemName: '',
            itemExternalId: '',
            loadingItem: true,
            disabledItem: false,
            disableField: false,
            isLoading: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
        this.request = this.request.bind(this);
    }


    componentDidMount() {
        this.request();
    }

    cleanData() {
        this.setState({
            modifierName: '',
            modifierExternalId: '',
            isRequired: false,
            maxModifier: 0,
            itemsGroup: []
        });
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };


    changeRequired = () => {
        this.setState(prevState => ({
            isRequired: !prevState.isRequired
        }));
    };

    changeItemSelect = (item) => {
        this.setState({ item });
        ///this.setState({ item });
    };


    handleCreateItem = (item) => {
        if (item === "" || this.state.surcharge < 0 ) {
            this.showNotification(this.props.t('INVALID_INFORMATION_MSG'), "danger", NotificationErrorIcon,  null);

        } else {
            let dataParameters = {
                "name": item,
                "categoryId": null,
                "description": "",
                "code": "",
                "productImage": null,
                "price": this.state.surcharge,
                "currency": null,
                "productType": 1,
                "isModifierItem": true,
                "consumerMinimumLegalAge": 0,
                "tax": DEFAULT_TAX,
                "catalogs": "[]",
                "modifiersGroups": "[]"
            };

            this.setState({ loadingItem: true });
            addProduct(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        let data = response.data.entity;
                        let item = { value: data.id, label: data.name };

                        this.setState({
                            loadingItem: false,
                            itemsOptions: [...this.state.itemsOptions, item],
                            item: item,
                        });
                    } else {
                        this.setState({ loadingItem: false });
                        this.showNotification(response.message, "danger", NotificationFailedIcon, null);
                    }
                })
                .catch((e) => {
                    this.setState({ loadingItem: false });
                    if(e.response) {
                        this.showNotification(e.response.message, "danger", NotificationFailedIcon, null);
                        validateSession(e.response, this.props.history);
                    } else {
                        this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                    }
                });
        }
};


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh)
                    this.props.modifierForm(false);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };



    addItem = () => {
        let array = {id: 0, modifierItem: {id: 0, externalId: "", name: "", surcharge: 0.00, isEditing: false, isUpdated: false}};

        if (selectValidation(this.state.item) || this.state.surcharge < 0) {
            this.showNotification(this.props.t('ITEM_DATA_INVALID_MSG'), "danger", NotificationErrorIcon);

        } else {

            let exist = false;
            if (modifierDetails.modifierItems.length > 0) {
                let result = modifierDetails.modifierItems.find(({ modifierItem }) => modifierItem ? modifierItem.id === this.state.item.value : console.log("Nothing"));
                if (result)
                    exist = true;
            }

            if (!exist) {
                array.id = null;
                array.modifierItem.id = this.state.item.value;
                array.modifierItem.name = this.state.item.label;
                array.modifierItem.externalId = this.state.itemExternalId;
                array.modifierItem.surcharge = this.state.surcharge;
                modifierDetails.modifierItems.push(array);

                this.setState({
                    item: '',
                    itemExternalId: '',
                    surchargeMask: setCurrencyValue(0.00),
                    surcharge: 0.00,
                    itemsGroup: modifierDetails.modifierItems
                });

            } else {
                this.showNotification(this.props.t('ITEM_ALREADY_ADDED_MSG'), "danger", NotificationErrorIcon);
            }
        }
    };


    addModifier = () => {
        let array = {
            id: 0,
            name: "",
            required: false,
            maxItems: 0,
            modifierItems: [],
        };

        if (this.state.modifierName === "" || this.state.maxModifier <= 0 || modifierDetails.modifierItems.length <= 0) {
             this.showNotification(this.props.t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon);

        } else {
            array.name = this.state.modifierName;
            array.externalId = this.state.modifierExternalId;
            array.required = this.state.isRequired;
            array.maxItems = this.state.maxModifier;
            array.modifierItems.push(this.state.itemsGroup);


            let modifierItems;
            if (selectValidation(this.state.itemsGroup)) {
                modifierItems = [];
            } else {
                modifierItems = this.state.itemsGroup.map( item => {
                    return { id: item.modifierItem.id, externalId: item.modifierItem.externalId, fee: item.modifierItem.surcharge}
                });
            }

            let dataParameters = {
                "name": this.state.modifierName,
                "externalId": this.state.modifierExternalId,
                "maxItems": this.state.maxModifier,
                "isRequired": this.state.isRequired,
                "modifierItems": JSON.stringify(modifierItems)
            };

            disableComponent(this, "disabledField");
            addModifierGroup(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        let data = response.data.entity;
                        let item = { id: null, modifierGroup: {id: data.id, name: data.name }};
                        productDetails.modifiers.push(item);

                        // console.log(productDetails.modifiers);
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch((e) => {
                    enableComponent(this, "disableField");
                    if (e.response) {
                        this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(e.response, this.props.history);
                    } else {
                        this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                    }
                });
        }
    };


    updateModifier = () => {
        if (this.state.modifierName === "" || this.state.maxModifier <= 0 || modifierDetails.modifierItems.length <= 0) {
            this.showNotification(this.props.t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon);

        } else {

            let index = productDetails.modifiers.findIndex(
                val => val.modifierGroup.id === modifierDetails.id);

            let modifierItems;
            if (selectValidation(this.state.itemsGroup)) {
                modifierItems = [];
            } else {
                modifierItems = this.state.itemsGroup.map( item => {
                    return {
                        id: item.id,
                        product: item.modifierItem.id,
                        externalId: item.modifierItem.isUpdated ? item.modifierItem.externalId : null,
                        fee: parseFloat(item.modifierItem.surcharge),
                        itemName: item.modifierItem.isUpdated ? item.modifierItem.name : null
                    }
                });
            }

            let dataParameters = {
                "name": this.state.modifierName,
                "externalId": this.state.modifierExternalId,
                "isRequired": this.state.isRequired,
                "maxItems": this.state.maxModifier,
                "modifierItems": JSON.stringify(modifierItems),
                "oldModifierItems": JSON.stringify(this.state.oldItemsGroup),
            };

            disableComponent(this, "disableField");
            editModifierGroup(dataParameters)
                .then((response) => {
                    let data = response.data.entity;

                    productDetails.modifiers[index].modifierGroup.name = data.name;
                    this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                })
                .catch((e) => {
                    enableComponent(this, "disableField");
                    if (e.response) {
                        this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(e.response, this.props.history);
                    } else {
                        this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                    }
                });
        }
    };


    request() {
        getAllModifierItemsByGroup()
            .then((response) => {
                let values = response.data.unassignedModifierItems.map( item => {
                    return { value: item.id , label: item.name }
                });

                if (this.props.action) {
                    let assigned = response.data.assignedModifierItems.map( item => {
                        return {
                            id: item.id,
                            modifierItem: { id: item.productId.id, name: item.productId.name, externalId: item.externalId, surcharge: getRoundNumber(item.fee), isEditing: false, isUpdated: false }}
                    });
                    let old = response.data.assignedModifierItems.map( item => {
                        return {
                            id: item.id,
                            product: item.productId.id,
                            fee: getRoundNumber(item.fee),
                            externalId: item.externalId,
                            itemName: null
                        }
                    });

                    modifierDetails.modifierItems = assigned;
                    this.setState({
                        itemsGroup: assigned,
                        oldItemsGroup: old,
                        itemsOptions: values,
                        loadingItem: false
                    });
                } else {
                    this.setState({
                        itemsOptions: values,
                        loadingItem: false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({ loadingItem: false });

                if (e.response)
                    validateSession(e.response, this.props.history);
            });
    }


    render() {
        const { classes, t } = this.props;

        const AddModifier = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem || this.state.loadingItem}
                onClick={() => this.addModifier()}
            >
                {t('BTN_CONFIRM')}
            </Button>
        );

        const EditModifier = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem || this.state.loadingItem}
                onClick={() => this.updateModifier()}
            >
                {t('BTN_CONFIRM_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.scheduleContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    value={this.state.modifierName}
                                    onChange={this.handleChangeValues}
                                    labelText={t('NAME_TEXT')}
                                    id="modifierName"
                                    name="modifierName"
                                    autoFocus={true}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <ProductIcon className={classes.colorIcon}/>
                                        </InputAdornment>
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    value={this.state.modifierExternalId}
                                    onChange={this.handleChangeValues}
                                    labelText={t('EXTERNAL_ID_TEXT')}
                                    id="modifierExternalId"
                                    name="modifierExternalId"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Muted>{t('HOW_ITEMS_CAN_SELECT_TEXT')}</Muted>
                                <GridContainer>
                                    <GridItem xs={8} sm={4} md={2}>
                                        <TextField
                                            style={{marginBottom: 30}}
                                            variant="outlined"
                                            value={this.state.maxModifier}
                                            onChange={this.handleChangeValues}
                                            id="maxModifier"
                                            name="maxModifier"
                                            type="number"
                                            margin="dense"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Muted>{t('IS_REQUIRED_TEXT')}</Muted>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={4}>
                                        <FormControlLabel
                                            style={{marginBottom: 30}}
                                            className={classes.leftPaper}
                                            control={
                                                <CustomSwitch
                                                    className={classes.switchBase}
                                                    size="small"
                                                    checked={this.state.isRequired}
                                                    onChange={this.changeRequired}
                                                />
                                            }
                                            label=""
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <Muted>{t('TH_ITEM')}</Muted>
                                        <div className={classes.itemSelect}>
                                            <CreatableSelect
                                                isClearable
                                                isDisabled={this.state.loadingItem || this.state.disabledItem}
                                                isLoading={this.state.loadingItem}
                                                placeholder={t('SELECT_TEXT')}
                                                onChange={this.changeItemSelect}
                                                onCreateOption={this.handleCreateItem}
                                                closeMenuOnSelect={true}
                                                maxMenuHeight={130}
                                                options={this.state.itemsOptions}
                                                value={this.state.item}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <Muted>{t('EXTERNAL_ID_TEXT')}</Muted>
                                        <TextField
                                            value={this.state.itemExternalId}
                                            id="itemExternalId"
                                            name="itemExternalId"
                                            disabled={this.state.disabledItem}
                                            onChange={this.handleChangeValues}
                                            className={classes.itemIdContainer}
                                            margin="dense"
                                            variant="outlined"
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <Muted>{t('SURCHARGE_TEXT')}</Muted>
                                        <TextField
                                            value={this.state.surchargeMask}
                                            id="surchargeMask"
                                            name="surcharge"
                                            disabled={this.state.disabledItem}
                                            onChange={this.handleCurrencyValue}
                                            className={classes.surchargeContainer}
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {getCompanyCurrency()}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <SimpleButton
                                            size="small"
                                            disabled={this.state.disabledItem}
                                            style={{width: 25, marginLeft: 5, marginTop: 10}}
                                            onClick={() => this.addItem()}
                                            color="primary">
                                            {t('BTN_ADD')}
                                        </SimpleButton>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.itemsGroupContainer}>
                            <ModifierItems items={this.state.itemsGroup} component={this}/>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{marginTop: 15}}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.modifierForm(false)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                {this.props.action ? <EditModifier/> : <AddModifier />}
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ModifierForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ModifierForm)));
