import React, {useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
import {saveData} from "../../../utils/helpersFunctions";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


function DefaultingMembers({ ...props }) {
    const { classes, data, onRequest } = props;
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const lastPage = data.totalPages;
    const content = data.content ? data.content : [];

    const {t} = useTranslation();


    const handleChangePage = (newPage) => {
        setPage(newPage);
        let dataParameters = { "page": newPage, "pageSize": rowsPerPage };
        onRequest(dataParameters);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
        let dataParameters = { "page": page, "pageSize": event.target.value };
        onRequest(dataParameters);
    };

    const handleLoadMore = () => {
        handleChangePage(page + 1);
        setCount(count + 1);
    };

    const handleGoToDetails = (values) => {
        saveData("member", values);
        props.history.push("member-details/" + values.uuid);
    };


    return (
        <div>
            <GridContainer>
                {/*<Backdrop className={classes.backdrop} open={isLoading} >*/}
                {/*    <CircularProgress color="inherit" />*/}
                {/*</Backdrop>*/}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'defaulting_members'}
                        title={t('DEFAULTING_MEMBERS')}
                        exportFileName={t('DEFAULTING_MEMBERS')}
                        handleLoadMore={handleLoadMore}
                        // isLoading={isLoading}
                        data={data}
                        actions={[]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: handleGoToDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_EMAIL'), name: "email", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_EXTERNAL_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('PHONE_NUMBER_TEXT'), name: "phone", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DEFAULTED_AMOUNT'), name: "defaultedAmount", isReadOnly: true, width: "*", isCustomCell: false },
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data;
}

DefaultingMembers.defaultProps = {
    data: {
        content: [],
        totalPages: 0
    }
};

DefaultingMembers.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequest: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default withRouter(React.memo(withStyles(tableViewStyle)(DefaultingMembers), propsAreEqual));
