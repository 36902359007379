var dashboardUserDetails = {
    uuid: "",
    email: "",
    role: "",
    fullName: "",
    firstName: "",
    lastName: "",
    status: null,
    permissions: [],
    permissionDetails: {
        uuid: "",
        name: "",
        description: "",
    },
    notifications: [],

    setUserDetail : function (values) {
        try {
            this.uuid = values.uuid;
            this.email = values.email;
            this.firstName = values.firstName;
            this.lastName = values.lastName;
            this.status = values.status;

            let assigned = [];
            assigned = values.permissions.map(val => {
                return { value: val.uuid, label: val.name }
            });

            let notificationList = [];
            notificationList = values.notifications.map(val => {
                return { value: val.id, label: val.name }
            });

            this.permissions = assigned;
            this.notifications = notificationList;
        } catch (e) {}

    },

    getFullName : function (fName, lName) {
        return fName + " " + lName;
    },

    cleanUserDetails : function () {
        this.uuid = "";
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.notificationType = [];
        this.permissions = [];
        this.status = null;
    },

    setPermissionDetail : function (values) {
        try {
            this.uuid = values.uuid;
            this.name = values.name;
            this.description = values.description;
            this.status = values.status;

        } catch (e) {}

    },

    cleanPermissionDetails : function () {
        this.uuid = "";
        this.name = "";
        this.description = "";
        this.status = null;
    },
};

export default dashboardUserDetails;