import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import signInStyle from "../../assets/jss/custom-components/signInStyle";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {
    IMG_GINIH_LOGO,
    IMG_WELCOME,
} from "../../variables/resourcesConstants";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Snackbar from "../../components/Snackbar/Snackbar";
import { NOTIFICATION_DURATION } from "../../variables/coreConstants";
import Tooltip from "@material-ui/core/Tooltip";
import {
    isSuccessfulRequest,
    validatePassword,
} from "../../utils/helpersFunctions";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {
    requestVerificationPassword,
    resetPassword,
} from "../../services/accountService";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

export default function ResetPassword(props) {
    const classes = signInStyle();
    const _code = props.match.params.code;
    const _referenceId = props.match.params.referenceId;

    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [code, setCode] = React.useState("");
    const [referenceId, setReferenceId] = React.useState("");
    const [values, setValues] = React.useState({
        newPassword: "",
        confirmPassword: "",
        showPassword: false,
        passwordError: true,
        color: "primary",
        icon: NotificationSuccessIcon,
        message: t("SOMETHING_WENT_WRONG"),
    });

    useEffect(() => {
        submitVerificationRequest(_code, _referenceId);
    }, [_code, _referenceId]);

    function showNotification(msj, color, icon, request) {
        setValues({ ...values, message: msj, color: color, icon: icon });
        setOpen(true);
        window.setTimeout(function() {
            setOpen(false);
            if (request) {
                props.history.push("/login");
            }
        }, NOTIFICATION_DURATION);
    }

    function hideNotification(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleFieldPassword = () => (e) => {
        const value = e.target.value;

        setValues({
            ...values,
            newPassword: value,
            passwordError: !validatePassword(value),
        });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const onResetPassword = () => {
        if (values.newPassword === "" || values.confirmPassword === "") {
            showNotification(
                t("FIELDS_EMPTY"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else if (values.newPassword !== values.confirmPassword) {
            showNotification(
                t("PASSWORD_ERROR_MESSAGE"),
                "danger",
                NotificationErrorIcon
            );
        } else {
            if (validateReferenceCode(code, referenceId)) {
                showNotification(
                    t("INVALID_REQUEST"),
                    "danger",
                    NotificationErrorIcon,
                    null
                );
            } else {
                setLoading(true);
                let dataParameters = {
                    secret_code: code,
                    reference_id: referenceId,
                    detail_value: values.newPassword,
                };

                resetPassword(dataParameters)
                    .then((response) => {
                        setIsLoading(false);
                        if (isSuccessfulRequest(response)) {
                            showNotification(
                                response.data.message,
                                "primary",
                                NotificationSuccessIcon,
                                true
                            );
                        } else {
                            showNotification(
                                response.data.message,
                                "danger",
                                NotificationErrorIcon,
                                null
                            );
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (error.response) {
                            showNotification(
                                error.response.data.message,
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                        }
                    });
            }
        }
    };

    const submitVerificationRequest = (_code, _referenceId) => {
        if (validateReferenceCode(_code, _referenceId)) {
            showNotification(
                t("INVALID_REQUEST"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            setCode(_code);
            setReferenceId(_referenceId);
            setIsLoading(true);

            let dataParameters = {
                secret_code: _code,
                reference_id: _referenceId,
            };

            requestVerificationPassword(dataParameters)
                .then((response) => {
                    setIsLoading(false);
                    if (isSuccessfulRequest(response)) {
                        showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            null
                        );
                    } else {
                        showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            null
                        );
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response) {
                        showNotification(
                            t("VERIFICATION_LINK_INVALID"),
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                });
        }
    };

    const validateReferenceCode = (_code, _referenceId) => {
        return (
            !_code ||
            _code === "" ||
            _code === null ||
            !_referenceId ||
            _referenceId === "" ||
            _referenceId === null ||
            _code.length !== 5
        );
    };

    //Set page title
    document.title = document.title
        ? document.title.split("-")[0].trim() + " - " + t("RESET_PASSWORD_TEXT")
        : t("RESET_PASSWORD_TEXT");

    return (
        <div className={classes.container}>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <GridContainer style={{ height: "100%" }}>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.welcomeWrapper}>
                        <div className={classes.welcomeMessage}>
                            <img
                                className={classes.image}
                                src={IMG_WELCOME}
                                alt={"Login"}
                            />
                            <Typography component="h3" variant="h3">
                                {t("WELCOME_TITLE")}
                            </Typography>
                            <Typography component="h3" variant="subtitle1">
                                {t("WELCOME_MSG")}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <img
                                className={classes.logoContainer}
                                src={IMG_GINIH_LOGO}
                                alt={"logo"}
                            />
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                align="center"
                            >
                                {t("ENTER_NEW_PASSWORD")}
                            </Typography>
                            <form className={classes.form}>
                                <Tooltip
                                    disableHoverListener
                                    disableTouchListener
                                    title={t("PASSWORD_WEAK_MESSAGE")}
                                    placement={"bottom"}
                                >
                                    <TextField
                                        className={
                                            values.passwordError
                                                ? classes.textFieldError
                                                : classes.textFieldSuccess
                                        }
                                        id="newPassword"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        type={
                                            values.showPassword
                                                ? "text"
                                                : "password"
                                        }
                                        label={t("NEW_PASSWORD_TEXT")}
                                        value={values.newPassword}
                                        onChange={handleFieldPassword()}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                    >
                                                        {values.showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="current-password"
                                    />
                                </Tooltip>
                                <TextField
                                    className={classes.textFieldPrimary}
                                    id="confirmPassword"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type={
                                        values.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    label={t("CONFIRM_PASSWORD_TEXT")}
                                    value={values.confirmPassword}
                                    onChange={handleChange("confirmPassword")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    autoComplete="current-password"
                                />
                                <Grid container>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <NavLink
                                            to="/sign-in"
                                            style={{
                                                color: primaryColor[0],
                                                textDecoration: "none",
                                            }}
                                            activeStyle={{
                                                color: primaryColor[0],
                                                textDecoration: "none",
                                            }}
                                        >
                                            {t("GO_TO_LOGIN_TEXT")}
                                        </NavLink>
                                    </Grid>
                                </Grid>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={loading || values.passwordError}
                                    className={classes.submit}
                                    onClick={() => onResetPassword()}
                                >
                                    {t("BTN_SUBMIT")}
                                </Button>
                            </form>
                        </div>
                        <Box mt={6}>
                            <GridItem xs={12}>
                                <div className={classes.root}>
                                    <div className={classes.placeholder}>
                                        <Fade
                                            in={loading}
                                            style={{
                                                transitionDelay: loading
                                                    ? "600ms"
                                                    : "0ms",
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress
                                                style={{
                                                    color: primaryColor[0],
                                                }}
                                            />
                                        </Fade>
                                    </div>
                                </div>
                            </GridItem>
                        </Box>
                        <Snackbar
                            place="tr"
                            color={values.color}
                            icon={values.icon}
                            message={values.message}
                            open={open}
                            closeNotification={hideNotification}
                            close
                        />
                    </Container>
                </GridItem>
            </GridContainer>
        </div>
    );
}
