
var roleDetails = {
    id: null,
    name: "",
    status: "",
    application: [],


    cleanRoleDetails : function () {
        this.id = null;
        this.name = "";
        this.status = "";
        this.application = [];
    },
};

export default roleDetails;