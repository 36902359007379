import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import {
    convertDateString,
    getData,
    getUser,
    getValueFromCurrencyMask,
    isSuccessfulCreate,
    isSuccessfulRequest,
    removeData,
    selectValidation,
    setCurrencyValue,
    validateFields,
} from "../../../../utils/helpersFunctions";

import { withTranslation } from "react-i18next";
import {
    ACTION_NEW,
    ACTION_UPDATE,
    NOTIFICATION_DURATION,
    STATUS_TRANSFERRED,
} from "../../../../variables/coreConstants";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/CustomButtons/Button";
import SimpleButton from "@material-ui/core/Button";
import CashAccountFormDetails from "./CashAccountFormDetails";
import CashAccountFormAgreement from "./CashAccountFormAgreement";
import CashAccountFormReview from "./CashAccountFormReview";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
import {
    getBankProvidersAvailable,
    getCurrenciesAvailable,
} from "../../../../services/transferService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import { validateSession } from "../../../../services/api";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {
    addCashAccount,
    cancelWalletAgreement,
    completeCashAccount,
    getBankAccountTypes,
    getWalletAgreement,
} from "../../../../services/cashAccountService";

const OBJECT_NAMES = require("../../../../variables/objectConstants");

class CashAccountFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            stepTotal: 4,
            isLoading: false,
            name: "",
            account: "",
            provider: "",
            currency: "",
            agreement: "",
            createdDate: null,
            cashAccountId: "",
            alias: "",
            accountType: "",
            providerOptions: [],
            currencyOptions: [],
            typeOptions: [],
            depositedAmount1Mask: setCurrencyValue(0.0),
            depositedAmount2Mask: setCurrencyValue(0.0),
            depositedAmount1: 0.0,
            depositedAmount2: 0.0,
            disableField: false,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
        };
    }

    componentDidMount() {
        const { match } = this.props;
        if (this.hasPaymentPermissionPermission()) {
            let pendingConfirm = getData(OBJECT_NAMES.CASH_ACCOUNT_PENDING);
            if (
                pendingConfirm !== null &&
                pendingConfirm !== undefined &&
                pendingConfirm.status === STATUS_TRANSFERRED &&
                match.params.action === ACTION_UPDATE
            ) {
                this.setState({
                    cashAccountId: pendingConfirm.uuid,
                    name: pendingConfirm.name,
                    alias: pendingConfirm.alias,
                    createdDate: pendingConfirm.createdDate,
                    account: pendingConfirm.accountNumber,
                    agreement: pendingConfirm.agreementReference,
                    provider: {
                        value: pendingConfirm.bankProvider.id,
                        label: pendingConfirm.bankProvider.name,
                    },
                    currency: {
                        value: pendingConfirm.currency.id,
                        label: pendingConfirm.currency.symbol,
                    },
                    step: 3,
                });
            } else {
                if (this.props.match.params.action === ACTION_UPDATE) {
                    removeData(OBJECT_NAMES.CASH_ACCOUNT_PENDING);
                    this.props.history.replace("/admin/cash-account-form/new");
                }

                this.setState({ isLoading: true }, () => {
                    this.requestProviders();
                });
            }
        } else {
            this.props.history.push("/admin/not-found");
        }
    }

    cleanData = () => {
        this.setState({
            depositedAmount1Mask: setCurrencyValue(0.0),
            depositedAmount2Mask: setCurrencyValue(0.0),
            depositedAmount1: 0.0,
            depositedAmount2: 0.0,
            name: "",
            createdDate: null,
            account: "",
            provider: "",
            currency: "",
            agreement: "",
            cashAccountId: "",
            alias: "",
            accountType: "",
        });
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    requestProviders() {
        const { t, history } = this.props;
        getBankProvidersAvailable()
            .then((response) => {
                let values = response.data.map((item) => {
                    return { value: item.id, label: item.name };
                });

                this.setState({
                    providerOptions: values,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, history);
                } else {
                    this.showNotification(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });

        getCurrenciesAvailable()
            .then((response) => {
                let values = response.data.map((item) => {
                    return { value: item.id, label: item.symbol };
                });

                this.setState({ currencyOptions: values });
            })
            .catch((error) => {
                if (error.response) {
                    validateSession(error.response, history);
                }
            });

        getBankAccountTypes()
            .then((response) => {
                let values = response.data.map((item) => {
                    return { value: item.id, label: item.name };
                });

                this.setState({ typeOptions: values, accountType: values[0] });
            })
            .catch((error) => {
                if (error.response) {
                    validateSession(error.response, history);
                }
            });
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value,
        });
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask =
                        value.length <= 11
                            ? setCurrencyValue(value)
                            : setCurrencyValue(0);

                    this.setState({
                        [name]:
                            value.length <= 11
                                ? getValueFromCurrencyMask(mask)
                                : 0.0,
                        [id]: mask,
                    });
                }
            } else {
                this.setState({
                    [name]: 0.0,
                    [id]: setCurrencyValue(0),
                });
            }
        }
    };

    changeProviderSelect = (provider) => {
        this.setState({ provider });
    };
    changeCurrencySelect = (currency) => {
        this.setState({ currency });
    };
    changeTypeSelect = (accountType) => {
        this.setState({ accountType });
    };

    handleNext = () => {
        const { step, stepTotal } = this.state;

        switch (step) {
            case 1:
                this.handleValidationForm();
                break;
            case 2:
                this.createCashAccount();
                break;
            case 3:
                this.completeAccount();
                break;
            case stepTotal:
                this.handleCancel();
                break;
            default:
                return true;
        }
    };

    createCashAccount = () => {
        const { t, history } = this.props;
        let fields = [
            this.state.name,
            this.state.alias,
            this.state.account,
            this.state.agreement,
        ];

        if (
            !validateFields(fields) ||
            selectValidation(this.state.provider) ||
            selectValidation(this.state.currency) ||
            selectValidation(this.state.accountType)
        ) {
            this.showNotification(
                t("CASH_ACCOUNT_FIELDS_REQUIRED_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            if (validateFields([this.state.cashAccountId])) {
                this.setState({ step: this.state.step + 1 });
            } else {
                let dataParameters = {
                    beneficiaryName: this.state.name,
                    alias: this.state.alias,
                    accountNumber: this.state.account,
                    provider: this.state.provider.value,
                    currencyId: this.state.currency.value,
                    accountTypeId: this.state.accountType.value,
                    agreementId: this.state.agreement,
                };

                this.setState({ isLoading: true, disableField: true });
                addCashAccount(dataParameters)
                    .then((response) => {
                        if (isSuccessfulCreate(response)) {
                            this.setState({
                                step: this.state.step + 1,
                                isLoading: false,
                                disableField: false,
                                cashAccountId: response.data.entity,
                            });
                        } else {
                            this.setState({
                                isLoading: false,
                                disableField: false,
                            });
                            this.showNotification(
                                response.data.message,
                                "danger",
                                NotificationErrorIcon,
                                null
                            );
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            disableField: false,
                        });
                        if (error.response) {
                            this.showNotification(
                                error.response.data.message,
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                            validateSession(error.response, history);
                        } else {
                            this.showNotification(
                                t("SOMETHING_WENT_WRONG"),
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                        }
                    });
            }
        }
    };

    completeAccount = () => {
        const { t, history } = this.props;

        if (
            this.state.depositedAmount1 <= 0 ||
            this.state.depositedAmount2 <= 0 ||
            this.state.cashAccountId === ""
        ) {
            this.showNotification(
                t("CASH_ACCOUNT_REVIEW_FIELDS_REQUIRED_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            let dataParameters = {
                bankAccountId: this.state.cashAccountId,
                depositOne: this.state.depositedAmount1,
                depositTwo: this.state.depositedAmount2,
            };

            this.setState({ isLoading: true, disableField: true });
            completeCashAccount(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.setState({
                            step: this.state.step + 1,
                            isLoading: false,
                            disableField: false,
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            disableField: false,
                        });
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            null
                        );
                    }
                })
                .catch((error) => {
                    this.setState({ isLoading: false, disableField: false });
                    if (error.response) {
                        this.showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        validateSession(error.response, history);
                    } else {
                        this.showNotification(
                            t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                });
        }
    };

    handleValidationForm = () => {
        let fields = [this.state.name, this.state.account];

        if (!validateFields(fields) || selectValidation(this.state.provider)) {
            this.showNotification(
                this.props.t("CASH_ACCOUNT_FIELDS_REQUIRED_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            if (this.state.agreement !== null && this.state.agreement !== "") {
                this.setState({ step: this.state.step + 1 });
            } else {
                this.setState({ isLoading: true, disableField: true });
                getWalletAgreement()
                    .then((response) => {
                        this.setState({
                            isLoading: false,
                            disableField: false,
                        });
                        if (
                            isSuccessfulCreate(response) &&
                            response.data.entity !== null
                        ) {
                            this.setState({
                                step: this.state.step + 1,
                                agreement: response.data.entity,
                            });
                        } else {
                            this.showNotification(
                                response.data.message,
                                "danger",
                                NotificationErrorIcon,
                                null
                            );
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            disableField: false,
                        });
                        if (error.response) {
                            this.showNotification(
                                error.response.data.message,
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                            validateSession(error.response, this.props.history);
                        } else {
                            this.showNotification(
                                this.props.t("SOMETHING_WENT_WRONG"),
                                "danger",
                                NotificationFailedIcon,
                                null
                            );
                        }
                    });
            }
        }
    };

    hasPaymentPermissionPermission = () => {
        const { match } = this.props;
        let result = false;

        try {
            let userTabs = getUser().userRoutes;

            let index = userTabs.findIndex((x) => x.routeUrl === "/cash");
            if (
                index !== -1 &&
                (match.params.action === ACTION_NEW ||
                    match.params.action === ACTION_UPDATE)
            ) {
                result = true;
            }
        } catch (e) {}

        return result;
    };

    getStepContent = (step) => {
        const {
            account,
            provider,
            agreement,
            currency,
            createdDate,
        } = this.state;

        switch (step) {
            case 1:
                return <CashAccountFormDetails component={this} />;
            case 2:
                return (
                    <CashAccountFormAgreement
                        data={{
                            account: account,
                            provider: provider !== "" ? provider.label : "",
                            date:
                                createdDate !== null
                                    ? convertDateString(new Date())
                                    : createdDate,
                            agreementReference: agreement,
                        }}
                    />
                );
            case 3:
                return (
                    <CashAccountFormReview
                        stepSuccess={false}
                        component={this}
                        currency={currency.label}
                    />
                );
            case 4:
                return (
                    <CashAccountFormReview
                        stepSuccess={true}
                        component={this}
                        currency={currency.label}
                    />
                );
            default:
                return null;
        }
    };

    handleGoBack = () => {
        this.setState({ step: this.state.step - 1 });
    };

    handleCancel = () => {
        removeData(OBJECT_NAMES.CASH_ACCOUNT_PENDING);
        if (this.state.step === 2) {
            this.setState({ isLoading: true, disableField: true });
            cancelWalletAgreement({ agreementId: this.state.agreement })
                .then((response) => {
                    this.setState({ isLoading: false, disableField: false });
                    this.props.history.push("/admin/cash");
                })
                .catch((error) => {
                    this.setState({ isLoading: false, disableField: false });
                    this.props.history.push("/admin/cash");
                });
        } else {
            this.cleanData();
            this.props.history.push("/admin/cash");
        }
    };

    handleCompleteLater = () => {
        removeData(OBJECT_NAMES.CASH_ACCOUNT_PENDING);
        this.props.history.push("/admin/cash");
    };

    render() {
        const { classes, t, match, routeProps } = this.props;
        const {
            step,
            stepTotal,
            isLoading,
            notification,
            notificationMessage,
            icon,
            color,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={2} md={2} lg={2}>
                        {step > 1 && step < stepTotal ? (
                            <SimpleButton
                                onClick={this.handleGoBack}
                                disabled={match.params.action === ACTION_UPDATE}
                                className={classes.breadButton}
                            >
                                {"<- " + t("BTN_BACK")}
                            </SimpleButton>
                        ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        {this.getStepContent(step)}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        {step === 1 || step === 2 ? (
                                            <div className={classes.center}>
                                                <Button
                                                    disabled={
                                                        this.state.disableField
                                                    }
                                                    className={
                                                        classes.cancelButton +
                                                        " " +
                                                        classes.horizontalSpacing
                                                    }
                                                    onClick={this.handleCancel}
                                                    color="white"
                                                >
                                                    {t("BTN_CANCEL")}
                                                </Button>
                                                <Button
                                                    disabled={
                                                        this.state.disableField
                                                    }
                                                    onClick={this.handleNext}
                                                    color="primary"
                                                >
                                                    {step === 1
                                                        ? t("BTN_NEXT")
                                                        : t("BTN_ACCEPT")}
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className={classes.centerRows}>
                                                <Button
                                                    disabled={
                                                        this.state.disableField
                                                    }
                                                    onClick={this.handleNext}
                                                    color="primary"
                                                >
                                                    {step === 3
                                                        ? t("BTN_CONFIRM")
                                                        : step === stepTotal
                                                        ? t("BTN_FINISH")
                                                        : t("BTN_NEXT")}
                                                </Button>
                                                {step === 3 ? (
                                                    <SimpleButton
                                                        className={
                                                            classes.laterButton
                                                        }
                                                        disabled={
                                                            this.state
                                                                .disableField
                                                        }
                                                        onClick={
                                                            this
                                                                .handleCompleteLater
                                                        }
                                                    >
                                                        {t(
                                                            "BTN_COMPLETE_LATER"
                                                        )}
                                                    </SimpleButton>
                                                ) : null}
                                            </div>
                                        )}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={false} sm={2} md={2} lg={2} />
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={color}
                    icon={icon}
                    message={notificationMessage}
                    open={notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

CashAccountFormContainer.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(CashAccountFormContainer))
);
