/**
 * Copyright 2021 Ginih (https://www.ginih.com)
 * Created By Marlon
 * Coded by Ginih Team
 **/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import Loading from "../../components/Loading/Loading";
import { NOTIFICATION_DURATION } from "../../variables/coreConstants";
import Snackbar from "../../components/Snackbar/Snackbar";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Transfers from "./components/Transfers/Transfers";
import CashAccount from "./components/Accounts/CashAccount";
import {getWalletAccountDetail, getWalletAccounts} from "../../services/cashAccountService";
import { validateSession } from "../../services/api";
import {
    getCompanyCurrency,
    getData, getDefaultWalletAccount,
    getRoundNumber,
    removeData,
    saveData,
    setCurrencyValue,
} from "../../utils/helpersFunctions";
import Button from "../../components/CustomButtons/Button";
import Arrow from "@material-ui/icons/ExpandMore";
import Poppers from "@material-ui/core/Popper";
import classNames from "classnames";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

const OBJECT_NAMES = require("../../variables/objectConstants");

class Cash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            walletAccount: null,
            walletAccountList: [],
            value: 0,
            data: "",
            currencySymbol: null,
            currencyCode: null,
            balance: setCurrencyValue(0.0),
            balanceReserved: setCurrencyValue(0.0),
            totalBalance: setCurrencyValue(0.0),
            isLoading: false,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
            openWalletSelector: false,
        };
    }

    componentDidMount() {
        let lastViewTab = getData(OBJECT_NAMES.CURRENT_CASH_TAB);

        if (lastViewTab !== null) {
            this.setState({
                value: lastViewTab.tab,
            });
        }

        this.setState({ isLoading: true }, () => {
            this.handleRequestWalletAccountList();
        });
    }

    showNotification = (msj, color, icon, action, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleToggleSelector = () => {
        this.setState(state => ({openWalletSelector: !state.openWalletSelector}));
    };

    handleCloseSelector = event => {
        if (this.anchorSelector.contains(event.target)) {
            return;
        }
        this.setState({openWalletSelector: false});
    };
    handleSelectWallet = (wa) => {
        this.setState({ openWalletSelector: false, walletAccount: wa.uuid, isLoading: true }, () => {
            this.request();
        });
    };

    request = () => {
        getWalletAccountDetail(this.state.walletAccount)
            .then((response) => {
                this.setState(
                    {
                        walletAccount:
                            response.data != null ? response.data.id : null,
                        currencySymbol:
                            response.data != null
                                ? response.data.currencySymbol
                                : "",
                        currencyCode:
                            response.data != null
                                ? response.data.currencyCode
                                : "",
                        balance:
                            response.data != null
                                ? setCurrencyValue(
                                      getRoundNumber(
                                          response.data.balanceAvailable
                                      )
                                  )
                                : setCurrencyValue(0.0),
                        balanceReserved:
                            response.data != null
                                ? setCurrencyValue(
                                      getRoundNumber(
                                          response.data.balanceReserved
                                      )
                                  )
                                : setCurrencyValue(0.0),
                        totalBalance:
                            response.data != null
                                ? setCurrencyValue(
                                      getRoundNumber(response.data.totalBalance)
                                  )
                                : setCurrencyValue(0.0),
                    },
                    () => {
                        this.setState({ isLoading: false });
                        saveData(
                            OBJECT_NAMES.WALLET_ACCOUNT_ID,
                            this.state.walletAccount
                        );
                    }
                );
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(
                        err.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };

    handleRequestWalletAccountList = () => {
        getWalletAccounts()
            .then((response) => {
                this.setState(
                    {
                        walletAccountList: response.data !== null && response.data !== undefined ? response.data: [],
                        walletAccount: getDefaultWalletAccount(response.data, getCompanyCurrency())
                    },
                    () => {
                        saveData(
                            OBJECT_NAMES.WALLET_ACCOUNT_ID,
                            this.state.walletAccount
                        );
                        this.request();
                    }
                );
            })
            .catch((err) => {
                this.request();

                if (err.response) {
                    validateSession(err.response, this.props.history);
                }
            });
    };

    handleChange = (event, newValue) => {
        let lastTab = getData(OBJECT_NAMES.CURRENT_CASH_TAB);
        if (newValue === 0 && lastTab !== null && lastTab.tab === 1) {
            removeData(OBJECT_NAMES.CURRENT_CASH_TAB);
        }
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            value,
            isLoading,
            openWalletSelector,
            notification,
            notificationMessage,
            icon,
            color,
            balance,
            walletAccount,
            currencySymbol,
            currencyCode
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <h3 className={classes.screenTitle}>
                                {t("MAIN_ACCOUNT_TXT")}
                            </h3>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={5}
                            lg={4}
                            className={classes.gridNoTop}
                        >
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={10} md={10}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p
                                                        className={
                                                            classes.balanceCardTitle
                                                        }
                                                    >
                                                        {t("BALANCE_TXT")}
                                                    </p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <p
                                                            className={
                                                                classes.balanceCardTitle2
                                                            }
                                                        >
                                                            {currencySymbol +
                                                                " " +
                                                                balance}
                                                        </p>
                                                    </div>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <div className={classes.manager}>
                                                <Button
                                                    buttonRef={node => {
                                                        this.anchorSelector = node;
                                                    }}
                                                    color={"white"}
                                                    justIcon={true}
                                                    simple={false}
                                                    aria-owns={null}
                                                    aria-haspopup="true"
                                                    onClick={this.handleToggleSelector}
                                                >
                                                    <Arrow className={classes.icons} fontSize={"large"}/>
                                                </Button>

                                                <Poppers
                                                    open={openWalletSelector}
                                                    anchorEl={this.anchorSelector}
                                                    transition
                                                    disablePortal
                                                    className={
                                                        classNames({[classes.popperClose]: !openWalletSelector}) +
                                                        " " +
                                                        classes.popperNav
                                                    }
                                                >
                                                    {({TransitionProps, placement}) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            id="profile-menu-list-grow"
                                                            style={{
                                                                transformOrigin:
                                                                    placement === "bottom" ? "center top" : "center bottom"
                                                            }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener
                                                                    onClickAway={this.handleCloseSelector}>
                                                                    <MenuList role="menu">
                                                                        {
                                                                            this.state.walletAccountList.map((wa, k) => (
                                                                                <MenuItem
                                                                                    key={k}
                                                                                    onClick={() => this.handleSelectWallet(wa)}
                                                                                    className={classes.dropdownItem}
                                                                                >
                                                                                    {wa.currencyId.name + ' (' + wa.currencyId.symbol + ')'}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Poppers>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <AppBar
                                position={"static"}
                                className={classes.tabBar}
                            >
                                <Tabs
                                    value={value}
                                    onChange={this.handleChange}
                                    aria-label="tab"
                                >
                                    <Tab
                                        label={t("ACTIVITY_TXT")}
                                        {...this.a11yProps(0)}
                                    />
                                    <Tab
                                        label={t("ACCOUNTS_TXT")}
                                        {...this.a11yProps(1)}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <Transfers
                                    showNotification={this.showNotification}
                                    walletAccount={{ id: walletAccount, currency: { symbol: currencySymbol, code: currencyCode } }}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <CashAccount
                                    showNotification={this.showNotification}
                                    walletAccount={{
                                        id: walletAccount,
                                        currency: {symbol: currencySymbol, code: currencyCode}
                                    }}
                                />
                            </TabPanel>
                        </GridItem>
                    </GridContainer>
                )}
                <Snackbar
                    place="tc"
                    color={color}
                    icon={icon}
                    message={notificationMessage}
                    open={notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

Cash.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(Cash)));
