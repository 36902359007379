var paymentMethodProviderDetails = {
    id: null,
    name: '',
    image: null,
    uuid: '',
    methodTypes: [],
    allProviders: [],

    cleanPaymentMethod : function () {
        this.id = null;
        this.name = '';
        this.image = null;
        this.uuid = '';
        this.methodTypes = [];
        this.allProviders = [];
    }
};

export default paymentMethodProviderDetails;