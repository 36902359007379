import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
// react plugin for creating chart
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import CommerceAnalyticCards from "../Analytics/Commerce/CommerceAnalyticCards";
import Select from "../../components/CustomSelect/CustomSelect";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import { validateSession } from "../../services/api";
import { withTranslation } from "react-i18next";
import {
    getCommerceAnalytics,
    getHomeFeedAnalytics,
} from "../../services/analyticsService";
import {
    CUSTOM_ID,
    LAST_MONTH_ID,
    LAST_SIX_MONTHS_ID,
    LAST_THREE_DAYS_ID,
    LAST_THREE_MONTHS_ID,
    LAST_WEEK_ID,
    LAST_YEAR_ID, NOTIFICATION_DURATION,
    TODAY_ID,
    YESTERDAY_ID,
} from "../../variables/coreConstants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDateFilter from "../Analytics/Commerce/CustomDateFilter";
import {
    formatDate, getCompanyCurrency, getDefaultCurrency, getDefaultWalletAccount, getRoundNumber,
    getUser,
    initialMomentOfDay,
    nowMomentDate, nullController, saveData, setCurrencyValue,
} from "../../utils/helpersFunctions";
import HomeFeedAnalytics from "../Home/components/HomeFeedAnalytics";
import { getDefaultingMembers } from "../../services/memberService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {getWalletAccounts} from "../../services/cashAccountService";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            filterType: "",
            dateInit: initialMomentOfDay(),
            dateEnd: nowMomentDate(),
            typesOptions: [],
            storeValue: { value: 0, label: this.props.t("ALL_STORES_TEXT") },
            storeOptions: [],
            requesting: false,
            dataMembers: null,
            analytics: null,
            openDate: false,
            includeExternalData: false,
            date: { value: TODAY_ID, label: this.props.t("TODAY_TEXT") },
            dateOptions: [
                { value: TODAY_ID, label: this.props.t("TODAY_TEXT") },
                { value: YESTERDAY_ID, label: this.props.t("YESTERDAY_TEXT") },
                {
                    value: LAST_THREE_DAYS_ID,
                    label: this.props.t("LAST_THREE_DAYS_TEXT"),
                },
                { value: LAST_WEEK_ID, label: this.props.t("LAST_WEEK_TEXT") },
                {
                    value: LAST_MONTH_ID,
                    label: this.props.t("LAST_MONTH_TEXT"),
                },
                {
                    value: LAST_THREE_MONTHS_ID,
                    label: this.props.t("LAST_THREE_MONTHS_TEXT"),
                },
                {
                    value: LAST_SIX_MONTHS_ID,
                    label: this.props.t("LAST_SIX_MONTHS_TEXT"),
                },
                { value: LAST_YEAR_ID, label: this.props.t("LAST_YEAR_TEXT") },
                { value: CUSTOM_ID, label: this.props.t("CUSTOM_TEXT") },
            ],
            data: [],
            selectedCurrency: null,
            currencyAvailable: [],
            isLoading: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }

    componentDidMount() {
        this.handleRequestWalletAccountList();
    }


    handleRequestWalletAccountList = () => {
        getWalletAccounts()
            .then((response) => {
                let currencies = response.data.map(wa => { return { value: wa.currencyId.isoCode, label: wa.currencyId.name + "(" + wa.currencyId.symbol + ")" }});
                this.setState(
                    {
                        currencyAvailable: currencies,
                        selectedCurrency: getDefaultCurrency(response.data, getCompanyCurrency())
                    },
                    () => {
                        this.handleRequestData();
                    }
                );
            })
            .catch((err) => {
                this.handleRequestData();

                if (err.response) {
                    validateSession(err.response, this.props.history);
                }
            });
    };

    handleRequestData = () => {
        this.request();

        try {
            if (getUser().verified) {
                this.requestMembers();
            }
        } catch (e) {
        }
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };
    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    handleCloseForm = (requireUpdate, states) => {
        this.setState({ openDate: false });
        if (requireUpdate && states !== null) {
            try {
                let initDate;
                let endDate;

                try {
                    initDate = states.startTime.valueOf();
                    endDate = states.endTime.valueOf();
                } catch (e) {
                    initDate = this.state.dateInit.valueOf();
                    endDate = this.state.dateEnd.valueOf();
                }

                this.setState({
                    isLoading: true,
                    dateInit: states.startTime,
                    dateEnd: states.endTime,
                });

                let dataParameters = {
                    currencyCode: nullController(this.state.selectedCurrency, "value"),
                    intervalDays: this.state.date.value,
                    storeId:
                        this.state.storeValue === ""
                            ? 0
                            : this.state.storeValue.value,
                    includeExternalData: this.state.includeExternalData,
                    startDate: formatDate(new Date(initDate)),
                    endDate: formatDate(new Date(endDate)),
                };

                this.requestAnalyticsData(dataParameters);
            } catch (e) {
                this.setState({ isLoading: false });
            }
        }
    };

    /**
     * @param {number} dateFilterValue
     * @return {Date}
     **/
    getDateTimeFromFilter = (dateFilterValue) => {
        let date = new Date();
        try {
            switch (dateFilterValue) {
                case TODAY_ID:
                    break;
                case YESTERDAY_ID:
                    date.setDate(date.getDate() - 1);
                    break;
                case LAST_THREE_DAYS_ID:
                    date.setDate(date.getDate() - 3);
                    break;
                case LAST_WEEK_ID:
                    date.setDate(date.getDate() - 7);
                    break;
                case LAST_MONTH_ID:
                    date.setDate(date.getDate() - 30);
                    break;
                case LAST_THREE_MONTHS_ID:
                    date.setDate(date.getDate() - 90);
                    break;
                case LAST_SIX_MONTHS_ID:
                    date.setDate(date.getDate() - 180);
                    break;
                case LAST_YEAR_ID:
                    date.setDate(date.getDate() - 365);
                    break;
                default:
                    date = new Date();
            }

            date.setHours(0, 0, 0);
        } catch (e) {}

        return date !== null ? date : null;
    };

    changeMode = () => {
        this.setState(
            (prevState) => ({
                includeExternalData: !prevState.includeExternalData,
                isLoading: true,
            }),
            () => {
                let initDate;
                let endDate;

                if (this.state.date.value !== CUSTOM_ID) {
                    initDate = this.getDateTimeFromFilter(
                        this.state.date.value
                    );

                    endDate =
                        this.state.date.value === YESTERDAY_ID &&
                        initDate !== null
                            ? new Date(initDate.valueOf()).setHours(23, 59, 59)
                            : new Date().setHours(23, 59, 59);
                } else {
                    initDate = new Date(this.state.dateInit.valueOf());
                    endDate = new Date(this.state.dateEnd.valueOf());
                }

                let dataParameters = {
                    currencyCode: nullController(this.state.selectedCurrency, "value"),
                    intervalDays: this.state.date.value,
                    storeId:
                        this.state.storeValue === ""
                            ? 0
                            : this.state.storeValue.value,
                    includeExternalData: this.state.includeExternalData,
                    startDate: formatDate(initDate),
                    endDate: formatDate(endDate),
                };

                this.requestAnalyticsData(dataParameters);
            }
        );
    };

    changeDateSelect = (date) => {
        const { storeValue } = this.state;
        this.setState({ date: date, isLoading: true });

        if (date.value !== CUSTOM_ID) {
            let initDate = this.getDateTimeFromFilter(date.value);
            let endDate =
                date.value === YESTERDAY_ID && initDate !== null
                    ? new Date(initDate.valueOf()).setHours(23, 59, 59)
                    : new Date().setHours(23, 59, 59);

            let dataParameters = {
                currencyCode: nullController(this.state.selectedCurrency, "value"),
                intervalDays: date.value,
                storeId: storeValue === "" ? 0 : storeValue.value,
                includeExternalData: this.state.includeExternalData,
                startDate: formatDate(initDate),
                endDate: formatDate(endDate),
            };

            this.requestAnalyticsData(dataParameters);
        } else {
            this.setState({ date: date, isLoading: false, openDate: true });
        }
    };

    changeCurrencySelect = (currency) => {
        this.setState({ isLoading: true, selectedCurrency: currency }, () => {
            this.handleRequestData();
        });
    }

        getDateRageString = (initial) => {
        let initDate, endDate;

        if (this.state.date.value !== CUSTOM_ID) {
            initDate = this.getDateTimeFromFilter(this.state.date.value);

            endDate =
                this.state.date.value === YESTERDAY_ID && initDate !== null
                    ? new Date(initDate.valueOf()).setHours(23, 59, 59)
                    : new Date().setHours(23, 59, 59);
        } else {
            initDate = new Date(this.state.dateInit.valueOf());
            endDate = new Date(this.state.dateEnd.valueOf());
        }

        return initial ? formatDate(initDate) : formatDate(endDate);
    };

    changeStoreSelect = (store) => {
        const { date } = this.state;
        this.setState({ storeValue: store, isLoading: true });

        let initDate;
        let endDate;

        if (date.value !== CUSTOM_ID) {
            initDate = this.getDateTimeFromFilter(date.value);

            endDate =
                date.value === YESTERDAY_ID && initDate !== null
                    ? new Date(initDate.valueOf()).setHours(23, 59, 59)
                    : new Date().setHours(23, 59, 59);
        } else {
            initDate = new Date(this.state.dateInit.valueOf());
            endDate = new Date(this.state.dateEnd.valueOf());
        }

        let dataParameters = {
            currencyCode: nullController(this.state.selectedCurrency, "value"),
            intervalDays: date.value,
            storeId: store.value,
            includeExternalData: this.state.includeExternalData,
            startDate: formatDate(initDate),
            endDate: formatDate(endDate),
        };

        this.requestAnalyticsData(dataParameters);
    };

    changeFilterTypeSelect = (filterType) => {
        this.setState({ filterType });
    };

    request = () => {
        this.setState({ isLoading: true });

        // getStores()
        //     .then((response) => {
        //       let storesValues = response.data.map( item => {
        //         return { value: item.id , label: item.name };
        //       });
        //
        //       this.setState({ storeOptions: storesValues });
        //
        //     })
        //     .catch((error) => {
        //       if (error.response) {
        //         validateSession(error.response, this.props.history);
        //       }
        //     });

        let start = new Date();
        start.setHours(0, 0, 0);
        let end = new Date();
        end.setHours(23, 59, 59);

        let dataParameters = {
            currencyCode: nullController(this.state.selectedCurrency, "value"),
            intervalDays: this.state.date.value,
            storeId: this.state.storeValue.value,
            includeExternalData: this.state.includeExternalData,
            startDate: formatDate(start),
            endDate: formatDate(end),
        };
        this.requestAnalyticsData(dataParameters);
    };

    requestAnalyticsData = (parameters) => {
        getCommerceAnalytics(parameters)
            .then((response) => {
                this.setState({
                    data: response.data,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });

                if (error.response) {
                    validateSession(error.response, this.props.history);
                }
            });
    };

    requestMembers = () => {
        this.requestAnalytics();
        this.onRequestMemberData({ currencyCode: nullController(this.state.selectedCurrency, "value"), page: 0, pageSize: 10 });
    };

    requestAnalytics = () => {
        this.setState({ loading: true });
        getHomeFeedAnalytics({ "currencyCode": nullController(this.state.selectedCurrency, "value") })
            .then((response) => {
                this.setState({
                    analytics:
                        response.data !== null &&
                        response.data.totalDefaultingMembers > 0
                            ? response.data
                            : null,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };

    onRequestMemberData = (parameters) => {
        this.setState({ requesting: true });
        getDefaultingMembers(parameters)
            .then((response) => {
                let newData = response.data !== null && response.data.content.length > 0
                    ? response.data.content.map(item => {
                        item.defaultedAmount = nullController(item, "currencySymbol")  + " " + setCurrencyValue(getRoundNumber(nullController(item, 'defaultedAmount')))
                        return item;
                    }) : [];

                this.setState({
                    dataMembers: newData,
                    requesting: false,
                });
            })
            .catch((error) => {
                this.setState({ requesting: false });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };



    render() {
        const { classes, t, routeProps } = this.props;
        const {
            data,
            isLoading,
            loading,
            dataMembers,
            requesting,
            analytics,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <Backdrop
                    className={classes.backdrop}
                    open={(loading && requesting) || isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div
                            className={
                                classes.wrapContent + " " + classes.leftPaper
                            }
                        >
                            {/*<div className={classes.filterContentDiv}>*/}
                            {/*  <Select*/}
                            {/*      value={this.state.storeValue}*/}
                            {/*      onChange={this.changeStoreSelect}*/}
                            {/*      placeholder={t('STORE_TEXT')}*/}
                            {/*      options={this.state.storeOptions}*/}
                            {/*      closeMenuOnSelect={true}*/}
                            {/*      maxMenuHeight={250}*/}
                            {/*      theme={theme => ({*/}
                            {/*        ...theme,*/}
                            {/*        colors: {*/}
                            {/*          ...theme.colors,*/}
                            {/*          primary: primaryColor[0],*/}
                            {/*        },*/}
                            {/*      })}*/}
                            {/*      styles={{*/}
                            {/*        menu: provided => ({ ...provided, zIndex: 5 })*/}
                            {/*      }}*/}
                            {/*  />*/}
                            {/*  <div style={{height: "10px", backgroundColor: "transparent"}}/>*/}
                            {/*    <FormControlLabel*/}
                            {/*        className={classes.rightContainerSwitch}*/}
                            {/*        control={*/}
                            {/*            <CustomSwitch*/}
                            {/*                className={classes.switchBase}*/}
                            {/*                size="small"*/}
                            {/*                disabled={this.state.isLoading}*/}
                            {/*                checked={this.state.includeExternalData}*/}
                            {/*                onChange={this.changeMode}*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*        label={t('INCLUDE_EXT_DATA_TXT')}/>*/}
                            {/*</div>*/}

                            <div className={classes.filterContentDiv}>
                                <Select
                                    value={this.state.selectedCurrency}
                                    onChange={this.changeCurrencySelect}
                                    placeholder={t("SELECT_TEXT")}
                                    options={this.state.currencyAvailable}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 5,
                                        }),
                                    }}
                                />
                            </div>

                            <div className={classes.filterContentDiv}>
                                <Select
                                    value={this.state.date}
                                    onChange={this.changeDateSelect}
                                    placeholder={t("SELECT_TEXT")}
                                    options={this.state.dateOptions}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 5,
                                        }),
                                    }}
                                />
                            </div>

                            <div className={classes.rowDiv}>
                                <div
                                    className={
                                        classes.row +
                                        " " +
                                        classes.separatorColumn
                                    }
                                >
                                    <label className={classes.labelGrayText}>
                                        {t("CUSTOM_DATE_LABEL_INIT")}
                                    </label>
                                    <label className={classes.valueGrayText}>
                                        {this.getDateRageString(true)}
                                    </label>
                                </div>
                                <div className={classes.row}>
                                    <label className={classes.labelGrayText}>
                                        {t("CUSTOM_DATE_LABEL_END")}
                                    </label>
                                    <label className={classes.valueGrayText}>
                                        {this.getDateRageString(false)}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </GridItem>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openDate}
                        onClose={() => this.handleCloseForm(false, null)}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t("CUSTOM_DATE_FILTER")}
                        </DialogTitle>
                        <DialogContent
                            style={{ display: "fex", flexWrap: "wrap" }}
                        >
                            <CustomDateFilter
                                closeForm={this.handleCloseForm}
                            />
                        </DialogContent>
                    </Dialog>
                </GridContainer>
                <CommerceAnalyticCards data={data} loading={isLoading} />

                {(dataMembers === null || dataMembers === []) &&
                analytics === null ? null : (
                    <GridItem xs={12} sm={12} md={12}>
                        <HomeFeedAnalytics
                            data={dataMembers !== null ? dataMembers : []}
                            analytics={analytics !== null ? analytics : []}
                            onPaginate={this.onRequestMemberData}
                            loading={requesting}
                        />
                    </GridItem>
                )}
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(Dashboard))
);
