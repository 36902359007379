import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {getPaymentDetails, getPaymentsByCompany} from "../../services/paymentService";
import Loading from "../../components/Loading/Loading";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
    convertDate,
    getRoundNumber,
    mergeArrays,
    nullController,
    saveData,
    setCurrencyValue
} from "../../utils/helpersFunctions";
import Status from "../../components/Status/Status";
import {statusColors, statusText} from "../../utils/statusHelpers";
import {
    ALL_STATUS,
    DECLINED_STATUS,
    NOTIFICATION_DURATION,
    PAID_STATUS,
    PAYMENT_NOT_COMPLETED_STATUS,
    PAYMENTS_ROWS_PER_PAGE_OPTIONS,
    PROVIDER_FAILED_STATUS,
    RETRY_FAILED_STATUS,
    ROWS_PER_PAGE_PAYMENTS,
    STATUS_PAID_PENDING_SYNC,
    STATUS_PENDING_AUTH,
    TRANSACTIONS_PAGINATION_PARAMETERS,
    UNSPECIFIED_STATUS
} from "../../variables/coreConstants";
import {validateSession} from "../../services/api";
import Table from "@material-ui/core/Table";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CustomTableNavigation from "../../components/Table/CustomTableNavigation";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import PaymentDetailsForm from "./components/PaymentDetailsForm";
import Select from "../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import {withRouter} from "react-router-dom";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Payments extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            open: false,
            isLoading: false,
            paymentsData: [],
            filteredPayments: [],
            rowsPerPage: ROWS_PER_PAGE_PAYMENTS,
            page: 0,
            count: 0,
            noItems: false,
            paymentDetail: "",
            openPaymentDetail: false,
            isLoadingDetails: false,
            status: {value: ALL_STATUS, label: this.props.t('ALL_STATUS_TEXT')},
            statusOptions: [
                {value: ALL_STATUS, label: this.props.t('ALL_STATUS_TEXT')},
                {value: PAID_STATUS, label: this.props.t('STATUS_PAID')},
                {value: UNSPECIFIED_STATUS, label: this.props.t('STATUS_UNSPECIFIED')},
                {value: DECLINED_STATUS, label: this.props.t('STATUS_DECLINED')},
                {value: PROVIDER_FAILED_STATUS, label: this.props.t('STATUS_EXTERNAL_FAILED')},
                {value: RETRY_FAILED_STATUS, label: this.props.t('STATUS_RETRY_FAILED')},
                {value: PAYMENT_NOT_COMPLETED_STATUS, label: this.props.t('STATUS_NOT_COMPLETED')},
                {value: STATUS_PAID_PENDING_SYNC, label: this.props.t('STATUS_PAID_PENDING_SYNC')},
                {value: STATUS_PENDING_AUTH, label: this.props.t('STATUS_PENDING_AUTH')}
            ],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount(): void {
        this.setState({ isLoading: true });
        this.request(TRANSACTIONS_PAGINATION_PARAMETERS);
    }

    closeForm(state, value, type) {
        this.setState({
            [state]: value,
            paymentDetail: ''
        });

        if(type) {
            this.setState({ isLoading: true, page: 0 });
            let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage, "status": this.state.status.value };
            this.request(dataParameters);
        }
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ page: 0 });
                    let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage, "status": this.state.status.value };
                    this.request(dataParameters);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {return;}
        this.setState({ notification: false });
    };


    changeStatusSelect = status => {
        this.setState({ status });
        this.setState({ isLoading: true, page: 0 });
        let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage, "status": status.value };
        this.request(dataParameters);
    };

    openLink = (data) => {
        this.setState({ openPaymentDetail: false }, ()=> {
            saveData("paymentRequest", { uuid: data.paymentRequestId });
            window.open("/admin/payment-request-details/" + data.paymentRequestId, "_blank");
            // this.props.history.push("/admin/payment-request-details/" + data.paymentRequestId);
        });
    };


    request(parameters) {
        getPaymentsByCompany(parameters)
            .then((response) => {
                let recordPrefix = this.state.filteredPayments.length + 1;
                let array = response.data.content ? response.data.content : response.data;

                let newData = array.map(r => {
                    r.recordName = "TX-" + recordPrefix;
                    r.amountLabel = nullController(r, "currency") + " " + setCurrencyValue(getRoundNumber(nullController(r, "amount")));
                    recordPrefix++;
                    return r;
                });

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredPayments,
                    newData,
                    "reference"
                );


                this.setState({
                    paymentsData: paginatedData,
                    filteredPayments: paginatedData,
                    noItems: newData.length === 0,
                    isLoadingMore: false,
                    isLoading: false,
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false, isLoadingMore } );
                if (error.response)
                    validateSession(error.response, this.props.history);
            });
    }

    handleOpenForm = () => {
        this.setState({open: true});
    };
    handleCloseForm = () => {
        this.setState({open: false});
    };
    handleCloseDetailForm = () => {
        this.setState({openPaymentDetail: false});
    };



    openDetails = (formData) => {
        const { t } = this.props;

        this.setState({ isLoadingDetails: true });
        getPaymentDetails(formData.reference)
            .then((response) => {
                if (response.data) {
                    this.setState({
                        paymentDetail: response.data,
                        openPaymentDetail: true,
                        isLoadingDetails: false
                    });
                } else {
                    this.setState({
                        isLoadingDetails: false,
                        openPaymentDetail: false
                    });
                    this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }

            })
            .catch((error) => {
                this.setState({
                    isLoadingDetails: false,
                    openPaymentDetail: false
                });

                if (error.response) {
                    this.showNotification(error.response.data.message
                        ? error.response.data.message
                        : t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);

                    validateSession(error.response, this.props.history);
                }
            });

    };



    handleLoadMore = () => {
        const { isLoadingMore, page, noItems } = this.state;

        if (!isLoadingMore && !noItems) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "status": this.state.status.value
                };
                this.request(dataParameters);
            });
        }
    };





    render(){
        const { classes, t, routeProps } = this.props;
        const { isLoadingDetails, paymentDetail, isLoadingMore, isLoading, filteredPayments, statusOptions, status } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingDetails || isLoadingMore || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'payments'}
                        title={t('PAYMENTS_TEXT')}
                        exportFileName={t('PAYMENTS_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredPayments}
                        actions={[
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[
                            { value: status, data: statusOptions, function: this.changeStatusSelect, isLoading: this.state.isLoading, disabled: this.state.isLoading, type: "select"}
                        ]}
                        headers={[
                            { label: "ID", name: "recordName", onSelect: this.openDetails, isReadOnly: true, width: 100, isCustomCell: true, clickable: true },
                            { label: t('TH_PAID_BY'), name: "createdBy", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_AMOUNT'), name: "amountLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TRANSACTION_ID'), name: "transactionId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('CREATED_DATE_TXT'), name: "createdAt", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('PAID_DATE_TXT'), name: "paidDate", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true }
                        ]}
                    />
                </GridItem>

                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openPaymentDetail}
                    aria-labelledby="form-dialog-title"
                    style={{marginBottom: 0, paddingTop: "0px !important"}}
                >
                    <DialogTitle id="form-dialog-title">
                        {/*<div className={classes.orderDetailActions}>*/}
                        {/*    <div className={classes.actionCloseContainer}>*/}
                        {/*        <Tooltip id="tooltip-close" title={t('BTN_CLOSE')} placement="right-start">*/}
                        {/*            <IconButton size={"small"} onClick={this.handleCloseDetailForm} className={classes.formIcons}>*/}
                        {/*                <CloseIcon className={classes.iconButtonRed} />*/}
                        {/*            </IconButton>*/}
                        {/*        </Tooltip>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {t('PAYMENT_DETAILS_TEXT')}
                    </DialogTitle>
                    <DialogContent>
                        <PaymentDetailsForm data={paymentDetail} closeForm={this.closeForm} openLink={this.openLink}/>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Payments.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Payments)));
