import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import TablePagination from "@material-ui/core/TablePagination";

function CustomTableNavigation({ ...props }) {
    const { count, noItems, pageOptions, rowsPerPage, onFirstPage, onBack, onNext, onChangeRowsPerPage, totalItems } = props;

    const CustomActions = () => (
        <div style={{flexShrink: 0}}>
            {count > 1
                ? <IconButton style={{marginLeft: 2}} onClick={(e) => {onFirstPage(e)}}
                              disabled={count === 0} aria-label="last page">
                    <FirstPageIcon/>
                </IconButton>
                : null
            }
            <IconButton
                onClick={(e) => {onBack(e)}}
                disabled={count === 0}
                aria-label="last page">
                <LeftArrowIcon/>
            </IconButton>
            <IconButton
                onClick={(e) => {onNext(e)}}
                disabled={noItems}
                aria-label="next page">
                <RightArrowIcon/>
            </IconButton>
        </div>
    );


    return (
        <div>
            <TablePagination
                rowsPerPageOptions={pageOptions}
                component="div"
                count={totalItems}

                rowsPerPage={rowsPerPage}
                page={0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onChangePage={() => {console.log("")}}
                onChangeRowsPerPage={(e) => {onChangeRowsPerPage(e)}}
                ActionsComponent={CustomActions}
            />
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.count === nextProps.count && prevProps.noItems === nextProps.noItems
        && prevProps.rowsPerPage === nextProps.rowsPerPage && prevProps.pageOptions === nextProps.pageOptions;
}


CustomTableNavigation.propTypes = {
    count: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onFirstPage: PropTypes.func.isRequired,
    noItems: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(tableViewStyle)(CustomTableNavigation), propsAreEqual);
