import storeDetails from "./StoreModel";

let _date = new Date();
_date.setHours(0,0);

var menuDetails = {
    id: null,
    name: "",
    description: "",
    store: storeDetails.getStoreId(),
    parent_catalog_id: 0,

    cleanMenuDetails : function () {
        this.id = null;
        this.name = "";
        this.description = "";
    },

};

export default menuDetails;