import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";

import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent, formatDate,
    isSuccessfulRequest, nowMomentDate
} from "../../../utils/helpersFunctions";
import {validateSession} from "../../../services/api";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {withTranslation} from "react-i18next";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import InvoiceIcon from "@material-ui/icons/Receipt";
import {syncPaymentRequestManually} from "../../../services/paymentService";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";



class PaymentRequestSyncForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            syncDate: nowMomentDate(),
            otherData: "",
            invoiceReference: "",
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleDateChange = (date) => {
        this.setState({ syncDate: date });
    };



    cleanData = () => {
        this.setState({
            notificationMessage: '',
            notification: false,
            memberReference: "",
            invoiceReference: "",
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleSyncManually() {
        const { t } = this.props;

        if (this.state.syncDate === "" && this.state.invoiceReference === "") {
            this.showNotification(t('FIELDS_EMPTY'), "danger", NotificationErrorIcon, null);

        } else {
            let dataParameters = {
                "otherData": this.state.otherData,
                "invoiceReference" : this.state.invoiceReference,
                "syncDate" : formatDate(this.state.syncDate.valueOf()),
                "paymentRequestId": this.props.uuid
            };



            disableComponent(this, "disableField");
            syncPaymentRequestManually(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }



    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.invoiceReference}
                                onChange={this.handleChangeValues}
                                labelText={t('TH_INVOICE_REFERENCE')}
                                id="invoiceReference"
                                name="invoiceReference"
                                disabled={this.state.disableField}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <InvoiceIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.datePickerForm}>
                            <InputLabel className={classes.selectTitle}>
                                {t('SYNC_DATE_TXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.syncDate}
                                onChange={this.handleDateChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.multiLineInput}>
                            <Typography
                                className={classes.graySmallTitleText + " " + classes.zeroMargin}>
                                {t('OTHER_INFORMATION_TEXT')}
                            </Typography>
                            <TextField
                                id={"otherData"}
                                name={"otherData"}
                                draggable={false}
                                multiline
                                className={classes.disabledTextArea}
                                rowsMax={15}
                                onChange={this.handleChangeValues}
                                variant={"outlined"}
                                value={this.state.otherData}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                disabled={this.state.disableField}
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <Button
                                color="primary"
                                disabled={this.state.disableField}
                                className={classes.buttonSpacing}
                                onClick={() => this.handleSyncManually()}
                            >
                                {t('BTN_SAVE')}
                            </Button>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

PaymentRequestSyncForm.propTypes = {
    classes: PropTypes.object,
    uuid: PropTypes.string,
    closeForm: PropTypes.func
};

export default withRouter(withStyles(formStyle)(withTranslation()(PaymentRequestSyncForm)));