/*
 * Ginih Team
 * Created by Marlon On February 17, 2020
 */

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import { getData, nullController } from "../../../utils/helpersFunctions";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import { NOTIFICATION_DURATION } from "../../../variables/coreConstants";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import CategoryOrderForm from "./CategoryOrderForm";
import Snackbar from "../../../components/Snackbar/Snackbar";
import MenuCategoriesDetails from "./MenuCategoriesDetails";
import { getCategoriesByMenu } from "../../../services/categoryService";
import { validateSession } from "../../../services/api";
import categoryDetails from "../../../models/CategoryModel";
import { withTranslation } from "react-i18next";

class QuickActionsMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            categoryData: [],
            openCategory: false,
            actionType: 0,
            isLoading: false,
            isQuickStart: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.handleCloseForm = this.handleCloseForm.bind(this);
    }

    componentDidMount() {
        this.setState({ menu: getData("menu") });
        this.request();
    }

    componentWillUnmount() {
        // removeData("menu");
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.handleCloseForm("openCategory", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    openCategoryForm = () => {
        categoryDetails.list = this.state.categoryData;

        this.setState({ openCategory: true });
    };
    closeCategoryForm = () => {
        this.setState({ openCategory: false });
    };
    handleCloseForm(state, value, type) {
        this.setState({ [state]: value });
        this.setState({ openCategory: false });
        if (type) this.request();
    }

    openForm = (action) => {
        if (action === 1 || action === 0) {
            this.setState({ openCategory: true });
            this.setState({ actionType: action });
        } else {
        }
    };

    goToMenus = () => {
        this.props.history.push("menus");
    };

    request() {
        let menu = getData("menu");
        this.setState({ isLoading: true });

        getCategoriesByMenu(menu.id)
            .then((response) => {
                this.setState({
                    categoryData: response.data,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                }
            });
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const { categoryData } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.typo} style={{ marginBottom: "20px"}}>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.menu, "name")}
                            </h3>
                            <Muted>{t("MENU_SECTION_DESCRIPTION")}</Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                {t("EDIT_TEXT")}
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToMenus}
                            >
                                {t("GO_TO_MENU_TEXT")}
                            </CustomButton>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <MenuCategoriesDetails component={this} data={categoryData} />
                    </GridItem>
                </GridContainer>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    aria-labelledby="form-category-dialog-title"
                    open={this.state.openCategory}
                    onClose={this.closeCategoryForm}
                >
                    <DialogTitle id="form-category-dialog-title">
                        {t("FORM_ORDER_CATEGORY_TITLE")}
                        <Muted>{t("FORM_ORDER_CATEGORY_DESCRIPTION")}</Muted>
                    </DialogTitle>
                    <DialogContent>
                        <CategoryOrderForm component={this} />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsMenu))
);
