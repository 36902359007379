import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ASSET_ZONE,
    BASE_URL_CORE,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {getData, isSuccessfulRequest, nullController, removeData, saveData} from "../../../utils/helpersFunctions";
import {
    ASSET_DETAILS_TABLE_ACTIONS, ASSET_QR_CODE, ASSET_QR_DETAILS_TABLE_ACTIONS,
    NOTIFICATION_DURATION, SHOW_QR_CODE
} from "../../../variables/coreConstants";
import assetDetails from "../../../models/AssetModel";
import {getAssetsByZone} from "../../../services/assetService";
import AssetAssignForm from "./AssetZoneAssignmentForm";
import {statusController} from "../../../services/statusService";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Muted from "../../../components/Typography/Muted";
import {validateSession} from "../../../services/api";
// import QrCodeForm from "./QrCodeForm";


class ZoneAssetsDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openAsset: false,
            openQr: false,
            isLoading: true,
            actionType: 0,
            assetsData: [],
            filteredAssets: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openAsset", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    openQrForm = () => {
        this.setState({openQr: true});
    };

    handleOpenForm = () => {
        assetDetails.cleanAssetDetails();
        this.setState({ openAsset: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({openAsset: false});
    };

    closeQrForm = () => {
        this.setState({openQr: false});
    };

    closeForm(state, value, type) {
        this.setState({[state]: value});

        if (state === 'openQr')
            removeData("asset");

        if(type)
            this.request();
    }
    openForm = (action, formData) => {
        const { t } = this.props;
        assetDetails.id = formData.id;

        if (action === 1 || action === 0) {
            this.setState({ openAsset: true, actionType: action });

        } else if (action === SHOW_QR_CODE) {
            if (formData.longLink == null || formData.longLink === '') {
                this.showNotification(t('QR_INVALID_DATA_MSG'), "danger", NotificationErrorIcon, null);
            } else {
                saveData("asset", formData);
                this.openQrForm();
            }

        } else {
            let dataParameters = {
                "asset_zone_id": formData.id
            };

            statusController(action, BASE_URL_CORE, ASSET_ZONE, dataParameters, this, false)
                .then((response) => {
                    if (isSuccessfulRequest(response)){
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value, page: 0 });
    };


    request(){
        this.setState({ isLoading: true} );
        let zone = getData("zone");

        getAssetsByZone(zone.id)
            .then((res) => {
                this.setState({ assetsData: res.data, filteredAssets: res.data, isLoading: false });

                if (res.data.length === 0) {
                    this.props.quickAction("isQuickStartA", true);
                } else {
                    this.props.quickAction("isQuickStartA", false);
                }
            })
            .catch((err) => {
                this.setState({ isLoading: false} );
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }

            });
    }


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.typo}>
                                <h4> {t('ASSETS_TEXT')} </h4>
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.rightPaper}>
                                <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_ASSIGN_ASSET')}</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ASSET_TYPE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_LINK')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredAssets.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'name')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'assetType')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'shortLink')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                val.assetType === ASSET_QR_CODE
                                                                    ? (
                                                                        <TableActions
                                                                            rowData={val}
                                                                            openForm={this.openForm}
                                                                            options={ASSET_DETAILS_TABLE_ACTIONS}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <TableActions
                                                                            rowData={val}
                                                                            openForm={this.openForm}
                                                                            options={ASSET_DETAILS_TABLE_ACTIONS}
                                                                        />
                                                                    )
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.filteredAssets.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"xs"}
                        fullWidth={true}
                        open={this.state.openAsset}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_ASSIGN_ASSETS_TITLE')}
                            <Muted>
                                {t('FORM_ASSIGN_ASSETS_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <AssetAssignForm closeForm={this.closeForm} />
                        </DialogContent>
                    </Dialog>
                    {/*<Dialog*/}
                    {/*    maxWidth={"xs"}*/}
                    {/*    fullWidth={true}*/}
                    {/*    aria-labelledby="form-qr-dialog-title"*/}
                    {/*    open={this.state.openQr}*/}
                    {/*    onClose={this.closeQrForm}*/}
                    {/*>*/}
                    {/*    <DialogTitle id="form-qr-dialog-title" >*/}
                    {/*        {t('FORM_QR_CODE_ZONE_TITLE')}*/}
                    {/*        <Muted>*/}
                    {/*            {t('FORM_QR_CODE_DESCRIPTION')}*/}
                    {/*        </Muted>*/}
                    {/*    </DialogTitle>*/}
                    {/*    <DialogContent>*/}
                    {/*        <QrCodeForm closeForm={this.closeForm}/>*/}
                    {/*    </DialogContent>*/}
                    {/*</Dialog>*/}
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

ZoneAssetsDetails.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(ZoneAssetsDetails)));