import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-react/customCheckboxRadioSwitch.jsx";
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import InputLabel from "@material-ui/core/InputLabel";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Select from "../../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import {selectValidation} from "../../../../utils/helpersFunctions";
import NameIcon from "@material-ui/icons/Notes";


const animatedComponents = makeAnimated();

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "0px"
    },
    ...customCheckboxRadioSwitch,
    ...formStyle
};

class IntegrationDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: "detail",
        };
        this.isValidated = this.isValidated.bind(this);
        this.sendState = this.sendState.bind(this);
    }


    handleChangeValues = (e) => {
        this.props.component.handleChangeValues(e);
    };
    changeObjectSelect = (object) => {
        this.props.component.changeObjectTypeSelect(object);
    };


    // Method for wizard
    sendState() {
        return this.state;
    }


    // Validate step form
    isValidated() {
        const { component, translation } = this.props;

        if (component.state.name === "" || selectValidation(component.state.object)) {
            component.showNotification(translation('INTEGRATION_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

            return false;
        } else {
            return true;
        }
    }




    render() {
        const { classes, component, translation } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={component.state.name}
                            onChange={this.handleChangeValues}
                            labelText={translation('NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <NameIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="object" className={classes.selectTitle}>
                                {translation('TH_OBJECT')}
                            </InputLabel>
                            <Select
                                value={component.state.object}
                                onChange={this.changeObjectSelect}
                                components={animatedComponents}
                                isLoading={component.state.loadingDetail}
                                isDisabled={component.state.disabledObject}
                                options={component.state.objectOptions}
                                placeholder={translation('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={200}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


IntegrationDetailsForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    translation: PropTypes.func.isRequired
};

export default withStyles(style)(IntegrationDetailsForm);
