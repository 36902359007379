import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import searchableStyle from "../../assets/jss/custom-components/searchableStyle";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import LeftIcon from "@material-ui/icons/ArrowBack";
import RightIcon from "@material-ui/icons/ArrowForward";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import {STATUS_REJECTED} from "../../variables/coreConstants";



function SearchAssignmentComponent({ ...props }) {
    // let scrollLeft;
    // let scrollRight;
    //
    // if (navigator.platform.indexOf("Win") > -1) {
    //     scrollLeft = new PerfectScrollbar(scrollLeft, {
    //         suppressScrollX: true,
    //         suppressScrollY: false
    //     });
    //     scrollRight = new PerfectScrollbar(scrollRight, {
    //         suppressScrollX: true,
    //         suppressScrollY: false
    //     });
    // }

    const { classes, onSearch, onUpdateData, isLoading, data, assignedData, itemsAssignedFilter, selectedItems, removedItems, warningText, errorText, infoText } = props;
    const { t } = useTranslation();


    const _handleSearch = (e) => {
        try {
            if (e.target.value !== "") {
                onSearch(e.target.value);
            }
        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    const _handleSearchSelectedItems = (e) => {
        try {
            let newData = assignedData;
            newData = newData.filter((item) => {
                return (item.name !== null ? (item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false);
            });

            onUpdateData("assignedDataFilter", newData);

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    const _handleAddItem = () => {
        try {
            let newData = data;
            let assigned = assignedData;

            selectedItems.forEach(x => {
                let index = newData.findIndex(val => val.uuid === x.uuid);
                let exist = assigned.findIndex(val => val.uuid === x.uuid);

                if (exist !== -1) {
                    newData.splice(index, 1);
                } else {
                    if (index !== -1) {
                        x.isSelected = false;
                        assigned.push(x);
                        newData.splice(index, 1);
                    }
                }
            });


            onUpdateData("data", newData);
            onUpdateData("assignedData", assigned);
            onUpdateData("assignedDataFilter", assigned);
            onUpdateData("selectedItems", []);

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    const _handleRemoveItem = () => {
        try {
            let newData = assignedData;
            let removed = data;
            removedItems.forEach(x => {
                let index = newData.findIndex(val => val.uuid === x.uuid);
                let exist = removed.findIndex(val => val.uuid === x.uuid);

                if (exist !== -1) {
                    newData.splice(index, 1);
                } else {
                    if (index !== -1) {
                        x.isSelected = false;
                        removed.push(x);
                        newData.splice(index, 1);
                    }
                }

            });

            onUpdateData("data", removed);
            onUpdateData("assignedData", newData);
            onUpdateData("assignedDataFilter", newData);
            onUpdateData("removedItems", []);

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    const _handleSelectRightItem = (value) => {
        try {
            let _initialData = assignedData.slice();
            let _initialAssignedData = itemsAssignedFilter.slice();
            let _removed = removedItems.slice();

                let index = _initialData.findIndex((val => val.uuid === value.uuid));
                let indexFilter = _initialAssignedData.findIndex((val => val.uuid === value.uuid));
                let removedFilter = _removed.findIndex((val => val.uuid === value.uuid));

                if (index !== -1) {
                    if (indexFilter !== -1) {
                        if (value.isSelected) {
                            _removed.splice(removedFilter, 1);

                        } else {
                            _removed.push(value);
                        }

                        onUpdateData("removedItems", _removed);

                        _initialAssignedData[indexFilter].isSelected = !value.isSelected;
                        onUpdateData("assignedDataFilter", _initialAssignedData);
                    }
                }
        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    const _handleSelectLeftItem = (value) => {
        try {
            let _initialData = data.slice();
            let _assigned = selectedItems.slice();

            let initIndex = _initialData.findIndex((val => val.uuid === value.uuid));

            if (initIndex !== -1) {
                let index = _assigned.findIndex((val => val.uuid === value.uuid));

                if (index !== -1) {
                    if (value.isSelected) {
                        _assigned.splice(index, 1);
                    } else {
                        value.isSelected = false;
                        _assigned.push(value);
                    }


                    // console.log(_assigned);
                    onUpdateData("selectedItems", _assigned);
                    // console.log(selectedItems);
                    _initialData[initIndex].isSelected = !value.isSelected;
                    onUpdateData("data", _initialData);
                } else {
                    value.isSelected = false;
                    _assigned.push(value);
                    onUpdateData("selectedItems", _assigned);
                    _initialData[initIndex].isSelected = !value.isSelected;
                    onUpdateData("data", _initialData);
                }
            }

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    return (
        <div className={classes.container}>
            <div className={classes.mainContainer}>
                <div className={classes.containerHeader}>
                    <div className={classes.searchContainer}>
                        <Input
                            onChange={_handleSearch}
                            className={classes.search}
                            endAdornment={
                                <InputAdornment position="start" className={classes.adornmentContainer}>
                                    <SearchIcon className={classes.searchIconColor}/>
                                </InputAdornment>
                            }
                            placeholder={t('SEARCH_TEXT')}
                        />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.containerListDecoration}>
                        {
                            isLoading ? (
                                <div className={classes.loadingText}>
                                    {t('LOADING_TEXT')}
                                </div>
                            ) : (
                                <div>
                                    {
                                        data.map(val => (
                                            <Button
                                                onClick={() => _handleSelectLeftItem(val)}
                                                color={"inherit"}
                                                key={val.uuid}
                                                className={val.isSelected ? classes.itemCardSelected : classes.itemCard}>
                                                <div className={classes.itemContainer}>
                                                    <div className={classes.itemTextContainer}>
                                                        <p className={classes.itemText}>{val.name}</p>
                                                        <p className={classes.itemTextGray}>{val.externalId}</p>
                                                    </div>
                                                    <div className={classes.itemIconContainer}>
                                                        {
                                                            val.isAssigned && val.status === STATUS_REJECTED ? (
                                                                <Tooltip id="tooltip-icon" title={infoText} placement="right-start">
                                                                    <WarningIcon className={classes.iconYellow} />
                                                                </Tooltip>
                                                            ) : (val.status === STATUS_REJECTED ? (
                                                                <Tooltip id="tooltip-icon" title={errorText} placement="right-start">
                                                                    <WarningIcon className={classes.iconYellow} />
                                                                </Tooltip>
                                                            ) : (val.isAssigned ? (
                                                                <Tooltip id="tooltip-icon" title={warningText} placement="right-start">
                                                                    <WarningIcon className={classes.iconYellow} />
                                                                </Tooltip>
                                                            ) : null))
                                                        }
                                                    </div>
                                                </div>
                                            </Button>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={classes.assignmentActions}>
                <IconButton
                    size={"medium"}
                    onClick={() => _handleAddItem()}>
                    <RightIcon className={classes.actionIcons} />
                </IconButton>
                <IconButton
                    size={"medium"}
                    onClick={() => _handleRemoveItem()}>
                    <LeftIcon className={classes.actionIcons} />
                </IconButton>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.containerHeader}>
                    <div className={classes.searchContainer}>
                        <Input
                            onChange={_handleSearchSelectedItems}
                            className={classes.search}
                            endAdornment={
                                <InputAdornment position="start" className={classes.adornmentContainer}>
                                    <SearchIcon className={classes.searchIconColor}/>
                                </InputAdornment>
                            }
                            placeholder={t('SEARCH_TEXT')}
                        />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.containerListDecoration}>
                        {
                            itemsAssignedFilter.map(val => (
                                <Button
                                    onClick={() => _handleSelectRightItem(val)}
                                    color={"inherit"}
                                    key={val.uuid}
                                    className={val.isSelected ? classes.itemCardSelected : classes.itemCard}>
                                    <div className={classes.itemContainer}>
                                        <div className={classes.itemTextContainer}>
                                            <p className={classes.itemText}>{val.name}</p>
                                            <p className={classes.itemTextGray}>{val.externalId}</p>
                                        </div>
                                        <div className={classes.itemIconContainer}>
                                            {
                                                val.isAssigned ? (
                                                    <Tooltip id="tooltip-icon" title={warningText} placement="right-start">
                                                        <WarningIcon className={classes.iconYellow} />
                                                    </Tooltip>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </Button>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data && prevProps.assignedData === nextProps.assignedData
        && prevProps.selectedItems === nextProps.selectedItems && prevProps.removedItems === nextProps.removedItems
        && prevProps.itemsAssignedFilter === nextProps.itemsAssignedFilter && prevProps.isLoading === nextProps.isLoading;
}


SearchAssignmentComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    assignedData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    selectedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    removedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    itemsAssignedFilter: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onSearch: PropTypes.func.isRequired,
    onUpdateData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    warningText: PropTypes.string,
    errorText: PropTypes.string,
    infoText: PropTypes.string,
};

export default React.memo(withStyles(searchableStyle)(SearchAssignmentComponent), propsAreEqual);
