import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import AssetIcon from "@material-ui/icons/Memory";
import RangeIcon from "@material-ui/icons/TrackChanges";
import IdentifierIcon from "@material-ui/icons/Fingerprint";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
import assetDetails from "../../../models/AssetModel";
import {addAsset, editAsset, getAssetsTechnologies} from "../../../services/assetService";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    getUser, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation,
    validateFields
} from "../../../utils/helpersFunctions";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {withRouter} from "react-router-dom";
import {Hidden} from "@material-ui/core";
import {validateSession} from "../../../services/api";
import {withTranslation} from "react-i18next";


const animatedComponents = makeAnimated();

class AssetForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: [],
            assetIdentifier: assetDetails.identifier,
            assetName: assetDetails.name,
            technologyId: assetDetails.technologyId,
            technologyOptions: [],
            range: assetDetails.range,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveAsset = this.saveAsset.bind(this);
        this.updateAsset = this.updateAsset.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.setState({ user : getUser() });
        this.request();
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };

    changeTechnologySelect = technologyId => {
        this.setState({ technologyId });
    };

    cleanData() {
        this.setState({
            assetName: '',
            assetIdentifier: '',
            range: 0,
            technologyId: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openAsset", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {
        getAssetsTechnologies()
            .then((response) => {
                let values = response.data.map(item => {
                    return { value: item.id , label: item.name };
                });

                this.setState({
                    technologyOptions: values
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false} );

                if (error.response)
                    validateSession(error.response, this.props.history);
            });
    }


    saveAsset() {
        const { t } = this.props;
        let fields = [this.state.assetIdentifier, this.state.assetName];

        if (!validateFields(fields) || selectValidation(this.state.technologyId)) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let dataParameters = {
                "technology_id": this.state.technologyId.value,
                "asset_range": parseFloat(this.state.range),
                "name": this.state.assetName,
                "uid": this.state.assetIdentifier
            };

            disableComponent(this, "disableField");
            addAsset(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    updateAsset() {
        const { t } = this.props;

        if (this.state.assetName === "") {
            this.showNotification(t('ASSET_FIELDS_REQUIRED'), "danger", NotificationFailedIcon, null);

        } else {
            let dataParameters = {
                "asset_range": parseFloat(this.state.range),
                "name": this.state.assetName
            };

            disableComponent(this, "disableField");
            editAsset(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    render() {
        const { classes, t } = this.props;

        const UpdateAssetButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateAsset()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        const SaveAssetButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveAsset()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.assetName}
                            onChange={this.handleChangeValues}
                            labelText={t('ASSET_NAME_TEXT')}
                            id="assetName"
                            name="assetName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <AssetIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="technologyId" className={classes.selectTitle}>
                                {t('TH_TECHNOLOGY')}
                            </InputLabel>
                            <Select
                                value={this.state.technologyId}
                                onChange={this.changeTechnologySelect}
                                components={animatedComponents}
                                options={this.state.technologyOptions}
                                placeholder={t('SELECT_TEXT')}
                                isClearable={false}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.assetIdentifier}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_IDENTIFIER')}
                            id="assetIdentifier"
                            name="assetIdentifier"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IdentifierIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Hidden xsDown={true} xsUp={true}>
                            <CustomInput
                                value={this.state.range}
                                onChange={this.handleChangeValues}
                                labelText={t('TH_RANGE')}
                                id="range"
                                name="range"
                                type={"number"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <RangeIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </Hidden>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openAsset", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateAssetButton/> : <SaveAssetButton/>}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

AssetForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(AssetForm)));