import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {
    getRoundNumber,
    nullController,
    nullValidator,
    saveData,
    setCurrencyValue,
    verifyAccessImage,
} from "../../utils/helpersFunctions";
import { validateSession } from "../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import { withRouter } from "react-router-dom";
import { withNotification } from "../../components/HOC/Notification/NotificationHOC";
import { Backdrop, CircularProgress } from "@material-ui/core";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { getRevenueReconciliationDetails } from "../../services/blockDepositService";
import {
    DEPOSIT_ORDER,
    DEPOSIT_PAYMENT_REQUEST,
    NOTIFICATION_DURATION,
} from "../../variables/coreConstants";
import { statusText } from "../../utils/statusHelpers";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentDetailsForm from "../Payments/components/PaymentDetailsForm";
import { getPaymentDetails } from "../../services/paymentService";
import SimpleButton from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OrderDetailsForm from "../Orders/components/OrderDetailsForm";
import orderDetails from "../../models/OrderModel";
import { getOrderDetail } from "../../services/orderService";
import RevenueReconciliationForm from "./RevenueReconciliationForm";

class RevenueReconciliationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: props.match.params.id,
            // revenueReconciliationDetails: getData("revenueReconciliationDetails"),
            revenueReconciliationDetails: null,
            isLoading: false,
            paymentDetail: "",
            openPaymentDetail: false,
            orderDetail: [],
            isLoadingDetails: false,
            openOrder: false,
            isImageError: false,
        };
    }

    componentDidMount(): void {
        this.request();
    }

    request() {
        const { showNotificationMessage, t, history, match } = this.props;

        this.setState({ isLoading: true });

        getRevenueReconciliationDetails(match.params.id)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    revenueReconciliationDetails:
                        response.data !== null ? response.data : null,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                if (error.response) {
                    showNotificationMessage(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                    validateSession(error.response, history);
                } else {
                    showNotificationMessage(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    }

    closeForm = (state, value, type) => {
        this.setState(
            {
                [state]: value,
            },
            () => {
                this.setState({
                    paymentDetail: "",
                });
            }
        );

        if (type) {
            this.request();
        }
    };

    handleCloseDetailForm = () => {
        this.setState({ openPaymentDetail: false });
    };
    handleCloseForm = () => {
        this.setState({ openOrder: false });
    };

    handleGoDeposit = (id, waId) => {
        saveData("blockDeposit", { uuid: id });
        this.props.history.push("/admin/wallet-account/" + waId + "/deposit/" + id);

    };

    handleGoTransaction = (id) => {
        const { t, history, showNotificationMessage } = this.props;

        if (id !== null && id !== undefined && id !== "") {
            this.setState({ isLoadingDetails: true });
            getPaymentDetails(id)
                .then((response) => {
                    if (response.data) {
                        this.setState(
                            {
                                paymentDetail: response.data,
                                isLoadingDetails: false,
                            },
                            () => {
                                this.setState({
                                    openPaymentDetail: true,
                                });
                            }
                        );
                    } else {
                        this.setState({
                            isLoadingDetails: false,
                            openPaymentDetail: false,
                        });
                        showNotificationMessage(
                            t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            NOTIFICATION_DURATION,
                            null
                        );
                    }
                })
                .catch((error) => {
                    this.setState({
                        isLoadingDetails: false,
                        openPaymentDetail: false,
                    });

                    if (error.response) {
                        showNotificationMessage(
                            error.response.data.message
                                ? error.response.data.message
                                : t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            NOTIFICATION_DURATION,
                            null
                        );

                        validateSession(error.response, history);
                    }
                });
        } else {
            showNotificationMessage(
                t("SOMETHING_WENT_WRONG"),
                "danger",
                NotificationFailedIcon,
                NOTIFICATION_DURATION,
                null
            );
        }
    };

    openLink = (data) => {
        this.setState({ openPaymentDetail: false }, () => {
            saveData("paymentRequest", { uuid: data.paymentRequestId });
            window.open(
                "/admin/payment-request-details/" + data.paymentRequestId,
                "_blank"
            );
            saveData("paymentRequest", { uuid: data.paymentRequestId });

            // this.props.history.push("/admin/payment-request-details/" + data.paymentRequestId);
        });
    };

    openOrder = (id) => {
        const { t, showNotificationMessage } = this.props;

        this.setState({ isLoadingDetails: true });
        getOrderDetail(id)
            .then((response) => {
                if (response.data) {
                    this.setState({
                        orderDetail: response.data,
                        isLoadingDetails: false,
                    });

                    if (response.data !== null) {
                        orderDetails.setOrderDetail(response.data);
                        verifyAccessImage(response.data.photo, this);
                    }
                } else {
                    this.setState({
                        isLoadingDetails: false,
                        openOrder: false,
                    });
                    showNotificationMessage(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    isLoadingDetails: false,
                    openOrder: false,
                });

                if (error.response) {
                    showNotificationMessage(
                        error.response.data.message
                            ? error.response.data.message
                            : t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );

                    validateSession(error.response, this.props.history);
                }
            });
    };

    handleGoItem = (data) => {
        if (data !== null && data !== undefined) {
            if (data.type === DEPOSIT_PAYMENT_REQUEST) {
                saveData("paymentRequest", { uuid: data.paymentRequestId });
                // this.props.history.push("/admin/payment-request-details/" + data.paymentRequestId);

                window.open(
                    "/admin/payment-request-details/" + data.paymentRequestId,
                    "_blank"
                );
                saveData("paymentRequest", { uuid: data.paymentRequestId });
            } else if (data.type === DEPOSIT_ORDER) {
                this.openOrder(data.orderId);
            }
        }
    };

    handleGoMember = (id) => {
        saveData("member", { uuid: id });
        window.open("/admin/member-details/" + id, "_blank");
        saveData("member", { uuid: id });
        // this.props.history.push("/admin/member-details/" + id);
    };

    handleOpenRevenueForm = () => {
        this.setState({
            openForm: true,
        });
    };

    handleCloseRevenueForm = () => {
        this.setState({
            openForm: false,
        });
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            revenueReconciliationDetails,
            isLoading,
            isLoadingDetails,
            paymentDetail,
            orderDetail,
            isImageError,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3 className={classes.screenTitle}>
                        {t("RECONCILIATION_DETAILS_TXT")}
                    </h3>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridNoTop}
                >
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <p className={classes.cardTitleGray}>
                                        {t("PAYMENT_DETAILS_TXT")}
                                    </p>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_NAME")}
                                            </p>
                                            {nullValidator(
                                                revenueReconciliationDetails,
                                                "name"
                                            ) &&
                                            revenueReconciliationDetails.name !==
                                                "" ? (
                                                <SimpleButton
                                                    className={
                                                        classes.btnCardRowTxtBlue
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        this.handleGoItem(
                                                            revenueReconciliationDetails
                                                        )
                                                    }
                                                >
                                                    {
                                                        revenueReconciliationDetails.name
                                                    }
                                                </SimpleButton>
                                            ) : null}
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_CUSTOMER")}
                                            </p>
                                            {nullValidator(
                                                revenueReconciliationDetails,
                                                "memberId"
                                            ) &&
                                            revenueReconciliationDetails.memberId !==
                                                "" ? (
                                                <SimpleButton
                                                    className={
                                                        classes.btnCardRowTxtBlue
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        this.handleGoMember(
                                                            revenueReconciliationDetails.memberId
                                                        )
                                                    }
                                                >
                                                    {
                                                        revenueReconciliationDetails.customerReference
                                                    }
                                                </SimpleButton>
                                            ) : (
                                                <p
                                                    className={
                                                        classes.cardRowTxtBlue
                                                    }
                                                >
                                                    {nullController(
                                                        revenueReconciliationDetails,
                                                        "customerReference"
                                                    )}
                                                </p>
                                            )}
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_TRANSACTION")}
                                            </p>
                                            {nullValidator(
                                                revenueReconciliationDetails,
                                                "transactionId"
                                            ) &&
                                            revenueReconciliationDetails.transactionId !==
                                                "" ? (
                                                <SimpleButton
                                                    className={
                                                        classes.btnCardRowTxtBlue
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        this.handleGoTransaction(
                                                            revenueReconciliationDetails.transactionId
                                                        )
                                                    }
                                                >
                                                    {
                                                        revenueReconciliationDetails.transactionId
                                                    }
                                                </SimpleButton>
                                            ) : (
                                                <p
                                                    className={
                                                        classes.cardRowTxtBlue
                                                    }
                                                >
                                                    {nullController(
                                                        revenueReconciliationDetails,
                                                        "transactionId"
                                                    )}
                                                </p>
                                            )}
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_PAYMENT_DATE")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "paymentDate"
                                                )}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <div className={classes.cardRowSeparator} />
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_PAYMENT_METHOD")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "paymentMethod"
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_TRANSACTION_STATUS")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {t(
                                                    statusText[
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "transactionStatus"
                                                        )
                                                    ]
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_CREATED_BY")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "paymentRequestCreatedBy"
                                                )}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridNoTop}
                >
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <p className={classes.cardTitleGray}>
                                        {t("PAYMENT_RECONCILIATION_TXT")}
                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <div className={classes.rightPaper}>
                                        <SimpleButton
                                            size="small"
                                            color="primary"
                                            className={classes.btnColorPrimary}
                                            onClick={this.handleOpenRevenueForm}
                                        >
                                            {t("EDIT_TEXT")}
                                        </SimpleButton>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {/*{*/}
                                    {/*    isLoading ? (*/}
                                    {/*        <Box sx={{ pt: 0.5 }}>*/}
                                    {/*            <Skeleton />*/}
                                    {/*            <Skeleton width="60%" />*/}
                                    {/*        </Box>*/}
                                    {/*    ) : (*/}

                                    {/*    )*/}
                                    {/*}*/}
                                    <GridContainer>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_CUSTOMER_REFERENCE")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "memberReference"
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_INVOICE_REFERENCE")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "invoiceId"
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_ERP_SYNC_DATE")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {nullController(
                                                    revenueReconciliationDetails,
                                                    "erpSyncedDate"
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_DEPOSIT")}
                                            </p>
                                            {nullValidator(
                                                revenueReconciliationDetails,
                                                "depositId"
                                            ) &&
                                            revenueReconciliationDetails.depositId !==
                                                "" ? (
                                                <SimpleButton
                                                    className={
                                                        classes.btnCardRowTxtBlue
                                                    }
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        this.handleGoDeposit(
                                                            revenueReconciliationDetails.depositId,
                                                            revenueReconciliationDetails.walletAccountId
                                                        )
                                                    }
                                                >
                                                    {
                                                        revenueReconciliationDetails.depositLabel
                                                    }
                                                </SimpleButton>
                                            ) : (
                                                <p
                                                    className={
                                                        classes.cardRowTxtBlue
                                                    }
                                                >
                                                    {nullController(
                                                        revenueReconciliationDetails,
                                                        "depositLabel"
                                                    )}
                                                </p>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <div className={classes.cardRowSeparator} />
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("ORIGINAL_AMOUNT")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) +
                                                    setCurrencyValue(
                                                        getRoundNumber(
                                                            nullController(
                                                                revenueReconciliationDetails,
                                                                "originalAmount"
                                                            )
                                                        )
                                                    )
                                                ).replace(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) + "-",
                                                    "- " +
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "currencySymbol"
                                                        )
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_FEES")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) +
                                                    setCurrencyValue(
                                                        getRoundNumber(
                                                            nullController(
                                                                revenueReconciliationDetails,
                                                                "fee"
                                                            )
                                                        )
                                                    )
                                                ).replace(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) + "-",
                                                    "- " +
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "currencySymbol"
                                                        )
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("TH_TAXES")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) +
                                                    setCurrencyValue(
                                                        getRoundNumber(
                                                            nullController(
                                                                revenueReconciliationDetails,
                                                                "tax"
                                                            )
                                                        )
                                                    )
                                                ).replace(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) + "-",
                                                    "- " +
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "currencySymbol"
                                                        )
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {nullValidator(
                                                    revenueReconciliationDetails,
                                                    "depositId"
                                                ) &&
                                                revenueReconciliationDetails.depositId !==
                                                    ""
                                                    ? t("TH_DEPOSIT_AMOUNT")
                                                    : t(
                                                          "TH_NO_DEPOSITED_AMOUNT"
                                                      )}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) +
                                                    setCurrencyValue(
                                                        getRoundNumber(
                                                            nullController(
                                                                revenueReconciliationDetails,
                                                                "netAmount"
                                                            )
                                                        )
                                                    )
                                                ).replace(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) + "-",
                                                    "- " +
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "currencySymbol"
                                                        )
                                                )}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <div className={classes.cardRowSeparator} />
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={3} sm={3} md={3} lg={3}>
                                            <p
                                                className={
                                                    classes.cardThTxtGray
                                                }
                                            >
                                                {t("3DS_FEE")}
                                            </p>
                                            <p
                                                className={
                                                    classes.cardRowTxtBlack
                                                }
                                            >
                                                {(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) +
                                                    setCurrencyValue(
                                                        getRoundNumber(
                                                            nullController(
                                                                revenueReconciliationDetails,
                                                                "fee3DS"
                                                            )
                                                        )
                                                    )
                                                ).replace(
                                                    nullController(
                                                        revenueReconciliationDetails,
                                                        "currencySymbol"
                                                    ) + "-",
                                                    "- " +
                                                        nullController(
                                                            revenueReconciliationDetails,
                                                            "currencySymbol"
                                                        )
                                                )}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <Backdrop className={classes.backdrop} open={isLoadingDetails}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openForm}
                    onClose={this.handleCloseRevenueForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("FORM_REVENUE_RECONCILIATION_TITLE")}
                    </DialogTitle>
                    <DialogContent>
                        <RevenueReconciliationForm
                            revenueData={
                                this.state.revenueReconciliationDetails
                            }
                            closeForm={this.closeForm}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openPaymentDetail}
                    aria-labelledby="form-dialog-title"
                    style={{ marginBottom: 0, paddingTop: "0px !important" }}
                >
                    <DialogTitle id="form-dialog-title">
                        {t("PAYMENT_DETAILS_TEXT")}
                    </DialogTitle>
                    <DialogContent>
                        <PaymentDetailsForm
                            data={paymentDetail}
                            closeForm={this.closeForm}
                            openLink={this.openLink}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openOrder}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        <div className={classes.orderDetailActions}>
                            <div className={classes.actionCloseContainer}>
                                <Tooltip
                                    id="tooltip-close"
                                    title={t("BTN_CLOSE")}
                                    placement="right-start"
                                >
                                    <IconButton
                                        size={"small"}
                                        onClick={this.handleCloseForm}
                                        className={classes.formIcons}
                                    >
                                        <CloseIcon
                                            className={classes.iconButtonRed}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div
                                className={classes.orderDetailIdentifierDialog}
                            >
                                {"#" + orderDetail.identifier}
                            </div>
                            <div className={classes.actionPrintContainer}>
                                <div
                                    className={
                                        classes.rightPaper +
                                        " " +
                                        classes.printContainer
                                    }
                                />
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <OrderDetailsForm
                            data={orderDetail}
                            isImageError={isImageError}
                            closeForm={this.closeForm}
                        />
                    </DialogContent>
                </Dialog>
            </GridContainer>
        );
    }
}

RevenueReconciliationDetails.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(dashboardStyle)(
        withNotification(withTranslation()(RevenueReconciliationDetails))
    )
);
