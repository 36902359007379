import React from "react";
import {withTranslation} from "react-i18next";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../../components/CustomButtons/Button";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {initialMomentOfDay, nowMomentDate} from "../../../utils/helpersFunctions";


class CustomDateFilter extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            startTime: initialMomentOfDay(),
            endTime: nowMomentDate(),
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleStartTimeChange = (date) => {
        date = date > this.state.endTime ? initialMomentOfDay() : date;
        this.setState({ startTime: date });
    };

    handleEndTimeChange = (date) => {
        date = date < this.state.startTime ? this.state.startTime : date;
        this.setState({ endTime: date } );
    };

    addFilter = () => {
        this.props.closeForm(true, this.state);
    };


    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.scheduleContainerFilter}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('START_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.startTime}
                                onChange={this.handleStartTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('END_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.endTime}
                                onChange={this.handleEndTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
                <div className={classes.footerForm}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.buttonContainer}>
                                <div className={classes.rightPaper}>
                                    <Button
                                        color="white"
                                        className={classes.buttonSpacing + " " + classes.cancelButton}
                                        onClick={() => this.props.closeForm(false, null)}
                                    >
                                        {t('BTN_CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        className={classes.buttonSpacing}
                                        onClick={() => this.addFilter()}
                                    >
                                        {t('BTN_CONFIRM')}
                                    </Button>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

CustomDateFilter.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(CustomDateFilter)));
