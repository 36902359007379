import React from "react";
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';
import buttonStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "./Button";
import {primaryColor, whiteColor} from "../../assets/jss/material-dashboard-react";

function LoadingButton({ ...props }) {
    const {
        classes,
        color,
        disabled,
        isLoading,
        fullWidth,
        text,
        onPress,
        className,
        ...rest
    } = props;

    return(
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Button
                    style={{color: isLoading ? primaryColor[0] : whiteColor}}
                    variant="contained"
                    color={color}
                    disabled={disabled}
                    onClick={onPress}
                    className={className}
                    {...rest}
                >
                    {text}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </div>
    );
}


LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "transparent"
    ]),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    onPress: PropTypes.func,
    className: PropTypes.string,
};

export default withStyles(buttonStyle)(LoadingButton);
