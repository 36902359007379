import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {withRouter} from "react-router-dom";
import {
    DELETE,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE_DETAILS,
} from "../../../variables/coreConstants";
import {disableComponent, enableComponent, isSuccessfulRequest} from "../../../utils/helpersFunctions";
import {
    generateApiKey,
    getCompanyDevelopmentApiKeys
} from "../../../services/accountService";
import {validateSession} from "../../../services/api";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {statusController} from "../../../services/statusService";
import {BASE_URL_CORE, COMPANY_API_KEY} from "../../../variables/apiConstants";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class DeveloperApiKeys extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            disableField: false,
            showApiKey: false,
            showSecretKey: false,
            data: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.onGenerateApiKey = this.onGenerateApiKey.bind(this);
    }


    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.request();
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleShowApiKey = () => {
        this.setState(prevState => ({
            showApiKey: !prevState.showApiKey
        }));
    };
    handleShowSecretKey = () => {
        this.setState(prevState => ({
            showSecretKey: !prevState.showSecretKey
        }));
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: + event.target.value,
            page: 0
        });
    };


    openForm = (action, formData) => {
        if (action === DELETE) {
            let dataParameters = {
                "companyApiSettingId": formData.id,
            };

            statusController(action, BASE_URL_CORE, COMPANY_API_KEY, dataParameters, this, true);
        }
    };



    request() {
        this.setState({ isLoading: true });
        getCompanyDevelopmentApiKeys()
            .then((res) => {
                this.setState({ data: res.data.length ? res.data : [], isLoading: false});
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    onGenerateApiKey() {
        disableComponent(this, "disableField");
        generateApiKey()
            .then((response) => {
                if (isSuccessfulRequest) {
                    this.showNotification(response.data.message, "primary", NotificationErrorIcon, true);
                } else {
                    this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                }
                enableComponent(this, "disableField");
            })
            .catch((error) => {
                enableComponent(this, "disableField");
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }



    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage, disableField, isLoading, data } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'developer_settings'}
                        title={t('DEVELOPER_API_KEYS_TEXT')}
                        exportFileName={t('DEVELOPER_API_KEYS_TEXT')}
                        data={data}
                        cellSelectedEnabled={true}
                        actions={[
                            { label: t('BTN_GENERATE'), function: this.onGenerateApiKey, isLoading: false, disabled: disableField || (this.state.data.length && this.state.data.length >= 1), type: "simple" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_API_KEY'), name: "apiKey", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_SECRET_KEY'), name: "secretKey", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

DeveloperApiKeys.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DeveloperApiKeys)));
