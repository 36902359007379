import { getService, postService, putService } from "./api";
import {
    BASE_URL_CORE,
    ZONES,
    ZONES_BY_STORE,
    ZONES_BY_COMPANY,
    ZONE,
    ZONES_BY_APPLICATION,
    ZONE_ASSETS_APPS,
    ZONES_AVAILABLE_BY_STORE,
    ZONES_GROUPS_ASSIGNMENTS,
    ZONES_GROUPS_BY_COMPANY,
    ZONE_GROUP, ZONES_GROUPS_BY_STORE
} from "../variables/apiConstants";
import zoneDetails from "../models/ZoneModel";


export function getZones() {
    let url = BASE_URL_CORE + ZONES;
    return getService(url);
}


export function getZonesByCompany() {
    let url = BASE_URL_CORE + ZONES_BY_COMPANY;
    return getService(url);
}


export function getZonesByStore(parameters) {
    let url = BASE_URL_CORE + ZONES_BY_STORE;
    return postService(url, parameters);
}


export function getAvailableZonesByStore(parameters) {
    let url = BASE_URL_CORE + ZONES_AVAILABLE_BY_STORE;
    return postService(url, parameters);
}


export function getZonesByApplication(appId) {
    let url = BASE_URL_CORE + ZONES_BY_APPLICATION;
    let parameters = {
        "app_id": appId
    };

    return postService(url, parameters);
}


export function getAssetsApplicationsByZone() {
    let url = BASE_URL_CORE + ZONE_ASSETS_APPS;
    let parameters = {
        "zone_id": zoneDetails.id
    };

    return postService(url, parameters);
}


export function addZone(parameters) {
    let url = BASE_URL_CORE + ZONE;
    return postService(url, parameters);
}


export function editZone(parameters) {
    let url = BASE_URL_CORE + ZONE + "/" + zoneDetails.id;
    return putService(url, parameters);
}


export function getAvailableGroupsByZone(zoneId) {
    let url = BASE_URL_CORE + ZONES_GROUPS_ASSIGNMENTS + zoneId;
    return getService(url);
}


export function getZoneGroupsByStore(storeId) {
    let url = BASE_URL_CORE + ZONES_GROUPS_BY_STORE + storeId;

    return getService(url);
}


export function getAllZoneGroupsByCompany() {
    let url = BASE_URL_CORE + ZONES_GROUPS_BY_COMPANY;

    return getService(url);
}


export function addZoneGroupByStore(parameters) {
    let url = BASE_URL_CORE + ZONE_GROUP;

    return postService(url, parameters);
}

export function editZoneGroupByStore(parameters) {
    let url = BASE_URL_CORE + ZONE_GROUP;

    return putService(url, parameters);
}
