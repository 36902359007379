import axios from 'axios';
import {getData, getLanguage} from "../utils/helpersFunctions";
import {getSignature} from "./api";

let cancel;

export async function searchService(url, parameters) {
    const signature = await getSignature(url, parameters, getData("userTk"));

    if (cancel) {
        // Cancel the previous request before making a new request
        cancel.cancel();
    }

    // Create a new CancelToken
    cancel = axios.CancelToken.source();

    return axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'Authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature
        },
        cancelToken: cancel.token
    });
}