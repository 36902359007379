import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Button from "../../../components/CustomButtons/Button";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {BASE64_HEADER, DATA_TYPE_SUPPORTED, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from '@material-ui/core/Typography';
import 'react-image-crop/dist/ReactCrop.css';
import Muted from "../../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ImageSelector from "./ImageSelector";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {addStoreImages} from "../../../services/storeService";
import {disableComponent, enableComponent, isSuccessfulRequest} from "../../../utils/helpersFunctions";
import storeDetails from "../../../models/StoreModel";
import {validateSession} from "../../../services/api";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";


class StoreImageUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: storeDetails.logo,
            header: storeDetails.headerImage,
            crop: {
                unit: 'px',
                width: 0,
                height: 0,
                aspect: 4 / 3,
            },
            imageType: 'logo',
            openImage: false,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.cleanData = this.cleanData.bind(this);
        this.saveResources = this.saveResources.bind(this);
    }


    componentDidMount() {
    }



    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData() {
        this.setState({
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        });
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openStore", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    }

    handleDeleteLogo = () => {
        this.setState({
            logo: null
        });
    };

    handleDeleteHeader = () => {
        this.setState({
            header: null
        });
    };


    handleCompleteForm = () => {
        this.setState({openImage: false});
    };
    handleCloseForm = () => {
        this.setState({openImage: false});
    };
    handleOpenForm = () => {
        this.setState({openImage: true});
    };


    handleSelectLogo = () => {
        this.setState({
            crop: {
                unit: 'px',
                width: 250,
                height: 250,
                aspect: 1,
            },
            imageType: 'logo',
        }, () => {
           this.handleOpenForm();
        });

    };

    handleSelectHeader = () => {
        this.setState({
            crop: {
                unit: 'px',
                width: 812,
                height: 312,
                aspect: 2.63 / 1,
            },
            imageType: 'header',
        }, () => {
            this.handleOpenForm();
        });
    };


    saveResources() {
        const { t } = this.props;

        if (this.state.logo === '' && this.state.header === '') {
            this.showNotification(t('IMAGE_REQUIREMENT_SIZE_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let logoBase64Data = "";
            if (this.state.logo !== null) {
                if (this.state.logo.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
                    logoBase64Data = this.state.logo.slice(BASE64_HEADER.length);

                } else {
                    logoBase64Data = this.state.logo;
                }
            }

            let headerBase64Data = "";
            if (this.state.header !== null) {
                if (this.state.header.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
                    headerBase64Data = this.state.header.slice(BASE64_HEADER.length);

                } else {
                    headerBase64Data = this.state.header;
                }
            }


            let dataParameters = {
                "store_id": storeDetails.id,
                "logo": logoBase64Data,
                "header_image": headerBase64Data
            };


            disableComponent(this, "disableField");
            addStoreImages(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                        if (!this.props.action)
                            this.props.history.push('quick-actions-store');

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    if (error.response)
                        validateSession(error.response, this.props.history)
                });
        }
    }


    goToStore = () => {
        if (!this.props.action) {
            this.props.closeForm("openStore", false, null);
            this.props.history.push("quick-actions-store");

        } else {
            this.props.closeForm("openStore", false, true);
        }
    };

    freezeButton = () => {
        return (!this.props.action && ((this.state.header === null || this.state.header === "") && (this.state.logo === null || this.state.logo === "")))
            || (this.props.action && ((storeDetails.headerImage === null || storeDetails.headerImage ==="") && (this.state.header === null || this.state.header === ""))
            && ((storeDetails.logo === null || storeDetails.logo ==="") && (this.state.logo === null || this.state.logo === "")));
    };



    render() {
        const { classes, t } = this.props;
        const { logo, header, crop, imageType } = this.state;


        const SaveImagesButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveResources()}
                disabled={this.state.disableField || this.freezeButton()}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        return (
            <div style={{flex:1}}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <h4 className={classes.descriptionTitle}>
                            {t('LOGO_TEXT')}
                            <Muted>
                                {t('LOGO_DESCRIPTION_TEXT')}
                            </Muted>
                        </h4>
                        <Tooltip id="tooltip-edit" title={logo === null ? t('SELECT_IMAGE_TEXT') : t('CHANGE_IMAGE_TEXT')} placement="bottom-start">
                            <ButtonBase
                                onClick={this.handleSelectLogo}
                                focusRipple
                                key={1}
                                className={classes.btnImage}
                                style={{height: 150, width: 150,}}
                                focusVisibleClassName={classes.focusVisible}
                            >
                                <span
                                    className={classes.imageSrcContainer}
                                    style={{backgroundImage: `url(${logo})`}} />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                    <Typography
                                        component="span"
                                        variant="subtitle2"
                                        color="inherit"
                                        className={classes.imageTitle}>
                                        {t('SELECT_LOGO_TEXT')}
                                    </Typography>
                                </span>
                            </ButtonBase>
                        </Tooltip>
                        {
                            logo !== null ?
                                <Button
                                    color={"danger"}
                                    className={classes.buttonFit}
                                    onClick={this.handleDeleteLogo}>
                                    <ClearIcon />
                                    {t('BTN_REMOVE')}
                                </Button>
                                : null
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                        <h4 className={classes.descriptionTitle}>
                            {t('HEADER_TEXT')}
                            <Muted>
                                {t('HEADER_DESCRIPTION_TEXT')}
                            </Muted>
                        </h4>
                        <Tooltip id="tooltip-edit" title={header === null ? t('SELECT_IMAGE_TEXT') : t('CHANGE_IMAGE_TEXT')} placement="bottom-end">
                            <ButtonBase
                                onClick={this.handleSelectHeader}
                                focusRipple
                                key={1}
                                className={classes.btnImage}
                                style={{height: 112, width: 512,}}
                                focusVisibleClassName={classes.focusVisible}
                            >
                                <span
                                    className={classes.imageSrcContainer}
                                    style={{backgroundImage: `url(${header})`}} />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                    <Typography
                                        component="span"
                                        variant="subtitle2"
                                        color="inherit"
                                        className={classes.imageTitle}>
                                        {t('SELECT_HEADER_TEXT')}
                                    </Typography>
                                </span>
                            </ButtonBase>
                        </Tooltip>
                        {
                            header !== null ?
                                <div className={classes.centerPaper}>
                                    <Button
                                        color={"danger"}
                                        className={classes.buttonFit}
                                        onClick={this.handleDeleteHeader}>
                                        <ClearIcon />
                                        {t('BTN_REMOVE')}
                                    </Button>
                                </div>
                                : null
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.goToStore() }
                            >
                                {t('BTN_LATER')}
                            </Button>
                            <SaveImagesButton />
                        </div>
                    </GridItem>
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openImage}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_IMAGE_TEXT')}
                        </DialogTitle>
                        <DialogContent>
                            <ImageSelector crop={crop} component={this} type={imageType}/>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

StoreImageUpload.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(StoreImageUpload)));