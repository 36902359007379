import { getService, postService, putService } from "./api";
import { BASE_URL_CORE, ROLE, ROLES, ROLES_BY_COMPANY } from "../variables/apiConstants";
import roleDetails from "../models/RoleModel";

export function getRoles() {
    let url = BASE_URL_CORE + ROLES;
    return getService(url);
}

export function getRolesByCompany() {
    let url = BASE_URL_CORE + ROLES_BY_COMPANY;
    return getService(url);
}

export function addRole(parameters) {
    let url = BASE_URL_CORE + ROLE;
    return postService(url, parameters);
}

export function editRole(parameters) {
    let url = BASE_URL_CORE + ROLE + "/" + roleDetails.id;
    return putService(url, parameters);
}


