import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Styles
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import formStyle from "../../../assets/jss/custom-components/formStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {enableComponent} from "../../../utils/helpersFunctions";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {DATA_TYPE_SUPPORTED, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import InputUpload from "../../../components/CustomUpload/InputUpload";


class ImageSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: '',
            src: null,
            crop: this.props.crop,
            disableField: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }


    componentDidMount() {
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        const { t } = this.props;
        if ((this.props.crop.width > this.imageRef.width ) || (this.props.crop.height > this.imageRef.height )) {
            this.showNotification(
                t('INVALID_SIZE_IMAGE_MSG'), "danger", NotificationErrorIcon
            );

        } else {
            if (this.imageRef && crop.width && crop.height) {
                const croppedImageUrl = await this.getCroppedImg(
                    this.imageRef,
                    crop,
                    'image.jpeg'
                );

                this.setState({ croppedImageUrl });
                this.setState({ image: croppedImageUrl, });
                enableComponent(this, "disableField")
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        /*return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });*/

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL('image/jpeg', 1));
        });
    }


    handleSelectImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (reader.result.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
                    this.setState({ src: reader.result })
                } else {
                    this.showNotification("The selected image is invalid.",
                        "danger",
                        NotificationErrorIcon
                    );
                }
            });

            reader.readAsDataURL(e.target.files[0]);
        }
    };


    saveImage() {

        if (this.state.image.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
            switch (this.props.type) {
                case "header":
                    this.props.component.setState({
                        header: this.state.image,
                    }, () => this.props.component.handleCompleteForm());
                    break;

                case "logo":
                    this.props.component.setState({
                        logo: this.state.image,
                    }, () => this.props.component.handleCompleteForm());
                    break;

                case "profile":
                    this.props.component.setState({
                        profile: this.state.image,
                    }, () => this.props.component.handleCompleteForm());
                    break;

                default:
                    break;
            }
        } else {
            this.showNotification("The selected image isn't supported.",
                "danger",
                NotificationErrorIcon
            );
        }
    }


    render() {
        const { classes, type, t } = this.props;
        const { src, crop } = this.state;

        return (
            <div className={classes.imageEditorContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.inputFile}>
                            <InputUpload handleFile={e => this.handleSelectImage(e)} label={t('BTN_SELECT')} color={"primary"}/>
                        </div>
                        <div style={type === 'logo' ? {maxWidth: 500, maxHeight: 500} : {}}>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                maxHeight={type === 'logo' ? 250 : 0}
                                maxWidth={type === 'logo' ? 250 : 0}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                ruleOfThirds
                                locked={false}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.component.handleCloseForm()}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <Button
                                color="primary"
                                className={classes.buttonSpacing}
                                onClick={() => this.saveImage()}
                                disabled={this.state.disableField}
                            >
                                {t('BTN_FINISH')}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

ImageSelector.propTypes = {
    classes: PropTypes.object,
    crop: PropTypes.object,
    component: PropTypes.object.isRequired,
    minHeight: PropTypes.number,
    minWidth: PropTypes.number,
    type: PropTypes.oneOf([
        "logo",
        "header",
        "profile"
    ])
};

export default withRouter(withStyles(formStyle)(withTranslation()(ImageSelector)));