import { getService, postService, putService } from "./api";
import {
    BASE_URL_CORE,
    COMPANY_MEMBER_CONFIGURATIONS, DEFAULT_MEMBER_AUTH_SOURCE, MEMBER_AUTH_CONFIGURATION,
    MEMBER_AUTH_SOURCES,
    MEMBER_INTEGRATIONS, UPDATE_MEMBER_AUTH_CONFIGURATION
} from "../variables/apiConstants";

export function getMembershipAuthByCompany() {
    let url = BASE_URL_CORE + COMPANY_MEMBER_CONFIGURATIONS;

    return getService(url);
}

export function getMemberAuthSources() {
    let url = BASE_URL_CORE + MEMBER_AUTH_SOURCES;

    return getService(url);
}

export function getMemberIntegrationsByCompany() {
    let url = BASE_URL_CORE + MEMBER_INTEGRATIONS;

    return getService(url);
}

export function addMemberAuthConfiguration(parameters) {
    let url = BASE_URL_CORE + MEMBER_AUTH_CONFIGURATION;

    return postService(url, parameters);
}

export function editMemberAuthConfiguration(parameters) {
    let url = BASE_URL_CORE + UPDATE_MEMBER_AUTH_CONFIGURATION;

    return putService(url, parameters);
}

export function setDefaultMemberAuthSource(id) {
    let url = BASE_URL_CORE + DEFAULT_MEMBER_AUTH_SOURCE;
    let dataParameters = { "memberAuthSourceId": id };

    return putService(url, dataParameters);
}