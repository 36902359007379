import React from "react";
import {withTranslation} from "react-i18next";
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../../../components/CustomButtons/Button";
import {
    NOTIFICATION_DURATION
} from "../../../../variables/coreConstants";
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import * as moment from "moment";
import {
    formatDate,
    formatDatetimeToDate,
    initialMomentOfDay,
    nowMomentDate,
    objectSizeValidator
} from "../../../../utils/helpersFunctions";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationInfoIcon from "@material-ui/icons/Info";
import {validateSession} from "../../../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {generateTransferSummaryCsv} from "../../../../services/transferService";


class ExportTransfersCsvForm extends React.Component{

    csvLink = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            startTime: initialMomentOfDay(),
            endTime: nowMomentDate(),
            data: [],
            headers: [
                { label: props.t('DATE_HEADER'), key: "date" },
                { label: props.t('DESCRIPTION_HEADER'), key: "description" },
                { label: props.t('TYPE_HEADER'), key: "type" },
                { label: props.t('CURRENCY_HEADER'), key: "currencySymbol" },
                { label: props.t('GROSS_HEADER'), key: "gross" },
                { label: props.t('COMISION_HEADER'), key: "fee" },
                { label: props.t('TH_TAXES'), key: "tax" },
                { label: props.t('CREDIT_HEADER'), key: "credit" },
                { label: props.t('DEBIT_HEADER'), key: "debit" },
                { label: props.t('BALANCE_HEADER'), key: "balance" }
            ],
            reportName: "",
            dateType: 0,
            csvDownloadRef: null,
            isLoading: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleStartTimeChange = (date) => {
        date = date > this.state.endTime ? initialMomentOfDay() : date;
        this.setState({ startTime: date });
    };

    handleEndTimeChange = (date) => {
        const currentDate = moment();
        date = date > currentDate ? currentDate : (date < this.state.startTime ? this.state.startTime : date);
        this.setState({ endTime: date } );
    };

    onRefChange = node => {
        this.setState({ csvDownloadRef: node });
    };


    onGenerateCsv = () => {
        this.setState({ isLoading: true }, () => {
            let dataParameters = {
                "initDate": formatDate(this.state.startTime.valueOf()),
                "endDate":formatDate(this.state.endTime.valueOf()),
                "walletAccountId": this.props.walletAccountId
            };

            generateTransferSummaryCsv(dataParameters)
                .then(response => {
                    if (objectSizeValidator(response.data, 0)) {
                        this.setState({
                            data: response.data,
                            reportName: this.props.t('CSV_FILE_NAME_TRANSFERS_SUMMARY',
                                {
                                    start: formatDatetimeToDate(this.state.startTime.valueOf()),
                                    end: formatDatetimeToDate(this.state.endTime.valueOf())
                                }),
                        }, () => {
                            // Wait to set ref again
                            window.setTimeout(
                                function() {
                                    if (this.csvLink !== null && this.csvLink !== undefined) {
                                        this.csvLink.link.click();
                                        this.setState({ isLoading: false });
                                    }
                                }.bind(this),
                                1000
                            );
                        });
                    } else {
                        this.setState({ isLoading: false, reportName: "" });
                        this.showNotification(this.props.t('NO_RECORDS_TXT'), "warning", NotificationInfoIcon, null);

                    }
                })
                .catch(e => {
                    this.setState({ isLoading: false, reportName: "" });
                    if (e.response) {
                        this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(e.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationErrorIcon, null);
                    }
                });
        });
    };


    render() {
        const { classes, t, closeForm } = this.props;
        const { isLoading, data, hideNotification, color, icon, notification, notificationMessage, startTime, endTime,
            reportName, headers } = this.state;

        return (
            <div className={classes.scheduleContainer}>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('START_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={startTime}
                                onChange={this.handleStartTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('END_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={endTime}
                                onChange={this.handleEndTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerButtonsColumn}>
                            <Button
                                disabled={isLoading}
                                color="white"
                                onClick={this.onGenerateCsv}
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                            >
                                {t('BTN_GENERATE_FILE')}
                            </Button>
                            <div className={classes.hidden}>
                                <CSVLink
                                    data={data}
                                    headers={headers}
                                    separator={","}
                                    className="hidden"
                                    ref={(r) => this.csvLink = r}
                                    filename={reportName}>
                                    {t('BTN_GENERATE_FILE')}
                                </CSVLink>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.buttonContainer}>
                            <div className={classes.rightPaper}>
                                <Button
                                    disabled={isLoading}
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => closeForm()}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    color="primary"
                                    className={classes.buttonSpacing}
                                    onClick={() => closeForm()}
                                >
                                    {t('BTN_FINISH')}
                                </Button>
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={color}
                        icon={icon}
                        message={notificationMessage}
                        open={notification}
                        closeNotification={hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ExportTransfersCsvForm.propTypes = {
    classes: PropTypes.object,
    walletAccountId: PropTypes.string
};

export default withRouter(withStyles(formStyle)(withTranslation()(ExportTransfersCsvForm)));
