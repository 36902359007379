var directDebitDetails = {
    id: null,
    jobId: null,
    currency: "",
    name: "",
    totalPayments: 0,
    totalAmount: "0.00",

    setDebitDetails : function (values) {
        try {
            this.id = values.id;
            this.jobId = values.jobId;
            this.name = values.name;
            this.totalAmount = values.amount;
            this.totalPayments = values.totalPayments;
            this.currency = values.currencySymbol;
        } catch (e) {
            console.log("Something went wrong");
        }
    },

    cleanDebitDetails : function () {
        this.id = null;
        this.jobId = null;
        this.name = "";
        this.totalPayments = 0;
        this.totalAmount = "0.00";
        this.currency = "";
    }

};

export default directDebitDetails;