import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    MEMBER_AUTH_SOURCE_DOMAIN,
    MEMBERSHIP_AUTH_DEFAULT_TABLE_ACTIONS,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS, SET_DEFAULT
} from "../../../variables/coreConstants";
import TableBody from "@material-ui/core/TableBody";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CustomButton from "../../../components/CustomButtons/Button";
import {getMembershipAuthByCompany, setDefaultMemberAuthSource} from "../../../services/memberAuthService";
import {validateSession} from "../../../services/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {formTitles} from "../../../utils/statusHelpers";
import DialogContent from "@material-ui/core/DialogContent";
import MemberAuthConfigForm from "./MemberAuthConfigForm";
import {getStringTextFormatByName, isSuccessfulRequest, nullController} from "../../../utils/helpersFunctions";
import memberAuthConfigDetails from "../../../models/MemberAuthConfig";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class MembershipAuths extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openForm: false,
            isLoading: false,
            disableField: false,
            data: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            actionType: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }


    componentDidMount() {
        this.setState({ isLoading: true });
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true });
                    this.request();
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: + event.target.value,
            page: 0
        });
    };


    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type) {
            this.setState({ isLoading: true });
            this.request();
        }
    }

    handleOpenForm = () => {
        memberAuthConfigDetails.cleanMemberAuthConfigDetails();
        this.setState({ openForm: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };


    handleTableAction = (action, formData) => {
        memberAuthConfigDetails.setMemberAuthConfigDetail(formData);
        if (action === 1 || action === 0) {
            this.setState({
                openForm: true,
                actionType: 1
            })
        } else if (action === SET_DEFAULT) {
            setDefaultMemberAuthSource(memberAuthConfigDetails.uuid)
                .then((response) => {
                    if (isSuccessfulRequest(response)){
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    };




    request() {
        getMembershipAuthByCompany()
            .then((res) => {
                let newData = res.data.map(item => {
                    item.membershipAuthSourceName = nullController(item.membershipAuthSourceId, "name") === MEMBER_AUTH_SOURCE_DOMAIN ? getStringTextFormatByName(item.memberAuthDomains) : nullController(item.integration, "name");
                    return item;
                });

                this.setState({ data: newData, isLoading: false});
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }



    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage, disableField, actionType, isLoading, data } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'member_authentications'}
                        title={t('MEMBERSHIP_AUTH_TEXT')}
                        exportFileName={t('MEMBERSHIP_AUTH_TEXT')}
                        data={data}
                        actions={[
                            { label: t('BTN_ADD_SOURCE'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TYPE_TEXT'), name: "membershipAuthSourceId.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('VALUE_TEXT'), name: "membershipAuthSourceName", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DEFAULT'), name: "isDefault", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.handleTableAction, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openForm}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {actionType ? (t(formTitles[actionType]) + " " + t('MEMBERSHIP_AUTH_TEXT')) : t('MEMBER_AUTH_TITLE')}
                    </DialogTitle>
                    <DialogContent>
                        <MemberAuthConfigForm closeForm={this.closeForm} action={actionType}/>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

MembershipAuths.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(MembershipAuths)));
