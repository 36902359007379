/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
} from "../../../../assets/jss/material-dashboard-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#F5F5F5",
  },
  wrapper2: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#F5F5F5",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  kanbanMainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100%)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  container: { ...containerFluid },
  content: {
    marginTop: "50px",
    padding: "30px 10px",
    minHeight: "calc(100vh - 123px)"
  },
  kanbanContent: {
    padding: "0px 30px 0px 0px",
  },
  kanbanContainer: {
    backgroundColor: "transparent",
    marginTop: "50px",
    padding: "0 0",
    width: "100%",
    minHeight: "calc(100vh - 123px)"
  },
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
