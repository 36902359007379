import React from "react";
// nodejs library to set properties for components
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {formTitles} from "../../utils/statusHelpers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {
    DIRECT_DEBIT_TYPE_ID,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE
} from "../../variables/coreConstants";
import {
    isSuccessfulRequest, mergeArrays,
    saveData
} from "../../utils/helpersFunctions";
import {validateSession} from "../../services/api";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import memberDetails from "../../models/MemberModel";
import MemberGroupForm from "./component/MemberGroupForm";
import {getMemberGroupsByCompany} from "../../services/memberService";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class MemberGroups extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openGroup: false,
            isLoading: false,
            isLoadingMore: false,
            actionType: 0,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            lastPage: false,
            totalElements: 0,
            totalPages: 0
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    handleOpenForm = () => {
        memberDetails.cleanMemberGroupDetails();
        this.setState({ openGroup: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openGroup: false });
    };

    closeForm(state, value, type) {
        this.setState({[state]: value });
        if(type) {
            this.setState({ page: 0 }, () => {
                this.request();
            });
        }
    }
    openForm = (action, formData) => {
        memberDetails.cleanMemberGroupDetails();
        if (action === 0 || action === 1) {
            memberDetails.setMemberGroupDetail(formData);
            this.setState({ openGroup: true, actionType: action });
        }
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.closeForm("openGroup", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    request = () => {
        const { page, rowsPerPage } = this.state;
        this.setState({ isLoading: true }, () => {

            let parameters = {
                "page": page,
                "pageSize": rowsPerPage,
                "filterId": DIRECT_DEBIT_TYPE_ID,
                "status": -1 // Without status filter
            };

            getMemberGroupsByCompany(parameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        let array = response.data !== null ? response.data.content : [];

                        let paginatedData = this.state.page === 0 ? array : mergeArrays(
                            this.state.filteredData,
                            array,
                            "uuid",
                        );

                        this.setState({
                            isLoading: false,
                            isLoadingMore: false,
                            data: paginatedData,
                            filteredData: paginatedData,
                            totalElements: response.data !== null ? response.data.totalElements : 0,
                            lastPage: response.data !== null ? response.data.last : false,
                            totalPages: response.data !== null ? response.data.totalPages : 0,
                        });
                    } else {
                        this.setState({ isLoading: false, isLoadingMore: false });
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                    }
                })
                .catch((err) => {
                    this.setState({ isLoading: false, isLoadingMore: false });
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });
        });
    };


    goToActions = (val) => {
        saveData("memberGroup", val);
        this.props.history.push("member-group/" + val.uuid);
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, lastPage } = this.state;

        if (!isLoadingMore && !lastPage) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                this.request();
            });
        }
    };




    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage, isLoading, isLoadingMore, filteredData, lastPage, totalElements, totalPages } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'member_groups'}
                        title={t('MEMBER_GROUPS_TEXT')}
                        exportFileName={t('MEMBER_GROUPS_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_ADD_GROUP'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_EXTERNAL_ID'), name: "externalId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openGroup}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} {t('MEMBER_GROUP_TEXT')}
                            {/*<Muted>*/}
                            {/*    {t('FORM_MEMBER_GROUP_DESCRIPTION')}*/}
                            {/*</Muted>*/}
                        </DialogTitle>
                        <DialogContent>
                            <MemberGroupForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}


MemberGroups.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(MemberGroups)));