/**
 * Created By Marlon 10/10/2019
 **/

import {eventTagTypes} from "../variables/tagConstants";
import {
    APP_ACTIVITY,
    APP_COMMERCE, DEBIT_TSK_EXCLUDED, DEBIT_TSK_SYNC_COMPLETED,
    NO,
    RESULT_CREATED,
    RESULT_SUCCESS,
    STATUS_PROGRESS,
    YES
} from "../variables/coreConstants";
import * as moment from "moment";



/**
 * Function to validate if value is an email
 * @param {string} email
 * @returns {boolean}
 */
export function validateEmail(email) {
    let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ;
    return reg.test(email);
}


/**
 * Validate if value is a email domain
 * @param {string} domain
 * @returns {boolean}
 */
export function validateEmailDomain(domain) {
    let reg = /^(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    return reg.test(domain);
}


/**
 * Convert a timestamp date to locale date format
 * @param {date} value (milliseconds)
 * @return Date with format dd-mm-yyyy hh:mm:ss
 **/
export const convertDate = (value) => {
    return (new Date(value).toLocaleString());
};


/**
 * Convert a timestamp date to locale date format
 * @param {date} value (milliseconds)
 * @return Date with format dd-mm-yyyy
 **/
export const convertDateString = (value) => {
    return (new Date(value).toLocaleDateString());
};


/**
 * Set time format from date
 * @param {date} _date (format default)
 * return: Time with format hh:mm:ss
 **/
export const formatTime = (_date) => {
    let date = new Date(_date);

    return (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
        ":" + (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes()) + ":" +
        (date.getSeconds() < 10 ? ("0" + date.getSeconds()) : date.getSeconds());
};


/**
 * @param {date} _date (format default)
 * return: Time with format hh:mm:ss
 **/
export const formatShortTime = (_date) => {
    let date = new Date(_date);

    return (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
        ":" + (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());
};


/**
 * Convert string to date with time configured
 * @param {String} stringDate (String time format HH:mm:ss)
 * return: Date
 **/
export const convertStringToDate = (stringDate) => {
    let [hours, minutes, seconds] = stringDate.split(':');

    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date;
};


/**
 * Format two string values with symbol
 * @param {string} value
 * @param {string} type (Example %, $)
 * return: value with format
 **/
export const formatValue = (value, type) => {
    return value + "" + type ;
};


/**
 * Method to set phone format with currency
 * @param {String} code (country code)
 * @param {String} phoneNumber
 * @return Phone number with country code
 **/
export const formatPhone = (code, phoneNumber) => {
    return "+" + code + " " + phoneNumber;
};


/**
 * Set api date time format (api request format from datetime picker component)
 * @param {date} _date (format default)
 * return: Time with format yyyy-mm-dd hh:mm:ss
 **/
export const formatDate = (_date) => {
    let date = new Date(_date),
        month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + " " + formatTime(_date);
};


/**
 * Get date format for downloaded files
 * @param {date} _date (format default)
 * return: Dete with format yyyy-mm-dd
 **/
export const formatDatetimeToDate = (_date) => {
    let date = new Date(_date),
        month = '' + (date.getMonth() >= 9 ? (parseInt(date.getMonth()) + 1) : ("0" + (parseInt(date.getMonth()) + 1))),
        day = '' + (date.getDate() >= 10 ? date.getDate() : ("0" + date.getDate())),
        year = date.getFullYear();

    return [year, month, day].join('-');
};


/**
 * Get day name from schedule day id from database
 * @param {String} _day (format three words)
 * @return Day name
 **/
export const formatDay = (_day) => {
    let day;

    switch (_day) {
        case 1:
            day = "SUNDAY";
            break;
        case 2:
            day = "MONDAY";
            break;
        case 3:
            day = "TUESDAY";
            break;
        case 4:
            day = "WEDNESDAY";
            break;
        case 5:
            day = "THURSDAY";
            break;
        case 6:
            day = "FRIDAY";
            break;
        case 7:
            day = "SATURDAY";
            break;
        default:
            day = "Invalid";
            break;
    }

    return day;
};


/**
 * Method that assigns the current date
 * @return string date in format yyyy-mm-dd
 **/
export const getTodayDate = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};


/**
 * Method to validate if react select object is empty or not
 * @param {array} _selectData (structure [{value: value, label: label}])
 * @return {boolean} true or false
 **/
export const selectValidation = (_selectData) => {
    return _selectData === undefined || _selectData === null || _selectData.value === 0 ||
        _selectData === "" ||  _selectData.length === 0 || _selectData === [];
};


/**
 * @deprecated
 * Convert time check array structure to exported structure
 * @param {array} timeChecks
 * @return Array
 **/
export const convertArrayToExport = (timeChecks) => {
    try {
        return timeChecks.map(item => {
            return {
                id: item.id,
                externalId: item.externalId,
                name: item.firstName + " " + item.lastName,
                store: item.store,
                zone: item.zone,
                datetime: convertDate(item.datetime),
                eventType: eventTagTypes[item.eventType],
                roleName: item.role,
                addedBy: item.addedBy
            };
        });

    } catch (e) {
        console.log(e);
    }
};


/**
 * Method that validate if the fields are null or empty
 * @param {array} fields
 * @return {boolean} true or false
 **/
export const validateFields = (fields) => {
    let result = 0;
    for(let i = 0; i < fields.length; i++) {
        if(fields[i] === "" || fields[i] === null || fields[i] === undefined)
            result += 1;
    }

    return result < 1;
};


/**
 * Method check if user is logged
 * @return {boolean} If user exist return true else return false
 **/
export const isLogged = () => {

    return !!sessionStorage.getItem("user") && !!sessionStorage.getItem("userTk");
};


/**
 * Method that removed session data and logout user
 **/
export const removeSession = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userTk");
    sessionStorage.removeItem("notificationTk");
    sessionStorage.clear();
};


/**
 * Method that return user data
 * @return User session data
 **/
export const getUser = () => {
    if (isLogged()) {
        return JSON.parse(sessionStorage.getItem("user"));

    } else {
        return null;
    }
};


/**
 * Method that return company currency
 * @return {string} Currency symbol
 **/
export const getCompanyCurrency = () => {
    if (isLogged()) {
        let data = JSON.parse(sessionStorage.getItem("user"));
        if (data !== null) {
            return (data.currency !== null && data.currency) ? data.currency : "";
        } else {
            return "";
        }
    } else {
        return "";
    }
};


/**
 * Method save user data
 * @param data User login information
 * @param auth User token
 **/
export const saveUser = (data, auth) => {
    let status;
    try {
        if ((data != null && auth != null) || (data !== undefined && auth !== undefined)) {
            sessionStorage.setItem("user", JSON.stringify(data));
            sessionStorage.setItem("userTk", JSON.stringify(auth));
            status = true;

        } else {
            status = false;
        }

    } catch (e) {
        console.log(e);
        status = false;
    }

    return status;
};


/**
 * Method update user data
 * @param data New user information
 * @return {boolean} successfully action
 **/
export const updateUser = (data) => {
    let status;
    let currentData = getUser();

    try {
        if (data !== null && data !== undefined) {
            currentData.verified = data.verified;
            currentData.currency = data.currency;
            currentData.isDocumentUploaded = data.isDocumentUploaded;
            sessionStorage.setItem("user", JSON.stringify(currentData));
            status = true;

        } else {
            status = false;
        }

    } catch (e) {
        console.log(e);
        status = false;
    }

    return status;
};


/**
 * Method that save storage data
 * @param {String} key
 * @param {Array} value
 **/
export const saveData = (key, value) => {
    if ((key != null && value != null) || (key !== undefined && value !== undefined)) {
        sessionStorage.removeItem(key);
        sessionStorage.setItem(key, JSON.stringify(value));
    }
};


/**
 * Method that return storage data
 * @param {String} key
 * return: Array
 **/
export const getData = (key) => {
    if (sessionStorage.getItem(key)) {
        return JSON.parse(sessionStorage.getItem(key));

    } else {
        return null;
    }
};


/**
 * Method that remove storage data
 * @param {String} key
 **/
export const removeData = (key) => {
    sessionStorage.removeItem(key);
};


/**
 * Validate if response is success created
 * @param response
 * @return {boolean}
 */
export const isSuccessfulCreate = (response) => {
    return response.status === RESULT_CREATED;
};


/**
 * Validate if request is success request
 * @param response
 * @return {boolean}
 */
export const isSuccessfulRequest = (response) => {
    return response.status === RESULT_SUCCESS && response.data.result !== "transaction_failed";
};


/**
 * Method convert boolean to yes or no
 * @param {boolean} value
 * return: String
 **/
export const convertToYesNo = (value) => {
    if (value === true) {
        return YES;
    } else {
        return NO;
    }
};


/**
 * Method disable DOM component
 * @param {Object} component
 * @param {String} state
 **/
export const disableComponent = (component, state) => {
    // component.setState({ disableField: true} );
    component.setState({[state]: true });
};


/**
 * Method enable DOM component
 * @param {Object} component
 * @param {String} state
 **/
export const enableComponent = (component, state) => {
    // component.setState({ disableField: false} );
    component.setState({[state]: false });

};


/**
 * Method check permission access
 * @param {String} appType
 * @param userType (at the moment this parameter is not undefined)
 * return: boolean
 **/
export const checkComponentAccess = (appType, userType) => {
    try {
        if (appType === APP_COMMERCE || appType === APP_ACTIVITY) {
            return !(userType === undefined || userType === null);

        } else {
            console.log("Invalid application");
            return false;
        }

    } catch (e) {
        console.log("Something went wrong: " + e);
    }

};


/**
 * Method that return string text separated with ','.
 * @param {Array} array
 * return: String
 **/
export const getStringTextFormatByName = (array) => {

    if (array !== undefined || null) {

        try {
            let names = array.map(({ name }) => name);
            return names.join(", ", );

        } catch (e) {
            console.log(e);
            return "";
        }

    } else {
        return "";
    }
};


/**
 * Method that return string value.
 * @param {Array} obj
 * @param {String} property
 * return: String
 **/
export const  nullController = (obj, property) => {
    let value = '';

  try {
      if (obj === null || obj === undefined) {
          value = '';
      } else {
          if (obj[property] === null || obj[property] === undefined) {
              value = '';

          } else if ((typeof obj[property]) === 'object') {
              value = '';
          } else {
              value = obj[property];
          }
      }

  }  catch (e) {
      console.log(e);
  }

  return value;
};


/**
 * Method that return boolean value.
 * @param {Object} array
 * @param {String} property
 * return: true or false
 **/
export const  nullValidator = (array, property) => {
    let value = true;

  try {
      if (array === null || array === undefined) {
          value = false;
      } else {
          if (array[property] === null || array[property] === undefined) {
              value = false;

          } else if ((typeof array[property]) === 'object') {
              value = false;
          }
      }

  }  catch (e) {
      value = false;
      console.log(e);
  }

  return value;
};


/**
 * Method that return boolean value.
 * @param {any} value
 * return: true if value is empty, else return false
 **/
export const  isEmpty = (value) => {
    let result = true;

  try {
      result = value === null || value === undefined || value === "";
  }  catch (e) {
      console.log(e);
  }

  return result;
};


/**
 * Method that return string value.
 * @param {Array} array
 * @param {String} property
 * return: String
 **/
export const  getStatusValue = (array, property) => {
    let value = false;

  try {
      if (array === null || array === undefined) {
          value = false;
      } else {
          if (array[property] === null || array[property] === undefined) {
              value = false;

          } else if ((typeof array[property]) === 'object') {
              value = false;
          } else {
              if (array[property] === 0) {
                  value = false;
              } else if (array[property] === 1) {
                  value = true;
              } else if (array[property] === false) {
                  value = false;
              } else if (array[property] === true) {
                  value = true;
              }
          }
      }

  }  catch (e) {
      console.log(e);
  }

  return value;
};


/**
 * Method that verify if the value is new or not.
 * @param {Array} oldArray [Required]
 * @param {String} property [Required]
 * @param {Number} valueId [Required]
 * return: boolean
 **/
export const validateNewValue = (oldArray, property, valueId) => {
    let isValid = false;

    try {
        let index = oldArray.findIndex(val => val[property] === valueId);
        if (index !== -1)
            isValid = true;

    } catch (e) {
        console.log("Something went wrong.");
    }

    return isValid;
};


/**
 * Prepare component to print
 * @param elementId
 * @param uniqueIframeId
 */
export const printComponent = (elementId, uniqueIframeId) => {

    const content = document.getElementById(elementId);
    let pri;

    if (document.getElementById(uniqueIframeId)) {
        pri = document.getElementById(uniqueIframeId).contentWindow
    } else {
        const iframe = document.createElement('iframe');

        iframe.setAttribute('title', uniqueIframeId);
        iframe.setAttribute('id', uniqueIframeId);
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
        document.body.appendChild(iframe);
        pri = iframe.contentWindow
    }
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print()
};


/**
 * Method group array by specific number.
 * @param {Array} array [Required]
 * @param {number} rows [Required]
 * @param {number} columns [Required]
 * return: Array
 **/
export const groupArray = (array, columns, rows) => {
    let arrayRows = array.reduce((r, e, i) =>
        (i % columns ? r[r.length - 1].push(e) : r.push([e])) && r
        , []);

    return arrayRows.reduce((r, e, i) =>
        (i % rows ? r[r.length - 1].push(e) : r.push([e])) && r
        , []);
};


/**
 * Get client language
 * @return {*}
 */
export const getLanguage = () => {
    let language;

    try {
        language = window.navigator.language.slice(0, 2);
    } catch (e) {
        language = '';
    }

    return language;
};


/**
 * Helper function to convert hex to base64
 * @param hexs tring
 * @return {string}
 */
export function hexToBase64(hexstring) {
    return btoa(hexstring.match(/\w{2}/g).map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
}


/**
 * Get client time zone information
 * @return {*}
 */
export const getTimeZone = () => {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone === undefined || timeZone === "") {
        timeZone = null;
    }

    return timeZone;
};


/**
 * Get operative system information from client
 * @return {string}
 */
export const getOs = () => {
    return navigator.userAgent === null || navigator.userAgent === undefined || navigator.userAgent === "" || navigator.userAgent.length <= 250
        ? navigator.userAgent
        : navigator.userAgent.substring(0, 250);
};


/**
 * Generate csp nonce used by policies
 * @return {string}
 */
export const getNonceProvider = () => {
    return document.querySelector(`meta[property="csp-nonce"]`).getAttribute('content');
};


/**
 * Method that verify if password is valid strong password.
 * @param {String} password [Required]
 * return: boolean
 **/
export const validatePassword = (password) => {
    /**
     * Password must contain 1 number (0-9), one uppercase letters,
     * one lowercase letters, one non-alpha numeric number
     * and a least of 8 characters with no space
     * **/
    const validPass =  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;

    return password.match(validPass);
};


/**
 * Get order eta with status validation
 * @param status
 * @param eta
 * @return {null}
 */
export const getOrderEta = (status, eta) => {
    let orderDate = null;
    if (status === STATUS_PROGRESS) {
        orderDate = eta
    }


    return orderDate;
};


/**
 * Verify image access
 * @param url
 * @param component
 */
export const verifyAccessImage = (url, component) => {
    try {
        let imageObj = new Image();

        imageObj.src = url;

        imageObj.onload = () => {
            component.setState({
                isImageError: false,
                isLoadingOrder: false,
                openOrder: true,
            });

        };
        imageObj.onerror = () => {
            component.setState({
                isImageError: true,
                isLoadingOrder: false,
                openOrder: true,
            });
        };
    } catch (e) {
        component.setState({
            isImageError: true,
            isLoadingOrder: false,
            openOrder: true,
        });
    }

};


/**
 * Validate access to loaded image to generate base64
 * @param url
 * @param component
 * @param loadingState
 */
export const verifyGeneralAccessImage = (url, component, loadingState) => {
    try {
        let imageObj = new Image();

        imageObj.src = url;

        imageObj.onload = () => {
            component.setState({
                isImageError: false,
                [loadingState]: false,
            });

        };
        imageObj.onerror = () => {
            component.setState({
                isImageError: true,
                [loadingState]: false,
            });
        };
    } catch (e) {
        component.setState({
            isImageError: true,
            [loadingState]: false,
        });
    }

};


/**
 * Get firebase configuration from environment
 * @return {null}
 */
export const firebaseConfiguration = () => {
    let config = null;
    try {
        config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
    } catch (e) {
        console.log("Invalid configuration.");
    }

    return config;
};


/**
 * Create last moment of day date object from moment library
 * @return {moment.Moment}
 */
export const initialMomentDate = () => {
    let _date = new Date();
    _date.setHours(23,59, 59);
    var m = moment(); // Initial moment object

    var _moment = moment(_date);

    m.set(_moment.toObject());

    return m;
};


/**
 * Create from init day date object with moment library
 * @return {moment.Moment}
 */
export const initialMomentOfDay = () => {
    let _date = new Date();
    _date.setHours(0,0, 0);
    var m = moment(); // Initial moment object

    var _moment = moment(_date);

    m.set(_moment.toObject());

    return m;
};


/**
 * Create a current date object from moment library
 * @return {moment.Moment}
 */
export const nowMomentDate = () => {
    let _date = new Date();
    _date.setHours(23,59, 59);

    var m = moment(); // Initial moment object

    var _moment = moment(_date);

    m.set(_moment.toObject());

    return m;
};


/**
 * Get decimal separator based on client configuration
 * @returns {*}
 */
export const getDecimalSeparator = () => {
    const numberWithDecimalSeparator = 1.1;

    let result = Intl.NumberFormat(window.navigator.language)
        .formatToParts(numberWithDecimalSeparator)
        .find(part => part.type === 'decimal')
        .value;

    if (result === null || result === undefined) {
        result = numberWithDecimalSeparator.toLocaleString().substring(1, 2).toString();
    }

    return result;
};


/**
 * Apply currency format to amount values
 * @param num
 * @returns {*}
 */
export function currencyFormat(num) {
    let output = num;
    let isNegative = false;

    try {
        if (num.toString().includes("-")) {
            isNegative = true;
            num = num.replace("-", "");
            output = output.replace("-", "");
        }

        num = num.length === 0 ? '000' + num : num.length === 1 ? '00' + num : num.length === 2 ? '0' + num  : num;

        let dot = getDecimalSeparator();
        let milSeparator = dot === "." ? "," : ".";
        let position = num.length - 2;

        output = [num.slice(0, position), dot, num.slice(position)].join('');
        output = output.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+ milSeparator);

        if (isNegative) {
            output = "-" + output;
        }

    }catch (e){console.log(e)}

    return output;
}


/**
 * Helper function to sanitize number values to string
 * @param num
 * @returns {string}
 */
export function clearCurrencyFormat(num) {
    let clearNumber = 0;

    if (num !== null && num !== undefined && num !== "") {
        try {
            num = num.replace(',', '').replace('.', '');
            clearNumber = parseFloat(num);

        } catch (e) {}
    }


    return clearNumber.toString();
}


/**
 * Set currency format to amount
 * @param value
 * @returns {*}
 */
export const setCurrencyValue = (value) => {
    return currencyFormat(clearCurrencyFormat(value))
};


/**
 * Method to mask number whit decimal based by client configuration
 * @param num
 * @returns {number}
 */
export function getValueFromCurrencyMask(num) {
    let clearNumber = 0;

    try {
        let milSeparator = getDecimalSeparator() === "." ? "," : ".";
        num = num.replaceAll(milSeparator, '');

        if (getDecimalSeparator() === ",") {
            num = num.replace(getDecimalSeparator(), '.');
        }

        clearNumber = parseFloat(num);
    } catch (e) {}

    return clearNumber;
}


/**
 * Round number to 2 decimals
 * @param value
 * @returns {string|*}
 */
export const getRoundNumber = (value) => {
    if (typeof value === "number") {
        return value.toFixed(2);
    } else {
        return value
    }
};


/**
 * Method to validate if user has access to specific tab
 * @param routeName
 * @returns {boolean}
 */
export const hasRouteAccess = (routeName) => {
    let result = false;

    try {
        if (routeName === "/admin/not-found" || routeName === "/admin/profile" || routeName === "/admin/account-settings") {
            result = true;
        } else {
            let userTabs = getUser().userRoutes;
            let index = userTabs.findIndex(x => "/admin" + x.routeUrl === routeName);
            if (index !== -1) {
                result = true;
            }
        }
    } catch (e) {}

    return result;
};


/**
 * Method that return boolean value.
 * @param {Array} array
 * @param {String} property
 * @param {Number} compareTo
 * return: true or false
 **/
export const  numberValidator = (array, property, compareTo) => {
    let value = false;

    try {
        if (array === null || array === undefined) {
            value = false;
        } else {
            if (array[property] === null || array[property] === undefined) {
                value = false;

            } else if ((typeof array[property]) === 'object') {
                value = false;
            } else if (array[property] > compareTo) {
                value = true;
            }
        }

    }  catch (e) {}

    return value;
};


/**
 * Validate array size
 * @param array Array object
 * @param size Number
 * @returns {boolean}
 */
export const  objectSizeValidator = (array, size) => {
    let value = false;

    try {
        if (array === null || array === undefined) {
            value = false;
        } else {
            if (array === [] || array.length === null || array.length === undefined) {
                value = false;
            } else if (array.length > size) {
                value = true;
            }
        }

    }  catch (e) {}

    console.log("IS: ", value);

    return value;
};


/**
 * Validate if value is json
 * @param str
 * @returns {boolean}
 */
export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


/**
 * Method to validate and parse value to json
 * @param value
 * @returns {string} Json string value
 */
export const  parseOtherInformation = (value) => {
    let json = "";
    try {
        if (value !== null && value !== "") {
            if (isJson(value) && typeof JSON.parse(value) === 'object') {
                json = JSON.stringify(JSON.parse(value));
            } else {
                json = value;
            }
        }
    } catch (e) {}


    return json;
};


/**
 * Get timeline object by sequence name
 * @param timeline
 * @param sequenceName
 * @returns {null} Timeline sequence item
 */
export const getTimeLineSequenceDetails = (timeline, sequenceName) => {
    let sequenceData = null

    try {
        for (let i = 0 ; i < timeline.length ; i++) {
            let current = timeline[i];

            if (current.name === sequenceName) {
                sequenceData = current;
                break;
            }
        }
    } catch (e) { console.log(e) }


    return sequenceData;
};


/**
 * Get table column value by column name from debit response
 * @param item
 * @param columnName
 * @returns {null}
 */
export const getTableColumnObject = (item, columnName) => {
    let result = null

    try {
        if (item !== null && item !== undefined && columnName !== null && columnName !== undefined && columnName !== "") {
            switch (columnName) {
                case("payment") :
                    break;
                case("member") :
                    break;
                case("paymentMethod") :
                    break;
                case("paymentAmount") :
                    break;
                case("paymentCurrency") :
                    break;
                case("statusSync") :
                    if (item.status === DEBIT_TSK_EXCLUDED) {
                        result = item.payments.length === 0 ? 'NO_PAYMENTS_FOUND' : (isEmpty(item.paymentMethod) ? 'NO_PAYMENT_METHOD' : '')
                    }
                    break;
                case("statusAttempt") :
                    if (!item.success) {
                        if (!isEmpty(item.response) && !isEmpty(item.response.error)) {
                            result = item.response.error.message;
                        }
                    }
                    break;
                case("statusSend") :
                    if (!item.success) {
                        if (!isEmpty(item.response) && !isEmpty(item.response.error)) {
                            result = item.response.error.message;
                        } else {
                            result = 'undefined'
                        }
                    }
                    break;
                case("paymentStatus") :
                    if (!isEmpty(item.response) && !isEmpty(item.response.result)) {
                        result = item.response.result.name;
                    } else {
                        result = 'undefined'
                    }
                    break;
                case("timelineStatus") :
                    break;
                default:
                    break;
            }

        }
    } catch (e) { console.log(e) }


    return result;
};


/**
 *
 * @param object process summary
 * @param {number} object.completed pending task
 * @param {number} object.total total task
 * @returns {number}
 */
export const getPercentageProcess = (object) => {
    let result = 0;
    try {
        if (object !== null && object !== undefined) {
            if (parseInt(object['completed']) > 0 && parseInt(object['total'])) {
                let percentage = (parseInt(object['completed']) * 100) / parseInt(object['total']);
                result = Math.floor(percentage);
            }
        }
    } catch (e) {
        console.log(e);
    }

    return result;
}


/**
 * Clone a object
 * @param currentObject
 * @returns {*}
 */
export const cloneObject = (currentObject) => {
    return  Object.assign({}, currentObject);
}


/**
 * Function that merger two objects values and properties
 * @param currentObject
 * @param newObjectValues
 * @returns {null} An object
 */
export const mergerObjects = (currentObject, newObjectValues) => {
    let result = null;

    try {
        let cloneObject = Object.assign({}, currentObject);
        result = Object.assign(cloneObject, newObjectValues);

    } catch (e) {};


    return result;
};


/**
 * Get wss task item label
 * @param tasks Wss task list
 * @param isSync Wss action is sync or not
 * @returns {null} Wss task item label string
 */
export const getMemberLabelFromJobTask = (tasks, isSync) => {
    let result = null;

    try {
        if (isSync) {
            result = tasks.length > 0 ? {
                label: (tasks[0].data.member.name !== undefined ? tasks[0].data.member.name : "")  + (tasks[0].data.member.externalId !== undefined && tasks[0].data.member.name !== undefined ? " - " : "") + (tasks[0].data.member.externalId !== undefined ? tasks[0].data.member.externalId : ""),
                success: tasks[0].status === DEBIT_TSK_SYNC_COMPLETED
            } : null;
        } else {
            result = tasks.length > 0 ? {
                label: (tasks[0].data.payment.paymentRequestName !== undefined ? tasks[0].data.payment.paymentRequestName : "")  + (tasks[0].data.payment.paymentRequestExternalId !== undefined && tasks[0].data.payment.paymentRequestName !== undefined ? " - " : "") + (tasks[0].data.payment.paymentRequestExternalId !== undefined ? tasks[0].data.payment.paymentRequestExternalId : ""),
                success: tasks[0].status === DEBIT_TSK_SYNC_COMPLETED
            } : null;
        }

    } catch (e) {}

    return result;
}


/**
 * Merge two arrays and remove duplicated, sort array desc with column receives
 * @param {[]} initArray
 * @param {[]} otherArray
 * @param {string} propertyName
 * @param {function} sortFunction
 * @returns {[]} returns array merged without duplicated
 */
export const mergeArrays = (initArray, otherArray, propertyName, sortFunction) => {
    let result = [];
    try {
        let ids = new Set(otherArray.map(d => d[propertyName]));
        result = [...initArray.filter(d => !ids.has(d[propertyName])), ...otherArray];

        if (!isEmpty(sortFunction) && typeof sortFunction === 'function') {
            result.sort(sortFunction);
        }
    } catch (e) { console.log(e) }

    return result;
}


export const timeSince = (date, t) => {
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return t('UPDATED') + t(interval === 1 ? 'YEAR_AGO' : 'YEARS_AGO', { value: interval === 1 ? t('A') : Math.floor(interval)});
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return t('UPDATED') + t(interval === 1 ? 'MONTH_AGO' : 'MONTHS_AGO', { value: interval === 1 ? t('A') : Math.floor(interval)});
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return t('UPDATED') + t(interval === 1 ? 'DAY_AGO' : 'DAYS_AGO', { value: interval === 1 ? t('A') : Math.floor(interval)});
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return t('UPDATED') + t(interval === 1 ? 'HOUR_AGO' : 'HOURS_AGO', { value: interval === 1 ? t('AN') : Math.floor(interval)});
    }
    interval = seconds / 60;
    if (interval > 1) {
        return t('UPDATED') + t(interval === 1 ? 'MINUTE_AGO' : 'MINUTES_AGO', { value: interval === 1 ? t('A') : Math.floor(interval)});
    }
    return t('UPDATED') + t('MOMENT_AGO');
}


export const getDefaultWalletAccount = (waList, companyCurrency) => {
    let result = null;

    try {
        if (waList !== null && waList !== undefined && waList.length > 0) {
            let filter = waList.filter(wa => wa.currencyId.symbol === companyCurrency);

            if (filter.length > 0) {
                result = filter[0].uuid;
            } else {
                result = waList[0].uuid;
            }
        }
    } catch (e) { console.log(e) }

    return result;
}
export const getDefaultCurrency = (waList, companyCurrency) => {
    let result = null;

    try {
        if (waList !== null && waList !== undefined && waList.length > 0) {
            let filter = waList.filter(wa => wa.currencyId.symbol === companyCurrency);

            if (filter.length > 0) {
                result = {
                    value: filter[0].currencyId.isoCode,
                    label: filter[0].currencyId.name + "(" + filter[0].currencyId.symbol + ")"
                };
            } else {
                result = {
                    value: waList[0].currencyId.isoCode,
                    label: waList[0].currencyId.name + "(" + waList[0].currencyId.symbol + ")"
                };
            }
        }
    } catch (e) { console.log(e) }

    return result;
}