import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import CategoryIcon from "@material-ui/icons/CollectionsBookmark";
// import DescriptionIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import categoryDetails from "../../../models/CategoryModel";
import {addCategory, editCategory} from "../../../services/categoryService";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent,
    enableComponent,
    isSuccessfulCreate,
    isSuccessfulRequest
} from "../../../utils/helpersFunctions";
import {validateSession} from "../../../services/api";
import {withTranslation} from "react-i18next";



class CategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryName: categoryDetails.name,
            description: categoryDetails.description,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.saveCategory = this.saveCategory.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.cleanData = this.cleanData.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }



    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    cleanData() {
        this.setState({
            categoryName: '',
            description: '',
            isDefaultCategory: 0,
            notificationMessage: '',
            notification: false,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openCategory", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {}


    saveCategory() {
        if (this.state.categoryName === "") {
            this.showNotification("Name is required", "danger", NotificationErrorIcon, null);

        } else {


            let dataParameters = {
                "name": this.state.categoryName
            };

            disableComponent(this, "disableField");
            addCategory(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        categoryDetails.name = dataParameters.name;
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");

                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response.data.message, this.props.history);
                    }
                });
        }
    }


    updateCategory() {
        const { t } = this.props;
        if (this.state.categoryName === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {


            let dataParameters = {
                "name": this.state.categoryName
            };

            disableComponent(this, "disableField");
            editCategory(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response.data.message, this.props.history);
                    }
                });
        }
    }



    render() {
        const { classes, t } = this.props;

        const SaveCategoryButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveCategory()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CATEGORY')}
            </Button>
        );

        const UpdateCategoryButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateCategory()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.categoryName}
                            onChange={this.handleChangeValues}
                            labelText={t('CATEGORY_NAME_TEXT')}
                            id="categoryName"
                            name="categoryName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CategoryIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    {/*<GridItem xs={12} sm={12} md={6}>*/}
                    {/*</GridItem>*/}
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openCategory", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateCategoryButton/> : <SaveCategoryButton />}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

CategoryForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(CategoryForm)));