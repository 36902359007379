import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

var ps;

class SidebarWrapper extends React.Component {
    sidebarWrapper = React.createRef();
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebarWrapper.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, headerLinks, links } = this.props;
        return (
            <div className={className} ref={this.sidebarWrapper}>
                {headerLinks}
                {links}
            </div>
        );
    }
}

SidebarWrapper.propTypes = {
    className: PropTypes.string.isRequired,
    headerLinks: PropTypes.object,
    links: PropTypes.object
};

export default SidebarWrapper;