
var zoneDetails = {
    id: null,
    uuid: "",
    name: "",
    label: "",
    externalId: "",
    description: "",
    store: [{
        value: 0,
        label: ""
    }],
    parentZone: {
        value: 0,
        label: ""
    },
    assets: [],
    zoneRange: 10,
    type: null,
    delivery: true,
    deliveryBlocked: false,


    cleanZoneDetails : function () {
        this.id = null;
        this.name = "";
        this.uuid = "";
        this.label = "";
        this.store = "";
        this.externalId = "";
        this.description = "";
        this.zoneRange = 10;
        this.type = null;
        this.delivery = true;
        this.deliveryBlocked = false;

    },
};

export default zoneDetails;