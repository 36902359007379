
var companyDetails = {
    address: "",
    cityId: "",
    countryCode: null,
    currentTimeZone: "",
    documentUploaded: false,
    fullName: "",
    phoneNumber: "",
    postalCode: "",
    verified: true,
    taxId: "",
    timeZone: "",
    currentTime: "",
    country: {
        value: 0,
        label: "Seleccionar...",
    },

    setCompanyDetail : function (values) {
        try {
            this.address = values.address;
            this.cityId = values.cityId;
            this.countryCode = values.countryCode;
            this.currentTimeZone = values.currentTime;
            this.documentUploaded = values.documentUploaded;
            this.fullName = values.fullName;
            this.phoneNumber = values.phoneNumber;
            this.postalCode = values.postalCode;
            this.verified = values.verified;
            this.taxId = values.taxId;
            this.country.value = values.country.id;
            this.country.label = values.country.name;



        } catch (e) {
            console.log("Something went wrong");
        }
    },


    cleanCompanyDetails : function () {
        this.address = "";
        this.cityId = "";
        this.countryCode = "";
        this.currentTime = "";
        this.currentTimeZone = "";
        this.documentUploaded = "";
        this.fullName = "";
        this.phoneNumber = "";
        this.postalCode = "";
        this.verified = "";
        this.taxId = "";
        this.timeZone = "";
    },

    getCurrentTimeZone : function () {
        return this.timeZone.findIndex(val => val.label === this.currentTime);
    },

};

export default companyDetails;