import {getService, postService, putService} from "./api";
import {
    BASE_URL_BALANCE,
    REVENUE_RECONCILIATION_DETAILS,
    BLOCK_DEPOSIT_REVENUE_RECONCILIATION_LIST,
    ALL_REVENUE_RECONCILIATION_LIST,
    BLOCK_DEPOSIT_DETAILS,
    UPDATE_RECONCILIATION, EXPORT_BLOCK_DEPOSIT, EXPORT_CONCILIATION, WALLET_ACCOUNT,
} from "../variables/apiConstants";


export function getRevenueReconciliationListByBlockDeposit(parameters) {
    let url = BASE_URL_BALANCE + BLOCK_DEPOSIT_REVENUE_RECONCILIATION_LIST;

    return postService(url, parameters);
}

export function getAllRevenueReconciliation(parameters) {
    let url = BASE_URL_BALANCE + ALL_REVENUE_RECONCILIATION_LIST;

    return postService(url, parameters);
}

export function getRevenueReconciliationDetails(uuid) {
    let url = BASE_URL_BALANCE + REVENUE_RECONCILIATION_DETAILS + uuid;

    return getService(url);
}


export function getBlockDepositDetails(walletAccountId, uuid) {
    let url = BASE_URL_BALANCE + WALLET_ACCOUNT + walletAccountId + BLOCK_DEPOSIT_DETAILS + uuid;

    return getService(url);
}

export function exportBlockDeposit(walletAccountId, uuid) {
    let url = BASE_URL_BALANCE + WALLET_ACCOUNT + walletAccountId + EXPORT_BLOCK_DEPOSIT + uuid;

    return getService(url);
}

export function updateReconciliation(parameters) {
    let url = BASE_URL_BALANCE + UPDATE_RECONCILIATION;

    return putService(url, parameters);
}

export function exportConciliationSummary(parameters) {
    let url = BASE_URL_BALANCE + EXPORT_CONCILIATION;

    return postService(url, parameters);
}
