/**
 * Copyright 2021 Ginih (https://www.ginih.com)
 * Created By Marlon
 * Coded by Ginih Team
 **/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { withRouter } from "react-router-dom";
import {
    disableComponent,
    enableComponent,
    getData, isEmpty,
    isSuccessfulRequest,
    nullController,
    parseOtherInformation,
    removeData,
    saveData,
    selectValidation,
    verifyGeneralAccessImage,
} from "../../../utils/helpersFunctions";
import { withTranslation } from "react-i18next";
import ResourceNotFound from "../../NotFound/ResourceNotFound";
import Loading from "../../../components/Loading/Loading";
import HeaderDetailView from "../../../components/CustomComponents/HeaderDetailView";
import { validateSession } from "../../../services/api";
import {
    FAILED_SYNC_STATUS_INT,
    NOTIFICATION_DURATION, PAID_STATUS, PAID_STATUS_INT,
    STATUS_CANCELLED,
    STATUS_EXTERNAL_PAID, STATUS_PAID_EXTERNALLY_INT, STATUS_PAID_PARTIAL_INT, STATUS_PAID_PENDING_SYNC_INT,
    STATUS_PENDING,
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CustomButton from "../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formTitles, statusText } from "../../../utils/statusHelpers";
import Muted from "../../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentRequestForm from "./PaymentRequestForm";
import paymentRequestDetails from "../../../models/PaymentRequestModel";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {
    getPaymentRequestDetail,
    updatePaymentRequestStatus,
} from "../../../services/paymentService";
import PaymentRequestDetails from "./PaymentRequestDetails";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import TextField from "@material-ui/core/TextField";
import StepPathComponent from "../../../components/StepPath/StepPathComponent";
import Button from "../../../components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {FormGroup} from "@material-ui/core";

class QuickActionsPaymentRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: "",
            paymentRequest: null,
            open: false,
            actionType: 1,
            isLoading: false,
            notFound: false,
            isImageError: false,
            openCancel: false,
            disableField: false,
            status: "",
            statusOptions: [],
            value: 0,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
            openNotificationModal: false,
            phoneNotification: false,
            emailNotification: false,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount(): void {
        const { match } = this.props;
        const paymentRequest = getData("paymentRequest");

        if (match.params.id && paymentRequest !== null) {
            this.initialize(match.params.id);
        } else {
            this.setState({ notFound: true });
            removeData("paymentRequest");
            this.props.history.goBack();
        }
    }

    initialize = (id) => {
        const paymentRequest = getData("paymentRequest");

        this.setState({
            paymentRequest: paymentRequest,
            status: {
                value: paymentRequest.status,
                label: this.props.t(statusText[paymentRequest.status]),
            },
            isLoading: true,
            uuid: id,
        });
        this.request(id);
    };

    changeStatusSelect = (status) => {
        this.setState({ status });
    };

    getStatusOptions = () => {
        let options = [];

        try {
            if (this.state.paymentRequest.status === STATUS_PENDING) {
                options = [
                    {
                        value: STATUS_EXTERNAL_PAID,
                        label: this.props.t("STATUS_EXTERNAL_PAID"),
                    },
                    {
                        value: STATUS_CANCELLED,
                        label: this.props.t("STATUS_CANCELLED"),
                    },
                ];
            } else if (this.state.paymentRequest.status === STATUS_CANCELLED) {
                options = [
                    {
                        value: STATUS_EXTERNAL_PAID,
                        label: this.props.t("STATUS_EXTERNAL_PAID"),
                    },
                    {
                        value: STATUS_PENDING,
                        label: this.props.t("STATUS_PENDING"),
                    },
                ];
            }
        } catch (e) {
            console.log(e);
        }

        return options;
    };

    handleCloseForm = () => {
        this.setState({ open: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request(this.state.uuid);
    }
    openForm = (action, formData) => {
        if (action === 1 && formData.status !== STATUS_PENDING) {
            this.showNotification(
                this.props.t("CANNOT_UPDATE_PAYMENT_REQUEST_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            paymentRequestDetails.setPaymentRequestDetail(formData);

            if (action === 1 || action === 0) {
                this.setState({ open: true, actionType: action });
            }
        }
    };

    openCancelForm = () => {
        if (
            this.state.paymentRequest.status === STATUS_PENDING ||
            this.state.paymentRequest.status === STATUS_CANCELLED
        ) {
            this.setState({
                openCancel: true,
                statusOptions: this.getStatusOptions(),
            });
        } else {
            this.showNotification(
                this.props.t("CANNOT_UPDATE_PAYMENT_REQUEST_MSG"),
                "danger",
                NotificationErrorIcon,
                null
            );
        }
    };

    handleOpenNotificationModal = () => {
        this.setState({
            openNotificationModal: true
        });
    };

    handleStatusPaymentRequest = () => {
        if (!selectValidation(this.state.status)) {
            let dataParameters = {
                paymentRequestId: this.state.uuid,
                reason: "",
                status: this.state.status.value,
            };

            disableComponent(this, "disableField");
            updatePaymentRequestStatus(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            null
                        );
                    }
                    enableComponent(this, "disableField");
                })
                .catch((error) => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(
                            this.props.t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                });
        }
    };

    handleCloseCancelForm = () => {
        this.setState({ openCancel: false, statusOptions: [] });
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null && refresh) {
                    this.setState({ openCancel: false });
                    this.request(this.state.uuid);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    request = (memberId) => {
        getPaymentRequestDetail(memberId)
            .then((response) => {
                this.setState({
                    paymentRequest: response.data,
                });
                verifyGeneralAccessImage(
                    response.data.userPhoto,
                    this,
                    "isLoading"
                );
                paymentRequestDetails.setPaymentRequestDetail(response.data);
                saveData("paymentRequest", response.data);
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(
                        err.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };

    handleUpdateScreen = (pr) => {
        if (pr !== null && pr !== undefined && pr !== "") {
            this.initialize(pr.paymentRequestId);
        }
    };

    handleChangNotifications = (stateName) => {
        this.setState({
            [stateName]: !this.state[stateName]
        });
    };


    getActivePathStatus = (stepId, paymentRequest) => {
        let result = false;

        try {
            if (!isEmpty(stepId) && !isEmpty(paymentRequest)) {
                switch (stepId) {
                    case 'notify':
                        result = true
                        break;
                    case 'collect':
                        result = paymentRequest.status === PAID_STATUS_INT
                            || paymentRequest.status === FAILED_SYNC_STATUS_INT
                            || paymentRequest.status === STATUS_PAID_PENDING_SYNC_INT
                            || paymentRequest.status === STATUS_PAID_EXTERNALLY_INT
                            || paymentRequest.status === STATUS_PAID_PARTIAL_INT;
                        break;
                    case 'record':
                        result = paymentRequest.status === PAID_STATUS_INT
                        break;
                    case 'reconcile':
                        result = false
                        break;
                    default:
                        result = false;
                        break;
                }
            }

            console.log(stepId + " => " + result);
        } catch (e) { console.log(e) }

        return result
    };



    render() {
        const { classes, t, routeProps } = this.props;
        const {
            notFound,
            isLoading,
            paymentRequest,
            actionType,
            isImageError,
            phoneNotification,
            emailNotification
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                {notFound ? (
                    <ResourceNotFound />
                ) : (
                    <div>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <HeaderDetailView
                                        title={nullController(
                                            paymentRequest,
                                            "name"
                                        )}
                                        description={nullController(
                                            paymentRequest,
                                            "description"
                                        )}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <div className={classes.rightPaper}>
                                        <CustomButton
                                            variant="outlined"
                                            color="white"
                                            onClick={() =>
                                                this.openForm(1, paymentRequest)
                                            }
                                            className={classes.cancelButton}
                                        >
                                            {t("EDIT_TEXT")}
                                        </CustomButton>
                                    </div>
                                    {paymentRequest !== null &&
                                    (paymentRequest.status === STATUS_PENDING ||
                                        paymentRequest.status ===
                                            STATUS_CANCELLED) ? (
                                        <div className={classes.rightPaper}>
                                            <CustomButton
                                                variant="outlined"
                                                color="white"
                                                onClick={() =>
                                                    this.openCancelForm()
                                                }
                                                className={classes.cancelButton}
                                            >
                                                {t("CHANGE_TEXT") +
                                                    " " +
                                                    t("TH_STATUS")}
                                            </CustomButton>
                                        </div>
                                    ) : null}
                                    {paymentRequest !== null && paymentRequest.status === STATUS_PENDING ? (
                                        <div className={classes.rightPaper}>
                                            <CustomButton
                                                variant="outlined"
                                                color="white"
                                                onClick={() => this.handleOpenNotificationModal()}
                                                className={classes.cancelButton}
                                            >
                                                {t("NOTIFY")}
                                            </CustomButton>
                                        </div>
                                    ) : null}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.stepPathContainer}>
                                        <StepPathComponent steps={[
                                            {
                                                position: 0,
                                                stepName:  t('NOTIFY'),
                                                stepId: "notify",
                                                stepEnabled: this.getActivePathStatus('notify', paymentRequest)
                                            },
                                            {
                                                position: 1,
                                                stepName: t('COLLECT'),
                                                stepId: "collect",
                                                stepEnabled: this.getActivePathStatus('collect', paymentRequest)
                                            },
                                            {
                                                position: 2,
                                                stepName: t('RECORD'),
                                                stepId: "record",
                                                stepEnabled: this.getActivePathStatus('record', paymentRequest)
                                            },
                                            {
                                                position: 3,
                                                stepName: t('RECONCILE'),
                                                stepId: "reconcile",
                                                stepEnabled: this.getActivePathStatus('reconcile', paymentRequest)
                                            }
                                        ]}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <PaymentRequestDetails
                                        data={paymentRequest}
                                        loading={isLoading}
                                        isImageError={isImageError}
                                        onUpdate={this.handleUpdateScreen}
                                    />
                                </GridItem>
                                {nullController(
                                    paymentRequest,
                                    "integrationData"
                                ) !== "" ? (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div
                                            className={classes.columnContainer}
                                        >
                                            <div>
                                                <h5
                                                    className={
                                                        classes.noPadding
                                                    }
                                                >
                                                    {" "}
                                                    {t(
                                                        "OTHER_INFORMATION_TEXT"
                                                    )}{" "}
                                                </h5>
                                            </div>
                                            <TextField
                                                disabled={true}
                                                readOnly={true}
                                                draggable={false}
                                                multiline
                                                className={
                                                    classes.disabledTextArea
                                                }
                                                rowsMax={7}
                                                variant={"outlined"}
                                                defaultValue={parseOtherInformation(
                                                    nullController(
                                                        paymentRequest,
                                                        "integrationData"
                                                    )
                                                )}
                                            />
                                        </div>
                                    </GridItem>
                                ) : null}
                            </GridContainer>
                        )}
                    </div>
                )}
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t("PAYMENT_REQUEST_TXT")}
                        <Muted>{t("FORM_PAYMENT_REQUEST_DESCRIPTION")}</Muted>
                    </DialogTitle>
                    <DialogContent>
                        <PaymentRequestForm
                            closeForm={this.closeForm}
                            action={actionType}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openCancel}
                    onClose={this.handleCloseCancelForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("PAYMENT_REQUEST_STATUS_TXT")}
                    </DialogTitle>
                    <DialogContent>
                        <GridContainer>
                            {paymentRequest !== null &&
                            (paymentRequest.status === STATUS_CANCELLED ||
                                paymentRequest.status === STATUS_PENDING) ? (
                                <GridItem xs={12} sm={8} md={8}>
                                    <div
                                        className={classes.selectControlBottom}
                                    >
                                        <InputLabel
                                            htmlFor="status"
                                            className={classes.selectTitle}
                                        >
                                            {t("TH_STATUS")}
                                        </InputLabel>
                                        <Select
                                            value={this.state.status}
                                            onChange={this.changeStatusSelect}
                                            options={this.state.statusOptions}
                                            isDisabled={this.state.disableField}
                                            placeholder={t("SELECT_TEXT")}
                                            closeMenuOnSelect={true}
                                            maxMenuHeight={140}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: primaryColor[0],
                                                },
                                            })}
                                        />
                                    </div>
                                </GridItem>
                            ) : null}
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.rightPaper}>
                                    <CustomButton
                                        color="white"
                                        className={
                                            classes.buttonSpacing +
                                            " " +
                                            classes.cancelButton
                                        }
                                        disabled={this.state.disableField}
                                        onClick={() =>
                                            this.handleCloseCancelForm()
                                        }
                                    >
                                        {t("BTN_CANCEL")}
                                    </CustomButton>
                                    <CustomButton
                                        color="primary"
                                        className={classes.buttonSpacing}
                                        onClick={() =>
                                            this.handleStatusPaymentRequest()
                                        }
                                        disabled={
                                            this.state.disableField ||
                                            paymentRequestDetails.status ===
                                                this.state.status.value
                                        }
                                    >
                                        {t("BTN_SAVE_CHANGES")}
                                    </CustomButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                </Dialog>

                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openNotificationModal}
                    onClose={() => { this.setState({ openNotificationModal: false })}}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("NOTIFICATION_CHANNEL")}
                        <Muted>
                            {t('CHOOSE_NOTIFICATION_CHANNEL')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {
                                    !isEmpty(paymentRequest) && (!isEmpty(paymentRequest.email) || !isEmpty(paymentRequest.memberPhone))
                                        ? (
                                            <FormGroup>
                                                {
                                                    !isEmpty(paymentRequest) && !isEmpty(paymentRequest.email) ? (
                                                        <FormControlLabel
                                                            className={classes.checkboxLabelGroup}
                                                            control={
                                                                <Checkbox
                                                                    value={emailNotification}
                                                                    checked={emailNotification}
                                                                    onClick={() => this.handleChangNotifications('emailNotification')}
                                                                    classes={{
                                                                        checked: classes.checkBoxChecked,
                                                                        root: classes.checkBox
                                                                    }}
                                                                />}
                                                            label={t('EMAIL_NOTIFICATIONS') + " (" + paymentRequest.email + ")"}
                                                        />
                                                    ) : null
                                                }

                                                {
                                                    !isEmpty(paymentRequest) && !isEmpty(paymentRequest.memberPhone) ? (
                                                        <FormControlLabel
                                                            className={classes.checkboxLabelGroup}
                                                            control={
                                                                <Checkbox
                                                                    value={phoneNotification}
                                                                    checked={phoneNotification}
                                                                    onClick={() => this.handleChangNotifications('phoneNotification')}
                                                                    classes={{
                                                                        checked: classes.checkBoxChecked,
                                                                        root: classes.checkBox
                                                                    }}
                                                                />}
                                                            label={t('PHONE_NOTIFICATIONS') + " (" + paymentRequest.memberPhone + ")"}
                                                        />
                                                    ) : null
                                                }
                                            </FormGroup>
                                        ) : <p className={classes.emptyFormText}>{t('MEMBER_HAS_NOT_NOTIFICATION_CHANNEL')}</p>
                                }
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.rightPaper}>
                                    <Button
                                        color="white"
                                        className={classes.buttonSpacing + " " + classes.cancelButton}
                                        onClick={() => { this.setState({ openNotificationModal: false })}}
                                    >
                                        {t('BTN_CANCEL')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        className={classes.buttonSpacing}
                                        disabled={!(!isEmpty(paymentRequest) && (!isEmpty(paymentRequest.email) || !isEmpty(paymentRequest.memberPhone)))}
                                    >
                                        {t('SEND_PAYMENT_NOTIFICATION')}
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsPaymentRequest.propTypes = {
    classes: PropTypes.object.isRequired,
};

function propsAreEqual(prevProps, nextProps) {
    return prevProps.match.params.id === nextProps.match.params.id;
}

export default React.memo(
    withRouter(withStyles(dashboardStyle)(withTranslation()(QuickActionsPaymentRequest))),
    propsAreEqual
);
