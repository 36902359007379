import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/SyncProblem";



function TaskIcon({ ...props }) {
    const { classes, label, icon, success } = props;


    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
            <p className={classes.cardThTxtPrimary}>
                {label}
            </p>

            {
                success
                    ? <DoneIcon className={classes.taskIconGreen} />
                    : <ErrorIcon className={classes.taskIconRed} />
            }

        </div>
    );
}

TaskIcon.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    icon: PropTypes.string,
    success: PropTypes.bool,
};

export default withStyles(dashboardStyle)(TaskIcon);
