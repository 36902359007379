import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-react/customCheckboxRadioSwitch.jsx";
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Select from "../../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import {selectValidation} from "../../../../utils/helpersFunctions";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";


const animatedComponents = makeAnimated();

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "0px"
    },
    ...customCheckboxRadioSwitch,
    ...formStyle
};

class IntegrationSettingsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setting: "setting",
        };
        this.isValidated = this.isValidated.bind(this);
        this.sendState = this.sendState.bind(this);
    }


    handleChangeValues = (e) => {
        this.props.component.handleChangeValues(e);
    };
    changeActionSelect = (action) => {
        this.props.component.changeActionSelect(action);
    };



    // Method for wizard
    sendState() {
        return this.state;
    }


    // Validate step form
    isValidated() {
        const { component, translation } = this.props;

        if (selectValidation(component.state.actions)) {
            component.showNotification(translation('INTEGRATION_ACTION_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

            return false;
        } else {
            return true;
        }
    }




    render() {
        const { classes, component, translation } = this.props;

        const ActionsAvailable = ({actions}) => (
            actions.map(value => (
                <div key={value.uuid} className={classes.scheduleContainer}>
                    <GridContainer>
                        <GridItem xs={12} sm={9} md={9}>
                            <Chip label={value.name}/>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <Tooltip id="tooltip-edit" title={translation('EDIT_TEXT')} placement="top-start">
                                <IconButton size={"small"} onClick={() => component.editAction(value)}>
                                    <EditIcon className={classes.iconButtonBlue}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip id="tooltip-delete" title={translation('DELETE_TEXT')} placement="top-start">
                                <IconButton size={"small"} onClick={() => component.deleteAction(value.uuid)}>
                                    <ClearIcon className={classes.iconButtonRed}/>
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                    </GridContainer>
                </div>
            ))
        );

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={9} md={8}>
                        <div style={{paddingBottom: 20}}>
                            <h5 className={classes.title2} style={{paddingTop: 0, marginTop: 0}}>{translation('ACTIONS_AVAILABLE_TXT')}</h5>
                            <GridContainer>
                                <GridItem xs={12} sm={10} md={10}>
                                    <Select
                                        value={component.state.action}
                                        onChange={this.changeActionSelect}
                                        components={animatedComponents}
                                        isLoading={component.state.loadingSetting}
                                        options={component.state.actionOptions}
                                        placeholder={translation('SELECT_TEXT')}
                                        closeMenuOnSelect={true}
                                        maxMenuHeight={200}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                        <ActionsAvailable actions={component.state.actions}/>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


IntegrationSettingsForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    translation: PropTypes.func.isRequired
};

export default withStyles(style)(IntegrationSettingsForm);
