import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReferenceIcon from "@material-ui/icons/Spellcheck";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    BASE64_HEADER,
    DATA_TYPE_SUPPORTED,
    DATA_TYPE_SUPPORTED_PDF,
    ERROR_REQUEST_MESSAGE,
    NOTIFICATION_DURATION, PDF_BASE64_HEADER
} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputUpload from "../../../components/CustomUpload/InputUpload";
import documentDetails from "../../../models/CompanyDocumentModel";
import {updateCompanyDocuments} from "../../../services/accountService";
import {
    disableComponent,
    enableComponent,
    isSuccessfulRequest
} from "../../../utils/helpersFunctions";
import Muted from "../../../components/Typography/Muted";


class CompanyDocumentsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            referenceId: documentDetails.referenceId,
            file: null,
            fileName: '',
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveDocument = this.saveDocument.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {}


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    cleanData() {
        this.setState({
            referenceId: '',
            file: null
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {}


    saveDocument() {
        if (this.state.referenceId === null || this.state.referenceId === '') {
            this.showNotification("Type the document reference id", "danger", NotificationErrorIcon, null);

        } else {
            let _file = "";

            if (this.state.file.indexOf(DATA_TYPE_SUPPORTED) !== -1) {
                _file = this.state.file.slice(BASE64_HEADER.length);

            } else if (this.state.file.indexOf(DATA_TYPE_SUPPORTED_PDF) !== -1) {
                _file = this.state.file.slice(PDF_BASE64_HEADER.length);
            }


            let dataParameters = {
                "id": documentDetails.id,
                "reference": this.state.referenceId,
                "file": _file,
            };

            disableComponent(this, "disableField");
            updateCompanyDocuments(dataParameters)
                .then((response) => {
                    let color = isSuccessfulRequest(response) ? "primary" : "danger";
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, color, NotificationSuccessIcon, true);
                        enableComponent(this, "disableField");

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, color, NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);

                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }

    handleSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if ((reader.result.indexOf(DATA_TYPE_SUPPORTED) !== -1) || (reader.result.indexOf(DATA_TYPE_SUPPORTED_PDF) !== -1)) {
                    this.setState({ file: reader.result });
                } else {
                    this.showNotification("The selected image is invalid.",
                        "danger",
                        NotificationErrorIcon
                    );
                }
            });

            this.setState({ fileName: e.target.files[0].name });
            reader.readAsDataURL(e.target.files[0]);
        }
    };



    render() {
        const { classes, t } = this.props;

        const SaveDocumentButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveDocument()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={11} sm={10} md={10}>
                        <CustomInput
                            value={this.state.referenceId}
                            onChange={this.handleChangeValues}
                            labelText={t('REFERENCE_ID_TEXT')}
                            id="referenceId"
                            name="referenceId"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ReferenceIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div>
                            <Muted>{this.state.fileName}</Muted>
                            <InputUpload
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                handleFile={e => this.handleSelectFile(e)}
                                label={t('BTN_UPLOAD_FILE')}
                                color={"white"}/>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <SaveDocumentButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

CompanyDocumentsForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(CompanyDocumentsForm)));
