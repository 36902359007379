import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { formTitles } from "../../utils/statusHelpers";
import Loading from "../../components/Loading/Loading";
import {
    DISCOUNT_TABLE_ACTIONS,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS,
} from "../../variables/coreConstants";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableActions from "../../components/TableActions/TableActions";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import {
    convertToYesNo,
    nullController,
    saveData,
} from "../../utils/helpersFunctions";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Snackbar from "../../components/Snackbar/Snackbar";
import discountDetail from "../../models/DiscountModel";
import { getDiscountByCompany } from "../../services/discountService";
import DiscountForm from "./components/DiscountForm";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";

class Discounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDiscount: false,
            isLoading: true,
            actionType: 0,
            discountData: [],
            filteredDiscounts: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        discountDetail.cleanDiscountDetails();
        this.setState({
            openDiscount: true,
            actionType: 0,
        });
    };
    handleCloseForm = () => {
        this.setState({ openDiscount: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        discountDetail.setDiscountDetails(formData);

        if (action === 1 || action === 0) {
            this.setState({
                openDiscount: true,
                actionType: action,
            });
        }
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openDiscount", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });

        getDiscountByCompany()
            .then((res) => {
                let newData = res.data.map(item => {
                    item.applyAllStoresLabel = convertToYesNo(item.applyAllStores);
                    item.applyAllProductsLabel = convertToYesNo(item.applyAllProducts);
                    return item;
                })
                this.setState({
                    discountData: newData,
                    filteredDiscounts: newData,
                    isLoading: false,
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    validateSession(err.response, this.props.history);
                }
            });
    };

    // Method to search data
    searchDiscount = (e) => {
        let newData = this.state.discountData;
        try {
            newData = newData.filter(function(item) {
                return (
                    item.name
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1 ||
                    item.code
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1
                );
            });
            this.setState({ filteredDiscounts: newData });
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    goToActions = (val) => {
        saveData("discount", val);
        this.props.history.push("quick-actions-discount");
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage, filteredDiscounts, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'discounts'}
                        title={t('DISCOUNTS_TEXT')}
                        exportFileName={t('DISCOUNTS_TEXT')}
                        data={filteredDiscounts}
                        actions={[
                            { label: t('BTN_ADD_DISCOUNT'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_DESCRIPTION'), name: "code", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_APPLY_STORE'), name: "applyAllStoresLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_APPLY_PRODUCT'), name: "applyAllProductsLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openDiscount}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} {t("DISCOUNT_TEXT")}
                            <Muted>{t("FORM_DISCOUNT_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <DiscountForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Discounts.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Discounts)));
