import React from 'react';
import PropTypes from "prop-types";


// onChange={date => handleDateChange(date)}

function DateTimePicker({ ...props }) {
    const {
        labelText,
        id,
        value,
        name,
        ampm,
        onChange,
        format,
    } = props;

    return null;
}

DateTimePicker.propTypes = {
    classes: PropTypes.object,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    format: PropTypes.string,
    ampm: PropTypes.bool,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    error: PropTypes.bool,
    success: PropTypes.bool
};


export default DateTimePicker;