import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    DOCUMENTS_REQUIRED_TABLE_ACTIONS, NOTIFICATION_DURATION,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS, STATUS_AWAITING_VALIDATION
} from "../../../variables/coreConstants";
import TableBody from "@material-ui/core/TableBody";
import {disableComponent, enableComponent, getUser, isSuccessfulRequest} from "../../../utils/helpersFunctions";
import Status from "../../../components/Status/Status";
import CompanyDocumentsForm from "./CompanyDocumentsForm";
import documentDetails from "../../../models/CompanyDocumentModel";
import {getDocumentsByCompany, submitVerificationRequest} from "../../../services/accountService";
import {validateSession} from "../../../services/api";
import Muted from "../../../components/Typography/Muted";
import Button from "../../../components/CustomButtons/Button";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingButton from "../../../components/CustomButtons/LoadingButton";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";


class CompanyDocumentsDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user: getUser(),
            openRequirements: false,
            openForm: false,
            isLoading: false,
            disableField: false,
            actionType: 0,
            documentsData: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
        this.submitVerificationRequest = this.submitVerificationRequest.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleOpenSubmitForm = () => {
        this.setState({ openRequirements: true });
        this.setState({ actionType: 1 });
    };
    handleCloseSubmitForm = () => {
        this.setState({ openRequirements: false });
    };

    handleOpenForm = () => {
        // menuDetails.cleanMenuDetails();
        this.setState({ openForm: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type)
            this.request();
    }
    openForm = (action, formData) => {
        documentDetails.setDocumentDetail(formData);
        this.setState({ openForm: true });
        this.setState({ actionType: action });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };



    request() {
        this.setState({ isLoading: true });
        let countInvalidDocument = 0;

        getDocumentsByCompany()
            .then((res) => {
                this.setState({ documentsData: res.data.length ? res.data : []});
                this.setState({ isLoading: false });

                res.data.forEach((value) => {
                    if (value.status !== STATUS_AWAITING_VALIDATION)
                        countInvalidDocument++;
                });

                if (res.data.length === 0) {
                    countInvalidDocument = 1;
                }

                let updatedUser = this.state.user;
                updatedUser.isDocumentUploaded = countInvalidDocument === 0;
                this.setState({ user: updatedUser });

            })
            .catch((err) => {
                this.setState({ isLoading: false });

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    submitVerificationRequest() {
        let user = this.state.user;

        if (user.isDocumentUploaded) {
            disableComponent(this, "disableField");
            submitVerificationRequest()
                .then((response) => {
                    if (isSuccessfulRequest) {
                        this.showNotification(response.data.message, "primary", NotificationErrorIcon);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon);
                    }
                    enableComponent(this, "disableField");
                })
                .catch((error) => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon);
                        validateSession(error.response, this.props.history);
                    }
                });
        } else {
            this.handleOpenSubmitForm();
        }
    }



    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage, disableField } = this.state;

        return (
            <GridContainer>
                <GridItem xs={6} sm={6} md={6} >
                    <div className={classes.typo}>
                        <h4>{t('REQUIRED_DOCUMENTS_DESCRIPTION')}</h4>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.rightPaper}>
                        <LoadingButton
                            color="primary"
                            disabled={disableField}
                            isLoading={disableField}
                            onClick={this.submitVerificationRequest}
                            text={t('BTN_VERIFY')}
                        />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card className={classes.card}>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_REFERENCE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STATUS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.documentsData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true} className={classes.tableRow}>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}>
                                                            {val.name}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>{val.reference}</TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    openForm={this.openForm}
                                                                    options={DOCUMENTS_REQUIRED_TABLE_ACTIONS}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.documentsData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openForm}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('REQUIRED_DOCUMENTS_DESCRIPTION')}
                        </DialogTitle>
                        <DialogContent>
                            <CompanyDocumentsForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={false}
                        open={this.state.openRequirements}
                        onClose={this.handleCloseSubmitForm}
                        aria-labelledby="form-submit-dialog-title"
                    >
                        <DialogTitle id="form-submit-dialog-title">
                            {t('FORM_SUBMIT_VERIFICATION_TEXT')}
                            <Muted>
                                {t('FORM_SUBMIT_VERIFICATION_MSG')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseSubmitForm} color="primary">
                                {t('BTN_CLOSE')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

CompanyDocumentsDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(CompanyDocumentsDetails)));
