import {getService, postService, putService} from "./api";
import {
    BASE_URL_CORE,
    APPLICATION,
    APPLICATION_BY_COMPANY,
    APPLICATION_BY_ZONE,
    ACCESS_LEVELS,
    TYPES_APPLICATIONS,
    APPS_AVAILABLE_BY_ZONE,
    APPLICATION_BY_ROLE,
    APPS_AVAILABLE_BY_ROLE,
    ALL_APPLICATION_BY_ROLE,
    TAGS_APP_TYPE,
    MENU_APP_SETTINGS,
    TAGS_AVAILABLE,
    PUBLIC_MENU_APPS,
    TIP_PERCENTAGES,
    PAYMENT_AMOUNT_OPTIONS
} from "../variables/apiConstants";
import applicationDetails from "../models/ApplicationModel";


export function getApplicationsByCompany() {
    let url = BASE_URL_CORE + APPLICATION_BY_COMPANY;

    return getService(url);
}


export function getApplicationsForRole(roleId) {
    let url = BASE_URL_CORE + ALL_APPLICATION_BY_ROLE;
    let dataParameters = {
        "role_id": roleId
    };

    return postService(url, dataParameters);
}


export function getAppsAvailableByZone(zoneId) {
    let url = BASE_URL_CORE + APPS_AVAILABLE_BY_ZONE;
    let dataParameters = {
        "zone_id": zoneId
    };

    return postService(url, dataParameters);
}

export function getApplicationsByZone(zoneId) {
    let url = BASE_URL_CORE + APPLICATION_BY_ZONE;
    let dataParameters = {
        "zone_id": zoneId
    };

    return postService(url, dataParameters);
}

export function getApplicationsByRole(roleId) {
    let url = BASE_URL_CORE + APPLICATION_BY_ROLE;
    let dataParameters = {
        "role_id": roleId
    };

    return postService(url, dataParameters);
}

export function getAppsAvailableByRole(roleId) {
    let url = BASE_URL_CORE + APPS_AVAILABLE_BY_ROLE;
    let dataParameters = {
        "role_id": roleId
    };

    return postService(url, dataParameters);
}

export function getAccessLevels() {
    let url = BASE_URL_CORE + ACCESS_LEVELS;
    return getService(url);
}

export function getTypesApplications() {
    let url = BASE_URL_CORE + TYPES_APPLICATIONS;
    return getService(url);
}

export function getPaymentAmountOptions() {
    let url = BASE_URL_CORE + PAYMENT_AMOUNT_OPTIONS;
    return getService(url);
}

export function getTagsAppType() {
    let url = BASE_URL_CORE + TAGS_APP_TYPE;
    let dataParameters = { "appTypeId": applicationDetails.type.value };

    return postService(url, dataParameters);
}

export function getTagsAvailableByApp() {
    let url = BASE_URL_CORE + TAGS_AVAILABLE;
    let dataParameters = { "appId": applicationDetails.id };

    return postService(url, dataParameters);
}

export function getMenuAppSettingsByApplication() {
    let url = BASE_URL_CORE + MENU_APP_SETTINGS;
    let dataParameters = { "appId": applicationDetails.id };

    return postService(url, dataParameters);
}

export function getTipPercentages() {
    let url = BASE_URL_CORE + TIP_PERCENTAGES;
    return getService(url);
}

export function addApplication(dataParameters) {
    let url = BASE_URL_CORE + APPLICATION;
    return postService(url, dataParameters);
}

export function editApplication(dataParameters, appId) {
    let url = BASE_URL_CORE + APPLICATION + "/" + appId;
    return putService(url, dataParameters);
}

export function getPublicApplicationsByStore(storeId) {
    let url = BASE_URL_CORE + PUBLIC_MENU_APPS;
    let dataParameters = {
        "storeId": storeId
    };

    return postService(url, dataParameters);
}



