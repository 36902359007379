import {checkComponentAccess} from "../../utils/helpersFunctions";

const PrivateComponent = ({appType, userType, ...props}) => {
    return (
            checkComponentAccess(appType, userType)
            ? (props.children)
            : null
    );
};

export default PrivateComponent;