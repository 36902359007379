import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {withTranslation} from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import Members from "./Members";
import Roles from "../Roles/Roles";
import {withRouter} from "react-router-dom";


class MemberContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }


    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };


    render() {
        const { classes, t, routeProps } = this.props;
        const { value } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <AppBar position={"static"} className={classes.tabBar}>
                            <Tabs value={value} onChange={this.handleChange} aria-label="tab">
                                <Tab label={t('MEMBERS_TEXT')} {...this.a11yProps(0)} />
                                {/*<Tab label={t('GROUPS_TEXT')} {...this.a11yProps(1)} />*/}
                                <Tab label={t('ROLES_TEXT')} {...this.a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <Members/>
                        </TabPanel>
                        {/*<TabPanel value={value} index={1}>*/}
                        {/*    <MemberGroups/>*/}
                        {/*</TabPanel>*/}
                        <TabPanel value={value} index={1}>
                            <Roles/>
                        </TabPanel>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

MemberContainer.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(MemberContainer)));
