import {getService, postPublicService, postService, putPublicService, putService} from "./api";
import {
    BASE_URL_ACCOUNT,
    VALIDATE_CREDENTIALS,
    CONFIRM_REGISTRATION,
    ACCOUNT,
    USER_INFORMATION,
    COMPANY,
    BASE_URL_CORE,
    PAYMENT_METHOD_PROVIDERS_BY_COMPANY,
    PAYMENT_METHOD_PROVIDERS_BY_COUNTRY,
    PAYMENT_METHOD_PROVIDER,
    BASE_URL_COMMERCE,
    COMPANY_INFORMATION,
    COMPANY_DOCUMENTS,
    DOCUMENT_REQUIRED,
    STORE_CONFIGURATION,
    SUBMIT_VERIFICATION_REQUEST,
    FORGOT_PASSWORD,
    REQUEST_VERIFICATION,
    RESET_PASSWORD,
    CHANGE_EMAIL,
    EMAIL_VERIFICATION,
    SET_DEFAULT_PAYMENT_PROVIDER,
    COMPANY_DEV_API_KEYS,
    COMPANY_API_KEY,
    REGISTER_NOTIFICATION_SERVICE,
    USERS_BY_COMPANY,
    DELEGATED_USER,
    PERMISSION_SET,
    PERMISSION_SET_AVAILABLE_BY_USER,
    COMPANY_LOGO,
    ACCOUNT_LINK,
    RESEND_CONFIRMATION_EMAIL, EVENT_NOTIFICATIONS_AVAILABLE, EVENT_NOTIFICATIONS
} from "../variables/apiConstants";

export function authentication(parameters) {
    let url = BASE_URL_ACCOUNT + VALIDATE_CREDENTIALS;
    return postPublicService(url, parameters);
}

export function createAccount(parameters) {
    let url = BASE_URL_ACCOUNT + ACCOUNT;
    return postPublicService(url, parameters);
}

export function companyRegistration(parameters) {
    let url = BASE_URL_ACCOUNT + COMPANY;
    return postService(url, parameters);
}

export function getUserInformation(parameters) {
    let url = BASE_URL_ACCOUNT + USER_INFORMATION;
    return postService(url, parameters);
}

export function getCompanyInformation() {
    let url = BASE_URL_CORE + COMPANY;
    return getService(url);
}

export function confirmAccount(token) {
    let url = BASE_URL_ACCOUNT + CONFIRM_REGISTRATION + token;
    return putPublicService(url, null);
}

export function getPaymentMethodProvidersByCompany() {
    let url = BASE_URL_COMMERCE + PAYMENT_METHOD_PROVIDERS_BY_COMPANY;
    return getService(url);
}

export function getPaymentMethodProvidersByCountry() {
    let url = BASE_URL_COMMERCE + PAYMENT_METHOD_PROVIDERS_BY_COUNTRY;
    return getService(url);
}

export function addPaymentMethodProvider(parameters) {
    let url = BASE_URL_COMMERCE + PAYMENT_METHOD_PROVIDER;

    return postService(url, parameters);
}

export function updateCompanyInformation(parameters) {
    let url = BASE_URL_CORE + COMPANY_INFORMATION;

    return putService(url, parameters);
}

export function getDocumentsByCompany() {
    let url = BASE_URL_CORE + COMPANY_DOCUMENTS;
    return getService(url);
}

export function updateCompanyDocuments(parameters) {
    let url = BASE_URL_CORE + DOCUMENT_REQUIRED;

    return putService(url, parameters);
}

export function getStoreConfiguration(parameters) {
    let url = BASE_URL_CORE + STORE_CONFIGURATION;

    return postService(url, parameters);
}

export function submitVerificationRequest() {
    let url = BASE_URL_CORE + SUBMIT_VERIFICATION_REQUEST;

    return postService(url, null);
}

export function submitForgotPassword(parameters) {
    let url = BASE_URL_ACCOUNT + FORGOT_PASSWORD;

    return postPublicService(url, parameters);
}
export function requestVerificationPassword(parameters) {
    let url = BASE_URL_ACCOUNT + REQUEST_VERIFICATION;

    return postPublicService(url, parameters);
}
export function resetPassword(parameters) {
    let url = BASE_URL_ACCOUNT + RESET_PASSWORD;

    return postPublicService(url, parameters);
}

export function requestChangeEmail(parameters) {
    let url = BASE_URL_ACCOUNT + CHANGE_EMAIL;

    return postService(url, parameters);
}

export function requestConfirmEmail(parameters) {
    let url = BASE_URL_ACCOUNT + EMAIL_VERIFICATION;

    return postPublicService(url, parameters);
}


export function setDefaultPaymentProvider(parameters) {
    let url = BASE_URL_COMMERCE + SET_DEFAULT_PAYMENT_PROVIDER;

    return putService(url, parameters);
}


export function getCompanyDevelopmentApiKeys() {
    let url = BASE_URL_CORE + COMPANY_DEV_API_KEYS;

    return getService(url);
}

export function generateApiKey() {
    let url = BASE_URL_CORE + COMPANY_API_KEY;

    return postService(url, null);
}


export function registerNotificationClient(parameters) {
    let url = BASE_URL_CORE + REGISTER_NOTIFICATION_SERVICE;

    return postService(url, parameters);
}

export function getDashboardUsersByCompany() {
    let url = BASE_URL_CORE + USERS_BY_COMPANY;

    return getService(url);
}

export function getPermissionsSet() {
    let url = BASE_URL_CORE + PERMISSION_SET;

    return getService(url);
}

export function getPermissionsSetAvailableByUser(userId) {
    let url = BASE_URL_CORE + PERMISSION_SET_AVAILABLE_BY_USER + userId;

    return getService(url);
}

export function createDelegatedUser(parameters) {
    let url = BASE_URL_CORE + DELEGATED_USER;

    return postService(url, parameters);
}

export function updateDelegatedUser(parameters) {
    let url = BASE_URL_CORE + DELEGATED_USER;

    return putService(url, parameters);
}

export function uploadCompanyLogo(parameters) {
    let url = BASE_URL_CORE + COMPANY_LOGO;

    return putService(url, parameters);
}

export function getAccountLinkInformation() {
    let url = BASE_URL_CORE + ACCOUNT_LINK;

    return getService(url);
}

export function createAccountLink(parameters) {
    let url = BASE_URL_CORE + ACCOUNT_LINK;

    return postService(url, parameters);
}

export function editAccountLink(parameters) {
    let url = BASE_URL_CORE + ACCOUNT_LINK;

    return putService(url, parameters);
}



export function resendConfirmationEmail(parameters) {
    let url = BASE_URL_ACCOUNT + RESEND_CONFIRMATION_EMAIL;
    return postPublicService(url, parameters);
}


export function getEventNotifications() {
    let url = BASE_URL_CORE + EVENT_NOTIFICATIONS;

    return getService(url);
}

export function getEventNotificationsAvailableByUser(userId) {
    let url = BASE_URL_CORE + EVENT_NOTIFICATIONS_AVAILABLE + userId;

    return getService(url);
}