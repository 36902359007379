import React from "react";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotesIcon from "@material-ui/icons/ShortText";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {withTranslation} from "react-i18next";
import Button from "../../../components/CustomButtons/Button";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {createAccountLink, editAccountLink, getAccountLinkInformation} from "../../../services/accountService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {validateSession} from "../../../services/api";
import {
    disableComponent,
    enableComponent,
    isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation
} from "../../../utils/helpersFunctions";
import {Typography} from "@material-ui/core";

const animatedComponents = makeAnimated();


class AccountLinkForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            suffix: "",
            assetId: "",
            prefix: "",
            stores: [],
            applications: [],
            storeOptions: [],
            applicationOptions: [],
            prefixOptions: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
            loading: false,
        };
    };


    componentDidMount(): void {
        this.setState({ loading: true });
        this.request();
    }


    changePrefixSelect = prefix => {
        this.setState({ prefix });
    };

    changeStoresSelect = stores => {
        this.setState({ stores });
    };
    changeAppsSelect = applications => {
        this.setState({ applications });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };

    cleanData = () => {
        this.setState({
            suffix: "",
            assetId: "",
            prefix: "",
            stores: [],
            applications: [],
        });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openLinkForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };



    request() {
        getAccountLinkInformation()
            .then(response => {
                let prefixValues = [];
                let storeValues = [];
                let appValues = [];
                let stores = [];
                let applications = [];
                let prefix = "";

                prefixValues = response.data.availablePrefixes.map(item => {
                    return { value: item.id, label: item.name };
                });

                storeValues = response.data.availableStores.map(item => {
                    return { value: item.id, label: item.name };
                });

                appValues = response.data.availableApplications.map(item => {
                    return { value: item.id, label: item.name };
                });

                if (response.data.assignedApplications !== null) {
                    applications = response.data.assignedApplications.map(item => {
                        return { value: item.id, label: item.name };
                    });
                }

                if (response.data.assignedStores !== null) {
                    stores = response.data.assignedStores.map(item => {
                        return { value: item.id, label: item.name };
                    });
                }

                if (response.data.prefix !== null) {
                    prefix = { value: response.data.prefix.id, label: response.data.prefix.name };
                }

                this.setState({
                    suffix: response.data.suffix,
                    assetId: response.data.assetId,
                    prefix: prefix,
                    stores: stores,
                    storeOptions: storeValues,
                    applications: applications,
                    applicationOptions: appValues,
                    prefixOptions: prefixValues,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({ loading: false });
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }


    saveAccountLink() {
        const { t } =  this.props;
        const { prefix, stores, suffix, applications } =  this.state;

        if (selectValidation(prefix) || selectValidation(stores) || suffix === "") {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationFailedIcon, null);

        } else {
            let storesId = stores.map( item => {
                return { uuid: item.value };
            });
            let appsId = applications.map( item => {
                return { uuid: item.value };
            });

            let dataParameters = {
                "suffix": suffix,
                "prefix": prefix.value,
                "stores": JSON.stringify(storesId),
                "applications": JSON.stringify(appsId)
            };

            disableComponent(this, "disableField");
            createAccountLink(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationFailedIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


    updateAccountLink() {
        const { t } =  this.props;
        const { prefix, stores, suffix, assetId, applications } =  this.state;

        if (selectValidation(prefix) || selectValidation(stores) || suffix === "") {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationFailedIcon, null);

        } else {
            let storesId = stores.map( item => {
                return { uuid: item.value };
            });
            let appsId = applications.map( item => {
                return { uuid: item.value };
            });

            let dataParameters = {
                "assetId": assetId,
                "suffix": suffix,
                "prefix": prefix.value,
                "stores": JSON.stringify(storesId),
                "applications": JSON.stringify(appsId)
            };

            disableComponent(this, "disableField");
            editAccountLink(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationFailedIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                            this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


        render() {
        const { classes, t, action } = this.props;

        const SaveButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={action ? () => this.updateAccountLink() : () => this.saveAccountLink()}
                disabled={this.state.disableField}
            >
                {action ? t('BTN_SAVE_CHANGES') : t('BTN_SAVE')}
            </Button>
        );


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="prefixId" className={classes.selectTitle}>
                                {t('PREFIX_TXT')}
                            </InputLabel>
                            <Select
                                value={this.state.prefix}
                                onChange={this.changePrefixSelect}
                                options={this.state.prefixOptions}
                                isDisabled={this.state.loading}
                                isLoading={this.state.loading}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={80}
                                isClearable={false}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.suffix}
                            onChange={this.handleChangeValues}
                            labelText={t('SUFFIX_TXT')}
                            id="suffix"
                            name="suffix"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <NotesIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                        {action ? (
                            <Typography className={classes.suffixWarning + " " + classes.negativeMargin}>
                                {t('SUFFIX_WARNING_TXT')}
                            </Typography>
                        ) : null}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="stores" className={classes.selectTitle}>
                                {t('STORES_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.stores}
                                onChange={this.changeStoresSelect}
                                components={animatedComponents}
                                options={this.state.storeOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={100}
                                isMulti
                                isDisabled={this.state.loading}
                                isLoading={this.state.loading}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={false} sm={false} md={6}/>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl} style={{marginTop: "30px"}}>
                            <InputLabel htmlFor="applications" className={classes.selectTitle}>
                                {t('APPLICATIONS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.applications}
                                onChange={this.changeAppsSelect}
                                components={animatedComponents}
                                options={this.state.applicationOptions}
                                placeholder={t('SELECT_TEXT')}
                                maxMenuHeight={100}
                                isMulti
                                isDisabled={this.state.loading}
                                isLoading={this.state.loading}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper} style={{marginTop:"50px"}}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openLinkForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <SaveButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }


}


AccountLinkForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(AccountLinkForm)));