import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Select from "../../components/CustomSelect/CustomSelect";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Loading from "../../components/Loading/Loading";
import { getTimeChecksByDate } from "../../services/memberService";
import { getStores } from "../../services/storeService";
import { convertDate, nullController } from "../../utils/helpersFunctions";
import { eventTagTypes } from "../../variables/tagConstants";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { formTitles } from "../../utils/statusHelpers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TimeCheckForm from "./components/TimeCheckForm";
import Dialog from "@material-ui/core/Dialog";
import {
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS,
} from "../../variables/coreConstants";
import { getRolesByCompany } from "../../services/roleService";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import { withTranslation } from "react-i18next";

class TimeChecks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openTimeCheck: false,
            isLoading: true,
            actionType: 0,
            storeValue: "",
            roleValue: "",
            date: { value: 0, label: "Today" },
            storesOptions: [],
            rolesOptions: [],
            timeCheckData: [],
            filteredTimeCheck: [],
            filteredArray: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            exportData: [],
            datetimeOptions: [
                { value: 0, label: "Today" },
                { value: 1, label: "Yesterday" },
                { value: 7, label: "Current week" },
                { value: 30, label: "Current month" },
                { value: 14, label: "Last week" },
                { value: 60, label: "Last month" },
            ],
        };
        this.closeForm = this.closeForm.bind(this);
        this.request = this.request.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    handleOpenForm = () => {
        this.setState({ openTimeCheck: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openTimeCheck: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }

    request() {
        this.setState({ isLoading: true });

        let dataParameters = {
            interval_days: this.state.date.value,
        };

        getTimeChecksByDate(dataParameters)
            .then((res) => {
                this.setState({ timeCheckData: res.data });
                this.setState({ filteredArray: res.data });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(res.data),
                });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });

        let storesValues;
        getStores()
            .then((res) => {
                storesValues = res.data.map((item) => {
                    return { value: item.id, label: item.name };
                });
                this.setState({ storesOptions: storesValues });
            })
            .catch((err) => {
                console.log(err);
                if (err.response)
                    validateSession(err.response, this.props.history);
            });

        let rolesValues;
        getRolesByCompany()
            .then((res) => {
                rolesValues = res.data.map((item) => {
                    return { value: item.id, label: item.name };
                });
                this.setState({ rolesOptions: rolesValues });
            })
            .catch((err) => {
                console.log(err);
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }

    // Method to request time checks by date
    changeDateSelect = (date) => {
        this.setState({ date });
        this.setState({ isLoading: true });

        let dataParameters = {
            interval_days: date.value,
        };

        getTimeChecksByDate(dataParameters)
            .then((res) => {
                this.setState({ timeCheckData: res.data });
                this.setState({ filteredArray: res.data });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(res.data),
                });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    // Method to filter time checks by store
    changeStoreSelect = (store) => {
        let storeValue = store;
        this.setState({ storeValue });

        try {
            let newData = this.state.timeCheckData;
            newData = newData.filter(function(item) {
                return (
                    item.store
                        .toLowerCase()
                        .search(storeValue.label.toLowerCase()) !== -1
                );
            });

            if (this.state.roleValue === "") {
                this.setState({ filteredArray: newData });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(newData),
                });
            } else {
                let role = this.state.roleValue;
                let finalData = newData;
                finalData = finalData.filter(function(item) {
                    return (
                        item.roleName
                            .toLowerCase()
                            .search(role.label.toLowerCase()) !== -1
                    );
                });

                this.setState({ filteredArray: finalData });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(finalData),
                });
            }
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    // Method to filter time checks by store
    changeRoleSelect = (role) => {
        let roleValue = role;
        this.setState({ roleValue });

        try {
            let newData = this.state.timeCheckData;
            newData = newData.filter(function(item) {
                return (
                    item.roleName
                        .toLowerCase()
                        .search(roleValue.label.toLowerCase()) !== -1
                );
            });

            if (this.state.storeValue === "") {
                this.setState({ filteredArray: newData });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(newData),
                });
            } else {
                let store = this.state.storeValue;
                let finalData = newData;
                finalData = finalData.filter(function(item) {
                    return (
                        item.store
                            .toLowerCase()
                            .search(store.label.toLowerCase()) !== -1
                    );
                });

                this.setState({ filteredArray: finalData });
                this.setState({
                    filteredTimeCheck: this.convertArrayToExport(finalData),
                });
            }
        } catch (e) {
            console.log("Something went wrong");
        }
    };

    // Method to search data
    searchMember = (e) => {
        let newData = this.state.filteredArray;
        try {
            newData = newData.filter(function(item) {
                return (
                    item.externalId
                        .toLowerCase()
                        .search(e.target.value.toLowerCase()) !== -1
                );
            });

            this.setState({
                filteredTimeCheck: this.convertArrayToExport(newData),
            });
        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    convertArrayToExport = (timeChecks) => {
        return timeChecks.map((item) => {
            return {
                id: item.id,
                externalId: item.externalId,
                name: item.firstName + " " + item.lastName,
                store: item.store,
                zone: item.zone,
                datetime: convertDate(item.datetime),
                eventType: eventTagTypes[item.eventType],
                roleName: item.roleName,
                activity: item.activity.name,
            };
        });
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                            <Input
                                onChange={this.searchMember}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon
                                            className={classes.objectColor}
                                        />
                                    </InputAdornment>
                                }
                                placeholder="Search member..."
                            />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={2}>
                            <div className={classes.selectFilter}>
                                <Select
                                    value={this.state.date}
                                    onChange={this.changeDateSelect}
                                    placeholder={"Date time"}
                                    defaultValue={this.state.datetimeOptions[0]}
                                    options={this.state.datetimeOptions}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={2}>
                            <div className={classes.selectFilter}>
                                <Select
                                    value={this.state.storeValue}
                                    onChange={this.changeStoreSelect}
                                    placeholder={"Store"}
                                    options={this.state.storesOptions}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={2}>
                            <div className={classes.selectFilter}>
                                <Select
                                    value={this.state.roleValue}
                                    onChange={this.changeRoleSelect}
                                    placeholder={"Role"}
                                    options={this.state.rolesOptions}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="primary"
                                    onClick={this.handleOpenForm}
                                >
                                    Add Attendance
                                </Button>
                            </div>
                            {/*<div className={classes.exportContainer}>
                                <ExportButton data={this.state.filteredTimeCheck} fileName={"Time Check"}/>
                            </div>*/}
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <Card>
                            <CardBody>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead
                                            className={classes.objectColor}
                                        >
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Store
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Zone
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Date Time
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Type
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Member External Id
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Role
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell +
                                                        " " +
                                                        classes.tableHeadCell
                                                    }
                                                >
                                                    Activity
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredTimeCheck
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage +
                                                        rowsPerPage
                                                )
                                                .map((val, key) => {
                                                    return (
                                                        <TableRow
                                                            key={key}
                                                            hover={true}
                                                        >
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell +
                                                                    " " +
                                                                    classes.hoverRow
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "name"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "store"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "zone"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "datetime"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "eventType"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "externalId"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "roleName"
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {nullController(
                                                                    val,
                                                                    "activity"
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredTimeCheck.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page",
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page",
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={
                                        this.handleChangeRowsPerPage
                                    }
                                />
                            </CardBody>
                        </Card>
                    )}
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openTimeCheck}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} Time Check
                            <Muted>
                                Add the email or external id of the member
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <TimeCheckForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }
}

TimeChecks.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(tableViewStyle)(withTranslation()(TimeChecks));
