import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import ExternalIcon from "@material-ui/icons/LocalOffer"
import DescriptionIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    addZoneGroupByStore, editZoneGroupByStore,
} from "../../../services/zoneService";
import {
    disableComponent,
    enableComponent,
    getData,
    isSuccessfulCreate, isSuccessfulRequest
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import zoneGroupDetails from "../../../models/ZoneGroupModel";



class ZoneGroupForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name: zoneGroupDetails.name,
            externalId: zoneGroupDetails.externalId,
            description: zoneGroupDetails.description,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveGroup = this.saveGroup.bind(this);
        // this.updateGroup = this.updateGroup.bind(this);
    }


    cleanData() {
        this.setState({
            name: '',
            description: '',
            externalId: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openGroup", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };



    saveGroup() {
        const { t } = this.props;

        if (this.state.name === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let store = getData("store");

            let dataParameters = {
                "name": this.state.name,
                "storeId": store.uuid,
                "externalId": this.state.externalId,
            };


            disableComponent(this, "disableField");
            addZoneGroupByStore(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        this.cleanData();

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }



    updateGroup() {
        const { t } = this.props;

        if (this.state.name === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let store = getData("store");

            let dataParameters = {
                "name": this.state.name,
                "storeId": store.uuid,
                "zoneGroupId": zoneGroupDetails.uuid,
                "externalId": this.state.externalId,
            };


            disableComponent(this, "disableField");
            editZoneGroupByStore(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        this.cleanData();

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }



    render() {
        const { classes, t } = this.props;

        const SaveGroupButton = (( ) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={this.props.action ? () => this.updateGroup() : () => this.saveGroup()}
                disabled={this.state.disableField}
            >
                {this.props.action ? t('BTN_SAVE_CHANGES') : t('BTN_SAVE_ZONE_GROUP')}
            </Button>
        ));



        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="name"
                            name="name"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText={t('EXTERNAL_ID_TEXT')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openGroup", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <SaveGroupButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ZoneGroupForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ZoneGroupForm)));
