import axios from "axios";
import {getData, getLanguage, removeSession, hexToBase64, isEmpty, mergerObjects} from "../utils/helpersFunctions";
import {KJUR} from "jsrsasign";
// import {enableComponent, isSuccessfulRequest} from "../utils/helpersFunctions";
// import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// import NotificationErrorIcon from "@material-ui/icons/Error";
// import {ERROR_REQUEST_MESSAGE} from "../variables/coreConstants";
// import NotificationFailedIcon from "@material-ui/icons/Cancel";


export async function getService(url, parameters) {
    if (parameters === null || parameters === undefined) {

        const signature = await getSignature(url, null, getData("userTk"));

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'Authorization': getData("userTk"),
                'accept-language': getLanguage(),
                'Signature': signature
            },
        });

    } else {
        const signature = await getSignature(url, parameters, getData("userTk"));

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'Authorization': getData("userTk"),
                'accept-language': getLanguage(),
                'Signature': signature
            },
            params: parameters,
        });
    }

}


export async function getPrivateService(url, parameters, customHeaders) {
    const signature = await getSignature(url, null, getData("directDebitAuth"));

    let headers = null;
    let defaultHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'Application/json',
        'Authorization': getData("directDebitAuth"),
        'accept-language': getLanguage(),
        'signature': signature,
        'x-secret-key': getData("userTk"),
    };

    headers = !isEmpty(customHeaders) ? mergerObjects(defaultHeaders, customHeaders) : defaultHeaders;

    if (parameters === null || parameters === undefined) {
        return axios.get(url, {
            headers: headers,
        });
    } else {
        return axios.get(url, {
            headers: headers,
            data: parameters,
        });
    }

}

export async function postService(url, parameters) {

    const signature = await getSignature(url, parameters, getData("userTk"));

    return axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'Authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature
        },
    });
}


export async function postPrivateService(url, parameters, customHeaders) {
    const signature = await getSignature(url, parameters, getData("directDebitAuth"));

    let headers = null;
    let defaultHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'Application/json',
        'Authorization': getData("directDebitAuth"),
        'accept-language': getLanguage(),
        'signature': signature,
        'x-secret-key': getData("userTk")
    };

    headers = !isEmpty(customHeaders) ? mergerObjects(defaultHeaders, customHeaders) : defaultHeaders;

    return axios.post(url, parameters, {
        headers: headers,
    });
}


export async function putService(url, parameters) {

    const signature = await getSignature(url, parameters, getData("userTk"));

    return axios.put(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature
        },
    });
}

export async function deleteService(url, parameters) {

    const signature = await getSignature(url, parameters, getData("userTk"));

    return axios.delete(url,  {
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature

        },
        data: parameters
    });
}


/**
 * Method validate if sessions has expired or not.
 * @param {object} response
 * @param {object} history
 **/
export function validateSession(response, history) {
    if (response.status === 403) {
        removeSession();
        history.push("/session-expired");
    }
}


export async function postPublicService(url, parameters){

    const signature = await getSignature(url, parameters, null);

        return axios.post(url, parameters, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
        });
}



export async function putPublicService(url, parameters) {

    const signature = await getSignature(url, parameters, null);

    return axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'accept-language': getLanguage(),
            'Signature': signature
        },
    });
}


export async function getPublicService(url, parameters) {


    if (parameters === null || parameters === undefined) {
        const signature = await getSignature(url, null, null);

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
        });

    } else {
        const signature = await getSignature(url, parameters, null);

        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
            params: parameters,
        });
    }

}

export async function getSignature (path, body, token){

    const message =  JSON.stringify(body) + '.' + token;
    // const message =  path + "." + JSON.stringify(body) + '.' + token + "." + new Date().getTime();
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;

    // RSA signature generation
    let sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});
    sig.init(privateKey);
    sig.updateString(message);
    
    let signature = await hexToBase64(sig.sign());


    return signature;
}


/*
export function saveService(url, parameters, component) {
    axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'Authorization': getData("userTk")
        },
    })
        .then((response) => {
            if (isSuccessfulRequest(response)) {
                component.cleanData();
                component.showNotification("Record added successfully.", "primary", NotificationSuccessIcon, true);

            } else {
                enableComponent(component);
                component.showNotification("Could not add new record.", "danger", NotificationErrorIcon, null);
            }
        })
        .catch(error => {
            enableComponent(component);
            if (error.response) {
                validateSession(error.response, component.props.history);
            } else {
                component.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                console.log(error)
            }
        });
}


export function editService(url, parameters, component) {
    axios.put(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'Authorization': getData("userTk")
        },
    })
        .then((response) => {
            if (isSuccessfulRequest(response)) {
                component.cleanData();
                component.showNotification("Record edited successfully.", "primary", NotificationSuccessIcon, true);

            } else {
                enableComponent(component);
                component.showNotification("Could not edit the record.", "danger", NotificationErrorIcon, null);
            }
        })
        .catch(error => {
            enableComponent(component);
            if (error.response) {
                validateSession(error.response, component.props.history);
            } else {
                component.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                console.log(error)
            }
        });
}
*/