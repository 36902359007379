var priceBookDetails = {
    id: null,
    name: '',
    description: '',
    isDefault: 0,

    cleanPriceBookDetails : function () {
        this.id = null;
        this.name = '';
        this.description = '';
        this.isDefault = 0;
    }
};

export default priceBookDetails;