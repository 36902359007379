/*
* Ginih Team
* Created by Marlon On February 17, 2020
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import { NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {withRouter} from "react-router-dom";
import categoryDetails from "../../../models/CategoryModel";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import DnDIcon from "@material-ui/icons/DragIndicator";
import {
    blackColor,
    hexToRgb,
    primaryColor,
} from "../../../assets/jss/material-dashboard-react";
import {
    disableComponent,
    enableComponent,
    isSuccessfulRequest
} from "../../../utils/helpersFunctions";
import {orderCategoriesByCatalog} from "../../../services/categoryService";
import {validateSession} from "../../../services/api";
import {withTranslation} from "react-i18next";


const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: '0 2px 2px 0',
    fontFamily: "sans-serif",
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    width: "90%",
    borderRadius: "6px",
    boxShadow: "1px 1px 1.5px 1px rgba(" + hexToRgb(blackColor) + ", 0.14)",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",


    // change background colour if dragging
    background: isDragging ? primaryColor[0] : "white",
    color: isDragging ? "white" : null,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#F5F5F5" : "white",
    padding: grid,
    fontFamily: "sans-serif",
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    borderRadius: "6px",
    width: "100%",
});



class CategoryOrderForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            category: '',
            categoryData: categoryDetails.list,
            loadingCategories: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: true,
        };
        this.changeOrder = this.changeOrder.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    componentDidMount() {}

    changeProductSelect = category => {
        this.setState({ category } );
    };

    cleanData() {
        this.setState({
            category: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.component.handleCloseForm("openCategory", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };



    changeOrder() {
        const { t } = this.props;

        // Create array of assignments, entityId support int so we can send the category priority (order) by this property
        try {
            let categoriesCatalog = this.state.categoryData.map((item, index) => {
                return { assignId: item.assignId, entityId: (index + 1) };
            });


            let dataParameters = {
                "categoriesOrder": JSON.stringify(categoriesCatalog)
            };

            disableComponent(this, "disableField");
            orderCategoriesByCatalog(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.messages, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });

        } catch (e) {
            this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, false);
        }
    }



    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    onDragEnd(result) {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const categoryData = this.reorder(
            this.state.categoryData,
            result.source.index,
            result.destination.index
        );

        this.setState({
            categoryData,
            disableField: false,
        });
    }




    render() {
        const { classes, component, t } = this.props;

        const SaveChangesButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.changeOrder()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card className={classes.card}>
                            <CardBody>
                                <div className={classes.tableResponsive}>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {this.state.categoryData.map((item, index) => (
                                                        <Draggable key={item.priority.toString()} draggableId={item.priority.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <DnDIcon className={snapshot.isDragging ? classes.objectColorWhite : classes.objectColorGrey}/>
                                                                    {item.name}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => component.handleCloseForm("openCategory", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <SaveChangesButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

CategoryOrderForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(CategoryOrderForm)));