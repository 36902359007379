var activityDetails = {
    id: null,
    name: "",
    description: "",
    externalId: "",
    duration: 0,
    owner: [{
        value: 0,
        label: "Select...",
    }],
    status: 0,

    cleanActivityDetails : function () {
        this.id = null;
        this.name = "";
        this.description = "";
        this.externalId = "";
        this.status = null;
        this.duration = 60;
        this.owner = [];
    }
};

export default activityDetails;