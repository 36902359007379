import React from "react";
// nodejs library to set properties for components
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent,
    enableComponent, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import Wizard from "../../../components/Wizard/Wizard";
import Snackbar from "../../../components/Snackbar/Snackbar";
import integrationDetails from "../../../models/IntegrationModel";
import IntegrationDetailsForm from "./wizard/IntegrationDetailsForm";
import IntegrationSettingsForm from "./wizard/IntegrationSettingsForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
    addIntegration, editIntegration, getIntegrationActions,
    getIntegrationObjects,
    getIntegrationSettingsByIntegration
} from "../../../services/integrationService";
import IntegrationActionForm from "./IntegrationActionForm";


class IntegrationForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name: integrationDetails.name,
            object: integrationDetails.object,
            action: integrationDetails.action,
            actions: [],
            objectOptions: [],
            actionOptions: [],
            loadingSetting: false,
            loadingDetail: false,
            disabledObject: false,
            openAction: false,
            actionType: 0,
            notificationMessage: '',
            notification: false,
            color: 'primary',
            icon: NotificationSuccessIcon,
            disableField: false,
            isSettingEnabled: true,
        };
        this.saveIntegration = this.saveIntegration.bind(this);
        this.updateIntegration = this.updateIntegration.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.closeForm = this.closeForm.bind(this);
    }


    componentDidMount() {
        this.setState({
            loadingDetail: true,
            loadingSetting: true,
        });
        this.request();
    }


    cleanData() {
        this.setState({
            name: '',
            object: '',
        });
    }

    closeForm(value) {
        this.setState({ openAction: value });
    };

    handleCloseForm = () => {
        this.setState({ openAction: false });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };


    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    changeObjectTypeSelect = (object) => {
        this.setState({ object });
        this.requestActions(object.value);
    };

    changeActionSelect = (action) => {
        this.addAction(action);
    };


    requestActions = (uuid) => {
        getIntegrationActions(uuid)
            .then(response => {
                let actionValues = response.data.map(item => {
                    return { value: item.uuid , label: item.name };
                });

                this.setState({ actionOptions: actionValues, loadingSetting: false });
            })
            .catch(error => {
                this.setState({ loadingDetails: false });

                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    };


    addAction = (action) => {
        const { t } = this.props;

        if (selectValidation(action)) {
            this.showNotification(t('SELECT_ACTION_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            integrationDetails.cleanActionDetails();
            let exist = false;
            if (integrationDetails.actions.length > 0) {
                let index = integrationDetails.actions.findIndex(val => val.uuid === action.value);

                if (index !== -1)
                    exist = true;
            }

            if (!exist) {
                integrationDetails.actionDetail.uuid = action.value;
                integrationDetails.actionDetail.name = action.label;

                this.setState({
                    action: '',
                    openAction: true,
                    actionType: 0,
                });
            } else {
                this.showNotification(t('ACTION_ALREADY_ADDED_MSG'), "danger", NotificationErrorIcon, null);
            }
        }
    };


    deleteAction = (uuid) => {
        let index = integrationDetails.actions.findIndex(val => val.uuid === uuid);
        if (index !== -1) {
            integrationDetails.actions.splice(index, 1);
            this.setState({ actions: integrationDetails.actions });
            integrationDetails.cleanActionDetails();
        }
    };


    editAction(values) {
        try {
            integrationDetails.cleanActionDetails();
            integrationDetails.actionDetail.uuid = values.uuid;
            integrationDetails.actionDetail.name = values.name;
            integrationDetails.actionDetail.endpoint = values.endpoint;
            integrationDetails.actionDetail.headers = typeof values.headers === "string" ? JSON.parse(values.headers) : values.headers;
            integrationDetails.actionDetail.settingUuid = values.settingUuid;

            this.setState({
                openAction: true,
                actionType: 1
            });
        } catch (e) {
            console.log("Something went wrong");
        }
    };


    // Method that request selects data
    request() {
        let objectValues;
        if (!this.props.action) {
            getIntegrationObjects()
                .then((res) => {
                    objectValues = res.data.map(item => {
                        return { value: item.uuid , label: item.name };
                    });

                    this.setState({
                        objectOptions: objectValues,
                        loadingDetail: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingDetails: false });

                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        } else {
            let actionValues;
            let object;
            let actionDetailValues = [];
            this.setState({ disabledObject: true });
            getIntegrationSettingsByIntegration(integrationDetails.uuid)
                .then((res) => {
                    const { data } = res;
                    if (data.objectIntegrations.length) {
                        objectValues = data.objectIntegrations.map(item => {
                            return { value: item.uuid , label: item.name };
                        });
                    }

                    if (data.integrationActions.length) {
                        actionValues = data.integrationActions.map(item => {
                            return { value: item.uuid , label: item.name };
                        });
                    }

                    if (data.objectIntegrationAssigned !== null && data.objectIntegrationAssigned.uuid !== null) {
                        object = { value: data.objectIntegrationAssigned.uuid, label: data.objectIntegrationAssigned.name };

                        if (data.objectIntegrationAssigned.integrationActionDetails.length
                            && data.objectIntegrationAssigned.integrationActionDetails.length > 0) {
                            actionDetailValues = data.objectIntegrationAssigned.integrationActionDetails.map(item => {
                                let _headers, array;
                                let count = 0;
                                try {
                                    array = JSON.parse(item.headers);
                                    _headers = array.map(val => {
                                        count++;
                                        return { id: count, name: val.name, value: val.value, isEditing: false, isUpdated: false}
                                    });
                                } catch (e) {
                                    console.log("Something went wrong");
                                    actionDetailValues = [];
                                }

                                return {
                                    uuid: item.uuid , name: item.name, settingUuid: item.settingUuid,
                                    endpoint: item.endpoint, headers: _headers
                                };
                            });
                        }
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }

                    integrationDetails.actions = actionDetailValues;
                    this.setState({
                        objectOptions: objectValues,
                        actionOptions: actionValues,
                        object: object,
                        actions: actionDetailValues,
                        loadingDetail: false,
                        loadingSetting: false,
                    });
                })
                .catch((err) => {
                    this.setState({ loadingDetail: false, loadingSetting: false });

                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        }
    }


    handleFinish(event) {
        if (this.props.action) {
            this.updateIntegration(event);
        } else {
            this.saveIntegration(event);
        }
    }

    saveIntegration(e) {
        if (this.state.name === "" || selectValidation(this.state.object) || selectValidation(this.state.actions)) {
            this.showNotification(this.props.t('INTEGRATION_FIELDS_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

        } else {
            let objectActions =[];
            try {
                objectActions = integrationDetails.actions.map(val => {
                    let values;
                    if (typeof val.headers === 'string') {
                        values = JSON.parse(val.headers);
                    } else {
                        values = val.headers;
                    }

                    let _headers = values.map(item => {
                        return {name: item.name, value: item.value};
                    });

                    return { endpoint: val.endpoint, name: val.name, uuid: val.uuid, settingUuid: val.settingUuid, headers: JSON.stringify(_headers)}

                });
            } catch (e) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                    NotificationFailedIcon, null);
            }

            if (selectValidation(objectActions)) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                    NotificationErrorIcon, null);
            } else {

                let integration = {
                    "name": this.state.name,
                    "objectUuid": this.state.object.value,
                    "objectActions": objectActions
                };

                let dataParameters = [];
                dataParameters.push(integration);


                disableComponent(this, "disableField");
                addIntegration(dataParameters)
                    .then((response) => {
                        if (isSuccessfulCreate(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary",
                                NotificationSuccessIcon, true);
                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger",
                                NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger",
                                NotificationFailedIcon, null);
                            validateSession(error.response.data.message, this.props.history);
                        } else {
                            this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                                NotificationFailedIcon, null);
                        }
                    });
            }
        }
    }



    updateIntegration(e) {
        const { t } = this.props;
        if (this.state.name === "" || selectValidation(this.state.object) || selectValidation(this.state.actions)) {
            this.showNotification(this.props.t('INTEGRATION_FIELDS_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

        } else {

            let objectActions = [];
            try {
                objectActions = integrationDetails.actions.map(val => {
                    let values;
                    if (typeof val.headers === 'string') {
                        values = JSON.parse(val.headers);
                    } else {
                        values = val.headers;
                    }

                    let _headers = values.map(item => {
                        return {name: item.name, value: item.value};
                    });

                    return { endpoint: val.endpoint, name: val.name, uuid: val.uuid, settingUuid: val.settingUuid, headers: JSON.stringify(_headers)}
                });
            } catch (e) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                    NotificationFailedIcon, null);
            }

            if (selectValidation(objectActions)) {
                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                    NotificationErrorIcon, null);
            } else {

                let integration = {
                    "uuid": integrationDetails.uuid,
                    "name": this.state.name,
                    "objectUuid": this.state.object.value,
                    "objectActions": objectActions
                };

                let dataParameters = [];
                dataParameters.push(integration);


                disableComponent(this, "disableField");
                editIntegration(dataParameters)
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary",
                                NotificationSuccessIcon, true);
                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger",
                                NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger",
                                NotificationFailedIcon, null);
                            validateSession(error.response.data.message, this.props.history);
                        } else {
                            this.showNotification(t('SOMETHING_WENT_WRONG'), "danger",
                                NotificationFailedIcon, null);
                        }
                    });
            }
        }
    }


    render(){
        const { classes, t } = this.props;

        return (
            <div className={classes.formContainer}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <Wizard
                            validate
                            component={this}
                            finishButtonText={this.props.action ? t('BTN_SAVE_CHANGES') : t('BTN_FINISH')}
                            steps={[
                                {
                                    stepName: t('STEP_DETAILS_TEXT'),
                                    stepComponent: IntegrationDetailsForm,
                                    stepId: "detail",
                                    stepEnabled: true
                                },
                                {
                                    stepName: t('STEP_SETTING_TEXT'),
                                    stepComponent: IntegrationSettingsForm,
                                    stepId: "setting",
                                    stepEnabled: true
                                }
                            ]}
                            color={"primary"}
                            withHeader={false}
                            fullWidth={true}
                            finishButtonClick={e => this.handleFinish(e)}
                            disableButton={this.state.disableField}
                            onlyOneStep={false}
                        />
                    </GridItem>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openAction}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('ACTION_TXT')}
                        </DialogTitle>
                        <DialogContent>
                            <IntegrationActionForm closeForm={this.closeForm} action={this.state.actionType} component={this}/>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

IntegrationForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(IntegrationForm)));
