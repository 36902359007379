import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
// Styles
import formStyle from "../../assets/jss/custom-components/formStyle";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import { components } from "react-select";
import {
    disableComponent,
    enableComponent, getData,
    getOs,
    getTimeZone, isEmpty, isSuccessfulCreate,
    isSuccessfulRequest, nullValidator, selectValidation, validateEmail, validateFields
} from "../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
import {withNotification} from "../../components/HOC/Notification/NotificationHOC";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/Button";
import CreditCardInput from "../../components/CreditCardInput/CreditCardInput";
import {getRegionsByCountry, getCountries, createMemberPaymentMethod} from "../../services/memberService";
import {validateSession} from "../../services/api";
import {NOTIFICATION_DURATION_LARGE} from "../../variables/coreConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";


const selectStyles = {
    control: base => ({
        ...base,
        fontSize: "12px"
    }),
    menu: base => ({
        ...base,
        fontSize: "14px",
    })
};


class PaymentMethodForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: false,
            phone: '',
            cardError: true,
            cardNumber: '',
            expiration: '',
            cvc: '',
            cardName: '',
            address: '',
            zip: null,
            countryOptions: [],
            regionOptions: [],
            callingCodeOptions: [],
            callingCode: '',
            callCode: '',
            region: '',
            city: '',
            country: '',
            isLoading: true,
            disableField: false
        };
    }

    componentDidMount() {
        const member = getData("member");

        if (!isEmpty(member) && window.location.pathname.includes('/admin/member-details/')) {
            this.setState({
                cardName: member.name,
                phone: member.phone,
                email: member.email
            });
        }

        getCountries(this.doneCountries, this.doneError);
    }

    doneCountries = (response) => {
        if (isSuccessfulRequest(response)) {
            let values = response.data.map((item, key) => {
                return { value: item.alpha2Code, label: item.name };
            });

            let valuesCallingCode = response.data.map((item, key) => {
                return { value: item.alpha2Code, code: item.callingCodes[0], label: "+" + item.callingCodes[0], flag: nullValidator(item, "alpha2Code") ? item.alpha2Code.toLowerCase() : null };
            });



            // let index = response.data.findIndex(val => val.alpha2Code.toLowerCase() === contextRouting.selectedCompany.country.toLowerCase());
            // let defaultCountry = index !== -1 ? { key: index + 1, value: response.data[index].alpha2Code, text: response.data[index].name } : '';
            // let defaultCallingCode = index !== -1 ? { key: index + 1, value: response.data[index].alpha2Code, text: response.data[index].callingCodes[0], flag: nullValidator(response.data[index], "alpha2Code") ? response.data[index].alpha2Code.toLowerCase() : null } : '';

            this.setState({
                countryOptions: values,
                callingCodeOptions: valuesCallingCode,
                // country: defaultCountry,
                // callingCode: defaultCallingCode,
                isLoading: false
            });
        } else {
            this.setState({ isLoading: false });
        }
    };


    doneRegion = (response, type) => {
        if (isSuccessfulRequest(response)) {
            let values = response.data.map((item, key) => {
                return { value: item.isoCode, label: item.name };
            });

            this.setState({ regionOptions: values, isLoading: false });
        } else {
            this.setState({ isLoading: false });
        }
    };



    doneError = (error, type) => {
        const { showNotificationMessage, t, history } = this.props;

        this.setState({ isLoading: false });
        enableComponent(this, "disableField");

        if (!isEmpty(error) && !isEmpty(error.response)) {
            if (!isEmpty(error.response.data)) {

                showNotificationMessage(error.response.data.message, "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
            } else {
                showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
            }

            validateSession(error.response, history);
        } else {
            showNotificationMessage(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
        }
    };


    handleRefreshRegions = (value) => {
        this.setState({ isLoading: true }, () => {
            getRegionsByCountry({ "countrys": value }, this.doneRegion, this.doneError);
        });
    };



    changeCountrySelect = country => {
        this.setState({ country });
        this.setState({ isLoading: true, region: '' }, () => {
            getRegionsByCountry({ "country": country.value }, this.doneRegion, this.doneError);
        });
    };

    changeRegionSelect = region => {
        this.setState({ region });
    };

    changeCallingCodeSelect = callingCode => {
        this.setState({ callingCode });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'email') {
            this.setState({
                [name]: value,
                emailError: !validateEmail(value)
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    };



    handleChangeCVC = (e) => {
        this.setState({
            cvc: e.target.value
        });
    };


    handleChangeExpiryDate = (e) => {
        this.setState({
            expiration: e.target.value
        });
    };


    handleChangeCardNumber = (e) => {
        this.setState({
            cardNumber: e.target.value
        });
    };


    handleChangeZipCode = (e) => {
        if (e.target.value.toString().length <= 7 && e.target.value >= 0) {
            this.setState({
                zip: e.target.value
            });
        }
    };

    handleError = (value) => {
        this.setState({
            cardError: value
        });
    };


    savePaymentMethod = () => {
        const { t, showNotificationMessage, memberId } = this.props;
        const { cardName, address, city, cardNumber, email, cvc, expiration, callingCode, phone, country, region, emailError } = this.state;

        let fields = [cardName, address, city, cardName, cardNumber, email, cvc, expiration, phone];

        if (validateFields(fields) && !emailError && !selectValidation(country) && !selectValidation(callingCode)
            && !selectValidation(region)) {

            let dataParameters = {
                "memberId": memberId,
                "cardDetails": {
                    "cardNumber": cardNumber.replaceAll(" ", ""),
                    "cardExpiration": expiration.replaceAll(" ", ""),
                    "cardCvc": cvc,
                    "cardHolderName": cardName,
                },
                "billingAddress": {
                    "city": city,
                    "country": country.value,
                    "phone": phone,
                    "callingCode": callingCode.code,
                    "name": cardName,
                    "addressLine1": address,
                    "addressLine2": "",
                    "addressState": region.value,
                    "addressZip": "",
                    "email": email,
                },
                "os": getOs(),
                "timezone": getTimeZone(),
            };

            disableComponent(this, "disableField");
            createMemberPaymentMethod(dataParameters, this.onCreateCompleted, this.doneError);

        } else {
            showNotificationMessage(t('INVALID_PAYMENT_METHOD_DATA'), "danger", NotificationFailedIcon, NOTIFICATION_DURATION_LARGE, null);
        }
    };


    onCreateCompleted = (response) => {
        const { showNotificationMessage } = this.props;

        if (isSuccessfulCreate(response)) {
            this.cleanStates();
            showNotificationMessage(response.data.message, "primary", NotificationSuccessIcon, NOTIFICATION_DURATION_LARGE, this.onUpdateList);

        } else {
            enableComponent(this, "disableField");
            showNotificationMessage(response.data.message, "danger", NotificationErrorIcon, NOTIFICATION_DURATION_LARGE, null);
        }
    };


    onUpdateList = () => {
        this.props.onCloseForm("openForm", false, true);
    };

    cleanStates = () => {
        this.setState({
            email: '',
            phone: '',
            cardNumber: '',
            expiration: '',
            cvc: '',
            cardName: '',
            address: '',
            zip: null,
            callingCode: '',
            region: '',
            city: '',
            country: ''
        });
    };

    
    

    render() {
        const { classes, t, onCloseForm } = this.props;
        const { disableField, isLoading } = this.state;


        const Options = ({ children, ...props}) => {
            return (
                <components.Option {...props}>
                    <div style={{display:"flex", flexDirection: "row"}}>
                        <img style={{marginTop: "4px", marginRight: "3px"}} width={"20px"} height={"16px"} alt={props.data.value} src={"https://flagcdn.com/84x63/" + props.data.value.toLowerCase() + ".png"}/>
                        {children}
                    </div>
                </components.Option>
            );
        };


        const SelectedValue = ({ children, ...props}) => {
            return (
                <components.SingleValue {...props}>
                    <div style={{display:"flex", flexDirection: "row"}}>
                        <img style={{marginTop: "3px", marginRight: "3px"}} width={"20px"} height={"16px"} alt={props.data.value} src={"https://flagcdn.com/84x63/" + props.data.value.toLowerCase() + ".png"}/>
                        {children}
                    </div>
                </components.SingleValue>
            );
        };



        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.fieldLabel}>{t('EMAIL_ADDRESS_TEXT') + "*"}</label>
                        <TextField
                            disabled={disableField || isLoading}
                            className={this.state.emailError ? classes.textFieldError : classes.textFieldPrimary}
                            error={this.state.emailError}
                            helperText={this.state.emailError ? t('INVALID_EMAIL_MESSAGE') : null}
                            style={{marginBottom: 10}}
                            variant="outlined"
                            fullWidth
                            autoFocus={true}
                            value={this.state.email}
                            onChange={this.handleChangeValues}
                            id="email"
                            name="email"
                            type="string"
                            margin="dense"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.fieldLabel}>{t('CARD_DETAILS_TEXT')+ "*"}</label>
                        <div style={{width: "99%", marginBottom: "10px", marginRight: "1%"}}>
                            <CreditCardInput
                                handleChangeCVC={this.handleChangeCVC}
                                handleChangeExpiryDate={this.handleChangeExpiryDate}
                                handleChangeCardNumber={this.handleChangeCardNumber}
                                setError={this.handleError}
                                cardNumber={this.state.cardNumber}
                                cvc={this.state.cvc}
                                expiration={this.state.expiration}/>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.fieldLabel}>{t('NAME_CARD_TEXT')+ "*"}</label>
                        <TextField
                            disabled={disableField || isLoading}
                            className={classes.textFieldPrimary}
                            style={{marginBottom: 10}}
                            variant="outlined"
                            fullWidth
                            value={this.state.cardName}
                            onChange={this.handleChangeValues}
                            id="cardName"
                            name="cardName"
                            type="string"
                            margin="dense"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <label className={classes.fieldLabel}>{t('PHONE_NUMBER_TEXT')+ "*"}</label>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <div className={classes.countrySelect}>
                                    <Select
                                        value={this.state.callingCode}
                                        styles={selectStyles}
                                        components={{ Option: Options, SingleValue: SelectedValue}}
                                        isDisabled={disableField || isLoading}
                                        isLoading={isLoading}
                                        maxMenuHeight={190}
                                        onChange={this.changeCallingCodeSelect}
                                        options={this.state.callingCodeOptions}
                                        placeholder={t('SELECT_TEXT')}
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                                <TextField
                                    disabled={disableField || isLoading}
                                    className={classes.fieldLabel}
                                    style={{marginTop: "0px"}}
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.phone}
                                    onChange={this.handleChangeValues}
                                    id="phone"
                                    name="phone"
                                    margin="dense"
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.selectContainer}>
                            <InputLabel htmlFor="country" className={classes.fieldLabel}>
                                {t('SELECT_COUNTRY_TEXT')+ "*"}
                            </InputLabel>
                            <Select
                                value={this.state.country}
                                isDisabled={disableField || isLoading}
                                isLoading={isLoading}
                                onChange={this.changeCountrySelect}
                                options={this.state.countryOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={150}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.selectContainer}>
                            <InputLabel htmlFor="region" className={classes.fieldLabel}>
                                {t('COUNTRY_REGION_TEXT')+ "*"}
                            </InputLabel>
                            <Select
                                value={this.state.region}
                                isDisabled={disableField || isLoading}
                                isLoading={isLoading}
                                onChange={this.changeRegionSelect}
                                options={this.state.regionOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={130}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.fieldLabel}>{t('CITY_TEXT')+ "*"}</label>
                        <TextField
                            disabled={disableField || isLoading}
                            className={classes.textFieldPrimary}
                            style={{marginBottom: 10}}
                            variant="outlined"
                            fullWidth
                            value={this.state.city}
                            onChange={this.handleChangeValues}
                            id="city"
                            name="city"
                            type="string"
                            margin="dense"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <label className={classes.fieldLabel}>{t('ADDRESS_TEXT')+ "*"}</label>
                        <TextField
                            disabled={disableField || isLoading}
                            className={classes.textFieldPrimary}
                            style={{marginBottom: 10}}
                            variant="outlined"
                            fullWidth
                            value={this.state.address}
                            onChange={this.handleChangeValues}
                            id="address"
                            name="address"
                            type="string"
                            margin="dense"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                disabled={disableField}
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => onCloseForm("openForm", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <Button

                                color="primary"
                                onClick={() => this.savePaymentMethod()}
                                className={classes.buttonSpacing}
                                disabled={disableField || isLoading}>
                                {t('BTN_SAVE')}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

PaymentMethodForm.propTypes = {
    classes: PropTypes.object,
    memberId: PropTypes.string,
    onCloseForm: PropTypes.func
};

export default withRouter(withStyles(formStyle)(withNotification(withTranslation()(PaymentMethodForm))));