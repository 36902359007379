import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import axios from 'axios';
import Snackbar from "../../../components/Snackbar/Snackbar";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Select from "../../../components/CustomSelect/CustomSelect";
import SearchAssignmentComponent from "../../../components/SearchAssignmentContainer/SearchAssignmentComponent";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {BASE_URL_CORE, SEARCH_MEMBERS_COMPANY} from "../../../variables/apiConstants";
import {searchService} from "../../../services/searchService";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {validateSession} from "../../../services/api";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import paymentTemplateDetails from "../../../models/PaymentTemplateModel";
import {getStoresAvailable} from "../../../services/storeService";
import Button from "../../../components/CustomButtons/Button";
import {disableComponent, enableComponent, isSuccessfulCreate, selectValidation} from "../../../utils/helpersFunctions";
import {assignTemplateToMembers} from "../../../services/paymentTemplateService";


class MemberTemplateAssignment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingStore: false,
            isRequesting: false,
            storeOptions: [],
            store: '',
            data: paymentTemplateDetails.members,
            assignedData: paymentTemplateDetails.memberAssigned,
            assignedDataFilter: paymentTemplateDetails.membersAssignedFiltered,
            selectedItems: paymentTemplateDetails.membersSelected,
            removedItems: paymentTemplateDetails.membersRemoved,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
            disableField: false,
        };
        this.saveAssignments = this.saveAssignments.bind(this);
    }

    componentDidMount(): void {
        this.request();
    }

    showNotification = (msj, color, icon, request) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return }
        this.setState({ notification: false });
    };


    cleanData = () => {
        paymentTemplateDetails.cleanMemberAssignments();
        this.setState({
            store: '',
            data: [],
            assignedData: [],
            assignedDataFilter: [],
            selectedItems: [],
            removedItems: [],
            disableField: false
        });
    };


    changeStoreSelect = (store) => {
        this.setState({ store });
    };


    searchMember = (value) => {
        let url = BASE_URL_CORE + SEARCH_MEMBERS_COMPANY;
        let dataParameters = {
            "value": value,
            "templateId": this.props.uuid
        };

        this.setState({ isRequesting: true });
        searchService(url, dataParameters)
            .then(response => {
                if (response.data !== null && response.data !== undefined) {
                    let values = response.data.map(item => {
                        return { name: item.name, uuid: item.uuid, externalId: item.externalId, isSelected: false, isAssigned: item.templateAssigned, status: item.status }
                    });

                    this.setState({ data: values });
                }

                this.setState({ isRequesting: false });
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    this.setState({ isRequesting: false });
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                }
            });
    };


    request = () => {
        this.setState({ isLoadingStore: true });
        getStoresAvailable()
            .then(response => {
                if (response.data !== null && response.data !== undefined) {
                    let values = response.data.map(item => {
                        return { label: item.name, value: item.id }
                    });

                    this.setState({ storeOptions: values });
                }

                this.setState({ isLoadingStore: false });
            })
            .catch(error => {
                this.setState({ isLoadingStore: false });
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null);
                }
            });
    };


    handleAddItem = (value) => {
        try {
            this.setState({ assignedData: value }, () => {console.log(this.state.assignedData)});

        } catch (e) {console.log(e)}

    };

    handleRemoveItem = (value) => {
        try {
            this.setState({ assignedData: value })

        } catch (e) {console.log(e)}
    };


    handleUpdateState = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleEnableAction = () => {
        const { store, assignedData } = this.state;
        let result = false;
        try {
            result = selectValidation(assignedData) || selectValidation(store);

        } catch (e) {}

        return result;
    };

    saveAssignments() {
        const { t, uuid } = this.props;
        const { assignedData, store } = this.state;

        if (this.handleEnableAction()) {
            this.showNotification(t('ALL_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let members = assignedData.map( item => {
                return { uuid: item.uuid };
            });

            let dataParameters = {
                "templateId": uuid,
                "members": JSON.stringify(members),
                "storeId": store.value
            };

            disableComponent(this, "disableField");
            assignTemplateToMembers(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });
        }
    }


    render() {
        const { t } = this.props;
        const { data, assignedData, assignedDataFilter,
            selectedItems, isRequesting, removedItems, disableField } = this.state;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} dismissRule>
                        <div style={{width: 300, marginBottom: 20 }}>
                          <Select
                              value={this.state.storeValue}
                              onChange={this.changeStoreSelect}
                              placeholder={t('STORE_TEXT')}
                              isDisabled={disableField}
                              options={this.state.storeOptions}
                              closeMenuOnSelect={true}
                              maxMenuHeight={350}
                              theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: primaryColor[0],
                                },
                              })}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 5 })
                              }}
                          />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} dismissRule>
                        <div style={{float: "right", marginBottom: 0}}>
                            <Button
                                disabled={this.handleEnableAction() || disableField}
                                color="primary"
                                onClick={() => this.saveAssignments()}>
                                {t('BTN_ASSIGN')}
                            </Button>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} dismissRule>
                        <SearchAssignmentComponent
                            isLoading={isRequesting}
                            data={data}
                            warningText={t('TEMPLATE_ALREADY_ASSIGNED_MSG')}
                            errorText={t('MEMBER_REJECTED_MSG')}
                            infoText={t('TEMPLATE_ALREADY_ASSIGNED_MEMBER_REJECTED_MSG')}
                            assignedData={assignedData}
                            itemsAssignedFilter={assignedDataFilter}
                            selectedItems={selectedItems}
                            removedItems={removedItems}
                            onUpdateData={this.handleUpdateState}
                            onSearch={this.searchMember}
                            onAdd={this.handleAddItem}
                            onRemove={this.handleRemoveItem} />
                    </GridItem>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.uuid === nextProps.uuid;
}

MemberTemplateAssignment.propTypes = {
    uuid: PropTypes.string.isRequired,
};


export default React.memo(withRouter(withTranslation()(MemberTemplateAssignment)), propsAreEqual);