
var documentDetails = {
    id: null,
    name: "",
    referenceId: "",
    status: null,
    file: null,

    setDocumentDetail : function (values) {
        try {
            this.id = values.id;
            this.name = values.name;
            this.referenceId = values.reference;
            this.status = values.status;
            this.file = values.fileUrl;

        } catch (e) {
            console.log("Something went wrong");
        }
    },


    cleanDocumentDetails : function () {
        this.id = null;
        this.name = "";
        this.referenceId = "";
        this.status = null;
        this.file = null;
    },

};

export default documentDetails;