import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import AnalyticCard from "../../../components/Card/AnalyticCard";
import {getRoundNumber, setCurrencyValue} from "../../../utils/helpersFunctions";


class HomeFeedCards extends React.Component {

    render() {
        const { data, loading, t } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={false} sm={4} md={4}/>
                    <GridItem xs={12} sm={4} md={4}>
                        <AnalyticCard
                            loading={loading}
                            currencySymbol={data.currencySymbol ? data.currencySymbol : ""}
                            value={data.totalDefaultedAmount ? (setCurrencyValue(getRoundNumber(parseFloat(data.totalDefaultedAmount)))) : setCurrencyValue(getRoundNumber(0))}
                            title={t('TOTAL_DEFAULTED_AMOUNT_TXT')}
                            icon={null}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <AnalyticCard
                            loading={loading}
                            currencySymbol={""}
                            value={data.totalDefaultingMembers ? parseInt(data.totalDefaultingMembers) : 0}
                            title={t('TOTAL_DEFAULTING_MEMBERS_TXT')}
                            icon={null}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

HomeFeedCards.defaultProps = {
    data: {
        totalDefaultedAmount: "0.00",
        totalDefaultingMembers: "0",
        currencySymbol: ""
    }
};

HomeFeedCards.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    loading: PropTypes.bool,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(HomeFeedCards)));