import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {formTitles} from "../../utils/statusHelpers";
import {
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE
} from "../../variables/coreConstants";
import Muted from "../../components/Typography/Muted";
import {withTranslation} from "react-i18next";
import {validateSession} from "../../services/api";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../components/Snackbar/Snackbar";
import {getIntegrationByCompany} from "../../services/integrationService";
import integrationDetails from "../../models/IntegrationModel";
import IntegrationForm from "./components/IntegrationForm";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Integrations extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openForm: false,
            isLoading: false,
            actionType: 0,
            integrationData: [],
            filteredIntegrations: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null) {
                    this.closeForm("openForm", false, refresh);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };

    handleOpenForm = () => {
        integrationDetails.cleanIntegrationDetails();
        this.setState({ openForm: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type) {
            this.request();
        }
    }
    openForm = (action, formData) => {
        integrationDetails.setIntegrationDetail(formData);

        if (action === 1 || 0) {
            this.setState({ openForm: true, actionType: action });
        } else {}
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value, page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });

        getIntegrationByCompany()
            .then((res) => {
                this.setState({
                    integrationData: res.data,
                    filteredIntegrations: res.data,
                    isLoading: false
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    };


    render() {
        const { classes, t } = this.props;
        const { actionType, isLoading, filteredIntegrations, openForm } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'integrations'}
                        title={t('INTEGRATIONS_TEXT')}
                        exportFileName={t('INTEGRATIONS_TEXT')}
                        data={filteredIntegrations}
                        actions={[
                            { label: t('BTN_ADD_INTEGRATION'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={openForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {actionType ? (t(formTitles[actionType]) + t('INTEGRATION_TEXT')) : t('FORM_INTEGRATION_TITLE')}
                            <Muted>
                                {t('FORM_INTEGRATION_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <IntegrationForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

Integrations.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Integrations)));
