import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../components/Loading/Loading";
import CardBody from "../../../components/Card/CardBody";
import {
    getRoundNumber,
    getUser, isEmpty,
    nullController,
    nullValidator, numberValidator, saveData, setCurrencyValue
} from "../../../utils/helpersFunctions";
import Avatar from "@material-ui/core/Avatar";
import UserIcon from "@material-ui/icons/AccountCircle";
import CallIcon from "@material-ui/icons/PhoneInTalk";
import Status from "../../../components/Status/Status";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CopyIcon from "@material-ui/icons/FilterNone";
import Tooltip from "@material-ui/core/Tooltip";
import PaymentRequestAttemptList from "./PaymentRequestAttemptList";


class PaymentRequestDetails extends React.Component {

    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(nullController(val, "link"));
        } catch (e) {
            console.log("Something went wrong");
        }
    };

    openLink = (data) => {
        saveData("member", { uuid: data.memberId });
        this.props.history.push("/admin/member-details/" + data.memberId);
    };


    render() {
        const { data, loading, classes, t, isImageError } = this.props;
        const currency = nullValidator(data, "currencyCode") ? data.currencyCode : getUser().currency;
        const attemptList = !isEmpty(data) && !isEmpty(data.paymentAttempList) ? data.paymentAttempList : [];

        return (
            <div>
                {
                    loading ? <Loading/> : (
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <p className={classes.statusCardTitle}>{t('TH_PAYMENT_REQUEST_STATUS')}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <div className={classes.memberStatusRow}>
                                                    <Status
                                                        className={classes.statusCard}
                                                        color={data !== null ? statusColors[data.status] : "default"}
                                                        size="lg"
                                                        label={data !== null ? t(statusText[data.status]) : "undefined"}
                                                    />
                                                </div>
                                            </GridItem>
                                            {
                                                (!isEmpty(data) && !isEmpty(data.paymentInformation)
                                                    && !isEmpty(data.paymentInformation.methodInformation)) ? (
                                                    <GridItem xs={12} sm={12} md={12} dismissRule>
                                                        <p className={classes.paymentCardBodyText}>{t('PAYMENT_METHOD') + ": "
                                                        + (nullController(data.paymentInformation.methodInformation, "name")
                                                            + (nullController(data.paymentInformation.methodInformation, "lastDigits") !== null
                                                            && nullController(data.paymentInformation.methodInformation, "lastDigits") !== ""
                                                            ? t('ENDING_IN_TEXT') + data.paymentInformation.methodInformation.lastDigits : ""))}
                                                        </p>
                                                        <p className={classes.paymentCardBodyText}>{t('AUTHORIZATION_BANK') + ": " + nullController(data.paymentInformation, "transactionId")}</p>
                                                        <p className={classes.paymentCardBodyText}>{t('PAYMENT_DATE') + ": " + nullController(data.paymentInformation, "paymentDate")}</p>
                                                        <p className={classes.paymentCardBodyText}>{t('INVOICE_REF') + ": " + nullController(data, "billReference")}</p>
                                                    </GridItem>
                                                ) : null
                                            }

                                        </GridContainer>
                                    </CardBody>
                                </Card>
                                {
                                    nullValidator(data, "link") ? (
                                        <Card>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={8} md={9} dismissRule>
                                                        <p className={classes.statusCardTitle}>{t('TH_LINK')}</p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={4} md={3} dismissRule>
                                                        <div className={classes.rightPaper}>
                                                            <Tooltip id={data.uuid} title={t('COPY_TEXT') + " " + data.link} placement="top-start">
                                                                <IconButton
                                                                    style={{marginRight: 10}}
                                                                    size={"small"}
                                                                    onClick={() => this.handleCopyToClipboard(data)}>
                                                                    <CopyIcon className={classes.copyButtonIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </GridItem>
                                                    {
                                                        data.link !== null && data.link !== "" ? (
                                                            <GridItem xs={12} sm={12} md={12} dismissRule>
                                                                <p className={classes.paymentCardBodyText}>{nullController(data, "link")}</p>
                                                            </GridItem>
                                                        ) : null
                                                    }
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }

                                {
                                    nullValidator(data, "memberExternalId")
                                    || nullValidator(data, "memberName") ? (
                                        <Card>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={8} md={8} dismissRule>
                                                        <div className={classes.memberProfileRow}>
                                                            {data !== null && data.userPhoto && !isImageError
                                                                ? <Avatar alt="Profile" src={data.userPhoto} className={classes.iconAvatar}/>
                                                                : <Avatar className={classes.iconAvatar}>
                                                                    <UserIcon className={classes.iconAvatarBlue} />
                                                                </Avatar>
                                                            }

                                                            <Button onClick={() => this.openLink(data)} className={classes.memberCardName}>
                                                                {nullController(data, "memberName") + (!isEmpty(data) && nullValidator(data, "memberExternalId") ? (" - " + nullController(data, "memberExternalId")) : "")}
                                                            </Button>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={4} md={4} dismissRule>
                                                        <div className={classes.rightPaper}>
                                                            {
                                                                nullController(data, "memberPhone") !== null
                                                                && nullController(data, "memberPhone") !== "" ? (
                                                                    <div className={classes.memberPhoneRow}>
                                                                        <p className={classes.memberCardPhone}>{nullController(data, "memberPhone")}</p>
                                                                        <CallIcon className={classes.iconAvatarBlue}/>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }

                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} dismissRule>
                                                <p className={classes.statusCardTitle}>{t('DETAILS_TEXT')}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={5} md={5} dismissRule>
                                                <p className={classes.paymentCardBodyText}>{t('EXPIRATION_DATE_TEXT')}</p>
                                                <p className={classes.paymentCardBodyText}>{t('TH_EXTERNAL_ID')}</p>
                                                <p className={classes.paymentCardBodyText}>{t('UNIT_PRICE')}</p>
                                                <p className={classes.paymentCardBodyText}>{t('SURCHARGE_TEXT')}</p>
                                                { numberValidator(data, "dueSurcharge", 0) ? <p className={classes.paymentCardBodyText}>{t('TH_DUE_SURCHARGE')}</p> : null }
                                                <p className={classes.paymentCardBodyText}>{t('DISCOUNT_TEXT')}</p>
                                                { numberValidator(data, "earlyDiscount", 0) ? <p className={classes.paymentCardBodyText}>{t('EARLY_DISCOUNT_TEXT')}</p> : null }
                                                <p className={classes.paymentCardBodyText}>{t('TAX_TEXT')}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={7} md={7} dismissRule>
                                                <div className={classes.rightPaper}>
                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {nullController(data, "expirationDate")}
                                                    </p>
                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {nullController(data, "externalId") === "" ? "- - -" : nullController(data, "externalId")}
                                                    </p>
                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {currency + setCurrencyValue(getRoundNumber(nullController(data, "amount")))}
                                                    </p>
                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {currency + setCurrencyValue(getRoundNumber(nullController(data, "surcharge")))}
                                                    </p>

                                                    {
                                                        numberValidator(data, "dueSurcharge", 0) ? (
                                                            <p className={classes.paymentDetailCardValueText}>
                                                                {currency + setCurrencyValue(getRoundNumber(nullController(data, "surcharge")))}
                                                            </p>
                                                    ) : null }

                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {"- " + currency + setCurrencyValue(getRoundNumber(nullController(data, "discount")))}
                                                    </p>

                                                    {
                                                        numberValidator(data, "earlyDiscount", 0) ? (
                                                            <p className={classes.paymentDetailCardValueText}>
                                                                {"- " + currency + setCurrencyValue(getRoundNumber(nullController(data, "earlyDiscount")))}
                                                            </p>
                                                        ) : null }

                                                    <p className={classes.paymentDetailCardValueText}>
                                                        {currency + setCurrencyValue(getRoundNumber(nullController(data, "tax")))}
                                                    </p>
                                                </div>
                                            </GridItem>
                                            <div className={classes.hardLine}/>
                                            <GridItem xs={12} sm={5} md={5} dismissRule>
                                                <p className={classes.paymentCardBodyText} style={{fontWeight: "bold"}}>
                                                    {t('TOTAL_TEXT')}
                                                </p>
                                            </GridItem>
                                            <GridItem xs={12} sm={7} md={7} dismissRule>
                                                <div className={classes.rightPaper}>
                                                    <p className={classes.paymentDetailCardValueText} style={{fontWeight: "bold", color: "black"}}>
                                                        {currency + setCurrencyValue(getRoundNumber(nullController(data, "total")))}
                                                    </p>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                                {
                                    nullController(data, "isPartialPayment") !== ''
                                    && nullController(data, "isPartialPayment") ? (
                                        <Card>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={12} dismissRule>
                                                        <Status
                                                            className={classes.statusCard}
                                                            color={statusColors[43]}
                                                            size="lg"
                                                            label={t(statusText[43])}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} dismissRule>
                                                        <p className={classes.paymentCardBodyText}>
                                                            {t('ORIGINAL_AMOUNT') + ": " + currency + setCurrencyValue(getRoundNumber(nullController(data, "amountBeforePartialPayment")))}
                                                        </p>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <PaymentRequestAttemptList data={attemptList} currency={currency} onUpdate={this.props.onUpdate}/>
                            </GridItem>
                        </GridContainer>
                    )
                }
            </div>
        );
    }
}


PaymentRequestDetails.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    loading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isImageError: PropTypes.bool,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(PaymentRequestDetails)));


