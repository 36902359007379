import { getService, postService, putService } from "./api";
import {
    BASE_URL_CORE,
    CATEGORY,
    CATEGORIES,
    CATEGORIES_BY_COMPANY,
    CATEGORIES_BY_CATALOG, CATEGORIES_BY_PRODUCT, CATEGORIES_ASSIGNED_CATALOG, CATEGORY_CATALOG_ORDER
} from "../variables/apiConstants";
import categoryDetails from "../models/CategoryModel";
import productDetails from "../models/ProductModel";

export function getCategories() {
    let url = BASE_URL_CORE + CATEGORIES;
    return getService(url);
}

export function getCategoriesByCompany() {
    let url = BASE_URL_CORE + CATEGORIES_BY_COMPANY;
    return getService(url);
}

export function getAllCategoriesByMenu(catalogId) {
    let url = BASE_URL_CORE + CATEGORIES_BY_CATALOG;
    let parameters = { "catalogId": catalogId };

    return postService(url, parameters);
}

export function getCategoriesByMenu(catalogId) {
    let url = BASE_URL_CORE + CATEGORIES_ASSIGNED_CATALOG;
    let parameters = { "catalogId": catalogId };

    return postService(url, parameters);
}

export function getCategoriesByProduct() {
    let url = BASE_URL_CORE + CATEGORIES_BY_PRODUCT;
    let parameters = { "productId": productDetails.id };

    return postService(url, parameters);
}

export function addCategory(parameters) {
    let url = BASE_URL_CORE + CATEGORY;
    return postService(url, parameters);
}

export function editCategory(parameters) {
    let url = BASE_URL_CORE + CATEGORY + "/" + categoryDetails.id;
    return putService(url, parameters);
}

export function orderCategoriesByCatalog(parameters) {
    let url = BASE_URL_CORE + CATEGORY_CATALOG_ORDER;

    return putService(url, parameters);
}