import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {isEmpty} from "../../../utils/helpersFunctions";


class MemberSyncModal extends React.Component {
    csvLink = React.createRef();

    constructor(props) {
        super(props);
    }


    render() {
        const { classes, t, member, show, password, onChange, onShowPassword, label, require2ndParam } = this.props;

        return (
            <div className={classes.imageEditorContainer}>
                <GridContainer>
                    {
                        require2ndParam ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                        {t("TYPE_SECOND_PARAMETER_FOR") + member.externalId + ":"}
                                                    </p>
                                                    <TextField
                                                        className={classes.textFieldPrimary}
                                                        id="memberAuth2ndParam"
                                                        name="memberAuth2ndParam"
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        type={
                                                            show
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        label={label}
                                                        value={password}
                                                        onChange={onChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="Toggle password visibility"
                                                                        onClick={onShowPassword}
                                                                    >
                                                                        {show ? (
                                                                            <VisibilityOff />
                                                                        ) : (
                                                                            <Visibility />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        autoComplete="current-password"
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        ) : null
                    }

                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.onClose("openSyncForm", false, false)}
                            >
                                {t('BTN_CLOSE')}
                            </Button>
                            <Button
                                color="primary"
                                disabled={require2ndParam && isEmpty(password)}
                                className={classes.buttonSpacing}
                                onClick={() => this.props.onSync()}
                            >
                                {t('BTN_SYNC')}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

MemberSyncModal.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    onSync: PropTypes.func,
    onShowPassword: PropTypes.func,
    onChange: PropTypes.func,
    member: PropTypes.object,
    require2ndParam: PropTypes.bool,
    show: PropTypes.bool,
    password: PropTypes.string,
    label: PropTypes.string
};

export default withRouter(withStyles(formStyle)(withTranslation()(MemberSyncModal)));