var memberAuthConfigDetails = {
    uuid: "",
    source: "",
    integration: "",
    usernameLabel: "",
    passwordLabel: "",
    fieldOption: 1,
    domains: [],
    domainDetail: {
        name: "",
        value: ""
    },

    setMemberAuthConfigDetail : function (values) {
        try {
            this.uuid = values.uuid;
            this.name = values.name;
            this.source = { value: values.membershipAuthSourceId.uuid, label: values.membershipAuthSourceId.name };
            this.integration = values.integration !== null ? { value: values.integration.id, label: values.integration.name } : "";

            if (values.memberAuthDomains !== null) {
                let id = 0;

                this.domains = values.memberAuthDomains.map(val => {
                    id++;
                    return {id: id, name: val.name, value: val.uuid, isEditing: false, isUpdated: false}
                });
            }

            this.fieldOption = values.isPasswordRequired ? 1 : 0;
            this.usernameLabel = values.usernameLabel;
            this.passwordLabel = values.passwordLabel;

        } catch (e) {
            console.log("Invalid data");
        }
    },
    cleanMemberAuthConfigDetails : function () {
        this.uuid = "";
        this.source = "";
        this.usernameLabel = "";
        this.passwordLabel = "";
        this.integration = "";
        this.domainDetail.value = "";
        this.domainDetail.name = "";
        this.fieldOption = 1;
        this.domains = [];
    },
};

export default memberAuthConfigDetails;