import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {
    formatDatetimeToDate,
    getCompanyCurrency,
    getRoundNumber, mergeArrays,
    nullController,
    nullValidator,
    saveData,
    setCurrencyValue,
} from "../../utils/helpersFunctions";
import {
    NOTIFICATION_DURATION,
} from "../../variables/coreConstants";
import { validateSession } from "../../services/api";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {
    ROWS_PER_PAGE
} from "../../variables/apiConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Snackbar from "../../components/Snackbar/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Muted from "../../components/Typography/Muted";
import PaymentTemplateGeneratorForm from "./components/PaymentTemplateGeneratorForm";
import { getPaymentRequestTemplatesByCompany } from "../../services/paymentTemplateService";
import paymentTemplateDetails from "../../models/PaymentTemplateModel";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";

class PaymentTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openTemplate: false,
            isLoading: false,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            count: 0,
            noItems: false,
            actionType: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount(): void {
        this.setState({ isLoading: true });
        this.request();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    };

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) {
            this.setState({ isLoading: true });
            this.request();
        }
    }

    request() {
        getPaymentRequestTemplatesByCompany()
            .then((response) => {
                let array = response.data.content ? response.data.content : response.data;
                let newData = array.map(r => {
                    r.averageAmountLabel = getCompanyCurrency() + " " +
                        (isNaN(parseFloat(r.averageAmount)) ? setCurrencyValue(getRoundNumber(0))
                        : setCurrencyValue(getRoundNumber(nullController(r, "averageAmount"))));
                    r.surchargeAmountLabel = getCompanyCurrency() + " " +
                        (isNaN(parseFloat(r.surchargeAmount)) ? setCurrencyValue(getRoundNumber(0))
                        : setCurrencyValue(getRoundNumber(nullController(r, "surchargeAmount"))));
                    r.earlyDiscountLabel = getCompanyCurrency() + " " +
                        (isNaN(parseFloat(r.earlyDiscount)) ? setCurrencyValue(getRoundNumber(0))
                        : setCurrencyValue(getRoundNumber(nullController(r, "earlyDiscount"))));
                    r.startDateLabel = nullValidator(r, "startDate") ? formatDatetimeToDate(new Date(r.startDate)) : "";
                    return r;
                });

                let paginatedData = mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid"
                );


                this.setState({
                    data: paginatedData,
                    filteredData: paginatedData,
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                if (error.response)
                    validateSession(error.response, this.props.history);
            });
    }

    handleOpenForm = () => {
        paymentTemplateDetails.cleanTemplateDetails();
        this.setState({ openTemplate: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openTemplate: false });
    };

    openForm = (action, formData) => {
        paymentTemplateDetails.cleanTemplateDetails();
        if (action === 1) {
            paymentTemplateDetails.setPaymentTemplateDetails(formData);
            this.setState({ openTemplate: true, actionType: 1 });
        } else {
            this.showNotification(
                this.props.t("CANNOT_UPDATE_TEMPLATE_ITEM"),
                "danger",
                NotificationErrorIcon,
                null
            );
        }
    };


    goToActions = (val) => {
        saveData("paymentTemplate", val);
        this.props.history.push("payment-template-details/" + val.uuid);
    };


    render() {
        const { classes, t, routeProps } = this.props;
        const { filteredData, rowsPerPage, page, actionType, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'payment_templates'}
                        title={t('PAYMENT_TEMPLATE_TEXT')}
                        exportFileName={t('PAYMENT_TEMPLATE_TEXT')}
                        // handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_GENERATE_TEMPLATE'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_NO_PAYMENT_REQUESTS'), name: "noPaymentRequests", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_AVERAGE_AMOUNT'), name: "averageAmountLabel", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DUE_SURCHARGE'), name: "surchargeAmountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_EARLY_PAYMENT_DISCOUNT'), name: "earlyDiscountLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('START_DATE_TEXT'), name: "startDateLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openTemplate}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {actionType
                            ? t("UPDATE_TEMPLATE")
                            : t("PAYMENT_TEMPLATE_TITLE")}
                        <Muted>
                            {actionType
                                ? t("REGENERATE_TEMPLATE_DESCRIPTION")
                                : t("FORM_PAYMENT_TEMPLATE_DESCRIPTION")}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <PaymentTemplateGeneratorForm
                            isTemplate={true}
                            action={actionType}
                            closeForm={this.closeForm}
                        />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

PaymentTemplates.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(PaymentTemplates)));
