import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Muted from "../Typography/Muted";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";



function HeaderDetailView({ ...props }) {
    const { classes, title, description } = props;


    return (
        <div>
            <h3 className={classes.headTitle}>{title}</h3>
            <Muted>
                {description}
            </Muted>
        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.title === nextProps.title && prevProps.description === nextProps.description;
}

HeaderDetailView.defaultProps = {
    description: "Description",
    title: "Title",
};

HeaderDetailView.propTypes = {
    classes: PropTypes.object.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
};

export default React.memo(withStyles(dashboardStyle)(HeaderDetailView), propsAreEqual);
