import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {useTranslation} from "react-i18next";
import HeaderForm from "../../../../components/CustomComponents/HeaderForm";
import {nullController} from "../../../../utils/helpersFunctions";
import CurrencyInput from "../../../../components/CustomInput/CurrencyInput";
import {withRouter} from "react-router-dom";



function CashAccountFormFormReview({ ...props }) {
    const { classes, stepSuccess, component, currency } = props;
    const {t} = useTranslation();


    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.headerFormContainerSmall}>
                                <HeaderForm
                                    title={stepSuccess ? t('FORM_SUCCESS_ACCOUNT_TITLE') : t('FORM_CONFIRM_ACCOUNT_TITLE')}
                                    description={t(stepSuccess ? 'FORM_SUCCESS_ACCOUNT_DESCRIPTION' : 'FORM_CONFIRM_ACCOUNT_DESCRIPTION',  { bankProvider: nullController(component.state.provider, "label"), account: component.state.account.substr(component.state.account.length - 4)})}/>
                                    <div style={{marginTop: 5}}>
                                        <HeaderForm
                                            title={null}
                                            description={stepSuccess? t('FORM_SUCCESS_ACCOUNT_LEGEND', { bankProvider: nullController(component.state.provider, "label") }) : t('FORM_CONFIRM_ACCOUNT_LEGEND')}/>
                                    </div>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                {
                    stepSuccess ? null : (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GridContainer>
                                <GridItem xs={false} sm={2} md={3} lg={3}/>
                                <GridItem xs={12} sm={8} md={6} lg={6}>
                                    <div className={classes.formBodyContainer}>
                                        <div className={classes.extractCard}>
                                            <div className={classes.extractCardRowHead}>
                                                {t('BANK_EXTRACT_TXT')}
                                            </div>
                                            <div className={classes.extractCardRow}>
                                                <div style={{width: "80%"}}>{"Ginih"}</div>
                                                <div style={{width: "20%", float: "right"}}>{"+ x,xx"}</div>
                                            </div>
                                            <div className={classes.extractCardRow}>
                                                <div style={{width: "80%"}}>{"Ginih"}</div>
                                                <div style={{width: "20%", float: "right"}}>{"+ x,xx"}</div>
                                            </div>
                                        </div>
                                        <CurrencyInput
                                            value={component.state.depositedAmount1Mask}
                                            onChange={component.handleCurrencyValue}
                                            labelText={t('DEPOSITED_AMOUNT_TXT')}
                                            id="depositedAmount1Mask"
                                            name="depositedAmount1"
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.inputFormContainer
                                            }}
                                            currencySymbol={currency}
                                        />
                                        <CurrencyInput
                                            value={component.state.depositedAmount2Mask}
                                            onChange={component.handleCurrencyValue}
                                            labelText={t('DEPOSITED_AMOUNT_TXT')}
                                            id="depositedAmount2Mask"
                                            name="depositedAmount2"
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.inputFormContainer
                                            }}
                                            currencySymbol={currency}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={false} sm={2} md={3} lg={3}/>
                            </GridContainer>
                        </GridItem>
                    )
                }
            </GridContainer>
        </div>
    );
}


CashAccountFormFormReview.propTypes = {
    classes: PropTypes.object.isRequired,
    stepSuccess: PropTypes.bool,
    currency: PropTypes.string,
    component: PropTypes.object
};

export default withRouter(withStyles(dashboardStyle)(CashAccountFormFormReview));
