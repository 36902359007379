import { getService, postService, putService } from "./api";
import productDetails from "../models/ProductModel";
import {
    BASE_URL_CORE,
    PRODUCTS_BY_COMPANY,
    PRODUCT,
    PRODUCTS_AVAILABLE_BY_CATEGORY,
    PRODUCTS_BY_CATEGORY, PRODUCT_TYPE
} from "../variables/apiConstants";


export function getProductsByCompany() {
    let url = BASE_URL_CORE + PRODUCTS_BY_COMPANY;
    return getService(url);
}

export function addProduct(parameters) {
    let url = BASE_URL_CORE + PRODUCT;
    return postService(url, parameters);
}

export function editProduct(parameters) {
    let url = BASE_URL_CORE + PRODUCT + "/" + productDetails.id;
    return putService(url, parameters);
}

export function getProductsByCategory(categoryId) {
    let url = BASE_URL_CORE +  PRODUCTS_BY_CATEGORY + "/" + categoryId;

    return getService(url);
}
    
export function getProductsAvailable(categoryId) {
    let url = BASE_URL_CORE + PRODUCTS_AVAILABLE_BY_CATEGORY + categoryId;

    return getService(url);
}

export function getProductType() {
    let url = BASE_URL_CORE + PRODUCT_TYPE;

    return getService(url);
}