module.exports = {
    HTTP_OK: 200,
    HTTP_CREATED: 201,
    HTTP_BAD_REQUEST: 400,
    HTTP_UNAUTHORIZED: 401,
    HTTP_FORBIDDEN: 403,
    HTTP_NOT_FOUND: 404,
    HTTP_INTERNAL_SERVER_ERROR: 500,
    HTTP_BAD_GATEWAY: 502,
    HTTP_SERVICE_UNAVAILABLE: 503,
    HTTP_GATEWAY_TIMEOUT: 504,
    CREATED_CODE: {
        code: 500,
        name: 'created'
    },
    NOT_CREATED_CODE: {
        code: 501,
        name: 'not_created'
    },
    COMPLETED_CODE: {
        code: 502,
        name: 'completed'
    },
    INTERNAL_ERROR_CODE: {
        code: 503,
        name: 'internal_error'
    },
    UNAUTHORIZED_CODE: {
        code: 504,
        name: 'unauthorized'
    },
    CREATED_WITH_ERRORS_CODE: {
        code: 505,
        name: 'created_with_errors'
    },
    GENERAL_FAIL_CODE: {
        code: 520,
        name: 'general_fail'
    },
    RESPONSE_FORMAT_CODE: {
        code: 521,
        name: 'response_format'
    },
    PERSIST_FAIL_CODE: {
        code: 522,
        name: 'persist'
    },
    INVALID_PARAMS_CODE: {
        code: 523,
        name: 'invalid_params'
    },
    INVALID_SERVICE_PARAMS_CODE: {
        code: 524,
        name: 'invalid_service_params'
    },
    TASK_NOT_FOUND_CODE: {
        code: 528,
        name: 'task_not_found'
    },
    INVALID_DEFINITION_FORMAT_CODE: {
        code: 529,
        name: 'invalid_definition_format'
    },
    TASK_NOT_UPDATED_CODE: {
        code: 530,
        name: 'task_not_updated'
    },
    TASK_PUBLISH_FAILED_CODE: {
        code: 531,
        name: 'task_publish_failed'
    },
    EXTERNAL_SERVICE_TIMEOUT_CODE: {
        code: 532,
        name: 'external_service_timeout'
    },
    EXTERNAL_SERVICE_BAD_REQUEST_CODE: {
        code: 533,
        name: 'external_service_bad_request'
    },
    EXTERNAL_SERVER_ERROR_CODE: {
        code: 534,
        name: 'external_server_error'
    },
    EXTERNAL_SERVICE_BAD_GATEWAY_CODE: {
        code: 535,
        name: 'external_service_bad_gateway'
    },
    EXTERNAL_SERVICE_UNAVAILABLE_CODE: {
        code: 536,
        name: 'external_service_unavailable'
    },
    INTERNAL_SERVICE_ERROR_CODE: {
        code: 537,
        name: 'internal_error'
    },
    INVALID_REQUEST_ID_CODE: {
        code: 538,
        name: 'invalid_request_id'
    },
    TIMELINE_BANK_PAYMENT: 'bank_payment',
    TIMELINE_SEND_PAYMENT_NOTIFICATION: 'send_company_notification',
    STATUS_COMPLETED_JOB: "completed",
    STATUS_PAYMENT_FAILED_JOB: "payment_failed",
    STATUS_COMPLETED_ERROR_JOB: "completed_with_errors",
    STATUS_PARTIAL_COMPLETED_JOB: "partial_completed",
    STATUS_IN_PROGRESS_TSK: "in_progress",
    STATUS_VALIDATION_COMPLETED_TSK: "validation_completed",
    STATUS_VALIDATING_TSK: "validating",
    STATUS_SENDING_NOTIFICATION_TSK: "sending_notification",
    STATUS_SYNC_FAILED_TSK: "sync_failed",
    STATUS_FAILED_TSK: "failed",
    STATUS_AUTHORIZED_TSK: "authorized",
    STATUS_DECLINED_TSK: "declined",
    STATUS_PROVIDER_FAILED_TSK: "provider_failed",
    JOB_CREATED_CODE: {
        code: 103,
        name: 'created'
    }
};