/* Created by Marlon Reyes on July 25, 2019 */

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import { withRouter } from "react-router-dom";
import {
    convertDate,
    getData,
    nullController,
} from "../../../utils/helpersFunctions";
import SessionAttendancesDetails from "./SessionAttendancesDetails";
import { withTranslation } from "react-i18next";

class QuickActionsSession extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store: getData("store"),
            zone: getData("zone"),
            session: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({ session: getData("session") });
    }

    componentWillUnmount() {
        // removeData("session");
    }

    goToActivity = () => {
        this.props.history.push("quick-actions-activity");
    };

    render() {
        const { classes, t, routeProps } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {convertDate(this.state.session.start_time)}
                            </h3>
                            <Muted>
                                {nullController(this.state.session, "zone")},{" "}
                                {nullController(this.state.session, "store")}
                            </Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                color="primary"
                                onClick={this.goToActivity}
                            >
                                Go to Activity
                            </CustomButton>
                        </div>
                    </GridItem>
                </GridContainer>
                <SessionAttendancesDetails />
                {/*<SessionRatingsDetails />*/}
            </div>
        );
    }
}

QuickActionsSession.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsSession))
);
