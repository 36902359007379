/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

import headerStyle from "../../assets/jss/material-dashboard-react/components/headerStyle.jsx";
import {withTranslation} from "react-i18next";
import {getData, nullController} from "../../utils/helpersFunctions";
import Muted from "../Typography/Muted";

function Header({ ...props }) {
  function makeBrand() {
    let result = {
      name: '',
      legend: ''
    };

    props.routes.map(prop => {
      let location = window.location.pathname.replace(prop.layout, "");
      let arrayRoute = prop.path.split("/");
      let arrayLocation = location.split("/");

      if (arrayRoute.length === arrayLocation.length) {
        let countMatches = 0;
        for (let i = 0 ; i < arrayRoute.length ; i++) {
          let currentPath = arrayLocation[i];
          let currentRoutePath = arrayRoute[i];

          // console.log(currentPath + " <==> " + currentRoutePath)

          if (!currentRoutePath.includes(":")) {
            if (currentRoutePath === currentPath) {
              countMatches++;
            }
          } else {
            countMatches++
          }
        }

        // console.log(countMatches);
        if (countMatches === arrayRoute.length) {
          let brand = getNameValue(prop.name);

          if (brand !== null) {
            result.name = brand.name;
            result.legend = brand.legend;
          }
        }
      } else {
        return result;
      }
    });

    return result;
  }
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  const getNameValue = (name) => {
    let result = {
      name: null,
      legend: null
    };

    try {
      switch (name) {
        case "DEBITS_HISTORY_TEXT":
          result.name = getData("debit").name;
          result.legend = props.t('WITH_DUE_DATE_BEFORE') + nullController(getData("debit"), 'paymentExpiringBefore');
          break;
        case "DEBIT_SYNC_RESULT_DETAILS_TEXT":
          result.name = getData("debit").name
          result.legend = props.t('WITH_DUE_DATE_BEFORE') + nullController(getData("debit"), 'paymentExpiringBefore');
          break;
        case "DEBIT_EXECUTION_DETAILS_TEXT":
          result.name = getData("debit").name;
          result.legend = props.t('WITH_DUE_DATE_BEFORE') + nullController(getData("debit"), 'paymentExpiringBefore');
          break;
        case "MEMBER_GROUP_DETAILS_TEXT":
          result.name = getData("memberGroup").name;
          break;
        case "MEMBER_GROUP_ASSIGNMENT_TEXT":
          result.name = getData("memberGroup").name;
          break;
        default:
          result.name = props.t(name);
      }
    } catch (e) {}

    return result
  };

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div style={{marginLeft:"30px"}}>
            {makeBrand().name}
            <Muted>{makeBrand().legend}</Muted>
          </div>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(headerStyle)(withTranslation()(Header));
