import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import {
    primaryColor,
    successColor,
    dangerColor,
    infoColor,
    warningColor, externalColor
} from "../../assets/jss/material-dashboard-react";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        fontWeight: 'bold',
    },
    fixed: {
        '& .MuiChip-label': {
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: "9px",
        },
    },
    sm: {
        height: theme.spacing(1),
        width: theme.spacing(1)
    },
    md: {
        height: theme.spacing(1) * 2,
        width: theme.spacing(1) * 2
    },
    lg: {
        height: theme.spacing(1) * 2.5,
        width: theme.spacing(1) * 2.5
    },
    primary: {
        backgroundColor: primaryColor[1],
    },
    info: {
        backgroundColor: infoColor[1]
    },
    warning: {
        backgroundColor: warningColor[1]
    },
    danger: {
        backgroundColor: dangerColor[1]
    },
    success: {
        backgroundColor: successColor[1]
    },
    error: {
        backgroundColor: externalColor[0]
    },
    cyanBlue: {
        backgroundColor: "#00A6A6"
    },
    grayishPink: {
        backgroundColor: "#D4AFB9"
    },
    default: {
        backgroundColor: '#F3F9FF'
    },
});

const Status = props => {
    const { classes, className, size, fixed, color, label, customColor, ...rest } = props;

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes.fixed]: fixed,
            [classes[size]]: size,
            [classes[color]]: !customColor,
        },
        className
    );

    return (
        <Chip
            {...rest}
            className={rootClassName}
            style={customColor ? {backgroundColor: customColor} : null}
            label={label ? label : 'Undefined'}
            //
        />
    );
};

Status.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    customColor: PropTypes.string,
    fixed: PropTypes.bool,
    color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'error',
        'default'
    ]),
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

Status.defaultProps = {
    size: 'md',
    color: 'default',
    label: 'undefined'
};

export default withStyles(styles)(Status);
