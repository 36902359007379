import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
// Styles
import formStyle from "../../assets/jss/custom-components/formStyle";

import {NOTIFICATION_DURATION} from "../../variables/coreConstants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import makeAnimated from "react-select/animated";
import companyDetails from "../../models/AccountModel";
import {
    disableComponent,
    enableComponent,
    isSuccessfulRequest,
    selectValidation
} from "../../utils/helpersFunctions";
import {updateCompanyInformation} from "../../services/accountService";
import {validateSession} from "../../services/api";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withTranslation} from "react-i18next";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExtIdIcon from "@material-ui/icons/Fingerprint";
import {getCountries} from "../../services/storeService";

const animatedComponents = makeAnimated();


class AccountSettingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companyName: companyDetails.fullName,
            address: companyDetails.address,
            city: companyDetails.cityId,
            timeZone: companyDetails.timeZone[companyDetails.getCurrentTimeZone()],
            timeZoneOptions: companyDetails.timeZone,
            countryCode: companyDetails.countryCode,
            postalCode: companyDetails.postalCode,
            phoneNumber: companyDetails.phoneNumber,
            taxId: companyDetails.taxId,
            countryOptions: [],
            country: companyDetails.country,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.cleanData = this.cleanData.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;
        let countryValues;
        getCountries()
            .then((res) => {
                countryValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({ countryOptions: countryValues });
            })
            .catch((error) => {
                if (error.response) {
                    this.showNotification(t('GET_COUNTRIES_FAILED_MSG'), "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                }
            });
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };



    cleanData() {
        this.setState({
            notificationMessage: '',
            notification: false,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    changeTimeZoneSelect = timeZone => {
        this.setState({ timeZone } );
    };

    changeCountrySelect = country => {
        this.setState({ country } );
    };



    updateDataAccount() {
        const { t } = this.props;

        if (selectValidation(this.state.timeZone) || this.state.companyName === "" || this.state.phoneNumber === ""
        || selectValidation(this.state.country) || this.state.address === "" || this.state.postalCode === ""
        || this.state.city === "" || this.state.taxId === "") {
            this.showNotification(t('FIELDS_EMPTY'), "danger", NotificationErrorIcon, null);

        } else {
            let dataParameters = {
                "fullName": this.state.companyName,
                "phoneNumber" : this.state.phoneNumber,
                "countryId": this.state.country.value,
                "address": this.state.address,
                "postalCode": parseInt(this.state.postalCode),
                "city": this.state.city,
                "taxId": this.state.taxId,
                "timeZone": this.state.timeZone.label
            };

            disableComponent(this, "disableField");
            updateCompanyInformation(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }



    render() {
        const { classes, t } = this.props;

        const UpdateDataButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateDataAccount()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.companyName}
                                onChange={this.handleChangeValues}
                                labelText={t('ORGANIZATION_NAME_TEXT')}
                                id="companyName"
                                name="companyName"
                                autoFocus={true}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.address}
                                onChange={this.handleChangeValues}
                                labelText={t('ADDRESS_TEXT')}
                                id="address"
                                name="address"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.city}
                                onChange={this.handleChangeValues}
                                labelText={t('CITY_TEXT')}
                                id="city"
                                name="city"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControlCenter}>
                            <InputLabel htmlFor="timeZones" className={classes.selectTitle}>
                                {t('TIME_ZONES_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.timeZone}
                                onChange={this.changeTimeZoneSelect}
                                components={animatedComponents}
                                options={this.state.timeZoneOptions}
                                defaultValue={this.state.timeZoneOptions[companyDetails.getCurrentTimeZone()]}
                                placeholder={t('SELECT_TEXT')}
                                isClearable={true}
                                maxMenuHeight={130}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControlCenter}>
                            <InputLabel htmlFor="country" className={classes.selectTitle}>
                                {t('SELECT_COUNTRY_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.country}
                                onChange={this.changeCountrySelect}
                                options={this.state.countryOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.postalCode}
                                onChange={this.handleChangeValues}
                                labelText={t('POSTAL_CODE_TEXT')}
                                id="postalCode"
                                name="postalCode"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.phoneNumber}
                                onChange={this.handleChangeValues}
                                labelText={t('PHONE_NUMBER_TEXT')}
                                id="phoneNumber"
                                name="phoneNumber"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerFormContainer}>
                            <CustomInput
                                value={this.state.taxId}
                                onChange={this.handleChangeValues}
                                labelText={t('TAX_ID_TEXT')}
                                id="taxId"
                                name="taxId"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ExtIdIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <UpdateDataButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

AccountSettingForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(AccountSettingForm)));