import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import RefreshIcon from '@material-ui/icons/Refresh';
import {
    getData,
    getMemberLabelFromJobTask,
    getPercentageProcess,
    isEmpty,
    nullController
} from "../../../../utils/helpersFunctions";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import {withTranslation} from "react-i18next";
import {Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import ExecuteDebitForm from "../execute/ExecuteDebitForm";
import LiveProcessModal from "../../../../components/CustomModals/LiveProcessModal";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";



class LoadDebitPayments extends React.Component {
    render() {
        const { classes, t, directDebit, data, onSearch, summary, isLoading, onLoadPayments, onExecuteDebit, onRefresh,
            executionEnabled, handleCheckRow, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
            debitInfoLoaded, isRequesting, syncAvailable, refreshAvailable, showExecutionModal, onCloseExecutionModal,
            excludedItems, handleSelectColumn, showLiveProcess, handleCloseLiveProcess, handleFinishLiveProcess, tasks,
            handleLoadMore, isLoadingMore, dataItemCount, handleRequestLastDebitJobs, handleRecentJob, handleInitGrid
        } = this.props;


        let processPercentage = getPercentageProcess(summary);

        let fileParameters = {
            debit: !isEmpty(directDebit) ? directDebit.name : "",
            syncDate: !isEmpty(directDebit) && !isEmpty(directDebit.lastModifiedDate) ? directDebit.lastModifiedDate : ""
        };

        return (
            <div>
                <Backdrop className={classes.backdropCenter} open={isRequesting || isLoading || isLoadingMore}>
                    <CircularProgress color={"inherit"} />
                    {
                        isLoadingMore ? <p>{t('RETRIEVING_INFORMATION')}</p> : null
                    }
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} lg={5}>
                        <div style={{marginBottom: 0}}>
                            {
                                !isEmpty(summary) ? (
                                    <Card>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <p className={classes.balanceCardTitle}>{t('LOAD_PAYMENT_SUMMARY')}</p>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer>
                                                        <GridItem xs={8} sm={8} md={8} lg={8}>
                                                            <div style={{display: "flex", flexDirection: "column"}}>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {t("SYNC_STATUS")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {t("TOTAL_MEMBERS")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {t("AVAILABLE_TO_DEBIT")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {t("NOT_AVAILABLE_TO_DEBIT")}
                                                                </p>
                                                                <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                    {t("MEMBERS_WITH_MULTIPLE_PAYMENTS")}
                                                                </p>
                                                            </div>
                                                        </GridItem>
                                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                                            <div style={{display: "flex", flexDirection: "column", float: "right", textAlign: "right"}}>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {nullController(summary, "completed") + "/" + nullController(summary, "total")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {nullController(summary, "total")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {nullController(summary, "availableToExecute")}
                                                                </p>
                                                                <p className={classes.balanceCardSubTitle}>
                                                                    {nullController(summary, "notAvailableToExecute")}
                                                                </p>
                                                                <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                    {nullController(summary, "withMultiplePayments")}
                                                                </p>
                                                            </div>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                ) : null
                            }
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TableGridWijmo
                            tableName={'debit_sync_result'}
                            title={t('DEBIT_SYNC_RESULT_DETAILS_TEXT')}
                            exportFileName={t('DEBIT_SYNC_RESULT_FILE_NAME', fileParameters)}
                            handleLoadMore={handleLoadMore}
                            handleSelect={handleCheckRow}
                            multiSelect
                            data={data}
                            actions={[
                                { label: t('BTN_EXECUTE_DEBIT'), function: onExecuteDebit, isLoading: false, disabled: !executionEnabled, type: "simple" },
                                { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                            ]}
                            controls={[
                                { icon: RefreshIcon, function: onRefresh, isLoading: false, disabled: false, type: "icon" }
                            ]}
                            headers={[
                                { label: "No", name: "recordNum", cssClass: "cell-number", isReadOnly: true, width: 50 },
                                { label: t('TH_MEMBER'), name: "memberName", onSelect: handleSelectColumn, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                                { label: t('TH_PAYMENT_METHOD'), name: "paymentMethod", isReadOnly: true, width: "*", isCustomCell: true },
                                { label: t('PAYMENT_TEXT'), name: "paymentExternalId", isReadOnly: true, width: "*", isCustomCell: true},
                                { label: t('TH_AMOUNT'), name: "amountFormatted", isReadOnly: true, width: "*", isCustomCell: true },
                                { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: "*", isCustomCell: true },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={showExecutionModal}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('EXECUTE_DIRECT_DEBIT_TXT')}
                    </DialogTitle>
                    <DialogContent>
                        <ExecuteDebitForm onCloseForm={onCloseExecutionModal} excludedItems={excludedItems}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={showLiveProcess}
                >
                    <DialogTitle id="form-dialog-title">
                        {t('PROCESS_SYNC_TITLE')}
                    </DialogTitle>
                    <DialogContent>
                        <LiveProcessModal
                            onClose={handleCloseLiveProcess}
                            onFinish={handleCloseLiveProcess}
                            processDescription={processPercentage < 100 ? t('SYNC_PROCESS') : t('SYNC_PROCESS_COMPLETED')}
                            label={t('PROCESS_SYNC_LABEL', { total: nullController(summary, "total") })}
                            isCompleted={processPercentage === 100}
                            processPercentage={processPercentage}
                            currentItem={getMemberLabelFromJobTask(tasks, true)}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

LoadDebitPayments.propTypes = {
    classes: PropTypes.object,
    handleCheckRow: PropTypes.func,
    handleSelectColumn: PropTypes.func,
    onLoadPayments: PropTypes.func,
    onSearch: PropTypes.func,
    onRefresh: PropTypes.func,
    onExecuteDebit: PropTypes.func,
    onCloseExecutionModal: PropTypes.func,
    directDebit: PropTypes.object,
    data: PropTypes.array,
    dataItemCount: PropTypes.number,
    excludedItems: PropTypes.array,
    summary: PropTypes.object,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    lastPage: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    isRequesting: PropTypes.bool,
    debitInfoLoaded: PropTypes.bool,
    executionEnabled: PropTypes.bool,
    syncAvailable: PropTypes.bool,
    refreshAvailable: PropTypes.bool,
    showExecutionModal: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleLastPage: PropTypes.func,
    handleFirstPage: PropTypes.func,
    handlePreviousPage: PropTypes.func,
    handleNextPage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleCloseLiveProcess: PropTypes.func,
    handleLoadMore: PropTypes.func,
    handleRequestLastDebitJobs: PropTypes.func,
    handleRecentJob: PropTypes.func,
    handleInitGrid: PropTypes.func,
    showLiveProcess: PropTypes.bool,
    tasks: PropTypes.array
}

export default withRouter(withStyles(tableViewStyle)(withTranslation()(LoadDebitPayments)));