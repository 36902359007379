import React, {useEffect, useState} from "react";
import {isEmpty} from "../../../utils/helpersFunctions";


/**
 * @description High order component that implement websocket methods
 * @param WrappedComponent
 * @returns {function(*)}
 */
export const withWssListener = (WrappedComponent) => {
    return props => {
        const [wssConfig, setWssConfig] = useState({
            url: null,
            registerData: null,
            maxReconnections: 10,
            waitTime: 60000,
            notificationCallback: null,
            closeCallback: null
        });
        const [wssTask, setWssTask] = useState([]);
        const [wss, setWss] = useState(null);
        const [preparingWss, setPreparingWss] = useState(false);
        const [wssConnecting, setWssConnecting] = useState(false);
        const [wssProcessCompleted, setWssProcessCompleted] = useState(false);
        const [countReconnection, setCountReconnection] = useState(0);

        let finishWssListener;

        // useEffect(() => {
        //     console.log("Use effect wss hoc...")
        //     document.addEventListener('visibilitychange', function() {
        //         if (document.visibilityState === 'visible') {
        //             checkWssConnection();
        //         }
        //     });
        // }, [])

        useEffect(() => {
            // console.log("Use effect count reconnection wss hoc... ", countReconnection);
            if (countReconnection > 0) {
                onConnectWss();
            }
        }, [countReconnection]);


        useEffect(() => {
            // console.log("Use effect config wss hoc... ", wssConfig);
            // console.log(typeof wssConfig.notificationCallback);
            if (!isEmpty(wssConfig) && !isEmpty(wssConfig.url) && !isEmpty(wssConfig.registerData)
                && !isEmpty(wssConfig.notificationCallback)) {
                onPrepareWssConnection();
            }
        }, [wssConfig]);



        // useEffect(() => {
        //     console.log("Use effect connecting wss hoc...");
        //     if (wssConnecting) {
        //         onPrepareWssConnection(onConnectWss);
        //     }
        // }, [wssConfig]);


        useEffect(() => {
            // console.log("Use effect connecting wss hoc... ", wssConnecting);
            if (wssConnecting) {
                onInitWssConnection();
                setPreparingWss(false);
            }
        }, [wssConnecting]);


        useEffect(() => {
            if (wssProcessCompleted) {
                try {
                    // console.log("will close")
                    wss.close(999, "Websocket process completed");
                } catch (e) {}

                setWss(null);
                setWssConnecting(false);
                setCountReconnection(0);
            } else {
                setWss(null);
                setWssTask([]);
                setCountReconnection(0);
            }
        }, [wssProcessCompleted]);


        // useEffect(() => {
        //     console.log("Use effect wss hoc ALL STATES... ");
        // }, [wss, wssTask, countReconnection, wssProcessCompleted]);


        useEffect(() => {
            // console.log("Wss Task cleaning, " + preparingWss);
            if (preparingWss) {
                onConnectWss();
            }
        }, [wssTask]);


        useEffect(() => {
            // console.log("Wss is preparing... ", preparingWss);
            if (preparingWss) {
                setWssTask([]);
            }
        }, [preparingWss]);


        useEffect(() => {
            // console.log("Wss is open connection... ", (wss !== null && wss !== undefined));
            if (wss !== null && wss !== undefined) {
                wss.onopen = () => {
                    console.log("Connecting....");
                    wss.send(wssConfig.registerData);

                    finishWssListener = window.setTimeout(() => {
                        // console.log("Executed this")
                        wssConfig.closeCallback()
                    }, wssConfig.waitTime);
                };

                wss.onmessage = (e) => {
                    // console.log("Listening....");
                    // console.log("*******************");
                    window.clearTimeout(finishWssListener);
                    const message = JSON.parse(e.data);
                    wssConfig.notificationCallback(message);
                }

                wss.onclose = (e) => {
                    console.log("Socket connection is closed ");
                    // console.log(JSON.stringify(e));

                    if (!wssProcessCompleted) {
                        window.setTimeout(
                            () => {
                                onCheckWssConnection();
                            },
                            2000
                        );
                    } else {
                        wssConfig.closeCallback();
                    }
                };

                // websocket onerror event listener
                wss.onerror = err => {
                    // console.log("Socket encountered error");
                    console.log(JSON.stringify(err));
                    onWssCompleted();
                };

                setWssConnecting(false);
            }
        }, [wss]);





        const onConnectWss = () => {
            try {
                // console.log("onConnectWss => ", !wssConnecting)
                if (!wssConnecting) {
                    setWssConnecting(true);
                } else {
                    onWssCompleted();
                }
            } catch (e) {
                console.log(JSON.stringify(e));
                onWssCompleted();
            }
        };


        const onInitWssConnection = () => {
            // console.log("Execute");
            let ws = new WebSocket(wssConfig.url);
            setWss(ws);
        }


        // const onReceiveNotification = (message) => {
        //     const { jobId, jobProgress, summary, status, actionType, step } = message;
        //     // const { code,  description, task } = step;
        //     // const { pending, completed, total } = summary;
        //
        //     console.log(JSON.stringify(message));
        //
        //     // if (jobId === currentJobId) {
        //         if (!isEmpty(summary)) {
        //             // const { pending, completed, total } = summary;
        //             //
        //             // let updatedSummary = cloneObject(this.state.syncSummary);
        //             // updatedSummary.total = total;
        //             // updatedSummary.pending = pending;
        //             // updatedSummary.completed = completed;
        //             //
        //             // this.setState({
        //             //     syncSummary: updatedSummary
        //             // }, () => {
        //             //     if (status === STATUS_IN_PROGRESS_TSK) {
        //             //         console.log("Job in progress");
        //             //
        //             //     } else if (status === STATUS_COMPLETED_JOB) {
        //             //         console.log("Job completed");
        //             //         this.onWssCompleted();
        //             //     } else {
        //             //         console.log("Invalid job status (" + status + ")");
        //             //     }
        //             // });
        //         } else {
        //             // iff summary is null ?
        //             // validate status
        //             console.log("Wss summary null");
        //         }
        //     // } else {
        //     //     console.log("Incoming message from another job (" + jobId + ")");
        //     // }
        // };

        const onCheckWssConnection = () => {
            if (wss !== null && wss !== undefined) {
                if (wss.readyState === WebSocket.CLOSED && countReconnection <= 10 && !wssProcessCompleted) {
                    console.log("Reconnecting...");
                    setCountReconnection(countReconnection + 1);
                }
            }
        };

        const onWssCompleted = () => {
            setWssProcessCompleted(true);
            // document.removeEventListener('visibilitychange', () => {});
        };

        const onPrepareWssConnection = () => {
            setWss(null);
            setCountReconnection(0);
            setWssProcessCompleted(false);
            setPreparingWss(true);
        };


        /**
         *
         * @param {string} wssUrl Web socket api url
         * @param {string} wssRegisterData Json string of data required to register a connection
         * @param {number} maxReconnections Number of max reconnection attempts
         * @param {number} waitTime Time to wait after last message (min)
         * @param {function} notificationCallback Callback function to manage wss messages
         * @param {function} closeCallback Callback function to manage wss close connections
         */
        const onSetWssConfig = (wssUrl, wssRegisterData, waitTime, maxReconnections, notificationCallback, closeCallback) => {
            setWssConfig({
                url: wssUrl,
                registerData: wssRegisterData,
                maxReconnections: maxReconnections,
                waitTime: waitTime * 60000,
                notificationCallback: typeof notificationCallback === 'function' ? notificationCallback : null,
                closeCallback: typeof closeCallback === 'function' ? closeCallback : null
            });
        };


        /**
         * @description Function that update listener condition to close connections if client do not receive messages
         * @param {function} callback Function that validate what to do if client do not receive a new message after 30 seconds
         */
        const updateListenerCondition = (callback) => {
            window.clearTimeout(finishWssListener);
            finishWssListener = window.setTimeout(() => {
                callback();
            }, wssConfig.waitTime);
        }


        return (
            <>
                <WrappedComponent {...props}
                                  onSetWssConfig={onSetWssConfig}
                                  onPrepareWssConnection={onPrepareWssConnection}
                                  onWssCompleted={onWssCompleted}
                                  onCheckWssConnection={onCheckWssConnection}
                                  onConnectWss={onConnectWss}
                                  // onReceiveWssNotification={onReceiveNotification}
                                  wssData={{
                                      wssTask: wssTask,
                                      wss: wss,
                                      wssConnecting: wssConnecting,
                                      wssProcessCompleted: wssProcessCompleted,
                                      countReconnection: countReconnection,
                                      config: wssConfig
                                  }}
                                  wssListenerCondition={finishWssListener}
                                  onUpdateWssListenerCondition={updateListenerCondition}
                />
            </>
        );
    };
};
