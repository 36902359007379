import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {nullValidator} from "../../utils/helpersFunctions";



function HeaderForm({ ...props }) {
    const { classes, title, description } = props;


    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", marginBottom: 10}}>
            {nullValidator(props, "title") ? <h4 className={classes.headFormTitle}>{title}</h4> : null}

            {
                nullValidator(props, "description")
                    ? <div className={classes.headerFormDescription}>{description}</div>
                    : null
            }

        </div>
    );
}


function propsAreEqual(prevProps, nextProps) {
    return prevProps.title === nextProps.title && prevProps.description === nextProps.description;
}

HeaderForm.propTypes = {
    classes: PropTypes.object.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
};

export default React.memo(withStyles(dashboardStyle)(HeaderForm), propsAreEqual);
