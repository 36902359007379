import {getService, postService, putService} from "./api";
import {
    BASE_URL_CORE, HTTP_METHODS, INTEGRATION,
    INTEGRATION_ACTIONS_BY_OBJECT,
    INTEGRATION_OBJECTS, INTEGRATION_SETTINGS,
    INTEGRATIONS_BY_COMPANY, UPDATE_INTEGRATION
} from "../variables/apiConstants";


export function addIntegration(parameters) {
    let url = BASE_URL_CORE + INTEGRATION;

    return postService(url, parameters);
}

export function editIntegration(parameters) {
    let url = BASE_URL_CORE + UPDATE_INTEGRATION;

    return putService(url, parameters);
}


export function getIntegrationByCompany() {
    let url = BASE_URL_CORE + INTEGRATIONS_BY_COMPANY;

    return getService(url);
}

export function getIntegrationObjects() {
    let url = BASE_URL_CORE + INTEGRATION_OBJECTS;

    return getService(url);
}

export function getIntegrationActions(objectId) {
    let url = BASE_URL_CORE + INTEGRATION_ACTIONS_BY_OBJECT + objectId;

    return getService(url);
}

export function getIntegrationSettingsByIntegration(integrationId) {
    let url = BASE_URL_CORE + INTEGRATION_SETTINGS + integrationId;

    return getService(url);
}

export function getHTTPMethods() {
    let url = BASE_URL_CORE + HTTP_METHODS;

    return getService(url);
}