/* Created by Marlon Reyes on July 25, 2019 */

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import { withRouter } from "react-router-dom";
import { getData, nullController } from "../../../utils/helpersFunctions";
import ActivitySessionsDetails from "./ActivitySessionsDetails";
import { getSessionsSubscribersByActivity } from "../../../services/activityService";
import { NOTIFICATION_DURATION } from "../../../variables/coreConstants";
import Loading from "../../../components/Loading/Loading";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ActivitySubscribersDetails from "./ActivitySubscribersDetails";
import { validateSession } from "../../../services/api";
import { withTranslation } from "react-i18next";

class QuickActionsActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            sessionsData: [],
            subscribersData: [],
            isLoading: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
    }

    componentDidMount() {
        this.request();
        this.setState({ activity: getData("activity") });
    }

    componentWillUnmount() {
        // removeData("activity");
    }

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    request() {
        this.setState({ isLoading: true });
        let activity = getData("activity");

        getSessionsSubscribersByActivity(activity.id)
            .then((res) => {
                this.setState({ sessionsData: res.data.activitySessions });
                this.setState({ subscribersData: res.data.memberSubscriber });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                this.showNotification(
                    err.response.data.message,
                    "danger",
                    NotificationFailedIcon,
                    false
                );

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }

    goToActivities = () => {
        this.props.history.push("activities");
    };

    render() {
        const { classes, t, routeProps } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.activity, "name")}
                            </h3>
                            <Muted>
                                {nullController(
                                    this.state.activity,
                                    "description"
                                )}
                            </Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                Edit
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToActivities}
                            >
                                Go to activities
                            </CustomButton>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
                {this.state.isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <ActivitySessionsDetails
                            data={this.state.sessionsData}
                        />
                        <ActivitySubscribersDetails
                            data={this.state.subscribersData}
                        />
                    </div>
                )}
            </div>
        );
    }
}

QuickActionsActivity.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsActivity))
);
