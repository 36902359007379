import { getService, postService, putService } from "./api";
import { BASE_URL_CORE, PRICEBOOKS, PRICEBOOK } from "../variables/apiConstants";
import priceBookDetails from "../models/PriceBookModel";

export function getPriceBoooks() {
    let url = BASE_URL_CORE + PRICEBOOKS;
    return getService(url);
}

export function addPriceBook(parameters) {
    let url = BASE_URL_CORE + PRICEBOOK;
    return postService(url, parameters);
}

export function editPriceBook(parameters) {
    let url = BASE_URL_CORE + PRICEBOOK + "/" + priceBookDetails.id;
    return putService(url, parameters);
}