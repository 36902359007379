
/**
 * Copyright 2021 Ginih (https://www.ginih.com)
 * Created By Marlon
 * Coded by Ginih Team
 **/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {withRouter} from "react-router-dom";
import {
    getData,
    isEmpty,
    isSuccessfulRequest,
    nullController,
    removeData,
    saveData
} from "../../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
import ResourceNotFound from "../../NotFound/ResourceNotFound";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel";
import Loading from "../../../components/Loading/Loading";
import HeaderDetailView from "../../../components/CustomComponents/HeaderDetailView";
import MemberDetails from "./MemberDetails";
import {getMemberDetails, syncMember, syncMemberData} from "../../../services/memberService";
import memberDetails from "../../../models/MemberModel";
import {validateSession} from "../../../services/api";
import {
    ERROR_PASSWORD_REQUIRED,
    NOTIFICATION_DURATION,
    PAYMENT_METHOD_ENROLLMENT
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import MemberOrders from "./MemberOrders";
import MemberPaymentsRequest from "./MemberPaymentsRequest";
import CustomButton from "../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {formTitles} from "../../../utils/statusHelpers";
import Muted from "../../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import MemberForm from "./MemberForm";
import MemberPaymentMethods from "./MemberPaymentMethods";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MemberSyncModal from "./MemberSyncModal";
import {getCompanyInformation} from "../../../services/accountService";


class QuickActionsMember extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: props.match.params.id,
            member: null,
            openMember: false,
            actionType: 1,
            isLoading: false,
            notFound: false,
            value: 0,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
            isSyncingPayments: false,
            isSyncingData: false,
            requireUpdate: false,
            openSyncForm: false,
            show2ndParam: false,
            require2ndParam: false,
            paymentMethodLink: null,
            memberAuth2ndParam: "",
            memberAuth2ndLabel: props.t('AUTH_2ND_PARAM'),
        };
        this.closeForm = this.closeForm.bind(this);
    }


    componentDidMount(): void {
        const { match } = this.props;
        const member = getData("member");

        if (match.params.id && member !== null) {
            this.setState({ member: member, isLoading: true, uuid: match.params.id });
            this.request(match.params.id);
            this.getLink();

        } else {
            this.setState({ notFound: true });
            removeData("member");
            this.props.history.goBack();
        }
    }



    getLink = () => {
        getCompanyInformation()
            .then((response) => {
                this.setState({
                    paymentMethodLink: response.data.link + PAYMENT_METHOD_ENROLLMENT + "&mid=" + getData("member").externalId
                });
            })
            .catch(e => { console.log("No link available") });
    };


    handleCloseForm = () => {
        this.setState({ openMember: false });
    };
    closeForm(state, value, type) {
        this.setState({
            [state]: value,
            isSyncingPayments: false,
            isSyncingData: false,
            requireUpdate: false,
            openSyncForm: false,
            show2ndParam: false,
            require2ndParam: false,
            memberAuth2ndParam: "",
        });

        if(type)
            this.request(this.state.uuid);
    }
    openForm = (action, formData) => {
        memberDetails.setMemberDetail(formData);

        if (action === 1 || action === 0) {
            this.setState({ openMember: true, actionType: action });
        }
    };



    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return }
        this.setState({ notification: false });
    };


    request = (memberId) => {
        getMemberDetails(memberId)
            .then((response) => {
                this.setState({
                    member: response.data,
                    isLoading: false
                });

                memberDetails.setMemberDetail(response.data);
                saveData("member", response.data);
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });
    };


    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangePassword = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value,
        });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };


    handleSyncMember = async () => {
        const { member, memberAuth2ndParam } = this.state;

        this.setState({
            requireUpdate: false,
            isSyncingData: true
        }, async () => {

            let memberDataParameters = {
                "memberId": member.uuid,
                "username": member.externalId,
                "password": memberAuth2ndParam
            };


            let syncDataResult = await syncMemberData(memberDataParameters);
            let result = this.handleResponseResolver(syncDataResult, 'isSyncingData');

            if (result) {
                let memberPaymentsParameters = {
                    "memberId": member.uuid,
                    "storeId": member.defaultStoreId
                };

                let syncPaymentsResult = await syncMember(memberPaymentsParameters);
                this.handleResponseResolver(syncPaymentsResult, 'isSyncingPayments');
            }
        });
    };


    handleResponseResolver = (r, loadingState) => {
        let success = false;

        let { member } = this.state;
        const {t, history} = this.props;


        if (r !== null && r !== undefined) {
            if (isSuccessfulRequest(r)) {
                this.setState({
                    [loadingState]: false,
                    isSyncingPayments: loadingState !== 'isSyncingPayments',
                    requireUpdate: true
                }, () => {
                    success = true;

                    if (!this.state.isSyncingData && !this.state.isSyncingPayments && this.state.requireUpdate) {
                        this.setState({
                            isLoading: true,
                            isSyncingPayments: false,
                            isSyncingData: false,
                            requireUpdate: false,
                            openSyncForm: false,
                            show2ndParam: false,
                            require2ndParam: false,
                            memberAuth2ndParam: "",
                        }, () => {
                            this.request(member.uuid);
                        });
                    }
                });
            } else {
                const requestSecondParameter = this.state.require2ndParam
                    || !isEmpty(r) && !isEmpty(r.data) && !isEmpty(r.data.error) && r.data.error.errorType === ERROR_PASSWORD_REQUIRED;

                this.setState({
                    [loadingState]: false,
                    require2ndParam: requestSecondParameter
                });

                this.showNotification(r.data.message, "danger", NotificationFailedIcon, null);

                validateSession(r, history);
            }

        } else {
            this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }

        return success;
    }



    render() {
        const { classes, t, routeProps } = this.props;
        const { notFound, value, isLoading, member, uuid, actionType, isSyncingData, isSyncingPayments, openSyncForm,
            memberAuth2ndParam, show2ndParam, memberAuth2ndLabel, require2ndParam, paymentMethodLink } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <Backdrop className={classes.backdrop} open={isSyncingData || isSyncingPayments}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {
                    notFound ? <ResourceNotFound/> : (
                        <div>
                            {
                                isLoading ? <Loading/> : (
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <HeaderDetailView
                                                title={nullController(member, "name")}
                                                description={nullController(member, "externalId")}/>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <div className={classes.rightPaper}>
                                                <CustomButton xz
                                                    variant="outlined"
                                                    color="white"
                                                    onClick={() => this.setState({ openSyncForm: true })}
                                                    className={classes.cancelButton}>
                                                    {t('BTN_SYNC_MEMBER_DATA')}
                                                </CustomButton>
                                                <CustomButton
                                                    variant="outlined"
                                                    color="white"
                                                    onClick={() => this.openForm(1, member)}
                                                    className={classes.cancelButton}>
                                                    {t('EDIT_TEXT')}
                                                </CustomButton>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <AppBar position={"static"} className={classes.tabBar}>
                                                <Tabs value={value} onChange={this.handleChange} aria-label="tab">
                                                    <Tab label={t('STEP_DETAILS_TEXT')} {...this.a11yProps(0)} />
                                                    <Tab label={t('PAYMENT_REQUEST_TEXT')} {...this.a11yProps(1)} />
                                                    <Tab label={t('ORDERS_TEXT')} {...this.a11yProps(2)} />
                                                    <Tab label={t('PAYMENT_METHODS_TEXT')} {...this.a11yProps(3)} />
                                                </Tabs>
                                            </AppBar>
                                            <TabPanel value={value} index={0}>
                                                <MemberDetails loading={isLoading} data={member !== null ? member : []}/>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <MemberPaymentsRequest uuid={uuid}/>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <MemberOrders uuid={uuid}/>
                                            </TabPanel>
                                            <TabPanel value={value} index={3}>
                                                <MemberPaymentMethods memberId={uuid} paymentMethodLink={paymentMethodLink}/>
                                            </TabPanel>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }
                        </div>
                    )
                }
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openMember}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t('MEMBER_TEXT')}
                        <Muted>
                            {t('FORM_MEMBER_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <MemberForm closeForm={this.closeForm} action={actionType}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={openSyncForm}
                    onClose={() => this.closeForm("openSyncForm", false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('FORM_SYNC_MEMBER')}
                        <Muted>
                            {t('FORM_SYNC_MEMBER_DESCRIPTION') + (member != null ? t('LAST_SYNC_DATE') + member.lastSyncDate : "")}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <MemberSyncModal
                            require2ndParam={require2ndParam}
                            show={show2ndParam}
                            label={memberAuth2ndLabel}
                            member={member}
                            password={memberAuth2ndParam}
                            onChange={this.handleChangePassword}
                            onClose={this.closeForm}
                            onSync={this.handleSyncMember}
                            onShowPassword={() => { this.setState( { show2ndParam: !this.state.show2ndParam })}}
                        />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsMember.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(QuickActionsMember)));