import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {Backdrop, CircularProgress} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import Select from "../../components/CustomSelect/CustomSelect";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {debitStatus, statusColors} from "../../utils/statusHelpers";

import {WIJMO_DISTRIBUTION_KEY} from "../../variables/apiConstants";
import {
    isEmpty,
    nullController, timeSince
} from "../../utils/helpersFunctions";
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { PdfPageOrientation } from '@grapecity/wijmo.pdf';
import { ExportMode, ScaleMode, FlexGridPdfConverter } from '@grapecity/wijmo.grid.pdf';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
// import { ListBox } from '@grapecity/wijmo.react.input';
// import { showPopup, hidePopup, hasClass, contains, closest } from '@grapecity/wijmo';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { setLicenseKey } from '@grapecity/wijmo';
import "../../assets/css/wijmo-main-flex-grid.css";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import {generateCellTemplate} from "../../utils/wijmo/wijmoUtilities";
import SimpleButton from "@material-ui/core/Button";

setLicenseKey(WIJMO_DISTRIBUTION_KEY);



class TableGridWijmo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isExcelPreparing: false,
            //     updatedTime: new Date(),
            // timeSince: timeSince(new Date(), props.t)
        };
        // this.isDragEnabled = false;
        // this.dragSrc = null;
        // this.dragDst = null;
        this.theGrid = React.createRef();
        this.theSearch = React.createRef();
        this.selector = null;
    };

    componentDidMount() {
        let theGrid = this.theGrid.current.control;
        let theSearch = this.theSearch.current.control;
        theSearch.grid = theGrid;

        // window.setInterval(() => {
        //     this.setState({
        //         timeSince: timeSince(this.state.updatedTime, this.props.t)
        //     });
        // }, 30000);
    }


    _initializeGrid = (flex) => {
        const { multiSelect, handleSelect, handleLoadMore } = this.props;
        this._flexGrid = flex;

        if (multiSelect) {
            this.selector = new Selector(flex, {
                itemChecked: (s, e) => {
                    handleSelect(flex, s, e);
                }
            });
        }

        flex.scrollPositionChanged.addHandler(async (s) => {
            // if we're close to the bottom, load more
            if ((s.viewRange.bottomRow >= s.rows.length - 1) && (s.rows.length > 0)) {
                let view = s.collectionView;
                if (!isEmpty(view) && !isEmpty(handleLoadMore) && typeof handleLoadMore === 'function') {
                    let index = view.currentPosition; // keep position in case the view is sorted
                    await handleLoadMore();
                    view.refresh();
                    view.currentPosition = index;
                }
            }
        });


        // this._flexGrid.formatItem.addHandler((s, e) => {
        //     if (e.panel == s.topLeftCells) {
        //         e.cell.innerHTML =
        //             '<span class="column-picker-icon glyphicon glyphicon-cog"></span>';
        //     }
        // });
        // // show the column picker when the user clicks the top-left cell
        // let ref = this._flexGrid.hostElement.querySelector(".wj-topleft");
        // ref.addEventListener("mousedown", e => {
        //     if (hasClass(e.target, "column-picker-icon")) {
        //         let host = this.columnPicker.hostElement;
        //         if (!host.offsetHeight) {
        //             showPopup(host, ref, false, true, false);
        //             this.columnPicker.focus();
        //         }
        //         else {
        //             hidePopup(host, true, true);
        //             this._flexGrid.focus();
        //         }
        //         this.columnPicker.focus();
        //         e.preventDefault();
        //     }
        // });
        // // work around Safari/IOS bug (TFS 321525, 361500, 402670)
        // // https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
        // window.addEventListener('touchstart', (e) => {
        //     let host = this.columnPicker.hostElement;
        //     if (!contains(host, e.target) && !closest(e.target, '.wj-flexgrid')) {
        //         hidePopup(host, true, true);
        //     }
        // });
    };

    // initializedPicker = (picker) => {
    //     this.columnPicker = picker;
    //     if (this._flexGrid) {
    //         this.columnPicker.itemsSource = this._flexGrid.columns;
    //         this.columnPicker.checkedMemberPath = 'visible';
    //         this.columnPicker.displayMemberPath = 'header';
    //         this.columnPicker.lostFocus.addHandler(() => {
    //             hidePopup(this.columnPicker.hostElement);
    //         });
    //         this.columnPicker.formatItem.addHandler((s, e) => {
    //             this.enableDragItem(e.item, this.isDragEnabled);
    //         });
    //     }
    // };

    // saveLayout() {
    //     localStorage.setItem("myLayout", this.flex.columnLayout);
    // }
    // loadLayout() {
    //     let layout = localStorage.getItem("myLayout");
    //     if (layout) {
    //         this.flex.columnLayout = layout;
    //     }
    // }
    // handleDragStart = (e) => {
    //     this.dragSrc = closest(e.target, '.wj-listbox-item');
    //     if (this.dragSrc) {
    //         e.dataTransfer.setData('text', this.dragSrc.innerHTML);
    //         e.dataTransfer.effectAllowed = 'move';
    //     }
    // }
    // handleDragOver = (e) => {
    //     const dragOver = closest(e.target, '.wj-listbox-item');
    //     if (this.dragDst && this.dragDst !== dragOver) {
    //         this.removeDropMarker();
    //     }
    //     if (dragOver && dragOver !== this.dragSrc) {
    //         e.preventDefault();
    //         e.dataTransfer.dropEffect = 'move';
    //         this.dragDst = dragOver;
    //         const src = this.getElementIndex(this.dragSrc);
    //         const dst = this.getElementIndex(this.dragDst);
    //         this.removeDropMarker();
    //         this.addDropMarker(dst > src);
    //     }
    //     else {
    //         this.dragDst = null;
    //     }
    // }
    // handleDrop = (e) => {
    //     if (this.dragSrc && this.dragDst) {
    //         e.preventDefault();
    //         const src = this.getElementIndex(this.dragSrc);
    //         const dst = this.getElementIndex(this.dragDst);
    //         this.flex.columns.moveElement(src, dst);
    //     }
    // }
    // handleDragEnd = (e) => {
    //     this.dragSrc = null;
    //     this.dragDst = null;
    //     this.removeDropMarker();
    // }
    // enableDrag = (e) => {
    //     const element = e.target;
    //     this.isDragEnabled = element.checked;
    //     const host = this.columnPicker.hostElement;
    //     const items = host.getElementsByClassName('wj-listbox-item');
    //     for (let i = 0; i < items.length; i++) {
    //         this.enableDragItem(items[i], this.isDragEnabled);
    //     }
    // }
    // enableDragItem = (item, enabled) => {
    //     item.setAttribute('draggable', enabled.toString());
    // }
    // getElementIndex = (element) => {
    //     const parent = element.parentElement;
    //     const siblings = Array.prototype.slice.call(parent.children);
    //     return siblings.indexOf(element);
    // }
    // removeDropMarker = () => {
    //     removeChild(getElement('.drop-marker'));
    // }
    // addDropMarker = (isAfterPos) => {
    //     const itemsGap = 10;
    //     const width = 6;
    //     const margin = itemsGap / width;
    //     const height = this.dragDst.clientHeight;
    //     const topPos = this.dragDst.offsetTop;
    //     const leftPos = isAfterPos
    //         ? (this.dragDst.offsetLeft + this.dragDst.clientWidth + margin)
    //         : (this.dragDst.offsetLeft - itemsGap + margin);
    //     const css = `top:${topPos}px;left:${leftPos}px;height:${height}px;width:${width}px`;
    //     const html = `<div class="drop-marker" style="${css}">&nbsp</div>`;
    //     createElement(html, this.columnPicker.hostElement);
    // }



    getActionButtonClasses = (key, length, classes) => {
        let btnAction = classes.gridActionButton;

        if (key === 0) {
            btnAction = btnAction + " " + classes.gridActionButtonLeft;
        }
        if ((key + 1) === length) {
            btnAction = btnAction + " " + classes.gridActionButtonRight;
        }


        return btnAction;
    };


    exportPdf = () => {
        FlexGridPdfConverter.export(this._flexGrid, this.props.exportFileName + '.pdf', {
            // maxPages: 10,
            exportMode: ExportMode.All,
            scaleMode: ScaleMode.PageWidth,
            documentOptions: {
                pageSettings: {
                    // layout: PdfPageOrientation.Landscape
                    layout: PdfPageOrientation.Landscape
                },
                // header: {
                //     declarative: {
                //         text: '\t&[Page]\\&[Pages]'
                //     }
                // },
                footer: {
                    declarative: {
                        text: '\t&[Page]\\&[Pages]'
                    }
                }
            },
            styles: {
                cellStyle: {
                    backgroundColor: '#ffffff',
                    borderColor: '#c6c6c6'
                },
                altCellStyle: {
                    backgroundColor: '#f9f9f9'
                },
                groupCellStyle: {
                    backgroundColor: '#dddddd'
                },
                headerCellStyle: {
                    backgroundColor: '#eaeaea'
                }
            }
        });
    };


    exportExcel = () => {
        const { isExcelPreparing: preparing } = this.state;

        if (!preparing) {
            this.setState({ isExcelPreparing: true }, () => {
                wjcGridXlsx.FlexGridXlsxConverter.saveAsync(this._flexGrid, {
                        includeColumnHeaders: true,
                        includeStyles: true,
                        // formatItem: this.exportFormatItem,
                        formatItem: null
                    }, this.props.exportFileName + '.xlsx',
                    () => {
                        console.log('Export to Excel completed');
                        this.resetExcelProgress();
                    }, (err) => {
                        console.error(`Export to Excel failed: ${err}`);
                        this.resetExcelProgress();
                    }, (prg) => {
                        console.log('Exporting to Excel...');
                        // this.setState({
                        //     isExcelPreparing: false,
                        //     isExcelExporting: true,
                        //     excelProgress: prg,
                        // });
                    }, true);
            });
        } else {
            this.cancelExcelExport(progress => {
                console.log('Export to Excel canceled');
                this.resetExcelProgress();
            });
        }
    };

    // const { isExcelPreparing: preparing, isExcelExporting: exporting } = this.state;
    resetExcelProgress = () => {
        this.setState({
            isExcelPreparing: false,
            // isExcelExporting: false,
            // excelProgress: 0,
        });
        // this.setState({
        //     isExcelPreparing: false,
        //     isExcelExporting: false,
        //     excelProgress: 0,
        // });
    };


    cancelExcelExport = (doneCallback) => {
        wjcGridXlsx.FlexGridXlsxConverter.cancelAsync(doneCallback);
    };



    render() {
        const { isExcelPreparing } = this.state;
        const {
            classes, t, data, isLoading, tableName, onRefresh, isRequesting, refreshAvailable, headerFixed,
            handleSelectColumn,  isLoadingMore, handleInitGrid, loadingText, title, headers, actions, controls, cellSelectedEnabled
        } = this.props;


        return (
            <div>
                <Backdrop className={classes.backdropCenter} open={ isExcelPreparing }>
                    <CircularProgress color={"inherit"} />
                    {
                        isExcelPreparing ? (
                            <div>
                                <p>{t('EXPORTING_EXCEL_FILE')}</p>
                                <SimpleButton
                                    style={{ "&:hover": { backgroundColor: "transparent !important"}}}
                                    // className={classes.btnCardRowTxtBlue}
                                    size="small"
                                    color={"inherit"}
                                    onClick={() => this.resetExcelProgress()}>
                                    {t('BTN_CANCEL')}
                                </SimpleButton>
                            </div>
                        ) : null
                    }
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card className={classes.cardFixBottom}>
                            <CardHeader className={classes.cardHeaderGrid}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={headerFixed ? 4 : 3} lg={headerFixed ? 4 : 3}>
                                        <div className={classes.gridCardHeaderRowTitle}>
                                            <h4 style={{marginTop: "0px"}}>
                                                {title}
                                            </h4>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={headerFixed ? 8 : 9} lg={headerFixed ? 8 : 9}>
                                        <div className={classes.gridCardHeaderRowTop}>
                                            <div style={{marginRight: "10px", height: "100%", maxWidth: "140px"}}>
                                                <FlexGridSearch ref={this.theSearch} placeholder={t('SEARCH_TEXT')}/>
                                            </div>
                                            {
                                                controls.map((control, key) => {
                                                    if (control.type === 'icon') {
                                                        return(
                                                            <div key={key} style={{marginRight: "5px", height: "100%"}}>
                                                                <button className={control.disabled ? classes.gridControlButtonIcon : classes.gridControlButtonIcon} onClick={control.function}>
                                                                    <control.icon className={control.disabled ? classes.gridControlIcon : classes.gridControlIcon}/>
                                                                </button>
                                                            </div>
                                                        );
                                                    } else {
                                                        return(
                                                            <div key={key} style={{marginRight: "5px", height: "35px", minWidth: "130px", maxWidth: "170px"}}>
                                                                <Select
                                                                    value={control.value}
                                                                    onChange={control.function}
                                                                    options={control.data}
                                                                    closeMenuOnSelect={true}
                                                                    placeholder={!isEmpty(control.label) ? control.label :t('SELECT_TEXT')}
                                                                    isDisabled={control.disabled}
                                                                    isLoading={control.isLoading}
                                                                    maxMenuHeight={350}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary: primaryColor[0],
                                                                        },
                                                                    })}
                                                                    styles={{
                                                                        menu: provided => ({ ...provided, zIndex: 5 }),
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            maxHeight: "35.5px",
                                                                            minHeight: "35.5px",
                                                                        }),
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                })
                                            }
                                            {
                                                actions.map((action, key) => {
                                                    return(
                                                        <div key={key}>
                                                            <button
                                                                disabled={action.disabled || action.isLoading}
                                                                className={this.getActionButtonClasses(key, actions.length, classes)}
                                                                onClick={action.type === 'pdf' ? this.exportPdf : (action.type === 'excel' ? this.exportExcel : action.function)}>
                                                                {action.label}
                                                            </button>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className={classes.cardFullWidth}>
                                {
                                    data.length > 0 || isLoading ? null : <p className={classes.emptyGridTxt}>{t("THERE_NOT_ITEMS")}</p>
                                }
                                <FlexGrid
                                    ref={this.theGrid}
                                    showMarquee={!cellSelectedEnabled}
                                    allowPinning="SingleColumn"
                                    autoSizeMode={3}
                                    deferResizing={true}
                                    itemsSource={data}
                                    itemsSourceChanged={(c) => {
                                        if (c.rows.length > 0) {
                                            c.rows.forEach(r => {
                                                r.isSelected =  r._data.isSelected;
                                                // r.isDisabled =  r._data.disabled;
                                            })
                                        }
                                    }}
                                    // selectionChanged={(c) => {
                                    //
                                    // }}
                                    // refreshed={(c) => {
                                    //
                                    // }}
                                    // updatedView={(c) => {
                                    // }}
                                    initialized={!isEmpty(handleInitGrid) && typeof  handleInitGrid === 'function' ? handleInitGrid : this._initializeGrid}>


                                    {/*<div className="column-picker-div" onDragStart={this.handleDragStart} onDragOver={this.handleDragOver} onDrop={this.handleDrop} onDragEnd={this.handleDragEnd}>*/}
                                    {/*    <ListBox initialized={this.initializedPicker}/>*/}
                                    {/*</div>*/}

                                    <FlexGridFilter/>


                                    {
                                        headers.map((header, key) => {
                                            if (header.isCustomCell) {
                                                return (
                                                    <FlexGridColumn
                                                        key={key}
                                                        binding={header.name !== "action" ? header.name : ""}
                                                        header={header.label}
                                                        isReadOnly={header.isReadOnly}
                                                        width={header.width}
                                                        allowResizing={true}
                                                        cssClass={header.cssClass}
                                                    >
                                                        <FlexGridCellTemplate cellType="Cell" template={(context) => {
                                                            return (
                                                                <React.Fragment>
                                                                    {generateCellTemplate(context, tableName, header.name, classes, header.onSelect, t)}
                                                                </React.Fragment>
                                                            )
                                                        }}/>
                                                    </FlexGridColumn>
                                                )
                                            } else {
                                                return (
                                                    <FlexGridColumn
                                                        key={key}
                                                        binding={header.name !== "action" ? header.name : ""}
                                                        header={header.label}
                                                        isReadOnly={header.isReadOnly}
                                                        width={header.width}
                                                        allowResizing={true}
                                                        cssClass={header.cssClass}
                                                    />
                                                )
                                            }

                                        })
                                    }
                                </FlexGrid>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


TableGridWijmo.defaultProps = {
    data: [],
    actions: [],
    controls: [],
    headers: []
};

TableGridWijmo.propTypes = {
    classes: PropTypes.object,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            function: PropTypes.func.isRequired,
            type: PropTypes.oneOf(["simple", "excel", "pdf"]),
            isLoading: PropTypes.bool,
            disabled: PropTypes.bool
        })
    ).isRequired,
    controls: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["icon", "select"]),
            value: PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
            data: PropTypes.array,
            label: PropTypes.string,
            icon: PropTypes.element,
            function: PropTypes.func.isRequired,
            isLoading: PropTypes.bool,
            disabled: PropTypes.bool
        })
    ).isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            cssClass: PropTypes.string,
            isReadOnly: PropTypes.bool,
            width: PropTypes.oneOfType([PropTypes.oneOf(["*", "2*"]), PropTypes.number]),
            onSelect: PropTypes.func,
            isCustomCell: PropTypes.bool,
            clickable: PropTypes.bool
        })
    ).isRequired,
    data: PropTypes.array.isRequired,
    tableName: PropTypes.string,
    exportFileName: PropTypes.string,
    title: PropTypes.string,
    headerFixed: PropTypes.string,
    loadingText: PropTypes.string,
    multiSelect: PropTypes.bool,
    cellSelectedEnabled: PropTypes.bool,
    importEnabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    handleSelect: PropTypes.func,
    handleLoadMore: PropTypes.func,
    handleInitGrid: PropTypes.func,
}

export default withStyles(tableViewStyle)(withTranslation()(TableGridWijmo));