/** Create by Marlon Reyes 24/09/2019 **/

import { primaryColor, grayColor } from "../material-dashboard-react.jsx";
import {
    blackColor,
    dangerColor,
    defaultFont,
    hexToRgb,
    lightColors,
    successColor, warningColor,
    whiteColor
} from "../material-dashboard-react";
import checkboxAdnRadioStyle from "../material-dashboard-react/checkboxAdnRadioStyle";

const tableViewStyle = {
    ...checkboxAdnRadioStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    rightPaper:{
        float: "right",
        marginBottom: 0,
    },
    rMargin: {
        marginRight: 10
    },
    search:{
        width: "250px",
        "&:before": {
            backgroundColor: "transparent !important"
        },
        "&:hover:not($disabled):before,&:before": {
            borderColor: grayColor[4] + " !important",
            borderWidth: "1px !important"
        },
        "&:after": {
            borderColor: primaryColor[0]
        },
    },
    objectColor:{
        color: grayColor[0]
    },
    rowColor:{
        backgroundColor: lightColors[1] + "!important"
    },
    thColor:{
        color: grayColor[2]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        overflow: "auto",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableWrapper: {
        maxHeight: "calc(100vh - 235px)",
        overflow: "auto",
    },
    headTitle:{
        fontWeight: "bold",
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0,
    },
    tableHeadCell: {
        color: "inherit",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        "&, &$tableCell": {
            fontSize: "1em"
        },
    },
    tableCellActions: {
        color: "inherit",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        "&, &$tableCell": {
            fontSize: "1em"
        },
        lineHeight: "1.42857143",
        padding: "8px 4px",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
        width: "7%",
        textAlign: "center"
    },
    tableCell: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "8px 4px !important",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
    },
    tableCellCenter: {
        justifyContent: "center"
    },
    tableCellWijmo: {
        fontFamily: "sans-serif",
        padding: "0px 4px !important",
        verticalAlign: "top",
        border: "none"
    },
    tableCell1: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "4px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableRow: {
        padding: "1px 1px",
    },
    tableResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "calc(100vh - 235px)",
    },
    tableResumeResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "290px"
    },
    status: {
        alignItems: "center",
        marginRight: 1,
        width: "auto",
    },
    statusSmallText: {
        alignItems: "center",
        width: "auto",
        fontSize: "0.7525rem",
        lineHeight: "1.42857143"
    },
    statusSuccess: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: successColor[2]
    },
    statusDanger: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: dangerColor[2]
    },
    hoverRow:{
        cursor: "pointer",
    },
    hoverRowClickable:{
        cursor: "pointer",
        "& p:hover": {
            textDecorationLine: "underline !important",
            color: "#4C5BFF"
        }
    },
    card:{
        marginTop: "1px",
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0"
    },
    tableActionButtonIcon: {
        color: primaryColor[0],
        width: "17px",
        height: "17px"
    },
    blueColor: {
        color: primaryColor[0],
    },
    selectFilter:{
        marginTop: "7px",
    },
    root: {
        width: "100%",
    },
    exportContainer:{
        float: "right",
        marginRight: "5px",
    },
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    cancelButton:{
        border: "1px solid #A2A2A2"
    },
    whiteButton: {
        border: "1px solid #A2A2A2",
        marginRight: 5,
    },
    buttonFit: {
        paddingLeft: 10,
        paddingRight: 10
    },
    buttonFitWithMarginRight: {
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 20
    },
    cardBodyBottomFit: {
        paddingBottom: 0
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    backdropCenter: {
        zIndex: 9999,
        color: '#fff',
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
    },
    closeIconButton: {
        width: "27px",
        height: "27px",
        padding: "0",
        backgroundColor: dangerColor[0],
    },
    formIcons: {
        color: whiteColor,
        width: "27px",
        height: "27px"
    },
    iconButtonRed: {
        width: "27px",
        height: "27px",
        color: dangerColor[0]
    },
    iconButtonBlue: {
        width: "27px",
        height: "27px",
        color: primaryColor[0]
    },
    iconCheckBlue: {
        width: "10px",
        height: "210px",
        color: primaryColor[0]
    },
    infoIcon: {
        marginLeft: "2px",
        width: "17px",
        height: "17px",
        color: grayColor[0]
    },
    center:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    copyButtonIcon: {
        width: "17px",
        height: "17px",
        color: primaryColor[0]
    },
    thBlueIcon: {
        color: primaryColor[0],
        width: "27px",
        height: "27px",
        alignSelf: "center"
    },
    thGreenIcon: {
        color: successColor[2],
        width: "23px",
        height: "23px",
        alignSelf: "center",
    },
    statusFixed: {
        alignItems: "center",
        margin: 0,
        width: "auto",
        padding: 0
    },
    minCardLightText: {
        fontSize: "10px",
        color: grayColor[0],
        fontWeight: "400"
    },
    minPaymentLightText: {
        fontSize: "9px",
        color: grayColor[0],
        fontWeight: "400",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "center",
        textOverflow: "ellipsis"
    },
    minCardDarkText: {
        fontSize: "11px",
        color: grayColor[2],
        fontWeight: "500",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover,&:focus": {
            textDecoration: "none",
            color: grayColor[2],
        },
    },
    identifierContainer: {
        backgroundColor: "transparent",
        fontSize: "11px",
        color: grayColor[2],
        fontWeight: "500",
        height: "60%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover,&:focus": {
            backgroundColor: lightColors[1],
            borderRadius: "5px"
        },
    },

    gridItemWithoutLeftPadding: {
        padding: "0 15px 15px 0 !important"
    },
    gridItemNoPadding: {
        padding: "8px 0 0 30px !important"
    },
    kanbanContainer: {
        backgroundColor: "transparent",
        width: "100%",
        padding: "15px 0 15px 30px !important"
    },
    groupCard: {
        backgroundColor: whiteColor,
        marginTop: "5px",
        marginBottom: "5px",
        width: "95%",
        marginLeft: "2.5%",
        marginRight: "2.5%",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: "#E9E9E9",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    groupCardSelected: {
        backgroundColor: whiteColor,
        borderRadius: "6px",
        marginTop: "5px",
        border: "1px solid #E3E3E3",
        marginBottom: "5px",
        width: "95%",
        marginLeft: "2.5%",
        marginRight: "2.5%",
        color: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.87)",
        background: whiteColor,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: "#E9E9E9",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    zoneCard: {
        backgroundColor: whiteColor,
        marginTop: "5px",
        marginBottom: "5px",
        width: "97%",
        marginLeft: "1.5%",
        marginRight: "1.5%",
        border: "0",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: "#E9E9E9",
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    zoneCardSelected: {
        backgroundColor: primaryColor[0],
        marginTop: "5px",
        marginBottom: "5px",
        width: "97%",
        marginLeft: "1.5%",
        marginRight: "1.5%",
        border: "0",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(whiteColor) + ", 0.87)",
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0",
        minHeight: "2.5rem",
        lineHeight: ".975rem",
        fontSize: ".875rem",
        textAlign: "left",
        "&:hover,&:focus": {
            backgroundColor: primaryColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb("#E9E9E9") +
                ", 0.2)"
        },
    },
    formContainer: {
        marginBottom: 1,
        backgroundColor: "transparent"
    },
    selectTitle:{
        ...defaultFont,
        color: grayColor[2] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    iconAvatarBlue: {
        width: "27px",
        height: "27px",
        padding: "0",
        color: primaryColor[0]
    },
    iconAvatar: {
        width: "27px",
        height: "27px",
        backgroundColor: "white",
        marginRight: "3px"
    },
    modifierSmallGrayText: {
        fontSize: "8px",
        color: grayColor[0],
        fontWeight: "400",
        marginLeft: "5px",
        padding: 0,
    },
    priceView: {
        float: "right",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
    item: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "2px"
    },
    quantityView: {
        width: "10%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    productView: {
        width: "90%",
        marginRight: "7%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: "2%",
        marginBottom: "1px",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    descriptionGrayText: {
        fontSize: "10px",
        color: grayColor[2],
        fontWeight: "400"
    },
    orderTypeIcon: {
        width: "17px",
        height: "17px",
        padding: "0",
        marginRight: "2px",
        color: primaryColor[0]
    },
    orderDineInIcon: {
        width: "19px",
        height: "19px",
        padding: "0",
        marginRight: "2px",
        color: primaryColor[0]
    },
    warningSmallIcon: {
        width: "14px",
        height: "14px",
        color: warningColor[4]
    },
    containerRight: {
        float: "right",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    containerNoteRight: {
        float: "right",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },

    // Kanban styles
    orderKanbanContainer: {
        backgroundColor: "transparent",
        width: "auto",
        height: "100%",
        paddingBottom: 2
    },
    dragDropContainer: {
        display: "flex",
        width: "auto",
        overflow: "hidden",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        minHeight: "calc(100vh - 125px)",
        height: "calc(100vh - 125px)",
        flexDirection: "row",
        backgroundColor: "transparent",
        marginTop: 10,
        paddingBottom: 2
    },
    zoneTitle: {
        marginTop: 0,
        marginBottom: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    roundNotificationNew: {
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        backgroundColor: "orange",
        width: "17px",
        height: "17px",
        borderRadius: "50%",
        border: "1.5px solid white",
    },
    roundNotificationProgress: {
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        backgroundColor: "#68C7BD",
        width: "17px",
        height: "17px",
        borderRadius: "50%",
        border: "1.5px solid white",
    },
    zoneNameContainer: {
        width: "80%",
        marginRight: "5%",
        overflow: "hidden",
        display: "flex",
        alignItems: "flex-start",
        textOverflow: "ellipsis",
        minHeight: "1em",
        maxHeight: "2.2em"
    },
    zoneWrapperContainer: {
        backgroundColor: lightColors[1],
        width: "95%",
        marginLeft: "2.5%",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        marginRight: "2.5%",
        marginTop: "10px",
        height: "auto",
        minHeight: "calc(100vh - 125px)"
    },
    zoneGroupContainer: {
        backgroundColor: whiteColor,
        width: "100%",
        overflow: "scroll",
        overflowX: "auto",
        overflowY: "auto",
        height: "auto",
        minHeight: "calc(100vh - 160px)",
        borderRight: "1.5px solid #E3E3E3"
    },
    zoneListContainer: {
        backgroundColor: grayColor[25],
        width: "100%",
        overflow: "scroll",
        overflowX: "auto",
        overflowY: "auto",
        height: "auto",
        minHeight: "calc(100vh - 160px)"
    },
    hidden: {
        backgroundColor: "transparent",
        width: "95%",
        marginLeft: "2.5%",
        overflowScrolling: "touch",
        overflowX: "auto",
        overflowY: "auto",
        marginRight: "2.5%",
        marginTop: "10px",
        height: "auto",
        minHeight: "calc(100vh - 125px)"
    },
    zoneSection: {
        display: "flex",
        width: "100%",
        overflow: "hidden",
        height: "auto",
        flexDirection: "row",
        backgroundColor: "transparent",
    },
    storeZoneContainer: {
        display: "flex",
        width: "100%",
        overflow: "hidden",
        minHeight: "calc(100vh - 60px)",
        height: "auto",
        maxHeight: "calc(100vh - 60px)",
        flexDirection: "column",
        backgroundColor: "white",
        zIndex: 99,
        border: "1.5px solid #E3E3E3"
    },
    storeContainer: {
        marginTop: 11,
        width: "95%",
        marginLeft: "2.5%",
        marginRight: "2.5%"
    },
    dropContainer: {
        width: "260px",
        height: "auto",
        minHeight: "calc(100vh - 125px)",
        display: "inline-block",
        flexDirection: "column",
        marginRight: 10,
        borderTop: "2px solid #E3E3E3",
        borderLeft: "2px solid #E3E3E3",
        borderRight: "2px solid #E3E3E3"
    },
    dropHeader: {
        height: "50px",
        backgroundColor: "white",
        maxWidth: "260px",
        width: "260px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    zonesHeader: {
        marginTop: 11,
        height: "35px",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #E3E3E3",
        borderBottom: "1px solid #E3E3E3"
    },
    dropTitle: {
        color: grayColor[2],
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.9725rem",
        fontFamily: 'sans-serif',
        fontWeight: "300",
        // ...defaultFont,
        "&, &$tableCell": {
            fontSize: "1em"
        },
    },
    dropBody: {
        backgroundColor: "white",
        marginLeft: "-2px",
        paddingBottom: "3px",
        width: "260px",
        height: "auto",
        minHeight: "calc(100vh - 185px)",
        borderBottom: "2px solid #E3E3E3",
        borderLeft: "2px solid #E3E3E3",
        borderRight: "2px solid #E3E3E3"
    },
    dropCardContainer: {
        backgroundColor: "transparent",
        height: "35px",
        width: "95%",
        borderRadius: "6px 6px 0 0",
        display: "flex",
        flexDirection: "row",
        marginLeft: "2%",
        marginRight: "2%"
    },
    dropCardHeader: {
        backgroundColor: "transparent",
        height: "100%",
        width: "35%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardStatusContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardOrderInfoContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "32%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardMethodContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "45%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardIdentifierContainer: {
        backgroundColor: "transparent",
        height: "100%",
        width: "55%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    divider: {
        width: "95%",
        height: ".7px",
        backgroundColor: grayColor[24],
        alignSelf: "center"
    },
    dropCardBody: {
        backgroundColor: "transparent",
        height: "68px",
        maxHeight: "65px",
        width: "94%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.5px",
        marginTop: "1.5px",
        marginLeft: "3%",
        marginRight: "3%"
    },
    dropCardFooter: {
        backgroundColor: "transparent",
        height: "25px",
        width: "95%",
        display: "flex",
        flexDirection: "row",
        marginLeft: "2%",
        marginRight: "2%",
        paddingRight: ".5%",
        paddingLeft: ".5%"
    },
    dropCardTime: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardTimeLarge: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    dropCardTotal: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    dropCardTotalLarge: {
        backgroundColor: "transparent",
        height: "100%",
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    dropCardNote: {
        backgroundColor: "transparent",
        height: "100%",
        width: "25%",
        minWidth: "20%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardDeliveryType: {
        backgroundColor: "transparent",
        position: "relative",
        height: "100%",
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    dropCardDeliveryTpLarge: {
        backgroundColor: "transparent",
        position: "relative",
        height: "100%",
        width: "34%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    // Styles for order detail form dialog
    orderDetailActions: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    actionCloseContainer: {
        display: "flex",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "30%"
    },
    orderDetailIdentifierDialog: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: "40%"
    },
    actionPrintContainer: {
        display: "flex",
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        width: "30%"
    },
    printContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'flex-end',
        marginTop: 5
    },
    iconButton: {
        width: "24px",
        height: "24px",
        padding: "0px"
    },
    close: {
        width: "11px",
        height: "11px"
    },
    inputNoStyle: {
        textShadow: "none",
        display: "inline-block",
        appearance: 'none',
        backgroundColor: "transparent",
        padding: 0,
        width: "192px",
        borderWidth: "0px",
        borderColor: "transparent",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    copyButton: {
        "&.MuiButton-root": {
            padding: "0px 0px",
            minWidth: "50px",
            backgroundColor:"red"
        }
    },
    select:{
        marginTop: 8
    },
    noPadding: {
        paddingTop: "0 !important",
        marginTop: "10px !important",
        marginBottom: "0 !important",
        paddingBottom: "0 !important"
    },
    withoutPadding: {
        paddingTop: "0 !important",
        marginTop: "0px !important",
        marginBottom: "0 !important",
        paddingBottom: "0 !important"
    },
    linkText:{
        fontSize: '12px',
        fontWeight: "400",
        color: primaryColor[0],
    },
    tableCellDoubleGrid: {
        fontFamily: "sans-serif",
        padding: "0px 4px !important",
        verticalAlign: "top",
        border: "none"
    },
    tableCellSingleGrid: {
        fontFamily: "sans-serif",
        padding: "0px 4px !important",
        verticalAlign: "top",
        border: "none"
    },
    tableCellDouble: {
        fontFamily: "sans-serif",
        padding: "8px 4px !important",
        verticalAlign: "top",
    },
    rightPaperFlexLg: {
        display: "flex",
        flexDirection: "row",
        float: "right",
        marginBottom: 0,
    },
    rightPaperFlexXs: {
        display: "flex",
        flexDirection: "column",
        float: "right",
        marginBottom: 0,
    },
    filterSelectContainer:{
        marginTop: 8,
        marginRight: "10px",
        width: "150px"
    },

    balanceCardTitle2: {
        color: grayColor[2],
        fontSize: "20px",
        lineHeight: "18px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    balanceCardSubTitle: {
        color: grayColor[1],
        fontSize: "13px",
        lineHeight: "18px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    balanceCardTitle: {
        color: grayColor[19],
        fontSize: "14px",
        fontWeight: "bold",
        padding: 0,
        marginTop: 0,
        marginBottom: 5,
    },
    screenTitle: {
        marginBottom: "-15px",
        marginTop: 0,
        color: grayColor[2]
    },
    gridTop: {
      marginTop: "10px !important"
    },
    balanceCardTotalText: {
        color: grayColor[2],
        fontSize: "14px",
        marginTop: 0,
        marginBottom: 0,
        padding: 0
    },
    gridNoTop: {
        marginTop: "0px !important",
        paddingTop: "0px !important"
    },
    cardFixed: {
        height: '137px !important',
        minHeight: '137px !important'
    },
    btnTableRowTxtBlue: {
        color: primaryColor[4],
        fontWeight: "bold",
        textAlign: "left",
        margin: "0px !important",
        minWidth: "auto !important",
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "0px !important",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
    },
    buttonSpacing:{
        marginLeft: 5,
        marginRight:5,
    },
    checkBox: {
        padding: "0px",
        width: "25px",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    checkBoxChecked: {
        padding: "0px",
        color: primaryColor[0] + "!important"
    },
    tableRefreshIconBtn: {
        width: "47px",
        height: "47px",
        padding: "0",
        "&:hover,&:focus": {
            backgroundColor: grayColor[10]
        },
    },
    tableCellBtn: {
        height: "20px !important",
        width: "50px !important",
        maxWidth: "50px !important",
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "8px 4px !important",
        verticalAlign: "middle",
        fontSize: "0.7825rem",
    },
    tableRefreshIcon: {
        color: primaryColor[0],
        width: "27px",
        height: "27px"
    },
    tableRefreshIconDisable: {
        color: grayColor[0],
        width: "27px",
        height: "27px"
    },
    tableRowInfo: {
        borderBottom: "none",
        fontFamily: "sans-serif",
        textAlign: "center",
        fontWeight: "bold",
        color: grayColor[3],
        fontSize: "16px",
    },
    fixedHeight: {
        minHeight: "calc(100% -100px)"
    },
    hiddenCsvButton: {
        display: "none"
    },
    formDivContainer: {
        padding: "0px !important",
        marginTop: 0,
        marginBottom: 30
    },
    formContentDescription: {
        fontSize: "15px",
        lineHeight: "16px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginTop: 0,
        paddingTop: 0,
        marginBottom: 20
    },
    formContentTitle: {
        color: grayColor[2],
        fontSize: "15px",
        lineHeight: "15px",
        fontWeight: "bold",
        padding: 0,
        marginBottom: 3,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    formContentLegend: {
        color: grayColor[2],
        fontSize: "13px",
        lineHeight: "13px",
        fontWeight: "normal",
        padding: 0,
        margin: 0,
    },
    cardFullWidth: {
        marginLeft: "0px !important",
        marginRight: "0px !important",
        paddingLeft: "0px !important",
        marginBottom: "-7px !important",
        paddingBottom: "0px !important",
        paddingRight: "0px !important",
        paddingTop: "0px !important"
    },
    cardFixBottom: {
        marginBottom: "0px !important",
        marginTop: "0px !important"
    },
    cardHeaderGrid: {
        paddingBottom: "0px !important",
        marginBottom: "0px !important"
    },
    cardHeaderActions: {
        paddingBottom: "0px !important",
        marginBottom: "0px !important",
    },
    gridCardHeaderRowTitle: {
        height: "35px",
        maxHeight: "35px",
        marginTop: "0px",
        paddingTop: "0px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        float: "left",
        justifyContent: "flex-start",
        marginBottom: "0px",
    },
    gridCardHeaderRowTop: {
        height: "35px",
        maxHeight: "35px",
        marginTop: "0px",
        paddingTop: "0px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        float: "right",
        justifyContent: "flex-end",
        marginBottom: "10px",
    },
    gridCardHeaderTitleLegend: {
        display: "flex",
        flexDirection: "row",
        width: "40%",
        float: "left",
        height: "28px",
        verticalAlign: "bottom",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        marginTop: "0px",
        paddingTop: "0px",
        marginBottom: "0px",
    },
    gridCardHeaderRow: {
        height: "28px",
        display: "flex",
        flexDirection: "row",
        width: "60%",
        float: "right",
        justifyContent: "flex-end"
    },
    gridControlIcon: {
        color: grayColor[1],
        width: "20px",
        height: "20px",
        "&:hover,&:focus": {
            color: primaryColor[2], //0
        },
    },
    gridControlIconDisabled: {
        // color: grayColor[0],
        color: whiteColor[0],
        width: "20px",
        height: "20px"
    },
    gridControlButtonIcon: {
        width: "30px",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textTransform: "none !important",
        // color: grayColor[0],
        borderRadius: "3px",
        padding: "0px",
        position: "relative",
        border: "1px solid rgba(0,0,0,.2)",
        willChange: "box-shadow, transform",
        // transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        // touchAction: "manipulation",
        cursor: "pointer",
        "&:hover": {
            // color: primaryColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.2)"
        },
    },
    gridControlButtonIconDisabled: {
        width: "30px",
        height: "100%",
        backgroundColor: grayColor[0],
        opacity: "0.65",
        pointerEvents: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textTransform: "none !important",
        color: whiteColor,
        borderRadius: "3px",
        padding: "0px",
        position: "relative",
        border: "1px solid rgba(0,0,0,.2)",
        willChange: "box-shadow, transform",
        transition:
            "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "none",
    },
    gridActionButton: {
        padding: "5px 10px 5px 10px",
        width: "auto",
        minWidth: "50px",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textTransform: "none !important",
        color: primaryColor[4],
        position: "relative",
        willChange: "box-shadow, transform",
        // transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        // touchAction: "manipulation",
        cursor: "pointer",
        "&:hover": {
            // color: primaryColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.42), 0 4px 23px 0px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 8px 10px -5px rgba(" +
                hexToRgb(grayColor[0]) +
                ", 0.2)"
        },
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        borderTopColor: "rgba(0,0,0,.2)",
        borderBottomColor: "rgba(0,0,0,.2)",
        borderLeftColor: "rgba(0,0,0,.2)",
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderRight: "none"
    },
    gridActionButtonLeft: {
        borderTopLeftRadius: "3px",
        borderBottomLeftRadius: "3px",
    },
    gridActionButtonRight: {
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        borderRightColor: "rgba(0,0,0,.2)",
        borderRightStyle: "solid",
        borderRightWidth: "1px"
    },
    gridCardHeaderLegend: {
        fontSize: "12px",
        lineHeight: "11px",
        color: "gray",
        marginTop: "0px",
        marginBottom: "0px",
        paddingTop: "0px"
    },
    flexGridCellLink: {
        cursor: "pointer",
        marginTop: "0px",
        marginBottom: "0px",
        color: "#4C5BFF",
        textDecorationLine: "none",
        "&:hover": {
            color: "#014486",
            textDecorationLine: "underline",
        },
    },
    emptyGridTxt: {
        zIndex: 1,
        position: "absolute",
        top: "50%",  /* position the top  edge of the element at the middle of the parent */
        left: "50%", /* position the left edge of the element at the middle of the parent */
        transform: "translate(-50%, -50%)"
    },
    popperNav: {
        zIndex: 999,
    },
};


export default tableViewStyle;