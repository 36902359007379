import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import Status from "../../../components/Status/Status";
import Link from '@material-ui/core/Link';
import {
    getOrderEta,
    nullController,
} from "../../../utils/helpersFunctions";
import {Typography} from "@material-ui/core";
import {DELIVERY, DINE_IN, STATUS_PROGRESS, TAKE_AWAY} from "../../../variables/coreConstants";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {grayColor, hexToRgb} from "../../../assets/jss/material-dashboard-react";
import TimeIcon from "@material-ui/icons/Schedule";
import NoteIcon from "@material-ui/icons/Notes";
import {withTranslation} from "react-i18next";
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import TakeAwayIcon from "@material-ui/icons/DirectionsRun";
import DineInIcon from "@material-ui/icons/Deck";
import DeliveryIcon from "@material-ui/icons/LocalShipping";
import WarningIcon from "@material-ui/icons/Warning";


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: '3px 5px 5px 3px',
    fontFamily: "sans-serif",
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    width: "243px",
    height: "130px",
    maxWidth: "243px",
    borderRadius: "6px",
    boxShadow: "1px 1px 1.5px 1px rgba(" + hexToRgb(grayColor[0]) + ", 0.24)",
    display: "flex",
    flexDirection: "column",

    // change background colour if dragging
    background: isDragging ? "#F3F9FF" : "white",
    color: isDragging ? "white" : null,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    backgroundColor: isDraggingOver ? "#DEEBFF" : "#F3F9FF",
    fontFamily: "sans-serif",
    lineHeight: "1.42857143",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    maxWidth: "250px",
    minWidth: "250px",
    width: "250px",
    height: "auto",
    minHeight: "calc(100vh - 185px)",
    paddingTop: "5px",
    marginRight: "5px",
    marginLeft: "5px",
});


function DroppableOrderList(props) {
    const { classes, component, data, title, dropId, isDisabled, status, t} = props;

    return (
        <div className={classes.dropContainer}>
            <div className={classes.dropHeader}>
                <p className={classes.dropTitle}>{title}</p>
            </div>
            <Droppable droppableId={dropId} isDropDisabled={isDisabled}>
                {(provided, snapshot) => (
                    <div className={classes.dropBody}>
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {data.map((order, index) => (
                                <Draggable key={order.uuid} draggableId={order.uuid} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className={classes.dropCardContainer}>
                                                <div className={classes.dropCardHeader}>
                                                    <CustomAvatar
                                                        alt="Profile"
                                                        src={order.photo}
                                                        className={classes.iconAvatar}
                                                        classNameIcon={classes.iconAvatarBlue}/>
                                                    <Typography className={classes.minCardDarkText}>
                                                        {nullController(order, "customer")}
                                                    </Typography>
                                                </div>
                                                <div className={classes.dropCardStatusContainer}>
                                                    <Status
                                                        className={classes.statusFixed}
                                                        customColor={nullController(order.paymentInformation, "statusColor")}
                                                        size="md"
                                                        fixed={true}
                                                        label={nullController(order.paymentInformation, "statusText")}
                                                    />
                                                </div>
                                                <div className={classes.dropCardOrderInfoContainer}>
                                                    <div className={classes.dropCardMethodContainer}>
                                                        <Typography className={classes.minPaymentLightText}>
                                                            {nullController(order.paymentInformation.methodInformation, "name")}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.dropCardIdentifierContainer}>
                                                        <Typography className={classes.identifierContainer}>
                                                            <Link href="#" onClick={() => component.openForm(order, status)} className={classes.minCardDarkText}>
                                                                {"#" + nullController(order, "identifier")}
                                                            </Link>
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.divider}/>
                                            <div className={classes.dropCardBody}>
                                                {
                                                    order.items.map((product, key) => {
                                                        return (
                                                            <div className={classes.item} key={key}>
                                                                <div className={classes.quantityView}>
                                                                    <Typography className={classes.descriptionGrayText}>
                                                                        {nullController(product, "quantity") + "x"}
                                                                    </Typography>
                                                                </div>
                                                                <div className={classes.productView}>
                                                                    <div>
                                                                        <Typography className={classes.descriptionGrayText}>
                                                                            {nullController(product, "name")}
                                                                        </Typography>
                                                                        <div style={{flexDirection: "column"}}>
                                                                            <div className={classes.columnContainer}>
                                                                                <Typography className={classes.modifierSmallGrayText}>
                                                                                    {nullController(product, "modifiers")}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className={classes.divider}/>
                                            <div className={classes.dropCardFooter}>
                                                <div className={nullController(order, "note") === ''
                                                    ? classes.dropCardTimeLarge : classes.dropCardTime}>
                                                    <div className={classes.containerRight}>
                                                        <TimeIcon className={classes.orderTypeIcon}/>
                                                        <Typography className={classes.minCardLightText}>
                                                            {getOrderEta(STATUS_PROGRESS, order.etaDate) !== null ? getOrderEta(STATUS_PROGRESS, order.etaDate) : ""}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                {nullController(order, "note") === ''
                                                    ? null : (
                                                        <div className={classes.dropCardNote}>
                                                            <div className={classes.containerNoteRight}>
                                                                <NoteIcon className={classes.orderTypeIcon}/>
                                                                <Typography className={classes.minPaymentLightText + " " + classes.blueColor}>
                                                                    {t('NOTE_TEXT')}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className={nullController(order, "note") === ''
                                                    ? classes.dropCardDeliveryTpLarge : classes.dropCardDeliveryType}>
                                                    {
                                                        order.deliveryTypeId === TAKE_AWAY
                                                            ? <TakeAwayIcon className={classes.orderDineInIcon}/>
                                                            : <div>
                                                                {
                                                                    order.deliveryTypeId === DINE_IN
                                                                        ? <div style={{display: "flex", flexDirection: "column"}}>
                                                                            <DineInIcon className={classes.orderDineInIcon}/>
                                                                            {
                                                                                order.locationIsVeryFar
                                                                                    ? <div style={{backgroundColor: "transparent", width: "19px", height: "19px", position: "absolute", marginTop: "5.9px", marginLeft: "13px"}}>
                                                                                        <WarningIcon className={classes.warningSmallIcon}/>
                                                                                    </div> : null
                                                                            }
                                                                        </div>
                                                                        : <div>
                                                                            {
                                                                                order.deliveryTypeId === DELIVERY
                                                                                    ? <DeliveryIcon className={classes.orderDineInIcon}/> : null
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                <div className={nullController(order, "note") === ''
                                                    ? classes.dropCardTotalLarge : classes.dropCardTotal}>
                                                    <Typography className={classes.minCardDarkText}>
                                                        {order.currencySymbol + ((parseFloat(order.total) + parseFloat(order.deliveryFee)).toFixed(2))}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data && prevProps.isDisabled === nextProps.isDisabled;
}

DroppableOrderList.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    title: PropTypes.string.isRequired,
    dropId: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool,
};

export default React.memo(withStyles(tableViewStyle)(withTranslation()((DroppableOrderList), propsAreEqual)));