/*
 * Ginih Team
 * Created by Marlon On February 17, 2020
 */

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import { IMG_PRODUCT_CARD } from "../../../variables/resourcesConstants";
import {
    getData,
    isSuccessfulRequest,
    nullController,
    removeData,
} from "../../../utils/helpersFunctions";
import productDetails from "../../../models/ProductModel";
import { getProductsByCategory } from "../../../services/productService";
import { validateSession } from "../../../services/api";
import { statusController } from "../../../services/statusService";
import {
    BASE_URL_CORE,
    PRODUCT_CATEGORY,
} from "../../../variables/apiConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import { NOTIFICATION_DURATION } from "../../../variables/coreConstants";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import ProductForm from "../../Products/components/ProductForm";
import { formTitles } from "../../../utils/statusHelpers";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CategoryProductDetails from "./CategoryProductDetails";
import ProductCategoryAssignmentForm from "./ProductCategoryAssignmentForm";
import { withTranslation } from "react-i18next";


class QuickActionsCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category:  getData("category"),
            productData: [],
            openProduct: false,
            openAssignProduct: false,
            actionType: 0,
            isLoading: false,
            isQuickStart: false,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.handleCloseForm = this.handleCloseForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.handleCloseForm("openProduct", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    openProductForm = () => {
        productDetails.cleanProductDetails();
        this.setState({ openProduct: true });
    };
    closeProductForm = () => {
        this.setState({ openProduct: false });
    };
    openAssignProductForm = () => {
        this.setState({ openAssignProduct: true });
    };
    closeAssignProductForm = () => {
        this.setState({ openAssignProduct: false });
    };
    handleCloseForm(state, value, type) {
        this.setState({ [state]: value });
        this.setState({ openAssignProduct: false });
        if (type) this.request();
    }

    openForm = (action, formData) => {
        productDetails.id = formData.id;

        if (action === 1 || action === 0) {
            this.setState({ openProduct: true });
            this.setState({ actionType: action });
        } else {
            let category = getData("category");
            let dataParameters = {
                categoryId: category.id,
                productId: formData.id,
            };

            statusController(
                action,
                BASE_URL_CORE,
                PRODUCT_CATEGORY,
                dataParameters,
                this,
                false
            )
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationErrorIcon,
                            null
                        );
                    }
                })
                .catch((error) => {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );

                    if (error.response)
                        validateSession(error.response, this.props);
                });
        }
    };

    goToCategories = () => {
        this.props.history.push("categories");
    };

    request() {
        this.setState({ isLoading: true });

        let category = getData("category");
        getProductsByCategory(category.id)
            .then((response) => {
                this.setState({
                    productData: response.data,
                    isLoading: false,
                });

                if (response.data.length === 0)
                    this.setState({ isQuickStart: true });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                this.showNotification(
                    error.response.data.message,
                    "danger",
                    NotificationFailedIcon,
                    null
                );

                if (error.response)
                    validateSession(
                        error.response.data.message,
                        this.props.history
                    );
            });
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, productData } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.typo} style={{ marginBottom: "20px"}}>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.category, "name")}
                            </h3>
                            <Muted>{t("PRODUCT_DETAILS_DESCRIPTION")}</Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                {t("EDIT_TEXT")}
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToCategories}
                            >
                                {t("GO_TO_CATEGORIES_TEXT")}
                            </CustomButton>
                        </div>
                    </GridItem>
                    {this.state.isQuickStart ? (
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.typo}>
                                <h4> {t("QUICK_START")} </h4>
                            </div>
                        </GridItem>
                    ) : null}
                </GridContainer>
                {this.state.isQuickStart ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card chart>
                                <div className={classes.center}>
                                    <img
                                        src={IMG_PRODUCT_CARD}
                                        width={"175px"}
                                        height={"175px"}
                                        alt={"Product"}
                                    />
                                </div>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        {t("PRODUCT_TEXT")}
                                    </h4>
                                    <p className={classes.cardCategory}>
                                        {t("PRODUCT_CARD_DESCRIPTION")}
                                    </p>
                                </CardBody>
                                <CardFooter chart>
                                    <Button
                                        onClick={this.openProductForm}
                                        size="small"
                                        color="primary"
                                    >
                                        {t("BTN_ADD_PRODUCT")}
                                    </Button>
                                    <Button
                                        onClick={this.openAssignProductForm}
                                        size="small"
                                        color="primary"
                                    >
                                        {t("BTN_ASSIGN_PRODUCT")}
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <CategoryProductDetails
                        component={this}
                        data={productData}
                    />
                )}
                <Dialog
                    maxWidth={"xs"}
                    fullWidth={true}
                    aria-labelledby="form-assign-dialog-title"
                    open={this.state.openAssignProduct}
                    onClose={this.closeAssignProductForm}
                >
                    <DialogTitle id="form-assign-dialog-title">
                        {t("BTN_ASSIGN_PRODUCT")}
                    </DialogTitle>
                    <DialogContent>
                        <ProductCategoryAssignmentForm component={this} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    aria-labelledby="form-product-dialog-title"
                    open={this.state.openProduct}
                    onClose={this.closeProductForm}
                >
                    <DialogTitle id="form-product-dialog-title">
                        {t(formTitles[actionType])} {t("PRODUCT_TEXT")}
                    </DialogTitle>
                    <DialogContent>
                        <ProductForm
                            component={this}
                            closeForm={this.handleCloseForm}
                            action={actionType}
                        />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsCategory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsCategory))
);
