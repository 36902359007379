import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/core/icons icons
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import ProductIcon from "@material-ui/icons/ShoppingCart";
import DescriptionIcon from "@material-ui/icons/ShortText";
import CodeIcon from "@material-ui/icons/SortByAlpha";
import AgeIcon from "@material-ui/icons/Filter9Plus";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../../components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import {
    disableComponent,
    enableComponent, getCompanyCurrency, getData, getValueFromCurrencyMask,
    isSuccessfulCreate, isSuccessfulRequest,
    selectValidation, setCurrencyValue, validateNewValue
} from "../../../utils/helpersFunctions";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import {getCategoriesByCompany, getCategoriesByProduct} from "../../../services/categoryService";
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {
    BASE64_HEADER,
    DATA_TYPE_SUPPORTED,
    NOTIFICATION_DURATION
} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import ModifierForm from "./ModifierForm";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {addProduct, editProduct, getProductType} from "../../../services/productService";
import productDetails from "../../../models/ProductModel";
import modifierDetails from "../../../models/ModifierModel";
import CreatableSelect from "../../../components/CustomSelect/CustomCreatableSelect";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import ProductImageEditor from "./ProductImageEditor";
import {
    getModifierGroupInformation,
    getModifierProductAssignments,
    getModifiersGroupsByCompany
} from "../../../services/modifierGroupService";
import Muted from "../../../components/Typography/Muted";
import CurrencyInput from "../../../components/CustomInput/CurrencyInput";


const animatedComponents = makeAnimated();



class ProductForm extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            openProduct: false,
            openModifier: false,
            openImage: false,
            loadingCategory: true,
            loadingModifier: true,
            modifier: '',
            productName: productDetails.name,
            externalId: productDetails.externalId,
            productCode: productDetails.code,
            standardPriceMask: setCurrencyValue(productDetails.standardPrice),
            standardPrice: productDetails.standardPrice,
            description: productDetails.description,
            minimumAge: productDetails.minimumAge,
            photo: productDetails.photo,
            categoryId: [],
            taxMask: setCurrencyValue(productDetails.tax),
            tax: productDetails.tax,
            typeOptions: [],
            productType: productDetails.type,
            categoriesOptions: [],
            modifiersOptions: [],
            modifiers: productDetails.modifiers,
            oldModifiers: [],
            oldCategories: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
            disabledCategory: false,
            crop: {
                unit: 'px',
                width: 400,
                height: 400,
                aspect: 4 / 3,
            },
        };
        this.saveProduct = this.saveProduct.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
    }


    componentDidMount() {
        let category = getData("category");
        if (category) {
            this.setState({
                categoryId: [{ value: category.id, label: category.name}],
            });
            disableComponent(this, "disabledCategory");
        }
        this.request();
    }


    cleanData() {
        this.setState({
            productName: '',
            description: '',
            productCode: '',
            standardPriceMask: setCurrencyValue(0.00),
            standardPrice: 0,
            minimumAge: 0,
            taxMask: setCurrencyValue(0.00),
            tax: 0,
            zoneDescription: '',
            categoryId: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openProduct", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleSelectImage = () => {
        this.setState({
            crop: {
                unit: 'px',
                width: 400,
                height: 400,
                aspect: 1,
            },
        }, () => {
            this.handleImageForm();
        });

    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };

    changeTypeSelect = productType => {
        this.setState({ productType });
    };

    changeCategorySelect = categoryId => {
        const { t } = this.props;

        if (categoryId !== null) {
            if (categoryId.length <= 1 ) {
                this.setState({ categoryId });

            } else {
                this.showNotification(t('CATEGORY_SELECT_FORBIDDEN_MSG'), "danger",
                    NotificationErrorIcon, null);
            }

        } else {
            this.setState({ categoryId });
        }
    };
    changeModifierSelect = modifier => {
        // this.setState({ modifier });
        this.addModifierGroup(modifier);
    };


    handleCreateModifier = (modifier) => {
        this.openModifierForm();
        modifierDetails.name = modifier;
    };


    // Method that request selects data
    request() {
        let categoryValues;
        let modifierValues;

        if (this.props.action) {
            getModifierProductAssignments()
                .then((res) => {
                    modifierValues = res.data.unassignedModifierGroups
                        .map( item => { return { value: item.id , label: item.name } });

                    let assigned = res.data.assignedModifierGroups
                        .map( item => { return {
                            id: item.id,
                            modifierGroup: { id: item.modifierGroupId.id, name: item.modifierGroupId.name }
                        }});

                    let oldData = res.data.assignedModifierGroups
                        .map( item => { return {
                            assignId: item.id,
                            entityId: item.modifierGroupId.id
                        }});

                    productDetails.modifiers = assigned;
                    this.setState({
                        modifiersOptions: modifierValues,
                        modifiers: assigned,
                        oldModifiers: oldData,
                        loadingModifier: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingModifier: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });


            getCategoriesByProduct()
                .then((res) => {
                    categoryValues = res.data.unassigned
                        .map( item => { return { value: item.id , label: item.name } });

                    let assigned = res.data.assigned
                        .map( item => { return {
                            value: item.entity.id,
                            label: item.entity.name
                        }});

                    let oldData = res.data.assigned
                        .map( item => { return {
                            assignId: item.assignId,
                            entityId: item.entity.id
                        }});


                    this.setState({
                        categoriesOptions: categoryValues,
                        categoryId: assigned,
                        oldCategories: oldData,
                        loadingCategory: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingCategory: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });

        } else {
            getCategoriesByCompany()
                .then((res) => {
                    categoryValues = res.data.map( item => { return { value: item.id , label: item.name } });

                    this.setState({
                        categoriesOptions: categoryValues,
                        loadingCategory: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingCategory: false} );
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);

                });

            getModifiersGroupsByCompany()
                .then((res) => {
                    modifierValues = res.data.map( item => { return { value: item.id , label: item.name } });

                    this.setState({
                        modifiersOptions: modifierValues,
                        loadingModifier: false
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loadingModifier: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });
        }

        getProductType()
            .then((res) => {
                let values = res.data.map( item => { return { value: item.id , label: item.name } });

                this.setState({
                    typeOptions: values,
                    productType: this.props.action ? productDetails.type : values[0]
                });
            })
            .catch((err) => {
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }


    openModifierForm = () => {
        modifierDetails.cleanModifier();

        this.setState({
            openModifier: true,
            actionType: 0
        });
    };

    closeModifierForm = () => {
        this.setState({ openModifier: false });
    };
    modifierForm = (value) => {
        this.setState({ openModifier: value });
    };

    handleImageForm = () => {
        this.setState({ openImage: true });
    };
    handleCloseImageForm = () => {
        this.setState({ openImage: false });
    };

    handleDeleteImage = () => {
        this.setState({
            photo: null
        });
    };

    deleteModifierGroup = (id) => {
        let index = productDetails.modifiers.findIndex(val => val.modifierGroup.id === id);
        productDetails.modifiers.splice(index, 1);
        this.setState({ modifiers: productDetails.modifiers });
    };

    addModifierGroup = (modifier) => {
        const { t } = this.props;

        let array = {
            id: 0,
            modifierGroup: {
                id: 0,
                name: ""
            }
        };

        if (selectValidation(modifier)) {
            this.showNotification(t('SELECT_MODIFIER_GROUP_MSG'), "danger", NotificationErrorIcon);

        } else {

            let exist = false;
            if (productDetails.modifiers.length > 0) {
                let result = productDetails.modifiers.find(({ modifierGroup }) => modifierGroup ?
                    modifierGroup.id === modifier.value : console.log("Nothing"));

                if (result)
                    exist = true;
            }

            if (!exist) {
                array.id = null;
                array.modifierGroup.id = modifier.value;
                array.modifierGroup.name = modifier.label;
                productDetails.modifiers.push(array);

                this.setState({
                    modifiers: productDetails.modifiers,
                    modifier: ''
                });

            } else {
                this.showNotification(t('MODIFIER_ALREADY_ADDED_MSG'), "danger", NotificationErrorIcon);
            }
        }
    };


    editModifierGroup(values) {
        modifierDetails.cleanModifier();
        modifierDetails.id = values.id;

        getModifierGroupInformation()
            .then((response) => {
                let values = response.data;
                modifierDetails.name = values.name;
                modifierDetails.externalId = values.externalId;
                modifierDetails.maxItems = values.maxItems;
                modifierDetails.required = values.isRequired;

                this.setState({
                    openModifier: true,
                    actionType: 1
                });

            })
            .catch((e) => {
                this.showNotification(e.response.data.message, "danger", NotificationErrorIcon, null);

                if (e.response)
                    validateSession(e.response, this.props.history);

            });
    };


    // Validate field required and save new product
    saveProduct() {
        const { t } = this.props;

        if (this.state.productName === "" || this.state.standardPrice <= 0 || this.state.tax < 0 || this.state.minimumAge < 0 || selectValidation(this.state.productType)) {
            this.showNotification(t('PRODUCT_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (this.state.description.length <= 255) {
                if (this.state.tax > 100) {
                    this.showNotification(t('INVALID_PERCENTAGE_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    let imageBase64Data = null;
                    if (this.state.photo !== null)
                        imageBase64Data = this.state.photo.slice(BASE64_HEADER.length);


                    let categories;
                    if (selectValidation(this.state.categoryId)) {
                        categories = [];

                    } else {
                        categories = [{id: this.state.categoryId[0].value}];
                    }


                    let modifiers;
                    if (selectValidation(this.state.modifiers)) {
                        modifiers = [];
                    } else {
                        modifiers = this.state.modifiers.map( item => { return {
                            id: item.modifierGroup.id
                        }});
                    }


                    let dataParameters = {
                        "name": this.state.productName,
                        "description": this.state.description,
                        "code": this.state.productCode,
                        "externalId": this.state.externalId,
                        "productImage": imageBase64Data,
                        "price": this.state.standardPrice,
                        "currency": null,
                        "isModifierItem": false,
                        "productType": this.state.productType.value,
                        "tax": this.state.tax,
                        "consumerMinimumLegalAge": parseInt(this.state.minimumAge),
                        "modifiersGroups": JSON.stringify(modifiers),
                        "categories": JSON.stringify(categories),
                    };


                    disableComponent(this, "disableField");
                    addProduct(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch((e) => {
                            enableComponent(this, "disableField");
                            this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);

                            if (e.response)
                                validateSession(e.response, this.props.history);
                        });
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'), "danger", NotificationErrorIcon, null);
            }
        }
    }


    // Validate field required and edit a product
    updateProduct() {
        const { t } = this.props;

        if (this.state.productName === "" || this.state.minimumAge < 0  || selectValidation(this.state.productType)) {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (this.state.description.length <= 255) {
                if (this.state.tax > 100) {
                    this.showNotification(t('INVALID_PERCENTAGE_MSG'), "danger", NotificationErrorIcon, null);
                } else {
                    let imageBase64Data = "";
                    if (this.state.photo !== null && this.state.photo !== productDetails.photo) {
                        if (this.state.photo.indexOf(DATA_TYPE_SUPPORTED) !== -1)
                            imageBase64Data = this.state.photo.slice(BASE64_HEADER.length);

                    } else if (this.state.photo === productDetails.photo) {
                        imageBase64Data = productDetails.photo;
                    }


                    let modifiers;
                    if (selectValidation(this.state.modifiers)) {
                        modifiers = [];
                    } else {
                        modifiers = this.state.modifiers.map( item => {
                            if (validateNewValue(this.state.oldModifiers, "entityId", item.modifierGroup.id)) {
                                let index = this.state.oldModifiers.findIndex(val => val.entityId === item.modifierGroup.id);
                                return {
                                    assignId: this.state.oldModifiers[index].assignId,
                                    entityId: this.state.oldModifiers[index].entityId
                                };

                            } else {
                                return { assignId: null, entityId: item.modifierGroup.id };
                            }
                        });
                    }


                    let categoriesId;
                    if (selectValidation(this.state.categoryId)) {
                        categoriesId = [];
                    } else {
                        categoriesId = this.state.categoryId.map( item => {
                            if (validateNewValue(this.state.oldCategories, "entityId", item.value)) {
                                let index = this.state.oldCategories.findIndex(val => val.entityId === item.value);
                                return {
                                    assignId: this.state.oldCategories[index].assignId,
                                    entityId: this.state.oldCategories[index].entityId
                                };

                            } else {
                                return { assignId: null, entityId: item.value };
                            }
                        });
                    }



                    let dataParameters = {
                        "name": this.state.productName,
                        "description": this.state.description,
                        "externalId": this.state.externalId,
                        "code": this.state.productCode,
                        "productImage": imageBase64Data,
                        "price": this.state.standardPrice,
                        "priceId": productDetails.priceId,
                        "tax": this.state.tax,
                        "isModifierItem": false,
                        "productType": this.state.productType.value,
                        "consumerMinimumLegalAge": parseInt(this.state.minimumAge),
                        "modifiersGroups": JSON.stringify(modifiers),
                        "oldModifiersGroups": JSON.stringify(this.state.oldModifiers),
                        "categories": JSON.stringify(categoriesId),
                        "oldCategories": JSON.stringify(this.state.oldCategories)
                    };

                    disableComponent(this, "disableField");
                    editProduct(dataParameters)
                        .then((response) => {
                            if (isSuccessfulRequest(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch((e) => {
                            enableComponent(this, "disableField");
                            this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);

                            if (e.response)
                                validateSession(e.response, this.props.history);
                        });
                }
            } else {
                this.showNotification(t('DESCRIPTION_CHARACTERS_EXCEEDS_MSG'), "danger", NotificationErrorIcon, null);
            }
        }
    }


    render() {
        const { classes, t } = this.props;
        const { photo } = this.state;

        const SaveProductButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveProduct()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_PRODUCT')}
            </Button>
        );

        const UpdateProductButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateProduct()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );


        const Modifiers = ({modifiers}) => (
            modifiers.map(modifier => (
                <div key={modifier.modifierGroup.id} className={classes.scheduleContainer}>
                    <GridContainer>
                        <GridItem xs={12} sm={9} md={9}>
                            <Chip label={modifier.modifierGroup.name}/>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                            <Tooltip id="tooltip-edit" title={t('EDIT_MODIFIER_TEXT')} placement="top-start">
                                <IconButton size={"small"} onClick={() => this.editModifierGroup(modifier.modifierGroup)}>
                                    <EditIcon className={classes.iconButtonBlue}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip id="tooltip-delete" title={t('DELETE_MODIFIER_TEXT')} placement="top-start">
                                <IconButton size={"small"} onClick={() => this.deleteModifierGroup(modifier.modifierGroup.id)}>
                                    <ClearIcon className={classes.iconButtonRed}/>
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                    </GridContainer>
                </div>
            ))
        );


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.productName}
                            onChange={this.handleChangeValues}
                            labelText={t('PRODUCT_NAME_TEXT')}
                            id="productName"
                            name="productName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ProductIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <CurrencyInput
                            value={this.state.standardPriceMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('STANDARD_PRICE_TEXT')}
                            id="standardPriceMask"
                            name="standardPrice"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <CurrencyInput
                            value={this.state.taxMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('TAX_TEXT')}
                            id="taxMask"
                            name="tax"
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={"%"}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.productCode}
                            onChange={this.handleChangeValues}
                            labelText={t('PRODUCT_CODE_TEXT')}
                            id="productCode"
                            name="productCode"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText={t('EXTERNAL_ID_TEXT')}
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CodeIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="categoryProduct" className={classes.selectTitle}>
                                {t('CATEGORY_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.categoryId}
                                onChange={this.changeCategorySelect}
                                components={animatedComponents}
                                isMulti={true}
                                isLoading={this.state.loadingCategory}
                                options={this.state.categoriesOptions}
                                isDisabled={this.state.disabledCategory}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={130}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="type" className={classes.selectTitle}>
                                {t('TYPE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.productType}
                                onChange={this.changeTypeSelect}
                                options={this.state.typeOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={130}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <div style={{marginTop: 30}}>
                            <CustomInput
                                value={this.state.minimumAge}
                                onChange={this.handleChangeValues}
                                labelText={t('MINIMUM_AGE_TEXT')}
                                id="minimumAge"
                                name="minimumAge"
                                type="number"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <AgeIcon className={classes.colorIcon}/>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={false} sm={6} md={9}/>
                    <GridItem xs={12} sm={12} md={6}>
                        <div style={{paddingTop: 30,  paddingBottom: 20}}>
                            <h5 className={classes.title2}>{t('MODIFIER_OPTIONS_TEXT')}</h5>
                            <GridContainer>
                                <GridItem xs={12} sm={10} md={10}>
                                    <div className={classes.itemSelect}>
                                        <CreatableSelect
                                            isClearable
                                            isDisabled={this.state.loadingModifier}
                                            isLoading={this.state.loadingModifier}
                                            placeholder={t('SELECT_TEXT')}
                                            onChange={this.changeModifierSelect}
                                            onCreateOption={this.handleCreateModifier}
                                            closeMenuOnSelect={true}
                                            maxMenuHeight={130}
                                            options={this.state.modifiersOptions}
                                            value={this.state.modifier}
                                            theme={theme => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: primaryColor[0],
                                                },
                                            })}
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <Modifiers modifiers={this.state.modifiers}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <h4 className={classes.descriptionTitle}>
                            {t('PRODUCT_IMAGE_TITLE')}
                            <Muted>
                                {t('PRODUCT_IMAGE_REQUIREMENTS')}
                            </Muted>
                        </h4>
                        <Tooltip id="tooltip-edit" title={photo === null ? t('SELECT_IMAGE_TEXT') : t('CHANGE_IMAGE_TEXT')} placement="bottom-start">
                            <ButtonBase
                                focusRipple
                                onClick={this.handleSelectImage}
                                key={1}
                                className={classes.btnImage}
                                style={{height: 120, width: 120,}}
                                focusVisibleClassName={classes.focusVisible}
                            >
                            <span
                                className={classes.imageSrcContainer}
                                style={{backgroundImage: `url(${this.state.photo})`}} />
                                <span className={classes.imageBackdrop} />
                                <span className={classes.imageButton}>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    color="inherit"
                                    className={classes.imageTitle}>
                                </Typography>
                            </span>
                            </ButtonBase>
                        </Tooltip>
                        {
                            photo !== null ?
                                <Button
                                    color={"danger"}
                                    className={classes.buttonFit}
                                    onClick={this.handleDeleteImage}>
                                    <ClearIcon />
                                    {t('BTN_REMOVE')}
                                </Button>
                            : null
                        }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Dialog
                            maxWidth={"sm"}
                            fullWidth={true}
                            open={this.state.openModifier}
                            onClose={this.closeModifierForm}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                {t('MODIFIER_GROUP_TEXT')}
                            </DialogTitle>
                            <DialogContent>
                                <ModifierForm modifierForm={this.modifierForm} action={this.state.actionType} component={this}/>
                            </DialogContent>
                        </Dialog>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openProduct", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateProductButton/> : <SaveProductButton />}
                        </div>
                    </GridItem>
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={false}
                        open={this.state.openImage}
                        onClose={this.handleCloseImageForm}
                        aria-labelledby="form-image-title"
                    >
                        <DialogTitle id="form-image-title">
                            {t('FORM_IMAGE_EDITOR_TITLE')}
                        </DialogTitle>
                        <DialogContent>
                            <ProductImageEditor
                                minWidth={300}
                                minHeight={300}
                                crop={this.state.crop} component={this} />
                        </DialogContent>
                    </Dialog>
                </GridContainer>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

ProductForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ProductForm)));
