import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {withTranslation} from "react-i18next";
import {objectSizeValidator} from "../../../utils/helpersFunctions";
import {CSVLink} from "react-csv";
import SimpleButton from "@material-ui/core/Button";
import DownLoadIcon from "@material-ui/icons/CloudDownload";


class UploadMemberCardsSummary extends React.Component {
    csvLink = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            csvData: [],
        };
    }

    handleDownloadSummary = () => {
        const { summary } = this.props;

        try {
            let dataCsv = [];

            summary.data.failed.forEach(f => dataCsv.push({
                "memberId": f.member.id,
                "memberExternalId": f.member.externalId,
                "error": f.error.name
            }));

            if (objectSizeValidator(dataCsv, 0)) {
                this.setState({
                    csvData: dataCsv,
                }, () => {
                    window.setTimeout(
                        function() {
                            if (this.csvLink !== null && this.csvLink !== undefined) {
                                this.csvLink.link.click();
                            }
                        }.bind(this),
                        1000
                    );
                });
            }
        } catch (e) { }
    };


    render() {
        const { classes, t, summary } = this.props;

        return (
            <div className={classes.imageEditorContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={8} sm={8} md={8} lg={8}>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <p className={classes.balanceCardSubTitle}>
                                                {t("SUCCESS")}
                                            </p>
                                            <p className={classes.balanceCardSubTitle}>
                                                {t("FAILED")}
                                            </p>
                                            <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                {t("TOTAL_CREDIT_CARDS")}
                                            </p>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={4} lg={4}>
                                        <div style={{display: "flex", flexDirection: "column", float: "right", textAlign: "right"}}>
                                            <p className={classes.balanceCardSubTitle}>
                                                {summary.data.success.length}
                                            </p>
                                            <p className={classes.balanceCardSubTitle}>
                                                {summary.data.failed.length}
                                            </p>
                                            <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                {summary.data.batchSize}
                                            </p>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    {
                        summary.data.failed.length > 0 ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <div style={{ marginTop: 20 }}>
                                    <SimpleButton
                                        className={classes.btnCardRowTxtBlue}
                                        size="small"
                                        endIcon={<DownLoadIcon className={classes.iconSimpleBtn} />}
                                        color="primary"
                                        onClick={() => this.handleDownloadSummary()}>
                                        {t('FAILED_MEMBER_CARDS_SUMMARY')}
                                    </SimpleButton>
                                    <div className={classes.hidden}>
                                        <CSVLink
                                            data={this.state.csvData}
                                            headers={[
                                                { label: t('TH_MEMBER_ID'), key: "memberId" },
                                                { label: t('TH_MEMBER_EXTERNAL_ID'), key: "memberExternalId" },
                                                { label: t('ERROR'), key: "error" }
                                            ]}
                                            separator={","}
                                            className="hidden"
                                            ref={(r) => this.csvLink = r}
                                            filename={t('FAILED_MEMBER_CARDS_SUMMARY')}>
                                            {t('BTN_DOWNLOAD')}
                                        </CSVLink>
                                    </div>
                                </div>
                            </GridItem>
                        ) : null
                    }
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.onGoBack()}
                            >
                                {t('BTN_TRY_AGAIN')}
                            </Button>
                            <Button
                                color="primary"
                                className={classes.buttonSpacing}
                                onClick={() => this.props.closeForm(true, null)}
                            >
                                {t('BTN_FINISH')}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

UploadMemberCardsSummary.propTypes = {
    classes: PropTypes.object,
    closeForm: PropTypes.func,
    onGoBack: PropTypes.func,
    summary: PropTypes.string.isRequired,
};

export default withRouter(withStyles(formStyle)(withTranslation()(UploadMemberCardsSummary)));