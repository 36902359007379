import React from "react";
import Select from "react-select";
import {getNonceProvider} from "../../utils/helpersFunctions";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";

const cache = createCache({
    key: 'react-select',
    nonce: getNonceProvider()
});

// Only rerender component if value or label change
function propsAreEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value && prevProps.isDisabled === nextProps.isDisabled
        && prevProps.isLoading === nextProps.isLoading && prevProps.defaultValue === nextProps.defaultValue
        && prevProps.options === nextProps.options;
}


function CustomSelect({ ...props }) {
    const { ...rest} = props;
    return (
        <CacheProvider value={cache} >
            {/*<NonceProvider nonce={getNonceProvider()} cacheKey={props.key} key={props.key}>*/}
                <Select{...rest}/>
            {/*</NonceProvider>*/}
        </CacheProvider>

    );
}

export default React.memo(CustomSelect, propsAreEqual);
