import {deleteService, postService, putService, validateSession} from "./api";
import {
    ACTIVATE,
    ACTIVATE_STATUS,
    DEACTIVATE,
    DEACTIVATE_STATUS, DEALLOCATE_STATUS,
    DELETE,
    DELETE_STATUS,
    UNASSIGN
} from "../variables/coreConstants";
import {isSuccessfulRequest} from "../utils/helpersFunctions";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {BASE_URL_CORE, STATUS} from "../variables/apiConstants";

export function assign(baseUrl, endpoint, parameters) {
    let url = baseUrl + endpoint;
    return postService(url, parameters);
}

export function deallocate(baseUrl, endpoint, parameters, isNoDelete, component) {
    if (isNoDelete) {
        return updateStatus(parameters, component);
    } else {
        let DEALLOCATE = "/deallocate";
        let url = baseUrl + DEALLOCATE + endpoint;

        return deleteService(url, parameters);
    }
}

export function deleteStatus(baseUrl, endpoint, parameters, component) {
    let DELETE = "/delete";
    let url = baseUrl + DELETE + endpoint;

    return putService(url, parameters).then((response) => {
            component.showNotification(response.data.message, isSuccessfulRequest(response) ? "primary" : "danger",
                NotificationSuccessIcon, isSuccessfulRequest(response) ? true : null);

    }).catch((error) => {
        if (error.response) {
            component.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
            validateSession(error.response, component.props.history)
        } else {
            component.showNotification(component.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }
    });
}

export function updateStatus(parameters, component) {
    let url = BASE_URL_CORE + STATUS;

    return putService(url, parameters).then((response) => {
        component.setState({ isRequesting: false });
        if (isSuccessfulRequest(response)) {
            component.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
        } else {
            component.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
        }
    }).catch((error) => {
        component.setState({ isLoading: false, isRequesting: false });
        if (error.response) {
            component.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
            validateSession(error.response, component.props.history)
        } else {
            component.showNotification(component.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }
    });
}
export function customUpdateStatus(url, parameters, component) {

    return putService(url, parameters).then((response) => {
        component.setState({ isRequesting: false });
        if (isSuccessfulRequest(response)) {
            component.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
        } else {
            component.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
        }
    }).catch((error) => {
        component.setState({ isLoading: false, isRequesting: false });
        if (error.response) {
            component.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
            validateSession(error.response, component.props.history)
        } else {
            component.showNotification(component.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
        }
    });
}

export function statusController(statusAction, url, endpoint, parameters, component, isNoDelete) {
    switch (statusAction) {
        case DELETE:
            return deleteStatus(url, endpoint, parameters, component);

        case DEACTIVATE:
            return url !== null && endpoint !== null ? customUpdateStatus(url + endpoint, parameters, component) : updateStatus(parameters, component);

        case ACTIVATE:
            return url !== null && endpoint !== null ? customUpdateStatus(url + endpoint, parameters, component) : updateStatus(parameters, component);

        case UNASSIGN:
            return deallocate(url, endpoint, parameters, isNoDelete, component);

        default:
            break;
    }

}

export function getStatus(action) {
    switch (action) {
        case ACTIVATE:
            return ACTIVATE_STATUS;

        case DEACTIVATE:
            return DEACTIVATE_STATUS;

        case DELETE:
            return DELETE_STATUS;

        case UNASSIGN:
            return DEALLOCATE_STATUS;

        default:
            return null;
    }
}