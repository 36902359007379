import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MenuForm from "./MenuForm";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Status from "../../../components/Status/Status";
import TableActions from "../../../components/TableActions/TableActions";
import {statusColors, statusText, formTitles} from "../../../utils/statusHelpers";
import menuDetails from "../../../models/MenuModel";
import {getMenusByStore} from "../../../services/menuService";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    MENU_DETAILS_TABLE_ACTIONS,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/coreConstants";
import TableBody from "@material-ui/core/TableBody";
import {getData} from "../../../utils/helpersFunctions";
import Muted from "../../../components/Typography/Muted";
import {validateSession} from "../../../services/api";


class MenusDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openMenu: false,
            isLoading: true,
            actionType: 0,
            menusData: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    handleOpenForm = () => {
        menuDetails.cleanMenuDetails();
        this.setState({openMenu: true});
        this.setState({actionType: 0});
    };
    handleCloseForm = () => {
        this.setState({openMenu: false});
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type)
            this.request();
    }
    openForm = (action, formData) =>{
        menuDetails.id = formData.id;
        menuDetails.description = formData.description;
        menuDetails.name = formData.name;
        this.setState({openMenu: true});
        this.setState({actionType: action});
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };



    request(){
        this.setState({ isLoading: true} );
        let store = getData("store");

        getMenusByStore(store.id)
            .then((res) => {
                this.setState({menusData: res.data});
                this.setState({ isLoading: false} );
                if (res.data.length === 0)
                {
                    this.props.quickAction("isQuickStartM", true);

                } else {
                    this.props.quickAction("isQuickStartM", false);
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false} );
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }

    goToActions = (val) =>{
        menuDetails.name = val.name;
        this.props.history.push("quick-actions-menu");
    };


    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={6} sm={6} md={6} >
                    <div className={classes.typo}>
                        <h4> Menus </h4>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.rightPaper}>
                        <Button color="primary" onClick={this.handleOpenForm}>Add New Menu</Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card className={classes.card}>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Description
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Owner
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.menusData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true} className={classes.tableRow}>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}
                                                            onClick={() => this.goToActions(val)}>
                                                            {val.catalogId.name}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>{val.catalogId.description}</TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>{val.catalogId.createdBy}</TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.catalogId.status]}
                                                                size="lg"
                                                                label={t(statusText[val.catalogId.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val.catalogId}
                                                                    openForm={this.openForm}
                                                                    options={MENU_DETAILS_TABLE_ACTIONS}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.menusData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openMenu}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} Menu
                            <Muted>
                                List of products your customer can view, select, order and pay.
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <MenuForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                </GridItem>
            </GridContainer>
        );
    }
}

MenusDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(MenusDetails)));
