import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import ActivityIcon from "@material-ui/icons/Class";
import DescriptionIcon from "@material-ui/icons/Notes";
import DurationIcon from "@material-ui/icons/Timer";
import ExternalIcon from "@material-ui/icons/LocalOffer";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Select from "../../../components/CustomSelect/CustomSelect";
import {
    disableComponent,
    enableComponent, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation,
    validateFields
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import activityDetails from "../../../models/ActivityModel";
import {addActivity, editActivity} from "../../../services/activityService";
import {getMembersOwnersByCompany} from "../../../services/memberService";
import makeAnimated from 'react-select/animated';
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";


const animatedComponents = makeAnimated();


class ActivityForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activityName: activityDetails.name,
            description: activityDetails.description,
            externalId: activityDetails.externalId,
            duration: activityDetails.duration,
            ownersOptions: [],
            owner: activityDetails.owner,
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.saveActivity = this.saveActivity.bind(this);
        this.updateActivity = this.updateActivity.bind(this);
        this.request = this.request.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    componentDidMount() {
        this.request();
    }


    changeOwnerSelect = owner => {
        this.setState({ owner });
    };

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    cleanData() {
        this.setState({
            activityName: '',
            description: '',
            externalId: '',
            duration: 0,
            owner: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        });
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openActivity", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request(){
        let ownerValues;

        getMembersOwnersByCompany()
            .then((res) => {
                ownerValues = res.data.map( item => {
                    if (item.firstname === null || item.firstname === "") {
                        return { value: item.id, label: item.email};

                    } else {
                        return { value: item.id, label: item.firstname + " " + item.lastname};
                    }
                });
                this.setState({ ownersOptions: ownerValues });
            })
            .catch((err) => {
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    saveActivity() {
        let fields = [
            this.state.activityName,
            this.state.duration,
        ];

        if (!validateFields(fields) || selectValidation(this.state.owner)) {
            this.showNotification("All fields are required", "danger", NotificationErrorIcon, null);

        } else {

            let owners;
            owners = this.state.owner.map( item => {
                return {id: item.value};
            });

            let dataParameters = {
                "name": this.state.activityName,
                "description": this.state.description,
                "external_id": this.state.externalId,
                "duration": parseFloat(this.state.duration),
                "members": JSON.stringify(owners)
            };


            disableComponent(this, "disableField");
            addActivity(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();

                        // history.push('quick-actions-store');

                        /* Temporal redirection*/
                        //history.push('activities');
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                });
        }
    }


    updateActivity() {
        let fields = [
            this.state.activityName,
            this.state.duration
        ];

        if (validateFields(fields) || selectValidation(this.state.owner)) {

            let owners;
            owners = this.state.owner.map( item => {
                return {id: item.value};
            });

            let dataParameters = {
                "name": this.state.activityName,
                "description": this.state.description,
                "external_id": this.state.externalId,
                "duration": parseFloat(this.state.duration),
                "members": JSON.stringify(owners)
            };

            disableComponent(this, "disableField");
            editActivity(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                });

        } else {
            this.showNotification("All fields are required", "danger", NotificationErrorIcon, null);
        }
    }




    render(){
        const { classes } = this.props;

        const SaveActivityButton = ()  => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveActivity()}
                disabled={this.state.disableField}
            >
                Save Activity
            </Button>
        );

        const UpdateActivityButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateActivity()}
                disabled={this.state.disableField}
            >
                Save Changes
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.activityName}
                            onChange={this.handleChangeValues}
                            labelText="Activity Name"
                            id="activityName"
                            name="activityName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ActivityIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="owner" className={classes.selectTitle}>
                                Owner
                            </InputLabel>
                            <Select
                                value={this.state.owner}
                                onChange={this.changeOwnerSelect}
                                components={animatedComponents}
                                options={this.state.ownersOptions}
                                name="owners"
                                classNamePrefix="owners"
                                placeholder={"Select..."}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                isMulti
                                maxMenuHeight={150}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText="Description"
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.externalId}
                            onChange={this.handleChangeValues}
                            labelText="External Id"
                            id="externalId"
                            name="externalId"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.duration}
                            onChange={this.handleChangeValues}
                            labelText="Duration (in minutes)"
                            id="duration"
                            type={"number"}
                            name="duration"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DurationIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openActivity", false, false)}
                            >
                                Cancel
                            </Button>
                            {this.props.action ? <UpdateActivityButton/> : <SaveActivityButton />}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ActivityForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(ActivityForm));