import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Card from "../../components/Card/Card";


function CardInvoice({ ...props }) {
    const { classes, data, showFooter, footerText } = props;

    return (
        <Card className={classes.cardInvoice}>
            {
                data.map((item, key) => {
                    return (
                        <div className={classes.invoiceRow} key={key}>
                            <p className={classes.invoiceRowTitle}>{item.label}</p>
                            <p className={classes.invoiceRowValue}>{item.value}</p>
                            {
                                item.footer !== null && item.footer !== undefined && item.footer !== "" ? (
                                    <p className={classes.invoiceRowValue}>{item.footer}</p>
                                ) : null
                            }
                        </div>
                    )
                })
            }
            {
                showFooter && footerText !== "" ? (
                    <p className={classes.invoiceFooterText}>{footerText}</p>
                ) : null
            }
        </Card>
    );
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data;
}

CardInvoice.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array,
    footerText: PropTypes.string,
    showFooter: PropTypes.bool
};

export default React.memo(withStyles(dashboardStyle)(CardInvoice), propsAreEqual);
