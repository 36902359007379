import React from "react";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";

// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";

import Button from "../../../components/CustomButtons/Button";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    MEMBER_AUTH_SOURCE_CLIENT_CODE,
    MEMBER_AUTH_SOURCE_DOMAIN,
    MEMBER_AUTH_SOURCE_INTEGRATION,
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import SimpleButton from "@material-ui/core/Button";
import {
    disableComponent, enableComponent, isSuccessfulCreate, isSuccessfulRequest, selectValidation,
} from "../../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    addMemberAuthConfiguration, editMemberAuthConfiguration,
    getMemberAuthSources,
    getMemberIntegrationsByCompany
} from "../../../services/memberAuthService";
import {validateSession} from "../../../services/api";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import memberAuthConfigDetails from "../../../models/MemberAuthConfig";
import Domains from "./Domains";
import CustomInput from "../../../components/CustomInput/CustomInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";



class MemberAuthConfigForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            memberAuthSource: memberAuthConfigDetails.source,
            memberAuthSourceOptions: [],
            integration: memberAuthConfigDetails.integration,
            domainName: '',
            domainUuid: '',
            usernameLabel: memberAuthConfigDetails.usernameLabel,
            passwordLabel: memberAuthConfigDetails.passwordLabel,
            integrationOptions: [],
            domains: memberAuthConfigDetails.domains,
            loadingSource: false,
            loadingIntegrations: false,
            currentId: 1,
            disabledItem: false,
            disableField: false,
            passwordRequired: memberAuthConfigDetails.fieldOption,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    componentDidMount() {
        this.setState({ loadingSource: true });
        this.request();
    }



    request() {
        getMemberAuthSources()
            .then((res) => {
                let values = [];
                if (res.data.length) {
                    values = res.data.map(val => {
                        return { value: val.uuid, label: val.name };
                    });
                }

                this.setState({ memberAuthSourceOptions: values, loadingSource: false});
            })
            .catch((err) => {
                this.setState({ loadingSource: false });

                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);

                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });

        if (this.props.action) {
            getMemberIntegrationsByCompany()
                .then((res) => {
                    let values = [];
                    if (res.data.length) {
                        values = res.data.map(val => {
                            return { value: val.uuid, label: val.name };
                        });
                    }

                    this.setState({ integrationOptions: values, loadingIntegrations: false});
                })
                .catch((err) => {
                    this.setState({ loadingIntegrations: false });

                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger",
                            NotificationFailedIcon, null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null)
                    }
                });
        }
    }

    requestIntegrations() {
        getMemberIntegrationsByCompany()
            .then((res) => {
                let values = [];
                if (res.data.length) {
                    values = res.data.map(val => {
                        return { value: val.uuid, label: val.name };
                    });
                }

                this.setState({ integrationOptions: values, loadingIntegrations: false});
            })
            .catch((err) => {
                this.setState({ loadingIntegrations: false });
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);

                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });
    }



    cleanData() {
        this.setState({
            domainName: '',
            domainUuid: '',
            domains: [],
            integration: '',
            memberAuthSource: '',
            currentId: 1
        });
    }

    changeSourceSelect = memberAuthSource => {
        this.setState({ domains: [], integration: '' });

        this.setState({ memberAuthSource });
        if (memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION) {
            this.setState({ loadingIntegrations: true });
            this.requestIntegrations();
        }
    };

    handleChangeFieldOption = (event) => {
        this.setState({ passwordRequired: parseInt(event.target.value) });
    };

    handleSwitchRequired = () => {
        this.setState({ passwordRequired: this.state.passwordRequired === 1 ? 0 : 1 });
    };


    changeIntegrationSelect = integration => {
        this.setState({ integration });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };



    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };




    addItem = () => {
        let id = this.state.currentId;
        if (this.props.action && this.state.domains.length && this.state.domains.length > 0) {
            id = parseInt(this.state.domains[this.state.domains.length - 1].id) + 1;
            this.setState({ currentId: id });
        }
        let array = {id: id, name: "", value: "", isEditing: true, isUpdated: false};

        memberAuthConfigDetails.domains.push(array);

        disableComponent(this, "disableField");
        this.setState({
            currentId: this.state.currentId + 1,
            domainName: "",
            domains: memberAuthConfigDetails.domains,
        });
    };



    updateMemberAuthSource = () => {
        const { memberAuthSource, integration, domains, passwordLabel, usernameLabel, passwordRequired } = this.state;

        if (selectValidation(memberAuthSource)) {
            this.showNotification(this.props.t('AUTH_SOURCE_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            let error = false;
            if (memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION && selectValidation(integration)) {
                error =  true;
                this.showNotification(this.props.t('SELECT_INTEGRATION_MSG'), "danger", NotificationErrorIcon, null);
            }
            if (memberAuthSource.label === MEMBER_AUTH_SOURCE_DOMAIN && selectValidation(domains)) {
                error =  true;
                this.showNotification(this.props.t('DOMAINS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
            }

            if (!error) {
                let domainsList = domains.map(item => {
                    return {name: item.name, value: item.value}
                });


                let dataParameters = {
                    "memberAuthConfigId": memberAuthConfigDetails.uuid,
                    "memberAuthSourceId": memberAuthSource.value,
                    "integrationId": !selectValidation(integration) ? integration.value : "",
                    "memberDomains": JSON.stringify(domainsList),
                    "passwordRequired": passwordRequired === 1,
                    "usernameLabel": usernameLabel,
                    "passwordLabel": passwordLabel,
                };


                disableComponent(this, "disableField");
                editMemberAuthConfiguration(dataParameters)
                    .then((response) => {
                        if (isSuccessfulRequest(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        } else {
                            this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                        }
                    });
            }
        }
    };



    saveMemberAuthSource = () => {
        const { memberAuthSource, integration, domains, passwordLabel, usernameLabel, passwordRequired } = this.state;

        if (selectValidation(memberAuthSource)) {
            this.showNotification(this.props.t('AUTH_SOURCE_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            let error = false;
            if (memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION && selectValidation(integration)) {
                error =  true;
                this.showNotification(this.props.t('SELECT_INTEGRATION_MSG'), "danger", NotificationErrorIcon, null);
            }
            if (memberAuthSource.label === MEMBER_AUTH_SOURCE_DOMAIN && selectValidation(domains)) {
                error =  true;
                this.showNotification(this.props.t('DOMAINS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
            }

            if (!error) {
                let domainsList = domains.map(item => {
                    return { name: item.name }
                });


                let dataParameters = {
                    "memberAuthSourceId": memberAuthSource.value,
                    "integrationId": !selectValidation(integration) ? integration.value : "",
                    "usernameLabel": usernameLabel,
                    "passwordLabel": passwordLabel,
                    "passwordRequired": passwordRequired === 1,
                    "memberDomains": JSON.stringify(domainsList)
                };


                disableComponent(this, "disableField");
                addMemberAuthConfiguration(dataParameters)
                    .then((response) => {
                        if (isSuccessfulCreate(response)) {
                            this.cleanData();
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                        } else {
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        } else {
                            this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                        }
                    });
            }
        }
    };



    render() {
        const { classes, t } = this.props;

        const SaveButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem}
                onClick={() => this.saveMemberAuthSource()}
            >
                {t('BTN_SAVE')}
            </Button>
        );

        const EditButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                disabled={this.state.disableField || this.state.disabledItem}
                onClick={() => this.updateMemberAuthSource()}
            >
                {t('BTN_CONFIRM_CHANGES')}
            </Button>
        );

        return (
            <div className={classes.scheduleContainer} style={{paddingBottom: 30}}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="memberAuthSource" className={classes.selectTitle}>
                                {t('MEMBER_AUTH_SOURCE_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.memberAuthSource}
                                onChange={this.changeSourceSelect}
                                isLoading={this.state.loadingSource}
                                options={this.state.memberAuthSourceOptions}
                                isDisabled={this.state.loadingSource || this.props.action}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={160}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    {
                        this.state.memberAuthSource === '' || this.state.memberAuthSource === null ? null : (
                            <GridItem xs={12} sm={12} md={12}>
                                {
                                    this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_DOMAIN ? (
                                        <div style={{marginTop: 10}}>
                                            <h5 className={classes.titleNoMargin}>{t('DOMAINS_TXT')}</h5>
                                            <div className={classes.itemsGroupContainer}>
                                                <Domains items={this.state.domains} component={this}/>
                                            </div>
                                            <div className={classes.addScheduleButton}>
                                                <SimpleButton
                                                    size="small"
                                                    color="primary"
                                                    disabled={this.state.disableField || this.state.disabledItem}
                                                    onClick={this.addItem}
                                                >
                                                    {t('BTN_ADD_DOMAIN')}
                                                </SimpleButton>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION ? (
                                                    <div className={classes.selectControl} style={{marginTop: 20}}>
                                                        <InputLabel htmlFor="integration" className={classes.selectTitle}>
                                                            {t('INTEGRATION_TEXT')}
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.integration}
                                                            onChange={this.changeIntegrationSelect}
                                                            isLoading={this.state.loadingIntegrations}
                                                            options={this.state.integrationOptions}
                                                            isDisabled={this.state.loadingIntegrations}
                                                            placeholder={t('SELECT_TEXT')}
                                                            closeMenuOnSelect={true}
                                                            maxMenuHeight={160}
                                                            theme={theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: primaryColor[0],
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    )
                                }
                            </GridItem>
                        )
                    }

                    {
                        this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION ? (
                            <GridItem xs={12} sm={6} md={6}>
                                <div className={classes.selectControl} style={{marginTop: 20}}>
                                    <FormControlLabel
                                        className={classes.rightContainerSwitch}
                                        control={
                                            <CustomSwitch
                                                className={classes.switchBase}
                                                size="small"
                                                checked={this.state.passwordRequired === 1}
                                                onChange={this.handleSwitchRequired}
                                            />
                                        }
                                        label={t('REQUIRE_PASSWORD_TXT')}/>
                                </div>
                            </GridItem>
                        ) : null
                    }

                    {
                        this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_INTEGRATION
                        || this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_CLIENT_CODE ? (
                            <div style={{width: "95%", marginLeft: "2.5%", marginTop: 25}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            value={this.state.usernameLabel}
                                            onChange={this.handleChangeValues}
                                            labelText={t('USERNAME_LABEL_TEXT')}
                                            id="usernameLabel"
                                            name="usernameLabel"
                                            formControlProps={{
                                                fullWidth: false,
                                            }}
                                        />
                                    </GridItem>
                                    {
                                        this.state.memberAuthSource.label === MEMBER_AUTH_SOURCE_CLIENT_CODE || this.state.passwordRequired === 0 ? null : (
                                            <GridItem xs={12} sm={6} md={6}>
                                                <CustomInput
                                                    value={this.state.passwordLabel}
                                                    onChange={this.handleChangeValues}
                                                    labelText={t('PASSWORD_LABEL_TEXT')}
                                                    id="passwordLabel"
                                                    name="passwordLabel"
                                                    formControlProps={{
                                                        fullWidth: false,
                                                    }}
                                                />
                                            </GridItem>
                                        )
                                    }
                                </GridContainer>
                            </div>

                        ) : null
                    }

                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{marginTop: 15}}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.closeForm("openForm", false, null)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                {this.props.action ? <EditButton/> : <SaveButton />}
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

MemberAuthConfigForm.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(MemberAuthConfigForm)));