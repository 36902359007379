import { getService, postService, putService } from "./api";
import {
    BASE_URL_CORE,
    BASE_URL_APPLICATIONS,
    MEMBER,
    MEMBERS_BY_COMPANY,
    TIME_CHECKS_BY_COMPANY,
    TIME_CHECKS_BY_DATE,
    TIME_CHECK_MANUAL,
    MEMBERS_OWNERS_BY_COMPANY,
    MEMBER_SEND_INVITE,
    DEFAULTING_MEMBERS,
    MEMBER_DETAILS,
    MEMBER_ORDERS,
    MEMBER_PAYMENT_REQUEST,
    MEMBER_GROUPS_BY_COMPANY,
    MEMBER_GROUP,
    PAYMENT_REQUEST_GROUPS_BY_MEMBER,
    MEMBER_BULK,
    ALL_COUNTRIES,
    REGIONS_BY_COUNTRY,
    DEFAULT_MEMBER_CREDIT_CARD,
    MEMBER_PAYMENT_METHODS,
    MEMBER_CREDIT_CARD,
    BASE_URL_COMMERCE,
    MEMBER_GROUP_DETAILS,
    MEMBER_GROUP_TYPES,
    MEMBER_GROUP_ASSIGNMENTS,
    MEMBER_GROUP_ASSIGNMENT,
    DELETE_MEMBER_GROUP_ASSIGNMENT,
    SYNC_MEMBERS,
    SYNC_MEMBER, SYNC_MEMBER_DATA,
} from "../variables/apiConstants";
import memberDetails from "../models/MemberModel";

export function getMembersByCompany() {
    let url = BASE_URL_CORE + MEMBERS_BY_COMPANY;
    return getService(url);
}

export function getMembersOwnersByCompany() {
    let url = BASE_URL_CORE + MEMBERS_OWNERS_BY_COMPANY ;

    return getService(url);
}

export function addMember(parameters) {
    let url = BASE_URL_CORE + MEMBER;
    return postService(url, parameters);
}

export function addMembersBulk(parameters) {
    let url = BASE_URL_CORE + MEMBER_BULK;
    return postService(url, parameters);
}

export function editMember(parameters) {
    let url = BASE_URL_CORE + MEMBER + "/" + memberDetails.uuid;
    return putService(url, parameters);
}

export function sendMemberInvite(memberId) {
    let url = BASE_URL_CORE + MEMBER_SEND_INVITE + memberId;
    return putService(url, null);
}

export function getTimeChecksByCompany() {
    let url = BASE_URL_APPLICATIONS + TIME_CHECKS_BY_COMPANY;
    return getService(url);
}


export function getTimeChecksByDate(parameters) {
    let url = BASE_URL_APPLICATIONS + TIME_CHECKS_BY_DATE;
    return postService(url, parameters);
}

export function addTimeCheck(parameters) {
    let url = BASE_URL_APPLICATIONS + TIME_CHECK_MANUAL;
    return postService(url, parameters);
}

export function getDefaultingMembers(parameters) {
    let url = BASE_URL_CORE + DEFAULTING_MEMBERS;
    return postService(url, parameters);
}

export function getMemberDetails(id) {
    let url = BASE_URL_CORE + MEMBER_DETAILS + id;
    return getService(url);
}

export function getMemberOrders(parameters) {
    let url = BASE_URL_CORE + MEMBER_ORDERS;
    return postService(url, parameters);
}

export function getMemberPaymentsRequest(parameters) {
    let url = BASE_URL_CORE + MEMBER_PAYMENT_REQUEST;
    return postService(url, parameters);
}


export function getMemberGroupsByCompany(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUPS_BY_COMPANY;
    return postService(url, parameters);
}

export function addMemberGroup(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUP;
    return postService(url, parameters);
}

export function editMemberGroup(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUP;
    return putService(url, parameters);
}


export function getPaymentsRequestGroupsByMember(member) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_GROUPS_BY_MEMBER + member;
    return postService(url, null);
}


export function getCountries(onComplete, onError, type, signal) {
    postService(BASE_URL_CORE + ALL_COUNTRIES, null)
        .then(response => {
            onComplete(response, type);
        })
        .catch(error => {
            onError(error, type)
        });
}


export function getRegionsByCountry(parameters, onComplete, onError, type, signal) {
    postService(BASE_URL_CORE + REGIONS_BY_COUNTRY, parameters)
        .then(response => {
            onComplete(response, type);
        })
        .catch(error => {
            onError(error, type)
        });
}

export function setDefaultMemberPaymentMethod(parameters, onComplete, onError, type, signal) {
    putService(BASE_URL_COMMERCE + DEFAULT_MEMBER_CREDIT_CARD, parameters)
        .then(response => {
            onComplete(response, type);
        })
        .catch(error => {
            onError(error, type)
        });
}

export function getMemberPaymentMethods(parameters, onComplete, onError, type, signal) {
    postService(BASE_URL_COMMERCE + MEMBER_PAYMENT_METHODS, parameters)
        .then(response => {
            onComplete(response, type);
        })
        .catch(error => {
            onError(error, type)
        });
}

export function createMemberPaymentMethod(parameters, onComplete, onError, type, signal) {
    postService(BASE_URL_COMMERCE + MEMBER_CREDIT_CARD, parameters)
        .then(response => {
            onComplete(response, type);
        })
        .catch(error => {
            onError(error, type)
        });
}


export function getMemberGroupTypes() {
    let url = BASE_URL_CORE + MEMBER_GROUP_TYPES;
    return getService(url);
}


export function getMembersOnMemberGroupByCompany(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUP_DETAILS;
    return postService(url, parameters);
}


export function getMemberGroupMemberAssignments(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUP_ASSIGNMENTS;
    return postService(url, parameters);
}


export function assignMembersToGroup(parameters) {
    let url = BASE_URL_CORE + MEMBER_GROUP_ASSIGNMENT;
    return putService(url, parameters);
}


export function deleteMemberFromGroup(parameters) {
    let url = BASE_URL_CORE + DELETE_MEMBER_GROUP_ASSIGNMENT;
    return putService(url, parameters);
}

export function syncMembers() {
    let url = BASE_URL_COMMERCE + SYNC_MEMBERS;
    return getService(url);
}


export function syncMember(parameters) {
    let url = BASE_URL_COMMERCE + SYNC_MEMBER;

    return new Promise(resolve => {
        postService(url, parameters).then(r => resolve(r)).catch(e => {
            if (e.response) {
                resolve(e.response);
            } else {
                resolve(null);
            }
        });
    });
}


export function syncMemberData(parameters) {
    let url = BASE_URL_COMMERCE + SYNC_MEMBER_DATA;
    return new Promise(resolve => {
        postService(url, parameters).then(r => resolve(r)).catch(e => {
            if (e.response) {
                resolve(e.response);
            } else {
                resolve(null);
            }
        });
    });
}
