import React from "react";
// nodejs library to set properties for components
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Loading from "../../components/Loading/Loading";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {formTitles, statusColors, statusText} from "../../utils/statusHelpers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import RoleForm from "./components/RoleForm";
import Dialog from "@material-ui/core/Dialog";
import TableActions from "../../components/TableActions/TableActions";
import Status from "../../components/Status/Status";
import {getRolesByCompany} from "../../services/roleService";
import roleDetails from "../../models/RoleModel";
import {
    ENTITY_ROLE,
    NOTIFICATION_DURATION,
    ROLE_TABLE_ACTIONS,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS
} from "../../variables/coreConstants";
import {nullController, saveData} from "../../utils/helpersFunctions";
import Muted from "../../components/Typography/Muted";
import {validateSession} from "../../services/api";
import Snackbar from "../../components/Snackbar/Snackbar";
import {getStatus, statusController} from "../../services/statusService";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Roles extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openRole: false,
            isLoading: true,
            isLoadingMore: true,
            actionType: 0,
            rolesData: [],
            filteredRoles: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: +event.target.value});
        this.setState({ page: 0 });
    };

    handleOpenForm = () => {
        roleDetails.cleanRoleDetails();
        this.setState({ openRole: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openRole: false });
    };

    closeForm(state, value, type) {
        this.setState({[state]: value });
        if(type)
            this.request();
    }
    openForm = (action, formData) => {
        roleDetails.id = formData.id;
        roleDetails.name = formData.name;

        if (action === 0 || action === 1){
            this.setState({ openRole: true });
            this.setState({ actionType: action });

        } else {
            let status = getStatus(action);

            if (status !== null) {
                let dataParameters = {
                    "entity": ENTITY_ROLE,
                    "id": roleDetails.id,
                    "status": status
                };

                statusController(action, null, null, dataParameters, this, true);
            }
        }
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.closeForm("openRole", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request = () => {
        this.setState({ isLoading: true });
        getRolesByCompany()
            .then((res) => {
                this.setState({ rolesData: res.data });
                this.setState({ filteredRoles: res.data });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };


    // Method to search data
    searchRoles= (e) => {
        let newData = this.state.rolesData;
        try {
            newData = newData.filter(function(item){
                return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });

            this.setState({ filteredRoles: newData });

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    goToActions = (val) => {
        saveData("role", val);
        roleDetails.id = val.id;
        roleDetails.name = val.name;
        this.props.history.push("quick-actions-role");
    };

    handleLoadMore = () => {

    };


    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage, isLoading, filteredRoles, isLoadingMore } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12}>
                    <TableGridWijmo
                        tableName={'roles'}
                        title={t('ROLES_TEXT')}
                        exportFileName={t('ROLES_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        data={filteredRoles}
                        actions={[
                            { label: t('BTN_ROLE'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ROLE_ID'), name: "uuid", isReadOnly: true, width: 350, isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 100, cssClass: "cell-vertical-middle", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />

                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openRole}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} {t('ROLE_TEXT')}
                            <Muted>
                                {t('FORM_ROLE_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <RoleForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}


Roles.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Roles)));
