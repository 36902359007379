import React from "react";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
// @material-ui/core icons
import Snackbar from "../../../../components/Snackbar/Snackbar.jsx";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../../variables/coreConstants";
import {withRouter} from "react-router-dom";



class ApplicationReleaseStep extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
    }

    componentDidMount() {
    }

    // Method for wizard component
    sendState() {
        return this.state;
    }

    cleanData() {
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    render() {
        const { classes } = this.props;


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ApplicationReleaseStep.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(ApplicationReleaseStep));