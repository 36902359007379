var integrationDetails = {
    uuid: "",
    name: "",
    object: "",
    action: "",
    actions: [],
    actionDetail: {
        uuid: "",
        name: "",
        settingUuid: "",
        endpoint: "",
        headers: []
    },
    headerDetail: {
        name: "",
        value: ""
    },

    setIntegrationDetail : function (values) {
        try {
            this.uuid = values.uuid;
            this.name = values.name;
            this.object = "";
            this.actions = [];
            this.actionDetail.uuid = "";
            this.actionDetail.name = "";
            this.actionDetail.settingUuid = "";
            this.actionDetail.endpoint = "";
            this.actionDetail.headers = [];
            this.headerDetail.name = "";
            this.headerDetail.value = "";
        } catch (e) {
            console.log("Invalid data");
        }
    },
    cleanIntegrationDetails : function () {
        this.uuid = "";
        this.name = "";
        this.object = "";
        this.actions = [];
        this.actionDetail.endpoint = "";
        this.actionDetail.uuid = "";
        this.actionDetail.settingUuid = "";
        this.actionDetail.name = "";
        this.actionDetail.headers = [];
        this.headerDetail.name = "";
        this.headerDetail.value = "";
    },
    cleanActionDetails : function () {
        this.actionDetail.uuid = "";
        this.actionDetail.name = "";
        this.actionDetail.settingUuid = "";
        this.actionDetail.endpoint = "";
        this.actionDetail.headers = [];
    },
};

export default integrationDetails;