/**
 * Created By Marlon Reyes 01/11/2019
 **/


// ##############################
// Text comparison
// ##############################

const RESULT_CREATED = 201;
const RESULT_SUCCESS = 200;
const RESULT_SESSION_EXPIRED = "Access Denied";


// ##############################
// Boolean string
// ##############################

const YES = "Yes";
const NO  = "No";

// ##############################
// Global messages
// ##############################

const CONFIRM_ACCOUNT_TITLE_SUCCESS = "Great";
const CONFIRM_ACCOUNT_TITLE_FAIL = "Sorry";
const CONFIRM_ACCOUNT_TITLE_ERROR = "Something went wrong";
const CONFIRM_ACCOUNT_MESSAGE_SUCCESS = "Your account has been confirmed.";
const CONFIRM_ACCOUNT_MESSAGE_FAIL = "Your account has'nt been confirmed, please contact us.";
const CONFIRM_ACCOUNT_MESSAGE_ERROR = "Verify your connection or contact us.";

const ACCOUNT_CREATED_TITLE = "Thank you for signing up.";
const ACCOUNT_CREATED_MESSAGE = "Click on the verification link we sent your email address to activate your Ginih account.";

const ERROR_REQUEST_MESSAGE = "Something went wrong.";
const INVALID_EMAIL_MESSAGE = "Cannot use emails with special characters.";
const PASSWORD_WEAK_MESSAGE = "Password must have a minimum of 8 characters.";
const PASSWORD_ERROR_MESSAGE = "Passwords do not match.";
const NOT_FOUND_MESSAGE = "Resource not found.";
const SESSION_EXPIRED_MESSAGE = "Your session has expired, please sign in again.";



// ##############################
// Pagination's configuration
// ##############################

const ROWS_PER_PAGE                     = 50;
const ROWS_PER_PAGE_PAYMENTS            = 50;
const ROWS_PER_PAGE_DETAILS             = 10;
const ROWS_PER_PAGE_OPTIONS             = [10, 25, 50];
const PAYMENTS_ROWS_PER_PAGE_OPTIONS    = [50, 100, 150];
const DEFAULT_ROWS_PER_PAGE_OPTIONS     = [25, 50, 100];
const ROWS_PER_PAGE_OPTIONS_DETAILS     = [5, 10, 25];
const ROWS_PER_PAGE_OPTIONS_BULK        = [50, 100, 200, 500];



// ##############################
// Table actions: menu options
// ##############################

const EDIT          = "EDIT_TEXT";
const DUPLICATE     = "DUPLICATE_TEXT";
const DELETE        = "DELETE_TEXT";
const ACTIVATE      = "ACTIVATE_TEXT";
const DEACTIVATE    = "DEACTIVATE_TEXT";
const UNASSIGN      = "UNASSIGN_TEXT";
const SHOW_QR_CODE  = "VIEW_QR_CODE_TEXT";
const DOWNLOAD_QR   = "DOWNLOAD_QR_CODE";
const SEND_INVITE   = "SEND_INVITE_TEXT";
const UPLOAD_FILE   = "UPLOAD_FILE_TEXT";
const SET_DEFAULT   = "MAKE_DEFAULT_TEXT";
const SYNC_NOW      = "SYNC_NOW_TEXT";
const SYNC_MANUALLY = "SYNC_MANUALLY_TEXT";
const VALIDATE      = "VALIDATE_TEXT";
const COPY_LINK     = "COPY_LINK_TEXT";


const STORE_TABLE_ACTIONS           = [EDIT, DUPLICATE, ACTIVATE, DEACTIVATE];
const ASSET_TABLE_ACTIONS           = [EDIT, DUPLICATE, ACTIVATE, DEACTIVATE];
const MEMBER_TABLE_ACTIONS          = [EDIT, SEND_INVITE, ACTIVATE, DEACTIVATE];
const MENU_TABLE_ACTIONS            = [EDIT, DUPLICATE, ACTIVATE, DEACTIVATE];
const PRODUCT_TABLE_ACTIONS         = [EDIT, DUPLICATE, ACTIVATE, DEACTIVATE];
const ORDER_TABLE_ACTIONS           = [];
const PAYMENT_TABLE_ACTIONS         = [];
const ROLE_TABLE_ACTIONS            = [EDIT, DUPLICATE, ACTIVATE, DEACTIVATE];
const PRICEBOOK_TABLE_ACTIONS       = [EDIT, DUPLICATE];
const CATEGORY_TABLE_ACTIONS        = [EDIT, DUPLICATE];
const APPLICATION_TABLE_ACTIONS     = [EDIT];
const ACTIVITY_TABLE_ACTIONS        = [EDIT];
const DISCOUNT_TABLE_ACTIONS        = [EDIT];
const CONNECT_TABLE_ACTIONS         = [EDIT];
const PAYMENT_REQUEST_TABLE_ACTIONS = [EDIT, COPY_LINK, DELETE];
const PAYMENT_REQUEST_TABLE_ACTIONS_II = [EDIT, DELETE];

const PAYMENT_REQUEST_SYNC_TABLE_ACTIONS = [COPY_LINK, SYNC_NOW, SYNC_MANUALLY];
const PAYMENT_REQUEST_SYNC_TABLE_ACTIONS_II = [SYNC_NOW, SYNC_MANUALLY];


const ASSET_DETAILS_TABLE_ACTIONS          = [UNASSIGN];
const ASSET_QR_DETAILS_TABLE_ACTIONS       = [SHOW_QR_CODE, UNASSIGN];
const ZONE_DETAILS_TABLE_ACTIONS           = [EDIT, DUPLICATE];
const MENU_DETAILS_TABLE_ACTIONS           = [EDIT, DUPLICATE];
const PRODUCT_DETAILS_TABLE_ACTIONS        = [UNASSIGN];
const APPLICATION_DETAILS_TABLE_ACTIONS    = [UNASSIGN];
const APP_ROLE_DETAILS_TABLE_ACTIONS       = [UNASSIGN];
const APP_ZONES_DETAILS_TABLE_ACTIONS      = [UNASSIGN];
const APP_MENUS_DETAILS_TABLE_ACTIONS      = [UNASSIGN];
const SESSIONS_DETAILS_TABLE_ACTIONS       = [];
const SUBSCRIBERS_DETAILS_TABLE_ACTIONS    = [];
const ATTENDANCE_DETAILS_TABLE_ACTIONS     = [];
const RATINGS_DETAILS_TABLE_ACTIONS        = [];
const DOCUMENTS_REQUIRED_TABLE_ACTIONS     = [UPLOAD_FILE];
const COMPANY_PROVIDERS_TABLE_ACTIONS      = [UNASSIGN];
const DEFAULT_PROVIDER_TABLE_ACTIONS       = [UNASSIGN, SET_DEFAULT];
const COMPANY_API_KEYS_TABLE_ACTIONS       = [DELETE];
const ORDERS_TABLE_ACTIONS                 = [SYNC_NOW];

const DISCOUNT_PRODUCT_TABLE_ACTIONS            = [UNASSIGN];
const DISCOUNT_STORE_TABLE_ACTIONS              = [UNASSIGN];
const DISCOUNT_COMPANY_TABLE_ACTIONS            = [UNASSIGN];
const MEMBERSHIP_AUTH_TABLE_ACTIONS             = [UNASSIGN];
const MEMBERSHIP_AUTH_DEFAULT_TABLE_ACTIONS     = [EDIT, SET_DEFAULT];
const MEMBER_PAYMENT_METHOD_TABLE_ACTIONS = [ACTIVATE, DEACTIVATE, SET_DEFAULT];

const TEMPLATE_ITEM_ACTIONS                 = [EDIT, DELETE];
const MEMBER_GROUP_TABLE_ACTIONS            = [EDIT];
const MEMBER_GROUP_DETAILS_TABLE_ACTIONS    = [DELETE];
const TEMPLATE_TABLE_ACTIONS                = [EDIT];

const USER_TABLE_ACTIONS                = [EDIT, DEACTIVATE, ACTIVATE];

const CASH_ACCOUNT_TABLE_ACTIONS            = [VALIDATE];


// ##############################
// Entities Constants
// ##############################


const ENTITY_STORE = "store";
const ENTITY_ZONE = "zone";
const ENTITY_ZONE_GROUP = "zoneGroup";
const ENTITY_APPLICATION = "application";       // there not status column
const ENTITY_ASSET = "asset";
const ENTITY_ROLE = "role";
const ENTITY_MEMBER = "member";
const ENTITY_DASHBOARD_USER = "dashboardUser";

const ENTITY_CATALOG = "catalog";
const ENTITY_CATEGORY = "category";             // there not status column
const ENTITY_PRODUCT = "product";

const ENTITY_DISCOUNT = "discounts";
const ENTITY_PRODUCT_DISCOUNT = "product_discount";
const ENTITY_STORE_DISCOUNT = "store_discount";
const ENTITY_MEMBERSHIP_DISCOUNT = "membership_discount";
const ENTITY_MEMBER_PAYMENT_METHOD = "member_payment_method";


const DEPOSIT_PAYMENT_REQUEST         = "PAYMENT_REQUEST";
const DEPOSIT_ORDER                   = "ORDER";



const ACTIVATE_STATUS = "activate";
const DEACTIVATE_STATUS = "deactivate";
const DELETE_STATUS = "delete";
const DEALLOCATE_STATUS = "deallocate";

const ALL_STATUS = "all";
const PAID_STATUS = "paid";
const NOT_PAID_STATUS = "not_paid";
const PENDING_STATUS = "pending";
const NOT_COMPLETED_STATUS = "not_completed";
const PROVIDER_FAILED_STATUS = "provider_failed";
const PAYMENT_NOT_COMPLETED_STATUS = "payment_not_completed";
const UNSPECIFIED_STATUS = "unspecified";
const DECLINED_STATUS = "declined";
const RETRY_FAILED_STATUS = "retry_failed";
const FAILED_SYNC_STATUS = "failed_sync";
const STATUS_PENDING_AUTH = "pending_authentication";
const STATUS_PAID_PENDING_SYNC = "paid_pending_sync";

const ALL_STATUS_INT = -1;
const PAID_STATUS_INT = 4;
const NOT_PAID_STATUS_INT = 5;
const PENDING_STATUS_INT = 3;
const NOT_COMPLETED_STATUS_INT = 22;
const PROVIDER_FAILED_STATUS_INT = 35;
const PAYMENT_NOT_COMPLETED_STATUS_INT = 16;
const UNSPECIFIED_STATUS_INT = 17;
const DECLINED_STATUS_INT = 11;
const RETRY_FAILED_STATUS_INT = 34;
const FAILED_SYNC_STATUS_INT = 25;
const STATUS_PENDING_AUTH_INT = 44;
const STATUS_PAID_PENDING_SYNC_INT = 46;
const STATUS_PAID_EXTERNALLY_INT = 36;
const STATUS_PAID_PARTIAL_INT = 43;


// ##############################
// Applications names Constants
// ##############################

const APP_COMMERCE = "Commerce";
const APP_SETUP = "Setup";
const APP_ACTIVITY = "Activity";



// ##############################
// Applications types identifiers
// ##############################

const ATTENDANCE = {value: 1, label:"Attendance"};
const MANAGE_ACTIVITIES = {value: 2, label:"Manage Activities"};
const MENU = {value: 3, label:"Menu"};
const PAYMENT_APP = {value: 8, label:"Payment"};


// ##############################
// Asset technologies
// ##############################

const ASSET_BEACON  = "Estimote Beacon";
const ASSET_QR_CODE = "QR Code";
const ASSET_VIRTUAL = "Virtual";



// ##############################
// Number Constants
// ##############################

const NOTIFICATION_DURATION         = 2500;
const NOTIFICATION_DURATION_SHORT   = 1500;
const NOTIFICATION_DURATION_LARGE   = 4500;
const NOTIFICATION_SOUND_TIME       = 8000;
const QR_CODE_PER_ROW               = 3;
const DEFAULT_PAGINATION_PARAMETERS = { "page": 0, "pageSize": 50 };
const TRANSACTIONS_PAGINATION_PARAMETERS = { "page": 0, "pageSize": 50, "status": "all" };
const PAYMENT_PAGINATION_PARAMETERS = { "page": 0, "pageSize": 50, "status": -1 };
const MAX_BULK_ITEMS                = 10000;



// ##############################
// Specific Constants
// ##############################

const BASE64_HEADER                 = "data:image/jpeg;base64,";
const PDF_BASE64_HEADER             = "data:application/pdf;base64,";
const DATA_TYPE_SUPPORTED           = "data:image/";
const DATA_TYPE_CSV_EXCEL_SUPPORTED = "application/vnd.ms-excel";
const DATA_TYPE_CSV_SUPPORTED       = "text/csv";
const DATA_TYPE_SUPPORTED_PDF       = "data:application/pdf";
const QR_CODE_DEEP_LINK_URL         = "https://ginih.com/qr/";
const MEMBER_CSV_EXAMPLE_URL        = "https://docs.google.com/spreadsheets/d/e/2PACX-1vRs1AiqC71eNQZoyFRtfBTHe-DcanXsi_GTYTVxyXH3y-Jalmk9caY5zS-8krqE7wD8afzTEW-c7zYI/pub?gid=0&single=true&output=csv";
const MEMBER_CARDS_CSV_EXAMPLE_URL  = "https://docs.google.com/spreadsheets/d/e/2PACX-1vRTSKx-KlOkRkWOmz6YhJe4aDswRraQ7r8Jth5KyUrypusTf-t5KPeVH1lRHgGxyejIBfxN-wzkxuR-/pub?gid=0&single=true&output=csv";
const DEFAULT_TAX                   = 0;
const DEFAULT_CURRENCY              = 1;
const ZONE_TYPE_DELIVERY            = 2;
const ZONE_TYPE_PAYMENT             = 4;

const STATUS_AWAITING_VALIDATION    = 19;
const STATUS_PENDING                = 3;
const STATUS_ASSIGNED               = 6;
const STATUS_PROGRESS               = 14;
const STATUS_COMPLETED              = 15;
const STATUS_PAID                   = 4;
const STATUS_PAID_NOT_SYNC          = 25;
const STATUS_REJECTED               = 24;
const STATUS_CANCELLED              = 7;
const STATUS_EXTERNAL_PAID          = 36;
const STATUS_TRANSFERRED            = 27;
const STATUS_COMPLETE               = 21;
const TAKE_AWAY                     = "0";
const DINE_IN                       = "1";
const DELIVERY                      = "2";

const RULE_STORE                    = 1;
const RULE_PRODUCT                  = 2;
const RULE_COMPANIES                = 3;

const ERROR_UPDATE_ORDER_NOT_ALLOWED    = 6;
const ERROR_PASSWORD_EXPIRED            = 7;
const ERROR_PASSWORD_REQUIRED           = 12;


const MEMBER_AUTH_SOURCE_DOMAIN         = "Domain";
const MEMBER_AUTH_SOURCE_INTEGRATION    = "Integration";
const MEMBER_AUTH_SOURCE_CLIENT_CODE    = "Client Code";


const PAYMENT_METHOD_ENROLLMENT    = "?mg=mgID&route=payment_method";


const NOTIFICATION_ACTION_UPDATE      = 1;
const NOTIFICATION_ACTION_SHOW        = 2;
const NOTIFICATION_ACTION_REDIRECT    = 3;
const NOTIFICATION_ACTION_NOTHING     = 5;


const TODAY_ID              = 0;
const YESTERDAY_ID          = 1;
const LAST_THREE_DAYS_ID    = 2;
const LAST_WEEK_ID          = 3;
const LAST_MONTH_ID         = 4;
const LAST_THREE_MONTHS_ID  = 5;
const LAST_SIX_MONTHS_ID    = 6;
const LAST_YEAR_ID          = 7;
const CUSTOM_ID             = 8;


const WITHDRAWAL_MOVEMENT_ID        = 1;
const DIRECT_DEBIT_TYPE_ID          = 1;



// ##############################
// Actions Constants
// ##############################

const ACTION_NEW                 = "new";
const ACTION_EDIT                = "edit";
const ACTION_UPDATE              = "update";

const MEMBERS_IMPORT_TYPE        = 1;
const MEMBER_CARDS_IMPORT_TYPE   = 2;


// ##############################
// Direct Debit Status
// ##############################

const DEBIT_TSK_READY_TO_PAY                 = "ready_to_pay";
const DEBIT_TSK_EXCLUDED                     = "excluded";
const DEBIT_TSK_COMPLETED                    = "completed";
const DEBIT_TSK_SYNC_COMPLETED               = "sync_completed";

const DEBIT_WSS_JOB_SYNC_ACTION               = "registerDebitSync";
const DEBIT_WSS_JOB_EXECUTE_ACTION            = "registerDebitExecution";


const SINGLE_PAYMENT_REQUEST        = 1;
const TEMPLATE_PAYMENT_REQUEST      = 2;





module.exports = {
    ROWS_PER_PAGE_OPTIONS,
    ROWS_PER_PAGE_OPTIONS_DETAILS,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_DETAILS,
    RESULT_CREATED,
    RESULT_SUCCESS,
    RESULT_SESSION_EXPIRED,
    CONFIRM_ACCOUNT_TITLE_SUCCESS,
    CONFIRM_ACCOUNT_TITLE_FAIL,
    CONFIRM_ACCOUNT_TITLE_ERROR,
    CONFIRM_ACCOUNT_MESSAGE_SUCCESS,
    CONFIRM_ACCOUNT_MESSAGE_FAIL,
    CONFIRM_ACCOUNT_MESSAGE_ERROR,
    NOTIFICATION_DURATION,
    NOTIFICATION_DURATION_LARGE,
    NOTIFICATION_DURATION_SHORT,
    ACCOUNT_CREATED_TITLE,
    ACCOUNT_CREATED_MESSAGE,
    ERROR_REQUEST_MESSAGE,
    INVALID_EMAIL_MESSAGE,
    PASSWORD_ERROR_MESSAGE,
    PASSWORD_WEAK_MESSAGE,
    NOT_FOUND_MESSAGE,
    SESSION_EXPIRED_MESSAGE,
    STORE_TABLE_ACTIONS,
    ASSET_TABLE_ACTIONS,
    MEMBER_TABLE_ACTIONS,
    ASSET_DETAILS_TABLE_ACTIONS,
    ASSET_QR_DETAILS_TABLE_ACTIONS,
    ZONE_DETAILS_TABLE_ACTIONS,
    MENU_DETAILS_TABLE_ACTIONS,
    MENU_TABLE_ACTIONS,
    ROLE_TABLE_ACTIONS,
    PRODUCT_TABLE_ACTIONS,
    PRICEBOOK_TABLE_ACTIONS,
    CATEGORY_TABLE_ACTIONS,
    DOCUMENTS_REQUIRED_TABLE_ACTIONS,
    DELETE,
    UNASSIGN,
    ACTIVATE,
    DEACTIVATE,
    EDIT,
    DUPLICATE,
    DOWNLOAD_QR,
    SHOW_QR_CODE,
    SEND_INVITE,
    UPLOAD_FILE,
    YES,
    NO,
    APPLICATION_TABLE_ACTIONS,
    APPLICATION_DETAILS_TABLE_ACTIONS,
    APP_ACTIVITY,
    APP_COMMERCE,
    APP_SETUP,
    APP_ROLE_DETAILS_TABLE_ACTIONS,
    ACTIVITY_TABLE_ACTIONS,
    SESSIONS_DETAILS_TABLE_ACTIONS,
    SUBSCRIBERS_DETAILS_TABLE_ACTIONS,
    RATINGS_DETAILS_TABLE_ACTIONS,
    ATTENDANCE_DETAILS_TABLE_ACTIONS,
    APP_ZONES_DETAILS_TABLE_ACTIONS,
    APP_MENUS_DETAILS_TABLE_ACTIONS,
    PRODUCT_DETAILS_TABLE_ACTIONS,
    ORDER_TABLE_ACTIONS,
    PAYMENT_TABLE_ACTIONS,
    ATTENDANCE,
    MANAGE_ACTIVITIES,
    MENU,
    BASE64_HEADER,
    PDF_BASE64_HEADER,
    DEFAULT_TAX,
    DEFAULT_CURRENCY,
    ASSET_BEACON,
    ASSET_QR_CODE,
    ASSET_VIRTUAL,
    QR_CODE_DEEP_LINK_URL,
    QR_CODE_PER_ROW,
    ENTITY_APPLICATION,
    ENTITY_ASSET,
    ENTITY_CATALOG,
    ENTITY_CATEGORY,
    ENTITY_MEMBER,
    ENTITY_PRODUCT,
    ENTITY_ROLE,
    ENTITY_STORE,
    ENTITY_ZONE,
    DEACTIVATE_STATUS,
    ACTIVATE_STATUS,
    DATA_TYPE_SUPPORTED,
    DATA_TYPE_SUPPORTED_PDF,
    STATUS_AWAITING_VALIDATION,
    COMPANY_PROVIDERS_TABLE_ACTIONS,
    DEFAULT_PROVIDER_TABLE_ACTIONS,
    TAKE_AWAY,
    DINE_IN,
    DELIVERY,
    STATUS_PROGRESS,
    STATUS_CANCELLED,
    STATUS_COMPLETED,
    STATUS_PENDING,
    STATUS_PAID,
    SET_DEFAULT,
    DEFAULT_PAGINATION_PARAMETERS,
    COMPANY_API_KEYS_TABLE_ACTIONS,
    DELETE_STATUS,
    DISCOUNT_TABLE_ACTIONS,
    DEALLOCATE_STATUS,
    ENTITY_DISCOUNT,
    ENTITY_PRODUCT_DISCOUNT,
    ENTITY_STORE_DISCOUNT,
    ENTITY_MEMBERSHIP_DISCOUNT,
    RULE_COMPANIES,
    RULE_PRODUCT,
    RULE_STORE,
    DISCOUNT_COMPANY_TABLE_ACTIONS,
    DISCOUNT_STORE_TABLE_ACTIONS,
    DISCOUNT_PRODUCT_TABLE_ACTIONS,
    ERROR_UPDATE_ORDER_NOT_ALLOWED,
    NOTIFICATION_ACTION_REDIRECT,
    NOTIFICATION_ACTION_SHOW,
    NOTIFICATION_ACTION_UPDATE,
    NOTIFICATION_ACTION_NOTHING,
    ZONE_TYPE_DELIVERY,
    ENTITY_ZONE_GROUP,
    CONNECT_TABLE_ACTIONS,
    MEMBERSHIP_AUTH_TABLE_ACTIONS,
    MEMBERSHIP_AUTH_DEFAULT_TABLE_ACTIONS,
    MEMBER_AUTH_SOURCE_DOMAIN,
    MEMBER_AUTH_SOURCE_INTEGRATION,
    PAYMENT_REQUEST_TABLE_ACTIONS,
    PAYMENT_PAGINATION_PARAMETERS,
    PAYMENTS_ROWS_PER_PAGE_OPTIONS,
    ROWS_PER_PAGE_PAYMENTS,
    NOTIFICATION_SOUND_TIME,
    FAILED_SYNC_STATUS,
    UNSPECIFIED_STATUS,
    NOT_COMPLETED_STATUS,
    PENDING_STATUS,
    NOT_PAID_STATUS,
    PAID_STATUS,
    ALL_STATUS,
    SYNC_NOW,
    PAYMENT_REQUEST_SYNC_TABLE_ACTIONS,
    STATUS_PAID_NOT_SYNC,
    FAILED_SYNC_STATUS_INT,
    UNSPECIFIED_STATUS_INT,
    NOT_COMPLETED_STATUS_INT,
    PENDING_STATUS_INT,
    NOT_PAID_STATUS_INT,
    PAID_STATUS_INT,
    ALL_STATUS_INT,
    STATUS_PAID_PENDING_SYNC_INT,
    STATUS_PENDING_AUTH_INT,
    RETRY_FAILED_STATUS_INT,
    DECLINED_STATUS_INT,
    PAYMENT_NOT_COMPLETED_STATUS_INT,
    PROVIDER_FAILED_STATUS_INT,
    LAST_SIX_MONTHS_ID,
    CUSTOM_ID,
    LAST_YEAR_ID,
    LAST_THREE_MONTHS_ID,
    LAST_MONTH_ID,
    LAST_WEEK_ID,
    LAST_THREE_DAYS_ID,
    TODAY_ID,
    YESTERDAY_ID,
    DEFAULT_ROWS_PER_PAGE_OPTIONS,
    STATUS_ASSIGNED,
    TEMPLATE_ITEM_ACTIONS,
    MEMBER_GROUP_TABLE_ACTIONS,
    TEMPLATE_TABLE_ACTIONS,
    ENTITY_DASHBOARD_USER,
    USER_TABLE_ACTIONS,
    ERROR_PASSWORD_EXPIRED,
    ORDERS_TABLE_ACTIONS,
    PROVIDER_FAILED_STATUS,
    PAYMENT_NOT_COMPLETED_STATUS,
    DECLINED_STATUS,
    RETRY_FAILED_STATUS,
    STATUS_REJECTED,
    MEMBER_AUTH_SOURCE_CLIENT_CODE,
    STATUS_EXTERNAL_PAID,
    DATA_TYPE_CSV_SUPPORTED,
    MAX_BULK_ITEMS,
    MEMBER_CSV_EXAMPLE_URL,
    DATA_TYPE_CSV_EXCEL_SUPPORTED,
    ACTION_NEW,
    ACTION_EDIT,
    VALIDATE,
    CASH_ACCOUNT_TABLE_ACTIONS,
    STATUS_COMPLETE,
    STATUS_TRANSFERRED,
    WITHDRAWAL_MOVEMENT_ID,
    ACTION_UPDATE,
    ZONE_TYPE_PAYMENT,
    PAYMENT_APP,
    STATUS_PENDING_AUTH,
    STATUS_PAID_PENDING_SYNC,
    DEPOSIT_PAYMENT_REQUEST,
    DEPOSIT_ORDER,
    SYNC_MANUALLY,
    MEMBER_PAYMENT_METHOD_TABLE_ACTIONS,
    ENTITY_MEMBER_PAYMENT_METHOD,
    MEMBERS_IMPORT_TYPE,
    MEMBER_CARDS_IMPORT_TYPE,
    MEMBER_CARDS_CSV_EXAMPLE_URL,
    MEMBER_GROUP_DETAILS_TABLE_ACTIONS,
    DIRECT_DEBIT_TYPE_ID,
    TRANSACTIONS_PAGINATION_PARAMETERS,
    ROWS_PER_PAGE_OPTIONS_BULK,
    DEBIT_TSK_READY_TO_PAY,
    DEBIT_TSK_EXCLUDED,
    DEBIT_TSK_COMPLETED,
    DEBIT_TSK_SYNC_COMPLETED,
    ERROR_PASSWORD_REQUIRED,
    DEBIT_WSS_JOB_SYNC_ACTION,
    DEBIT_WSS_JOB_EXECUTE_ACTION,
    STATUS_PAID_EXTERNALLY_INT,
    STATUS_PAID_PARTIAL_INT,
    PAYMENT_METHOD_ENROLLMENT,
    COPY_LINK,
    PAYMENT_REQUEST_SYNC_TABLE_ACTIONS_II,
    PAYMENT_REQUEST_TABLE_ACTIONS_II,
    SINGLE_PAYMENT_REQUEST,
    TEMPLATE_PAYMENT_REQUEST
};