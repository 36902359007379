/* Created by Marlon Reyes on July 25, 2019 */

import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Button from "@material-ui/core/Button";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import CardFooter from "../../components/Card/CardFooter.jsx";
import StoreForm from "../Stores/components/StoreForm";
import MenuForm from "../Menus/components/MenuForm";
import ProductForm from "../Products/components/ProductForm";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import storeDetails from "../../models/StoreModel";
import menuDetails from "../../models/MenuModel";
import "../../components/Icons/Icons";
import { getUser, nullController } from "../../utils/helpersFunctions";
import {
    IMG_MENU_CARD,
    IMG_PRODUCT_CARD,
    IMG_STORE_CARD,
} from "../../variables/resourcesConstants";
import PrivateComponent from "../../components/PrivateComponent/PrivateComponent";
import {
    APP_COMMERCE,
    NOTIFICATION_DURATION,
} from "../../variables/coreConstants";
import productDetails from "../../models/ProductModel";
import StoreImageUpload from "../Stores/components/StoreImageUpload";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Lottie from "react-lottie";
import homeAnimation from "../../assets/animations/home";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openStore: false,
            openMenu: false,
            openProduct: false,
            storeCompleted: false,
            actionType: 0,
            user: getUser(),
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationErrorIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    openStoreForm = () => {
        storeDetails.cleanStoreDetails();
        this.setState({ openStore: true });
    };
    closeStoreForm = () => {
        this.setState({ openStore: false });
    };

    openMenuForm = () => {
        menuDetails.cleanMenuDetails();
        this.setState({ openMenu: true });
    };
    closeMenuForm = () => {
        this.setState({ openMenu: false });
    };

    openProductForm = () => {
        productDetails.cleanProductDetails();
        this.setState({ openProduct: true });
    };
    closeProductForm = () => {
        this.setState({ openProduct: false });
    };

    closeForm(state, value) {
        this.setState({ [state]: value });
    }

    render() {
        const { storeCompleted, actionType } = this.state;
        const { classes, t, routeProps } = this.props;

        const screenWidth = Math.round(window.innerWidth);

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.typo}>
                            <h3 className={classes.headTitle}>
                                {t("WELCOME_TEXT")}{" "}
                                {nullController(this.state.user, "first_name") +
                                    " " +
                                    nullController(
                                        this.state.user,
                                        "last_name"
                                    )}
                            </h3>
                            <Muted>{t("WELCOME_HOME_DESCRIPTION")}</Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <div style={{ width: "100%", height: "auto" }}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: homeAnimation,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                                height={screenWidth * 0.25}
                                width={screenWidth * 0.25}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <PrivateComponent appType={APP_COMMERCE}>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.typo}>
                                    <h4> {t("QUICK_START")} </h4>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart>
                                    <div className={classes.center}>
                                        <img
                                            src={IMG_STORE_CARD}
                                            width={"175px"}
                                            height={"175px"}
                                            alt={"Store"}
                                        />
                                    </div>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>
                                            {t("STORE_TEXT")}
                                        </h4>
                                        <p className={classes.cardCategory}>
                                            {t("STORE_CARD_DESCRIPTION")}
                                        </p>
                                    </CardBody>
                                    <CardFooter chart>
                                        <Button
                                            onClick={this.openStoreForm}
                                            size="small"
                                            color="primary"
                                        >
                                            {t("BTN_ADD_NEW_STORE")}
                                        </Button>
                                    </CardFooter>
                                </Card>
                                <Dialog
                                    maxWidth={"md"}
                                    fullWidth={true}
                                    aria-labelledby="form-stores-dialog-title"
                                    open={this.state.openStore}
                                    onClose={this.closeStoreForm}
                                >
                                    <DialogTitle id="form-stores-dialog-title">
                                        {t("FORM_NEW_STORE_TEXT")}
                                        <Muted>
                                            {t("FORM_NEW_STORE_DESCRIPTION")}
                                        </Muted>
                                    </DialogTitle>
                                    <DialogContent>
                                        {storeCompleted ? (
                                            <StoreImageUpload
                                                closeForm={this.closeForm}
                                                action={actionType}
                                            />
                                        ) : (
                                            <StoreForm
                                                closeForm={this.closeForm}
                                                action={actionType}
                                                component={this}
                                            />
                                        )}
                                    </DialogContent>
                                </Dialog>
                            </GridItem>
                        </PrivateComponent>

                        <PrivateComponent appType={APP_COMMERCE}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart>
                                    <div className={classes.center}>
                                        <img
                                            src={IMG_MENU_CARD}
                                            width={"175px"}
                                            height={"175px"}
                                            alt={"Menu"}
                                        />
                                    </div>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>
                                            {t("MENU_TEXT")}
                                        </h4>
                                        <p className={classes.cardCategory}>
                                            {t("MENU_CARD_DESCRIPTION")}
                                        </p>
                                    </CardBody>
                                    <CardFooter chart>
                                        <Button
                                            onClick={this.openMenuForm}
                                            size="small"
                                            color="primary"
                                        >
                                            {t("BTN_ADD_NEW_MENU")}
                                        </Button>
                                    </CardFooter>
                                </Card>
                                <Dialog
                                    maxWidth={"md"}
                                    fullWidth={true}
                                    aria-labelledby="form-menu-dialog-title"
                                    open={this.state.openMenu}
                                    onClose={this.closeMenuForm}
                                >
                                    <DialogTitle id="form-menu-dialog-title">
                                        {t("FORM_NEW_MENU_TEXT")}
                                        <Muted>
                                            {t("FORM_NEW_MENU_DESCRIPTION")}
                                        </Muted>
                                    </DialogTitle>
                                    <DialogContent>
                                        <MenuForm closeForm={this.closeForm} />
                                    </DialogContent>
                                </Dialog>
                            </GridItem>
                        </PrivateComponent>
                        <PrivateComponent appType={APP_COMMERCE}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card chart>
                                    <div className={classes.center}>
                                        <img
                                            src={IMG_PRODUCT_CARD}
                                            width={"175px"}
                                            height={"175px"}
                                            alt={"Product"}
                                        />
                                    </div>
                                    <CardBody>
                                        <h4 className={classes.cardTitle}>
                                            {t("PRODUCT_TEXT")}
                                        </h4>
                                        <p className={classes.cardCategory}>
                                            {t("PRODUCT_CARD_DESCRIPTION")}
                                        </p>
                                    </CardBody>
                                    <CardFooter chart>
                                        <Button
                                            onClick={this.openProductForm}
                                            size="small"
                                            color="primary"
                                        >
                                            {t("BTN_ADD_NEW_PRODUCT")}
                                        </Button>
                                    </CardFooter>
                                </Card>
                                <Dialog
                                    maxWidth={"md"}
                                    fullWidth={true}
                                    aria-labelledby="form-menu-dialog-title"
                                    open={this.state.openProduct}
                                    onClose={this.closeProductForm}
                                >
                                    <DialogTitle id="form-menu-dialog-title">
                                        {t("FORM_NEW_PRODUCT_TEXT")}
                                        <Muted>
                                            {t("FORM_NEW_PRODUCT_DESCRIPTION")}
                                        </Muted>
                                    </DialogTitle>
                                    <DialogContent>
                                        <ProductForm
                                            closeForm={this.closeForm}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </GridItem>
                        </PrivateComponent>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(Home)));
