import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {formatPhone, getUser, nullController, nullValidator, updateUser} from "../../utils/helpersFunctions";
import {getCompanyInformation} from "../../services/accountService";
import Loading from "../../components/Loading/Loading";
import {validateSession} from "../../services/api";
import PaymentMethodProviderDetails from "./components/PaymentMethodProviderDetails";
import CompanyDocumentsDetails from "./components/CompanyDocumentsDetails";
import companyDetails from "../../models/AccountModel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AccountSettingForm from "./AccountSettingForm";
import {withTranslation} from "react-i18next";
import Muted from "../../components/Typography/Muted";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Link from "@material-ui/core/Link/Link";
import AccountLinkForm from "./components/AccountLinkForm";
import {withRouter} from "react-router-dom";


class AccountSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyInformation: this.props.data,
      actionType: 1,
      openForm: false,
      openLinkForm: false,
      openSubmitForm: false,
      isLoading: false,
    };

    this.closeForm = this.closeForm.bind(this);
  }


  request () {
      this.setState({ isLoading: false });
      getCompanyInformation()
          .then((response) => {
              this.setState({
                  companyInformation: response.data,
                  isLoading: false
              });

              companyDetails.setCompanyDetail(response.data);
              updateUser(response.data);

              try {
                  let key = 0;
                  companyDetails.timeZone = response.data.timeZones.map(item => {
                      key++;
                      return { value: key , label: item };
                  });
                  companyDetails.currentTime = response.data.currentTimeZone;
              } catch (e) {
                  console.log("Something went wrong");
              }

          })
          .catch((err) => {
              this.setState({ isLoading: false} );
              if (err.response) {
                  validateSession(err.response, this.props.history);
              } else {
                  console.log(err);
              }
          });
  }


    handleOpenForm = () => {
        this.setState({
            openForm: true,
            actionType: 1
        });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };
    handleOpenLinkForm = () => {
        const { companyInformation } = this.state;

        this.setState({
            openLinkForm: true,
            actionType: nullValidator(companyInformation, "link")
        });
    };
    handleCloseLinkForm = () => {
        this.setState({ openLinkForm: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type)
            this.request();
    }


  render() {
    const { classes, t, loading } = this.props;
    const { actionType, isLoading, companyInformation } = this.state;

    return (
        <div>
            {loading || isLoading ? <Loading/>
            : (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                              <div style={{float: "right", marginBottom: 15}}>
                                  <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_UPDATE')}</Button>
                              </div>
                          </GridItem>
                      </GridContainer>
                    <Card className={classes.noMargin}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={4}>
                            <h6 className={classes.settingsTitles}>
                              {t('TH_ADDRESS')}
                                <p className={classes.settingsTitle}>
                                  {nullController(companyInformation, 'address')}
                              </p>
                                <p className={classes.settingsTitle}>
                                  {nullController(companyInformation, 'postalCode')
                                  + " " + nullController(companyInformation, 'cityId')}
                              </p>
                            </h6>
                          </GridItem>
                          <GridItem xs={12} sm={3} md={2}>
                            <h6 className={classes.settingsTitles}>
                              {t('TH_PHONE_NUMBER')}
                              <p className={classes.settingsTitle}>
                                  {formatPhone(
                                      companyInformation.countryCode,
                                      companyInformation.phoneNumber
                                  )}
                              </p>
                            </h6>
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3}>
                            <h6 className={classes.settingsTitles}>
                                {t('TAX_TEXT')}
                              <p className={classes.settingsTitle}>{nullController(companyInformation, 'taxId')}</p>
                            </h6>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <h6 className={classes.settingsTitles}>
                                {t('ACCOUNT_ID_TEXT')}
                              <p className={classes.settingsTitle}>{nullController(getUser(), 'externalId')}</p>
                            </h6>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                              <h6 className={classes.settingsTitleNoMargin}>
                                  {t('ACCOUNT_LINK_TXT')}
                              </h6>
                              <div style={{display: "flex",flexDirection: "row"}}>
                                  {
                                      nullValidator(companyInformation, "link") ? (
                                          <Link href={companyInformation.link} target="_blank" rel="noopener noreferrer" className={classes.settingsTitle}>
                                              {companyInformation.link}
                                          </Link>
                                      ) : null
                                  }
                                  <Tooltip id="tooltip-edit" title={t('EDIT_TEXT')} placement="right-start">
                                      <IconButton
                                          onClick={this.handleOpenLinkForm}
                                          className={nullValidator(companyInformation, "link") ? classes.editLinkIcon : classes.formIcon}
                                      >
                                          <EditIcon className={classes.editIcon}/>
                                      </IconButton>
                                  </Tooltip>
                              </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <CompanyDocumentsDetails/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <PaymentMethodProviderDetails/>
                  </GridItem>
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openLinkForm}
                        onClose={this.handleCloseLinkForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_ACCOUNT_LINK_TITLE')}
                            <Muted>
                                {t('FORM_ACCOUNT_LINK_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <AccountLinkForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openForm}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            <div className={classes.center}>
                                <Muted>
                                    {t('UPDATE_COMPANY_INFORMATION')}
                                </Muted>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <AccountSettingForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                </GridContainer>
                )}
        </div>
    );
  }
}

AccountSettings.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  loading: PropTypes.bool
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(AccountSettings)));
