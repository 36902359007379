import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import {getPaymentRequestByCompany, syncPaymentRequest} from "../../services/paymentService";
import Loading from "../../components/Loading/Loading";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
    getCompanyCurrency, getRoundNumber,
    isSuccessfulRequest, mergeArrays,
    nullController, saveData, setCurrencyValue,
} from "../../utils/helpersFunctions";
import {formTitles, statusText} from "../../utils/statusHelpers";
import {
    NOTIFICATION_DURATION,
    PAYMENT_PAGINATION_PARAMETERS,
    PAYMENT_REQUEST_SYNC_TABLE_ACTIONS,
    PAYMENT_REQUEST_TABLE_ACTIONS,
    PAYMENTS_ROWS_PER_PAGE_OPTIONS,
    ROWS_PER_PAGE_PAYMENTS,
    STATUS_PAID_NOT_SYNC,
    STATUS_PENDING,
    SYNC_NOW,
    DELETE,
    SYNC_MANUALLY,
    ALL_STATUS_INT,
    PAID_STATUS_INT,
    FAILED_SYNC_STATUS_INT,
    PENDING_STATUS_INT,
    UNSPECIFIED_STATUS_INT,
    NOT_COMPLETED_STATUS_INT, STATUS_PAID_PENDING_SYNC_INT, COPY_LINK
} from "../../variables/coreConstants";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "../../components/Snackbar/Snackbar";
import {validateSession} from "../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import CopyIcon from "@material-ui/icons/FilterNone";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Muted from "../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import PaymentRequestForm from "./components/PaymentRequestForm";
import TableActions from "../../components/TableActions/TableActions";
import paymentRequestDetails from "../../models/PaymentRequestModel";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import {withRouter} from "react-router-dom";
import {statusController} from "../../services/statusService";
import {BASE_URL_CORE, PAYMENT_REQUEST} from "../../variables/apiConstants";
import TableCellDouble from "../../components/Table/TableCellDouble";
import ExportCsvForm from "./components/ExportCsvForm";
import PaymentRequestSyncForm from "./components/PaymentRequestSyncForm";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";



class PaymentRequest extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            currency: getCompanyCurrency(),
            openForm: false,
            confirmationForm: false,
            open: false,
            openExport: false,
            openDetail: false,
            isLoading: false,
            isLoadingMore: false,
            loadingDetail: false,
            isImageError: false,
            noMoreItems: false,
            data: [],
            currentPayment: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE_PAYMENTS,
            page: 0,
            count: 0,
            actionType: 0,
            status: {value: ALL_STATUS_INT, label: this.props.t('ALL_STATUS_TEXT')},
            statusOptions: [
                {value: ALL_STATUS_INT, label: this.props.t('ALL_STATUS_TEXT')},
                {value: PAID_STATUS_INT, label: this.props.t('STATUS_PAID')},
                {value: FAILED_SYNC_STATUS_INT, label: this.props.t('STATUS_SYNC_FAILED')},
                {value: PENDING_STATUS_INT, label: this.props.t('STATUS_PENDING')},
                {value: UNSPECIFIED_STATUS_INT, label: this.props.t('STATUS_UNSPECIFIED')},
                {value: NOT_COMPLETED_STATUS_INT, label: this.props.t('STATUS_NOT_COMPLETED')},
                {value: STATUS_PAID_PENDING_SYNC_INT, label: this.props.t('STATUS_PAID_PENDING_SYNC')}
            ],
            notificationMessage: '',
            copySuccess: false,
            notification: false,
            noItems: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount(): void {
        this.setState({ isLoading: true });
        this.request(PAYMENT_PAGINATION_PARAMETERS);
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true, page: 0 });
                    let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage, "status": this.state.status.value };
                    this.request(dataParameters);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };
    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    changeStatusSelect = status => {
        this.setState({ status });
        this.setState({ isLoading: true, page: 0 });
        let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage, "status": status.value };
        this.request(dataParameters);
    };

    openForm = (action, formData) => {
        paymentRequestDetails.setPaymentRequestDetail(formData);

        if ((action === 1 || action === DELETE) && formData.status !== STATUS_PENDING) {
            this.showNotification(this.props.t('CANNOT_UPDATE_PAYMENT_REQUEST_MSG'), "danger", NotificationErrorIcon, null);
        } else {
            if (action === 1 || 0) {
                this.setState({ open: true, actionType: action });
            } else if (action === COPY_LINK) {
                this.handleCopyToClipboard(formData);
            } else if (action === SYNC_NOW) {
                this.setState({ confirmationForm: true });

            } else if (action === SYNC_MANUALLY) {
                this.setState({ openForm: true });

            } else {
                let dataParameters = {
                    "paymentRequestId": formData.uuid
                };

                statusController(action, BASE_URL_CORE, PAYMENT_REQUEST, dataParameters, this, false);

            }
        }
    };

    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type) {
            this.setState({ isLoading: true, page: 0 });
            this.request(PAYMENT_PAGINATION_PARAMETERS);
        }
    }

    request(parameters) {
        getPaymentRequestByCompany(parameters)
            .then((response) => {
                let newData = response.data !== null ? response.data.map(r => {
                    r.amountPaidLabel = parseFloat(r.amountPaid) > 0 ? (nullController(r, 'currencyCode') + " " + setCurrencyValue(getRoundNumber(nullController(r, 'amountPaid')))) : null
                    return r;
                }) : [];

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredData,
                    newData,
                    "uuid",
                );

                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    noMoreItems: newData.length === 0,
                    data: paginatedData,
                    filteredData: paginatedData
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false, isLoadingMore: false } );
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }

    handleOpenForm = () => {
        paymentRequestDetails.cleanPaymentRequestDetail();
        this.setState({ open: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ open: false });
    };




    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(nullController(val, "link"));
            this.showNotification(this.props.t('LINK_COPIED_MSG'), "primary", InfoIcon, null);
        } catch (e) {
            console.log("Something went wrong");
        }
    };

    handleGoToDetails = (values) => {
        saveData("paymentRequest", values);
        this.props.history.push("payment-request-details/" + values.uuid);
    };


    handleOpenExport = () => {
        this.setState({ openExport: true });
    };
    handleCloseExport = () => {
        this.setState({ openExport: false });
    };



    handleConfirmSync = () => {
        this.setState({
            loadingDetail: true,
            confirmationForm: false
        }, () => {
            syncPaymentRequest(paymentRequestDetails.uuid)
                .then(response => {
                    this.setState({ loadingDetail: false }, () => {
                        if (isSuccessfulRequest(response)) {
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        } else {
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    });
                })
                .catch(error => {
                    this.setState({ loadingDetail: false });
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        });
    };

    handleLoadMore = () => {
        const { isLoadingMore, page, noMoreItems } = this.state;

        if (!isLoadingMore && !noMoreItems) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "status": this.state.status.value
                };
                this.request(dataParameters);
            });
        }
    };


    render() {
        const { classes, t } = this.props;
        const { actionType, isLoading, isLoadingMore, loadingDetail, filteredData, statusOptions, status } = this.state;


        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || loadingDetail || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'payment_requests'}
                        title={t('PAYMENT_REQUEST_TEXT')}
                        exportFileName={t('PAYMENT_REQUEST_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_ADD_REQUEST'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_CUSTOM_EXPORT'), function: this.handleOpenExport, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[
                            { value: status, data: statusOptions, function: this.changeStatusSelect, isLoading: isLoading, disabled: isLoading, type: "select"}
                        ]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.handleGoToDetails, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_MEMBER'), name: "memberExternalId", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_USER'), name: "user", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_AMOUNT_PAID'), name: "amountPaidLabel", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_PAYMENT_STATUS'), name: "status", isReadOnly: true, width: "*", cssClass: "", isCustomCell: true },
                            { label: t('TH_CREATED_BY'), name: "createdBy", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t('PAYMENT_REQUEST_TXT')}
                        <Muted>
                            {t('FORM_PAYMENT_REQUEST_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <PaymentRequestForm closeForm={this.closeForm} action={actionType}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openExport}
                    onClose={() => this.handleCloseExport()}
                    aria-labelledby="form-dialog-export"
                >
                    <DialogTitle id="form-dialog-export">
                        {t('EXPORT_PAYMENT_REQUEST_TITLE')}
                        <Muted>
                            {t('EXPORT_PAYMENT_REQUEST_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <ExportCsvForm closeForm={this.handleCloseExport}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openForm}
                    aria-labelledby="form-dialog-sync"
                >
                    <DialogTitle id="form-dialog-sync">
                        {t('SYNC_MANUALLY_PAYMENT_REQUEST_FORM_TITLE', { paymentName: paymentRequestDetails.name })}
                        <Muted>
                            {t('SYNC_MANUALLY_PAYMENT_REQUEST_FORM_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                         <PaymentRequestSyncForm uuid={paymentRequestDetails.uuid} closeForm={this.closeForm}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    open={this.state.confirmationForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('SYNC_PAYMENT_REQUEST_FORM_TITLE', { paymentName: paymentRequestDetails.name })}
                        <Muted>
                            {t('SYNC_PAYMENT_REQUEST_FORM_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.formContainer}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.rightPaper}>
                                        <Button
                                            color="white"
                                            className={classes.buttonSpacing + " " + classes.cancelButton}
                                            onClick={() => this.closeForm("confirmationForm", false, false)}
                                        >
                                            {t('BTN_CANCEL')}
                                        </Button>
                                        <Button
                                            color="primary"
                                            className={classes.buttonSpacing}
                                            onClick={() => this.handleConfirmSync()}
                                        >
                                            {t('BTN_SYNC')}
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

PaymentRequest.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(PaymentRequest)));
