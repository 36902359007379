import { getService, postService, putService } from "./api";
import modifierDetails from "../models/ModifierModel";
import {
    BASE_URL_CORE,
    MODIFIER_GROUP, MODIFIER_ITEM_ASSIGNMENTS, MODIFIER_PRODUCT_ASSIGNMENTS,
    MODIFIERS_GROUPS_BY_COMPANY
} from "../variables/apiConstants";
import productDetails from "../models/ProductModel";


export function getModifiersGroupsByCompany() {
    let url = BASE_URL_CORE + MODIFIERS_GROUPS_BY_COMPANY;
    return getService(url);
}

export function getModifierProductAssignments() {
    let url = BASE_URL_CORE + MODIFIER_PRODUCT_ASSIGNMENTS;
    let dataParameters = { "productId": productDetails.id };

    return postService(url, dataParameters);
}


export function addModifierGroup(parameters) {
    let url = BASE_URL_CORE + MODIFIER_GROUP;
    return postService(url, parameters);
}

export function editModifierGroup(parameters) {
    let url = BASE_URL_CORE + MODIFIER_GROUP + "/" + modifierDetails.id;
    return putService(url, parameters);
}


export function getModifierGroupInformation() {
    let url = BASE_URL_CORE + MODIFIER_GROUP + "/" + modifierDetails.id;

    return getService(url);
}

export function getAllModifierItemsByGroup() {
    let url = BASE_URL_CORE + MODIFIER_ITEM_ASSIGNMENTS + modifierDetails.id;

    return getService(url);
}