import {getRoundNumber, initialMomentDate, initialMomentOfDay} from "../utils/helpersFunctions";
import * as moment from "moment";

var paymentTemplateDetails = {
    uuid: '',
    name: '',
    noPaymentRequest: 0,
    status: null,
    averageAmount: 0.00,
    description: '',
    surchargeAmount: 0.00,
    surchargeTerms: '',
    discount: 0.00,
    surchargeFixed: 0.00,
    earlyDiscount: 0.00,
    earlyDiscountDays: 0,
    startDate: initialMomentOfDay(),
    items: [],
    members: [],
    membersAssignedFiltered: [],
    memberAssigned: [],
    membersRemoved: [],
    membersSelected: [],

    templateItemDetails : {
        uuid: "",
        name: "",
        description: "",
        amount: 0.00,
        surcharge: 0.00,
        discount: 0.00,
        tax: 0.00,
        expirationDate: initialMomentDate(),
    },


    setPaymentTemplateDetails : function (values) {
        try {
            this.uuid = values.uuid;
            this.name = values.name;
            this.noPaymentRequest = values.noPaymentRequests;
            this.surchargeAmount = getRoundNumber(values.surchargeAmount);
            this.surchargeFixed = 0.00;
            this.status = values.status;
            this.averageAmount = getRoundNumber(values.averageAmount);
            this.description = "" ;
            this.discount = 0.00;
            this.earlyDiscount = getRoundNumber(values.earlyDiscount);
            this.earlyDiscountDays = values.earlyDiscountDays;

            try {

                this.surchargeTerms = { value: values.surchargeTerms.id, label: values.surchargeTerms.name };

                let _date = new Date(values.startDate);
                var m = moment(); // Initial moment object
                var _moment = moment(_date);
                m.set(_moment.toObject());

                this.startDate = m;
            } catch (e) {
                this.startDate = initialMomentOfDay();
            }

        } catch (e) {}
    },

    setPaymentTemplateItemDetails : function (values) {
        try {
            this.templateItemDetails.uuid = values.uuid;
            this.templateItemDetails.name = values.name;
            this.templateItemDetails.description = values.description;
            this.templateItemDetails.amount = getRoundNumber(values.unitPrice);
            this.templateItemDetails.discount = getRoundNumber(values.discount);
            this.templateItemDetails.tax = getRoundNumber(values.tax);
            this.templateItemDetails.surcharge = getRoundNumber(values.surcharge);

            let _date = new Date(values.expirationDate);
            var m = moment(); // Initial moment object
            var _moment = moment(_date);
            m.set(_moment.toObject());

            this.templateItemDetails.expirationDate = m;
        } catch (e) {
            this.templateItemDetails.expirationDate = initialMomentDate();
        }
    },

    cleanTemplateItemDetails : function () {
        this.templateItemDetails.uuid = "";
        this.templateItemDetails.name = "";
        this.templateItemDetails.description = "";
        this.templateItemDetails.amount = 0.00;
        this.templateItemDetails.discount = 0.00;
        this.templateItemDetails.tax = 0.00;
        this.templateItemDetails.surcharge = 0.00;
        this.templateItemDetails.expirationDate = initialMomentDate();
    },

    cleanMemberAssignments : function () {
        this.members = [];
        this.membersAssignedFiltered = [];
        this.memberAssigned = [];
        this.membersRemoved = [];
        this.membersSelected = [];
    },

    cleanTemplateDetails : function () {
        this.uuid = "";
        this.name = "";
        this.noPaymentRequest = 0;
        this.surchargeAmount = 0.00;
        this.surchargeTerms = "";
        this.status = null;
        this.averageAmount = 0.00;
        this.description = '';
        this.surchargeFixed = 0.00;
        this.surchargeTerms = '';
        this.discount = 0.00;
        this.earlyDiscount = 0.00;
        this.earlyDiscountDays = 0;
        this.startDate = initialMomentOfDay();
    },

};

export default paymentTemplateDetails;