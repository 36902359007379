import {getService, postService, putService} from "./api";
import {
    BASE_URL_CORE,
    PAYMENT_REQUEST_TEMPLATES,
    TERMS_TEMPLATE_CONFIGURATION,
    PAYMENT_REQUEST_TEMPLATE,
    PAYMENT_TEMPLATE_DETAILS,
    ASSIGN_MEMBER_TEMPLATE,
    PAYMENT_TEMPLATE_ITEM,
    UPDATE_PAYMENT_TEMPLATE_ITEM,
    UPDATE_PAYMENT_TEMPLATE, UPDATE_PAYMENT_TEMPLATE_INFO, GROUP_PAYMENT_REQUESTS,
} from "../variables/apiConstants";


export function getPaymentRequestTemplatesByCompany() {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_TEMPLATES;

    return getService(url);
}

export function getPaymentTemplateDetail(templateId) {
    let url = BASE_URL_CORE + PAYMENT_TEMPLATE_DETAILS + templateId;

    return getService(url);
}

export function getTermsTemplateConfiguration() {
    let url = BASE_URL_CORE + TERMS_TEMPLATE_CONFIGURATION;

    return getService(url);
}

export function addPaymentRequestTemplate(parameters) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_TEMPLATE;

    return postService(url, parameters);
}

export function editPaymentRequestTemplate(parameters) {
    let url = BASE_URL_CORE + UPDATE_PAYMENT_TEMPLATE;

    return putService(url, parameters);
}

export function editTemplateInformation(parameters) {
    let url = BASE_URL_CORE + UPDATE_PAYMENT_TEMPLATE_INFO;

    return putService(url, parameters);
}

export function assignTemplateToMembers(parameters) {
    let url = BASE_URL_CORE + ASSIGN_MEMBER_TEMPLATE;

    return postService(url, parameters);
}


export function addPaymentTemplateItem(parameters) {
    let url = BASE_URL_CORE + PAYMENT_TEMPLATE_ITEM;

    return postService(url, parameters);
}

export function editPaymentTemplateItem(parameters) {
    let url = BASE_URL_CORE + UPDATE_PAYMENT_TEMPLATE_ITEM;

    return putService(url, parameters);
}

export function addPaymentRequestGroup(parameters) {
    let url = BASE_URL_CORE + GROUP_PAYMENT_REQUESTS;

    return postService(url, parameters);
}
