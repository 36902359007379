import React from "react";
import { withTranslation } from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { withRouter } from "react-router-dom";
import orderDetails from "../../models/OrderModel";
import {
    getOrderDetail,
    getOrdersByCompany,
    syncOrder,
} from "../../services/orderService";
import IconButton from "@material-ui/core/IconButton";
import {
    ROWS_PER_PAGE
} from "../../variables/apiConstants";
import { validateSession } from "../../services/api";
import {
    getRoundNumber,
    isSuccessfulRequest, mergeArrays,
    nullController,
    setCurrencyValue,
    verifyAccessImage,
} from "../../utils/helpersFunctions";
import OrderDetailsForm from "./components/OrderDetailsForm";
import { DialogTitle } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {
    DEFAULT_PAGINATION_PARAMETERS,
    NOTIFICATION_DURATION,
    SYNC_NOW,
} from "../../variables/coreConstants";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openOrder: false,
            isLoading: true,
            isLoadingOrder: false,
            isImageError: false,
            noItems: false,
            actionType: 0,
            ordersData: [],
            orderDetail: [],
            filteredOrders: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            count: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request(DEFAULT_PAGINATION_PARAMETERS);
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({
                        page: 0
                    });

                    let dataParameters = { "page": 0, "pageSize": this.state.rowsPerPage };
                    this.request(dataParameters);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleOpenForm = () => {
        orderDetails.cleanOrderDetails();
        this.setState({ openOrder: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openOrder: false });
        if (orderDetails.oldStatus !== orderDetails.status) {
            this.setState({ page: 0 });
            this.request(DEFAULT_PAGINATION_PARAMETERS);
        }
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value});
        if (type) {
            this.setState({ page: 0 });
            this.request(DEFAULT_PAGINATION_PARAMETERS);
        }
    }
    openForm = (formData) => {
        const { t } = this.props;

        this.setState({ isLoadingOrder: true });
        orderDetails.setOrderDetail(formData);
        getOrderDetail(formData.uuid)
            .then((response) => {
                if (response.data) {
                    this.setState({
                        orderDetail: response.data,
                    });
                    verifyAccessImage(response.data.photo, this);
                } else {
                    this.setState({
                        isLoadingOrder: false,
                        openOrder: false,
                    });
                    this.showNotification(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    isLoadingOrder: false,
                    openOrder: false,
                });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message
                            ? error.response.data.message
                            : t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon
                    );

                    validateSession(error.response, this.props.history);
                }
            });
    };

    handleActions = (action, formData) => {
        if (action === SYNC_NOW) {
            syncOrder(formData.uuid)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationSuccessIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(
                            this.props.t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                });
        }
    };


    request = (parameters) => {
        this.setState({ isLoading: true });

        getOrdersByCompany(parameters)
            .then((res) => {
                let newData = res.data.map(r => {
                    r.totalLabel = nullController(r, "currency") + " " + setCurrencyValue(getRoundNumber(nullController(r, "total")));
                    return r;
                });

                let paginatedData = this.state.page === 0 ? newData : mergeArrays(
                    this.state.filteredOrders,
                    newData,
                    "uuid"
                );


                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    noItems: newData.length === 0,
                    ordersData: paginatedData,
                    filteredOrders: paginatedData,
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false, isLoadingMore: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };

    goToActions = () => {
        //storeDetails.name = val.name;
        //console.log(val);
    };

    handleLoadMore = () => {
        const { isLoadingMore, page, noItems } = this.state;

        if (!isLoadingMore && !noItems) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "page": this.state.page,
                    "pageSize": this.state.rowsPerPage
                };
                this.request(dataParameters);
            });
        }
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { orderDetail, isLoadingOrder, isImageError, filteredOrders, isLoadingMore, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);


        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingOrder || isLoadingMore || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'orders'}
                        title={t('ORDERS_TEXT')}
                        exportFileName={t('ORDERS_TEXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredOrders}
                        actions={[
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('MEMBER_NAME_TEXT'), name: "memberName", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_MEMBER_EXTERNAL_ID'), name: "memberExternalId", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('ORDER_DETAILS_TEXT'), name: "orderDetails", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DELIVERY_ZONE'), name: "zone", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "orderStatus", isReadOnly: true, width: 130, isCustomCell: true },
                            { label: t('TH_IDENTIFIER'), name: "identifier", onSelect: this.openForm, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_DATE'), name: "createdDateOrder", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_TOTAL'), name: "totalLabel", isReadOnly: true, width: "*", cssClass: "", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.handleActions, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openOrder}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            <div className={classes.orderDetailActions}>
                                <div className={classes.actionCloseContainer}>
                                    <Tooltip
                                        id="tooltip-close"
                                        title={t("BTN_CLOSE")}
                                        placement="right-start"
                                    >
                                        <IconButton
                                            size={"small"}
                                            onClick={this.handleCloseForm}
                                            className={classes.formIcons}
                                        >
                                            <CloseIcon
                                                className={
                                                    classes.iconButtonRed
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div
                                    className={
                                        classes.orderDetailIdentifierDialog
                                    }
                                >
                                    {"#" + orderDetail.identifier}
                                </div>
                                <div className={classes.actionPrintContainer}>
                                    <div
                                        className={
                                            classes.rightPaper +
                                            " " +
                                            classes.printContainer
                                        }
                                    >
                                    </div>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <OrderDetailsForm
                                data={orderDetail}
                                isImageError={isImageError}
                                closeForm={this.closeForm}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Orders.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(Orders))
);
