import {
    BASE_URL_CORE,
    DISCOUNT,
    DISCOUNT_CONFIGURATION,
    DISCOUNT_MEMBERSHIP_ASSIGNMENT,
    DISCOUNT_PRODUCT_ASSIGNMENT,
    DISCOUNT_STORE_ASSIGNMENT,
    DISCOUNTS_BY_COMPANY,
    DISCOUNTS_DETAILS,
    DISCOUNTS_TYPE_VALUE,
    UNASSIGNED_PRODUCTS_DISCOUNT,
    UNASSIGNED_STORES_DISCOUNT
} from "../variables/apiConstants";
import {getService, postService, putService} from "./api";


export function getDiscountByCompany() {
    let url = BASE_URL_CORE + DISCOUNTS_BY_COMPANY;
    return getService(url);
}

export function getDiscountTypeValue() {
    let url = BASE_URL_CORE + DISCOUNTS_TYPE_VALUE;
    return getService(url);
}

export function addDiscount(parameters) {
    let url = BASE_URL_CORE + DISCOUNT;
    return postService(url, parameters);
}

export function editDiscount(parameters, discountId) {
    let url = BASE_URL_CORE + DISCOUNT + "/" + discountId;
    return putService(url, parameters);
}

export function updateDiscountConfiguration(parameters) {
    let url = BASE_URL_CORE + DISCOUNT_CONFIGURATION;
    return putService(url, parameters);
}

export function getDiscountDetails(parameters) {
    let url = BASE_URL_CORE + DISCOUNTS_DETAILS;
    return postService(url, parameters);
}

export function addDiscountMembershipAssigment(parameters) {
    let url = BASE_URL_CORE + DISCOUNT_MEMBERSHIP_ASSIGNMENT;
    return postService(url, parameters);
}

export function addDiscountProductAssigment(parameters) {
    let url = BASE_URL_CORE + DISCOUNT_PRODUCT_ASSIGNMENT;
    return postService(url, parameters);
}

export function addDiscountStoreAssigment(parameters) {
    let url = BASE_URL_CORE + DISCOUNT_STORE_ASSIGNMENT;
    return postService(url, parameters);
}

export function getProductsAvailableToDiscount(discountId) {
    let url = BASE_URL_CORE + UNASSIGNED_PRODUCTS_DISCOUNT + discountId;
    return getService(url);
}

export function getStoresAvailableToDiscount(discountId) {
    let url = BASE_URL_CORE + UNASSIGNED_STORES_DISCOUNT + discountId;
    return getService(url);
}