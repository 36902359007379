import React from "react";
import {withRouter} from "react-router-dom";
import {withNotification} from "../../components/HOC/Notification/NotificationHOC";
import {withTranslation} from "react-i18next";
import DirectDebitsScreen from "./DirectDebitsScreen";
import {getDirectDebits, loginDirectDebit, refreshPrivateServiceToken} from "../../services/directDebitService";
// import {validateSession} from "../../services/api";
import {getData, isEmpty, mergeArrays, saveData} from "../../utils/helpersFunctions";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {NOTIFICATION_DURATION_LARGE} from "../../variables/coreConstants";


class DirectDebitsController extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            metadata: null,
            data: [],
            filteredData: [],
            openForm: false,
            rowsPerPage: 50,
            page: 0,
            lastPage: false,
            totalElements: 0,
            totalPages: 0,
            retryDebitToken: 0,
            isLoadingMore: false
        }
    }


    componentDidMount() {
        const { t, routeProps } =  this.props;

        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        if (isEmpty(getData("directDebitAuth"))) {
            this.getAuthToken();
        } else {
            this.request(false);
        }
    }


    getAuthToken = () => {
        const { t, showNotificationMessage } = this.props;

        this.setState({ isRequesting: true }, () => {
            loginDirectDebit().then(r => {
                if (r.status === 200 && r.data.success === true) {
                    saveData("directDebitAuth", r.headers.authorization);
                    this.request(false);

                } else {
                    this.setState({ isLoading: false, isRequesting: false });
                    showNotificationMessage(t("INVALID_SESSION"), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            }).catch(e => {
                this.setState({ isLoading: false, isRequesting: false });
                if (!isEmpty(e.response)) {
                    showNotificationMessage(e.response.data.message, 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                } else {
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            });
        });
    }

    search = (e) => {
        let newData = this.state.data;
        try {
            newData = newData.filter(function(item) {
                return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });

            this.setState({ filteredData: newData });

        } catch (e) {
            console.log("Something went wrong.");
        }
    };

    handleLoadMore = () => {
        const { isLoadingMore, metadata } = this.state;

        if (!isLoadingMore && !isEmpty(metadata) && metadata.hasMore) {
        // if (!isLoadingMore) {
            this.setState({
                isLoadingMore: true
            }, () => {
                // console.log("Loading more...");
                this.request(true);
            });
        }
    }


    request = (loadMore) => {
        const { showNotificationMessage, t } =  this.props;
        this.setState({ isLoading: !loadMore, isLoadingMore: loadMore, retryDebitToken: this.state.retryDebitToken + 1 }, async () => {
            let dataParameters = {
                "lastItem": !isEmpty(this.state.metadata) ? this.state.metadata.lastItem : null
            };

            let lastId = this.state.data.length

            getDirectDebits(dataParameters).then(response => {

                let refactorData = response.data.items.map((r, k) => {
                    lastId++;
                    r["recordNum"] = lastId;
                    r.createdDate = !isEmpty(r.createdDate) ? new Date(parseInt(r.createdDate)).toLocaleString() : "---";
                    r.paymentExpiringBefore = !isEmpty(r.paymentExpiringBefore) ? new Date(parseInt(r.paymentExpiringBefore)).toLocaleString() : "---";
                    return r;
                });

                let paginatedData = mergeArrays(
                    this.state.data,
                    refactorData,
                    "id",
                    (a,b) => b["createdDate"] - a["createdDate"]
                );

                this.setState({
                    metadata: response.data.metadata,
                    data: paginatedData,
                    filteredData: paginatedData,
                    retryDebitToken: 0
                }, () => {
                    this.setState({
                        isLoading: false,
                        isLoadingMore: false
                    });
                });
            }).catch(e => {
                if (!isEmpty(e.response)) {
                    if (this.state.retryDebitToken <= 2) {
                        refreshPrivateServiceToken(e.response, this.request, (errorResponse) => {
                            this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                            showNotificationMessage(errorResponse.data.error.message, 'danger', NotificationErrorIcon,
                                NOTIFICATION_DURATION_LARGE, null);
                        });
                    } else {
                        this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                        showNotificationMessage(e.response.data.error.message, 'danger', NotificationErrorIcon,
                            NOTIFICATION_DURATION_LARGE, null);
                    }
                } else {
                    this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            });
        });
    };


    onChangePage = (event, newPage) => {
        this.setState({ page: newPage})
    };

    onChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value
        });
    };


    handleSelectRow = (row) => {
        saveData("debit", row);
        this.props.history.push("/admin/debit/" + row.id);
    };



    render() {
        return <DirectDebitsScreen
            states={this.state}
            onAdd={() => { this.props.history.push("/admin/debit-form/new") }}
            onSearch={this.search}
            onLoadMore={this.handleLoadMore}
            onSelectRow={this.handleSelectRow}
            onTableAction={() => {return false}}
            handleChangeRowsPerPage={this.onChangeRowsPerPage}
            handleChangePage={this.onChangePage}
            handleFirstPage={() => {return false}}
            handleNextPage={() => {return false}}
            handlePreviousPage={() => {return false}}
            handleLastPage={() => {return false}}
        />;
    }
}

export default withRouter(withNotification(withTranslation()(DirectDebitsController)));
