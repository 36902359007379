
var zoneGroupDetails = {
    uuid: "",
    name: "",
    externalId: "",
    description: "",


    setZoneGroupDetail : function (values) {
        this.name = values.name;
        this.uuid = values.uuid;
        this.externalId = values.externalId;
        this.description = values.description;
    },
    cleanZoneGroupDetails : function () {
        this.name = "";
        this.uuid = "";
        this.externalId = "";
        this.description = "";
    },
};

export default zoneGroupDetails;