import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core icons
import MenuIcon from "@material-ui/icons/Assignment";
import ExternalIcon from "@material-ui/icons/LocalOffer"
import DescriptionIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar.jsx";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent,
    enableComponent, formatDate, getCompanyCurrency, getValueFromCurrencyMask,
    isSuccessfulCreate, isSuccessfulRequest,
    selectValidation, setCurrencyValue
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION, STATUS_ASSIGNED} from "../../../variables/coreConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import CurrencyInput from "../../../components/CustomInput/CurrencyInput";
import Datetime from "react-datetime";
import {
    addPaymentRequestGroup,
    addPaymentRequestTemplate,
    editPaymentRequestTemplate,
    getTermsTemplateConfiguration
} from "../../../services/paymentTemplateService";
import paymentTemplateDetails from "../../../models/PaymentTemplateModel";
import {getStoresAvailable} from "../../../services/storeService";



class PaymentTemplateGeneratorForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name: paymentTemplateDetails.name,
            description: paymentTemplateDetails.description,
            noPaymentRequests: paymentTemplateDetails.noPaymentRequest,
            paymentRequestAmountMask: setCurrencyValue(paymentTemplateDetails.averageAmount),
            paymentRequestAmount: paymentTemplateDetails.averageAmount,
            startDate: paymentTemplateDetails.startDate,
            paymentRequestLabel: "",
            dueDateSurchargeMask: setCurrencyValue(paymentTemplateDetails.surchargeAmount),
            dueDateSurcharge: paymentTemplateDetails.surchargeAmount,
            discountMask: setCurrencyValue(paymentTemplateDetails.discount),
            discount: paymentTemplateDetails.discount,
            surcharge: paymentTemplateDetails.surchargeFixed,
            surchargeMask: setCurrencyValue(paymentTemplateDetails.surchargeFixed),
            earlyDiscountMask: setCurrencyValue(paymentTemplateDetails.earlyDiscount),
            earlyDiscount: paymentTemplateDetails.earlyDiscount,
            earlyDiscountExpiration: paymentTemplateDetails.earlyDiscountDays,
            monthlyExpirationDay: '',
            surchargeTerm: paymentTemplateDetails.surchargeTerms,
            monthlyOptions: [],
            termOptions: [],
            loadingStore: false,
            storeOptions: [],
            store: '',
            notificationMessage: '',
            isLoading: false,
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.generateTemplate = this.generateTemplate.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    cleanData() {
        this.setState({
            name: '',
            description: '',
            noPaymentRequests: 0,
            paymentRequestAmountMask: setCurrencyValue(0.00),
            paymentRequestAmount: 0,
            paymentRequestLabel: '',
            dueDateSurchargeMask: setCurrencyValue(0.00),
            dueDateSurcharge: 0,
            discountMask: setCurrencyValue(0.00),
            surchargeMask: setCurrencyValue(0.00),
            discount: 0,
            surchargeFixed: 0,
            earlyDiscountMask: setCurrencyValue(0.00),
            earlyDiscount: 0,
            earlyDiscountExpiration: 0,
            monthlyExpirationDay: '',
            surchargeTerm: '',
            store: '',
        });
    }


    changeStoreSelect = (store) => {
        this.setState({ store });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm(this.props.isTemplate ? "openTemplate" : "openPaymentGroup", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return }
        this.setState({ notification: false });
    };

    handleStartTimeChange = (date) => {
        this.setState({ startDate: date });
    };

    changeExpirationDaySelect = (monthlyExpirationDay) => {
        this.setState({ monthlyExpirationDay });
    };

    changeTermsSelect = (surchargeTerm) => {
        this.setState({ surchargeTerm });
    };


    disableFields = () => {
        return paymentTemplateDetails.status === STATUS_ASSIGNED && this.props.action;
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };


    // Method that request selects data
    request() {
        getTermsTemplateConfiguration()
            .then((response) => {
                let values = [];
                if (response.data !== null) {
                    try {
                        values = response.data.map(val => {
                            return { value: val.uuid, label: val.name };
                        });
                    } catch (e) {}
                }

                this.setState({
                    termOptions: values,
                    surchargeTerm: values[0],
                    isLoading: false
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false} );
                if (error.response)
                    validateSession(error.response, this.props.history);
            });

        getStoresAvailable()
            .then((response) => {
                enableComponent(this, "disableField");
                if (response.data !== null) {
                    let values = response.data.map(val => {
                        return { value: val.id, label: val.name };
                    });

                    this.setState({
                        storeOptions: values,
                        loadingStore: false
                    });
                }
            })
            .catch((error) => {
                this.setState({ loadingStore: false });
                enableComponent(this, "disableField");
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }

    generateTemplate() {
        const { t } = this.props;
        const { name, startDate, surchargeTerm, description, paymentRequestLabel, noPaymentRequests,
            paymentRequestAmount,dueDateSurcharge, discount,earlyDiscount, earlyDiscountExpiration, surcharge} = this.state;

        if (name === "" || selectValidation(surchargeTerm) || paymentRequestLabel === "") {
            this.showNotification(t('TEMPLATE_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (noPaymentRequests <= 0 || paymentRequestAmount <= 0 || dueDateSurcharge < 0 || discount < 0 || surcharge < 0
                || earlyDiscount < 0 || earlyDiscountExpiration < 0) {
                this.showNotification(t('INVALID_TEMPLATE_CONFIGURATION'), "danger", NotificationErrorIcon, null);

            } else {
                let dataParameters = {
                    "uuid": "",
                    "name": name,
                    "paymentRequestLabel": paymentRequestLabel,
                    "description": description,
                    "paymentRequestAmount": paymentRequestAmount,
                    "paymentRequestQuantity": noPaymentRequests,
                    "startDate": formatDate(new Date(startDate.valueOf()).setHours(23,59, 0)),
                    "dueDateSurcharge": dueDateSurcharge,
                    "surcharge": surcharge,
                    "tax": 0,
                    "discount": discount,
                    "earlyDiscount": earlyDiscount,
                    "earlyDiscountDays": earlyDiscountExpiration,
                    "termsTemplateId": surchargeTerm.value
                };


                disableComponent(this, "disableField");
                addPaymentRequestTemplate(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            if (error.response)
                                validateSession(error.response, this.props.history);
                        });
            }
        }
    }

    generatePayments() {
        const { t, memberId } = this.props;
        const { name, startDate, description, paymentRequestLabel, noPaymentRequests,
            paymentRequestAmount, discount, surcharge, store} = this.state;

        if (name === "" || paymentRequestLabel === "" || selectValidation(store)) {
            this.showNotification(t('PAYMENT_GROUP_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (noPaymentRequests <= 0 || paymentRequestAmount <= 0 || discount < 0 || surcharge < 0) {
                this.showNotification(t('INVALID_PAYMENT_GROUP_CONFIGURATION'), "danger", NotificationErrorIcon, null);

            } else {
                let dataParameters = {
                    "name": name,
                    "storeId": store.value,
                    "memberId": memberId,
                    "paymentRequestLabel": paymentRequestLabel,
                    "description": description,
                    "paymentRequestAmount": paymentRequestAmount,
                    "paymentRequestQuantity": noPaymentRequests,
                    "startDate": formatDate(new Date(startDate.valueOf()).setHours(23,59, 0)),
                    "surcharge": surcharge,
                    "tax": 0,
                    "discount": discount
                };


                disableComponent(this, "disableField");
                addPaymentRequestGroup(dataParameters)
                        .then((response) => {
                            if (isSuccessfulCreate(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            if (error.response)
                                validateSession(error.response, this.props.history);
                        });
            }
        }
    }


    regenerateTemplate() {
        const { t } = this.props;
        const { name, startDate, surchargeTerm, description, paymentRequestLabel, noPaymentRequests, surcharge,
            paymentRequestAmount,dueDateSurcharge, discount,earlyDiscount, earlyDiscountExpiration} = this.state;

        if (name === "" || selectValidation(surchargeTerm) || paymentRequestLabel === "") {
            this.showNotification(t('TEMPLATE_FIELDS_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            if (noPaymentRequests <= 0 || paymentRequestAmount <= 0 || dueDateSurcharge < 0 || discount < 0 || surcharge < 0
                || earlyDiscount < 0 || earlyDiscountExpiration < 0) {
                this.showNotification(t('INVALID_TEMPLATE_CONFIGURATION'), "danger", NotificationErrorIcon, null);

            } else {
                let dataParameters = {
                    "uuid": paymentTemplateDetails.uuid,
                    "name": name,
                    "paymentRequestLabel": paymentRequestLabel,
                    "description": description,
                    "paymentRequestAmount": paymentRequestAmount,
                    "paymentRequestQuantity": noPaymentRequests,
                    "startDate": formatDate(new Date(startDate.valueOf()).setHours(23,59, 0)),
                    "dueDateSurcharge": dueDateSurcharge,
                    "tax": 0,
                    "surcharge": surcharge,
                    "discount": discount,
                    "earlyDiscount": earlyDiscount,
                    "earlyDiscountDays": earlyDiscountExpiration,
                    "termsTemplateId": surchargeTerm.value
                };


                disableComponent(this, "disableField");
                editPaymentRequestTemplate(dataParameters)
                        .then((response) => {
                            if (isSuccessfulRequest(response)) {
                                this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                                this.cleanData();

                            } else {
                                enableComponent(this, "disableField");
                                this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            }
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            if (error.response)
                                validateSession(error.response, this.props.history);
                        });
            }
        }
    }


    render() {
        const { classes, t, action, isTemplate } = this.props;

        const GenerateButton = (( ) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={action ? () => this.regenerateTemplate() : () => this.generateTemplate()}
                disabled={this.state.disableField}
            >
                {action ? paymentTemplateDetails.status === STATUS_ASSIGNED ? t('BTN_SAVE_CHANGES') : t('BTN_REGENERATE') : t('BTN_GENERATE')}
            </Button>
        ));

        const GeneratePaymentsButton = (( ) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.generatePayments()}
                disabled={this.state.disableField}
            >
                {t('BTN_GENERATE')}
            </Button>
        ));

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.name}
                            onChange={this.handleChangeValues}
                            labelText={t('NAME_TEXT')}
                            id="name"
                            name="name"
                            disabled={this.disableFields()}
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <MenuIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <div className={classes.timerContainer} style={{marginBottom: 15}}>
                            <InputLabel className={classes.selectTitle}>
                                {t('INITIAL_DATE_TXT')}
                            </InputLabel>
                            <Datetime
                                value={this.state.startDate}
                                onChange={this.handleStartTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{disabled: this.disableFields()}}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={false} sm={2} md={2}/>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            disabled={this.disableFields()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            value={this.state.noPaymentRequests}
                            onChange={this.handleChangeValues}
                            labelText={t('TH_NO_PAYMENT_REQUESTS')}
                            id="noPaymentRequests"
                            name="noPaymentRequests"
                            type={"number"}
                            disabled={this.disableFields()}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.paymentRequestAmountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('PAYMENT_REQUEST_AMOUNT_TXT')}
                            id="paymentRequestAmountMask"
                            name="paymentRequestAmount"
                            disabled={this.disableFields()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            value={this.state.paymentRequestLabel}
                            onChange={this.handleChangeValues}
                            labelText={t('PAYMENT_REQUEST_LABEL_TXT')}
                            id="paymentRequestLabel"
                            name="paymentRequestLabel"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <ExternalIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.surchargeMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('SURCHARGE_TEXT')}
                            id="surchargeMask"
                            name="surcharge"
                            disabled={this.disableFields()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CurrencyInput
                            value={this.state.discountMask}
                            onChange={this.handleCurrencyValue}
                            labelText={t('DISCOUNT_TEXT')}
                            id="discountMask"
                            name="discount"
                            disabled={this.disableFields()}
                            formControlProps={{
                                fullWidth: true
                            }}
                            currencySymbol={getCompanyCurrency()}
                        />
                    </GridItem>
                    {
                        isTemplate ? (
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={5} md={5}>
                                        <div className={classes.subtitleContent}>
                                            <h5 style={{color: 'black', marginTop: 5, marginBottom: 0}}>
                                                {t('DUE_DATE_CALCULATIONS_TXT')}
                                            </h5>
                                        </div>
                                        <CurrencyInput
                                            value={this.state.dueDateSurchargeMask}
                                            onChange={this.handleCurrencyValue}
                                            labelText={t('SURCHARGE_DESCRIPTION_TXT')}
                                            id="dueDateSurchargeMask"
                                            name="dueDateSurcharge"
                                            disabled={this.disableFields()}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            currencySymbol={getCompanyCurrency()}
                                        />
                                    </GridItem>
                                    <GridItem xs={false} sm={1} md={1}/>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div className={classes.selectControl} style={{marginTop: 55}}>
                                            <InputLabel htmlFor="terms" className={classes.selectTitle}>
                                                {t('SURCHARGE_TERMS_TXT')}
                                            </InputLabel>
                                            <Select
                                                value={this.state.surchargeTerm}
                                                onChange={this.changeTermsSelect}
                                                isLoading={this.state.isLoading}
                                                isDisabled={this.state.isLoading || this.disableFields()}
                                                options={this.state.termOptions}
                                                name="surchargeTerm"
                                                classNamePrefix="terms"
                                                placeholder={t('SELECT_TEXT')}
                                                closeMenuOnSelect={true}
                                                maxMenuHeight={80}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CurrencyInput
                                            value={this.state.earlyDiscountMask}
                                            onChange={this.handleCurrencyValue}
                                            labelText={t('EARLY_DISCOUNT_TEXT')}
                                            id="earlyDiscountMask"
                                            name="earlyDiscount"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            currencySymbol={getCompanyCurrency()}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <CustomInput
                                            value={this.state.earlyDiscountExpiration}
                                            onChange={this.handleChangeValues}
                                            labelText={t('EARLY_DISCOUNT_BEFORE_DAYS_TEXT')}
                                            id="earlyDiscountExpiration"
                                            name="earlyDiscountExpiration"
                                            type={"number"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        ) : (
                            <GridItem xs={12} sm={6} md={6}>
                                <div className={classes.selectControl}>
                                    <InputLabel htmlFor="store" className={classes.selectTitle}>
                                        {t('STORE_TEXT')}
                                    </InputLabel>
                                    <Select
                                        value={this.state.store}
                                        onChange={this.changeStoreSelect}
                                        options={this.state.storeOptions}
                                        placeholder={t('SELECT_TEXT')}
                                        closeMenuOnSelect={true}
                                        maxMenuHeight={160}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </div>
                            </GridItem>
                        )
                    }
                    {
                        isTemplate ? null : (
                            <GridItem xs={12} sm={6} md={6}>
                                <div className={classes.selectControl} style={{marginTop: 0}}>
                                    <InputLabel htmlFor="terms" className={classes.selectTitle}>
                                        {t('PERIOD_TXT')}
                                    </InputLabel>
                                    <Select
                                        value={this.state.surchargeTerm}
                                        onChange={this.changeTermsSelect}
                                        isLoading={this.state.isLoading}
                                        isDisabled={this.state.isLoading || this.disableFields()}
                                        options={this.state.termOptions}
                                        name="surchargeTerm"
                                        classNamePrefix="terms"
                                        placeholder={t('SELECT_TEXT')}
                                        closeMenuOnSelect={true}
                                        maxMenuHeight={80}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </div>
                            </GridItem>
                        )
                    }
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm(isTemplate ? "openTemplate" : "openPaymentGroup", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {
                                isTemplate ? <GenerateButton/> : <GeneratePaymentsButton/>
                            }
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

PaymentTemplateGeneratorForm.propTypes = {
    classes: PropTypes.object,
    closeForm: PropTypes.func,
    isTemplate: PropTypes.bool,
    memberId: PropTypes.string.isRequired,
};

export default withRouter(withStyles(formStyle)(withTranslation()(PaymentTemplateGeneratorForm)));
