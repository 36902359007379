/*
* Ginih Team
* Created by Marlon On February 17, 2020
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    PRODUCT_DETAILS_TABLE_ACTIONS,
    ROWS_PER_PAGE_DETAILS, ROWS_PER_PAGE_OPTIONS,
} from "../../../variables/coreConstants";
import TableBody from "@material-ui/core/TableBody";
import {nullController} from "../../../utils/helpersFunctions";
import {withTranslation} from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";


class MenuCategoriesDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            categoryData: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
    }


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };



    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const categoryData = this.reorder(
            this.props.component.state.categoryData,
            result.source.index,
            result.destination.index
        );

        this.props.component.setState({
            categoryData
        });
    }



    render() {
        const { classes, component, t, data } = this.props;
        const { page, rowsPerPage, categoryData } = this.state;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={component.isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'catalog_category_assignments'}
                        title={t('CATEGORIES_TEXT')}
                        exportFileName={t('CATEGORIES_TEXT')}
                        data={data}
                        actions={[
                            { label: t('BTN_ORDER_CATEGORIES'), function: component.openCategoryForm, isLoading: false, disabled:data.length <= 1, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_PRODUCT_ASSIGNED'), name: "productQuantity", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ORDER'), name: "priority", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: component.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}/>
                </GridItem>
            </GridContainer>
        );
    }
}

MenuCategoriesDetails.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    data: PropTypes.array,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(MenuCategoriesDetails)));
