import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import Button from "../../../../components/CustomButtons/Button";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Styles
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../../variables/coreConstants";
import {
    getData, isEmpty,
    mergeArrays
} from "../../../../utils/helpersFunctions";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import {withTranslation} from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getDirectDebitResult} from "../../../../services/directDebitService";
import {CSVLink} from "react-csv";


class ExportDebitExecutionResult extends React.Component {

    csvLink = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            metadata: props.metadata,
            headers: [
                { label: props.t('MEMBER_NAME_TEXT'), key: "memberName" },
                { label: props.t('TH_MEMBER_EXTERNAL_ID'), key: "memberExternalId" },
                { label: props.t('TH_PAYMENT_METHOD'), key: "paymentMethod" },
                // { label: props.t('TH_PAYMENT_METHOD_STATUS'), key: "currencySymbol" },
                { label: props.t('TH_PAYMENT_NAME'), key: "paymentName" },
                { label: props.t('TH_PAYMENT_EXTERNAL_ID'), key: "paymentExternalId" },
                { label: props.t('TH_AMOUNT'), key: "amountFormatted" },
                { label: props.t('TH_PAYMENT_STATUS'), key: "paymentStatus" },
                { label: props.t('TH_PAYMENT_REASON'), key: "paymentStatusMessage" },
                { label: props.t('TH_SYNC_STATUS'), key: "syncStatus" },
                { label: props.t('TH_SYNC_REASON'), key: "syncStatusMessage" }
            ],
            reportName: "",
            dateType: 0,
            csvDownloadRef: null,
            isLoading: false,
            showError: false,
            errorMessage: '',
            disableField: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }


    componentDidMount() {
        const { debitId, jobId, t } = this.props;
        const debitExecution = getData('debitExecution');

        try {
            if (!isEmpty(this.state.metadata)) {
                this.setState({
                    isLoading: true
                }, async () => {
                    while (!isEmpty(this.state.metadata) && this.state.metadata.hasMore) {
                        let dataParameters = {
                            "debitDefinitionId": debitId,
                            "jobId": jobId,
                            "lastItem": this.state.metadata.lastItem
                        };

                        await new Promise((resolve) => {
                            getDirectDebitResult(dataParameters)
                                .then(response => {
                                    if (!isEmpty(response) && !isEmpty(response.data)) {
                                        const { payments, metadata } = response.data;
                                        let data = this.props.prepareData(payments);
                                        let paginatedData = mergeArrays(this.state.data, data, "id", null);

                                        // console.log(this.state.metadata.lastItem["Id"]["S"]);
                                        // console.log(metadata.lastItem["Id"]["S"]);
                                        // console.log("########################");
                                        if (metadata.hasMore) {
                                            if (this.state.metadata.lastItem["Id"]["S"] !== metadata.lastItem["Id"]["S"]) {
                                                this.setState({
                                                    data: paginatedData,
                                                    metadata: metadata
                                                }, () => resolve(true));
                                            } else {
                                                this.setState({ showError: true, errorMessage: t('COULD_NOT_GET_DATA'), data: [], metadata: null }, ()=> resolve(false));
                                            }
                                        } else {
                                            this.setState({
                                                data: paginatedData,
                                                metadata: metadata
                                            }, () => resolve(true));
                                        }
                                    } else {
                                        this.setState({ showError: true, errorMessage: t('MISSING_RESPONSE_DATA'), data: [], metadata: null }, ()=> resolve(false));
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.setState({ showError: true, errorMessage: t('SOMETHING_WENT_WRONG'), data: [], metadata: null }, ()=> resolve(false));
                                });
                        });
                    }

                    let fileParameters = {
                        debit: !isEmpty(debitExecution) ? debitExecution.debitName : "",
                        executionDate: !isEmpty(debitExecution) && !isEmpty(debitExecution.executionDate) ? new Date(parseInt(debitExecution.executionDate)).toDateString() : ""
                    };

                    this.setState({
                        reportName: t('DEBIT_EXECUTION_RESULT_FILE_NAME', fileParameters)
                    }, () => {
                        if (this.state.showError) {
                            this.setState({ isLoading: false }, () => {
                                this.props.showError(this.state.errorMessage);
                            });
                        } else {
                            if (this.state.data.length > 0) {
                                window.setTimeout(
                                    function() {
                                        if (this.csvLink !== null && this.csvLink !== undefined) {
                                            this.csvLink.link.click();
                                            this.setState({ isLoading: false }, () => {
                                                this.props.closeForm("openExport", false, false);
                                            });
                                        }
                                    }.bind(this),
                                    1000
                                );

                            } else {
                                this.setState({ isLoading: false }, () => {
                                    this.props.showError(t('THERE_ARE_NOT_RECORDS'));
                                });
                            }
                        }
                    });
                });
            } else {
                // empty values
                this.setState({ isLoading: false, data: [], metadata: null }, () => {
                    this.props.showError(t('THERE_ARE_NOT_RECORDS'));
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ isLoading: false, data: [], metadata: null }, () =>{
                this.props.showError(t('SOMETHING_WENT_WRONG'))
            });
        }
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openExport", false, false);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };



    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <Backdrop className={classes.backdrop} open={this.state.isLoading} >
                        <CircularProgress color="inherit" />
                        {/*<label style={{color: "white", marginTop: 10, fontSize: "16px"}}>{t('PREPARING_FILE')}</label>*/}
                    </Backdrop>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <div className={classes.hidden}>
                                <CSVLink
                                    data={this.state.data}
                                    headers={this.state.headers}
                                    separator={","}
                                    className="hidden"
                                    ref={(r) => this.csvLink = r}
                                    filename={this.state.reportName}>
                                    {t('BTN_GENERATE_FILE')}
                                </CSVLink>
                            </div>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openExport", false, false)}
                            >
                                {t('BTN_CLOSE')}
                            </Button>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ExportDebitExecutionResult.propTypes = {
    classes: PropTypes.object,
    debitId: PropTypes.string,
    jobId: PropTypes.string,
    data: PropTypes.array,
    metadata: PropTypes.object,
    closeForm: PropTypes.func,
    showError: PropTypes.func,
    prepareData: PropTypes.func
};

export default withRouter(withStyles(formStyle)(withTranslation()(ExportDebitExecutionResult)));