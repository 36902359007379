import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import StoreForm from "./components/StoreForm";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import storeDetails from "../../models/StoreModel";
import {
    formTitles,
} from "../../utils/statusHelpers";
import { withRouter } from "react-router-dom";
import { getStores } from "../../services/storeService";
import {
    ENTITY_STORE,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
} from "../../variables/coreConstants";
import { saveData } from "../../utils/helpersFunctions";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import StoreImageUpload from "./components/StoreImageUpload";
import { withTranslation } from "react-i18next";
import scheduleDetails from "../../models/ScheduleModel";
import { getStatus, statusController } from "../../services/statusService";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import Snackbar from "../../components/Snackbar/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Stores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openStore: false,
            isLoading: true,
            storeCompleted: false,
            actionType: 0,
            storesData: [],
            filteredStores: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        storeDetails.cleanStoreDetails();
        scheduleDetails.cleanScheduleDetails();
        this.setState({
            openStore: true,
            actionType: 0,
            storeCompleted: false,
        });
    };
    handleCloseForm = () => {
        this.setState({ openStore: false });
    };
    closeForm(state, value, type) {
        this.setState({
            [state]: value,
        });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        storeDetails.setStoreDetail(formData);

        if (action === 1 || action === 0) {
            scheduleDetails.cleanScheduleDetails();
            this.setState({
                openStore: true,
                actionType: action,
                storeCompleted: false,
            });
        } else {
            let status = getStatus(action);

            if (status !== null) {
                let dataParameters = {
                    entity: ENTITY_STORE,
                    id: storeDetails.id,
                    status: status,
                };

                statusController(
                    action,
                    null,
                    null,
                    dataParameters,
                    this,
                    true
                );
            }
        }
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.closeForm("openStore", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });

        getStores()
            .then((res) => {
                this.setState({
                    storesData: res.data,
                    filteredStores: res.data,
                    isLoading: false,
                });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };



    goToActions = (val) => {
        saveData("store", val);
        storeDetails.name = val.name;
        storeDetails.uuid = val.uuid;
        storeDetails.id = val.id;
        this.props.history.push("quick-actions-store");
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            actionType,
            storeCompleted,
            page,
            rowsPerPage,
            filteredStores,
            isLoading
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'stores'}
                        title={t('STORES_TEXT')}
                        exportFileName={t('STORES_TEXT')}
                        // handleLoadMore={() => {}}
                        isLoading={isLoading}
                        data={filteredStores}
                        actions={[
                            { label: t('BTN_ADD_NEW_STORE'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: true, clickable: true },
                            { label: t('TH_ADDRESS'), name: "address", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TYPE'), name: "storeTypeId.type", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_OWNER'), name: "createdBy.firstname", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "cell-number", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openStore}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t("STORE_TEXT")}
                        <Muted>{t("FORM_NEW_STORE_DESCRIPTION")}</Muted>
                    </DialogTitle>
                    <DialogContent>
                        {storeCompleted ? (
                            <StoreImageUpload
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        ) : (
                            <StoreForm
                                closeForm={this.closeForm}
                                action={actionType}
                                component={this}
                            />
                        )}
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Stores.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(Stores))
);
