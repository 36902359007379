import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {primaryColor} from "../../assets/jss/material-dashboard-react";
import PropTypes from "prop-types";

const ColorSwitch = withStyles({
    switchBase: {
        color: "#FFFFFF",
        '&$checked': {
            color: primaryColor[3],
        },
        '&$checked + $track': {
            backgroundColor: primaryColor[3],
        },
    },
    checked: {},
    track: {},
})(Switch);


export default function CustomSwitch(props) {

    const {
        classes,
        size,
        checked,
        onchange,
        value,
    } = props;

    return (
        <ColorSwitch
            className={classes}
            size={size}
            checked={checked}
            onChange={onchange}
            value={value}
            {...props}
        />
    );
}

CustomSwitch.propTypes = {
    classes: PropTypes.object,
    size: PropTypes.oneOf(["small", "medium"]),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
};
