/** Created by Marlon Reyes on 10/09/2019 **/

import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import Actions from '@material-ui/icons/KeyboardArrowDown';
import {
    defaultFont,
    grayColor,
    primaryBoxShadow,
    primaryColor,
    whiteColor
} from "../../assets/jss/material-dashboard-react";
import PropTypes from "prop-types";
import {
    ACTIVATE, COPY_LINK,
    DEACTIVATE,
    DELETE,
    DOWNLOAD_QR,
    DUPLICATE,
    EDIT, SEND_INVITE, SET_DEFAULT,
    SHOW_QR_CODE, SYNC_MANUALLY, SYNC_NOW,
    UNASSIGN, UPLOAD_FILE, VALIDATE
} from "../../variables/coreConstants";
import {withTranslation} from "react-i18next";

const classes = {
    tableActionButton: {
        width: "23px",
        height: "23px",
        padding: "0",
        backgroundColor: primaryColor[0],
    },
    tableActionButtonIcon: {
        color: whiteColor,
        width: "17px",
        height: "17px"
    },
    dropdownItem: {
        ...defaultFont,
        fontSize: "13px",
        padding: "10px 20px",
        margin: "0 5px",
        borderRadius: "2px",
        WebkitTransition: "all 150ms linear",
        MozTransition: "all 150ms linear",
        OTransition: "all 150ms linear",
        MsTransition: "all 150ms linear",
        transition: "all 150ms linear",
        display: "block",
        clear: "both",
        fontWeight: "400",
        lineHeight: "1.42857143",
        color: grayColor[8],
        whiteSpace: "nowrap",
        height: "unset",
        minHeight: "unset",
        "&:hover": {
            backgroundColor: primaryColor[0],
            color: whiteColor,
            ...primaryBoxShadow
        }
    },
};


function TableActions({ ...props }) {
    const { options, disabled, rowData, t } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setFormData = () => {
        return props.rowData;
    };

    const handleEditForm = () => {
        handleClose();
        props.openForm(1, setFormData());
    };

    const handleDuplicateForm = () => {
        handleClose();
        props.openForm(0, setFormData());
    };

    const handleDeleteForm = () => {
        handleClose();
        props.openForm(DELETE, setFormData());
    };

    const handleActivateForm = () => {
        handleClose();
        props.openForm(ACTIVATE, setFormData());
    };

    const handleDeactivateForm = () => {
        handleClose();
        props.openForm(DEACTIVATE, setFormData());
    };

    const handleUnassignForm = () => {
        handleClose();
        props.openForm(UNASSIGN, setFormData());
    };

    const handleShowForm = () => {
        handleClose();
        props.openForm(SHOW_QR_CODE, setFormData());
    };

    const handleSendInviteForm = () => {
        handleClose();
        props.openForm(SEND_INVITE, setFormData());
    };

    const handleUploadFileForm = () => {
        handleClose();
        props.openForm(UPLOAD_FILE, setFormData());
    };

    const handleSetDefaultForm = () => {
        handleClose();
        props.openForm(SET_DEFAULT, setFormData());
    };

    const handleSync = (type) => {
        handleClose();
        props.openForm(type, setFormData());
    };

    const handleValidate = () => {
        handleClose();
        props.openForm(VALIDATE, setFormData());
    };
    const handleCopy = () => {
        handleClose();
        props.openForm(COPY_LINK, setFormData());
    };



    return (
    <div>
        {options !== undefined ? (
            <div>
                <IconButton
                    aria-owns={"actions-menu"}
                    aria-haspopup="true"
                    disabled={disabled}
                    onClick={handleClick}
                    style={classes.tableActionButton}
                >
                    <Actions style={classes.tableActionButtonIcon}/>
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {options.map((prop) => {
                        if (prop === EDIT) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleEditForm()}
                                    key={1}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === DUPLICATE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleDuplicateForm()}
                                    key={2}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === DELETE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleDeleteForm()}
                                    key={3}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === ACTIVATE) {
                            return (
                                <MenuItem
                                    disabled={rowData.status === 1 || rowData.status === 24}
                                    style={classes.dropdownItem}
                                    onClick={() => handleActivateForm()}
                                    key={4}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === DEACTIVATE) {
                            return (
                                <MenuItem
                                    disabled={rowData.status === 0}
                                    style={classes.dropdownItem}
                                    onClick={() => handleDeactivateForm()}
                                    key={5}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === UNASSIGN) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleUnassignForm()}
                                    key={6}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === SHOW_QR_CODE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleShowForm()}
                                    key={7}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === DOWNLOAD_QR) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleShowForm()}
                                    key={8}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === SEND_INVITE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleSendInviteForm()}
                                    key={9}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === UPLOAD_FILE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleUploadFileForm()}
                                    key={10}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === SET_DEFAULT) {
                            return (
                                <MenuItem
                                    disabled={rowData.isDefault || rowData.default}
                                    style={classes.dropdownItem}
                                    onClick={() => handleSetDefaultForm()}
                                    key={11}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === SYNC_NOW) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleSync(prop)}
                                    key={12}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === SYNC_MANUALLY) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleSync(prop)}
                                    key={12}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === VALIDATE) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleValidate()}
                                    key={13}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        if (prop === COPY_LINK) {
                            return (
                                <MenuItem
                                    style={classes.dropdownItem}
                                    onClick={() => handleCopy()}
                                    key={14}
                                >
                                    {t(prop)}
                                </MenuItem>
                            );
                        }
                        else{
                            return null;
                        }
                    })
                    }
                </Menu>
            </div>
        ) : (
            <div>
                <IconButton
                    aria-owns={"actions-menu"}
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={classes.tableActionButton}
                >
                    <Actions style={classes.tableActionButtonIcon}/>
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <MenuItem
                        style={classes.dropdownItem}
                        onClick={() => handleClose()}
                        key={0}
                    >
                        {t('NO_ACTIONS_TXT')}
                    </MenuItem>
                </Menu>
            </div>

        )}
    </div>
    );
}

TableActions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool
};

export default withTranslation()(TableActions);
