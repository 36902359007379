import React from "react";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import {grayColor} from "../../assets/jss/material-dashboard-react";


function TableCellDouble({ ...props }) {
    const { mainText, secondaryText, className, onClick, children, singleItem, title, textColor } = props;
    let applyColor = title !== null && title !== undefined && title !== "" && textColor !== null && textColor !== undefined;

    return (
        <TableCell className={className} onClick={onClick} >
            {
                children !== null && children ? (children) : (
                    <div>
                        {
                            singleItem ? (
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <p style={{
                                        // "&:hover": {
                                        //     textDecorationLine: "underline !important",
                                        //     color: "#4C5BFF" + " !important"
                                        // },
                                        color: applyColor ? (textColor === "green" ? "#10c856" : textColor) : "black",
                                        fontWeight: 'bold',
                                        fontSize: applyColor ? (textColor === "green" ? "0.9250rem" : "0.7825rem") : "0.7825rem",
                                        lineHeight: "1.22857143",
                                        marginTop: 0,
                                        marginBottom: 0}}>{title !== null && title !== undefined && title !== "" ? title : "None"}</p>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: "0.7825rem",
                                        lineHeight: "1.22857143",
                                        marginTop: 0,
                                        marginBottom: 3
                                    }}>
                                        {mainText}
                                    </p>
                                    <span style={{
                                        fontSize: "0.6825rem",
                                        textDecorationLine: "none !important",
                                        fontWeight: "600",
                                        lineHeight: "1.22857143",
                                        marginTop: mainText !== null && mainText !== "" ? 0 : 15.5,
                                        marginBottom: 3,
                                        color: grayColor[1]
                                    }}>
                                        {secondaryText}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </TableCell>
    );
}

TableCellDouble.defaultProps = {
    textColor: "black"
};


TableCellDouble.propTypes = {
    mainText: PropTypes.string,
    secondaryText: PropTypes.string,
    singleItem: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    textColor: PropTypes.oneOf(["black", "green", "yellow", "red", undefined]),
};

export default React.memo(TableCellDouble);
