import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { IMG_NOT_FOUND } from "../../variables/resourcesConstants";
import { withTranslation } from "react-i18next";

const useStyles = {
    paper: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
    },
    messageComponent: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
    },
    image: {
        width: "400px",
        height: "400px",
    },
    title: {
        marginTop: "15px",
    },
};

class ResourceNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgMessage: IMG_NOT_FOUND,
            isRequesting: true,
        };
    }

    render() {
        const { classes, t, routeProps } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.messageComponent}>
                        <img
                            className={classes.image}
                            src={IMG_NOT_FOUND}
                            alt={"Message Icon"}
                        />
                        <h3 style={{ marginTop: 0 }}>{t("NOT_FOUND_MSG")}</h3>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(useStyles)(withTranslation()(ResourceNotFound));
