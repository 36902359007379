import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {getRoundNumber, nullController, nullValidator, setCurrencyValue} from "../../../utils/helpersFunctions";
import {statusText} from "../../../utils/statusHelpers";
import TextField from "@material-ui/core/TextField";
import LinkIcon from "@material-ui/icons/Launch";
import IconButton from "@material-ui/core/IconButton";


// Only rerender component if value or label change
function propsAreEqual(prevProps, nextProps) {
    return prevProps.data === nextProps.data;
}

function PaymentDetailsForm({ ...props }) {
    const {t} = useTranslation();

    const {
        classes,
        data,
        closeForm,
        openLink,
    } = props;


    return (
        <div className={classes.formContainer} style={{paddingTop: 0}}>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('CREATED_DATE_TXT')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "createdAt")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('PAYMENT_DATE')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "paidDate")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('TH_STATUS')}</p>
                        <p className={classes.paymentValueText}>{": " + t(statusText[nullController(data, "status")])}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('TH_AMOUNT')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "currency") + " " + setCurrencyValue(getRoundNumber(nullController(data, "amount")))}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('CARD_TEXT')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "cardType") + " " + nullController(data, "cardNumber")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('CARD_HOLDER_NAME')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "cardHolderName")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('AUTHORIZATION_BANK')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "transactionId")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('GINIH_REFERENCE_ID_TEXT')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "reference")}</p>
                    </div>
                    <div className={classes.rowContainer}>
                        <p className={classes.paymentLabelText}>{t('USER_NAME_HEADER')}</p>
                        <p className={classes.paymentValueText}>{": " + nullController(data, "createdBy")}</p>
                    </div>
                    {
                        nullValidator(data, "paymentRequestId") ? (
                            <div className={classes.rowContainer}>
                                <p className={classes.paymentLabelText}>{t('PAYMENT_REQUEST_TXT') + ":"}</p>
                                {
                                    nullValidator(data, "paymentRequestId") ? (
                                        <IconButton
                                            size={"small"}
                                            onClick={() => openLink(data)}
                                            style={{marginLeft: 5}}>
                                            <LinkIcon className={classes.iconButtonBlue} />
                                        </IconButton>
                                    ) : null
                                }
                            </div>
                        ) : (
                            <div>
                                {
                                    data !== null && data !== undefined && data.paymentRequestList ? (
                                        data.paymentRequestList.map(pr => {
                                            return(
                                                <div className={classes.rowContainer} key={pr.paymentRequestId}>
                                                    <p className={classes.paymentLabelText}>{pr.name + ":"}</p>
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => openLink({ paymentRequestId: pr.id, name: pr.name })}
                                                        style={{marginLeft: 5}}>
                                                        <LinkIcon className={classes.iconButtonBlue} />
                                                    </IconButton>
                                                </div>
                                            )
                                        })
                                    ) : null
                                }
                            </div>
                        )
                    }
                </GridItem>

                {
                    data !== null && data.response !== null && data.response !== "" ? (
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.columnContainer} style={{marginTop: 10}}>
                                <Typography
                                    className={classes.blackSmallTitleText + " " + classes.zeroMargin}>
                                    {t('RESPONSE_TEXT')}
                                </Typography>
                                <TextField
                                    disabled={true}
                                    readOnly={true}
                                    draggable={false}
                                    multiline
                                    className={classes.disabledTextArea}
                                    rowsMax={7}
                                    variant={"outlined"}
                                    defaultValue={nullController(data, "response")}
                                />
                            </div>
                        </GridItem>
                    ) : null
                }

                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.rightPaper}>
                        <Button
                            color="white"
                            className={classes.buttonSpacing + " " + classes.cancelButton}
                            onClick={() => closeForm("openPaymentDetail", false, null)}
                        >
                            {t('BTN_CLOSE')}
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
        </div>

    );
}

PaymentDetailsForm.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
    closeForm: PropTypes.func.isRequired,
    openLink: PropTypes.func.isRequired
};

export default React.memo(withStyles(formStyle)(PaymentDetailsForm), propsAreEqual);
