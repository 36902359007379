import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
// @material-ui/core icons
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";

// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {addTimeCheck, getMembersByCompany} from "../../../services/memberService";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomRadioButton from "../../../components/CustomRadioButton/CustomRadioButton";
import DateTimePicker from "../../../components/TimePicker/DateTimePicker";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../../../components/CustomSelect/CustomSelect";
import {getZonesByCompany} from "../../../services/zoneService";
import {
    disableComponent,
    enableComponent,
    getUser, isSuccessfulCreate,
    selectValidation
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";


class TimeCheckForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            member: '',
            zone: '',
            eventType: 'Check in',
            date: String(new Date()),
            memberOptions: [],
            zoneOptions: [],
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveCheckTime = this.saveCheckTime.bind(this);
        this.request = this.request.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    changeMemberSelect = member => {
        this.setState({ member });
    };
    changeZoneSelect = zone => {
        this.setState({ zone });
    };
    handleDateTimeChange = (_date) => {
        this.setState({ date: String(_date) });
    };

    cleanData(){
        this.setState({
            member: '',
            zone: '',
            notificationMessage: '',
            color: 'primary',
            notification: false,
        });
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.props.closeForm("openTimeCheck", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    request() {

        let memberValues;
        getMembersByCompany()
            .then((res) => {
                memberValues = res.data.map( item => {
                    if (item.firstname === "" || item.firstname === null) {
                        return {
                            value: item.id,
                            label: item.email + " (" + item.externalId + ")"
                        };

                    } else {
                        return {
                            value: item.id,
                            label: item.firstname + item.lastname + " (" + item.externalId + ")"
                        };
                    }

                });
                this.setState({ memberOptions: memberValues});
            })
            .catch((err) => {
                console.log(err);
                if (err.response)
                    validateSession(err.response, this.props.history);
            });


        let zoneValues;
        getZonesByCompany()
            .then((res) => {
                zoneValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });
                this.setState({ zoneOptions: zoneValues});
            })
            .catch((err) => {
                console.log(err);
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    saveCheckTime(){

        if (selectValidation(this.state.zone) || selectValidation(this.state.member)) {
            this.showNotification("All fields are required", "danger", NotificationErrorIcon, null);

        } else {
            let eventType;
            if (this.state.eventType === "Check in")
                eventType = 1;
            
            if (this.state.eventType === "Check out")
                eventType = 0;

            let user = getUser();
            let dataParameters = {
                "member_id": this.state.member.value,
                "added_by": user.user_id,
                "zone_id": this.state.zone.value,
                "event_type": eventType,
                "datetime": new Date(this.state.date).getTime()
            };


            disableComponent(this, "disableField");
            addTimeCheck(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                });
        }
    }


    render(){
        const { classes } = this.props;

        const SaveTimeCheckButton = (() => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveCheckTime()}
                disabled={this.state.disableField}
            >
                Save
            </Button>
        ));


        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="store" className={classes.selectTitle}>
                                Member
                            </InputLabel>
                            <Select
                                value={this.state.member}
                                onChange={this.changeMemberSelect}
                                options={this.state.memberOptions}
                                placeholder={"Select..."}
                                closeMenuOnSelect={true}
                                maxMenuHeight={150}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl>
                            <RadioGroup
                                value={this.state.eventType}
                                aria-label="Event Type"
                                name="eventType"
                                onChange={this.handleChangeValues}
                                style={{marginLeft: "20px", marginBottom: "30px"}}
                            >
                                <FormControlLabel
                                    value={"Check in"}
                                    control={<CustomRadioButton />}
                                    label="Check In"
                                />
                                <FormControlLabel
                                    value={"Check out"}
                                    control={<CustomRadioButton />}
                                    label="Check Out"
                                />
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="store" className={classes.selectTitle}>
                                Zone
                            </InputLabel>
                            <Select
                                value={this.state.zone}
                                onChange={this.changeZoneSelect}
                                options={this.state.zoneOptions}
                                placeholder={"Select..."}
                                closeMenuOnSelect={true}
                                maxMenuHeight={70}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem>
                        <div className={classes.datePickerControl}>
                            <DateTimePicker
                                ampm={false}
                                name={"date"}
                                value={this.state.date}
                                onChange={this.handleDateTimeChange}
                                labelText="Date"
                                format="yyyy/MM/dd HH:mm"
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openTimeCheck", false, false)}
                            >
                                Cancel
                            </Button>
                            <SaveTimeCheckButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

TimeCheckForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(TimeCheckForm));