/* Created by Marlon Reyes on July 25, 2019 */

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Button from "@material-ui/core/Button";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import { getData, nullController } from "../../../utils/helpersFunctions";
import AssetsDetails from "./ZoneAssetsDetails";
import ApplicationsDetails from "./ZoneApplicationsDetails";
import AssetAssignForm from "./AssetZoneAssignmentForm";
import ApplicationAssignForm from "./AppZoneAssignmentForm";
import assetDetails from "../../../models/AssetModel";
import applicationDetails from "../../../models/ApplicationModel";
import {
    IMG_APP_CARD,
    IMG_ASSET_CARD,
} from "../../../variables/resourcesConstants";

class QuickActionsZone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zone: getData("zone"),
            openAsset: false,
            openApp: false,
            openQr: false,
            isLoading: true,
            isQuickStartA: true,
            isQuickStartApp: true,
            isQuickStart: false,
        };
        this.closeForm = this.closeForm.bind(this);
        this.changeQuickStart = this.changeQuickStart.bind(this);
    }

    componentWillUnmount() {
        // removeData("store");
    }

    openAssetForm = () => {
        assetDetails.cleanAssetDetails();
        this.setState({ openAsset: true });
    };
    closeAssetForm = () => {
        this.setState({ openAsset: false });
    };
    openAppForm = () => {
        applicationDetails.cleanApplicationDetails();
        this.setState({ openApp: true });
    };
    closeAppForm = () => {
        this.setState({ openApp: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });

        if (type) this.setState({ isQuickStart: false });
    }
    changeQuickStart(state, value) {
        this.setState({ [state]: value });
        if (
            this.state.isQuickStartA === true &&
            this.state.isQuickStartApp === true
        ) {
            this.setState({
                isQuickStart: true,
                isLoading: false,
            });
        } else {
            this.setState({
                isQuickStart: false,
                isLoading: false,
            });
        }
    }

    goToStore = () => {
        this.props.history.push("quick-actions-store");
    };

    render() {
        const { classes, t, routeProps } = this.props;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.zone, "name")}
                            </h3>
                            <Muted>{t("ZONE_DETAILS_DESCRIPTION_TEXT")}</Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            <CustomButton
                                variant="outlined"
                                color="white"
                                className={classes.cancelButton}
                            >
                                {t("EDIT_TEXT")}
                            </CustomButton>
                            <CustomButton
                                color="primary"
                                onClick={this.goToStore}
                            >
                                {t("GO_TO_STORE_TEXT")}
                            </CustomButton>
                        </div>
                    </GridItem>
                    {this.state.isQuickStart ? (
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.typo}>
                                <h4> {t("QUICK_START")} </h4>
                            </div>
                        </GridItem>
                    ) : null}
                </GridContainer>
                {this.state.isQuickStart ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card chart>
                                <div className={classes.center}>
                                    <img
                                        src={IMG_APP_CARD}
                                        width={"175px"}
                                        height={"175px"}
                                        alt={"Application"}
                                    />
                                </div>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>
                                        {t("APPLICATION_TEXT")}
                                    </h4>
                                    <p className={classes.cardCategory}>
                                        {t("APPLICATION_DETAIL_DESCRIPTION")}
                                    </p>
                                </CardBody>
                                <CardFooter chart>
                                    <Button
                                        onClick={this.openAppForm}
                                        size="small"
                                        color="primary"
                                    >
                                        {t("BTN_ASSIGN_APP")}
                                    </Button>
                                </CardFooter>
                            </Card>
                            <Dialog
                                maxWidth={"xs"}
                                fullWidth={true}
                                aria-labelledby="form-app-dialog-title"
                                open={this.state.openApp}
                                onClose={this.closeAppForm}
                            >
                                <DialogTitle id="form-app-dialog-title">
                                    {t("FORM_APP_TITLE")}
                                </DialogTitle>
                                <DialogContent>
                                    <ApplicationAssignForm
                                        closeForm={this.closeForm}
                                    />
                                </DialogContent>
                            </Dialog>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card chart>
                                <div className={classes.center}>
                                    <img
                                        src={IMG_ASSET_CARD}
                                        width={"175px"}
                                        height={"175px"}
                                        alt={"Asset"}
                                    />
                                </div>
                                <CardBody>
                                    <h4 className={classes.cardTitle}>Asset</h4>
                                    <p className={classes.cardCategory}>
                                        {t("ASSET_CARD_DESCRIPTION")}
                                    </p>
                                </CardBody>
                                <CardFooter chart>
                                    <Button
                                        onClick={this.openAssetForm}
                                        size="small"
                                        color="primary"
                                    >
                                        {t("BTN_ASSIGN_ASSET")}
                                    </Button>
                                </CardFooter>
                            </Card>
                            <Dialog
                                maxWidth={"xs"}
                                fullWidth={true}
                                aria-labelledby="form-asset-dialog-title"
                                open={this.state.openAsset}
                                onClose={this.closeAssetForm}
                            >
                                <DialogTitle id="form-menu-dialog-title">
                                    {t("BTN_ASSIGN_ASSET")}
                                </DialogTitle>
                                <DialogContent>
                                    <AssetAssignForm
                                        closeForm={this.closeForm}
                                    />
                                </DialogContent>
                            </Dialog>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <ApplicationsDetails quickAction={this.changeQuickStart} />
                )}
                {this.state.isQuickStart ? null : (
                    <AssetsDetails quickAction={this.changeQuickStart} />
                )}
            </div>
        );
    }
}

QuickActionsZone.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsZone))
);
