import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardFooter from "../../../components/Card/CardFooter";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import AnalyticCard from "../../../components/Card/AnalyticCard";
import {getRoundNumber, setCurrencyValue} from "../../../utils/helpersFunctions";


class CommerceAnalyticCards extends React.Component {

    render() {
        const { data, classes, t } = this.props;
        const analytics = data[0] ? data[0] : data;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                        <AnalyticCard
                            currencySymbol={analytics.currencySymbol ? analytics.currencySymbol : ""}
                            value={analytics.totalRevenue ? (setCurrencyValue(getRoundNumber(parseFloat(analytics.totalRevenue)))) : setCurrencyValue(getRoundNumber(0))}
                            title={t('TOTAL_REVENUE_TEXT')}
                            icon={null}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card className={classes.cardFixed}>
                            <CardHeader color="danger" stats icon>
                                <p className={classes.cardCategory}>{t('TOTAL_ORDERS_REVENUE_TEXT')}</p>
                                <h3 className={classes.cardTitle}>
                                    {(analytics.currencySymbol ? analytics.currencySymbol : "") + (analytics.totalOrdersRevenue
                                        ? (setCurrencyValue(getRoundNumber(parseFloat(analytics.totalOrdersRevenue))))
                                        : setCurrencyValue(getRoundNumber(0)))
                                    }
                                </h3>
                            </CardHeader>
                            <CardFooter className={classes.cardFooterFixed}>
                                <div className={classes.stats}>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card className={classes.cardFixed}>
                            <CardHeader color="danger" stats icon>
                                <p className={classes.cardCategory}>{t('TOTAL_PAYMENTS_REQUEST_REVENUE_TEXT')}</p>
                                <h3 className={classes.cardTitle}>
                                    {(analytics.currencySymbol ? analytics.currencySymbol : "") + (analytics.totalPaymentRequestRevenue
                                        ? (setCurrencyValue(getRoundNumber(parseFloat(analytics.totalPaymentRequestRevenue))))
                                        : setCurrencyValue(getRoundNumber(0)))
                                    }
                                </h3>
                            </CardHeader>
                            <CardFooter className={classes.cardFooterFixed}>
                                <div className={classes.stats}>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card className={classes.cardFixed}>
                            <CardHeader stats icon>
                                <p className={classes.cardCategory}>{t('AVERAGE_ORDER_AMOUNT_TEXT')}</p>
                                <h3 className={classes.cardTitle}>
                                    {(analytics.currencySymbol ? analytics.currencySymbol : "") + (analytics.averageOrderAmount
                                        ? (setCurrencyValue(getRoundNumber(parseFloat(analytics.averageOrderAmount))))
                                        : setCurrencyValue(getRoundNumber(0)))
                                    }
                                </h3>
                            </CardHeader>
                            <CardFooter className={classes.cardFooterFixed}>
                                <div className={classes.stats}>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card className={classes.cardFixed}>
                            <CardHeader color="danger" stats icon>
                                <p className={classes.cardCategory}>{t('TOTAL_ORDERS_TEXT')}</p>
                                <h3 className={classes.cardTitle}>
                                    {analytics.totalOrders ? analytics.totalOrders : "0"}
                                </h3>
                            </CardHeader>
                            <CardFooter className={classes.cardFooterFixed}>
                                <div className={classes.stats}>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <Card className={classes.cardFixed}>
                            <CardHeader color="danger" stats icon>
                                <p className={classes.cardCategory}>{t('TOTAL_PAYMENT_REQUEST_TEXT')}</p>
                                <h3 className={classes.cardTitle}>
                                    {analytics.totalPaymentRequest ? analytics.totalPaymentRequest : "0"}
                                </h3>
                            </CardHeader>
                            <CardFooter className={classes.cardFooterFixed}>
                                <div className={classes.stats}>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

CommerceAnalyticCards.defaultProps = {
    data: [{
        totalRevenue: "0.00",
        totalPaymentRequest: "0",
        totalOrders: "0",
        averageOrderAmount: "0.00",
        totalPaymentRequestRevenue: "0.00",
        totalOrdersRevenue: "0.00",
        currencySymbol: ""
    }]
};

CommerceAnalyticCards.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(CommerceAnalyticCards)));