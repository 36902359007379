import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import {
    getCompanyCurrency, mergeArrays, saveData,
} from "../../../../utils/helpersFunctions";
import {
    NOTIFICATION_DURATION, VALIDATE,
} from "../../../../variables/coreConstants";
import tableViewStyle from "../../../../assets/jss/custom-components/tableViewStyle";
import {validateSession} from "../../../../services/api";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {ROWS_PER_PAGE} from "../../../../variables/apiConstants";
import {withRouter} from "react-router-dom";
import {getCashAccountsByCompany} from "../../../../services/cashAccountService";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../../../components/WijmoTableGrid/TabledGridWijmo";
const OBJECT_NAMES = require("../../../../variables/objectConstants");


class CashAccount extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            currency: props.walletAccount ? props.walletAccount.currency.symbol : getCompanyCurrency(),
            isLoading: false,
            isLoadingMore: false,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            lastPage: false,
            totalRecords: 0,
            totalPages: 0,
            count: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let dataParameters = {
            "page": this.state.page,
            "pageSize": this.state.rowsPerPage,
            "walletAccountId": this.props.walletAccount.id
        };
        this.request(dataParameters);
    }

    handleCloseForm = () => {
        this.setState({ open: false });
    };


    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type) {
            this.setState({ isLoading: true, page: 0 });

            let dataParameters = {
                "page": 0,
                "pageSize": this.state.rowsPerPage,
                "walletAccountId": this.props.walletAccount.id
            };

            this.request(dataParameters);
        }
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.setState({ isLoading: true, page: 0 });
                    let dataParameters = {
                        "page": 0,
                        "pageSize": this.state.rowsPerPage,
                        "walletAccountId": this.props.walletAccount.id
                    };
                    this.request(dataParameters);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };
    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    request(parameters) {
        getCashAccountsByCompany(parameters)
            .then((response) => {
                let paginatedData = this.state.page === 0 ? response.data !== null ? response.data.content : [] : mergeArrays(
                    this.state.filteredData,
                    response.data !== null ? response.data.content : [],
                    "uuid",
                    null
                );

                this.setState({
                    isLoading: false,
                    isLoadingMore: false,
                    data: paginatedData,
                    filteredData: paginatedData,
                    totalElements: response.data !== null ? response.data.totalElements : 0,
                    lastPage: response.data !== null ? response.data.last : false,
                    totalPages: response.data !== null ? response.data.totalPages : 0,
                });

            })
            .catch((error) => {
                this.setState({ isLoading: false, isLoadingMore: false } );
                if (error.response) {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    }



    memorizeLastCashView = () => {
        try {
            let obj = {
                tab: 1,
                page: 0,
                pageSize: 10,
                lastPage: 0,
                totalRecords: 0,
                count: 0,
            };

            saveData(OBJECT_NAMES.CURRENT_CASH_TAB, obj);
        } catch (e) {}
    };



    handleCreateAccount = () => {
        this.memorizeLastCashView();
        this.props.history.replace("/admin/cash-account-form/new");
    };

    handleValidateAccount = (values) => {
        this.memorizeLastCashView();
        saveData(OBJECT_NAMES.CASH_ACCOUNT_PENDING, values);
        this.props.history.replace("/admin/cash-account-form/update");
    };

    handleActions = (action, rowData) => {
        if (action === VALIDATE) {
            this.handleValidateAccount(rowData);
        }
    };


    handleLoadMore = () => {
        const { isLoadingMore, page, lastPage } = this.state;

        if (!isLoadingMore && !lastPage) {
            this.setState({ page: page + 1, isLoadingMore: true }, () => {
                let dataParameters = {
                    "pageNo": this.state.page,
                    "pageSize": this.state.rowsPerPage,
                    "walletAccountId": this.props.walletAccount.id
                };
                this.request(dataParameters);
            });
        }
    };



    render(){
        const { classes, t } = this.props;
        const { totalPages, lastPage, page, rowsPerPage, totalElements, isLoadingMore, isLoading, filteredData } = this.state;


        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoadingMore || isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12}>
                    <TableGridWijmo
                        tableName={'cash_accounts'}
                        title={t('ACTIVITY_TXT')}
                        exportFileName={t('ACTIVITY_TXT')}
                        handleLoadMore={this.handleLoadMore}
                        isLoading={isLoading}
                        data={filteredData}
                        actions={[
                            { label: t('BTN_ADD_ACCOUNT'), function: this.handleCreateAccount, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "alias", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_PROVIDER'), name: "bankProvider.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACCOUNT_NUMBER_IBAN'), name: "accountNumber", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 130, cssClass: "", isCustomCell: true },
                            { label: t('TH_DATE_ADDED'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.handleActions, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

CashAccount.propTypes = {
    classes: PropTypes.object,
    showNotification: PropTypes.func,
    walletAccount: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(CashAccount)));
