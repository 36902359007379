import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import HomeFeedCards from "./HomeFeedCards";
import DefaultingMembers from "./DefaultingMembers";


class HomeFeedAnalytics extends React.Component {

    render() {
        const { data, analytics, onPaginate} = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <HomeFeedCards data={analytics}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <DefaultingMembers data={data} onRequest={onPaginate} />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

HomeFeedAnalytics.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    analytics: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
    onPaginate: PropTypes.func.isRequired,
};

export default withRouter(HomeFeedAnalytics);