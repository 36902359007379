import {getService, postService, putService} from "./api";
import {
    BANK_ACCOUNT_TYPES,
    BASE_URL_BALANCE, CANCEL_WALLET_AGREEMENT,
    CASH_ACCOUNT,
    CASH_ACCOUNT_DETAILS,
    CASH_ACCOUNTS_BY_COMPANY,
    UPDATE_CASH_ACCOUNT,
    VALIDATE_CASH_ACCOUNT,
    WALLET_ACCOUNT_DETAILS, WALLET_ACCOUNTS, WALLET_AGREEMENT,
} from "../variables/apiConstants";


export function getCashAccountsByCompany(parameters) {
    let url = BASE_URL_BALANCE + CASH_ACCOUNTS_BY_COMPANY;

    return postService(url, parameters);
}

export function getCashAccountDetail(uuid) {
    let url = BASE_URL_BALANCE + CASH_ACCOUNT_DETAILS + uuid;

    return getService(url);
}

export function addCashAccount(parameters) {
    let url = BASE_URL_BALANCE + CASH_ACCOUNT;

    return postService(url, parameters);
}

export function editCashAccount(parameters) {
    let url = BASE_URL_BALANCE + UPDATE_CASH_ACCOUNT;

    return putService(url, parameters);
}

export function completeCashAccount(parameters) {
    let url = BASE_URL_BALANCE + VALIDATE_CASH_ACCOUNT;

    return putService(url, parameters);
}

export function getWalletAccountDetail(walletAccountId) {
    let url = BASE_URL_BALANCE + WALLET_ACCOUNT_DETAILS + (walletAccountId !== null ? '/' + walletAccountId : '');

    return getService(url);
}

export function getWalletAgreement() {
    let url = BASE_URL_BALANCE + WALLET_AGREEMENT;

    return getService(url);
}


export function cancelWalletAgreement(parameters) {
    let url = BASE_URL_BALANCE + CANCEL_WALLET_AGREEMENT;

    return putService(url, parameters);
}


export function getBankAccountTypes() {
    let url = BASE_URL_BALANCE + BANK_ACCOUNT_TYPES;
    return getService(url);
}


export function getWalletAccounts() {
    let url = BASE_URL_BALANCE + WALLET_ACCOUNTS;

    return getService(url);
}