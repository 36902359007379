import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components

import PropTypes from "prop-types";
// @material-ui/core components

import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import formStyle from "../../assets/jss/custom-components/formStyle";
import {withRouter} from "react-router-dom";
import scheduleDetails from "../../models/ScheduleModel";
import {formatDay, formatShortTime} from "../../utils/helpersFunctions";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";


class ScheduleAvailable extends React.Component{

    deleteSchedule = (index) => {
        const { component } = this.props;

        scheduleDetails.schedules.splice(index, 1);
        component.setState({ schedules: scheduleDetails.schedules });
    };


    editSchedule = (values, index) => {
        scheduleDetails.cleanSchedule();
        const { component } = this.props;

        component.setState({
            openSchedule: true,
            actionType: 1
        });

        scheduleDetails.schedule.currentIndex = index;
        scheduleDetails.schedule.assignId = values.assignId;
        scheduleDetails.schedule.scheduleId = values.scheduleId;
        scheduleDetails.schedule.startTime = values.timeFrom;
        scheduleDetails.schedule.startTime = values.timeFrom;
        scheduleDetails.schedule.endTime = values.timeTo;
        scheduleDetails.schedule.day.push({
            value: values.scheduleDaysId,
            label: values.day
        });
    };


    render() {
        const { classes, t, schedules } = this.props;

        return (
            <div className={classes.scheduleContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {
                            schedules.map((schedule, key) => (
                                <div key={key} className={classes.scheduleContainer}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={3} md={2} className={classes.colorIcon}>
                                            <label style={{color: "#000"}}>{t(formatDay(schedule.scheduleDaysId))}</label>
                                        </GridItem>
                                        <GridItem xs={8} sm={5} md={4}>
                                            <Chip label={formatShortTime(schedule.timeFrom)}/>
                                            <label className={classes.separator}>{t('TO_TEXT')}</label>
                                            <Chip label={formatShortTime(schedule.timeTo)}/>
                                        </GridItem>
                                        <GridItem xs={4} sm={3} md={2}>
                                            <Tooltip id="tooltip-edit" title={t('EDIT_SCHEDULE_TEXT')} placement="top-start">
                                                <IconButton size={"small"} onClick={() => this.editSchedule(schedule, key)}>
                                                    <EditIcon className={classes.iconButtonBlue}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip id="tooltip-delete" title={t('DELETE_SCHEDULE_TEXT')} placement="top-start">
                                                <IconButton size={"small"} onClick={() => this.deleteSchedule(key)}>
                                                    <ClearIcon className={classes.iconButtonRed}/>
                                                </IconButton>
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            ))
                        }
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

ScheduleAvailable.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    schedules: PropTypes.array.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(ScheduleAvailable)));
