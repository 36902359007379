import {getCompanyCurrency, getRoundNumber, initialMomentDate} from "../utils/helpersFunctions";
import * as moment from "moment";
import {
    STATUS_PAID,
    STATUS_PENDING
} from "../variables/coreConstants";

var paymentRequestDetails = {
    uuid: "",
    name: "",
    email: "",
    user: "",
    userPhoto: "",
    link: "",
    currencyCode: getCompanyCurrency(),
    currencySymbol: "",
    externalId: "",
    memberExternalId: "",
    description: "",
    amount: 0.00,
    surcharge: 0.00,
    discount: 0.00,
    tax: 0.00,
    status: null,
    deactivateOption: false,
    expirationDate: initialMomentDate(),
    store: "",

    setPaymentRequestDetail : function (values) {
        try {
            this.uuid = values.uuid;
            this.name = values.name;
            this.description = values.description;
            this.email = values.email;
            this.user = values.user;
            this.link = values.link;
            this.currencyCode = values.currencyCode;
            this.externalId = values.externalId;
            this.memberExternalId = values.memberExternalId;
            this.amount = getRoundNumber(values.amount);
            this.discount = getRoundNumber(values.discount);
            this.tax = getRoundNumber(values.tax);
            this.surcharge = getRoundNumber(values.surcharge);
            this.status = values.status;
            this.deactivateOption = values.deactivateAfterExpiration;


            this.store = {value: values.store.id, label: values.store.name};

            let _date = new Date(values.expirationDate);
            var m = moment(); // Initial moment object
            var _moment = moment(_date);
            m.set(_moment.toObject());

            this.expirationDate = m;
        } catch (e) {
            this.expirationDate = initialMomentDate();
        }
    },

    cleanPaymentRequestDetail : function () {
        this.uuid = "";
        this.name = "";
        this.description = "";
        this.email = "";
        this.user = "";
        this.link = "";
        this.currencyCode = getCompanyCurrency();
        this.currencySymbol = "";
        this.externalId = "";
        this.memberExternalId = "";
        this.description = "";
        this.amount = 0.00;
        this.discount = 0.00;
        this.tax = 0.00;
        this.surcharge = 0.00;
        this.status = null;
        this.deactivateOption = false;
        this.expirationDate = initialMomentDate();
        this.store = "";
    },

    disablePaymentRequestStatus : function () {
        let result;

        switch (this.status) {
            case STATUS_PAID: result = true;
                break;

            case STATUS_PENDING: result = false;
                break;

            default: result = true;
        }

        return result;
    },

};

export default paymentRequestDetails;