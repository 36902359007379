import React from "react";
import {withTranslation} from "react-i18next";
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Snackbar from "../../../components/Snackbar/Snackbar";
import Select from "../../../components/CustomSelect/CustomSelect";
import NotificationErrorIcon from "@material-ui/icons/Error";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../../components/CustomButtons/Button";
import {
    ALL_STATUS,
    FAILED_SYNC_STATUS, NOT_COMPLETED_STATUS,
    NOTIFICATION_DURATION,
    PAID_STATUS,
    PENDING_STATUS, UNSPECIFIED_STATUS
} from "../../../variables/coreConstants";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import * as moment from "moment";
import {
    formatDate,
    formatDatetimeToDate,
    initialMomentOfDay,
    nowMomentDate,
    objectSizeValidator
} from "../../../utils/helpersFunctions";
import {generatePaymentRequestsCsv} from "../../../services/paymentService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationInfoIcon from "@material-ui/icons/Info";
import {validateSession} from "../../../services/api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SimpleRadioButton from "../../../components/CustomRadioButton/SimpleRadioButton";


class ExportCsvForm extends React.Component{

    csvLink = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            startTime: initialMomentOfDay(),
            endTime: nowMomentDate(),
            data: [],
            headers: [
                    { label: props.t('NAME_HEADER'), key: "name" },
                    { label: props.t('DESCRIPTION_HEADER'), key: "description" },
                    { label: props.t('STATUS_HEADER'), key: "status" },
                    { label: props.t('CREATED_DATE_HEADER'), key: "createdDate" },
                    { label: props.t('CREATED_BY_HEADER'), key: "createdBy" },
                    { label: props.t('UNIT_PRICE'), key: "originalAmount" },
                    { label: props.t('SURCHARGE_HEADER'), key: "surcharge" },
                    { label: props.t('DISCOUNT_HEADER'), key: "discount" },
                    { label: props.t('PAID_DATE_HEADER'), key: "paidDate" },
                    { label: props.t('AMOUNT_PAID_HEADER'), key: "amountPaid" },
                    { label: props.t('CURRENCY_HEADER'), key: "currency" },
                    { label: props.t('INVOICE_REF'), key: "billReference" },
                    { label: props.t('EXTERNAL_ID_HEADER'), key: "externalId" },
                    { label: props.t('USER_NAME_HEADER'), key: "userName" },
                    { label: props.t('MEMBER_EXTERNAL_ID_HEADER'), key: "memberExternalId" },
                    { label: props.t('EMAIL_HEADER'), key: "email" },
                    { label: props.t('LINK_HEADER'), key: "link" },
                    { label: props.t('ID_HEADER'), key: "uuid" },
            ],
            reportName: "",
            dateType: 1,
            csvDownloadRef: null,
            status: {value: ALL_STATUS, label: this.props.t('ALL_STATUS_TEXT')},
            statusOptions: [
                {value: ALL_STATUS, label: this.props.t('ALL_STATUS_TEXT')},
                {value: PAID_STATUS, label: this.props.t('STATUS_PAID')},
                {value: FAILED_SYNC_STATUS, label: this.props.t('STATUS_SYNC_FAILED')},
                {value: PENDING_STATUS, label: this.props.t('STATUS_PENDING')},
                {value: UNSPECIFIED_STATUS, label: this.props.t('STATUS_UNSPECIFIED')},
                {value: NOT_COMPLETED_STATUS, label: this.props.t('STATUS_NOT_COMPLETED')}
            ],
            isLoading: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationErrorIcon,
        };
    }


    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    handleStartTimeChange = (date) => {
        date = date > this.state.endTime ? initialMomentOfDay() : date;
        this.setState({ startTime: date });
    };

    handleEndTimeChange = (date) => {
        const currentDate = moment();
        date = date > currentDate ? currentDate : (date < this.state.startTime ? this.state.startTime : date);
        this.setState({ endTime: date } );
    };

    onRefChange = node => {
        this.setState({ csvDownloadRef: node });
    };


    changeStatusSelect = status => {
        this.setState({ status });
    };

    handleChangeType = (event) => {
        this.setState({ dateType: parseInt(event.target.value) });
    };


    onGenerateCsv = () => {
        this.setState({ isLoading: true }, () => {
            let dataParameters = {
                "startDate": formatDate(this.state.startTime.valueOf()),
                "endDate":formatDate(this.state.endTime.valueOf()),
                "status": this.state.status.value,
                "dateType": this.state.dateType
            };

            generatePaymentRequestsCsv(dataParameters)
                .then(response => {
                    if (objectSizeValidator(response.data, 0)) {
                        this.setState({
                            data: response.data,
                            reportName: this.props.t('CSV_FILE_NAME_PAYMENT_REQUEST',
                                {
                                    start: formatDatetimeToDate(this.state.startTime.valueOf()),
                                    end: formatDatetimeToDate(this.state.endTime.valueOf())
                                }),
                        }, () => {
                            // Wait to set ref again
                            window.setTimeout(
                                function() {
                                    if (this.csvLink !== null && this.csvLink !== undefined) {
                                        this.csvLink.link.click();
                                        this.setState({ isLoading: false });
                                    }
                                }.bind(this),
                                1000
                            );
                        });
                    } else {
                        this.setState({ isLoading: false, reportName: "" });
                        this.showNotification(this.props.t('NO_RECORDS_TXT'), "warning", NotificationInfoIcon, null);

                    }
                })
                .catch(e => {
                    this.setState({ isLoading: false, reportName: "" });
                    if (e.response) {
                        this.showNotification(e.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(e.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationErrorIcon, null);
                    }
                });
        });
    };


    render() {
        const { classes, t, closeForm } = this.props;
        const { isLoading, data, hideNotification, color, icon, notification, notificationMessage, startTime, endTime,
            reportName, headers } = this.state;

        return (
            <div className={classes.scheduleContainer}>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('START_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={startTime}
                                onChange={this.handleStartTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.timerContainer}>
                            <InputLabel className={classes.selectTitle}>
                                {t('END_DATE_TEXT')}
                            </InputLabel>
                            <Datetime
                                value={endTime}
                                onChange={this.handleEndTimeChange}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={"HH:mm:ss"}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div className={classes.selectForm}>
                            <Select
                                value={this.state.status}
                                onChange={this.changeStatusSelect}
                                options={this.state.statusOptions}
                                closeMenuOnSelect={true}
                                isDisabled={this.state.isLoading}
                                isLoading={this.state.isLoading}
                                maxMenuHeight={350}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 5 })
                                }}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <FormControl className={classes.radioContainer}>
                            <FormLabel className={classes.groupLegend}>{t('FILTER_BY_TXT')}</FormLabel>
                            <RadioGroup
                                row
                                className={classes.groupBox}
                                aria-label="dateType"
                                name="dateType"
                                value={this.state.dateType}
                                onChange={(event, value) => { this.handleChangeType(event, value)}}>
                                <FormControlLabel value={0} control={<SimpleRadioButton />} label={t('CREATED_DATE_TXT')} />
                                <FormControlLabel value={1} control={<SimpleRadioButton />} label={t('PAID_DATE_TXT')} />
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centerButtonsColumn}>
                            <Button
                                disabled={isLoading}
                                color="white"
                                onClick={this.onGenerateCsv}
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                            >
                                {t('BTN_GENERATE_FILE')}
                            </Button>
                            <div className={classes.hidden}>
                                <CSVLink
                                    data={data}
                                    headers={headers}
                                    separator={","}
                                    className="hidden"
                                    ref={(r) => this.csvLink = r}
                                    filename={reportName}>
                                    {t('BTN_GENERATE_FILE')}
                                </CSVLink>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.buttonContainer}>
                            <div className={classes.rightPaper}>
                                <Button
                                    disabled={isLoading}
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => closeForm()}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    color="primary"
                                    className={classes.buttonSpacing}
                                    onClick={() => closeForm()}
                                >
                                    {t('BTN_FINISH')}
                                </Button>
                            </div>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={color}
                        icon={icon}
                        message={notificationMessage}
                        open={notification}
                        closeNotification={hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ExportCsvForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ExportCsvForm)));
