import firebase from "firebase/app";
import "firebase/messaging";
import {firebaseConfiguration, saveData} from "./helpersFunctions";

let messaging = null;
if(firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp(firebaseConfiguration());
    messaging = initializedFirebaseApp.messaging();
    saveData("notificationSupport", true);

} else {
    saveData("notificationSupport", false);
    console.log("Notifications no supported");
}

export { messaging };

