import React from "react";
import PropTypes from "prop-types";
import formStyle from "../../assets/jss/custom-components/formStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import {useTranslation} from "react-i18next";
import TaskIcon from "../CustomComponents/TaskIcon";
import {isEmpty} from "../../utils/helpersFunctions";

function LiveProcessModal({ ...props }) {
    const { classes, onClose, onFinish, processPercentage, label, processDescription, isCompleted, currentItem } = props;
    const { t } = useTranslation();

    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} className={classes.bottomPaper}>
                    <p className={classes.cardThTxtGray}>
                        {label}
                    </p>
                    {processPercentage + "% " + processDescription}

                    {
                        !isCompleted && !isEmpty(currentItem)
                            ? <TaskIcon label={currentItem.label} success={currentItem.success} />
                            : null
                    }

                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.centerWithTopMargin}>
                        {
                            !isCompleted ? (
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => onClose(false)}
                                >
                                    {t('BTN_CLOSE')}
                                </Button>
                            ) : null
                        }

                        {
                            isCompleted ?
                                (
                                    <Button
                                        color="primary"
                                        className={classes.buttonSpacing}
                                        onClick={() => onFinish(true)}
                                    >
                                        {t('BTN_CLOSE')}
                                    </Button>
                                )
                                : null
                        }
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}


LiveProcessModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    processPercentage: PropTypes.number,
    label: PropTypes.string,
    currentItem: PropTypes.shape({
        success: PropTypes.bool,
        label: PropTypes.string,
    }),
    processDescription: PropTypes.string,
    isCompleted: PropTypes.bool
};


function propsAreEqual(prevProps, nextProps) {
    return prevProps.processPercentage === nextProps.processPercentage;
}

export default withStyles(formStyle)(LiveProcessModal);
// export default React.memo(withStyles(formStyle)(LiveProcessModal), propsAreEqual);
