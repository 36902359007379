import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Styles
import 'react-image-crop/dist/ReactCrop.css';
import formStyle from "../../../assets/jss/custom-components/formStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import {
    disableComponent,
    enableComponent, getData,
    getLanguage,
    isSuccessfulCreate,
    nullController
} from "../../../utils/helpersFunctions";
import Snackbar from "../../../components/Snackbar/Snackbar";
import {
    DATA_TYPE_CSV_EXCEL_SUPPORTED,
    DATA_TYPE_CSV_SUPPORTED,
    MAX_BULK_ITEMS, MEMBER_CARDS_IMPORT_TYPE, MEMBERS_IMPORT_TYPE,
    NOTIFICATION_DURATION
} from "../../../variables/coreConstants";
import CSVReader from "react-csv-reader";
import Muted from "../../../components/Typography/Muted";
import Loading from "../../../components/Loading/Loading";
import {addMembersBulk} from "../../../services/memberService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {getSignature, validateSession} from "../../../services/api";
import lodash from 'lodash';
import {addMembersCardsBulk, getDevKeys} from "../../../services/directDebitService";
import {BASE_URL_PAYMENT_METHOD_SERVICE, MEMBER_CREDIT_CARDS_CSV} from "../../../variables/apiConstants";


class MemberCsvUploadForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            src: null,
            uploading: false,
            disableField: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }

    parseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: 'greedy',
        transformHeader: header => lodash.camelCase(header)
    };

    parseOptionsDefault = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_").toString()
    };


    showNotification = (msj, color, icon, refresh) => {
        const { importType } = this.props;

        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null && refresh) {
                    this.props.closeForm(importType === MEMBERS_IMPORT_TYPE, null);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    async saveCollection() {
        const {importType} = this.props;

        if (this.state.file !== null && this.state.file !== undefined && this.state.file.length > 0) {
            disableComponent(this, "disableField");

            if (importType === MEMBERS_IMPORT_TYPE) {
                addMembersBulk(this.state.file)
                    .then((response) => {
                        if (isSuccessfulCreate(response)) {
                            this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        } else {
                            this.setState({uploading: false});
                            enableComponent(this, "disableField");
                            this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                        }
                    })
                    .catch(error => {
                        enableComponent(this, "disableField");
                        this.setState({uploading: false});
                        if (error.response) {
                            this.showNotification(error.response.data.message
                                ? error.response.data.message
                                : this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);

                            validateSession(error.response, this.props.history);
                        }
                    });
            } else if (importType === MEMBER_CARDS_IMPORT_TYPE) {
                let devKeys = await getDevKeys();

                if (devKeys !== null) {
                    let url = BASE_URL_PAYMENT_METHOD_SERVICE + MEMBER_CREDIT_CARDS_CSV;
                    const signature = await getSignature(url, this.state.file, getData("userTk"));

                    let headers = {
                        'accept-language': getLanguage(),
                        'Content-Type': 'application/json',
                        'x-secret-key': devKeys.secretKey,
                        'x-api-key': devKeys.apiKey,
                        'Authorization': getData("userTk"),
                        'Signature': signature
                    };

                    // send member card csv
                    addMembersCardsBulk(url, this.state.file, headers)
                        .then((response) => {
                            // if (isSuccessfulCreate(response)) {
                            //     this.setState({
                            //         csvResponseDetails: response.data
                            //     });
                            // } else {
                            //     this.setState({ uploading: false });
                            //     // enableComponent(this, "disableField");
                            //     // this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                            // }

                            this.props.closeForm(true, response.data);
                        })
                        .catch(error => {
                            enableComponent(this, "disableField");
                            this.setState({uploading: false});

                            if (error.response) {
                                if (error.response.data === null) {
                                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                                } else {
                                    this.props.closeForm(true, error.response.data);
                                }

                                validateSession(error.response, this.props.history);
                            } else {
                                this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                            }
                        });
                } else {
                    this.setState({uploading: false});
                    this.showNotification(this.props.t('DEVELOPER_KEYS_NOT_AVAILABLE'), "danger", NotificationFailedIcon, null);
                }
            } else {
                // invalid import setting
            }
        } else {
            this.setState({uploading: false}, () => {
                this.showNotification(this.props.t('EMPTY_CSV_MSG'), "danger", NotificationErrorIcon);
            });
        }
    }


        handleForce = (data, fileInfo) => {
            const {importType} = this.props;

            if (fileInfo !== null && fileInfo !== undefined && (fileInfo.type === DATA_TYPE_CSV_SUPPORTED || fileInfo.type === DATA_TYPE_CSV_EXCEL_SUPPORTED)) {
                if (data !== null && data !== undefined && data.length <= MAX_BULK_ITEMS) {
                    let validKeyNames = [];

                    if (importType === MEMBERS_IMPORT_TYPE) {
                        validKeyNames = ['name', 'role_id', 'external_id', 'email', 'access_code', 'phone'];
                    } else if (importType === MEMBER_CARDS_IMPORT_TYPE) {
                        validKeyNames = ['cardNumber', 'cardHolderName', 'cardExpirationDate', 'cardCvv', 'memberExternalId', 'memberId', 'billingAddress', 'billingCity', 'billingCountry', 'billingPostalCode', 'billingPhone', 'email'];
                    }

                    let dataCleaned = [];

                    data.forEach(row => {
                        dataCleaned.push(Object.keys(row)
                            .filter((k) => row[k] != null && row[k] !== undefined && row[k] !== "")
                            .reduce((a, k) => ({ ...a, [k]: row[k] }), {}));
                    });

                    if (this.checkArray(dataCleaned[0], validKeyNames)) {
                        this.setState({
                            file: dataCleaned,
                            src: fileInfo,
                            uploading: true
                        }, () => {
                            this.saveCollection();
                        });
                    } else {
                        this.showNotification(this.props.t(importType === MEMBERS_IMPORT_TYPE ? 'INVALID_CSV_STRUCTURE_MSG' : 'INVALID_CSV_STRUCTURE_CARD_MSG'), "danger", NotificationErrorIcon);
                    }
                } else {
                    this.showNotification(this.props.t('MAX_BULK_EXCEEDS_MSG'), "danger", NotificationErrorIcon);
                }
            } else {
                this.showNotification(this.props.t('INVALID_CSV_MSG'), "danger", NotificationErrorIcon);
            }
        };

        handleError = (error) => {
            this.showNotification(this.props.t('INVALID_CSV_MSG'), "danger", NotificationErrorIcon);
        };


        checkArray = (obj, arr) => {
            let result = false;

            try {

                result = Object.keys(obj).every(e => arr.includes(e));
            } catch (e) {
            }

            return result;
        };


        render()
        {
            const {classes, t, importType} = this.props;

            return (
                <div className={classes.imageEditorContainer}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            {
                                this.state.uploading
                                    ? <div style={{
                                        marginTop: 20,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}><Loading/></div>
                                    : (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "transparent",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div className={classes.buttonUploadFile}>
                                                <CSVReader
                                                    strict={true}
                                                    accept={".csv"}
                                                    label={t('BTN_SELECT_CSV_FILE')}
                                                    onFileLoaded={this.handleForce}
                                                    onError={this.handleError}
                                                    parserOptions={importType === MEMBERS_IMPORT_TYPE ? this.parseOptionsDefault : this.parseOptions}
                                                    inputStyle={{display: "none"}}
                                                />
                                                <Muted>{nullController(this.state.src, "name")}</Muted>

                                            </div>
                                        </div>
                                    )
                            }

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.rightPaper}>
                                <Button
                                    color="white"
                                    className={classes.buttonSpacing + " " + classes.cancelButton}
                                    onClick={() => this.props.closeForm(false, null)}
                                >
                                    {t('BTN_CANCEL')}
                                </Button>
                                <Button
                                    color="primary"
                                    className={classes.buttonSpacing}
                                    onClick={() => this.saveCollection()}
                                    disabled={this.state.disableField}
                                >
                                    {t('BTN_FINISH')}
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </div>
            );
        }
    }

MemberCsvUploadForm.propTypes = {
    classes: PropTypes.object,
    closeForm: PropTypes.func,
    component: PropTypes.object.isRequired,
    importType: PropTypes.string.isRequired,
};

export default withRouter(withStyles(formStyle)(withTranslation()(MemberCsvUploadForm)));