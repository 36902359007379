import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../../components/CustomInput/CustomInput.jsx";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core icons
import AppIcon from "@material-ui/icons/TouchApp";
import DescriptionIcon from "@material-ui/icons/Notes";
import NotificationErrorIcon from "@material-ui/icons/Error";
// @material-ui/react-select
import Select from "../../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
    selectValidation
} from "../../../../utils/helpersFunctions";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import {withRouter} from "react-router-dom";
import {PAYMENT_APP} from "../../../../variables/coreConstants";

const animatedComponents = makeAnimated();


class ApplicationDetailStep extends React.Component     {
    constructor(props) {
        super(props);
        this.state = {
            details: "details",
        };
        this.isValidated = this.isValidated.bind(this);
        this.sendState = this.sendState.bind(this);
    }



    handleChangeValues = (e) => {
        this.props.component.handleChangeValues(e);
    };
    changeTypeAppSelect = typeApp => {
        this.props.component.changeTypeAppSelect(typeApp);
    };
    changeAccessSelect = accessLevel => {
        this.props.component.changeAccessSelect(accessLevel);
    };
    changePaymentAmountSelect = paymentAmountOption => {
        this.props.component.changePaymentAmountSelect(paymentAmountOption);
    };
    changeMenuSelect = (menus) => {
        this.props.component.changeMenuSelect(menus);
    };
    changePublished = () => {
        this.props.component.changePublished();
    };


    // Method for wizard component
    sendState() {
        return this.state;
    }

    // Validate step form
    isValidated() {
        const { component, translation } = this.props;

        if (component.state.appName === "" || selectValidation(component.state.typeApp) || selectValidation(component.state.accessLevel)) {
            component.showNotification(translation('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);
            return false;

        } else {
            return true;
        }
    }



    render(){
        const { classes, component, translation } = this.props;

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={component.state.appName}
                            onChange={this.handleChangeValues}
                            labelText={translation('APP_NAME_TEXT')}
                            id="appName"
                            name="appName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <AppIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="typeApp" className={classes.selectTitle}>
                                {translation('TYPE_TEXT')}
                            </InputLabel>
                            <Select
                                value={component.state.typeApp}
                                onChange={this.changeTypeAppSelect}
                                components={animatedComponents}
                                isLoading={component.state.loadingTypes}
                                isDisabled={component.props.action}
                                options={component.state.typeAppOptions}
                                placeholder={translation('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={200}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={component.state.description}
                            onChange={this.handleChangeValues}
                            labelText={translation('DESCRIPTION_TEXT')}
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl}>
                            <InputLabel htmlFor="accessLevel" className={classes.selectTitle}>
                                {translation('TH_ACCESS_LEVEL')}
                            </InputLabel>
                            <Select
                                value={component.state.accessLevel}
                                onChange={this.changeAccessSelect}
                                components={animatedComponents}
                                isLoading={component.state.loadingAccess}
                                options={component.state.accessLevelOptions}
                                name="accessLevel"
                                classNamePrefix="accessLevel"
                                placeholder={translation('SELECT_TEXT')}
                                closeMenuOnSelect={true}
                                maxMenuHeight={100}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <FormControlLabel
                            className={classes.leftPaper}
                            control={
                                <CustomSwitch
                                    className={classes.switchBase}
                                    size="small"
                                    checked={component.state.published}
                                    onChange={this.changePublished}
                                />
                            }
                            label={translation('TH_PUBLISHED')}
                        />
                    </GridItem>
                    {
                        !selectValidation(component.state.typeApp) && component.state.typeApp.value === PAYMENT_APP.value ? (
                            <GridItem xs={12} sm={6} md={6}>
                                <div className={classes.selectControl}>
                                    <InputLabel htmlFor="paymentOption" className={classes.selectTitle}>
                                        {translation('TH_PAYMENT_AMOUNT_OPTION')}
                                    </InputLabel>
                                    <Select
                                        value={component.state.paymentAmountOption}
                                        onChange={this.changePaymentAmountSelect}
                                        components={animatedComponents}
                                        options={component.state.paymentOptions}
                                        name="paymentAmountOption"
                                        classNamePrefix="paymentAmountOption"
                                        placeholder={translation('SELECT_TEXT')}
                                        closeMenuOnSelect={true}
                                        maxMenuHeight={100}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                    />
                                </div>
                            </GridItem>
                        ) : null
                    }

                </GridContainer>
            </div>
        );
    }
}

ApplicationDetailStep.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    translation: PropTypes.func.isRequired
};

export default withRouter(withStyles(formStyle)(ApplicationDetailStep));