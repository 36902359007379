import {getService, postService, putService} from "./api";
import {
    ADD_MEMBER_PAYMENT_REQUEST,
    BASE_URL_COMMERCE,
    BASE_URL_CORE,
    CANCEL_PAYMENT_REQUEST,
    PAYMENT_DETAILS,
    PAYMENT_REQUEST,
    PAYMENT_REQUEST_BY_COMPANY, PAYMENT_REQUEST_CSV,
    PAYMENT_REQUEST_DETAILS,
    PAYMENTS_BY_COMPANY,
    SYNC_PAYMENT_REQUEST, SYNC_PAYMENT_REQUEST_MANUALLY,
    UPDATE_PAYMENT_REQUEST, UPDATE_PAYMENT_REQUEST_STATUS
} from "../variables/apiConstants";



export function getPaymentsByCompany(parameters) {
    let url = BASE_URL_CORE + PAYMENTS_BY_COMPANY;

    return postService(url, parameters);
}

export function getPaymentRequestByCompany(parameters) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_BY_COMPANY;

    return postService(url, parameters);
}

export function getPaymentRequestDetail(uuid) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_DETAILS + uuid;

    return getService(url);
}

export function addPaymentRequest(parameters) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST;

    return postService(url, parameters);
}

export function editPaymentRequest(parameters) {
    let url = BASE_URL_CORE + UPDATE_PAYMENT_REQUEST;

    return putService(url, parameters);
}

export function syncPaymentRequest(uuid) {
    let url = BASE_URL_COMMERCE + SYNC_PAYMENT_REQUEST;
    let parameters = { "paymentRequestId": uuid };

    return putService(url, parameters);
}


export function getPaymentDetails(uuid) {
    let url = BASE_URL_CORE + PAYMENT_DETAILS;
    let parameters = { "paymentId": uuid };

    return postService(url, parameters);
}


export function cancelPaymentRequest(parameters) {
    let url = BASE_URL_CORE + CANCEL_PAYMENT_REQUEST;

    return putService(url, parameters);
}

export function updatePaymentRequestStatus(parameters) {
    let url = BASE_URL_CORE + UPDATE_PAYMENT_REQUEST_STATUS;

    return putService(url, parameters);
}


export function generatePaymentRequestsCsv(parameters) {
    let url = BASE_URL_CORE + PAYMENT_REQUEST_CSV;

    return postService(url, parameters);
}

export function syncPaymentRequestManually(parameters) {
    let url = BASE_URL_COMMERCE + SYNC_PAYMENT_REQUEST_MANUALLY;

    return putService(url, parameters);
}

export function addMemberPaymentRequest(parameters, memberId) {
    let url = BASE_URL_CORE + ADD_MEMBER_PAYMENT_REQUEST.replace("{memberId}", memberId);

    return postService(url, parameters);
}
