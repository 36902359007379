import React from "react";
import {withRouter} from "react-router-dom";
import {withNotification} from "../../../components/HOC/Notification/NotificationHOC";
import {withTranslation} from "react-i18next";
import DirectDebitHistoryScreen from "./history/DirectDebitHistoryScreen";
import {getLastDirectDebitJobs, refreshPrivateServiceToken} from "../../../services/directDebitService";
import {getData, isEmpty, removeData, saveData} from "../../../utils/helpersFunctions";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {NOTIFICATION_DURATION_LARGE} from "../../../variables/coreConstants";


class DirectDebitHistoryController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingMore: false,
            isLoading: false,
            data: [],
            retryDebitToken: 0
        }
    }


    componentDidMount() {
        this.onRequestLastDebitJobs();
    }

    onRequestLastDebitJobs = () => {
        const { showNotificationMessage, t, match } = this.props;

        this.setState({
            isLoading: true
        }, () => {
            getLastDirectDebitJobs(match.params.id).then(r => {
                let data = r.data.map(i => {
                    i.createdDate = !isEmpty(i.createdDate) ? new Date(parseInt(i.createdDate)).toLocaleString() : "---";
                    i.lastModifiedDate = !isEmpty(i.lastModifiedDate) ? new Date(parseInt(i.lastModifiedDate)).toLocaleString() : "---";
                    return i;
                });

                this.setState({
                    isLoading: false,
                    data: data
                })
            }).catch(e => {
                if (!isEmpty(e.response)) {
                    if (this.state.retryDebitToken <= 2) {
                        refreshPrivateServiceToken(e.response, this.onRequestLastDebitJobs, (errorResponse) => {
                            this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                            showNotificationMessage(errorResponse.data.error.message, 'danger', NotificationErrorIcon,
                                NOTIFICATION_DURATION_LARGE, null);
                        });
                    } else {
                        this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                        showNotificationMessage(e.response.data.error.message, 'danger', NotificationErrorIcon,
                            NOTIFICATION_DURATION_LARGE, null);
                    }
                } else {
                    this.setState({ isLoading: false, retryDebitToken: 0, isLoadingMore: false });
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            });
        });
    }


    handlePrepareNew = () => {
        let debit = getData("debit");
        saveData("syncJobAvailable", true);
        removeData("isExecuted");
        removeData("syncJobId");
        removeData("syncSummary");
        removeData("debitExecution");

        this.props.history.push("/admin/debit/" + debit.id + "/prepare-sync");
    };

    handleSelect = (item) => {
        let debit = getData("debit");

        if (item["executed"] === "Yes" || item["executed"] === "Si") {
            saveData("syncJobAvailable", item["availableToExecute"]);
            saveData("syncJobId", item["id"]);
            saveData("isExecuted", true);
            removeData("syncSummary");
            saveData("debitExecution", {
                debitName: debit.name,
                executionJobId: item["id"],
                executionDate: item["createdDate"]
            });

            this.props.history.push("/admin/debit/" + debit.id + "/execution/" + item["id"]);
        } else {
            removeData("isExecuted");
            removeData("syncSummary");
            saveData("syncJobId", item["id"]);
            saveData("syncJobAvailable", item["availableToExecute"]);
            this.props.history.push("/admin/debit/" + debit.id + "/prepare-sync");
        }
    };


    render() {
        const { data, isLoading } = this.state;

        return <DirectDebitHistoryScreen
            data={data}
            isLoading={isLoading}
            onPrepareNew={this.handlePrepareNew}
            onSelectRow={this.handleSelect}
        />;
    };

}


export default withRouter(withNotification(withTranslation()(DirectDebitHistoryController)));
