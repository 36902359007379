/*
* Ginih Team
* Created by Marlon On February 17, 2020
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import {withRouter} from "react-router-dom";
import {
    ROWS_PER_PAGE_DETAILS,
} from "../../../variables/coreConstants";
import TableGridWijmo from "../../../components/WijmoTableGrid/TabledGridWijmo";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";


class CategoryProductDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            productData: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
        };
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };



    render() {
        const { classes, component, t, data } = this.props;

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={component.isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'category_product_assignments'}
                        title={t('PRODUCTS_TEXT')}
                        exportFileName={t('PRODUCTS_TEXT')}
                        data={data}
                        actions={[
                            { label: t('BTN_ASSIGN_PRODUCT'), function: component.openAssignProductForm, isLoading: false, disabled:false, type: "simple" },
                            { label: t('BTN_ADD_NEW_PRODUCT'), function: component.openProductForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_CODE'), name: "code", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_IMAGE'), name: "productPhoto", isReadOnly: true, width: "*", isCustomCell: true},
                            { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_DESCRIPTION'), name: "description", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: "*", isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: component.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}/>
                </GridItem>
            </GridContainer>
        );
    }
}

CategoryProductDetails.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(CategoryProductDetails)));
