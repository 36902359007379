import React from "react";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
// @material-ui/core icons
import CategoryIcon from "@material-ui/icons/CollectionsBookmark";
import DescriptionIcon from "@material-ui/icons/Notes";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import priceBookDetails from "../../../models/PriceBookModel";
import {addPriceBook, editPriceBook} from "../../../services/priceBookService";
import {
    disableComponent,
    enableComponent,
    getUser,
    isSuccessfulCreate,
    isSuccessfulRequest
} from "../../../utils/helpersFunctions";
import {ERROR_REQUEST_MESSAGE, NOTIFICATION_DURATION} from "../../../variables/coreConstants";


class PriceBookForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            priceBookName: priceBookDetails.name,
            description: priceBookDetails.description,
            isDefaultPriceBook: priceBookDetails.isDefault,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.savePriceBook = this.savePriceBook.bind(this);
        this.updatePriceBook = this.updatePriceBook.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    changeDefault = () => {
        this.setState(
            prevState => ({ isDefaultPriceBook: !prevState.isDefaultPriceBook})
        );
    };

    cleanData() {
        this.setState({
            priceBookName: '',
            description: '',
            isDefaultPriceBook: 0,
            notificationMessage: '',
            notification: false,
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openPriceBook", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    savePriceBook() {
        if (this.state.priceBookName === "" || this.state.description === "") {
            this.cleanData();
            this.showNotification("All fields are required", "danger", NotificationErrorIcon, null);

        } else {

            let user = getUser();
            let dataParameters = {
                "name": this.state.priceBookName,
                "description": this.state.description,
                "is_standard": 0,
                "last_modify_by": user.user_id,
            };

            addPriceBook(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        priceBookDetails.name = dataParameters.name;
                        this.cleanData();
                        this.showNotification("Price book added.", "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification("Bad request", "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    console.log(error);
                    enableComponent(this, "disableField");
                    this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                });
        }
    }

    updatePriceBook() {
        if (this.state.categoryName === "") {
            this.showNotification("Category name is required", "danger");

        } else {

            let user = getUser();
            let dataParameters = {
                "name": this.state.categoryName,
                "description": this.state.description,
                "is_standard": 0,
                "last_modify_by": user.user_id
            };

            disableComponent(this, "disableField");
            editPriceBook(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification("Price book edited.", "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification("Bad request", "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(ERROR_REQUEST_MESSAGE, "danger", NotificationFailedIcon, null);
                    console.log(error);
                });
        }
    }

    render(){
        const { classes } = this.props;

        const SavePriceBookButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.savePriceBook()}
                disabled={this.state.disableField}
            >
                Save Category
            </Button>
        );

        const UpdatePriceBookButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updatePriceBook()}
                disabled={this.state.disableField}
            >
                Save Changes
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.priceBookName}
                            onChange={this.handleChangeValues}
                            labelText="Price Book Name"
                            id="priceBookName"
                            name="priceBookName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <CategoryIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.description}
                            onChange={this.handleChangeValues}
                            labelText="Description"
                            id="description"
                            name="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openPriceBook", false, false)}
                            >
                                Cancel
                            </Button>
                            {this.props.action ? <UpdatePriceBookButton/> : <SavePriceBookButton />}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

PriceBookForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(PriceBookForm));