import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-react/customCheckboxRadioSwitch.jsx";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Button from "../../../components/CustomButtons/Button";


const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "0px"
    },
    ...customCheckboxRadioSwitch,
    ...formStyle
};

class OptionSelectorModal extends React.Component {
    render() {
        const { classes, title, onSelectOption, options, onClose, btnCloseLabel } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <h4 className={classes.infoText}>{title}</h4>
                        <GridContainer justify="center">
                            {
                                options.map(op => {
                                    return (
                                        <GridItem xs={12} sm={4} md={5} lg={5} key={op.state}>
                                            <div className={classes.choiche}>
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={() => onSelectOption(op.state)}
                                                    checked={op.selected}
                                                    checkedIcon={
                                                        <op.icon
                                                            className={classes.iconCheckboxIcon}
                                                        />
                                                    }
                                                    icon={
                                                        <op.icon
                                                            className={classes.iconCheckboxIcon}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.iconCheckboxChecked,
                                                        root: classes.iconCheckbox
                                                    }}
                                                />
                                                <h6 style={{marginBlockStart: "0.40em", marginBlockEnd: "0.40em"}}>
                                                    {op.label}
                                                </h6>
                                            </div>
                                        </GridItem>
                                    )
                                })
                            }
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => onClose("openModal", false, false)}
                            >
                                {btnCloseLabel}
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


OptionSelectorModal.propTypes = {
    classes: PropTypes.object,
    onSelectOption: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    btnCloseLabel: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element,
            state: PropTypes.string,
            selected: PropTypes.bool,
            label: PropTypes.string
        })
    ).isRequired,
    title: PropTypes.string.isRequired
};

export default withStyles(style)(OptionSelectorModal);
