import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {withTranslation} from "react-i18next";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Loading from "../../components/Loading/Loading";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class DirectDebitsScreen extends React.Component {
    render() {
        const { onAdd, onSelectRow, t, states, onLoadMore } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {states.isLoading ?
                        <Loading/>
                        : (
                            <TableGridWijmo
                                tableName={'direct_debits'}
                                title={t('DEBITS_TEXT')}
                                exportFileName={t('DEBITS_TEXT')}
                                handleLoadMore={onLoadMore}
                                data={states.data}
                                actions={[
                                    { label: t('BTN_ADD_DEBIT'), function: onAdd, isLoading: false, disabled: false, type: "simple" }
                                ]}
                                controls={[]}
                                headers={[
                                    { label: "No", name: "recordNum", cssClass: "cell-number", isReadOnly: true, width: 50 },
                                    { label: t('TH_NAME'), name: "name", isReadOnly: true, width: "*", onSelect: onSelectRow, isCustomCell: true, clickable: true },
                                    { label: t('TH_CREATED_AT'), name: "createdDate", isReadOnly: true, width: "*", isCustomCell: false },
                                    { label: t('TH_EXPIRING_BY'), name: "paymentExpiringBefore", isReadOnly: true, width: "*", isCustomCell: false }
                                ]}
                            />
                        )
                    }
                </GridItem>
            </GridContainer>
        );
    }
}


DirectDebitsScreen.propTypes = {
    classes: PropTypes.object,
    states: PropTypes.object,
    onAdd: PropTypes.func,
    onTableAction: PropTypes.func,
    onSelectRow: PropTypes.func,
    onSearch: PropTypes.func,
    onLoadMore: PropTypes.func,
    handleChangePage: PropTypes.func,
    handleLastPage: PropTypes.func,
    handleFirstPage: PropTypes.func,
    handlePreviousPage: PropTypes.func,
    handleNextPage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DirectDebitsScreen)));