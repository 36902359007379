var storeDetails = {
    createdAt: null,
    createdByUser: null,
    id: null,
    uuid: "",
    externalId: "",
    name: "",
    phoneNumber: "",
    postalCode: "",
    status: null,
    address: null,
    placeDescription: null,
    addressDetails: "",
    country: {
        value: 0,
        label: "Seleccionar...",
    },
    storeType: {
        value: 0,
        label: 'Seleccionar...',
    },
    explorerMode: {
        value: 0,
        label: 'Seleccionar...',
    },
    city: "",
    company: {
        id: null,
        address: "",
        status: null,
        fullName: "",
        phoneNumber: ""
    },
    place: {
        formattedAddress: "",
        latitude: 0,
        longitude: 0,
        extReference: "",
        extPlaceId: "",
        details: ""
    },
    logo: null,
    headerImage: null,


    getStoreId : function () {
        return this.id;
    },

    setStoreDetail : function (values) {
        try {
            this.id = values.id;
            this.name = values.name;
            this.uuid = values.uuid;
            this.externalId = values.externalId;
            this.placeDescription = values.address;
            this.address = values.placeId.formattedAddress;
            this.phoneNumber = values.phoneNumber;
            this.postalCode = values.postalCode;
            this.addressDetails = values.placeId.details;
            this.country.value = values.countryId.id;
            this.country.label = values.countryId.name;
            this.storeType.value = values.storeTypeId.id;
            this.storeType.label = values.storeTypeId.type;
            this.city = values.city;
            this.logo = values.logo;
            this.headerImage = values.headerImage;
            this.place.formattedAddress = values.placeId.formattedAddress;
            this.place.extPlaceId = values.placeId.externalPlaceIdentifier;
            this.place.extReference = values.placeId.externalReference;
            this.place.latitude = values.placeId.latitude;
            this.place.longitude = values.placeId.longitude;
            this.place.details = values.placeId.details;
        } catch (e) {
            console.log("Something went wrong");
        }
    },

    cleanStoreDetails : function () {
        this.createdAt = null;
        this.createdByUser = null;
        this.id = null;
        this.uuid = "";
        this.externalId = "";
        this.name = "";
        this.phoneNumber = "";
        this.postalCode = "";
        this.status = null;
        this.logo = null;
        this.headerImage = null;
        this.address = "";
        this.placeDescription = "";
        this.country.label = "Seleccionar...";
        this.country.value = 0;
        this.storeType.label = "Seleccionar...";
        this.explorerMode.value = 0;
        this.explorerMode.label = "Seleccionar...";
        this.storeType.value = 0;
        this.city = "";
        this.addressDetails = "";
        this.company.id = null;
        this.company.address = "";
        this.company.status = null;
        this.company.fullName = "";
        this.company.phoneNumber = "";
    }


};

export default storeDetails;