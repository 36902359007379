import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    APPLICATION_ZONE,
    BASE_URL_CORE,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import TableBody from "@material-ui/core/TableBody";
import {
    APPLICATION_DETAILS_TABLE_ACTIONS, NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import Status from "../../../components/Status/Status";
import {getData, isSuccessfulRequest, nullController, saveData} from "../../../utils/helpersFunctions";
import {getApplicationsByZone} from "../../../services/applicationsService";
import {statusController} from "../../../services/statusService";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import ApplicationAssignForm from "./AppZoneAssignmentForm";
import Muted from "../../../components/Typography/Muted";
import applicationDetails from "../../../models/ApplicationModel";
import {validateSession} from "../../../services/api";


class ZoneApplicationsDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openApp: false,
            isLoading: true,
            appsData: [],
            filteredApps: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openApp", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleOpenForm = () => {
        this.setState({openApp: true});
        this.setState({actionType: 0});
        // appDetails.cleanAssetDetails();
    };
    handleCloseForm = () => {
        this.setState({openApp: false});
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type)
            this.request();
    }
    openForm = (action, formData) => {
        if (action === 1 || action === 0) {
            this.setState({openApp: true});
            this.setState({actionType: action});
        }
        else {
            let dataParameters = {
                "app_zone_id": formData.id
            };

            statusController(action, BASE_URL_CORE, APPLICATION_ZONE, dataParameters, this, false)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, false);
                    }
                })
                .catch(error => {
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, false);
                });
        }

    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    request(){
        this.setState({ isLoading: true} );
        let zone = getData("zone");

        getApplicationsByZone(zone.id)
            .then((res) => {
                this.setState({appsData: res.data});
                this.setState({filteredApps: res.data});
                this.setState({ isLoading: false});
                if (res.data.length === 0)
                {
                    this.props.quickAction("isQuickStartApp", true);
                } else {
                    this.props.quickAction("isQuickStartApp", false);
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false} );
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                }
            });
    }

    goToDetails = (val) =>{
        saveData("app", val);
        applicationDetails.name = val.name;
        applicationDetails.id = val.id;
        this.props.history.push("quick-actions-application");
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.typo}>
                                <h4> {t('APPLICATIONS_TEXT')} </h4>
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.rightPaper}>
                                <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_ASSIGN_APP')}</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_DESCRIPTION')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACCESS_LEVEL')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STATUS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredApps.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, 'name')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'application_type')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'accessLevel')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    openForm={this.openForm}
                                                                    options={APPLICATION_DETAILS_TABLE_ACTIONS}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.filteredApps.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"xs"}
                        fullWidth={true}
                        open={this.state.openApp}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_APP_TITLE')}
                            <Muted>
                                {t('FORM_ASSIGN_APP_DESCRIPTION')}
                            </Muted>
                        </DialogTitle>
                        <DialogContent>
                            <ApplicationAssignForm closeForm={this.closeForm}/>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridItem>
            </GridContainer>
        );
    }
}

ZoneApplicationsDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(ZoneApplicationsDetails)));