import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {useTranslation} from "react-i18next";
import HeaderForm from "../../../../components/CustomComponents/HeaderForm";
import Select from "../../../../components/CustomSelect/CustomSelect";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {withRouter} from "react-router-dom";



function CashAccountFormDetails({ ...props }) {
    const { classes, component } = props;
    const {t} = useTranslation();


    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.headerFormContainerSmall}>
                                <HeaderForm title={t('FORM_NEW_ACCOUNT_TITLE')} description={t('FORM_NEW_ACCOUNT_DESCRIPTION')} />
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.formBodyContainer}>
                                <CustomInput
                                    value={component.state.alias}
                                    onChange={component.handleChangeValues}
                                    labelText={t('ALIAS_TEXT')}
                                    id="alias"
                                    name="alias"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.inputFormContainer
                                    }}
                                />
                                <CustomInput
                                    value={component.state.name}
                                    onChange={component.handleChangeValues}
                                    labelText={t('BENEFICIARY_LEGAL_NAME_TEXT')}
                                    id="name"
                                    name="name"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.inputFormContainer
                                    }}
                                />
                                <CustomInput
                                    value={component.state.account}
                                    onChange={component.handleChangeValues}
                                    labelText={t('ACCOUNT_IBAN_TXT')}
                                    id="account"
                                    name="account"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.inputFormContainer
                                    }}
                                />
                                <div className={classes.selectContainerTop}>
                                    <Select
                                        value={component.state.provider}
                                        onChange={component.changeProviderSelect}
                                        options={component.state.providerOptions}
                                        closeMenuOnSelect={true}
                                        placeholder={t('BANK_PROVIDER_TXT') + " ..."}
                                        maxMenuHeight={400}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 5 })
                                        }}
                                    />
                                </div>
                                <div className={classes.selectContainerTop}>
                                    <Select
                                        value={component.state.currency}
                                        onChange={component.changeCurrencySelect}
                                        options={component.state.currencyOptions}
                                        closeMenuOnSelect={true}
                                        placeholder={t('CURRENCY_TXT') + "..."}
                                        maxMenuHeight={400}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 5 })
                                        }}
                                    />
                                </div>
                                <div className={classes.selectContainerTop}>
                                    <Select
                                        value={component.state.accountType}
                                        onChange={component.changeTypeSelect}
                                        options={component.state.typeOptions}
                                        closeMenuOnSelect={true}
                                        placeholder={t('ACCOUNT_TYPE_TXT') + "..."}
                                        maxMenuHeight={400}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: primaryColor[0],
                                            },
                                        })}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 5 })
                                        }}
                                    />
                                </div>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}


CashAccountFormDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    component: PropTypes.object,
};

export default withRouter(withStyles(dashboardStyle)(CashAccountFormDetails));
