import React from "react";
// nodejs library to set properties for components
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {
    disableComponent,
    enableComponent, getRoundNumber, getValueFromCurrencyMask, isSuccessfulCreate,
    isSuccessfulRequest,
    selectValidation, setCurrencyValue, validateNewValue
} from "../../../utils/helpersFunctions";
import {MENU, NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    addApplication,
    editApplication,
    getAccessLevels,
    getMenuAppSettingsByApplication, getPaymentAmountOptions,
    getTagsAppType, getTagsAvailableByApp, getTipPercentages,
    getTypesApplications
} from "../../../services/applicationsService";
import applicationDetails from "../../../models/ApplicationModel";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {getMenusAssignmentsByApp, getMenusByCompany} from "../../../services/menuService";

import ApplicationDetailStep from "./wizard/ApplicationDetailStep.jsx";
import ApplicationSettingStep from "./wizard/ApplicationSettingStep.jsx";
import ApplicationReleaseStep from "./wizard/ApplicationReleaseStep.jsx";
import Wizard from "../../../components/Wizard/Wizard";
import Snackbar from "../../../components/Snackbar/Snackbar";


class ApplicationForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            appName: applicationDetails.name,
            description: applicationDetails.description,
            typeApp: applicationDetails.type,
            accessLevel: applicationDetails.accessLevel,
            paymentAmountOption: applicationDetails.paymentAmountOption,
            menus: [],
            cuisines: [],
            oldMenus: [],
            oldCuisines: [],
            isAutoOpen: applicationDetails.isAutoOpen,
            isCompact: applicationDetails.isCompact,
            typeAppOptions: [],
            accessLevelOptions: [],
            menusOptions: [],
            paymentOptions: [],
            cuisinesOptions: [],
            published: applicationDetails.published,
            takeAway: applicationDetails.takeAway,
            dineIn: applicationDetails.dineIn,
            delivery: applicationDetails.delivery,
            takeAwayMinimumMask: setCurrencyValue(applicationDetails.takeAwayMinimum),
            takeAwayMinimum: applicationDetails.takeAwayMinimum,
            dineInMinimumMask: setCurrencyValue(applicationDetails.dineInMinimum),
            dineInMinimum: applicationDetails.dineInMinimum,
            deliveryMinimumMask: setCurrencyValue(applicationDetails.deliveryMinimum),
            deliveryMinimum: applicationDetails.deliveryMinimum,
            deliveryRadiusMask: setCurrencyValue(applicationDetails.deliveryRadius),
            deliveryRadius: applicationDetails.deliveryRadius,
            deliveryBaseFeeRadiusMask: setCurrencyValue(applicationDetails.deliveryBaseFeeRadius),
            deliveryBaseFeeRadius: applicationDetails.deliveryBaseFeeRadius,
            deliveryBaseFeeMask: setCurrencyValue(applicationDetails.deliveryBaseFee),
            deliveryBaseFee: applicationDetails.deliveryBaseFee,
            deliveryExtraFeeMask: setCurrencyValue(applicationDetails.deliveryExtraFee),
            deliveryExtraFee: applicationDetails.deliveryExtraFee,
            deliveryTip: applicationDetails.deliveryTip,
            instructions: applicationDetails.instructions,
            dineInTip: applicationDetails.dineInTip,
            takeAwayTip: applicationDetails.takeAwayTip,
            deliveryPercentage: '',
            dineInPercentage: '',
            takeAwayPercentage: '',
            tipPercentagesOptions: [],
            loadingTypes: true,
            loadingAccess: true,
            loadingMenus: true,
            loadingCuisines: true,
            loadingSetting: true,
            showMenu: false,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
            isSettingEnabled: false,
        };
        this.saveApp = this.saveApp.bind(this);
        this.updateApp = this.updateApp.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }


    componentDidMount() {
        if (this.state.typeApp.value === MENU.value) {
            // this.loadApplicationSettings();
            this.setState({
                showMenu: true,
                isSettingEnabled: true,
            });
        }
        this.request();
    }


    cleanData() {
        this.setState({
            appName: '',
            instructions: '',
            description: '',
            typeApp: '',
            accessLevel: '',
            menus: '',
            isAutoOpen: false,
            isCompact: false,
            published: false,
            takeAway: false,
            dineIn: false,
            delivery: false,
            deliveryTip: false,
            dineInTip: false,
            cuisines: '',
        });
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openForm", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };


    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };


    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };


    handleChangeSetting = (name, event) => {
        this.setState({[name]: event.target.checked});
        if (name === 'delivery' && !event.target.checked)
            this.setState({ deliveryMinimum: 0.00, deliveryMinimumMask: getRoundNumber(0) });
    };


    changeTypeAppSelect = typeApp => {
        this.setState({ typeApp });
        if (typeApp.value === MENU.value) {
            if (!this.props.action)
                this.loadApplicationSettings(typeApp);

            this.setState({ showMenu: true, isSettingEnabled: true, isLoadingSetting: true });

        } else {
            this.setState({ showMenu: false, isSettingEnabled: false, isLoadingSetting: false });
        }
    };

    handleChangeTip = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }));
    };



    changeAccessSelect = accessLevel => {
        this.setState({ accessLevel });
    };
    changePaymentAmountSelect = paymentAmountOption => {
        this.setState({ paymentAmountOption });
    };

    changeMenuSelect = (menus) => {
        this.setState({ menus });
    };

    changeDeliveryPercentageSelect = (deliveryPercentage) => {
        this.setState({ deliveryPercentage });
    };

    changeDineInPercentageSelect = (dineInPercentage) => {
        this.setState({ dineInPercentage });
    };

    changeTakeAwayPercentageSelect = (takeAwayPercentage) => {
        this.setState({ takeAwayPercentage });
    };


    changeTagAppSelect = (cuisines) => {
        if (cuisines !== null) {
            if (cuisines.length <= 3 ) {
                this.setState({ cuisines });

            } else {
                this.showNotification("Sólo puedes seleccionar 3 etiquetas por aplicación.", "danger",
                    NotificationErrorIcon, null);
            }

        } else {
            this.setState({ cuisines });
        }
    };


    changePublished = () => {
        this.setState(prevState => ({
            published: !prevState.published
        }));
    };

    changeCompact = () => {
        this.setState(prevState => ({
            isCompact: !prevState.isCompact
        }));
    };


    // Method that request selects data
    request() {
        let accessValues;
        getAccessLevels()
            .then((res) => {
                accessValues = res.data.map(item => {
                    return { value: item.id , label: item.name };
                });

                this.setState({ accessLevelOptions: accessValues});
                this.setState({ loadingAccess: false} );

                if (!this.props.action)
                    this.setState({ accessLevel: accessValues[0] })

            })
            .catch((err) => {
                this.setState({ loadingAccess: false} );
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });


        let typesAppsValues;
        getTypesApplications()
            .then((res) => {
                typesAppsValues = res.data.map( item => {
                    return { value: item.id , label: item.name };
                });

                this.setState({ typeAppOptions: typesAppsValues});
                this.setState({ loadingTypes: false} );

            })
            .catch((err) => {
                this.setState({ loadingTypes: false} );
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });

        let paymentAmountOptions;
        getPaymentAmountOptions()
            .then((res) => {
                paymentAmountOptions = res.data.map( item => {
                    return { value: item.id , label: this.props.t(item.translateCode.toUpperCase()) };
                });

                this.setState({
                    paymentOptions: paymentAmountOptions,
                    paymentAmountOption: this.props.action ? this.state.paymentAmountOption : paymentAmountOptions[0]
                });

            })
            .catch((err) => {
                this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }


    loadApplicationSettings(appType) {
        let tagsValues;
        let menusValues;

        if (this.props.action && (applicationDetails.type !== []) && (applicationDetails.type.value === MENU.value)) {
            // Get menus assigned and unassigned by application
            getMenusAssignmentsByApp()
                .then((response) => {
                    // Set menus available
                    menusValues = response.data.unassignedCatalogs.map( item => {
                        return { value: item.id , label: item.name };
                    });


                    // Set menus on application
                    let menus = response.data.assignedCatalogs.map( item => {
                        return { value: item.catalogId.id , label: item.catalogId.name };
                    });

                    let oldMenus = response.data.assignedCatalogs.map( item =>
                    { return { assignId: item.id, entityId: item.catalogId.id } });

                    this.setState({
                        menus: menus,
                        oldMenus: oldMenus,
                        menusOptions: menusValues,
                        loadingMenus: false
                    });

                })
                .catch((err) => {
                    this.setState({ loadingMenus: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });

            // Get menu app settings
            getMenuAppSettingsByApplication()
                .then((res) => {
                    let details = res.data;
                    if (details.menuApplicationSetting !== null) {
                        let deliveryPercentage = { value: 1, label: "0%"};
                        let dineInPercentage = { value: 1, label: "0%"};
                        let takeAwayPercentage = { value: 1, label: "0%"};

                        try {
                            deliveryPercentage.value = details.menuApplicationSetting.deliveryPercentage.id;
                            deliveryPercentage.label = details.menuApplicationSetting.deliveryPercentage.value + "%";
                            dineInPercentage.value = details.menuApplicationSetting.dineInPercentage.id;
                            dineInPercentage.label = details.menuApplicationSetting.dineInPercentage.value + "%";
                            takeAwayPercentage.value = details.menuApplicationSetting.takeAwayPercentage.id;
                            takeAwayPercentage.label = details.menuApplicationSetting.takeAwayPercentage.value + "%";
                        } catch (e) {
                            console.log(e);
                        }


                        let valuesPercentages = details.tipPercentages.map( item => {
                            return { value: item.id , label: item.value + "%" };
                        });

                        this.setState({
                            takeAway: details.menuApplicationSetting.takeAway,
                            dineIn: details.menuApplicationSetting.dineIn,
                            delivery: details.menuApplicationSetting.delivery,
                            dineInMinimum: details.menuApplicationSetting.dineInMinimum,
                            takeAwayMinimum: details.menuApplicationSetting.takeAwayMinimum,
                            deliveryMinimum: details.menuApplicationSetting.deliveryMinimum,
                            deliveryRadius: details.menuApplicationSetting.deliveryRadius,
                            dineInMinimumMask: getRoundNumber(details.menuApplicationSetting.dineInMinimum),
                            takeAwayMinimumMask: getRoundNumber(details.menuApplicationSetting.takeAwayMinimum),
                            deliveryMinimumMask: getRoundNumber(details.menuApplicationSetting.deliveryMinimum),
                            deliveryRadiusMask: getRoundNumber(details.menuApplicationSetting.deliveryRadius),
                            deliveryTip: details.menuApplicationSetting.deliveryTip,
                            deliveryBaseFeeRadius: details.menuApplicationSetting.deliveryBaseFeeRadius,
                            deliveryExtraFee: details.menuApplicationSetting.deliveryExtraFee,
                            deliveryBaseFee: details.menuApplicationSetting.deliveryBaseFee,
                            deliveryBaseFeeRadiusMask: getRoundNumber(details.menuApplicationSetting.deliveryBaseFeeRadius),
                            deliveryExtraFeeMask: getRoundNumber(details.menuApplicationSetting.deliveryExtraFee),
                            deliveryBaseFeeMask: getRoundNumber(details.menuApplicationSetting.deliveryBaseFee),
                            dineInTip: details.menuApplicationSetting.dineinTip,
                            takeAwayTip: details.menuApplicationSetting.takeAwayTip,
                            takeAwayPercentage: takeAwayPercentage,
                            dineInPercentage: dineInPercentage,
                            deliveryPercentage: deliveryPercentage,
                            tipPercentagesOptions: valuesPercentages,
                            instructions: details.menuApplicationSetting.instruction,
                        });

                        applicationDetails.delivery = details.menuApplicationSetting.delivery;
                        applicationDetails.takeAway = details.menuApplicationSetting.takeAway;
                        applicationDetails.dineIn = details.menuApplicationSetting.dineIn;
                        applicationDetails.deliveryMinimum = getRoundNumber(details.menuApplicationSetting.deliveryMinimum);
                        applicationDetails.takeAwayMinimum = getRoundNumber(details.menuApplicationSetting.takeAwayMinimum);
                        applicationDetails.dineInMinimum = getRoundNumber(details.menuApplicationSetting.dineInMinimum);
                        applicationDetails.deliveryRadius = getRoundNumber(details.menuApplicationSetting.deliveryRadius);
                        applicationDetails.deliveryTip = details.menuApplicationSetting.deliveryTip;
                        applicationDetails.deliveryBaseFeeRadius = getRoundNumber(details.menuApplicationSetting.deliveryBaseFeeRadius);
                        applicationDetails.deliveryExtraFee = getRoundNumber(details.menuApplicationSetting.deliveryExtraFee);
                        applicationDetails.deliveryBaseFee = getRoundNumber(details.menuApplicationSetting.deliveryBaseFee);
                        applicationDetails.dineInTip = details.menuApplicationSetting.dineinTip;
                        applicationDetails.takeAwayTip = details.menuApplicationSetting.takeAwayTip;
                        applicationDetails.takeAwayPercentage = takeAwayPercentage;
                        applicationDetails.dineInPercentage = dineInPercentage;
                        applicationDetails.deliveryPercentage = deliveryPercentage;
                        applicationDetails.instructions = details.menuApplicationSetting.instruction;
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                            NotificationFailedIcon, null);
                    }
                });

            // Get menu app settings
            getTagsAvailableByApp()
                .then((res) => {
                    let details = res.data;
                    // Set menus available
                    tagsValues = details.unassignedTags.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    // Set menus on application
                    let tags = details.assignedTags.map( item => {
                        return { value: item.tagId.id , label: item.tagId.name };
                    });

                    let oldTags = details.assignedTags.map( item => {
                        return { assignId: item.id, entityId: item.tagId.id }
                    });

                    this.setState({
                        cuisines: tags,
                        oldCuisines: oldTags,
                        cuisinesOptions: tagsValues,
                        loadingCuisines: false
                    });
                })
                .catch((err) => {
                    this.setState({ loadingCuisines: false });

                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(err.response, this.props.history);
                    }
                });

        } else {
            applicationDetails.type = appType;

            // Get all menus by company
            getMenusByCompany()
                .then((res) => {
                    menusValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    this.setState({ menusOptions: menusValues });
                    this.setState({ loadingMenus: false });

                })
                .catch((err) => {
                    this.setState({ loadingMenus: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });

            // Get tags
            getTagsAppType()
                .then((res) => {
                    tagsValues = res.data.map( item => {
                        return { value: item.id , label: item.name };
                    });

                    this.setState({ cuisinesOptions: tagsValues });
                    this.setState({ loadingCuisines: false });

                })
                .catch((err) => {
                    this.setState({ loadingCuisines: false });
                    this.showNotification(err.response.data.message, "danger", NotificationFailedIcon, null);

                    if (err.response)
                        validateSession(err.response, this.props.history);
                });

            // Get tip percentages
            getTipPercentages()
                .then((res) => {
                    let values = res.data.map( item => {
                        return { value: item.id , label: item.value + "%" };
                    });

                    this.setState({
                        tipPercentagesOptions: values,
                        deliveryPercentage: !this.props.action && values[0],
                        dineInPercentage: !this.props.action && values[0],
                        takeAwayPercentage: !this.props.action && values[0],
                    });
                })
                .catch((err) => {
                    if (err.response) {
                        this.showNotification(err.response.data.message, "danger", NotificationFailedIcon,
                            null);

                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon,
                            null);
                    }
                });
        }
    }


    handleFinish(event) {
        if (this.props.action) {
            this.updateApp(event);
        } else {
            this.saveApp(event);
        }
    }

    saveApp(e) {
        if (this.state.appName === "" || selectValidation(this.state.typeApp) || selectValidation(this.state.accessLevel) || selectValidation(this.state.paymentAmountOption)) {
            this.showNotification(this.props.t('APP_FIELDS_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

        } else {
            let catalogsId;
            if (selectValidation(this.state.menus)) {
                catalogsId = [];
            } else {
                catalogsId = this.state.menus.map( item => {
                    return { id: item.value };
                });
            }

            let cuisinesId;
            if (selectValidation(this.state.cuisines)) {
                cuisinesId = [];
            } else {
                cuisinesId = this.state.cuisines.map( item => {
                    return { id: item.value };
                })
            }

            let dataParameters = {
                "name": this.state.appName,
                "description": this.state.description,
                "typeId": this.state.typeApp.value,
                "isCompact": this.state.isCompact,
                "autoOpen": this.state.isAutoOpen,
                "accessLevelId": this.state.accessLevel.value,
                "isPublished": this.state.published,
                "catalogs": JSON.stringify(catalogsId),
                "cuisines": JSON.stringify(cuisinesId),
                "takeAway": this.state.takeAway,
                "dineIn": this.state.dineIn,
                "deliveryBaseFeeRadius": this.state.deliveryBaseFeeRadius,
                "deliveryExtraFee": this.state.deliveryExtraFee,
                "deliveryBaseFee": this.state.deliveryBaseFee,
                "delivery": this.state.delivery,
                "deliveryMinimum": this.state.deliveryMinimum,
                "deliveryRadius": this.state.deliveryRadius,
                "takeAwayMinimum": this.state.takeAwayMinimum,
                "dineInMinimum": this.state.dineInMinimum,
                "dineinTip": this.state.dineInTip,
                "deliveryTip": this.state.deliveryTip,
                "takeAwayTip": this.state.takeAwayTip,
                "takeAwayPercentage": this.state.takeAwayPercentage.value,
                "dineInPercentage": this.state.dineInPercentage.value,
                "deliveryPercentage": this.state.deliveryPercentage.value,
                "instructions": this.state.instructions,
                "payment_amount_option": this.state.paymentAmountOption.value
            };


            disableComponent(this, "disableField");
            addApplication(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response.data.message, this.props.history);
                    }
                });
        }
    }



    updateApp(e) {
        const { t } = this.props;
        if (this.state.appName === "" || selectValidation(this.state.typeApp) || selectValidation(this.state.accessLevel) || selectValidation(this.state.paymentAmountOption)) {
            this.showNotification(t('APP_FIELDS_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

        } else {
            let catalogsId;
            if (selectValidation(this.state.menus)) {
                catalogsId = [];
            } else {
                catalogsId = this.state.menus.map( item => {
                    if (validateNewValue(this.state.oldMenus, "entityId", item.value)) {
                        let index = this.state.oldMenus.findIndex(val => val.entityId === item.value);
                        return {
                            assignId: this.state.oldMenus[index].assignId,
                            entityId: this.state.oldMenus[index].entityId
                        };

                    } else {
                        return { assignId: null, entityId: item.value };
                    }
                });
            }

            let cuisinesId;
            if (selectValidation(this.state.cuisines)) {
                cuisinesId = [];
            } else {
                cuisinesId = this.state.cuisines.map( item => {
                    if (validateNewValue(this.state.oldCuisines, "entityId", item.value)) {
                        let index = this.state.oldCuisines.findIndex(val => val.entityId === item.value);
                        return {
                            assignId: this.state.oldCuisines[index].assignId,
                            entityId: this.state.oldCuisines[index].entityId
                        };

                    } else {
                        return { assignId: null, entityId: item.value };
                    }
                });
            }


            let dataParameters = {
                "name": this.state.appName,
                "description": this.state.description,
                "typeId": this.state.typeApp.value,
                "accessLevelId": this.state.accessLevel.value,
                "isPublished": this.state.published,
                "catalogs": JSON.stringify(catalogsId),
                "oldCatalogs": JSON.stringify(this.state.oldMenus),
                "cuisines": JSON.stringify(cuisinesId),
                "oldCuisines": JSON.stringify(this.state.oldCuisines),
                "takeAway": this.state.takeAway,
                "dineIn": this.state.dineIn,
                "delivery": this.state.delivery,
                "deliveryBaseFeeRadius": this.state.deliveryBaseFeeRadius,
                "deliveryExtraFee": this.state.deliveryExtraFee,
                "deliveryBaseFee": this.state.deliveryBaseFee,
                "deliveryMinimum": this.state.deliveryMinimum,
                "deliveryRadius": this.state.deliveryRadius,
                "takeAwayMinimum": this.state.takeAwayMinimum,
                "dineInMinimum": this.state.dineInMinimum,
                "dineinTip": this.state.dineInTip,
                "deliveryTip": this.state.deliveryTip,
                "takeAwayTip": this.state.takeAwayTip,
                "takeAwayPercentage": this.state.takeAwayPercentage.value,
                "dineInPercentage": this.state.dineInPercentage.value,
                "deliveryPercentage": this.state.deliveryPercentage.value,
                "instructions": this.state.instructions,
                "payment_amount_option": this.state.paymentAmountOption.value
            };


            disableComponent(this, "disableField");
            editApplication(dataParameters, applicationDetails.id)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response.data.message, this.props.history);
                    }
                });
        }
    }


    render(){
        const { classes, t } = this.props;
        const { isSettingEnabled } = this.state;

        return (
            <div className={classes.formContainer}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <Wizard
                            validate
                            component={this}
                            finishButtonText={this.props.action ? t('BTN_SAVE_CHANGES') : t('BTN_FINISH')}
                            steps={[
                                {
                                    stepName: t('STEP_DETAILS_TEXT'),
                                    stepComponent: ApplicationDetailStep,
                                    stepId: "detail",
                                    stepEnabled: true
                                },
                                {
                                    stepName: t('STEP_SETTING_TEXT'),
                                    stepComponent: ApplicationSettingStep,
                                    stepId: "setting",
                                    stepEnabled: isSettingEnabled
                                },
                                {
                                    stepName: t('STEP_RELEASE_TEXT'),
                                    stepComponent: ApplicationReleaseStep,
                                    stepId: "release",
                                    stepEnabled: false
                                }
                            ]}
                            color={"primary"}
                            withHeader={false}
                            fullWidth={true}
                            finishButtonClick={e => this.handleFinish(e)}
                            disableButton={this.state.disableField}
                            onlyOneStep={!isSettingEnabled}
                        />
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

ApplicationForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(ApplicationForm)));
