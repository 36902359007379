import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AssetForm from "./components/AssetForm";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {
    formTitles,
} from "../../utils/statusHelpers";
import assetDetails from "../../models/AssetModel";
import { getAssetsByCompany } from "../../services/assetService";
import {
    ENTITY_ASSET,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
} from "../../variables/coreConstants";
import Muted from "../../components/Typography/Muted";
import { validateSession } from "../../services/api";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import { getStatus, statusController } from "../../services/statusService";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableGridWijmo from "../../components/WijmoTableGrid/TabledGridWijmo";


class Assets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAsset: false,
            isLoading: true,
            actionType: 0,
            assetsData: [],
            filteredAssets: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: "",
            notification: false,
            color: "success",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    handleOpenForm = () => {
        assetDetails.cleanAssetDetails();
        this.setState({ openAsset: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openAsset: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request();
    }
    openForm = (action, formData) => {
        assetDetails.id = formData.id;
        assetDetails.actionId = formData.technologyId.id;
        assetDetails.range = formData.range;
        assetDetails.name = formData.name;
        assetDetails.identifier = formData.uid;
        assetDetails.technologyId.value = formData.technologyId.id;
        assetDetails.technologyId.label = formData.technologyId.name;

        if (action === 1 || action === 0) {
            this.setState({
                openAsset: true,
                actionType: action,
            });
        } else {
            let status = getStatus(action);

            if (status !== null) {
                let dataParameters = {
                    entity: ENTITY_ASSET,
                    id: assetDetails.id,
                    status: status,
                };

                statusController(
                    action,
                    null,
                    null,
                    dataParameters,
                    this,
                    true
                );
            }
        }
    };

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.closeForm("openAsset", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    request = () => {
        this.setState({ isLoading: true });

        getAssetsByCompany()
            .then((res) => {
                this.setState({ assetsData: res.data });
                this.setState({ filteredAssets: res.data });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    };



    goToActions = (val) => {
        assetDetails.name = val.name;
        //this.props.history.push("quick-actions-asset");
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const { actionType, page, rowsPerPage, filteredAssets, isLoading } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <GridContainer>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableGridWijmo
                        tableName={'assets'}
                        title={t('ASSETS_TEXT')}
                        exportFileName={t('ASSETS_TEXT')}
                        data={filteredAssets}
                        actions={[
                            { label: t('BTN_ADD_NEW_ASSET'), function: this.handleOpenForm, isLoading: false, disabled: false, type: "simple" },
                            { label: t('BTN_EXPORT_EXCEL'), function: null, isLoading: false, disabled: false, type: "excel" }
                        ]}
                        controls={[]}
                        headers={[
                            { label: t('TH_NAME'), name: "name", onSelect: this.goToActions, isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_ASSET_IDENTIFIER'), name: "uid", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_RANGE_MTS'), name: "range", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_TECHNOLOGY'), name: "technologyId.name", isReadOnly: true, width: "*", isCustomCell: false },
                            { label: t('TH_STATUS'), name: "status", isReadOnly: true, width: 120, isCustomCell: true },
                            { label: t('TH_ACTIONS'), name: "action", onSelect: this.openForm, isReadOnly: true, width: 90, cssClass: "cell-vertical-middle", isCustomCell: true }
                        ]}
                    />
                    <Dialog
                        maxWidth={"md"}
                        fullWidth={true}
                        open={this.state.openAsset}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t(formTitles[actionType])} {t("ASSET_TEXT")}
                            <Muted>{t("FORM_ASSET_DESCRIPTION")}</Muted>
                        </DialogTitle>
                        <DialogContent>
                            <AssetForm
                                closeForm={this.closeForm}
                                action={actionType}
                            />
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

Assets.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Assets)));
