let _dateInit = new Date();
_dateInit.setHours(0,0);

let _dateEnd = new Date();
_dateEnd.setHours(23,59);

var scheduleDetails = {
    schedule: {
        currentIndex: null,
        assignId: null,
        scheduleId: null,
        day: [],
        startTime: _dateInit,
        endTime: _dateEnd,
        defaultDay: null
    },
    schedules:[],

    cleanScheduleDetails : function () {
        this.schedules = [];
        this.schedule.day = [];
        this.schedule.startTime = _dateInit;
        this.schedule.endTime = _dateEnd;
        this.schedule.assignId = null;
        this.schedule.scheduleId = null;
        this.schedule.defaultDay = null;
        this.schedule.currentIndex = null;
    },

    cleanSchedule : function () {
        this.schedule.assignId = null;
        this.schedule.scheduleId = null;
        this.schedule.currentIndex = null;
        this.schedule.day = [];
        this.schedule.startTime = _dateInit;
        this.schedule.endTime = _dateEnd;
        this.schedule.defaultDay = 0;
    }
};

export default scheduleDetails;