/**
 * Copyright 2021 Ginih (https://www.ginih.com)
 * Created By Marlon
 * Coded by Ginih Team
 **/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { withRouter } from "react-router-dom";
import {
    convertDate,
    disableComponent,
    enableComponent, formatDatetimeToDate, getCompanyCurrency,
    getData, getRoundNumber,
    isSuccessfulRequest, mergeArrays,
    nullController, nullValidator,
    saveData, setCurrencyValue,
} from "../../../utils/helpersFunctions";
import { withTranslation } from "react-i18next";
import ResourceNotFound from "../../NotFound/ResourceNotFound";
import Loading from "../../../components/Loading/Loading";
import HeaderDetailView from "../../../components/CustomComponents/HeaderDetailView";
import {
    DELETE,
    NOTIFICATION_DURATION,
    STATUS_ASSIGNED,
} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formTitles } from "../../../utils/statusHelpers";
import DialogContent from "@material-ui/core/DialogContent";
import NotificationErrorIcon from "@material-ui/icons/Error";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel";
import MemberTemplateAssignment from "./MemberTemplateAssignment";
import PaymentTemplateDetails from "./PaymentTemplateDetails";
import {
    editTemplateInformation,
    getPaymentTemplateDetail,
} from "../../../services/paymentTemplateService";
import { validateSession } from "../../../services/api";
import PaymentTemplateItemForm from "./PaymentTemplateItemForm";
import paymentTemplateDetails from "../../../models/PaymentTemplateModel";
import {
    BASE_URL_CORE,
    PAYMENT_TEMPLATE_ITEM,
} from "../../../variables/apiConstants";
import { statusController } from "../../../services/statusService";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuIcon from "@material-ui/icons/Assignment";
import Button from "../../../components/CustomButtons/Button";

class QuickActionsPaymentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: "",
            paymentTemplate: null,
            templateName: null,
            openTemplate: false,
            actionType: 0,
            value: 0,
            openItemForm: false,
            openTemplateForm: false,
            openGenerateTemplateForm: false,
            isLoading: false,
            notFound: false,
            notificationMessage: "",
            color: "primary",
            icon: NotificationSuccessIcon,
            notification: false,
            disableField: false,
        };
        this.closeForm = this.closeForm.bind(this);
        this.editTemplateInformation = this.editTemplateInformation.bind(this);
    }

    componentDidMount(): void {
        const { match } = this.props;
        const paymentTemplate = getData("paymentTemplate");

        if (match.params.id && paymentTemplate !== null) {
            this.setState({
                paymentTemplate: paymentTemplate,
                isLoading: true,
                uuid: match.params.id,
            });
            this.request(match.params.id);
        } else {
            // this.setState({ notFound: true });
            // removeData("paymentTemplate");
            // this.props.history.goBack();
            // this.props.history.push('payment-templates');
        }
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value,
        });
    };

    handleCloseForm = () => {
        this.setState({ openTemplate: false });
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) this.request(this.state.uuid);
    }
    openForm = (action, formData) => {
        if (action === 1 && formData.status === STATUS_ASSIGNED) {
            this.showNotification(
                this.props.t("CANNOT_UPDATE_TEMPLATE_ITEM"),
                "danger",
                NotificationErrorIcon,
                null
            );
        } else {
            this.setState({
                templateName: formData.name,
                openTemplate: true,
                actionType: action,
            });
        }
    };

    showNotification = (msj, color, icon, type) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (type) {
                    this.request(this.state.uuid);
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChangeIndex = (index) => {
        this.setState({ value: index });
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    handleOpenItemForm = () => {
        this.setState({ openItemForm: true, actionType: 0 });
    };
    handleDeleteTemplateItem = () => {
        let dataParameters = {
            templateItemId: paymentTemplateDetails.templateItemDetails.uuid,
        };

        statusController(
            DELETE,
            BASE_URL_CORE,
            PAYMENT_TEMPLATE_ITEM,
            dataParameters,
            this,
            false
        );
    };
    handleItemTableAction = () => {
        this.setState({ openItemForm: true, actionType: 1 });
    };
    handleCloseItemForm = () => {
        this.setState({ openItemForm: false, actionType: 0 });
    };

    handleError = (message) => {
        this.showNotification(
            this.props.t(message),
            "danger",
            NotificationFailedIcon,
            null
        );
    };

    request = (templateId) => {
        getPaymentTemplateDetail(templateId)
            .then((response) => {
                let newData = response.data.items.map(r => {
                    r.unitPriceLabel = getCompanyCurrency() + " " + setCurrencyValue(getRoundNumber(parseFloat(nullController(r, 'unitPrice'))))
                    r.discountLabel = getCompanyCurrency() + " " + setCurrencyValue(getRoundNumber(parseFloat(nullController(r, 'discount'))))
                    r.surchargeLabel = getCompanyCurrency() + " " + setCurrencyValue(getRoundNumber(parseFloat(nullController(r, 'surcharge'))))
                    r.totalLabel = getCompanyCurrency() + " " + setCurrencyValue(getRoundNumber(parseFloat(nullController(r, 'total'))))
                    r.expirationDateLabel = convertDate(r.expirationDate);
                    return r;
                });

                response.data.items = newData;

                this.setState({
                    paymentTemplate: response.data,
                    isLoading: false,
                    isLoadingMore: false
                });
                // paymentTemplateDetails.setPaymentTemplateDetail(response.data);
                saveData("paymentTemplate", response.data);
            })
            .catch((err) => {
                this.setState({ isLoading: false , isLoadingMore: false });
                if (err.response) {
                    this.showNotification(
                        err.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                }
            });
    };

    editTemplateInformation() {
        if (this.state.templateName !== "") {
            disableComponent(this, "disableField");
            editTemplateInformation({
                name: this.state.templateName,
                uuid: this.state.uuid,
            })
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.setState({
                            templateName: "",
                            isLoading: false,
                            openTemplate: false,
                        });

                        this.showNotification(
                            response.data.message,
                            "primary",
                            NotificationFailedIcon,
                            true
                        );
                    } else {
                        this.showNotification(
                            response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                    enableComponent(this, "disableField");
                })
                .catch((err) => {
                    enableComponent(this, "disableField");
                    this.setState({ isLoading: false });
                    if (err.response) {
                        this.showNotification(
                            err.response.data.message,
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                        validateSession(err.response, this.props.history);
                    } else {
                        this.showNotification(
                            this.props.t("SOMETHING_WENT_WRONG"),
                            "danger",
                            NotificationFailedIcon,
                            null
                        );
                    }
                });
        } else {
            this.showNotification(
                this.props.t("NAME_REQUIRED_MSG"),
                "danger",
                NotificationFailedIcon,
                null
            );
        }
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            notFound,
            isLoading,
            paymentTemplate,
            actionType,
            value,
            uuid,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                {notFound ? (
                    <ResourceNotFound />
                ) : (
                    <div>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <HeaderDetailView
                                        title={nullController(
                                            paymentTemplate,
                                            "name"
                                        )}
                                        description={nullController(
                                            paymentTemplate,
                                            "description"
                                        )}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <div className={classes.rightPaper}>
                                        <Button
                                            variant="outlined"
                                            color="white"
                                            disabled={
                                                paymentTemplate === null ||
                                                paymentTemplate.status ===
                                                    STATUS_ASSIGNED
                                            }
                                            onClick={() =>
                                                this.openForm(
                                                    1,
                                                    paymentTemplate
                                                )
                                            }
                                            className={classes.cancelButton}
                                        >
                                            {t("EDIT_TEXT")}
                                        </Button>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <AppBar
                                        position={"static"}
                                        className={classes.tabBar}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={this.handleChange}
                                            aria-label="tab"
                                        >
                                            <Tab
                                                label={t("DETAIL_TEXT")}
                                                {...this.a11yProps(0)}
                                            />
                                            <Tab
                                                label={t("ASSIGNMENTS_TEXT")}
                                                {...this.a11yProps(1)}
                                            />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0}>
                                        <PaymentTemplateDetails
                                            data={
                                                paymentTemplate !== null
                                                    ? paymentTemplate.items
                                                    : []
                                            }
                                            loading={isLoading}
                                            component={this}
                                            template={
                                                paymentTemplate !== null
                                                    ? paymentTemplate
                                                    : []
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <MemberTemplateAssignment uuid={uuid} />
                                    </TabPanel>
                                </GridItem>
                            </GridContainer>
                        )}
                    </div>
                )}
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openTemplate}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t("UPDATE_TEMPLATE_INFORMATION")}
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.formContent}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <CustomInput
                                        value={this.state.templateName}
                                        onChange={this.handleChangeValues}
                                        labelText={t("NAME_TEXT")}
                                        id="templateName"
                                        name="templateName"
                                        autoFocus={true}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <MenuIcon
                                                    className={
                                                        classes.textInputIcon
                                                    }
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.rightPaper}>
                                        <Button
                                            color="white"
                                            className={
                                                classes.buttonMargin +
                                                " " +
                                                classes.cancelButton
                                            }
                                            onClick={() =>
                                                this.closeForm(
                                                    "openTemplate",
                                                    false,
                                                    false
                                                )
                                            }
                                        >
                                            {t("BTN_CANCEL")}
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={this.state.disableField}
                                            className={
                                                classes.buttonMargin +
                                                " " +
                                                classes.cancelButton
                                            }
                                            onClick={() =>
                                                this.editTemplateInformation()
                                            }
                                        >
                                            {t("BTN_SAVE_CHANGES")}
                                        </Button>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    open={this.state.openItemForm}
                    onClose={this.handleCloseItemForm}
                    aria-labelledby="form-dialog-item-title"
                >
                    <DialogTitle id="form-dialog-item-title">
                        {t(formTitles[actionType])}{" "}
                        {t("PAYMENT_TEMPLATE_ITEM_TITLE")}
                    </DialogTitle>
                    <DialogContent>
                        <PaymentTemplateItemForm
                            closeForm={this.closeForm}
                            action={actionType}
                            templateId={uuid}
                        />
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </div>
        );
    }
}

QuickActionsPaymentTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsPaymentTemplate))
);
