import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import SimpleButton from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Muted from "../../../components/Typography/Muted";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/core icons
import MenuIcon from "@material-ui/icons/Assignment";
import DescriptionIcon from "@material-ui/icons/ShortText";
import Button from "../../../components/CustomButtons/Button";
import menuDetails from "../../../models/MenuModel";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import formStyle from "../../../assets/jss/custom-components/formStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Schedule from "../../../components/Schedules/ScheduleForm";
import DialogTitle from "@material-ui/core/DialogTitle";
import {addMenu, editMenu, getSchedulesByMenu} from "../../../services/menuService";
import {
    convertStringToDate,
    disableComponent,
    enableComponent,
    formatTime, isSuccessfulCreate,
    isSuccessfulRequest, selectValidation, validateNewValue
} from "../../../utils/helpersFunctions";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {getCategoriesByCompany, getAllCategoriesByMenu} from "../../../services/categoryService";
import makeAnimated from "react-select/animated";
import scheduleDetails from "../../../models/ScheduleModel";
import ScheduleAvailable from "../../../components/Schedules/ScheduleAvailable";


const animatedComponents = makeAnimated();


class MenuForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            oldSchedules: [],
            schedules: scheduleDetails.schedules,
            menuName: menuDetails.name,
            menuDescription: menuDetails.description,
            categories: [],
            categoriesOptions: [],
            oldCategories: [],
            loadingCategories: true,
            openSchedule: false,
            actionType: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.saveMenu = this.saveMenu.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.request = this.request.bind(this);
        this.scheduleForm = this.scheduleForm.bind(this);
    }


    componentDidMount() {
        this.request();
    }

    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    };

    changeCategorySelect = categories => {
        this.setState({ categories });
    };

    cleanData = () => {
        this.setState({
            menuName: '',
            menuDescription: '',
            parentCatalogId: 0,
        });
    };


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openMenu", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    openScheduleForm = () => {
        this.setState({
            openSchedule: true,
            actionType: 0
        });
        scheduleDetails.cleanSchedule();
    };

    closeScheduleForm = () => {
        this.setState({ openSchedule: false } );
    };

    scheduleForm(value) {
        this.setState({ openSchedule: value });
    };


    request() {
            let categoryValues;

            if (this.props.action) {
                getAllCategoriesByMenu(menuDetails.id)
                    .then((res) => {
                        categoryValues = res.data.unassigned.map( item => { return { value: item.id , label: item.name } });

                        // Set categories
                        let categories = res.data.assigned.map( item => {
                            return { value: item.entity.id , label: item.entity.name };
                        });

                        let oldCategories = res.data.assigned.map( item => {
                            return { assignId: item.assignId, entityId: item.entity.id }
                        });

                        this.setState({
                            categoriesOptions: categoryValues,
                            categories: categories,
                            oldCategories: oldCategories,
                            loadingCategories: false
                        });
                    })
                    .catch((err) => {
                        this.setState({ loadingCategories: false });

                        if (err.response)
                            validateSession(err.response, this.props.history);
                    });

                let schedules;
                getSchedulesByMenu(menuDetails.id)
                    .then((response) => {
                        schedules = response.data.map(val => {
                            return {
                                assignId: val.id,
                                scheduleId: val.scheduleId.id,
                                scheduleDaysId: val.scheduleId.scheduleDaysId.id,
                                day: val.scheduleId.scheduleDaysId.days,
                                timeFrom: convertStringToDate(val.scheduleId.timeFrom),
                                timeTo: convertStringToDate(val.scheduleId.timeTo)
                            }
                        });

                        scheduleDetails.schedules = schedules;
                        this.setState({
                            schedules: schedules,
                            oldSchedules: response.data
                        });

                    })
                    .catch((error) => {
                        if (error.response) {
                            this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                            validateSession(error.response, this.props.history);
                        }
                    });

            } else {
                getCategoriesByCompany()
                    .then((res) => {
                        categoryValues = res.data.map( item => { return { value: item.id , label: item.name } });

                        this.setState({
                            categoriesOptions: categoryValues,
                            loadingCategories: false
                        });
                    })
                    .catch((err) => {
                        this.setState({ loadingCategories: false });

                        if (err.response)
                            validateSession(err.response, this.props.history);
                    });
            }
    }



    saveMenu() {
        const { t } = this.props;

        if (this.state.menuName === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let schedules = this.state.schedules.map( item => {
                return {
                    scheduleDaysId: item.scheduleDaysId,
                    timeFrom: formatTime(item.timeFrom),
                    timeTo: formatTime(item.timeTo)
                };
            });


            let categoriesId;
            if (selectValidation(this.state.categories)) {
                categoriesId = [];
            } else {
                categoriesId = this.state.categories.map( item => {
                    return { id: item.value };
                });
            }

            let dataParameters = {
                "name": this.state.menuName,
                "description": this.state.menuDescription,
                "categories": JSON.stringify(categoriesId),
                "schedules": JSON.stringify(schedules)
            };


            disableComponent(this, "disableField");
            addMenu(dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                        // history.push('menus');

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");
                    this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                    if (error.response)
                        validateSession(error.response, this.props.history);
                });
        }
    }


    updateMenu() {
        const { t } = this.props;

        if (this.state.menuName === "") {
            this.showNotification(t('NAME_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {

            let categoriesId;
            if (selectValidation(this.state.categories)) {
                categoriesId = [];
            } else {
                categoriesId = this.state.categories.map( item => {
                    if (validateNewValue(this.state.oldCategories, "entityId", item.value)) {
                        let index = this.state.oldCategories.findIndex(val => val.entityId === item.value);
                        return {
                            assignId: this.state.oldCategories[index].assignId,
                            entityId: this.state.oldCategories[index].entityId
                        };

                    } else {
                        return { assignId: null, entityId: item.value };
                    }
                });
            }

            let schedules = this.state.schedules.map( item => {
                return {
                    assignId: item.assignId,
                    scheduleId: item.scheduleId,
                    dayId: item.scheduleDaysId,
                    timeFrom: formatTime(item.timeFrom),
                    timeTo: formatTime(item.timeTo)
                };
            });


            let oldSchedules = this.state.oldSchedules.map( item => {
                return {
                    assignId: item.id,
                    scheduleId: item.scheduleId.id,
                    dayId: item.scheduleId.scheduleDaysId.id,
                    timeFrom: item.scheduleId.timeFrom,
                    timeTo: item.scheduleId.timeTo
                };
            });


            let dataParameters = {
                "name": this.state.menuName,
                "description": this.state.menuDescription,
                "categories": JSON.stringify(categoriesId),
                "oldCategories": JSON.stringify(this.state.oldCategories),
                "schedules": JSON.stringify(schedules),
                "oldSchedules": JSON.stringify(oldSchedules)
            };

            disableComponent(this, "disableField");
            editMenu(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.cleanData();
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    enableComponent(this, "disableField");

                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    render() {
        const { classes, t } = this.props;

        const SaveMenuButton = withRouter(({ history }) => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.saveMenu(history)}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_MENU')}
            </Button>
        ));

        const UpdateMenuButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.updateMenu()}
                disabled={this.state.disableField}
            >
                {t('BTN_SAVE_CHANGES')}
            </Button>
        );



        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.menuName}
                            onChange={this.handleChangeValues}
                            labelText={t('MENU_NAME_TEXT')}
                            id="menuName"
                            name="menuName"
                            autoFocus={true}
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <MenuIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            value={this.state.menuDescription}
                            onChange={this.handleChangeValues}
                            labelText={t('DESCRIPTION_TEXT')}
                            id="description"
                            name="menuDescription"
                            formControlProps={{
                                fullWidth: true
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <DescriptionIcon className={classes.colorIcon}/>
                                </InputAdornment>
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.formControl}>
                            <InputLabel htmlFor="categories" className={classes.selectTitle}>
                                {t('CATEGORIES_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.categories}
                                name="categories"
                                isLoading={this.state.loadingCategories}
                                isMulti={true}
                                components={animatedComponents}
                                onChange={this.changeCategorySelect}
                                options={this.state.categoriesOptions}
                                placeholder={t('SELECT_TEXT')}
                                closeMenuOnSelect={false}
                                maxMenuHeight={200}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.typo}>
                            <h5 style={{color: 'black'}}>
                                {t('SCHEDULE_SECTION_TITLE')}
                                <Muted>
                                    {t('SCHEDULE_SECTION_DESCRIPTION')}
                                </Muted>
                            </h5>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <ScheduleAvailable schedules={this.state.schedules} component={this}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.addScheduleButton}>
                            <SimpleButton
                                size="small"
                                color="primary"
                                onClick={this.openScheduleForm}
                            >
                                {t('BTN_ADD_AVAILABILITY')}
                            </SimpleButton>
                        </div>
                        <Dialog
                            maxWidth={"sm"}
                            fullWidth={true}
                            open={this.state.openSchedule}
                            onClose={this.closeScheduleForm}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                {this.state.actionType
                                    ? (t('FORM_EDIT_TEXT')) : (t('FORM_NEW_TEXT'))} {t('SCHEDULE_SECTION_TITLE')}
                            </DialogTitle>
                            <DialogContent>
                                <Schedule closeForm={this.scheduleForm} action={this.state.actionType}/>
                            </DialogContent>
                        </Dialog>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openMenu", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            {this.props.action ? <UpdateMenuButton/> : <SaveMenuButton />}
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

MenuForm.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(formStyle)(withTranslation()(MenuForm)));