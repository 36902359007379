var applicationDetails = {
    id: null,
    name: "",
    description: "",
    instructions: "",
    type: {
        value: 0,
        label: "Seleccionar...",
    },
    accessLevel: {
        value: 0,
        label: "Seleccionar...",
    },
    paymentAmountOption: {
        value: 0,
        label: "Seleccionar...",
    },
    isCompact: false,
    isAutoOpen: false,
    published: true,
    takeAway: false,
    dineIn: false,
    delivery: false,
    dineInTip: false,
    deliveryTip: false,
    takeAwayTip: false,
    takeAwayMinimum: 0.00,
    dineInMinimum: 0.00,
    deliveryMinimum: 0.00,
    deliveryRadius: 10.00,
    deliveryExtraFee: 0.00,
    deliveryBaseFee: 0.00,
    deliveryBaseFeeRadius: 0.00,
    menus: [],
    oldMenus: [],
    cuisines: [],
    oldCuisines: [],


    cleanApplicationDetails : function () {
        this.id = null;
        this.description = "";
        this.instructions = "";
        this.name = "";
        this.accessLevel.label = "Seleccionar...";
        this.accessLevel.value = 0;
        this.type.label = "Seleccionar...";
        this.type.value = 0;
        this.paymentAmountOption.label = "Seleccionar...";
        this.paymentAmountOption.value = 0;
        this.isCompact = false;
        this.isAutoOpen = false;
        this.published = true;
        this.dineInMinimum = 0.00;
        this.takeAwayMinimum = 0.00;
        this.deliveryMinimum = 0.00;
        this.deliveryRadius = 10.00;
        this.deliveryBaseFee = 0.00;
        this.deliveryBaseFeeRadius = 0.00;
        this.deliveryExtraFee = 0.00;
        this.delivery = false;
        this.dineIn = false;
        this.takeAway = false;
        this.dineInTip = false;
        this.deliveryTip = false;
        this.takeAwayTip = false;
        this.menus = [];
        this.oldMenus = [];
        this.cuisines = [];
        this.oldCuisines = [];
    },
};

export default applicationDetails;