import {getPrivateService, getService, postPrivateService} from "./api";
import {
    BASE_URL_DIRECT_DEBIT,
    DIRECT_DEBIT_SYNC_PAYMENTS,
    DIRECT_DEBIT_PAYMENTS,
    DIRECT_DEBIT,
    EXECUTE_DEBIT,
    DEBIT_EXECUTION_RESULT,
    LAST_EXECUTION_DIRECT_DEBIT,
    DIRECT_DEBITS, BASE_URL_ACCOUNT, LOGIN_DIRECT_DEBIT, LAST_DIRECT_DEBIT_JOBS
} from "../variables/apiConstants";
import {getCompanyDevelopmentApiKeys} from "./accountService";
import {isEmpty, removeData, saveData} from "../utils/helpersFunctions";
import axios from "axios";


export function getDirectDebits(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBITS;
    let customHeaders = {
        'limit': 100
    };

    return postPrivateService(url, parameters, customHeaders)
}


export function addDirectDebit(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBIT;

    return postPrivateService(url, parameters);
}


// export function getDirectDebitDetails(parameters, headers) {
//     let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBIT;
//
//     return axios.post(url, parameters, {
//         headers: headers
//     });
// }


export function getLastDirectDebitExecution(debitId, headers) {
    let url = BASE_URL_DIRECT_DEBIT + LAST_EXECUTION_DIRECT_DEBIT + debitId;

    return getPrivateService(url, null);
}


export function getDirectDebitPayments(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBIT_PAYMENTS;
    let customHeaders = {
        'limit': 500
    };

    return postPrivateService(url, parameters, customHeaders);
}


export function syncDirectDebitPayments(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBIT_SYNC_PAYMENTS;

    return postPrivateService(url, parameters);
}



export function executeDirectDebit(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + EXECUTE_DEBIT;

    return postPrivateService(url, parameters);
}

export function getDirectDebitResult(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DEBIT_EXECUTION_RESULT;
    let customHeaders = {
        'limit': 500
    };

    return postPrivateService(url, parameters, customHeaders);
}

export function getLastDirectDebitJobs(id) {
    let url = BASE_URL_DIRECT_DEBIT + LAST_DIRECT_DEBIT_JOBS + id;
    let customHeaders = {
        'limit': 100
    };

    return getPrivateService(url, null, customHeaders);
}


export function updateDirectDebit(parameters) {
    let url = BASE_URL_DIRECT_DEBIT + DIRECT_DEBIT;

    return postPrivateService(url, parameters);
}


// export function addMembersCardsBulk(url, parameters) {
//     return postPrivateService(url, parameters);
// }


export function loginDirectDebit() {
    return getService(BASE_URL_ACCOUNT + LOGIN_DIRECT_DEBIT, null);
}


export function refreshPrivateServiceToken(response, callback, onError) {
    if (!isEmpty(response) && response.status === 403) {
        removeData("directDebitAuth");

        loginDirectDebit().then(r => {
            if (r.status === 200 && r.data.success === true) {
                saveData("directDebitAuth", r.headers.authorization);
                callback();
            } else {
                onError(r);
            }
        }).catch(e => {
            console.log(e);
            if (e.response !== null && e.response !== undefined) {
                onError(e.response);
            } else {
                onError(response);
            }
        });
    } else {
        onError(response);
    }
}


export function getDevKeys() {
    return new Promise(function (resolve, reject) {
        getCompanyDevelopmentApiKeys()
            .then((res) => {
                if (res !== null && res !== undefined && res.data.length > 0) {
                    resolve(res.data[0])
                } else {
                    resolve(null);
                }
            })
            .catch((err) => {
                resolve(null);
            });
    });
}

export function addMembersCardsBulk(url, parameters, headers) {
    return axios.post(url, parameters,  {
        headers: headers
    });
}