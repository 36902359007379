import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {withNotification} from "../../../../components/HOC/Notification/NotificationHOC";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import {
    cloneObject,
    getData, getMemberLabelFromJobTask, getPercentageProcess,
    getRoundNumber, getTableColumnObject,
    getTimeLineSequenceDetails,
    isEmpty, mergeArrays,
    nullController, saveData,
    setCurrencyValue
} from "../../../../utils/helpersFunctions";
import {getDirectDebitResult, refreshPrivateServiceToken} from "../../../../services/directDebitService";
import {Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {
    DEBIT_WSS_JOB_EXECUTE_ACTION,
    NOTIFICATION_DURATION_LARGE
} from "../../../../variables/coreConstants";
import Muted from "../../../../components/Typography/Muted";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import DebitedPaymentsDetails from "./DebitedPaymentsDetails";
import API_CODES, {STATUS_COMPLETED_JOB, STATUS_IN_PROGRESS_TSK} from "../../../../variables/apiCodes";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import {statusText} from "../../../../utils/statusHelpers";
import {getPaymentDetails} from "../../../../services/paymentService";
import {getSignature, validateSession} from "../../../../services/api";
import LiveProcessModal from "../../../../components/CustomModals/LiveProcessModal";
import {BASE_URL_WEB_SOCKET_DIRECT_DEBIT} from "../../../../variables/apiConstants";
import {withWssListener} from "../../../../components/HOC/WebSocket/WebSocketHOC";
import ExportDebitExecutionResult from "./ExportDebitExecutionResult";


class DebitExecutionDetailsController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debitDetails: getData("debit"),
            debitedItems: [],
            wssTask: [],
            filteredData: [],
            notDebitedItems: [],
            excludedItems: [],
            executionSummary: {
                "total": 0,
                "pending": 0,
                "completed": 0,
                "completedWithErrors": 0
            },
            syncSummary: getData("syncSummary"),
            tab: 0,
            isRequesting: false,
            page: 0,
            rowsPerPage: 50,
            lastPage: false,
            totalElements: 0,
            retryDebitToken: 0,
            totalPages: 0,
            paymentDetail: null,
            openPaymentDetail: false,
            isLoadingDetails: false,
            showLiveProcess: false,
            enableProgress: !getData("isExecuted"),
            executionInfoLoaded: false,
            forceConnection: false,
            jobInProgress: false,
            metadata: null,
            isLoadingMore: false,
            executeResultLoaded: false,
            exportError: props.t("FORM_DEBIT_EXECUTION_RESULT_EXPORT_DESCRIPTION")
        };
    }


    async componentDidMount() {
        const { t, routeProps, wssData } = this.props;

        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);


        this.getDebitExecutionResult();

        if (this.state.enableProgress && !this.state.executionInfoLoaded) {
            if (!wssData.wssProcessCompleted) {
                await this.onInitializeWss();
            }
        }
    }



    handleDebitExecutionResult = (initWss) => {
        this.setState({
            forceConnection: initWss === true,
            debitedItems: this.state.executeResultLoaded ? [] : this.state.debitedItems,
            filteredData: this.state.executeResultLoaded ? [] : this.state.filteredData,
        }, () => {
            this.getDebitExecutionResult();
        });
    }

    getDebitExecutionResult = async () => {
        const { match, history, t, showNotificationMessage } = this.props;

        if (!isEmpty(match.params.id) && !isEmpty(match.params.exId)) {

            this.setState({ isRequesting: !this.state.isLoadingMore, retryDebitToken: this.state.retryDebitToken + 1 }, ()=> {

                let missingLastItem = false;
                let lastItem = null;

                if (!isEmpty(this.state.metadata)) {
                    if (this.state.metadata.hasMore) {
                        lastItem = this.state.metadata.lastItem;
                        missingLastItem = isEmpty(this.state.metadata.lastItem);
                    }
                }

                if (!missingLastItem) {
                    let dataParameters = {
                        "debitDefinitionId": match.params.id,
                        "jobId": match.params.exId,
                        "lastItem": !isEmpty(this.state.metadata) ? this.state.metadata.lastItem : null
                    };

                    getDirectDebitResult(dataParameters)
                        .then(response => {
                            if (!isEmpty(response) && !isEmpty(response.data)) {
                                const { payments, summary, metadata } = response.data;
                                let data = [];
                                let metadataPagination = metadata;

                                let cloneSummary = cloneObject(this.state.executionSummary);
                                if (!isEmpty(summary)) {
                                    cloneSummary.completedWithErrors = summary.completedWithErrors;
                                    cloneSummary.total = summary.total;
                                    cloneSummary.completed = summary.completed;
                                    cloneSummary.pending = summary.pending;
                                }

                                this.setState({
                                    executionSummary: cloneSummary,
                                    executionInfoLoaded: !isEmpty(cloneSummary) && (cloneSummary.total > 0 && cloneSummary.completed >= cloneSummary.total)
                                }, () => {
                                    data = this.handlePrepareResultItems(payments);

                                    let paginatedData = mergeArrays(
                                        this.state.debitedItems,
                                        data,
                                        "id",
                                        (a,b) => a["recordNum"] - b["recordNum"]
                                    );

                                    this.setState({
                                        isLoading: false,
                                        isLoadingMore: false,
                                        isRequesting: false,
                                        retryDebitToken: 0,
                                        metadata: metadataPagination,
                                        filteredData: paginatedData,
                                        debitedItems: paginatedData,
                                        executeResultLoaded: !isEmpty(metadataPagination) && !metadataPagination.hasMore,
                                        showLiveProcess: this.state.enableProgress,
                                        // showLiveProcess: this.state.forceConnection && (this.state.executionSummary.total > this.state.executionSummary.completed),
                                    }, () => {
                                        if (cloneSummary.completed >= cloneSummary.total && cloneSummary.total > 0) {
                                            saveData("isExecuted", true);
                                            this.handleLoadMore();
                                        }
                                        // console.log(!this.state.executionInfoLoaded);
                                        // if (this.state.enableProgress && !this.state.executionInfoLoaded) {
                                        //     const { wssData } = this.props;
                                        //
                                        //     if (!wssData.wssProcessCompleted) {
                                        //         this.onInitializeWss();
                                        //     }
                                        // }
                                        //
                                        // if (this.state.executionSummary.completed < this.state.executionSummary.total
                                        //     && this.state.executionSummary.total > 0 && this.state.forceConnection) {
                                        //
                                        //     this.onInitializeWss();
                                        // }
                                    });
                                });

                                // } else {
                                //     history.push("/admin/debits");
                                // }


                            } else {
                                history.replace("/admin/debits");
                            }
                        }).catch(e => {
                        if (!isEmpty(e.response)) {
                            if (this.state.retryDebitToken <= 2) {
                                refreshPrivateServiceToken(e.response, this.getDebitExecutionResult, this.handlerHttpError);
                            } else {
                                this.setState({ retryDebitToken: 0 });
                                this.handlerHttpError(e.response);
                            }
                        } else {
                            this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0, isLoadingMore: false });
                            showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                                NOTIFICATION_DURATION_LARGE, null);
                        }
                    });
                } else {
                    this.setState({ isLoading: false, isRequesting: false, retryDebitToken: 0, isLoadingMore: false, metadata: null });
                }
            });
        } else {
            // redirect to debits
            this.props.history.goBack();
        }
    }



    handleCloseLiveProcess = (update) => {
        this.setState({
            wssTask: [],
            showLiveProcess: false,
            enableProgress: false
        });
        // }, () => {
        //     if (update) {
        //         this.getDebitExecutionResult();
        //     }
        // });
    };


    /**
     * Build payment result item object array from response payments
     * @param payments
     * @return {[]}
     */
    handlePrepareResultItems = (payments) => {
        let data = [];
        let recordNum = this.state.debitedItems.length + 1;

        if (!isEmpty(payments) && payments.length > 0) {
            for(let tsk = 0; tsk < payments.length; tsk++) {
                try {
                    let taskItem = payments[tsk];

                    if (!isEmpty(taskItem)) {
                        const { id, paymentId, member, paymentRequest, amount,
                            timeline, status, paymentMethod } = taskItem;

                        let paymentTsk = getTimeLineSequenceDetails(timeline, API_CODES.TIMELINE_BANK_PAYMENT);
                        let syncTsk = getTimeLineSequenceDetails(timeline, API_CODES.TIMELINE_SEND_PAYMENT_NOTIFICATION);

                        // console.log(paymentTsk)
                        // console.log(syncTsk)

                        data.push({
                            id: id,
                            recordNum: recordNum,
                            paymentId: paymentId,
                            memberId: member.id,
                            status: status,
                            timeline: timeline,
                            paymentStatus: !isEmpty(paymentTsk) ? (paymentTsk.success ? paymentTsk.status : getTableColumnObject(paymentTsk, "paymentStatus")) : 'undefined',
                            paymentStatusMessage: getTableColumnObject(paymentTsk, "statusAttempt"),
                            syncStatusMessage: getTableColumnObject(syncTsk, "statusSend"),
                            syncStatus: !isEmpty(syncTsk) ? (syncTsk.success ? syncTsk.status : status) : 'undefined',
                            memberName: member.name,
                            memberExternalId: member.externalId,
                            paymentExternalId: paymentRequest.externalId,
                            paymentMethod: !isEmpty(paymentMethod) ? "XXXX-" + paymentMethod.lastDigits : null,
                            paymentMethodStatus: !isEmpty(paymentMethod) ? paymentMethod.status : null,
                            paymentName: paymentRequest.name,
                            amountFormatted: !isNaN(parseInt(amount.minorAmount)) && parseInt(amount.minorAmount) >= 0 ? amount.currency.symbol + " " + setCurrencyValue(getRoundNumber(parseInt(amount.minorAmount) / 100)) : "-",
                            amountValue: !isNaN(amount.minorAmount) && parseInt(amount.minorAmount) >= 0 ? parseInt(amount.minorAmount) : 0,
                            currencySymbol: amount.currency.symbol
                        });

                        recordNum++;
                    }
                } catch (e) {
                    console.log("Item error => " + tsk);
                }
            }
        }

        return data;
    };


    /**
     * Response error validator
     * @param response
     */
    handlerHttpError = (response) => {
        const { showNotificationMessage, t } = this.props;

        this.setState({ isLoading: false, isRequesting: false, isLoadingMore: false });

        if (!isEmpty(response.data)) {
            const { data } = response;

            if (!isEmpty(data.error)) {
                showNotificationMessage(data.error.message, "danger", NotificationFailedIcon,
                    NOTIFICATION_DURATION_LARGE, null);

                // If we need to validate the code error to do something
                // if (data.error.code === API_CODES.GENERAL_FAIL_CODE.code) {
                // } else {
                //     if (!isEmpty(data.error.data)) {
                //         this.setState({ data : response.data.error.data });
                //     }
                // }
            } else {
                if (!isEmpty(data.code)) {
                    showNotificationMessage(data.code.message, "danger", NotificationFailedIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                } else {
                    showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                        NOTIFICATION_DURATION_LARGE, null);
                }
            }
        } else {
            showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                NOTIFICATION_DURATION_LARGE, null);
        }
    };


    /**
     * Method update tab state value
     * @param {event} event
     * @param {number} newValue
     */
    handleChange = (event, newValue) => {
        this.setState({ tab: newValue });
    };

    /**
     * Tab props function assignment
     * @param {number} index
     * @return {{"aria-controls": string, id: string}}
     */
    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };


    onChangePage = (event, newPage) => {
        this.setState({ page: newPage})
    };

    onChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value
        });
    };

    search = (e) => {
        const { t } = this.props;
        let newData = this.state.debitedItems;

        try {
            newData = newData.filter((item) => {

                return (item.memberName !== null ? (item.memberName.search(e.target.value) !== -1) : false)
                    || (item.memberExternalId !== null ? (item.memberExternalId.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.paymentName !== null ? (item.paymentName.search(e.target.value) !== -1) : false)
                    || (item.paymentExternalId !== null ? (item.paymentExternalId.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || t(statusText[item.paymentStatus]).toLowerCase().search(e.target.value.toLowerCase()) !== -1
                    || t(statusText[item.syncStatus]).toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });

            this.setState({ filteredData: newData });

        } catch (e) {
            console.log(e);
            console.log("Something went wrong.");
        }
    };


    openDetails = (item) => {
        const { t, showNotificationMessage, history } = this.props;

        if (!isEmpty(item) && !isEmpty(item.paymentId) && item.paymentStatus !== 'undefined') {
            this.setState({ isLoadingDetails: true }, () => {
                getPaymentDetails(item.paymentId)
                    .then((response) => {
                        if (response.data) {
                            this.setState({
                                paymentDetail: response.data,
                                openPaymentDetail: true,
                                isLoadingDetails: false
                            });
                        } else {
                            this.setState({
                                isLoadingDetails: false,
                                openPaymentDetail: false
                            });

                            showNotificationMessage(t('SOMETHING_WENT_WRONG'), 'danger', NotificationErrorIcon,
                                NOTIFICATION_DURATION_LARGE, null);
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            isLoadingDetails: false,
                            openPaymentDetail: false
                        });

                        if (error.response) {
                            showNotificationMessage(error.response.data.message
                                    ? error.response.data.message
                                    : t('SOMETHING_WENT_WRONG'),
                                'danger',
                                NotificationFailedIcon,
                                NOTIFICATION_DURATION_LARGE,
                                null
                            );

                            validateSession(error.response, history);
                        }
                    });
            });
        }
    };


    closeForm = (state, value, type) => {
        this.setState({
            [state]: value,
            paymentDetail: ''
        });
    };

    openLink = (data) => {
        this.setState({ openPaymentDetail: false }, ()=> {
            saveData("paymentRequest", { uuid: data.paymentRequestId });
            window.open("/admin/payment-request-details/" + data.paymentRequestId, "_blank");
        });
    };



    onReceiveNotification = (message) => {
        const { executionSummary } = this.state;
        const { jobId, jobProgress, summary, status, actionType, step } = message;
        // const { code,  description, task } = step;
        // const { pending, completed, total } = summary;
        let taskData = null;

        // console.log(JSON.stringify(message));

        if (jobId === this.props.match.params.exId && actionType === 'debit_send_payments') {
            if (!isEmpty(summary)) {
                // console.log("*********************");
                // console.log(jobId);
                // console.log(status);
                // console.log(JSON.stringify(summary));

                const { pending, completed, total, completedWithErrors } = summary;

                let updatedSummary = cloneObject(this.state.executionSummary);

                // if (updatedSummary.total === 0) {
                //     updatedSummary.total = total;
                // }
                //
                //
                // // do not set complete value if receive a message with minor task number than currenct summary
                // if (completed > updatedSummary.completed) {
                //     if (completed < updatedSummary.total) {
                //         updatedSummary.pending = pending;
                //         updatedSummary.completed = completed;
                //     } else if (completed === updatedSummary.total) {
                //         updatedSummary.pending = 0;
                //         updatedSummary.completed = completed;
                //     }
                // }
                //
                // if (total > updatedSummary.total) {
                //     updatedSummary.total = total;
                // }

                updatedSummary.total = total;
                updatedSummary.completed = completed;
                updatedSummary.pending = pending;
                updatedSummary.completedWithErrors = completedWithErrors;

                if (!isEmpty(step)) {
                    const { code,  description, task } = step;
                    taskData = task;
                }


                this.setState({
                    executionSummary: updatedSummary,
                    wssTask: !isEmpty(taskData) ? [taskData].concat(this.state.wssTask) : this.state.wssTask
                }, () => {
                    if (status === STATUS_IN_PROGRESS_TSK) {
                        // console.log("Job in progress");

                    } else if (status === STATUS_COMPLETED_JOB) {
                        // console.log("Job completed");
                        this.props.onWssCompleted();

                        if (!isEmpty(this.state.executionSummary) && (this.state.executionSummary.total > 0
                            && this.state.executionSummary.completed >= this.state.executionSummary.total)) {

                            this.setState({
                                executionInfoLoaded: true
                            }, () => {
                                this.getDebitExecutionResult();
                            });
                        }
                    } else {
                        // console.log("Invalid job status (" + status + ")");
                    }
                });
            } else {
                // iff summary is null ?
                // validate status
                // console.log("Wss summary null");
            }
        } else {
            // console.log("Incoming message from another job (" + jobId + ")");
        }


        this.props.onUpdateWssListenerCondition(() => {
            // console.log("Last message");
            const { executionSummary } = this.state;
            if (executionSummary.completed < executionSummary.total) {
                // console.log("Close connection last");
                this.onMissingMessages();
            }
        });
    };


    onInitializeWss = () => {
        this.setState({
            jobInProgress: true
        }, async () => {
            const data = {
                actionType: 'debit_send_payments',
                jobId: this.props.match.params.exId
            };

            const signature = await getSignature(BASE_URL_WEB_SOCKET_DIRECT_DEBIT, data, getData("directDebitAuth"));

            this.props.onSetWssConfig(
                BASE_URL_WEB_SOCKET_DIRECT_DEBIT,
                JSON.stringify({
                    action: DEBIT_WSS_JOB_EXECUTE_ACTION,
                    data: data,
                    headers: {
                        "signature": signature,
                        "x-secret-key": getData("userTk"),
                        "authorization": getData("directDebitAuth")
                    }
                }),
                3,
                10,
                this.onReceiveNotification,
                this.onMissingMessages
            );
        });
    };


    onMissingMessages = () => {
        if (this.state.executionSummary.completed < this.state.executionSummary.total && this.state.executionSummary.total > 0) {
            this.setState({
                executionInfoLoaded: false,
                retryDebitToken: 0,
                isRequesting: false,
                jobAvailableExecute: false,
                jobInProgress: false
            }, () => {
                this.props.onWssCompleted();
                this.handleDebitExecutionResult(false);
            });
        } else {
            this.setState({
                executionInfoLoaded: true,
                jobInProgress: false
            });
        }
    }

    handleLoadMore = () => {
        const { isLoadingMore, metadata } = this.state;

        if (!isLoadingMore && !isEmpty(metadata) && metadata.hasMore) {
            this.setState({
                isLoadingMore: true
            }, () => {
                this.handleDebitExecutionResult(false);
            });
        }
    }


    handleShowExportError = (message, callback) => {
        this.setState({
            exportError: message
        });
    };


    render() {
        const { classes, t, match } = this.props;
        const { isRequesting, debitDetails, tab, filteredData, page, rowsPerPage, executionSummary, syncSummary,
            openPaymentDetail, isLoadingDetails, paymentDetail, showLiveProcess, wssTask, debitedItems, metadata, isLoadingMore } = this.state;

        let processPercentage = getPercentageProcess(executionSummary);

        return (
            <div>
                <Backdrop className={classes.backdropCenter} open={isRequesting || isLoadingMore}>
                    <CircularProgress color={"inherit"} />
                    {
                        isLoadingMore ? <p>{t('RETRIEVING_INFORMATION')}</p> : null
                    }
                </Backdrop>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div style={{marginBottom: 0}}>
                            <GridContainer>
                                {
                                    !isEmpty(syncSummary) ? (
                                        <GridItem xs={12} sm={6} md={6} lg={5}>
                                            <Card>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p className={classes.balanceCardTitle}>{t('LOAD_PAYMENT_SUMMARY')}</p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <GridContainer>
                                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("SYNC_STATUS")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("TOTAL_MEMBERS")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("AVAILABLE_TO_DEBIT")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("NOT_AVAILABLE_TO_DEBIT")}
                                                                        </p>
                                                                        <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                            {t("MEMBERS_WITH_MULTIPLE_PAYMENTS")}
                                                                        </p>
                                                                    </div>
                                                                </GridItem>
                                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                                    <div style={{display: "flex", flexDirection: "column", float: "right", textAlign: "right"}}>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(syncSummary, "completed") + "/" + nullController(syncSummary, "total")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(syncSummary, "total")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(syncSummary, "availableToExecute")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(syncSummary, "notAvailableToExecute")}
                                                                        </p>
                                                                        <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                            {nullController(syncSummary, "withMultiplePayments")}
                                                                        </p>
                                                                    </div>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                    </GridItem>

                                    ) : null
                                }
                                {
                                    !isEmpty(executionSummary) ? (
                                        <GridItem xs={12} sm={6} md={6} lg={5}>
                                            <Card>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p className={classes.balanceCardTitle}>{t('DEBIT_SUMMARY')}</p>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <GridContainer>
                                                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("DEBIT_STATUS")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("TOTAL_PAYMENTS")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {t("SUCCESSFUL_PAYMENTS")}
                                                                        </p>
                                                                        <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                            {t("PAYMENTS_WITH_ERRORS")}
                                                                        </p>
                                                                    </div>
                                                                </GridItem>
                                                                <GridItem xs={4} sm={4} md={4} lg={4}>
                                                                    <div style={{display: "flex", flexDirection: "column", float: "right", textAlign: "right"}}>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(executionSummary, "completed") + "/" + nullController(executionSummary, "total")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(executionSummary, "total")}
                                                                        </p>
                                                                        <p className={classes.balanceCardSubTitle}>
                                                                            {nullController(executionSummary, "completed")}
                                                                        </p>
                                                                        <p className={classes.balanceCardTotalText} style={{fontWeight: "bold"}}>
                                                                            {nullController(executionSummary, "completedWithErrors")}
                                                                        </p>
                                                                    </div>
                                                                </GridItem>
                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                    ) : null
                                }
                            </GridContainer>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <DebitedPaymentsDetails
                            data={filteredData}
                            dataItemCount={executionSummary.total}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onSearch={this.search}
                            openPaymentDetails={openPaymentDetail}
                            isRequesting={isLoadingDetails}
                            paymentDetails={paymentDetail}
                            handleLoadMore={this.handleLoadMore}
                            handleChangeRowsPerPage={this.onChangeRowsPerPage}
                            handleChangePage={this.onChangePage}
                            handleOpenPayment={this.openDetails}
                            onClosePayment={this.closeForm}
                            onOpenLink={this.openLink}
                            handleRefresh={this.handleDebitExecutionResult}
                            handleExport={() => this.setState({ openExport: true, exportError: t("FORM_DEBIT_EXECUTION_RESULT_EXPORT_DESCRIPTION") })}
                        />
                    </GridItem>
                </GridContainer>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={showLiveProcess}
                >
                    <DialogTitle id="form-dialog-title">
                        {t('PROCESS_DEBIT_TITLE')}
                    </DialogTitle>
                    <DialogContent>
                        <LiveProcessModal
                            onClose={this.handleCloseLiveProcess}
                            onFinish={this.handleCloseLiveProcess}
                            processDescription={processPercentage < 100 ? t('DEBIT_PROCESS') : t('DEBIT_PROCESS_COMPLETED')}
                            label={t('PROCESS_DEBIT_LABEL', { total: nullController(this.state.executionSummary, "total") })}
                            isCompleted={processPercentage === 100}
                            processPercentage={processPercentage}
                            currentItem={getMemberLabelFromJobTask(wssTask, false)}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

DebitExecutionDetailsController.propTypes = {
    classes: PropTypes.object
}

export default withRouter(withStyles(dashboardStyle)(withWssListener(withNotification(withTranslation()(DebitExecutionDetailsController)))));
