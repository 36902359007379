/* Created by Marlon Reyes on July 25, 2019 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Button from "@material-ui/core/Button";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";
import CustomButton from "../../../components/CustomButtons/Button";
import ZoneForm from "../../Zones/components/ZoneForm";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Muted from "../../../components/Typography/Muted";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import MenusDetails from "../../Menus/components/MenusDetails";
import ZonesDetails from "../../Zones/components/ZonesDetails";
import {
    getData,
    isSuccessfulRequest,
    nullController,
    saveData,
} from "../../../utils/helpersFunctions";
import {
    IMG_MENU_CARD,
    IMG_ZONE_CARD,
} from "../../../variables/resourcesConstants";
import PrivateComponent from "../../../components/PrivateComponent/PrivateComponent";
import {
    APP_COMMERCE,
    NOTIFICATION_DURATION,
} from "../../../variables/coreConstants";
import zoneDetails from "../../../models/ZoneModel";
import menuDetails from "../../../models/MenuModel";
import Loading from "../../../components/Loading/Loading";
// import QrCodeGenerator from "./QrCodeGenerator";
import { getQrCodeInformationByStore } from "../../../services/assetService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import { validateSession } from "../../../services/api";
import QrCodeGenerator from "./QrCodeGenerator";
import storeDetails from "../../../models/StoreModel";
import { formTitles, statusText } from "../../../utils/statusHelpers";
import StoreForm from "./StoreForm";
import PublicApplicationDetails from "./PublicApplicationDetails";
import StoreImageUpload from "./StoreImageUpload";
import {
    getStoreDetails,
    updateExplorerMode,
} from "../../../services/storeService";
import Select from "../../../components/CustomSelect/CustomSelect";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ZoneGroups from "../../Zones/components/ZoneGroups";

class QuickActionsStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store: getData("store") !== null ? getData("store") : "",
            qrCodesData: [],
            storeZones: [],
            storeApps: [],
            storeZoneGroups: [],
            explorerMode: "",
            explorerModes: [],
            storeUrl: "",
            openMenu: false,
            openZone: false,
            openQr: false,
            openStore: false,
            storeCompleted: false,
            isLoading: false,
            isLoadingQrCodes: false,
            isLoadingExplorerMode: false,
            openRequirements: false,
            isQuickStartZ: true,
            isQuickStartM: true,
            isQuickStart: false,
            disableSwitch: false,
        };
        this.closeForm = this.closeForm.bind(this);
        this.changeQuickStart = this.changeQuickStart.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        if (this.state.store === "") {
            this.props.history.push("stores");
        } else {
            this.request();
        }
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh !== null) {
                    this.request();
                }
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    openRequirementsForm = () => {
        this.setState({ openRequirements: true });
    };

    closeRequirementsForm = () => {
        this.setState({ openRequirements: false });
    };

    openMenuForm = () => {
        menuDetails.cleanMenuDetails();
        this.setState({ openMenu: true });
    };

    closeMenuForm = () => {
        this.setState({ openMenu: false });
    };

    openQrForm = () => {
        saveData("store", this.state.store);
        this.setState({
            openQr: true,
            isLoadingQrCodes: true,
        });
        this.requestQrCodes();
    };

    closeQrForm = () => {
        this.setState({ openQr: false });
    };

    openZoneForm = () => {
        zoneDetails.cleanZoneDetails();
        this.setState({ openZone: true });
    };

    closeZoneForm = () => {
        this.setState({ openZone: false });
    };

    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) {
            this.setState({ isLoading: true });
            this.request();
        }
    }

    changeQuickStart(state, value) {
        this.setState({ [state]: value });
        if (
            this.state.isQuickStartZ === true &&
            this.state.isQuickStartM === true
        ) {
            this.setState({ isQuickStart: true });
        } else {
            this.setState({ isQuickStart: false });
        }
    }

    openStoreForm = () => {
        storeDetails.setStoreDetail(getData("store"));
        this.setState({
            openStore: true,
            storeCompleted: false,
        });
    };

    closeStoreForm = () => {
        this.setState({ openStore: false });
    };

    goToStores = () => {
        this.props.history.push("stores");
    };

    requestQrCodes() {
        // const { t } = this.props;
        getQrCodeInformationByStore(this.state.store.id)
            .then((response) => {
                if (response.data.length === 0) {
                    this.setState({
                        openQr: false,
                    });
                } else {
                    this.setState({
                        qrCodesData: response.data,
                        isLoadingQrCodes: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({ isLoadingQrCodes: false });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                }
            });
    }

    changeModeSelect = (explorerMode) => {
        let dataParameters = {
            storeId: this.state.store.id,
            explorerModeId: explorerMode.value,
        };

        this.setState({ explorerMode });
        this.setState({ isLoadingExplorerMode: true });
        updateExplorerMode(dataParameters)
            .then((response) => {
                this.setState({ isLoadingExplorerMode: false });
                if (isSuccessfulRequest(response)) {
                    this.showNotification(
                        response.data.message,
                        "primary",
                        NotificationSuccessIcon,
                        true
                    );
                } else {
                    this.setState((prevState) => ({
                        explorerMode: prevState.explorerMode,
                    }));
                    this.showNotification(
                        response.data.message,
                        "danger",
                        NotificationErrorIcon,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({ isLoadingExplorerMode: false });
                this.setState((prevState) => ({
                    explorerMode: prevState.explorerMode,
                }));
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                }
            });
    };

    request() {
        getStoreDetails(this.state.store.id)
            .then((response) => {
                if (response.data.storeZones.length) {
                    this.changeQuickStart(
                        "isQuickStartZ",
                        response.data.storeZones.length === 0
                    );
                }

                if (response.data.storeZones.length === 0) {
                    this.changeQuickStart("isQuickStartZ", true);
                } else {
                    this.changeQuickStart("isQuickStartZ", false);
                }

                let modesValues = response.data.explorerModes.map(
                    (val, key) => {
                        return { value: val.id, label: val.name };
                    }
                );

                this.setState({
                    storeUrl: response.data.storeUrl,
                    storeZones: response.data.storeZones,
                    storeApps: response.data.storePublicApplications,
                    explorerModes: modesValues,
                    storeZoneGroups: response.data.zoneGroups,
                    explorerMode: {
                        value: response.data.explorerMode.id,
                        label: response.data.explorerMode.name,
                    },
                    isLoading: false,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        null
                    );
                    validateSession(error.response, this.props.history);
                }
            });
    }

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            isLoadingQrCodes,
            isLoadingExplorerMode,
            qrCodesData,
            store,
            storeCompleted,
            isLoading,
            storeZones,
            storeApps,
            storeZoneGroups,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div>
                <GridContainer>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                    <GridItem xs={6} sm={6} md={6}>
                        <div>
                            <h3 className={classes.headTitle}>
                                {nullController(this.state.store, "name")}
                            </h3>
                            <Muted>
                                {t("TH_STORE_ID") +
                                    ": " +
                                    nullController(store, "uuid")}
                            </Muted>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <div className={classes.rightPaper}>
                            {/*<CustomButton*/}
                            {/*    variant="outlined"*/}
                            {/*    color="white"*/}
                            {/*    onClick={this.openQrForm}*/}
                            {/*    className={classes.cancelButton}*/}
                            {/*>*/}
                            {/*    {t("BTN_DOWNLOAD_QR_CODES")}*/}
                            {/*</CustomButton>*/}
                            <CustomButton
                                variant="outlined"
                                color="white"
                                onClick={this.openStoreForm}
                                className={classes.cancelButton}
                            >
                                {t("EDIT_TEXT")}
                            </CustomButton>
                            <Dialog
                                maxWidth={"md"}
                                fullWidth={true}
                                aria-labelledby="form-store-dialog-title"
                                open={this.state.openStore}
                                onClose={this.closeStoreForm}
                            >
                                <DialogTitle id="form-store-dialog-title">
                                    {t(formTitles[1])} {t("STORE_TEXT")}
                                </DialogTitle>
                                <DialogContent>
                                    {storeCompleted ? (
                                        <StoreImageUpload
                                            closeForm={this.closeForm}
                                            action={1}
                                        />
                                    ) : (
                                        <StoreForm
                                            closeForm={this.closeForm}
                                            action={1}
                                            component={this}
                                        />
                                    )}
                                </DialogContent>
                            </Dialog>
                            <CustomButton
                                color="primary"
                                onClick={this.goToStores}
                            >
                                {t("BTN_GO_STORE")}
                            </CustomButton>
                        </div>
                        {/*<Dialog*/}
                        {/*    maxWidth={"sm"}*/}
                        {/*    fullWidth={true}*/}
                        {/*    aria-labelledby="form-qr-dialog-title"*/}
                        {/*    open={this.state.openQr}*/}
                        {/*    onClose={this.closeQrForm}*/}
                        {/*>*/}
                        {/*    <DialogTitle id="form-menu-dialog-title">*/}
                        {/*        {t("FORM_STORE_QR_CODES_TITLE")}*/}
                        {/*    </DialogTitle>*/}
                        {/*    <DialogContent>*/}
                        {/*        {isLoadingQrCodes ? (*/}
                        {/*            <div>*/}
                        {/*                <Loading />*/}
                        {/*                <Muted>{t("PREPARING_PDF_TEXT")}</Muted>*/}
                        {/*            </div>*/}
                        {/*        ) : (*/}
                        {/*            <QrCodeGenerator*/}
                        {/*                data={qrCodesData}*/}
                        {/*                closeForm={this.closeForm}*/}
                        {/*            />*/}
                        {/*        )}*/}
                        {/*    </DialogContent>*/}
                        {/*</Dialog>*/}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2} md={2}>
                                            <p className={classes.settingsText}>
                                                {t("TH_STORE_STATUS")}
                                            </p>
                                            <p
                                                className={
                                                    classes.descriptionLink
                                                }
                                            >
                                                {t(
                                                    statusText[
                                                        nullController(
                                                            store,
                                                            "status"
                                                        )
                                                    ]
                                                )}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={12} sm={5} md={5}>
                                            <p className={classes.settingsText}>
                                                {t("TH_SOCIAL_LINK")}
                                            </p>
                                            <p
                                                className={
                                                    classes.descriptionLink
                                                }
                                            >
                                                {this.state.storeUrl}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={12} sm={4} md={4}>
                                            <p className={classes.settingsText}>
                                                {t("EXPLORER_MODE_TEXT")}
                                            </p>
                                            <Select
                                                value={this.state.explorerMode}
                                                onChange={this.changeModeSelect}
                                                options={
                                                    this.state.explorerModes
                                                }
                                                placeholder={t("SELECT_TEXT")}
                                                closeMenuOnSelect={true}
                                                isDisabled={
                                                    isLoadingExplorerMode
                                                }
                                                isLoading={
                                                    isLoadingExplorerMode
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary:
                                                            primaryColor[0],
                                                    },
                                                })}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        )}
                    </GridItem>
                    {isLoading ? null : (
                        <div>
                            {this.state.isQuickStart ? (
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.typo}>
                                        <h4> {t("QUICK_START")} </h4>
                                    </div>
                                </GridItem>
                            ) : null}
                        </div>
                    )}
                </GridContainer>
                {isLoading ? null : (
                    <div>
                        {this.state.isQuickStart ? (
                            <GridContainer>
                                <PrivateComponent appType={APP_COMMERCE}>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Card chart>
                                            <div className={classes.center}>
                                                <img
                                                    src={IMG_MENU_CARD}
                                                    width={"175px"}
                                                    height={"175px"}
                                                    alt={"Menu"}
                                                />
                                            </div>
                                            <CardBody>
                                                <h4
                                                    className={
                                                        classes.cardTitle
                                                    }
                                                >
                                                    {t("MENU_TEXT")}
                                                </h4>
                                                <p
                                                    className={
                                                        classes.cardCategory
                                                    }
                                                >
                                                    {t("MENU_CARD_DESCRIPTION")}
                                                </p>
                                            </CardBody>
                                            <CardFooter chart>
                                                <Button
                                                    onClick={this.openMenuForm}
                                                    size="small"
                                                    color="primary"
                                                >
                                                    {t("BTN_ADD_NEW_MENU")}
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                </PrivateComponent>
                                <GridItem xs={12} sm={12} md={4}>
                                    <Card chart>
                                        <div className={classes.center}>
                                            <img
                                                src={IMG_ZONE_CARD}
                                                width={"175px"}
                                                height={"175px"}
                                                alt={"Zone"}
                                            />
                                        </div>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>
                                                {t("ZONE_TEXT")}
                                            </h4>
                                            <p className={classes.cardCategory}>
                                                {t("ZONE_CARD_DESCRIPTION")}
                                            </p>
                                        </CardBody>
                                        <CardFooter chart>
                                            <Button
                                                onClick={this.openZoneForm}
                                                size="small"
                                                color="primary"
                                            >
                                                {t("BTN_ADD_NEW_ZONE")}
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                    <Dialog
                                        maxWidth={"md"}
                                        fullWidth={true}
                                        aria-labelledby="form-zone-dialog-title"
                                        open={this.state.openZone}
                                        onClose={this.closeZoneForm}
                                    >
                                        <DialogTitle id="form-zone-dialog-title">
                                            {t("FORM_NEW_ZONE_TEXT")}
                                        </DialogTitle>
                                        <DialogContent>
                                            <ZoneForm
                                                closeForm={this.closeForm}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </GridItem>
                            </GridContainer>
                        ) : (
                            <PrivateComponent appType={APP_COMMERCE}>
                                <MenusDetails
                                    quickAction={this.changeQuickStart}
                                />
                            </PrivateComponent>
                        )}
                    </div>
                )}
                {isLoading ? null : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <PublicApplicationDetails
                                data={storeApps}
                                component={this}
                            />
                        </GridItem>
                    </GridContainer>
                )}
                {isLoading ? null : (
                    <div>
                        {this.state.isQuickStart ? null : (
                            <ZonesDetails
                                quickAction={this.changeQuickStart}
                                data={storeZones}
                            />
                        )}
                    </div>
                )}
                {isLoading ? null : (
                    <div>
                        <ZoneGroups data={storeZoneGroups} />
                    </div>
                )}
            </div>
        );
    }
}

QuickActionsStore.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(dashboardStyle)(withTranslation()(QuickActionsStore))
);
