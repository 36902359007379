import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/DeleteOutline";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import formStyle from "../../assets/jss/custom-components/formStyle";
import modifierDetails from "../../models/ModifierModel";
import {withTranslation} from "react-i18next";
import NotificationErrorIcon from "@material-ui/icons/Error";
import {
    getCompanyCurrency,
    getRoundNumber,
    getValueFromCurrencyMask,
    setCurrencyValue
} from "../../utils/helpersFunctions";
import InputAdornment from "@material-ui/core/InputAdornment";


class ModifierItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemName: '',
            itemId: '',
            currency: getCompanyCurrency(),
            itemSurchargeMask: setCurrencyValue(0.00),
            itemSurcharge: 0,
            selectedItem: null
        };
    }


    handleChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    };


    handleCurrencyValue = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const value = e.target.value;
        const regExp = /[a-zA-Z]/g;

        if (!regExp.test(value)) {
            if (value !== null && value !== undefined && value !== "") {
                if (value.search("-") === -1) {
                    const mask = value.length <= 11 ? setCurrencyValue(value) : setCurrencyValue(0);

                    this.setState({
                        [name]: value.length <= 11 ? getValueFromCurrencyMask(mask) : 0.00,
                        [id]: mask
                    });
                }
            } else {
                this.setState({
                    [name]: 0.00,
                    [id]: setCurrencyValue(0)
                });
            }
        }
    };



    editItem = (item) => {
        const { component } = this.props;

        //Find index of specific object using findIndex method.
        let index = modifierDetails.modifierItems.findIndex((val => val.modifierItem.id === item.id));
        modifierDetails.modifierItems[index].modifierItem.isEditing = true;

        component.setState({
            itemsGroup: modifierDetails.modifierItems,
            disabledItem: true
        });

        this.setState({
            selectedItem: item,
            itemSurchargeMask: setCurrencyValue(getRoundNumber(item.surcharge)),
            itemSurcharge: item.surcharge,
            itemName: item.name,
            itemId: item.externalId,
        })
    };


    saveItemChanges = (item) => {
        const { component, t } = this.props;

        if (this.state.itemName !== ""  || this.state.itemSurcharge >= 0) {
            //Find index of specific object using findIndex method.
            let index = modifierDetails.modifierItems.findIndex((val => val.modifierItem.id === item.id));

            modifierDetails.modifierItems[index].modifierItem.name = this.state.itemName;
            modifierDetails.modifierItems[index].modifierItem.externalId = this.state.itemId;
            modifierDetails.modifierItems[index].modifierItem.surcharge = this.state.itemSurcharge;
            modifierDetails.modifierItems[index].modifierItem.isEditing = false;
            modifierDetails.modifierItems[index].modifierItem.isUpdated = true;

            this.setState({ selectedItem: null });
            component.setState({
                itemsGroup: modifierDetails.modifierItems,
                disabledItem: false,
            });


        } else {
            component.showNotification(t('INVALID_ITEM_MSG'), "danger", NotificationErrorIcon);
        }

    };


    deleteItem = (item) => {
        const { component } = this.props;

        let index = modifierDetails.modifierItems.findIndex(val => val.modifierItem.id === item.modifierItem.id);
        modifierDetails.modifierItems.splice(index, 1);
        component.setState({
            itemsGroup: modifierDetails.modifierItems,
            disabledItem: false
        });

        this.setState({ selectedItem: null });
    };



    render() {
        const { classes, items, t } = this.props;
        const { selectedItem, itemName, itemId, itemSurchargeMask, currency } = this.state;
        return (
            <div>
                {
                    items.map((item, key) => (
                        <div key={key} className={classes.scheduleContainer}>
                            {
                                    item.modifierItem.isEditing
                                    ? (
                                        <GridContainer>
                                            <GridItem xs={12} sm={5} md={5}>
                                                <TextField
                                                    value={itemName}
                                                    id="itemName"
                                                    name="itemName"
                                                    fullWidth={true}
                                                    onChange={this.handleChangeValues}
                                                    className={classes.modifierItemNameContainer}
                                                    margin="dense"
                                                    variant="outlined"/>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <TextField
                                                    value={itemId}
                                                    id="itemId"
                                                    name="itemId"
                                                    fullWidth={true}
                                                    onChange={this.handleChangeValues}
                                                    className={classes.itemIdContainer}
                                                    margin="dense"
                                                    variant="outlined"/>
                                            </GridItem>
                                            <GridItem xs={10} sm={5} md={5}>
                                                <TextField
                                                    value={itemSurchargeMask}
                                                    id="itemSurchargeMask"
                                                    name="itemSurcharge"
                                                    onChange={this.handleCurrencyValue}
                                                    className={classes.surchargeContainer}
                                                    margin="dense"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {getCompanyCurrency()}
                                                            </InputAdornment>
                                                        )
                                                    }}/>
                                                <Tooltip id="tooltip-save" title={t('BTN_SAVE_CHANGES')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.saveItemChanges(item.modifierItem)}
                                                        style={{marginLeft: 25, marginTop:13}}>
                                                        <SaveIcon className={classes.iconButtonGreen} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="tooltip-delete" title={t('DELETE_ITEM_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.deleteItem(item)}
                                                        style={{marginTop:13}}>
                                                        <ClearIcon className={classes.iconButtonRed} />
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>
                                    ) : (
                                        <GridContainer>
                                            <GridItem xs={12} sm={2} md={5}>
                                                <Chip label={item.modifierItem.name}/>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <Chip label={item.modifierItem.externalId}/>
                                            </GridItem>
                                            <GridItem xs={10} sm={5} md={5}>
                                                <Chip
                                                    label={
                                                        (currency ? currency : "") + " " +
                                                        setCurrencyValue(getRoundNumber(item.modifierItem.surcharge))}
                                                    style={{width:100}}/>
                                                <Tooltip id="tooltip-edit" title={t('EDIT_ITEM_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={() => this.editItem(item.modifierItem)}
                                                        disabled={selectedItem != null && selectedItem !== item}
                                                        style={{marginLeft: 25}}>
                                                        <EditIcon className={classes.iconButtonBlue} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="tooltip-delete" title={t('DELETE_ITEM_TEXT')} placement="top-start">
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={selectedItem != null && selectedItem !== item}
                                                        onClick={() => this.deleteItem(item)}>
                                                        <ClearIcon className={classes.iconButtonRed} />
                                                    </IconButton>
                                                </Tooltip>
                                            </GridItem>
                                        </GridContainer>
                                    )
                            }
                        </div>
                    ))
                }
            </div>
        );
    }

}

ModifierItems.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(ModifierItems)));
