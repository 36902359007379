import {
    STATUS_CANCELLED,
    STATUS_COMPLETED,
    STATUS_PAID_NOT_SYNC,
    STATUS_PENDING,
    STATUS_PROGRESS
} from "../variables/coreConstants";
import {statusText} from "../utils/statusHelpers";

var orderDetails = {
    id: null,
    uuid: "",
    status: null,
    oldStatus: null,

    setOrderDetail : function (values) {
        this.id = values.id;
        this.uuid = values.uuid;
        this.status = values.orderStatus ? values.orderStatus : values.status;
        this.oldStatus = values.orderStatus;
    },

    getOrderStatusAction : function (_status) {
        let action;
        switch (_status) {
            case STATUS_COMPLETED: action = "ready";
                break;

            case STATUS_CANCELLED: action = "cancel";
                break;

            case STATUS_PROGRESS: action = "accept";
                break;

            default: action = -1;
        }

        return action;
    },

    getStatusOptions : function (props) {
        const { t } = props;
        let options = [];

        switch (this.status) {
            case STATUS_COMPLETED: options = [];
                break;

            case STATUS_CANCELLED: options = [];
                break;

            case STATUS_PROGRESS: options = [{value: STATUS_COMPLETED, label: t(statusText[STATUS_COMPLETED])}, {value: STATUS_CANCELLED, label: t(statusText[STATUS_CANCELLED])}];
                break;

            case STATUS_PENDING: options = [{value: STATUS_COMPLETED, label: t(statusText[STATUS_COMPLETED])}, {value: STATUS_CANCELLED, label: t(statusText[STATUS_CANCELLED])}, {value: STATUS_PROGRESS, label: t(statusText[STATUS_PROGRESS])}];
                break;

            case STATUS_PAID_NOT_SYNC: options = [{value: STATUS_COMPLETED, label: t(statusText[STATUS_COMPLETED])}];
                break;

            default: options = [];
        }

        return options;
    },

    disableOrderStatus : function () {
        let result;

        switch (this.status) {
            case STATUS_COMPLETED: result = true;
                break;

            case STATUS_CANCELLED: result = true;
                break;

            case STATUS_PROGRESS: result = false;
                break;

            case STATUS_PENDING: result = false;
                break;

            default: result = true;
        }

        return result;
    },

    cleanOrderDetails : function () {
        this.id = null;
        this.name = "";
        this.status = null;
        this.oldStatus = null;
    }


};

export default orderDetails;