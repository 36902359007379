import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
// core components
import GridContainer from "../../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../../components/Grid/GridItem.jsx";
import TakeAwayIcon from "@material-ui/icons/DirectionsWalk";
import DineInIcon from "@material-ui/icons/Store";
import DeliveryIcon from "@material-ui/icons/LocalShipping";
import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-react/customCheckboxRadioSwitch.jsx";
import formStyle from "../../../../assets/jss/custom-components/formStyle";
import InputLabel from "@material-ui/core/InputLabel";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import NotificationErrorIcon from "@material-ui/icons/Error";
import Select from "../../../../components/CustomSelect/CustomSelect";
import makeAnimated from 'react-select/animated';
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import {getCompanyCurrency, selectValidation} from "../../../../utils/helpersFunctions";
import CurrencyInput from "../../../../components/CustomInput/CurrencyInput";
import {withRouter} from "react-router-dom";


const animatedComponents = makeAnimated();

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "0px"
    },
    ...customCheckboxRadioSwitch,
    ...formStyle
};

class ApplicationSettingStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: "settings",
        };
        this.isValidated = this.isValidated.bind(this);
        this.sendState = this.sendState.bind(this);
    }


    handleChange = name => event => {
        this.props.component.handleChangeSetting(name, event);
    };
    changeMenuSelect = (menus) => {
        this.props.component.changeMenuSelect(menus);
    };
    changeTakeAwayPercentageSelect = (takeAwayPercentage) => {
        this.props.component.changeTakeAwayPercentageSelect(takeAwayPercentage);
    };
    changeDineInPercentageSelect = (dineInPercentage) => {
        this.props.component.changeDineInPercentageSelect(dineInPercentage);
    };
    changeDeliveryPercentageSelect = (deliveryPercentage) => {
        this.props.component.changeDeliveryPercentageSelect(deliveryPercentage);
    };
    changeTagAppSelect = (cuisines) => {
        this.props.component.changeTagAppSelect(cuisines);
    };
    handleChangeValues = (e) => {
        this.props.component.handleChangeValues(e);
    };
    handleCurrencyValue = (e) => {
        this.props.component.handleCurrencyValue(e);
    };
    handleChangeTip = name => event => {
        this.props.component.handleChangeTip(name, event);
    };



    // Method for wizard
    sendState() {
        return this.state;
    }


    // Validate step form
    isValidated() {

        const { component, translation } = this.props;
        
        if (component.state.takeAway || component.state.dineIn || component.state.delivery) {
            if (component.state.delivery && (component.state.deliveryMinimum < 0 || component.state.deliveryRadius <= 0)) {
                component.showNotification(translation('INVALID_DELIVERY_SETTING_MSG'), "danger",
                    NotificationErrorIcon, null);

                return false;
            }

            if (component.state.takeAway && component.state.takeAwayMinimum < 0) {
                component.showNotification(translation('INVALID_TAKE_AWAY_SETTING_MSG'),
                    "danger", NotificationErrorIcon, null);

                return false;
            }


            if (component.state.dineIn && component.state.dineInMinimum < 0) {
                component.showNotification(translation('INVALID_DINE_IN_SETTING_MSG'), "danger",
                    NotificationErrorIcon, null);
                
                return false;
            }

            if (component.state.deliveryTip && selectValidation(component.state.deliveryPercentage)) {
                component.showNotification(translation('INVALID_TIP_PERCENTAGE_MSG'), "danger",
                    NotificationErrorIcon, null);
                return false;
            }

            if (component.state.takeAwayTip && selectValidation(component.state.takeAwayPercentage)) {
                component.showNotification(translation('INVALID_TIP_PERCENTAGE_MSG'), "danger",
                    NotificationErrorIcon, null);
                return false;
            }

            if (component.state.dineInTip && selectValidation(component.state.dineInPercentage)) {
                component.showNotification(translation('INVALID_TIP_PERCENTAGE_MSG'), "danger",
                    NotificationErrorIcon, null);
                return false;
            }

            return true;

        } else {
            component.showNotification(translation('APP_OPTION_REQUIRED_MSG'), "danger",
                NotificationErrorIcon, null);

            return false
        }
    }




    render() {
        const { classes, component, translation } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl} style={{ marginBottom: 20, marginTop: 10}}>
                            <InputLabel htmlFor="cuisines" className={classes.selectTitle}>
                                {translation('APP_TAGS_TXT')}
                            </InputLabel>
                            <Select
                                value={component.state.cuisines}
                                onChange={this.changeTagAppSelect}
                                components={animatedComponents}
                                isLoading={component.state.loadingCuisines}
                                options={component.state.cuisinesOptions}
                                isDisabled={false}
                                name="cuisines"
                                isMulti={true}
                                classNamePrefix="cuisines"
                                placeholder={translation('SELECT_TEXT')}
                                closeMenuOnSelect={false}
                                maxMenuHeight={150}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.selectControl} style={{ marginBottom: 20, marginTop: 10}}>
                            <InputLabel htmlFor="menus" className={classes.selectTitle}>
                                {translation('CATALOGS_AVAILABLE_TXT')}
                            </InputLabel>
                            <Select
                                value={component.state.menus}
                                onChange={this.changeMenuSelect}
                                components={animatedComponents}
                                isLoading={component.state.loadingMenus}
                                options={component.state.menusOptions}
                                isDisabled={false}
                                name="menus"
                                isMulti={true}
                                placeholder={translation('SELECT_TEXT')}
                                closeMenuOnSelect={false}
                                maxMenuHeight={150}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <GridContainer justify="center">
                            <h4 className={classes.infoText}>{translation('OPTIONS_AVAILABLE_TXT')}</h4>
                            <GridItem xs={12} sm={4}>
                                <div className={classes.choiche}>
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={this.handleChange("takeAway")}
                                        checked={component.state.takeAway}
                                        checkedIcon={
                                            <TakeAwayIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        icon={
                                            <TakeAwayIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        classes={{
                                            checked: classes.iconCheckboxChecked,
                                            root: classes.iconCheckbox
                                        }}
                                    />
                                    <h6 style={{marginBlockStart: "0.40em", marginBlockEnd: "0.40em"}}>
                                        {translation('TAKE_AWAY_TXT')}
                                    </h6>
                                    {component.state.takeAway
                                        ? <div>
                                            <InputLabel className={classes.smallSwitchLabel}>{translation('ACCEPT_TIP_TXT')}</InputLabel>
                                            <CustomSwitch
                                                className={classes.switchBase}
                                                size="small"
                                                checked={component.state.takeAwayTip}
                                                onChange={this.handleChangeTip("takeAwayTip")}/>
                                            <div className={classes.selectControl} style={{ marginBottom: 20, marginTop: 10}}>
                                                <InputLabel htmlFor="taPercentage" className={classes.selectTitle}>
                                                    {translation('DEFAULT_PERCENTAGE_TXT')}
                                                </InputLabel>
                                                <Select
                                                    value={component.state.takeAwayPercentage}
                                                    onChange={this.changeTakeAwayPercentageSelect}
                                                    components={animatedComponents}
                                                    options={component.state.tipPercentagesOptions}
                                                    isDisabled={false}
                                                    name="menus"
                                                    closeMenuOnSelect={true}
                                                    maxMenuHeight={100}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: primaryColor[0],
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={4}>
                                <div className={classes.choiche}>
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={this.handleChange("dineIn")}
                                        checked={component.state.dineIn}
                                        checkedIcon={
                                            <DineInIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        icon={
                                            <DineInIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        classes={{
                                            checked: classes.iconCheckboxChecked,
                                            root: classes.iconCheckbox
                                        }}
                                    />
                                    <h6 style={{marginBlockStart: "0.40em", marginBlockEnd: "0.40em"}}>
                                        {translation('DINE_IN_TEXT')}
                                    </h6>
                                    {component.state.dineIn
                                        ? <div>
                                            <InputLabel className={classes.smallSwitchLabel}>{translation('ACCEPT_TIP_TXT')}</InputLabel>
                                            <CustomSwitch
                                                className={classes.switchBase}
                                                size="small"
                                                checked={component.state.dineInTip}
                                                onChange={this.handleChangeTip("dineInTip")}/>
                                            <div className={classes.selectControl} style={{ marginBottom: 20, marginTop: 10}}>
                                                <InputLabel htmlFor="diPercentage" className={classes.selectTitle}>
                                                    {translation('DEFAULT_PERCENTAGE_TXT')}
                                                </InputLabel>
                                                <Select
                                                    value={component.state.dineInPercentage}
                                                    onChange={this.changeDineInPercentageSelect}
                                                    components={animatedComponents}
                                                    options={component.state.tipPercentagesOptions}
                                                    isDisabled={false}
                                                    name="dineInPercentage"
                                                    closeMenuOnSelect={true}
                                                    maxMenuHeight={100}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: primaryColor[0],
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={4}>
                                <div className={classes.choiche}>
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={this.handleChange("delivery")}
                                        checked={component.state.delivery}
                                        checkedIcon={
                                            <DeliveryIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        icon={
                                            <DeliveryIcon
                                                className={classes.iconCheckboxIcon}
                                            />
                                        }
                                        classes={{
                                            checked: classes.iconCheckboxChecked,
                                            root: classes.iconCheckbox
                                        }}
                                    />
                                    <h6 style={{marginBlockStart: "0.40em", marginBlockEnd: "0.40em"}}>
                                        {translation('DELIVERY_TEXT')}
                                    </h6>
                                    {component.state.delivery
                                        ? <div>
                                            <InputLabel className={classes.smallSwitchLabel}>{translation('ACCEPT_TIP_TXT')}</InputLabel>
                                            <CustomSwitch
                                                className={classes.switchBase}
                                                size="small"
                                                checked={component.state.deliveryTip}
                                                onChange={this.handleChangeTip("deliveryTip")}/>
                                            <div className={classes.selectControl} style={{ marginBottom: 20, marginTop: 10}}>
                                                <InputLabel htmlFor="dPercentage" className={classes.selectTitle}>
                                                    {translation('DEFAULT_PERCENTAGE_TXT')}
                                                </InputLabel>
                                                <Select
                                                    value={component.state.deliveryPercentage}
                                                    onChange={this.changeDeliveryPercentageSelect}
                                                    components={animatedComponents}
                                                    options={component.state.tipPercentagesOptions}
                                                    isDisabled={false}
                                                    name="deliveryPercentage"
                                                    closeMenuOnSelect={true}
                                                    maxMenuHeight={100}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary: primaryColor[0],
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <GridContainer>
                            {
                                component.state.takeAway ?
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div style={{ display: "flex", height: "auto", flexDirection: "column", marginTop: 50}}>
                                            <CurrencyInput
                                                value={component.state.takeAwayMinimumMask}
                                                onChange={this.handleCurrencyValue}
                                                labelText={translation('TAKE_AWAY_MIN_TXT')}
                                                id="takeAwayMinimumMask"
                                                name="takeAwayMinimum"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                currencySymbol={getCompanyCurrency()}
                                            />
                                        </div>
                                    </GridItem>
                                    : null
                            }
                            {
                                component.state.dineIn ?
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div style={{ display: "flex", height: "auto", flexDirection: "column", marginTop: 50}}>
                                            <CurrencyInput
                                                value={component.state.dineInMinimumMask}
                                                onChange={this.handleCurrencyValue}
                                                labelText={translation('DINE_IN_MIN_TXT')}
                                                id="dineInMinimumMask"
                                                name="dineInMinimum"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                currencySymbol={getCompanyCurrency()}
                                            />
                                        </div>
                                    </GridItem>
                                    : null
                            }
                            {
                                component.state.delivery ?
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div style={{
                                            display: "flex",
                                            height: "auto",
                                            flexDirection: "row",
                                            marginTop: (component.state.takeAway || component.state.dineIn) ? 0 : 50
                                        }}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CurrencyInput
                                                        value={component.state.deliveryMinimumMask}
                                                        onChange={this.handleCurrencyValue}
                                                        labelText={translation('DELIVERY_MIN_TXT')}
                                                        id="deliveryMinimumMask"
                                                        name="deliveryMinimum"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        currencySymbol={getCompanyCurrency()}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CurrencyInput
                                                        value={component.state.deliveryRadiusMask}
                                                        onChange={this.handleCurrencyValue}
                                                        labelText={translation('DELIVERY_RADIUS_TXT')}
                                                        id="deliveryRadiusMask"
                                                        name="deliveryRadius"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        currencySymbol={"Km"}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CurrencyInput
                                                        value={component.state.deliveryBaseFeeMask}
                                                        onChange={this.handleCurrencyValue}
                                                        labelText={translation('DELIVERY_BASE_FEE_TXT')}
                                                        id="deliveryBaseFeeMask"
                                                        name="deliveryBaseFee"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        currencySymbol={getCompanyCurrency()}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CurrencyInput
                                                        value={component.state.deliveryBaseFeeRadiusMask}
                                                        onChange={this.handleCurrencyValue}
                                                        labelText={translation('DELIVERY_BASE_FEE_RADIUS_TXT')}
                                                        id="deliveryBaseFeeRadiusMask"
                                                        name="deliveryBaseFeeRadius"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        currencySymbol={"Km"}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <CurrencyInput
                                                        value={component.state.deliveryExtraFeeMask}
                                                        onChange={this.handleCurrencyValue}
                                                        labelText={translation('DELIVERY_EXTRA_FEE_TXT')}
                                                        id="deliveryExtraFeeMask"
                                                        name="deliveryExtraFee"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        currencySymbol={getCompanyCurrency()}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        value={component.state.instructions}
                                                        onChange={this.handleChangeValues}
                                                        labelText={translation('INSTRUCTIONS_TEXT')}
                                                        id="instructions"
                                                        name="instructions"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </GridItem>
                                    : null
                            }
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}


ApplicationSettingStep.propTypes = {
    classes: PropTypes.object,
    component: PropTypes.object.isRequired,
    translation: PropTypes.func.isRequired
};

export default withRouter(withStyles(style)(ApplicationSettingStep));
