import React from "react";
import {withTranslation} from "react-i18next";
import GridContainer from "../../../components/Grid/GridContainer";
import { withRouter } from "react-router-dom";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridItem from "../../../components/Grid/GridItem";
import {nullController} from "../../../utils/helpersFunctions";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Status from "../../../components/Status/Status";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import TableActions from "../../../components/TableActions/TableActions";
import {
    APP_MENUS_DETAILS_TABLE_ACTIONS,
} from "../../../variables/coreConstants";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS
} from "../../../variables/apiConstants";
import Button from "../../../components/CustomButtons/Button";
import Loading from "../../../components/Loading/Loading";


class AppMenusDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menusData: this.props.data,
            filteredMenus: this.props.data,
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
        };
    }


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    // goToDetails = (val) => {
    //     saveData("menu", val);
    //     this.props.history.push("quick-actions-menu");
    // };



    render() {
        const { classes, t, component } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={6} sm={6} md={6} >
                    <div className={classes.typo}>
                        <h4> {t('MENUS_TEXT')} </h4>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.rightPaper}>
                        <Button
                            color="primary"
                            onClick={component.openMenuForm}>
                            {t('BTN_ASSIGN_MENU')}
                        </Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {component.state.isLoadingM ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_DESCRIPTION')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STATUS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {component.state.menusData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val.entity, 'name')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val.entity, 'description')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.entity.status]}
                                                                size="lg"
                                                                label={t(statusText[val.entity.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    options={APP_MENUS_DETAILS_TABLE_ACTIONS}
                                                                    openForm={component.openForm}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={component.state.menusData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                </GridItem>
            </GridContainer>
        );
    }

}

AppMenusDetails.protoType = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(AppMenusDetails)));
