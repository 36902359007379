import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {useTranslation} from "react-i18next";
import HeaderForm from "../../../../components/CustomComponents/HeaderForm";
import {nullController} from "../../../../utils/helpersFunctions";
import CardInvoice from "../../../../components/CustomComponents/CardInvoice";



function CashAccountFormAgreement({ ...props }) {
    const { classes, data } = props;
    const {t} = useTranslation();


    return (
        <div className={classes.containerForm}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.headerFormContainerSmall}>
                                <HeaderForm
                                    title={t('FORM_ACCEPT_AGREEMENT_TITLE')}
                                    description={t('FORM_ACCEPT_AGREEMENT_DESCRIPTION')}/>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                        <GridItem xs={12} sm={8} md={6} lg={6}>
                            <div className={classes.formBodyContainer}>
                                <CardInvoice
                                    data={[
                                        { label: t('ACCOUNT_IBAN_TXT'), value: nullController(data, "account")},
                                        { label: t('BANK_PROVIDER_TXT'), value: nullController(data, "provider")},
                                        { label: t('DATE_TEXT'), value: nullController(data, "date")},
                                        { label: t('AGREEMENT_REFERENCE_TXT'), value: nullController(data, "agreementReference")},]}
                                    showFooter={true} footerText={t('ACCEPT_AGREEMENT_CARD_LEGEND')}/>
                            </div>
                        </GridItem>
                        <GridItem xs={false} sm={2} md={3} lg={3}/>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}

function propsAreEqual(prevProps, nextProps) {
    return prevProps.stepSuccess === nextProps.stepSuccess;
}

CashAccountFormAgreement.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object
};

export default React.memo(withStyles(dashboardStyle)(CashAccountFormAgreement), propsAreEqual);
