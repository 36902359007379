import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "../../../components/Snackbar/Snackbar";
// @material-ui/core icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// @material-ui/react-select
import Select from "../../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
// Models & service
// Styles
import formStyle from "../../../assets/jss/custom-components/formStyle";
import {NOTIFICATION_DURATION} from "../../../variables/coreConstants";
import {
    disableComponent, enableComponent,
    getData, isSuccessfulCreate,
    selectValidation,
} from "../../../utils/helpersFunctions";
import {assign} from "../../../services/statusService";
import {APP_CATALOG_ASSIGN, BASE_URL_CORE} from "../../../variables/apiConstants";
import {primaryColor} from "../../../assets/jss/material-dashboard-react";
import {validateSession} from "../../../services/api";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";


const animatedComponents = makeAnimated();


class MenuAppAssignmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menus: '',
            loadingAssets: true,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
            disableField: false,
        };
        this.assignMenu = this.assignMenu.bind(this);
        this.cleanData = this.cleanData.bind(this);
    }


    changeMenuSelect = menus => {
        this.setState({ menus });
    };

    cleanData() {
        this.setState({
            menus: '',
        });
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.props.closeForm("openMenu", false, refresh);

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };


    assignMenu() {
        const { t } = this.props;

        if (selectValidation(this.state.menus)) {
            this.showNotification(t('MENU_REQUIRED_MSG'), "danger", NotificationErrorIcon, null);

        } else {
            let app = getData("app");

            let menusId = this.state.menus.map( item => {
                return { id: item.value };
            });

            let dataParameters = {
                "appId": app.id,
                "catalogs": JSON.stringify(menusId)
            };

            disableComponent(this, "disableField");
            assign(BASE_URL_CORE, APP_CATALOG_ASSIGN, dataParameters)
                .then((response) => {
                    if (isSuccessfulCreate(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);

                    } else {
                        enableComponent(this, "disableField");
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch((error) => {
                    enableComponent(this, "disableField");

                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationErrorIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    }


    render() {
        const { classes, t, menusOptions } = this.props;

        const AssignAssetButton = () => (
            <Button
                color="primary"
                className={classes.buttonSpacing}
                onClick={() => this.assignMenu()}
                disabled={this.state.disableField}
            >
                {t('BTN_ASSIGN')}
            </Button>
        );

        return (
            <div className={classes.formContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.selectControlMax}>
                            <InputLabel htmlFor="asset" className={classes.selectTitle}>
                                {t('MENUS_TEXT')}
                            </InputLabel>
                            <Select
                                value={this.state.menus}
                                onChange={this.changeMenuSelect}
                                components={animatedComponents}
                                options={menusOptions}
                                placeholder={t('SELECT_TEXT')}
                                isMulti={true}
                                isClearable={true}
                                maxMenuHeight={100}
                                closeMenuOnSelect={false}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: primaryColor[0],
                                    },
                                })}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.rightPaper}>
                            <Button
                                color="white"
                                className={classes.buttonSpacing + " " + classes.cancelButton}
                                onClick={() => this.props.closeForm("openMenu", false, false)}
                            >
                                {t('BTN_CANCEL')}
                            </Button>
                            <AssignAssetButton/>
                        </div>
                    </GridItem>
                    <Snackbar
                        place="tc"
                        color={this.state.color}
                        icon={this.state.icon}
                        message={this.state.notificationMessage}
                        open={this.state.notification}
                        closeNotification={this.hideNotification}
                        close
                    />
                </GridContainer>
            </div>
        );
    }
}

MenuAppAssignmentForm.propTypes = {
    classes: PropTypes.object,
    menusOptions: PropTypes.array.isRequired
};

export default withRouter(withStyles(formStyle)(withTranslation()(MenuAppAssignmentForm)));