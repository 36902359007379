import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Snackbar from "../../components/Snackbar/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {
    removeSession,
    saveUser,
    validateEmail,
    validateFields,
} from "../../utils/helpersFunctions";
import { authentication } from "../../services/accountService";
import signInStyle from "../../assets/jss/custom-components/signInStyle";
import { NOTIFICATION_DURATION } from "../../variables/coreConstants";
import {
    IMG_GINIH_LOGO,
    IMG_WELCOME,
} from "../../variables/resourcesConstants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Muted from "../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";

export default function SignIn() {
    const [loading, setLoading] = React.useState(false);

    const classes = signInStyle();
    const [open, setOpen] = React.useState(false);
    const [openForgotForm, setForgotForm] = React.useState(false);
    const { t } = useTranslation();

    const [values, setValues] = React.useState({
        email: "",
        password: "",
        showPassword: false,
        message: "",
        color: "warning",
        icon: NotificationSuccessIcon,
        isLoading: false,
    });

    function showNotification(msj, color, icon) {
        setValues({ ...values, message: msj, color: color, icon: icon });
        setOpen(true);
        window.setTimeout(function() {
            setOpen(false);
        }, NOTIFICATION_DURATION);
    }

    function hideNotification(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const closeForgotForm = () => {
        setForgotForm(false);
    };
    const handleOpenForgotForm = () => {
        setForgotForm(true);
    };

    function login(history) {
        let fields = [values.email, values.password];

        if (!validateFields(fields)) {
            showNotification(
                t("LOGIN_FIELDS_REQUIRED"),
                "danger",
                NotificationErrorIcon
            );
        } else if (validateEmail(values.email)) {
            setLoading((prevLoading) => !prevLoading);

            let dataParameters = {
                username: values.email,
                password: values.password,
            };

            authentication(dataParameters)
                .then((response) => {
                    try {
                        let data = response.data;
                        let result = data.userDetails;
                        if (response.status === 200 && data.success === true) {
                            setLoading((prevLoading) => !prevLoading);
                            removeSession();

                            if (
                                saveUser(result, response.headers.authorization)
                            ) {
                                history.push("/admin");
                            } else {
                                showNotification(
                                    t("INVALID_SESSION"),
                                    "danger",
                                    NotificationErrorIcon
                                );
                            }
                        } else {
                            showNotification(
                                data.message,
                                "danger",
                                NotificationErrorIcon
                            );
                            setLoading((prevLoading) => !prevLoading);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                })
                .catch((error) => {
                    setLoading((prevLoading) => !prevLoading);
                    if (error.response)
                        showNotification(
                            error.response.data.message,
                            "danger",
                            NotificationFailedIcon
                        );
                });
        } else {
            showNotification(
                t("INVALID_EMAIL_MESSAGE"),
                "danger",
                NotificationErrorIcon
            );
        }
    }

    const SubmitButton = withRouter(({ history }) => (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => login(history)}
        >
            {t("BTN_LOGIN")}
        </Button>
    ));

    //Set page title
    document.title = document.title
        ? document.title.split("-")[0].trim() + " - " + t("SIGNIN_TEXT")
        : t("SIGNIN_TEXT");

    return (
        <div className={classes.container}>
            <GridContainer style={{ height: "100%" }}>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.welcomeWrapper}>
                        <div className={classes.welcomeMessage}>
                            <img
                                className={classes.image}
                                src={IMG_WELCOME}
                                alt={"Login"}
                            />
                            <Typography component="h3" variant="h3">
                                {t("WELCOME_TITLE")}
                            </Typography>
                            <Typography component="h3" variant="subtitle1">
                                {t("WELCOME_MSG")}
                            </Typography>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <img
                                className={classes.logoContainer}
                                src={IMG_GINIH_LOGO}
                                alt={"Logo"}
                            />
                            <form className={classes.form}>
                                <TextField
                                    className={classes.textFieldPrimary}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={t("EMAIL_TEXT")}
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    className={classes.textFieldPrimary}
                                    id="password"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type={
                                        values.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    label={t("PASSWORD_TEXT")}
                                    value={values.password}
                                    onChange={handleChange("password")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    autoComplete="current-password"
                                />
                                <Button
                                    color={"primary"}
                                    size={"small"}
                                    onClick={handleOpenForgotForm}
                                >
                                    {t("FORGOT_PASSWORD_TEXT")}
                                </Button>
                                <SubmitButton />
                                <Grid container>
                                    <Grid item xs></Grid>
                                    <Grid item style={{ marginTop: 5 }}>
                                        {t("DONT_HAVE_ACCOUNT")}
                                        <NavLink
                                            to="/sign-up"
                                            style={{
                                                color: primaryColor[0],
                                                textDecoration: "none",
                                            }}
                                            activeStyle={{
                                                color: primaryColor[0],
                                                textDecoration: "none",
                                            }}
                                        >
                                            {t("REGISTER_TEXT")}
                                        </NavLink>
                                    </Grid>
                                    <Dialog
                                        maxWidth={"sm"}
                                        fullWidth={true}
                                        open={openForgotForm}
                                        onClose={closeForgotForm}
                                        aria-labelledby="form-submit-dialog-title"
                                    >
                                        <DialogTitle id="form-submit-dialog-title">
                                            {t("FORGOT_PASSWORD_FORM")}
                                            <Muted>
                                                {t(
                                                    "FORGOT_PASSWORD_FORM_DESCRIPTION"
                                                )}
                                            </Muted>
                                        </DialogTitle>
                                        <DialogContent>
                                            <ForgotPasswordForm
                                                close={() => closeForgotForm()}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </Grid>
                            </form>
                        </div>
                        <Box mt={6}>
                            <GridItem xs={12}>
                                <div className={classes.root}>
                                    <div className={classes.placeholder}>
                                        <Fade
                                            in={loading}
                                            style={{
                                                transitionDelay: loading
                                                    ? "600ms"
                                                    : "0ms",
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress
                                                style={{
                                                    color: primaryColor[0],
                                                }}
                                            />
                                        </Fade>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="center"
                                >
                                    {t("MADE_WITH_LOVE_TEXT")}
                                    <Link color="inherit" href="#">
                                        {t("GINIH_TEAM")}
                                    </Link>
                                </Typography>
                            </GridItem>
                        </Box>
                        <Snackbar
                            place="tr"
                            color={values.color}
                            icon={values.icon}
                            message={values.message}
                            open={open}
                            closeNotification={hideNotification}
                            close
                        />
                    </Container>
                </GridItem>
            </GridContainer>
        </div>
    );
}
