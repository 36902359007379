import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import NotificationErrorIcon from "@material-ui/icons/Error";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableActions from "../../../components/TableActions/TableActions";
import {withRouter} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    COMPANY_PROVIDERS_TABLE_ACTIONS, DEFAULT_PROVIDER_TABLE_ACTIONS, NOTIFICATION_DURATION,
    ROWS_PER_PAGE_DETAILS,
    ROWS_PER_PAGE_OPTIONS_DETAILS, SET_DEFAULT
} from "../../../variables/coreConstants";
import TableBody from "@material-ui/core/TableBody";
import {getStringTextFormatByName, isSuccessfulRequest, nullController} from "../../../utils/helpersFunctions";
import {validateSession} from "../../../services/api";
import {getPaymentMethodProvidersByCompany, setDefaultPaymentProvider} from "../../../services/accountService";
import PaymentMethodProviderForm from "./PaymentMethodProviderForm";
import {statusController} from "../../../services/statusService";
import {BASE_URL_COMMERCE, COMPANY_PROVIDERS_STATUS} from "../../../variables/apiConstants";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CheckIcon from "@material-ui/icons/CheckCircle";


class PaymentMethodProviderDetails extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openForm: false,
            isLoading: false,
            actionType: 0,
            paymentMethodData: [],
            rowsPerPage: ROWS_PER_PAGE_DETAILS,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });

                if (refresh != null)
                    this.request();

            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ notification: false });
    };

    handleOpenForm = () => {
        // menuDetails.cleanMenuDetails();
        this.setState({ openForm: true });
        this.setState({ actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openForm: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if (type) {
            this.request();
        }
    }
    openForm = (action, formData) => {
        const { t } = this.props;
        let dataParameters = { "companyPaymentProviderId": formData.id };

        if (action === 1 || action === 0) {
            this.setState({ openForm: true, actionType: action });
        } else if (action === SET_DEFAULT) {
            setDefaultPaymentProvider(dataParameters)
                .then((response) => {
                    if (isSuccessfulRequest(response)){
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    } else {
                        this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                    }
                });
        } else {
            statusController(action, BASE_URL_COMMERCE, COMPANY_PROVIDERS_STATUS, dataParameters, this, false)
                .then((response) => {
                    if (isSuccessfulRequest(response)){
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationErrorIcon, null);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    };


    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };



    request() {
        this.setState({ isLoading: true });

        getPaymentMethodProvidersByCompany()
            .then((res) => {
                this.setState({ paymentMethodData: res.data });
                this.setState({ isLoading: false });

            })
            .catch((err) => {
                this.setState({ isLoading: false });

                if (err.response)
                    validateSession(err.response, this.props.history);
            });
    }



    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage } = this.state;

        return (
            <GridContainer>
                <GridItem xs={6} sm={6} md={6} >
                    <div className={classes.typo}>
                        <h4>{t('PAYMENT_METHOD_PROVIDERS_TEXT')}</h4>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.rightPaper}>
                        <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_ADD_NEW')}</Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card className={classes.card}>
                            <CardBody className={classes.cardBodyBottomFit}>
                                <div className={classes.tableResumeResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_PAYMENT_METHOD_PROVIDER')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACCEPTED_CARDS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_DEFAULT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.paymentMethodData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell
                                                            className={classes.tableCell + " " + classes.hoverRow}>
                                                            {nullController(val, "name")}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {getStringTextFormatByName(val.paymentTypes)}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{width: "20%"}}
                                                            className={classes.tableCell + "" + classes.tableCellCenter}>
                                                            {nullController(val, "default")
                                                                ? <CheckIcon className={classes.thGreenIcon}/>
                                                                : null}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    openForm={this.openForm}
                                                                    options={
                                                                        !nullController(val, "techService") || nullController(val, "default")
                                                                            ? COMPANY_PROVIDERS_TABLE_ACTIONS
                                                                            : DEFAULT_PROVIDER_TABLE_ACTIONS
                                                                    }
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS_DETAILS}
                                    component="div"
                                    count={this.state.paymentMethodData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                    <Dialog
                        maxWidth={"sm"}
                        fullWidth={true}
                        open={this.state.openForm}
                        onClose={this.handleCloseForm}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            {t('FORM_PAYMENT_METHOD_PROVIDER_TITLE')}
                            {/*<Muted>*/}
                            {/*    {t('')}*/}
                            {/*</Muted>*/}
                        </DialogTitle>
                        <DialogContent>
                            <PaymentMethodProviderForm closeForm={this.closeForm} action={actionType}/>
                        </DialogContent>
                    </Dialog>
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

PaymentMethodProviderDetails.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(PaymentMethodProviderDetails)));
