// import { getService, postService, putService, deleteService } from "./api";
import {postService, putService} from "./api";
// import orderDetails from "../models/OrderModel";
import {
    BASE_URL_COMMERCE,
    BASE_URL_CORE,
    ORDER_DETAIL, ORDER_LIST_BY_ZONE,
    ORDERS_BY_COMPANY,
    ORDERS_BY_STORE, SYNC_ORDER, UPDATE_ORDER_STATUS
} from "../variables/apiConstants";

export function getOrdersByCompany(parameters) {
    let url = BASE_URL_CORE + ORDERS_BY_COMPANY;
    return postService(url, parameters);
}

export function getOrdersByStore(storeId) {
    let url = BASE_URL_CORE + ORDERS_BY_STORE;
    let dataParameters = { store_id : storeId };

    return postService(url, dataParameters);
}

export function getOrdersByZone(parameters) {
    let url = BASE_URL_COMMERCE + ORDER_LIST_BY_ZONE;

    return postService(url, parameters);
}

export function getOrderDetail(orderId) {
    let url = BASE_URL_COMMERCE + ORDER_DETAIL;
    let dataParameters = { orderId : orderId };

    return postService(url, dataParameters);
}

export function updateOrderStatus(action, parameters) {
    let url = BASE_URL_COMMERCE + UPDATE_ORDER_STATUS + action;

    return putService(url, parameters);
}

export function syncOrder(uuid) {
    let url = BASE_URL_COMMERCE + SYNC_ORDER;
    let parameters = { "orderId": uuid };

    return putService(url, parameters);
}
