import React from "react";
import { withTranslation } from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import { withRouter } from "react-router-dom";
import orderDetails from "../../models/OrderModel";
import {
    getOrderDetail,
    getOrdersByZone,
    updateOrderStatus,
} from "../../services/orderService";
import { ROWS_PER_PAGE } from "../../variables/apiConstants";
import { validateSession } from "../../services/api";
import {
    getData,
    isSuccessfulRequest,
    nullController,
    removeData,
    saveData,
    selectValidation,
    verifyAccessImage,
} from "../../utils/helpersFunctions";
import { Button, DialogTitle } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {
    ERROR_UPDATE_ORDER_NOT_ALLOWED,
    NOTIFICATION_ACTION_NOTHING,
    NOTIFICATION_ACTION_SHOW,
    NOTIFICATION_ACTION_UPDATE,
    NOTIFICATION_DURATION,
    NOTIFICATION_DURATION_SHORT,
    NOTIFICATION_SOUND_TIME,
    STATUS_COMPLETED,
    STATUS_PENDING,
    STATUS_PROGRESS,
} from "../../variables/coreConstants";
import { DragDropContext } from "react-beautiful-dnd";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import Select from "../../components/CustomSelect/CustomSelect";
import makeAnimated from "react-select/animated";
import { getStoresAvailable } from "../../services/storeService";
import { getAvailableZonesByStore } from "../../services/zoneService";
// import PerfectScrollbar from "perfect-scrollbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OrderDetailsForm from "../Orders/components/OrderDetailsForm";
import DroppableOrderList from "./component/DroppableOrderList";
import { messaging } from "../../utils/configurations";
import NewNotificationIcon from "@material-ui/icons/NotificationsActive";
import { NOTIFICATION_SOUND } from "../../variables/resourcesConstants";

// var psZone;
// var psGroup;
// var psOrder;
const animatedComponents = makeAnimated();
const audio = new Audio(NOTIFICATION_SOUND);

class OrdersKanban extends React.Component {
    mainContainer = React.createRef();
    sidebarContainer = React.createRef();
    // zoneWrapper = React.createRef();
    // groupWrapper = React.createRef();
    orderWrapper = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            zone:
                getData("zoneSelected") != null ? getData("zoneSelected") : "",
            selectedGroup:
                getData("groupSelected") != null
                    ? getData("groupSelected")
                    : "",
            store:
                getData("storeSelected") != null
                    ? getData("storeSelected")
                    : { value: 0, label: this.props.t("STORES_TEXT") },
            zoneOptions: [],
            data: [],
            storeOptions: [],
            date: { value: 0, label: this.props.t("TODAY_TEXT") },
            dateOptions: [
                { value: 0, label: this.props.t("TODAY_TEXT") },
                { value: 1, label: this.props.t("YESTERDAY_TEXT") },
            ],
            status: { value: 0, label: this.props.t("ALL_STATUS_TEXT") },
            statusOptions: [],
            openOrder: false,
            isLoading: true,
            isLoadingZones: false,
            isLoadingOrder: false,
            isImageError: false,
            noItems: false,
            actionType: 0,
            filter: "ALL",
            currentZone: "",
            newOrders: [],
            progressOrders: [],
            ordersCompleted: [],
            orderDetail: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            count: 0,
            notificationMessage: "",
            notificationPlace: "tc",
            notification: false,
            action: null,
            color: "primary",
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            //     psZone = new PerfectScrollbar(this.zoneWrapper.current, {
            //         wheelPropagation : false,
            //         suppressScrollX: false,
            //         suppressScrollY: false
            //     });
            //     psGroup = new PerfectScrollbar(this.groupWrapper.current, {
            //         wheelPropagation : false,
            //         suppressScrollX: false,
            //         suppressScrollY: false
            //     });
            //     psOrder = new PerfectScrollbar(this.orderWrapper.current, {
            //         wheelPropagation : false,
            //         suppressScrollX: false,
            //         suppressScrollY: false
            //     });
        }

        this.notificationListener = messaging.onMessage((payload) => {
            let action = NOTIFICATION_ACTION_NOTHING;

            if (payload.data && payload.data.zoneId && payload.data.storeId) {
                this.handlePlayNotification();
                if (this.state.store.value !== 0) {
                    if (
                        this.state.zone !== "" &&
                        this.state.selectedGroup !== ""
                    ) {
                        if (this.state.zone.value === payload.data.zoneId) {
                            action = NOTIFICATION_ACTION_UPDATE;
                        } else {
                            if (
                                this.state.store.value === payload.data.storeId
                            ) {
                                action = NOTIFICATION_ACTION_SHOW;
                            }
                        }
                    } else {
                        action = NOTIFICATION_ACTION_SHOW;
                    }
                }

                this.showNotification(
                    nullController(payload.data, "title"),
                    "primary",
                    NewNotificationIcon,
                    NOTIFICATION_SOUND_TIME,
                    action
                );
            }
        });

        this.setState({ isLoading: true });
        this.request();
    }

    componentWillUnmount() {
        // Do not allow go back

        // if (navigator.platform.indexOf("Win") > -1) {
        //     psZone.destroy();
        //     psGroup.destroy();
        //     psOrder.destroy();
        // }
        removeData("zoneSelected");
        removeData("storeSelected");
        removeData("groupSelected");
        removeData("zoneOptions");
        this.notificationListener();
    }

    handlePlayNotification = () => {
        try {
            audio.loop = true;
            audio.play();

            window.setTimeout(
                function() {
                    this.handleStopNotification();
                }.bind(this),
                12000
            );
        } catch (e) {}
    };

    handleStopNotification = () => {
        try {
            audio.pause();
        } catch (e) {}
    };

    showNotification = (msj, color, icon, time, action) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
            action: action,
            notificationPlace: action !== null ? "tr" : "tc",
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (action !== null) {
                    this.onReceiveNotification(action);
                }
            }.bind(this),
            time
        );
    };

    hideNotification = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ notification: false });
    };

    onReceiveNotification = (action) => {
        const { zone, store, date } = this.state;

        if (action !== NOTIFICATION_ACTION_NOTHING) {
            let ordersParameters = {
                intervalDays: date.value,
                storeId: store.value,
                zoneId: zone.value,
            };
            let zonesParameters = {
                storeId: store.value,
                intervalDays: date.value,
                zonesFilter: this.state.filter,
            };

            if (action === NOTIFICATION_ACTION_SHOW) {
                this.requestZones(zonesParameters);
            } else if (action === NOTIFICATION_ACTION_UPDATE) {
                this.requestZones(zonesParameters);
                this.requestOrders(ordersParameters);
            }
        }
    };

    handleOpenForm = () => {
        orderDetails.cleanOrderDetails();
        this.setState({ openOrder: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openOrder: false });
        if (orderDetails.oldStatus !== orderDetails.status) {
            const { store, zone, date } = this.state;
            this.setState({ isLoadingZones: true });

            let dataParameters = {
                intervalDays: date.value,
                storeId: store.value,
                zoneId: zone.value,
            };
            this.requestOrders(dataParameters);
        }
    };
    closeForm(state, value, type) {
        this.setState({ [state]: value });
        if (type) {
            const { store, zone, date } = this.state;
            this.setState({ isLoadingZones: true });

            let dataParameters = {
                intervalDays: date.value,
                storeId: store.value,
                zoneId: zone.value,
            };
            this.requestOrders(dataParameters);
        }
    }
    openForm = (formData, status) => {
        const { t } = this.props;
        let order = { id: null, uuid: formData.uuid, orderStatus: status };

        orderDetails.setOrderDetail(order);
        this.setState({ isLoadingOrder: true });
        getOrderDetail(formData.uuid)
            .then((response) => {
                if (response.data) {
                    this.setState({
                        orderDetail: response.data,
                    });
                    verifyAccessImage(response.data.photo, this);
                } else {
                    this.setState({
                        isLoadingOrder: false,
                        openOrder: false,
                    });
                    this.showNotification(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    isLoadingOrder: false,
                    openOrder: false,
                });

                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );

                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0,
        });
    };

    requestZones = (parameters) => {
        getAvailableZonesByStore(parameters)
            .then((res) => {
                if (res.data.length > 0) {
                    let values = res.data.map((zone) => {
                        return {
                            id: zone.zoneGroupId,
                            name: zone.zoneGroup,
                            countNew: zone.countNew,
                            countProgress: zone.countProgress,
                            zoneList: zone.zoneList,
                        };
                    });
                    this.setState({ data: values });

                    try {
                        if (this.state.selectedGroup !== "") {
                            let index = res.data.findIndex(
                                (val) =>
                                    val.zoneGroupId ===
                                    this.state.selectedGroup.value
                            );
                            if (index !== -1) {
                                let zoneList = res.data[index].zoneList.map(
                                    (zone) => {
                                        return {
                                            id: zone.id,
                                            name: zone.name,
                                            countNew: zone.countNew,
                                            countProgress: zone.countProgress,
                                        };
                                    }
                                );

                                this.setState({ zoneOptions: zoneList });
                            }
                        } else {
                            if (
                                this.state.zone !== "" &&
                                this.state.store.value !== 0
                            ) {
                                let currentGroup = values.findIndex(
                                    (group) =>
                                        group.zoneList.findIndex(
                                            (item) =>
                                                item.id ===
                                                this.state.zone.value
                                        ) !== -1
                                );

                                let group = {
                                    value: values[currentGroup].id,
                                    label: values[currentGroup].name,
                                };
                                let zoneList = values[currentGroup].zoneList;
                                saveData("groupSelected", group);
                                this.setState({
                                    selectedGroup: group,
                                    zoneOptions: zoneList,
                                });
                            }
                        }
                    } catch (e) {
                        console.log("Something went wrong");
                    }
                }
                this.setState({ isLoadingZones: false });
            })
            .catch((error) => {
                this.setState({ isLoadingZones: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    };

    requestOrders = (parameters) => {
        getOrdersByZone(parameters)
            .then((res) => {
                if (res.data) {
                    this.setState({
                        newOrders:
                            res.data.newOrders && res.data.newOrders.length > 0
                                ? res.data.newOrders
                                : [],
                        ordersCompleted:
                            res.data.completedOrders &&
                            res.data.completedOrders.length > 0
                                ? res.data.completedOrders
                                : [],
                        progressOrders:
                            res.data.progressOrders &&
                            res.data.progressOrders.length > 0
                                ? res.data.progressOrders
                                : [],
                    });
                }
                this.setState({ isLoadingZones: false, isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoadingZones: false, isLoading: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    };

    changeDateSelect = (date) => {
        const { store, zone, selectedGroup } = this.state;
        this.setState({ date: date, isLoadingZones: true });

        if (store.value !== 0) {
            let zonesParameters = {
                storeId: store.value,
                intervalDays: date.value,
                zonesFilter: this.state.filter,
            };

            this.requestZones(zonesParameters);
        }

        if (store.value !== 0 && selectedGroup !== "" && zone !== "") {
            let ordersParameters = {
                intervalDays: date.value,
                storeId: store.value,
                zoneId: zone.value,
            };

            this.requestOrders(ordersParameters);
        }
    };

    changeStoreSelect = (store) => {
        this.setState({
            store: store,
            isLoadingZones: true,
            zone: "",
            selectedGroup: "",
            data: [],
            zoneOptions: [],
            newOrders: [],
            ordersCompleted: [],
            progressOrders: [],
        });

        let dataParameters = {
            storeId: store.value,
            intervalDays: this.state.date.value,
            zonesFilter: this.state.filter,
        };

        removeData("storeSelected");
        removeData("zoneOptions");
        removeData("groupSelected");
        removeData("zoneSelected");
        saveData("storeSelected", store);
        this.requestZones(dataParameters);
    };

    request = () => {
        const { zone, store, date } = this.state;

        getStoresAvailable()
            .then((res) => {
                if (res.data.length > 0) {
                    let values = res.data.map((store) => {
                        return { value: store.id, label: store.name };
                    });
                    this.setState({ storeOptions: values });
                }
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );

                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });

        if (store.value !== 0) {
            let zonesParameters = {
                storeId: store.value,
                intervalDays: date.value,
                zonesFilter: this.state.filter,
            };

            this.requestZones(zonesParameters);
        }

        if (zone !== "" && store.value !== 0) {
            let dataParameters = {
                intervalDays: date.value,
                storeId: store.value,
                zoneId: zone.value,
            };

            this.setState({ isLoading: true });
            this.requestOrders(dataParameters);
        }
    };

    updateOrder = (order, status) => {
        this.setState({ isLoadingZones: true });
        let dataParameters = {
            order_id: order,
            eta: 0,
            status_comment: "",
        };

        updateOrderStatus(status, dataParameters)
            .then((response) => {
                if (isSuccessfulRequest(response)) {
                    // this.showNotification(response.data.message, "primary", NotificationSuccessIcon, status);
                    if (
                        this.state.zone !== "" &&
                        this.state.store.value !== 0 &&
                        this.state.selectedGroup !== ""
                    ) {
                        this.requestZones({
                            storeId: this.state.store.value,
                            intervalDays: this.state.date.value,
                            zonesFilter: this.state.filter,
                        });

                        this.requestOrders({
                            intervalDays: this.state.date.value,
                            storeId: this.state.store.value,
                            zoneId: this.state.zone.value,
                        });
                    }
                } else {
                    this.showNotification(
                        response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            })
            .catch((error) => {
                this.setState({ isLoadingZones: false });
                if (error.response) {
                    this.showNotification(
                        error.response.data.message,
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                    try {
                        if (
                            error.response.data.errorEntity !== null &&
                            error.response.data.errorEntity.errorType ===
                                ERROR_UPDATE_ORDER_NOT_ALLOWED
                        ) {
                            if (error.response.data.errorEntity.errorData) {
                                const {
                                    errorData,
                                } = error.response.data.errorEntity;
                                this.setState({
                                    newOrders:
                                        errorData.newOrders &&
                                        errorData.newOrders.length > 0
                                            ? errorData.newOrders
                                            : [],
                                    ordersCompleted:
                                        errorData.completedOrders &&
                                        errorData.completedOrders.length > 0
                                            ? errorData.completedOrders
                                            : [],
                                    progressOrders:
                                        errorData.progressOrders &&
                                        errorData.progressOrders.length > 0
                                            ? errorData.progressOrders
                                            : [],
                                });
                            }
                        }
                    } catch (e) {
                        console.log("Something went wrong");
                    }
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(
                        this.props.t("SOMETHING_WENT_WRONG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION,
                        null
                    );
                }
            });
    };

    handleSelectedGroup = (group) => {
        let newGroup = { value: group.id, label: group.name };
        removeData("groupSelected");
        removeData("zoneOptions");
        removeData("zoneSelected");

        this.setState({
            zone: "",
            selectedGroup: newGroup,
            newOrders: [],
            ordersCompleted: [],
            progressOrders: [],
            zoneOptions: group.zoneList,
        });
        saveData("groupSelected", newGroup);
        saveData("zoneOptions", group.zoneList);
    };

    handleSelectedZone = (zone) => {
        removeData("zoneSelected");

        const { date, store } = this.state;
        let newZone = { value: zone.id, label: zone.name };
        this.setState({ zone: newZone, isLoadingZones: true });

        let dataParameters = {
            intervalDays: date.value,
            storeId: store.value,
            zoneId: zone.id,
        };

        saveData("zoneSelected", newZone);
        this.requestOrders(dataParameters);
    };

    onDragEnd(result) {
        // Dropped outside the list
        if (!result.destination) {
            return;
        } else {
            const { destination, source, draggableId } = result;
            if (destination.droppableId !== source.droppableId) {
                if (source.droppableId === "new") {
                    if (destination.droppableId === "accept") {
                        const progressOrders = this.reorder(
                            this.state.newOrders,
                            this.state.progressOrders,
                            source.index,
                            destination.index
                        );

                        this.setState({
                            progressOrders,
                            newOrders: this.removeFromInitial(
                                this.state.newOrders,
                                source.index
                            ),
                        });
                        this.updateOrder(draggableId, destination.droppableId);
                    } else if (destination.droppableId === "ready") {
                        const ordersCompleted = this.reorder(
                            this.state.newOrders,
                            this.state.ordersCompleted,
                            source.index,
                            destination.index
                        );

                        this.setState({
                            ordersCompleted,
                            newOrders: this.removeFromInitial(
                                this.state.newOrders,
                                source.index
                            ),
                        });
                        this.updateOrder(draggableId, destination.droppableId);
                    } else if (destination.droppableId === "cancel") {
                        console.log("Update: N-cancelled");
                    } else {
                        return;
                    }
                } else if (source.droppableId === "accept") {
                    if (destination.droppableId === "ready") {
                        const ordersCompleted = this.reorder(
                            this.state.progressOrders,
                            this.state.ordersCompleted,
                            source.index,
                            destination.index
                        );

                        this.setState({
                            ordersCompleted,
                            progressOrders: this.removeFromInitial(
                                this.state.progressOrders,
                                source.index
                            ),
                        });
                        this.updateOrder(draggableId, destination.droppableId);
                    } else if (destination.droppableId === "cancel") {
                        console.log("Update: P-cancelled");
                    } else if (destination.droppableId === "new") {
                        this.showNotification(
                            this.props.t("NOT_ALLOW_ACTION_MSG"),
                            "danger",
                            NotificationFailedIcon,
                            NOTIFICATION_DURATION_SHORT,
                            null
                        );
                    } else {
                        return;
                    }
                } else if (source.droppableId === "ready") {
                    this.showNotification(
                        this.props.t("NOT_ALLOW_ACTION_MSG"),
                        "danger",
                        NotificationFailedIcon,
                        NOTIFICATION_DURATION_SHORT,
                        null
                    );
                    return;
                } else {
                    return;
                }
            } else {
                return;
            }
        }
    }

    reorder = (originList, endingList, startIndex, endIndex) => {
        let initList = Array.from(originList);
        let endList = Array.from(endingList);

        const [removed] = initList.splice(startIndex, 1);
        endList.splice(endIndex, 0, removed);

        return endList;
    };

    removeFromInitial = (list, index) => {
        const result = Array.from(list);
        result.splice(index, 1);

        return result;
    };

    render() {
        const { classes, t, routeProps } = this.props;
        const {
            orderDetail,
            isLoading,
            isLoadingOrder,
            isImageError,
            selectedGroup,
            zone,
        } = this.state;

        //Set page title
        document.title = document.title
            ? document.title.split("-")[0].trim() + " - " + t(routeProps.name)
            : t(routeProps.name);

        return (
            <div className={classes.orderKanbanContainer}>
                <Backdrop
                    className={classes.backdrop}
                    open={isLoading || isLoadingOrder}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={3}
                        md={3}
                        dismissRule={false}
                        className={classes.gridItemWithoutLeftPadding}
                    >
                        <GridContainer>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                dismissRule={true}
                                className={classes.gridItemNoPadding}
                            >
                                <div
                                    ref={this.sidebarContainer}
                                    className={classes.storeZoneContainer}
                                >
                                    <div className={classes.storeContainer}>
                                        <Select
                                            value={this.state.store}
                                            onChange={this.changeStoreSelect}
                                            components={animatedComponents}
                                            options={this.state.storeOptions}
                                            isDisabled={
                                                this.state.isLoadingZones
                                            }
                                            isLoadig={this.state.isLoadingZones}
                                            placeholder={
                                                t("STORES_TEXT") + "..."
                                            }
                                            closeMenuOnSelect={true}
                                            maxMenuHeight={400}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: primaryColor[0],
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className={classes.zonesHeader}>
                                        <p className={classes.dropTitle}>
                                            {t("ZONES_TEXT")}
                                        </p>
                                    </div>
                                    <div className={classes.zoneSection}>
                                        <div
                                            className={
                                                classes.zoneGroupContainer
                                            }
                                        >
                                            {this.state.data.map((group) => (
                                                <Button
                                                    onClick={() =>
                                                        this.handleSelectedGroup(
                                                            group
                                                        )
                                                    }
                                                    disabled={
                                                        this.state
                                                            .isLoadingZones
                                                    }
                                                    color={"inherit"}
                                                    key={group.id}
                                                    className={
                                                        group.id ===
                                                        selectedGroup.value
                                                            ? classes.groupCardSelected
                                                            : classes.groupCard
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.zoneNameContainer
                                                        }
                                                    >
                                                        {group.name}
                                                    </div>
                                                    <div
                                                        style={{ width: "15%" }}
                                                    >
                                                        {parseInt(
                                                            group.countNew
                                                        ) +
                                                            parseInt(
                                                                group.countProgress
                                                            ) >
                                                        0 ? (
                                                            <div
                                                                className={
                                                                    group.countNew >
                                                                    0
                                                                        ? classes.roundNotificationNew
                                                                        : classes.roundNotificationProgress
                                                                }
                                                            >
                                                                {parseInt(
                                                                    group.countNew
                                                                ) +
                                                                    parseInt(
                                                                        group.countProgress
                                                                    )}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Button>
                                            ))}
                                        </div>
                                        <div
                                            className={
                                                classes.zoneListContainer
                                            }
                                        >
                                            {this.state.zoneOptions.map(
                                                (val, key) => (
                                                    <Button
                                                        onClick={() =>
                                                            this.handleSelectedZone(
                                                                val
                                                            )
                                                        }
                                                        disabled={
                                                            this.state
                                                                .isLoadingZones
                                                        }
                                                        color={"inherit"}
                                                        key={val.id}
                                                        className={
                                                            val.id ===
                                                            zone.value
                                                                ? classes.zoneCardSelected
                                                                : classes.zoneCard
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.zoneNameContainer
                                                            }
                                                        >
                                                            {val.name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "15%",
                                                            }}
                                                        >
                                                            {parseInt(
                                                                val.countNew
                                                            ) +
                                                                parseInt(
                                                                    val.countProgress
                                                                ) >
                                                            0 ? (
                                                                <div
                                                                    className={
                                                                        val.countNew >
                                                                        0
                                                                            ? classes.roundNotificationNew
                                                                            : classes.roundNotificationProgress
                                                                    }
                                                                >
                                                                    {parseInt(
                                                                        val.countNew
                                                                    ) +
                                                                        parseInt(
                                                                            val.countProgress
                                                                        )}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={9}
                        md={9}
                        dismissRule={true}
                        className={classes.kanbanContainer}
                    >
                        <div ref={this.mainContainer} style={{ width: "100%" }}>
                            <GridContainer>
                                <GridItem xs={8} sm={8} md={8}>
                                    <h3 className={classes.zoneTitle}>
                                        {nullController(
                                            this.state.zone,
                                            "label"
                                        )}
                                    </h3>
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>
                                    <div
                                        style={{
                                            width: "100%",
                                            marginRight: "20%",
                                        }}
                                    >
                                        <Select
                                            value={this.state.date}
                                            onChange={this.changeDateSelect}
                                            components={animatedComponents}
                                            options={this.state.dateOptions}
                                            isDisabled={
                                                this.state.isLoadingZones ||
                                                selectValidation(
                                                    this.state.store
                                                )
                                            }
                                            isLoadig={this.state.isLoadingZones}
                                            placeholder={t("SELECT_TEXT")}
                                            closeMenuOnSelect={true}
                                            maxMenuHeight={300}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: primaryColor[0],
                                                },
                                            })}
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <div
                                ref={this.orderWrapper}
                                className={classes.dragDropContainer}
                            >
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <DroppableOrderList
                                        data={this.state.newOrders}
                                        dropId={"new"}
                                        status={STATUS_PENDING}
                                        isDisabled={this.isLoadingZones}
                                        title={t("NEW_TEXT")}
                                        component={this}
                                    />
                                    <DroppableOrderList
                                        data={this.state.progressOrders}
                                        dropId={"accept"}
                                        status={STATUS_PROGRESS}
                                        isDisabled={this.isLoadingZones}
                                        title={t("PROGRESS_TEXT")}
                                        component={this}
                                    />
                                    <DroppableOrderList
                                        data={this.state.ordersCompleted}
                                        dropId={"ready"}
                                        status={STATUS_COMPLETED}
                                        isDisabled={this.isLoadingZones}
                                        title={t("COMPLETED_TEXT")}
                                        component={this}
                                    />
                                </DragDropContext>
                                <Dialog
                                    maxWidth={"md"}
                                    fullWidth={true}
                                    open={this.state.openOrder}
                                    aria-labelledby="form-dialog-title"
                                >
                                    <DialogTitle id="form-dialog-title">
                                        <div
                                            className={
                                                classes.orderDetailActions
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.actionCloseContainer
                                                }
                                            >
                                                <Tooltip
                                                    id="tooltip-close"
                                                    title={t("BTN_CLOSE")}
                                                    placement="right-start"
                                                >
                                                    <IconButton
                                                        size={"small"}
                                                        onClick={
                                                            this.handleCloseForm
                                                        }
                                                        className={
                                                            classes.formIcons
                                                        }
                                                    >
                                                        <CloseIcon
                                                            className={
                                                                classes.iconButtonRed
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div
                                                className={
                                                    classes.orderDetailIdentifierDialog
                                                }
                                            >
                                                {"#" + orderDetail.identifier}
                                            </div>
                                            <div
                                                className={
                                                    classes.actionPrintContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.rightPaper +
                                                        " " +
                                                        classes.printContainer
                                                    }
                                                >
                                                    {/*<Tooltip id="tooltip-edit" title={t('PRINT_TEXT')} placement="right-start">*/}
                                                    {/*    <IconButton size={"small"} className={classes.formIcons}>*/}
                                                    {/*        <PrintIcon className={classes.iconButtonBlue} />*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</Tooltip>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <OrderDetailsForm
                                            data={orderDetail}
                                            isImageError={isImageError}
                                            closeForm={this.closeForm}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Snackbar
                                    place={this.state.notificationPlace}
                                    color={this.state.color}
                                    icon={this.state.icon}
                                    message={this.state.notificationMessage}
                                    open={this.state.notification}
                                    closeNotification={this.hideNotification}
                                    close
                                />
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

OrdersKanban.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(
    withStyles(tableViewStyle)(withTranslation()(OrdersKanban))
);
