import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../components/Loading/Loading";
import CardBody from "../../../components/Card/CardBody";
import {nullController} from "../../../utils/helpersFunctions";
import Avatar from "@material-ui/core/Avatar";
import UserIcon from "@material-ui/icons/AccountCircle";
import CallIcon from "@material-ui/icons/PhoneInTalk";
import Status from "../../../components/Status/Status";
import {statusColors, statusText} from "../../../utils/statusHelpers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FilterNone";


class MemberDetails extends React.Component{

    handleCopyToClipboard = (val) => {
        try {
            navigator.clipboard.writeText(val);
        } catch (e) {
            console.log("Something went wrong");
        }
    };

    render() {
        const { data, loading, classes, t, isImageError } = this.props;
        const { role } = data !== null ? data : [];

        return (
            <div>
                {
                    loading ? <Loading/> : (
                        <GridContainer>
                            <GridItem xs={12} sm={8} md={8}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={8} md={8} dismissRule>
                                                <div className={classes.memberProfileRow}>
                                                    {data !== null && data.photo && !isImageError
                                                        ? <Avatar alt="Profile" src={data.photo} className={classes.iconAvatar}/>
                                                        : <Avatar className={classes.iconAvatar}>
                                                            <UserIcon className={classes.iconAvatarBlue} />
                                                        </Avatar>
                                                    }
                                                    <p className={classes.memberCardName}>{nullController(data, "name")}</p>
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4} dismissRule>
                                                <div className={classes.rightPaper}>
                                                    {
                                                        nullController(data, "phone") !== null
                                                        && nullController(data, "phone") !== "" ? (
                                                            <div className={classes.memberPhoneRow}>
                                                                <p className={classes.memberCardPhone}>{nullController(data, "phone")}</p>
                                                                <CallIcon className={classes.iconAvatarBlue}/>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12} dismissRule>
                                                <p className={classes.memberCardBodyText}>{t('TH_ROLE') + ": " + nullController(role, "name")}</p>
                                                <p className={classes.memberCardBodyText}>{t('TH_MEMBER_ID') + ": " + nullController(data, "externalId")}</p>
                                                <p className={classes.memberCardBodyText}>{t('MEMBER_EMAIL_TXT') + ": " + nullController(data, "email")}</p>
                                                <p className={classes.memberCardBodyText}>{t('MEMBER_PHONE_TEXT') + ": " + nullController(data, "phone")}</p>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                                {
                                    data !== null && data.memberLink !== null && data.memberLink !== undefined
                                    && data.memberLink !== "" ? (
                                        <Card>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridItem xs={12} sm={8} md={9} dismissRule>
                                                        <p className={classes.statusCardTitle}>{t('MEMBER_LINK_TXT')}</p>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={4} md={3} dismissRule>
                                                        <div className={classes.rightPaper}>
                                                            <Tooltip id={data.uuid} title={t('COPY_TEXT') + " " + data.memberLink} placement="top-start">
                                                                <IconButton
                                                                    style={{marginRight: 10}}
                                                                    size={"small"}
                                                                    onClick={() => this.handleCopyToClipboard(data.memberLink)}>
                                                                    <CopyIcon className={classes.copyButtonIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} dismissRule>
                                                        <p className={classes.paymentCardBodyText}>{data.memberLink}</p>
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    ) : null
                                }
                                {/*{*/}
                                {/*    data.invitationLink !== null && data.invitationLink !== undefined*/}
                                {/*    && data.invitationLink !== "" ? (*/}
                                {/*        <Card>*/}
                                {/*            <CardBody>*/}
                                {/*                <GridContainer>*/}
                                {/*                    <GridItem xs={12} sm={8} md={9} dismissRule>*/}
                                {/*                        <p className={classes.statusCardTitle}>{t('INVITATION_LINK_TXT')}</p>*/}
                                {/*                    </GridItem>*/}
                                {/*                    <GridItem xs={12} sm={4} md={3} dismissRule>*/}
                                {/*                        <div className={classes.rightPaper}>*/}
                                {/*                            <Tooltip id={data.uuid} title={t('COPY_TEXT') + " " + data.invitationLink} placement="top-start">*/}
                                {/*                                <IconButton*/}
                                {/*                                    style={{marginRight: 10}}*/}
                                {/*                                    size={"small"}*/}
                                {/*                                    onClick={() => this.handleCopyToClipboard(data.invitationLink)}>*/}
                                {/*                                    <CopyIcon className={classes.copyButtonIcon} />*/}
                                {/*                                </IconButton>*/}
                                {/*                            </Tooltip>*/}
                                {/*                        </div>*/}
                                {/*                    </GridItem>*/}
                                {/*                    <GridItem xs={12} sm={12} md={12} dismissRule>*/}
                                {/*                        <p className={classes.paymentCardBodyText}>{data.invitationLink}</p>*/}
                                {/*                    </GridItem>*/}
                                {/*                </GridContainer>*/}
                                {/*            </CardBody>*/}
                                {/*        </Card>*/}
                                {/*    ) : null*/}
                                {/*}*/}
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <p className={classes.statusCardTitle}>{t('TH_MEMBER_STATUS')}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <div className={classes.memberStatusRow}>
                                                    <Status
                                                        className={classes.statusCard}
                                                        color={data !== null ? statusColors[data.status] : "default"}
                                                        size="lg"
                                                        label={data !== null ? t(statusText[data.status]) : "undefined"}
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <p className={classes.statusCardTitle}>{t('TH_PAYMENT_STATUS')}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} dismissRule>
                                                <div className={classes.memberStatusRow}>
                                                    <Status
                                                        className={classes.statusCard}
                                                        color={data !== null ? statusColors[data.paymentStatus] : "default"}
                                                        size="lg"
                                                        label={data !== null ? t(statusText[data.paymentStatus]) : "undefined"}
                                                    />
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )
                }
            </div>
        );
    }
}


MemberDetails.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
    isImageError: PropTypes.bool,
};

export default withRouter(withStyles(dashboardStyle)(withTranslation()(MemberDetails)));


