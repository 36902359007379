/**
 * Created By Marlon Reyes 10/10/2019
 **/

const eventTagTypes = {
    0: 'Check Out',
    1: 'Check In'
};


module.exports = {
    eventTagTypes,
};