import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Status from "../../components/Status/Status";
import {statusColors, statusText, formTitles} from "../../utils/statusHelpers";
import Loading from "../../components/Loading/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import {
    ENTITY_DASHBOARD_USER,
    NOTIFICATION_DURATION,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_OPTIONS, USER_TABLE_ACTIONS,
} from "../../variables/coreConstants";
import {getStringTextFormatByName, isSuccessfulRequest, nullController} from "../../utils/helpersFunctions";
import TableBody from "@material-ui/core/TableBody";
import TableActions from "../../components/TableActions/TableActions";
import Muted from "../../components/Typography/Muted";
import {validateSession} from "../../services/api";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import Snackbar from "../../components/Snackbar/Snackbar";
import {withRouter} from "react-router-dom";
import dashboardUserDetails from "../../models/DashboardUserModel";
import {getDashboardUsersByCompany} from "../../services/accountService";
import DashboardUserForm from "./components/DashboardUserForm";
import {getStatus, statusController} from "../../services/statusService";


class DashboardUsers extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            openUser: false,
            isLoading: false,
            actionType: 0,
            data: [],
            filteredData: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }


    showNotification = (msj, color, icon, refresh) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
                if (refresh != null)
                    this.closeForm("openUser", false, refresh);
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    handleOpenForm = () => {
        dashboardUserDetails.cleanUserDetails();
        this.setState({ openUser: true, actionType: 0 });
    };
    handleCloseForm = () => {
        this.setState({ openUser: false });
    };
    closeForm(state, value, type) {
        this.setState({[state]: value});
        if(type)
            this.request();
    }
    openForm = (action, formData) => {
        dashboardUserDetails.cleanUserDetails();
        dashboardUserDetails.setUserDetail(formData);

        if (action === 1 || action === 0) {
            this.setState({ openUser: true, actionType: action });

        } else {
            let dataParameters = {
                "entity": ENTITY_DASHBOARD_USER,
                "uuid": dashboardUserDetails.uuid,
                "status": getStatus(action)
            };

            statusController(action, null, null, dataParameters, this, false)
                .then((response) => {
                    if (isSuccessfulRequest(response)) {
                        this.showNotification(response.data.message, "primary", NotificationSuccessIcon, true);
                    } else {
                        this.showNotification(response.data.message, "danger", NotificationFailedIcon, false);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.showNotification(error.response.data.message, "danger", NotificationFailedIcon, null);
                        validateSession(error.response, this.props.history);
                    }
                });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    request = () => {
        this.setState({ isLoading: true} );
        getDashboardUsersByCompany()
            .then((res) => {
                this.setState({ data: res.data, filteredData: res.data, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false} );
                if (err.response) {
                    this.showNotification(err.response.data.message, "danger",
                        NotificationFailedIcon, null);
                    validateSession(err.response, this.props.history);
                } else {
                    this.showNotification(this.props.t('SOMETHING_WENT_WRONG'), "danger",
                        NotificationFailedIcon, null)
                }
            });
    };

    // Method to search data
    searchUser = (e) => {
        let newData = this.state.data;
        try {
            newData = newData.filter((item) => {
                return (item.firstName !== null ? (item.firstName.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.lastName !== null ? (item.lastName.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || (item.email !== null ? (item.email.toLowerCase().search(e.target.value.toLowerCase()) !== -1) : false)
                    || this.props.t(statusText[item.status]).toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });
            this.setState({ filteredData: newData});

        } catch (e) {
            console.log("Something went wrong.");
        }
    };



    render() {
        const { classes, t } = this.props;
        const { actionType, page, rowsPerPage, filteredData, isLoading } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Input
                                onChange={this.searchUser}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon className={classes.objectColor}/>
                                    </InputAdornment>
                                }
                                placeholder={t('SEARCH_USER')}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.rightPaper}>
                                <Button color="primary" onClick={this.handleOpenForm}>{t('BTN_ADD_USER')}</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                    {isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.objectColor}>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NAME')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_EMAIL')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_PERMISSIONS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_NOTIFICATIONS_EVENT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STATUS')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true} style={{cursor: "pointer"}}>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'firstName') + " " + nullController(val, 'lastName')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'email')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {getStringTextFormatByName(val.permissions)}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {getStringTextFormatByName(val.notifications)}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={statusColors[val.status]}
                                                                size="lg"
                                                                label={t(statusText[val.status])}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <TableActions
                                                                    rowData={val}
                                                                    openForm={this.openForm}
                                                                    options={USER_TABLE_ACTIONS}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                </GridItem>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openUser}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t(formTitles[actionType])} {t('USER_TEXT')}
                        <Muted>
                            {t('FORM_USER_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <DashboardUserForm action={actionType} closeForm={this.closeForm}/>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

DashboardUsers.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(DashboardUsers)));