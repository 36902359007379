import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from '../utils/history';
import Admin from './Admin';
import SignIn from '../views/SignIn/SignIn';
import SignUp from '../views/SignUp/SignUp';
import ConfirmationPage from "../views/ConfirmationPage/ConfirmationPage";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import SessionExpired from "../views/ErrorPage/SessionExpired";
import ErrorController from "../components/ErrorBoundary/ErrorController";
import ResetPassword from "../views/ForgotPassword/ResetPassword";


history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a page view for the given page
});



class Main extends React.Component {

    render() {
        return (
                <Router history={history} >
                    <ErrorController>
                        <Switch>
                            <PrivateRoute path="/admin" component={Admin} />
                            <Route exact={true} path="/sign-in" component={SignIn}/>
                            <Route exact={true} path="/sign-up" component={SignUp}/>
                            <Route exact={true} path="/confirm-registration/:code" component={ConfirmationPage}/>
                            <Route exact={true} path="/reset-password/:referenceId/:code" component={ResetPassword}/>
                            <Route exact={true} path="/confirm-email/:referenceId/:code" component={ConfirmationPage}/>
                            <Route exact={true} path="/session-expired" component={SessionExpired}/>
                            <Redirect from="/" to="/sign-in" />
                        </Switch>
                    </ErrorController>
                </Router>
        );
    }
}

export default Main;